import ChartWrapper from "./charts/ChartWrapper"
import { AnaliseWrapComponentPropsInterface } from "./Analise.Model"
import { Paper } from "@mui/material"

const AnaliseWrapComponent = (props: AnaliseWrapComponentPropsInterface) => {
  const {
    meterReadingDateRange,
    barColors,
    analiseData,
    headerTabs,
    isLoading,
    selectedTab,
    handleSelectTab,
    handleDateChange,
    handleTimeChange,
  } = props

  return (
    <Paper sx={{ pt: 3 }}>
      <ChartWrapper
        headerTabs={headerTabs}
        meterReadingDateRange={meterReadingDateRange}
        analiseData={analiseData}
        handleDateChange={handleDateChange}
        handleTimeChange={handleTimeChange}
        barColors={barColors}
        handleSelectTab={handleSelectTab}
        isLoading={isLoading}
        selectedTab={selectedTab}
      />
    </Paper>
  )
}

export default AnaliseWrapComponent

import { RouteComponentProps } from "react-router-dom"
import { AuthType } from "../../../../domain/auth/Auth.Model"
import { AuthTypeProps } from "../../AppContainer.Component"
import { AppContainerState } from "../../AppContainer.Reducer"
import { AdminNavigationView, AdminNavigationViewProps } from "./AdminNavigationView"
import {
  CustomerNavigationView,
  CustomerNavigationViewProps,
  CustomerNavigationZevProps,
} from "./CustomerNavigationView"

export type AppNavigationViewProps = AdminNavigationViewProps &
  CustomerNavigationViewProps &
  CustomerNavigationZevProps &
  AuthTypeProps &
  AppContainerState &
  RouteComponentProps

export const AppNavigationView = (props: AppNavigationViewProps) => {
  const { authType } = props

  if (authType === AuthType.ADMINISTRATOR) return <AdminNavigationView {...props} />
  else if (authType === AuthType.MANAGER) return <CustomerNavigationView {...props} />

  return null
}

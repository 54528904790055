import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import { TutorialStatus } from "../../../../domain/auth/Auth.Model"

export interface UiState {
  managerSettingActiveTab: number
  tutorialStatus: TutorialStatus
  resetVewaNextBillingPeriod: {
    canReset: boolean
    billingId: string
  }
}

export const initialState: UiState = {
  managerSettingActiveTab: 0,
  tutorialStatus: TutorialStatus.COMPLETED,
  resetVewaNextBillingPeriod: {
    canReset: false,
    billingId: "",
  },
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setTutorialStatus: (state: UiState, { payload }: PayloadAction<TutorialStatus>) => {
      state.tutorialStatus = payload
    },
    setManagerSettingActiveTab: (state: UiState, { payload }: PayloadAction<number>) => {
      state.managerSettingActiveTab = payload
    },
    setResetVewaNextBillingPeriod: (
      state: UiState,
      { payload }: PayloadAction<{ canReset: boolean; billingId: string }>,
    ) => {
      state.resetVewaNextBillingPeriod = payload
    },
  },
})

export const { setManagerSettingActiveTab, setResetVewaNextBillingPeriod } = uiSlice.actions

const uiState = (state: RootState) => state.managerPortal.ui
export const selectTutorialStatus = createSelector(uiState, (state) => state.tutorialStatus)
export const selectManagerSettingActiveTab = createSelector(uiState, (state) => state.managerSettingActiveTab)
export const selectResetVewaNextBillingPeriod = createSelector(uiState, (state) => state.resetVewaNextBillingPeriod)

export default uiSlice.reducer

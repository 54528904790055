import { useCallback, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { startOfWeek } from "date-fns"
import endOfWeek from "date-fns/endOfWeek"
import isSameDay from "date-fns/isSameDay"
import isWithinInterval from "date-fns/isWithinInterval"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { DateView, LocalizationProvider, MobileDatePicker, PickersDay, PickersDayProps } from "@mui/x-date-pickers"
import de from "date-fns/locale/de"
import { styled } from "@mui/material/styles"

import { getDateLocale } from "../../app/App.i18n"
import { appFormattedDateValue, YEAR_DATE_FORMAT, DAY_DATE_FORMAT } from "../../domain/Domain.Formatters"
import { Grid } from "@mui/material"

export enum DatePickerMonthDay {
  CURRENT = "CURRENT",
  BEGIN = "BEGIN",
  END = "END",
}

type DatePickerType = "year" | "month" | "week" | "day" | undefined

interface InlineDatePickerProps {
  pickerType: DatePickerType
  selectedDate: Date
  onChange: (date: Date | null) => void
  maxDate?: Date
}

type CustomPickerDayProps = PickersDayProps<Date> & {
  dayIsBetween: boolean
  isFirstDay: boolean
  isLastDay: boolean
}

interface DateOutputProps {
  date: string
  onClick: () => void
}
export const DateOutput = (props: DateOutputProps) => {
  const { date, onClick } = props
  return (
    <output
      style={{
        textAlign: "center",
        fontSize: "0.85em",
        cursor: "pointer",
        fontFamily: "Montserrat",
        width: "100%",
        height: "30px",
        lineHeight: "30px",
      }}
      onClick={onClick}
    >
      {date}
    </output>
  )
}

const CustomPickersWeek = styled(PickersDay, {
  shouldForwardProp: (prop) => prop !== "dayIsBetween" && prop !== "isFirstDay" && prop !== "isLastDay",
})<CustomPickerDayProps>(({ theme, dayIsBetween, isFirstDay, isLastDay }) => ({
  ...(dayIsBetween && {
    borderRadius: 0,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    "&:hover, &:focus": {
      backgroundColor: theme.palette.primary.dark,
    },
  }),
  ...(isFirstDay && {
    borderTopLeftRadius: "50%",
    borderBottomLeftRadius: "50%",
  }),
  ...(isLastDay && {
    borderTopRightRadius: "50%",
    borderBottomRightRadius: "50%",
  }),
})) as React.ComponentType<CustomPickerDayProps>

export const InlineDatePicker = (props: InlineDatePickerProps) => {
  const { pickerType, selectedDate, onChange, maxDate } = props
  const { t } = useTranslation()

  const [date, setDate] = useState<Date | null>(selectedDate)
  const [openView, setOpenView] = useState(false)
  const [views, setViews] = useState<DateView[]>(["day", "month", "year"])
  const [openTo, setOpenTo] = useState<DateView>("day")

  useEffect(() => {
    switch (pickerType) {
      case "year":
        setViews(["year"])
        setOpenTo("year")
        break
      case "month":
        setViews(["year", "month"])
        setOpenTo("month")
        break
      case "day":
      case "week":
        setViews(["year", "month", "day"])
        setOpenTo("day")
        break
    }
  }, [pickerType])

  const formattedDateValue = useCallback(
    (date: Date | null) => {
      const dateClone = date ? new Date(date) : new Date()
      let label
      switch (pickerType) {
        case "year":
          label = appFormattedDateValue(dateClone, getDateLocale(), YEAR_DATE_FORMAT)
          break
        case "month":
          label = appFormattedDateValue(dateClone, getDateLocale(), "MMMM yyyy")
          break
        case "week":
          label = `${t("shared:picker.picked.week")} ${appFormattedDateValue(
            startOfWeek(dateClone, { locale: getDateLocale() }),
            getDateLocale(),
            "dd. MMM",
          )}`
          break
        default:
          label = appFormattedDateValue(dateClone, getDateLocale(), DAY_DATE_FORMAT)
          break
      }
      return label
    },
    [pickerType, t],
  )

  const RenderWeekPickerDay = (pickersDayProps: PickersDayProps<Date>) => {
    if (!date) {
      return <PickersDay {...pickersDayProps} />
    }
    const weekDay = pickersDayProps?.day

    const start = startOfWeek(date)
    const end = endOfWeek(date)

    const dayIsBetween = isWithinInterval(weekDay, { start, end })
    const isFirstDay = isSameDay(weekDay, start)
    const isLastDay = isSameDay(weekDay, end)

    return pickerType === "week" ? (
      <CustomPickersWeek
        {...pickersDayProps}
        disableMargin
        dayIsBetween={dayIsBetween}
        isFirstDay={isFirstDay}
        isLastDay={isLastDay}
      />
    ) : (
      <PickersDay {...pickersDayProps} disableMargin />
    )
  }

  return (
    <Grid container justifyContent="space-around">
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
        <MobileDatePicker<Date>
          openTo={openTo}
          open={openView}
          onAccept={() => {
            onChange(date)
            setOpenView(false)
          }}
          onClose={() => setOpenView(false)}
          value={date ?? null}
          onChange={(date: Date | null) => setDate(date)}
          maxDate={maxDate ? new Date(maxDate) : new Date()}
          views={views}
          slots={{
            textField: () => <DateOutput date={formattedDateValue(selectedDate)} onClick={() => setOpenView(true)} />,
            day: RenderWeekPickerDay,
          }}
          label={t("shared:picker.chooseDate")}
          localeText={{
            okButtonLabel: t("shared:label.ok"),
            cancelButtonLabel: t("shared:form.action.cancel"),
          }}
        />
      </LocalizationProvider>
    </Grid>
  )
}

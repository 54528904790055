import { TableCell } from "@mui/material/"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { useHistory } from "react-router-dom"

interface ContractsManagementBuildingTableInterface {
  itemConfig: BuildingAdminResponse
}

const ContractsManagementBuildingTableComponent = ({ itemConfig }: ContractsManagementBuildingTableInterface) => {
  const history = useHistory()
  return (
    <TableRowClickable<BuildingAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={({ id }) => {
        if (id) history.push(`/buildings/${id}`)
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
      <TableCell align="left">
        {itemConfig.address?.street} {itemConfig.address?.houseNumber}
      </TableCell>
      <TableCell align="left">
        {itemConfig.address?.postalCode} {itemConfig.address?.city}
      </TableCell>
    </TableRowClickable>
  )
}

export default ContractsManagementBuildingTableComponent

import { GetAreaHook } from "../CustomGetHooks"
import { AxiosResponse } from "axios"
import React from "react"
import { UseQueryResult } from "@tanstack/react-query"
import { getExactParamFromUrl, getTranslateValue, isInactiveStatus } from "../../../../Shared.Utils"
import RedirectButton from "../../../../../uikit/button/RedirectButton"
import SharedListComponent from "../../sharedListComponet/SharedList.Component"
import { COMPONENTS_CONFIG } from "../../sharedComponentsConfig/Components.Config"
import AreaDeleteComponent from "../../area/components/AreaDelete.Component"
import { AreaAdminResponse, AreaState } from "../../../../../data/generated-sources/openapi"
import AreaDeactivateComponent from "../../area/components/AreaDeactivate.Component"
import { AdminPortalRouteEnums } from "../../AdminPortal.Enums"

export const AREA_MASTER_DATA_CONFIG = {
  area: {
    name: getTranslateValue("area:label.name"),
    useQueryHook: (paramId: string) => GetAreaHook({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: "",
          getValue: (response: AxiosResponse<AreaAdminResponse>) => response.data.activeState,
        },
        secondColumn: {
          label: "",
          getComponent: (response: AxiosResponse<AreaAdminResponse>) => {
            return !isInactiveStatus(response?.data?.activeState as string) ? (
              <RedirectButton redirectUrl={`/area/${response.data.id}/update`} />
            ) : null
          },
        },
      },
      {
        firstColumn: {
          label: "",
          getComponent: (response: AxiosResponse<AreaAdminResponse>) => response.data.name,
        },
      },
      {
        firstColumn: {
          label: "",
        },
        row: {
          label: "",
          getComponent: (response: UseQueryResult<AreaAdminResponse>) => {
            if (response?.isSuccess && response?.data && response?.refetch) {
              return (
                <>
                  <AreaDeleteComponent area={response.data} />
                  <AreaDeactivateComponent area={response.data} refetch={() => response.refetch()} />
                </>
              )
            }
          },
        },
      },
    ],
    childrenWithResponse: (response: AxiosResponse<AreaAdminResponse>) => (
      <>
        <SharedListComponent
          configNew={{
            ...COMPONENTS_CONFIG.areaContracts,
            header: {
              ...COMPONENTS_CONFIG.areaContracts.header,
              buttonDisabled: response?.data?.activeState === AreaState.INACTIVE,
            },
          }}
          getParamFromUrl={() => getExactParamFromUrl("/area/:id", AdminPortalRouteEnums.id)}
        />
        <SharedListComponent
          configNew={{
            ...COMPONENTS_CONFIG.areaBuildings,
            header: {
              ...COMPONENTS_CONFIG.areaBuildings.header,
              buttonDisabled: response?.data?.activeState === AreaState.INACTIVE,
            },
          }}
          getParamFromUrl={() => getExactParamFromUrl("/area/:id", AdminPortalRouteEnums.id)}
        />
      </>
    ),
  },
}

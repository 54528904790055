import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import {
  ContractType,
  MeterReadingsCalcResolution,
  MeterReadingsUtilityUnitResponse,
  MoveinParticipationExistingRequest,
  MoveinWithNewParticipantCustomerRequest,
  UtilityUnitResponse,
} from "../../../../data/generated-sources/openapi"
import { timestampToApiFormattedDate } from "../../../Domain.Formatters"
import { customerGetParticipantBulkByIds } from "../participants/Participants.Repository"
import { utilityUnitsCustomBillingDetails } from "../billings/Billings.Mapper"
import { CustomBillingDetails } from "../../../../app/portal/manager/utilityUnit/components/billings/UtilityUnitInterfaces"
import { mapUnitParticipationParticipant } from "../../admin/utility-units/UtilityUnits.Repository"

export const customerGetUtilityUnitsByBuildingId = async (
  buildingId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<UtilityUnitResponse[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerBuildingApi.customerGetUtilityUnitsForBuilding(
    buildingId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const customerGetUtilityUnitById = async (utilityUnitId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerGetUtilityUnitById(
    utilityUnitId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetUtilityUnitParticipations = async (utilityUnitId: string) => {
  const { data: participations } =
    await DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerGetParticipationsByUtilityUnitId(
      utilityUnitId,
      1,
      undefined,
      undefined,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )

  const participantsIds: Array<string> = []

  participations.elements?.forEach((participation) => {
    if (participation?.participantId) participantsIds.push(participation?.participantId)
  })

  const participants = await customerGetParticipantBulkByIds(participantsIds)

  return mapUnitParticipationParticipant(participations?.elements, participants.elements)
}

export const customerMoveInUtilityUnitParticipantExisting = (
  utilityUnitId: string,
  participationId: string,
  moveInData: MoveinParticipationExistingRequest,
) => {
  return DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerMoveinParticipationExisting(
    utilityUnitId,
    participationId,
    moveInData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerMoveinWithNewParticipant = (
  utilityUnitId: string,
  participationId: string,
  payload: MoveinWithNewParticipantCustomerRequest,
) => {
  return DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerMoveinWithNewParticipant(
    utilityUnitId,
    participationId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerMoveoutUtilityUnitParticipationVacancy = async (
  utilityUnitId: string,
  participationId: string,
  moveOutDate: number,
) => {
  return DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerMoveoutParticipationVacancy(
    utilityUnitId,
    participationId,
    {
      moveoutDate: timestampToApiFormattedDate(moveOutDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerUtilityParticipationChangeMoveInDate = async (
  utilityUnitId: string,
  participationId: string,
  moveInDate: number,
) => {
  return DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerParticipationChangeMoveinDate(
    utilityUnitId,
    participationId,
    {
      moveinDate: timestampToApiFormattedDate(moveInDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerUtilityParticipationChangeMoveOutDate = (
  utilityUnitId: string,
  participationId: string,
  moveOutDate: number,
) => {
  return DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerParticipationChangeMoveoutDate(
    utilityUnitId,
    participationId,
    {
      moveoutDate: timestampToApiFormattedDate(moveOutDate, DOMAIN_DEPENDENCIES),
    },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerDeleteUtilityUnitParticipation = async (utilityUnitId: string, participationId: string) =>
  DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerParticipationStopMovein(
    utilityUnitId,
    participationId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerDeleteUtilityUnitParticipationMoveOut = async (utilityUnitId: string, participationId: string) =>
  DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerParticipationStopMoveout(
    utilityUnitId,
    participationId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerGetMetersForUtilityUnit = async (
  utilityUnitId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerGetMetersForUtilityUnit(
    utilityUnitId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const customerGetUtilityUnitBillingsById = async (utilityUnitId: string): Promise<CustomBillingDetails[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerGetUtilityUnitBillingsById(
    utilityUnitId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const billings: CustomBillingDetails[] = data.elements.flatMap((item) => {
    return utilityUnitsCustomBillingDetails(item)
  })

  return billings
}

export const updateMetersPricePackage = (meterId: string, pricePackageId: number) => {
  return DOMAIN_DEPENDENCIES.customerMeterApi.customerPutPricePackageId(
    meterId,
    { pricePackageId },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getMeterReadingForUtilityUnit = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  isFinalOnly: boolean,
  resolution: MeterReadingsCalcResolution,
  contractType: ContractType,
): Promise<MeterReadingsUtilityUnitResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerUtilityUnitApi.customerGetMeterReadingsByUtilityUnitId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    isFinalOnly,
    contractType,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

import { Box } from "@mui/material"
import { useAppSelector } from "../../../hooks"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import ContractSelector from "../contractSelector/ContractSelector"
import ContractAnalysis from "../../shared/analiyse/ContractAnalysis"

export const AnalysesDetailComponent = () => {
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  return (
    <>
      <ContractSelector />
      <Box pl={1}>
        <ContractAnalysis contractId={contractId} contractProductType={contractProductType} />
      </Box>
    </>
  )
}

import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import { AllParticipantsBilling, VewaAllParticipantsBilling } from "../../../../data/generated-sources/openapi"

export interface AllParticipantsBillingExtended extends AllParticipantsBilling {
  isNew: boolean
}

export interface VewaAllParticipantsBillingExtended extends VewaAllParticipantsBilling {
  isNew: boolean
}

export interface BillingsState {
  participantsBillings: AllParticipantsBillingExtended[]
  vewaParticipantsBillings: VewaAllParticipantsBillingExtended[]
}

const initialState: BillingsState = {
  participantsBillings: [],
  vewaParticipantsBillings: [],
}

export const billingsSlice = createSlice({
  name: "billings",
  initialState,
  reducers: {
    setAdminParticipantBillings: (
      state,
      { payload }: PayloadAction<{ participantsBillings: AllParticipantsBillingExtended[] }>,
    ) => {
      state.participantsBillings = payload.participantsBillings
    },
    setAdminVewaParticipantBillings: (
      state,
      { payload }: PayloadAction<{ vewaParticipantsBillings: VewaAllParticipantsBillingExtended[] }>,
    ) => {
      state.vewaParticipantsBillings = payload.vewaParticipantsBillings
    },
  },
})

export const { setAdminParticipantBillings, setAdminVewaParticipantBillings } = billingsSlice.actions

const billingsState = (state: RootState) => state.adminPortal.billings
export const selectAdminParticipantBillings = createSelector(billingsState, (state) => state.participantsBillings)
export const selectAdminVewaParticipantBillings = createSelector(
  billingsState,
  (state) => state.vewaParticipantsBillings,
)

export default billingsSlice.reducer

import { TFunction } from "i18next"

import { FieldTouched, validate, ValidField } from "../../../../Shared.Validation"

export const validateProfileAddressForm = {
  city: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("address.city"),
        })
      }
      return ValidField
    }, touched),
  street: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("address.street"),
        })
      }
      return ValidField
    }, touched),
  houseNumber: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("address.houseNumber"),
        })
      }
      return ValidField
    }, touched),
  postalCode: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("address.postalCode"),
        })
      }
      return ValidField
    }, touched),
}

import * as yup from "yup"
import { getTranslateValue } from "../../../../../../Shared.Utils"
import { ZevMeterType } from "../../../../../../../data/generated-sources/openapi"

export const mediumZevValidation = yup.object().shape({
  meters: yup.array().of(
    yup.object().shape({
      meteringCode: yup.string().required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("medium:table.header.deliveryId"),
        }),
      ),
      meterType: yup
        .mixed()
        .oneOf(Object.values(ZevMeterType))
        .required(() =>
          getTranslateValue("shared:validation.mandatory", {
            field: getTranslateValue("medium:label.deliveryType"),
          }),
        ),
      dateFrom: yup.string().required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("medium:table.header.startDate"),
        }),
      ),
      dateTo: yup.string().optional(),
    }),
  ),
  municipality: yup.string().required(),
})

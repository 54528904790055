import { BuildingAdminResponse, PagedBuildingAdminResponse } from "../../../../data/generated-sources/openapi"
import { IBuildingAdminResponse } from "./Buildings.Model"
import { AreaAdminResponse } from "../../../../data/generated-sources/openapi/api"

export const mapBuildingListItem = (
  building: BuildingAdminResponse,
  areas: AreaAdminResponse[],
): IBuildingAdminResponse => {
  const getAreaName = () => {
    const matchingArea = areas?.find((area) => area?.id === building?.areaId)
    return matchingArea?.name ?? ""
  }

  return {
    ...building,
    fullAddress:
      `${building.address.street} ${building.address.houseNumber} ` +
      `${building.address.postalCode} ${building.address.city}`,
    areaName: getAreaName(),
  }
}

export const buildingsListMapper = (
  buildings: PagedBuildingAdminResponse,
  areas: AreaAdminResponse[],
): IBuildingAdminResponse[] => {
  return buildings.elements.map((building) => mapBuildingListItem(building, areas))
}

import { useQuery } from "@tanstack/react-query"
import { useEffect } from "react"
import { Loader } from "../../../../../uikit/indicator/ProgressIndicator"
import { DataItemBoxNoValue } from "../../../../../uikit/box/DataItemBox"
import { Caption } from "../../../../../uikit/typography/Typography"
import { appThemeGreyNewDesign } from "../../../../Shared.Theme"
import { Stack } from "@mui/material"
import { useTranslation } from "react-i18next"

interface GetSpecialValueComponentPropsInterface {
  // eslint-disable-next-line
  getMethod: () => any
  propertyName: string
  uniqueKey: string | number
  label?: string
}

const GetSpecialValueComponent = (props: GetSpecialValueComponentPropsInterface) => {
  const { getMethod, propertyName, uniqueKey, label } = props
  const { t } = useTranslation()
  const { data, isError, isLoading, isSuccess, remove } = useQuery(["getSpecialValue", uniqueKey], () => getMethod())

  useEffect(() => {
    return () => remove()
  }, [])

  // done like this, because BE returning different structure of response.
  const renderCorrectData = () => {
    // eslint-disable-next-line no-prototype-builtins
    if (data && data?.hasOwnProperty("data")) return data.data[propertyName]
    else return data[propertyName]
  }

  if (isLoading) return <Loader height={30} justify="start" align="center" size={24} />

  return (
    <Stack>
      {label && <Caption sx={{ color: appThemeGreyNewDesign }}>{t(`${label}`)}</Caption>}
      {isError && <DataItemBoxNoValue />}
      {isSuccess && renderCorrectData()}
    </Stack>
  )
}

export default GetSpecialValueComponent

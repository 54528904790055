import { CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { customerGetContactByContractId } from "../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ContactData, ContractAddressData } from "../../../../../data/generated-sources/openapi"
import { OnboardingPagesProps } from "../Onboarding"

import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import { OnboardingContactInfoForm } from "./OnboardingContactInfoForm"
import { OnboardingContactDetailsForm } from "./OnboardingContactDetailsForm"
import { OnboardingContactAddressForm } from "./OnboardingContactAddressForm"
import { OnboardingActionButtons, OnboardingSteperInfo } from "../fragments/OnboardingUI"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

export interface OnboardingContactInfoFormProps {
  contractId: string
  contact: ContactData
  addressData: ContractAddressData
  onSuccessUpdate: () => void
  onErrorUpdate: (error: AxiosErrorDataType) => void
}

export const OnboardingContactInfo = ({ contractId, onBackClick, onNextClick }: OnboardingPagesProps) => {
  const { t } = useTranslation("onboarding")
  const [contractError, setContractError] = useState<AxiosErrorDataType>()
  const [showSuccess, setShowSuccess] = useState(false)

  const {
    data: contactData,
    isLoading: isContactLoading,
    isFetching: isContactFetching,
    refetch,
    remove: removeContact,
  } = useQuery(["getContractContact"], () => customerGetContactByContractId(contractId), {
    enabled: !!contractId,
    onError: setContractError,
  })

  useEffect(() => {
    return () => {
      removeContact()
    }
  }, [removeContact])

  const handleSuccess = () => {
    setContractError(undefined)
    refetch()
    setShowSuccess(true)
  }

  const errorHandler = (error: AxiosErrorDataType) => {
    setContractError(error)
    setShowSuccess(false)
  }

  return (
    <>
      <OnboardingSteperInfo stepNumber={"1"} title={t("contactInfo.title")} />
      <OptionalSuccessAlert show={showSuccess} scrollOnDisplay />
      <ErrorAlert
        scrollOnDisplay
        visible={!!contractError}
        title={contractError?.code && t(`error-codes:${contractError?.code}`)}
        message={t(`error-codes:${contractError?.response?.data?.code || contractError?.code || "OTHER"}`)}
      />
      {isContactLoading && isContactFetching && <CircularProgress />}
      {contactData && (
        <>
          <OnboardingContactDetailsForm
            {...contactData}
            contractId={contractId}
            onSuccessUpdate={handleSuccess}
            onErrorUpdate={errorHandler}
          />
          <OnboardingContactAddressForm
            {...contactData}
            contractId={contractId}
            onSuccessUpdate={handleSuccess}
            onErrorUpdate={errorHandler}
          />
          <OnboardingContactInfoForm
            {...contactData}
            contractId={contractId}
            onSuccessUpdate={handleSuccess}
            onErrorUpdate={errorHandler}
          />
        </>
      )}
      <OnboardingActionButtons onBackClick={onBackClick} onNextClick={onNextClick} />
    </>
  )
}

import {
  Stack,
  Typography,
  ListItem,
  ListItemText,
  Autocomplete,
  AutocompleteRenderInputParams,
  TextField as MuiTextField,
  styled,
  Paper,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useLocation, withRouter } from "react-router-dom"
import { RemoveIcon, SaveIcon } from "../../../../../uikit/Shared.Icon"
import { useEffect, useState } from "react"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { PrimaryButton } from "../../../../../uikit/button"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getBuildingsByContractId,
  updateContractBuildingList,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"
import { getAreaBuildings } from "../../../../../domain/portal/admin/areas/Areas.Repository"

const TextField = styled(MuiTextField)(({ theme }) => ({
  "& label": {
    fontSize: theme.typography.pxToRem(12),
    color: theme.palette.secondary.dark,
    p: 2,
  },
}))

const EditBuildingsComponent = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("buildings")
  const { history, match } = props
  const [buildingsList, setBuildingList] = useState<BuildingAdminResponse[]>([])
  const [buildingError, setBuildingError] = useState<AxiosErrorDataType>()
  const { search } = useLocation()
  const areaId = new URLSearchParams(search).get("areaId")

  const {
    data: allBuildingsData,
    isLoading: allBuildingsIsLoading,
    isError: allBuildingsIsError,
    remove: allBuildingsRemove,
  } = useQuery(["getAreaBuildings"], () => getAreaBuildings(areaId ?? ""), {
    enabled: !!areaId,
    ...useQueryDefaultOptions,
  })

  const {
    isLoading: contractBuildingsIsLoading,
    isError: contractBuildingsIsError,
    remove: contractBuildingsRemove,
  } = useQuery(["getBuildingsByContractId"], () => getBuildingsByContractId(match.params.id, 1, 1000, ""), {
    enabled: !!match.params.id,
    ...useQueryDefaultOptions,
    onError: setBuildingError,
    onSuccess: (data) => setBuildingList(data?.data.elements),
  })

  const {
    mutate: updateBuildings,
    isLoading: updateBuildingsIsLoading,
    isSuccess: updateBuildingsIsSuccess,
    isError: updateBuildingsIsError,
    reset: updateBuildingsReset,
  } = useMutation(
    ["updateContractBuildingsList"],
    () =>
      updateContractBuildingList(match.params.id, {
        buildingIds: buildingsList.map((item: BuildingAdminResponse) => item.id),
      }),
    {
      onSuccess: () => history.push(`/management-contracts/${match.params.id}`),
      onError: setBuildingError,
    },
  )

  useEffect(() => {
    return () => {
      updateBuildingsReset()
      contractBuildingsRemove()
      allBuildingsRemove()
    }
  }, [updateBuildingsReset, contractBuildingsRemove, allBuildingsRemove])

  const getBuildingName = (building: BuildingAdminResponse): string => `${building.name},
  ${building.address.street} ${building.address.houseNumber},
  ${building.address.postalCode} ${building.address.city}`

  const renderBuildingList = () => {
    if (!buildingsList?.length)
      return (
        <Typography variant="body2" color="textSecondary">
          {t("buildings:label.noBuildings")}
        </Typography>
      )

    return buildingsList?.map((item: BuildingAdminResponse, index: number) => {
      return (
        <ListItem
          key={index}
          sx={{
            borderBottom: "1px solid #E5E5E5",
            padding: "15px",
            cursor: "pointer",
            mb: 2,
            "&:hover": {
              backgroundColor: "#F1F1F1",
            },
          }}
        >
          <ListItemText primary={getBuildingName(item)} />
          <RemoveIcon
            color="primary"
            sx={{
              cursor: "pointer",
              "&:hover": {
                opacity: 0.7,
              },
            }}
            onClick={() => {
              const newList = buildingsList.filter((building: BuildingAdminResponse) => building.id !== item.id)
              setBuildingList(newList)
            }}
          />
        </ListItem>
      )
    })
  }

  const renderAutofillInput = () => {
    return (
      <Autocomplete
        id="ckw-search-buildings"
        options={allBuildingsData?.data.elements ?? []}
        disabled={!allBuildingsData?.data.elements.length || allBuildingsIsError}
        loading={allBuildingsIsLoading}
        getOptionLabel={(option: BuildingAdminResponse) => getBuildingName(option)}
        clearOnBlur={true}
        fullWidth={true}
        sx={{ mb: 2, mt: 2 }}
        onChange={(e, value) => {
          if (
            value &&
            value?.id &&
            !buildingsList.find((building: BuildingAdminResponse) => building.id === value.id)
          ) {
            setBuildingList([...buildingsList, value])
          }
        }}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField {...params} variant={"standard"} label={t("label.searchBuildings")} />
        )}
      />
    )
  }

  return (
    <>
      <Paper>
        {(contractBuildingsIsLoading || allBuildingsIsLoading) && <FullScreenLoader />}
        <Typography variant="h4" fontFamily="Montserrat" fontWeight="lighter" mb={3}>
          {t("list.title")}
        </Typography>
        <ErrorAlert
          visible={contractBuildingsIsError || updateBuildingsIsError}
          message={t(`error-codes:${buildingError?.response?.data?.code || "GENERIC_FATAL_ERROR"}`)}
        />
        <OptionalSuccessAlert
          show={updateBuildingsIsSuccess}
          message={t("contracts-management:contract.updateBuilding.success")}
        />
        {renderAutofillInput()}
        {renderBuildingList()}
        <Stack direction={"row"} alignItems="center" justifyContent="space-between" mt={3}>
          <CancelButton onClick={() => history.goBack()} />
          <PrimaryButton
            isLoading={updateBuildingsIsLoading}
            disabled={updateBuildingsIsLoading}
            startIcon={<SaveIcon />}
            onClick={() => updateBuildings()}
            label={t("label.saveBuildings")}
          />
        </Stack>
      </Paper>
    </>
  )
})

export default EditBuildingsComponent

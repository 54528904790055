import { TableCell } from "@mui/material/"
import { useHistory } from "react-router-dom"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { ServiceBillingAdminResponse } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"

interface ServiceBillingTableInterface {
  itemConfig: ServiceBillingAdminResponse
  isInitialBilling: boolean
}

const ServiceBillingTableComponent = ({ itemConfig, isInitialBilling }: ServiceBillingTableInterface) => {
  const history = useHistory()
  return (
    <TableRowClickable<ServiceBillingAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(param) => {
        if (param.id) {
          history.push(
            `/billings/${isInitialBilling ? "initial" : "recurring"}/details/${
              isInitialBilling ? param.id : param?.billingRunId
            }`,
          )
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig?.invoiceReferenceNumber}</TableCell>
      <TableCell align="left">{itemConfig?.totalAmountDue}</TableCell>
      <TableCell align="left">{DateUtils.getDeFormatDateWithMonthString(itemConfig.billingDate)}</TableCell>
    </TableRowClickable>
  )
}

export default ServiceBillingTableComponent

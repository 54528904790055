import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { Button, Drawer, Grid, IconButton, Typography } from "@mui/material"
import { FormSectionTitleIconTrailing } from "../../../../uikit/form/FormView"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { ErrorAlert, SuccessAlert } from "../../../../uikit/Shared.Alert"
import { ContactIcon } from "../../../../uikit/Shared.Icon"

import { firstViewState } from "../../../Shared.Reducer"
import { coerce } from "../../../Shared.View"
import { AdminPortalRouteParams } from "../../admin/AdminPortal.Routes"
import { mapDispatchToProps } from "./ProfileDetails.Connect"
import { ProfileDetailState } from "./ProfileDetails.Reducer"
import { Formik, Form } from "formik"
import { ProfileNameForm } from "./forms/ProfileNameForm"
import { ProfileAddressForm } from "./forms/ProfileAddressForm"
import { ProfileContactForm } from "./forms/ProfileContactForm"
import { ProfileEmailForm } from "./forms/ProfileEmailForm"
import { TinyPaddedBox } from "../../../../uikit/box/PaddedBox"
import { ProfileFooterView } from "../../../appcontainer/views/footer/ProfileFooterView"

interface ProfileDetailsComponentProps
  extends ProfileDetailState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {
  open: boolean
  areaId: string
  closeDrawer: () => void
  logout: () => void
}

export const ProfileDetailComponent = withRouter((props: ProfileDetailsComponentProps) => {
  const { t } = useTranslation("profile")
  const {
    open,
    closeDrawer,
    logout,
    viewState,
    updatePersonalViewState,
    updateAddressViewState,
    updateContactViewState,
    updateEmailViewState,
    getCustomerProfile,
    updateCustomerProfileAddress,
    updateCustomerProfileContact,
    updateCustomerProfilePersonal,
    updateCustomerProfileEmail,
    navigateResetPassword,
    areaId,
  } = props

  const [shouldDisplayProfile, setShouldDisplayProfile] = useState(open)

  const [isNameEditable, setIsNameEditable] = useState(false)
  const [isAddressEditable, setIsAddressEditable] = useState(false)
  const [isContactEditable, setIsContactEditable] = useState(false)
  const [isEmailEditable, setIsEmailEditable] = useState(false)

  useEffect(() => {
    if (firstViewState(viewState)) {
      getCustomerProfile()
    }
  }, [viewState, getCustomerProfile])

  useEffect(() => {
    if (updatePersonalViewState.domainResult) {
      setIsNameEditable(false)
      getCustomerProfile()
    }
  }, [updatePersonalViewState, getCustomerProfile])

  useEffect(() => {
    if (updateAddressViewState.domainResult) {
      setIsAddressEditable(false)
      getCustomerProfile()
    }
  }, [updateAddressViewState, getCustomerProfile])

  useEffect(() => {
    if (updateContactViewState.domainResult) {
      setIsContactEditable(false)
      getCustomerProfile()
    }
  }, [updateContactViewState, getCustomerProfile])

  useEffect(() => {
    if (updateEmailViewState.domainResult) {
      setIsEmailEditable(false)
      getCustomerProfile()
    }
  }, [updateEmailViewState, getCustomerProfile])

  const onClickAway = (event: React.KeyboardEvent | React.MouseEvent) => {
    if (
      event.type === "keydown" &&
      ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
    ) {
      return
    }

    setShouldDisplayProfile(false)
    closeDrawer()
  }

  return (
    <Drawer anchor={"right"} open={shouldDisplayProfile} onClose={onClickAway}>
      <Grid container direction="column" wrap="nowrap" p={2.5} maxWidth={415}>
        <Grid item>
          <Grid container direction="row" justifyContent="flex-end">
            <Grid item style={{ flex: 1 }} py={2}>
              <Typography variant="h4" component="div" fontWeight={300} sx={{ textTransform: "uppercase" }}>
                {t("profile title")}
              </Typography>
            </Grid>
            <Grid item>
              <Button variant="text" style={{ textTransform: "capitalize" }} onClick={() => logout()}>
                <span>{t("logout")}</span>
              </Button>
              <IconButton aria-label={t("navigation:profile of loggedin")} onClick={onClickAway} color={"primary"}>
                <ContactIcon />
              </IconButton>
            </Grid>
          </Grid>
          {viewState.isLoading && <ProgressIndicator />}
          {viewState.domainError && <ErrorAlert message={viewState.domainError.message} />}
          {updatePersonalViewState.domainResult && <SuccessAlert message={t("personal update successful")} />}
          {coerce(viewState.domainResult, (profileInfo) => (
            <>
              <FormSectionTitleIconTrailing
                label={t("subtitle.personal")}
                editMode={isNameEditable}
                editModeClick={() => setIsNameEditable((toggleEditState) => !toggleEditState)}
              />
              <Formik
                initialValues={profileInfo.personal}
                onSubmit={(values) => {
                  updateCustomerProfilePersonal(values)
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {updatePersonalViewState.domainError && (
                      <ErrorAlert message={updatePersonalViewState.domainError.message} />
                    )}
                    <ProfileNameForm
                      editMode={isNameEditable}
                      profileNameValues={values}
                      dataChanged={(values) => setFieldValue("personal", values.personal)}
                      isLoading={updatePersonalViewState.isLoading}
                    />
                  </Form>
                )}
              </Formik>

              <FormSectionTitleIconTrailing
                label={t("subtitle.address")}
                editMode={isAddressEditable}
                editModeClick={() => setIsAddressEditable((toggleEditState) => !toggleEditState)}
              />
              <Formik
                initialValues={profileInfo.address}
                onSubmit={(values) => {
                  updateCustomerProfileAddress(values)
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {updateAddressViewState.domainError && (
                      <ErrorAlert message={updateAddressViewState.domainError.message} />
                    )}
                    <ProfileAddressForm
                      editMode={isAddressEditable}
                      profileAddressValues={values}
                      dataChanged={(values) => setFieldValue("address", values.address)}
                      isLoading={updateAddressViewState.isLoading}
                    />
                  </Form>
                )}
              </Formik>

              <FormSectionTitleIconTrailing
                label={t("subtitle.contact")}
                editMode={isContactEditable}
                editModeClick={() => setIsContactEditable((toggleEditState) => !toggleEditState)}
              />
              <Formik
                initialValues={profileInfo.contact}
                onSubmit={(values) => {
                  updateCustomerProfileContact(values.contact)
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {updateContactViewState.domainError && (
                      <ErrorAlert message={updateContactViewState.domainError.message} />
                    )}
                    <ProfileContactForm
                      editMode={isContactEditable}
                      profileContactValues={values}
                      dataChanged={(values) => setFieldValue("contact", values.contact)}
                      isLoading={updateContactViewState.isLoading}
                    />
                  </Form>
                )}
              </Formik>

              <FormSectionTitleIconTrailing
                label={t("subtitle.email")}
                editMode={isEmailEditable}
                editModeClick={() => setIsEmailEditable((toggleEditState) => !toggleEditState)}
              />
              <Formik
                initialValues={profileInfo.email}
                onSubmit={(values) => {
                  updateCustomerProfileEmail(values.contact)
                }}
              >
                {({ values, setFieldValue, handleSubmit }) => (
                  <Form onSubmit={handleSubmit}>
                    {updateEmailViewState.domainError && (
                      <ErrorAlert message={updateEmailViewState.domainError.message} />
                    )}
                    <ProfileEmailForm
                      editMode={isEmailEditable}
                      profileEmailValues={values}
                      dataChanged={(values) => {
                        setFieldValue("contact", values.contact)
                      }}
                      isLoading={updateEmailViewState.isLoading}
                    />
                  </Form>
                )}
              </Formik>
              <TinyPaddedBox mt={4}>
                <Typography variant="h6">{t("subtitle.password")}</Typography>
                <span>*******</span>
              </TinyPaddedBox>
              <Button variant="text" onClick={() => navigateResetPassword()}>
                <span>{t("password change")}</span>
              </Button>
            </>
          ))}
        </Grid>
        <ProfileFooterView areaId={areaId} />
      </Grid>
    </Drawer>
  )
})

import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { chfMoneyLabelComparator, DefaultCell } from "../../../../../../uikit/dataGridTable"
import { getTranslateValue } from "../../../../../Shared.Utils"
import { formatMoneyLabel } from "../../../../../../domain/Domain.Formatters"

export const RecurringBillingPositionsTableColumns: Array<GridBaseColDef> = [
  {
    flex: 200,
    field: "name",
    headerName: getTranslateValue("billings-recurring:field.label.position.name"),
    renderCell: DefaultCell,
  },
  {
    flex: 100,
    field: "quantity",
    headerName: getTranslateValue("billings-recurring:field.label.position.quantity"),
    renderCell: DefaultCell,
  },
  {
    flex: 100,
    field: "price",
    headerName: getTranslateValue("billings-recurring:field.label.position.price"),
    renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={formatMoneyLabel(value)} />,
    sortComparator: chfMoneyLabelComparator,
  },
  {
    flex: 100,
    field: "finalAmountDue",
    headerName: getTranslateValue("billings-recurring:field.label.position.total"),
    renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={formatMoneyLabel(value)} />,
    sortComparator: chfMoneyLabelComparator,
  },
]

import { PeriodRowI } from "../TariffModels"
import { Grid, IconButton, TextField, Typography } from "@mui/material"
import { daysOfWeek } from "../../../../../services/utils/Tariffs.Utils"
import DeleteIcon from "@mui/icons-material/Delete"
import React from "react"
import DaysOfWeekCheckbox from "./DaysOfWeekCheckbox"

const TariffSchedulePeriodRow = ({ period, onDelete, setPeriods }: PeriodRowI) => (
  <Grid container spacing={2} alignItems="center" mt={2}>
    <Grid item xs={2}>
      <Typography pl={2}>{`Periode ${period.name}`}</Typography>
    </Grid>
    <Grid item xs={4}>
      <Grid container spacing={1}>
        {daysOfWeek.map((day: string) => (
          <DaysOfWeekCheckbox
            key={period.name + day}
            day={day}
            checked={period.days.includes(day)}
            onChange={() => {
              const newDays = period.days.includes(day) ? period.days.filter((d) => d !== day) : [...period.days, day]
              setPeriods({ ...period, days: newDays })
            }}
          />
        ))}
      </Grid>
    </Grid>
    <Grid item xs={2}>
      <TextField
        name="StartTime"
        type="time"
        fullWidth
        sx={{
          width: "100%",
          ".MuiInputBase-root": { borderRadius: 2 },
        }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900,
        }}
        value={period.startTime ?? "00:00"}
        onChange={(e) => {
          setPeriods({ ...period, startTime: e.target.value })
        }}
      />
    </Grid>
    <Grid item xs={2}>
      <TextField
        name="EndTime"
        type="time"
        fullWidth
        sx={{ ".MuiInputBase-root": { borderRadius: 2 } }}
        InputLabelProps={{
          shrink: true,
        }}
        inputProps={{
          step: 900,
        }}
        value={period.endTime ?? "07:00"}
        onChange={(e) => {
          setPeriods({ ...period, endTime: e.target.value })
        }}
      />
    </Grid>
    <Grid item xs={1}>
      <IconButton onClick={onDelete}>
        <DeleteIcon />
      </IconButton>
    </Grid>
  </Grid>
)

export default TariffSchedulePeriodRow

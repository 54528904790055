import { TFunction } from "i18next"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridRenderCellParams } from "@mui/x-data-grid"
import DownloadBill from "./DownloadBill"
import BillNumber from "./BillNumber"
import { dateSortComparator, DefaultCell } from "../../../../../../uikit/dataGridTable"
import { Typography } from "@mui/material"

export const UtilityUnitBillingsTableConfig: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "name",
    headerName: t("manager-utility-unit:table.header.billNumber"),
    flex: 120,
    renderCell: (params: GridRenderCellParams) => (
      <BillNumber name={params.row?.billDetails?.billNumber} isNew={params.row.isNew} />
    ),
  },
  {
    field: "period",
    headerName: t("shared:label.period"),
    sortComparator: (v1, v2) => dateSortComparator(v1?.startDate, v2?.startDate, v1?.startDate, v2?.startDate),
    valueGetter: (_, row) => row?.period || "-",
    renderCell: DefaultCell,
    flex: 250,
  },
  {
    field: "participant",
    headerName: t("manager-utility-unit:table.header.participant"),
    flex: 200,
    valueGetter: (_, row) => row?.participantName || "-",
    renderCell: DefaultCell,
  },
  {
    field: "sum",
    headerName: t("manager-utility-unit:table.header.sum"),
    valueGetter: (_, row) => row?.totalSum ?? "-",
    renderCell: DefaultCell,
    flex: 100,
  },
  {
    field: "status",
    headerName: t("manager-utility-unit:table.header.status"),
    valueGetter: (_, row) => t(`shared:status.${row?.billingStatus}`).toLowerCase(),
    renderCell: ({ value }) => (
      <Typography fontWeight={500} fontSize={14} sx={{ textTransform: "capitalize" }}>
        {value}
      </Typography>
    ),
    flex: 120,
  },
  {
    field: "act",
    headerName: "",
    renderCell: (params: GridRenderCellParams) => {
      if (params.row.hasCollection || params.row.billingStatus !== "DONE") return null
      return <DownloadBill id={params.row?.billDetails?.id} />
    },
    flex: 50,
  },
]

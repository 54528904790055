import { useTranslation } from "react-i18next"
import { Typography, Stack, CircularProgress, Tooltip, Box, InputAdornment } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { omit } from "lodash-es"
import { useCallback, useEffect, useState } from "react"
import { Form, Formik } from "formik"
import { ZevPricePackage } from "../../../../data/generated-sources/openapi"
import {
  customerGetPricePackagesByContractId,
  customerUpdateContractPricePackageById,
} from "../../../../domain/portal/manager/contracts/Contract.Repository"

import { OnboardingActionButtons, OnboardingSteperInfo } from "./fragments/OnboardingUI"
import { InfoIconOutlined } from "../../../../uikit/Shared.Icon"
import { SwitchToggleView } from "../../../../uikit/toggle/SwitchToggleView"
import { CURRENCY_ADORNMENT } from "../../../../uikit/input/CurrencyAdornment"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { ExtendedZevPricePackage } from "../managerSettings/components/electricity/SettingsElectricityTab"
import { initialZevPriceItem } from "../../../../domain/portal/admin/prices/Prices.Model"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { pricePackageSchema } from "../../shared/electricityPrices/ElectricityPricePackageForm"

interface OnboardingElectricityPricesInfoProps {
  contractId: string
  onBackClick: () => void
  onNextClick: () => void
}

export const OnboardingElectricityPricesInfo = ({
  contractId,
  onBackClick,
  onNextClick,
}: OnboardingElectricityPricesInfoProps) => {
  const { t } = useTranslation("contracts-management")
  const [updateError, setUpdateError] = useState<AxiosErrorDataType | null>(null)

  const {
    isLoading,
    isFetching,
    remove: removePackages,
    data: pricePackages,
  } = useQuery(["getPricePackages"], () => customerGetPricePackagesByContractId(contractId), {
    enabled: !!contractId,
    onError: setUpdateError,
  })

  const { mutate: updatePricePackage, isLoading: isUpdatingPackage } = useMutation(
    ["updatePricePackage"],
    (updateData: ZevPricePackage) => customerUpdateContractPricePackageById(contractId, updateData.id, updateData),
    {
      onSuccess: onNextClick,
      onError: setUpdateError,
    },
  )

  useEffect(() => {
    return () => removePackages()
  }, [removePackages])

  const handleUpdate = (packageData: ZevPricePackage) => {
    const standardPrice = omit(packageData, "hasSpikePrice")
    updatePricePackage(standardPrice)
  }

  const standardPackage = pricePackages?.find((pack) => pack.id === 0)

  const InfoTooltip = useCallback(
    ({ tooltipText }: { tooltipText: string }) => (
      <Tooltip
        title={tooltipText}
        placement={"top-start"}
        arrow
        componentsProps={{
          tooltip: {
            sx: {
              p: 2,
              fontSize: 14,
              lineHeight: 1.6,
            },
          },
        }}
      >
        <InputAdornment position="end">
          <InfoIconOutlined color="primary" />
        </InputAdornment>
      </Tooltip>
    ),
    [],
  )

  return (
    <>
      <OnboardingSteperInfo
        stepNumber={"3"}
        title={t("onboarding:electricityPricesInfo.title")}
        subTitle={t("onboarding:electricityPricesInfo.subTitle1")}
      />
      <Box py={3}>
        <ErrorAlert
          visible={!!updateError}
          message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
        />
      </Box>
      {isLoading && isFetching ? (
        <CircularProgress />
      ) : (
        <Formik<ExtendedZevPricePackage>
          validateOnBlur
          validateOnChange
          enableReinitialize
          onSubmit={handleUpdate}
          initialValues={{
            ...initialZevPriceItem,
            ...standardPackage,
            hasSpikePrice: !!standardPackage?.prices?.spikePrice,
          }}
          validationSchema={pricePackageSchema}
        >
          {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue }) => {
            const touchedFileds = Object.keys(touched)
            const errorsFileds = Object.keys(errors?.prices ?? {})

            const onSubmit = () => (dirty ? handleUpdate(values) : onNextClick())

            return (
              <Form onSubmit={onSubmit}>
                <Stack spacing={4} alignItems={"flex-end"} width={"50%"} mb={3}>
                  <SingleLineTextField
                    id="solarPrice"
                    name="solarPrice"
                    label={t("priceForm.solarPrice")}
                    value={values.prices?.solarPrice}
                    onBlur={handleBlur}
                    onChange={({ target }) => setFieldValue("prices.solarPrice", target.value)}
                    helperText={
                      touchedFileds.includes("solarPrice") && errorsFileds.includes("solarPrice")
                        ? t("shared:validation.valid.number")
                        : undefined
                    }
                    InputProps={{
                      ...CURRENCY_ADORNMENT,
                      endAdornment: <InfoTooltip tooltipText={t("onboarding:tooltip.solarPower")} />,
                    }}
                  />
                  <SingleLineTextField
                    id="highTariffPrice"
                    name="highTariffPrice"
                    label={t("priceForm.highTariffPrice")}
                    value={values.prices?.highTariffPrice}
                    onBlur={handleBlur}
                    onChange={({ target }) => setFieldValue("prices.highTariffPrice", target.value)}
                    helperText={
                      touchedFileds.includes("highTariffPrice") && errorsFileds.includes("highTariffPrice")
                        ? t("shared:validation.valid.number")
                        : undefined
                    }
                    InputProps={{
                      ...CURRENCY_ADORNMENT,
                      endAdornment: <InfoTooltip tooltipText={t("onboarding:tooltip.netzstrom")} />,
                    }}
                  />
                  <SingleLineTextField
                    id="lowTariffPrice"
                    name="lowTariffPrice"
                    label={t("priceForm.lowTariffPrice")}
                    value={values.prices?.lowTariffPrice}
                    onBlur={handleBlur}
                    onChange={({ target }) => setFieldValue("prices.lowTariffPrice", target.value)}
                    helperText={
                      touchedFileds.includes("lowTariffPrice") && errorsFileds.includes("lowTariffPrice")
                        ? t("shared:validation.valid.number")
                        : undefined
                    }
                    InputProps={{
                      ...CURRENCY_ADORNMENT,
                      endAdornment: <InfoTooltip tooltipText={t("onboarding:tooltip.niedertarif")} />,
                    }}
                  />
                  <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
                    <Typography>{t("priceForm.consumptionDependant")}</Typography>
                    <SwitchToggleView
                      label={t("priceForm.flatRate")}
                      checked={values.prices?.isFixedRate}
                      onChange={(checked) => setFieldValue("prices.isFixedRate", checked)}
                    />
                  </Stack>
                  <SingleLineTextField
                    id="additionalServicesPrice"
                    name="additionalServicesPrice"
                    label={
                      values.prices?.isFixedRate ? t("priceForm.monthlyMeasurement") : t("priceForm.kwhMeasurement")
                    }
                    value={values.prices?.additionalServicesPrice}
                    onBlur={handleBlur}
                    onChange={({ target }) => setFieldValue("prices.additionalServicesPrice", target.value)}
                    helperText={
                      touchedFileds.includes("additionalServicesPrice") &&
                      errorsFileds.includes("additionalServicesPrice")
                        ? t("shared:validation.valid.number")
                        : undefined
                    }
                    InputProps={{
                      ...CURRENCY_ADORNMENT,
                      endAdornment: <InfoTooltip tooltipText={t("onboarding:tooltip.measurement")} />,
                    }}
                  />
                  <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
                    <Typography>{t("priceForm.withoutSpike")}</Typography>
                    <SwitchToggleView
                      label={t("priceForm.withSpike")}
                      checked={values.hasSpikePrice}
                      onChange={(checked) => {
                        if (!checked) {
                          setFieldValue("prices.spikePrice", undefined)
                        }
                        setFieldValue("hasSpikePrice", checked)
                      }}
                    />
                  </Stack>
                  {values.hasSpikePrice && (
                    <SingleLineTextField
                      id="spikePrice"
                      name="spikePrice"
                      label={t("priceForm.spikePrice")}
                      value={values.prices?.spikePrice}
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("prices.spikePrice", target.value)}
                      helperText={
                        touchedFileds.includes("spikePrice") && errorsFileds.includes("spikePrice")
                          ? t("shared:validation.valid.number")
                          : undefined
                      }
                      InputProps={{
                        ...CURRENCY_ADORNMENT,
                        endAdornment: <InfoTooltip tooltipText={t("onboarding:tooltip.performancePrice")} />,
                      }}
                    />
                  )}
                </Stack>
                <OnboardingActionButtons
                  onNextClick={onSubmit}
                  onBackClick={onBackClick}
                  isNextBtnLoading={isUpdatingPackage}
                  nextBtnDisabled={isUpdatingPackage || !isValid}
                />
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

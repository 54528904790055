import { useState } from "react"
import { DialogContent, DialogActions } from "@mui/material"
import { useParams } from "react-router"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import { ParticipantCustomerCreateRequest } from "../../../../../data/generated-sources/openapi"
import { ParticipantRegisterSchema } from "../ParticipantsValidation.Schema"
import { ParticipantCreateUpdateFormFields } from "./ParticipantCreateUpdateFormFields"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { PrimaryButton } from "../../../../../uikit/button"
import { managerCreateAreaParticipant } from "../../../../../domain/portal/manager/participants/Participants.Repository"
import { initialParticipantValues } from "../../../../../domain/participant/Participant.Model"
import { AxiosErrorDataType, getParamFromResponseUrl } from "../../../../Shared.Utils"

interface ParticipantCreateFormProps {
  onClose: () => void
  onCreateSuccess: (participantId: string) => void
}

export const ParticipantRegisterForm = ({ onClose, onCreateSuccess }: ParticipantCreateFormProps) => {
  const { t } = useTranslation("customer-participants")
  const [registerError, setRegisterError] = useState<AxiosErrorDataType>()
  const { areaId } = useParams<{ areaId: string }>()

  const handleCreateSuccess = (url: string) => {
    const createdParticipantId = getParamFromResponseUrl(url, "participants/")
    onCreateSuccess && onCreateSuccess(createdParticipantId)
    onClose()
  }

  const { mutate: registerUser, isLoading } = useMutation(
    ["registerUser", areaId],
    (createData: ParticipantCustomerCreateRequest) => managerCreateAreaParticipant(areaId, createData),
    {
      onSuccess: ({ data }) => handleCreateSuccess(data),
      onError: setRegisterError,
    },
  )

  const handleOnSubmit = ({
    gpType,
    contactDetails,
    personalData,
    domicileAddress,
  }: ParticipantCustomerCreateRequest) => {
    if (!gpType || !domicileAddress || !personalData) return

    registerUser({
      gpType,
      personalData,
      domicileAddress,
      contactDetails: {
        ...initialParticipantValues.contactDetails,
        ...contactDetails,
      },
    })
  }

  return (
    <Formik<ParticipantCustomerCreateRequest>
      initialValues={initialParticipantValues}
      validationSchema={ParticipantRegisterSchema}
      onSubmit={handleOnSubmit}
    >
      {({ handleSubmit, dirty, isValid, ...rest }) => (
        <Form onSubmit={handleSubmit}>
          <DialogContent>
            <ErrorAlert
              visible={!!registerError}
              message={t(`error-codes:${registerError?.response?.data?.code || registerError?.code || "OTHER"}`)}
            />
            <ParticipantCreateUpdateFormFields {...rest} />
          </DialogContent>
          <DialogActions sx={{ justifyContent: "flex-start" }}>
            <PrimaryButton
              onClick={() => handleSubmit()}
              isLoading={isLoading}
              label={t("registerForm.save")}
              disabled={!dirty || !isValid || isLoading}
            />
          </DialogActions>
        </Form>
      )}
    </Formik>
  )
}

import { Grid, Box } from "@mui/material"
import { BAR_CHART_VIEW_HEIGHT, BarChartView } from "../../../../../uikit/chart/BarChartView"
import { PIE_CHART_VIEW_HEIGHT } from "../../../../../uikit/chart/PieChartView"
import { MiddleCircularProgress } from "../../../../../uikit/indicator/ProgressIndicator"
import { EmptyChartDisplay } from "../../../../../uikit/chart/EmptyChartView"
import { ConsumptionChartViewProps } from "../Analise.Model"

export const BarChartComponent = (props: ConsumptionChartViewProps) => {
  const { meterReadingDateRange, barColors, analiseData, isLoading } = props

  if (isLoading) return <MiddleCircularProgress height={BAR_CHART_VIEW_HEIGHT} />

  return (
    <>
      {analiseData?.data?.length ? (
        <>
          <Grid container sx={{ width: "100%", height: "auto" }}>
            <Box
              sx={{
                width: { md: "100%", lg: "95%" },
                pl: 5,
                height: BAR_CHART_VIEW_HEIGHT,
              }}
            >
              <BarChartView
                dataKey="dateTime"
                data={analiseData.data}
                chartConfig={barColors}
                meterReadingDateRange={meterReadingDateRange}
                unit={barColors[0]?.unit}
              />
            </Box>
          </Grid>
        </>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: PIE_CHART_VIEW_HEIGHT,
          }}
        >
          <EmptyChartDisplay />
        </Box>
      )}
    </>
  )
}

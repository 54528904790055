import { getTranslateValue, isActiveStatus } from "../../../../Shared.Utils"
import { PrimaryButton } from "../../../../../uikit/button/PrimaryButton"
import { RemoveIcon } from "../../../../../uikit/Shared.Icon"
import { deleteAreaById } from "../../../../../domain/portal/admin/areas/Areas.Repository"
import { ModalComponent } from "../../../../../uikit/modal/Modal"
import React, { useState } from "react"
import { AreaAdminResponse } from "../../../../../data/generated-sources/openapi"
import AreaDeleteModal from "./AreaDelate.modal"
import { useHistory } from "react-router-dom"

interface AreaDeleteComponentPropsInterface {
  area: AreaAdminResponse
}
const AreaDeleteComponent = ({ area }: AreaDeleteComponentPropsInterface) => {
  const [isError, setIsError] = useState<boolean>(false)
  const history = useHistory()

  return (
    <ModalComponent
      closeButtonText={getTranslateValue("shared:form.action.cancel")}
      buttonText={
        <>
          <PrimaryButton
            disabled={!isActiveStatus(area?.activeState as string)}
            startIcon={<RemoveIcon fontSize="large" />}
            label={getTranslateValue("area:label.areaDelete")}
            sx={{ marginRight: "15px" }}
          />
        </>
      }
      buttonDisabled={!isActiveStatus(area?.activeState as string)}
      ctaButtonText={getTranslateValue("area:label.areaDelete")}
      ctaButtonAction={(handleClose) => {
        isActiveStatus(area?.activeState as string) &&
          area?.id &&
          deleteAreaById(area?.id)
            .then((): void => {
              handleClose && handleClose()
              history.push("/area")
            })
            .catch((): void => {
              setIsError(true)
            })
      }}
      modalStyle={{ backgroundColor: "#fff" }}
      useCloseBtn={false}
      body={<AreaDeleteModal isError={isError} />}
    />
  )
}

export default AreaDeleteComponent

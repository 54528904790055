import { useTranslation } from "react-i18next"
import { Typography } from "@mui/material"

import { DataStatus } from "../../../../../domain/portal/manager/my-zev/MyZev.Model"
import { ZevPriceCalculatorEnergy, ZevPriceCalculatorInput } from "../../../../../data/generated-sources/openapi"

interface DataStatusViewProps {
  dataStatus: DataStatus
  value?: string
}

export const DataStatusView = (props: DataStatusViewProps) => {
  const { dataStatus, value } = props
  const { t } = useTranslation("my-zev")

  switch (dataStatus) {
    case DataStatus.DISPLAY_VALUE:
      return (
        <>
          {value ? (
            <Typography variant="body1">{value}</Typography>
          ) : (
            <Typography variant="body1" fontWeight={400}>
              {t("section-main.tab.priceCalculator.notSelected")}
            </Typography>
          )}
        </>
      )
    case DataStatus.AVAILABLE:
      return (
        <Typography variant="body1" fontWeight={400}>
          {t("section-main.tab.priceCalculator.dataAvailable")}
        </Typography>
      )
    case DataStatus.NOT_SELECTED:
      return (
        <Typography variant="body1" fontWeight={400}>
          {t("section-main.tab.priceCalculator.notSelected")}
        </Typography>
      )
    case DataStatus.MISSING:
      return (
        <Typography variant="body1" fontWeight={400}>
          {t("section-main.tab.priceCalculator.dataMissing")}
        </Typography>
      )
    default:
      return <></>
  }
}

export const investmentInformationDataStatus = (priceCalculatorInput: ZevPriceCalculatorInput | undefined) => {
  if (
    priceCalculatorInput &&
    priceCalculatorInput.interestRateOnInvestment !== undefined &&
    priceCalculatorInput.totalInvestmentAmount !== undefined &&
    priceCalculatorInput.deprecationPeriodInYears !== undefined
  )
    return DataStatus.AVAILABLE
  return DataStatus.MISSING
}

export const operationDataStatus = (priceCalculatorInput: ZevPriceCalculatorInput | undefined): DataStatus => {
  if (
    priceCalculatorInput &&
    priceCalculatorInput.sellingElectricityPricePerKwh !== undefined &&
    priceCalculatorInput.operatingCostsPerKwh !== undefined
  )
    return DataStatus.AVAILABLE
  return DataStatus.MISSING
}

export const yearlyConsumptionDataStatus = (energy: ZevPriceCalculatorEnergy | undefined): DataStatus => {
  if (energy) {
    const isMissingData = isMissingConsumptionData(energy)
    return isMissingData ? DataStatus.MISSING : DataStatus.AVAILABLE
  } else return DataStatus.AVAILABLE
}

export const interestIsHighEnough = (input: ZevPriceCalculatorInput | undefined) => {
  return input && input.interestRateOnInvestment !== undefined && input.interestRateOnInvestment > 0
}

export const hasInputPrices = (input: ZevPriceCalculatorInput | undefined) => {
  return (
    input &&
    input.deprecationPeriodInYears !== undefined &&
    input.interestRateOnInvestment !== undefined &&
    input.operatingCostsPerKwh !== undefined &&
    input.sellingElectricityPricePerKwh !== undefined &&
    input.totalInvestmentAmount !== undefined &&
    input.purchasingPowerProductId !== undefined &&
    input.referencePowerProductId !== undefined &&
    input.purchasingPowerProductId !== -1 &&
    input.referencePowerProductId !== -1
  )
}

export const isMissingConsumptionData = (energy: ZevPriceCalculatorEnergy): boolean => {
  const values = [
    energy.production,
    energy.boughtHighTariff,
    energy.boughtLowTariff,
    energy.consumptionOfProductionHighTariff,
    energy.consumptionOfProductionLowTariff,
    energy.averageBoughtMonthlyPeak,
  ]
  const zeroValues = values.filter((v) => v === "0.00")
  return zeroValues.length > 0
}

import { Box, CircularProgress, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getBillingSettingsByContractId } from "../../../../domain/portal/admin/contracts/Contracts.Repository"
import { AxiosErrorDataType } from "../../../Shared.Utils"

import { Task, TaskReferenceTypeEnum, TaskStatusEnum, TaskTypeEnum } from "../../../../data/generated-sources/openapi"
import { ConfirmDialog } from "../../../../uikit/confirmDialog/ConfirmDialog"
import { TextButton } from "../../../../uikit/button/TextButton"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { adminGetParticipantById, completeAdminTaskById } from "../../../../domain/portal/admin/tasks/Tasks.Repository"

interface TaskDetailProps extends Task, RouteComponentProps<AdminPortalRouteParams> {
  onCompleteTask: () => void
}

export const TaskDetail = ({
  id,
  type,
  reference,
  referenceType,
  taskScope,
  status,
  history,
  onCompleteTask,
}: TaskDetailProps) => {
  const { t } = useTranslation("tasklist")
  const [updateError, setUpdateError] = useState<AxiosErrorDataType>()
  const taskTypeList = [TaskTypeEnum.MODIFIED_PAYMENT_INFORMATION, TaskTypeEnum.MODIFIED_CONTACT_DATA]

  const { data: billingSettings } = useQuery(
    ["getContractBillingSettings"],
    () => getBillingSettingsByContractId(taskScope),
    {
      enabled: taskTypeList.includes(type) && !!taskScope,
    },
  )
  const {
    data: participant,
    refetch: refetchParticipant,
    isLoading: isLoadingParticipant,
    remove: removeParticipant,
  } = useQuery(["getParticipant"], () => adminGetParticipantById(reference), {
    enabled: referenceType === TaskReferenceTypeEnum.PARTICIPANT && !!reference,
  })

  const { mutate: taskComplete, isLoading: isComplitingTasks } = useMutation(
    ["updateArea"],
    () => completeAdminTaskById(id),
    {
      onSuccess: onCompleteTask,
      onError: setUpdateError,
    },
  )

  useEffect(() => {
    refetchParticipant()
  }, [reference])

  useEffect(() => {
    return () => {
      removeParticipant()
    }
  }, [])

  const detailLabel = () => {
    switch (type) {
      case TaskTypeEnum.MODIFIED_CONTACT_DATA:
        return t("details.label.contactData")
      case TaskTypeEnum.MODIFIED_PAYMENT_INFORMATION:
        return t("details.label.paymentInformation")
      case TaskTypeEnum.NEW_PARTICIPANT:
      case TaskTypeEnum.MODIFIED_PARTICIPANT:
        return t("details.label.participants")
      case TaskTypeEnum.BILLING_CONFIRMATION:
        return t("list.billing.title")
      default:
        return ""
    }
  }

  const getZevTextButtonLabel = () => {
    switch (type) {
      case TaskTypeEnum.MODIFIED_CONTACT_DATA:
        return billingSettings?.data.mainContactPerson.name ?? ""
      case TaskTypeEnum.MODIFIED_PAYMENT_INFORMATION:
        return billingSettings?.data.paymentInformation.payee ?? ""
      case TaskTypeEnum.BILLING_CONFIRMATION:
        return t(`state.${type}`)
      default:
        return participant?.personalData
          ? `${participant?.personalData?.firstName} ${participant?.personalData?.lastName}`
          : "-"
    }
  }

  const handleActionButtonClick = () => {
    switch (referenceType) {
      case TaskReferenceTypeEnum.CONTRACT:
        history.push(`/management-contracts/${taskScope}`)
        break
      case TaskReferenceTypeEnum.ALL_PARTICIPANT_BILLING:
        history.push(`/management-contracts/${taskScope}/billings/all/${reference}/finalize`)
        break
      case TaskReferenceTypeEnum.INDIVIDUAL_PARTICIPANT_BILLING:
        history.push(`/management-contracts/${taskScope}/billings/individual/${reference}/finalize`)
        break
      case TaskReferenceTypeEnum.PARTICIPANT:
        history.push(`/tasklist/participants/${reference}`)
        break
      default:
        break
    }
  }

  return (
    <>
      <ErrorAlert
        visible={!!updateError}
        message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
      />
      <Box sx={{ px: 4 }}>
        <Box p={2} pl={"38%"} sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box>
            <Typography fontSize={14} fontWeight={"bold"}>
              {detailLabel()}
            </Typography>
            {isLoadingParticipant ? (
              <CircularProgress size={20} />
            ) : (
              <TextButton label={getZevTextButtonLabel()} onClick={handleActionButtonClick} />
            )}
          </Box>
          {status === TaskStatusEnum.CREATED && (
            <ConfirmDialog
              onConfirm={taskComplete}
              actionButtonText={t("details.action.cta")}
              actionButtonLoading={isComplitingTasks}
              actionButtonDisabled={isComplitingTasks}
            />
          )}
        </Box>
      </Box>
    </>
  )
}

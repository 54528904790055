import { FormikHandlers, FormikState } from "formik"
import { ProfileUpsert, salutationKeys, userTypeKeys } from "../../../../../domain/portal/admin/profiles/Profiles.Model"
import { useTranslation } from "react-i18next"
import { FormMode } from "../../../../../uikit/form/FormView"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { LocationOn, TelephoneIconFilled, ContactIconFilled } from "../../../../../uikit/Shared.Icon"
import { validationError } from "../../../../Shared.Validation"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { Grid, Typography, Stack, styled, Paper } from "@mui/material"

interface ProfileFormModeFormProps {
  mode: FormMode
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  padding: theme.spacing(1),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: 700,
}))

export const SectionHeader = ({ title, icon }: { title: string; icon: React.ReactNode }) => (
  <Stack flexDirection="row" alignItems="center" justifyContent="flex-start">
    {icon}
    <SectionTitle>{title}</SectionTitle>
  </Stack>
)

export const ProfilesForm = (
  props: ProfileFormModeFormProps &
    Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<ProfileUpsert>, "errors" | "values" | "touched">,
) => {
  const { t } = useTranslation("profiles")
  const { mode, values, errors, handleChange, handleBlur, touched } = props
  return (
    <Paper>
      <Grid container rowGap={6}>
        <Grid item container rowGap={3} columnSpacing={3}>
          <Grid item xs={12}>
            <SectionHeader title={t("form.title.user")} icon={<ContactIconFilled />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectPicker
              required
              autoFocus
              disabled={mode === FormMode.UPDATE}
              name="userType"
              type="text"
              label={t("form.field.userType")}
              helperText={validationError(errors.userType, touched.userType)}
              value={values.userType}
              items={userTypeKeys.map((userType) => ({
                label: t(`userType.${userType}`),
                value: userType,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}></Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="title"
              type="text"
              label={t("form.user.title")}
              helperText={validationError(errors.title, touched.title)}
              value={values.title}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SelectPicker
              required
              name="salutation"
              type="text"
              label={t("form.user.salutation")}
              emptyValue="None"
              value={values.salutation}
              helperText={validationError(errors.salutation, touched.salutation)}
              items={salutationKeys.map((salutation) => ({
                label: t(`shared:salutation.${salutation}`),
                value: salutation,
              }))}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="firstName"
              type="text"
              label={t("form.field.first-name")}
              helperText={validationError(errors.firstName, touched.firstName)}
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="lastName"
              type="text"
              label={t("form.field.lastName")}
              helperText={validationError(errors.lastName, touched.lastName)}
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container rowGap={3} columnSpacing={3}>
          <Grid item xs={12}>
            <SectionHeader title={t("form.title.address")} icon={<LocationOn />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="street"
              type="text"
              label={t("form.field.street")}
              helperText={validationError(errors.street, touched.street)}
              value={values.street}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="houseNumber"
              type="text"
              label={t("form.field.house-number")}
              helperText={validationError(errors.houseNumber, touched.houseNumber)}
              value={values.houseNumber}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="postalCode"
              type="text"
              inputProps={{ maxLength: 6 }}
              label={t("form.field.postalCode")}
              helperText={validationError(errors.postalCode, touched.postalCode)}
              value={values.postalCode}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              required
              name="city"
              type="text"
              label={t("form.field.city")}
              helperText={validationError(errors.city, touched.city)}
              value={values.city ?? ""}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>

        <Grid container rowGap={3} columnSpacing={3}>
          <Grid item xs={12}>
            <SectionHeader title={t("form.title.phoneDetails")} icon={<TelephoneIconFilled />} />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="telephone"
              type="tel"
              label={t("form.field.telephone")}
              helperText={validationError(errors.telephone, touched.telephone)}
              value={values.telephone}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="mobile"
              type="tel"
              label={t("form.field.mobile")}
              helperText={validationError(errors.mobile, touched.mobile)}
              value={values.mobile}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="email"
              type="email"
              label={t("form.field.email")}
              helperText={validationError(errors.email, touched.email)}
              value={values.email}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

import { Medium, ZevMeterType } from "../../../../../data/generated-sources/openapi"

export enum MediumColorEnum {
  ELECTRICITY = "#FDC300",
}

export enum MediumNumberEnum {
  HEAT,
  COLD,
  WARM_WATER,
  COLD_WATER,
}

export type ATYPE = Exclude<Medium, Medium.ELECTRICITY>

export enum MediumTableEnum {
  DELIVERY_ID = "DELIVERY ID",
  START_DATE = "START DATE",
  END_DATE = "END_DATE",
}
export const mediumTypeValues = Object.values(Medium)
export const mediumVewaTypeValues = mediumTypeValues.filter((item: string) => item !== Medium.ELECTRICITY)
export const mediumZevTypeValues = mediumTypeValues.filter((item: string) => item === Medium.ELECTRICITY)
export const mediumZevMeterTypeValues = Object.values(ZevMeterType)

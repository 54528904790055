import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Box, Divider, Stack } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useAppDispatch } from "../../../hooks"
import { useTranslation } from "react-i18next"
import DateUtils from "../../../../services/utils/DateUtils"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import {
  customerGetVewaAllParticipantBillingDetailsById,
  customerGetVewaAllParticipantBillingParticipants,
  customerReloadVewaParticipantBillingData,
  customerUpdateBillingCostShares,
} from "../../../../domain/portal/manager/billings/Billings.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { VewaBillingCostShares } from "../../shared/vewaBillings/VewaBillingCostShares"
import { VewaBillingParticipatioins } from "../../shared/vewaBillings/VewaBillingParticipatioins"
import { VewaBillingConsumptionOverview } from "../../shared/vewaBillings/VewaBillingConsumptionOverview"
import { VewaBillingAggregateConsumption } from "../../shared/vewaBillings/VewaBillingAggregateConsumption"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import { customerCreateVewaParticipantBillingBills } from "../../../../domain/portal/manager/bills/Bills.Repository"
import {
  VewaAllParticipantsBillingState,
  VewaCostShare,
  VewaCostShares,
} from "../../../../data/generated-sources/openapi"
import { ConfirmReloadBillingDialog } from "../../../../uikit/confirmDialog/ConfirmReloadBillingDialog"

export const VewaBillingValidateDetails = ({
  match: { params },
  history,
}: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { billingId, areaId, contractId } = params
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: bill,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getVewaBill"], () => customerGetVewaAllParticipantBillingDetailsById(billingId), {
    enabled: !!billingId,
    onError: setError,
    onSuccess: (billData) => {
      if (billData.activeState !== VewaAllParticipantsBillingState.IN_PROGRESS) {
        history.push(`/${areaId}/billings`)
      }
    },
  })

  const {
    data: participations,
    remove: removeParticipantData,
    isLoading: isLoadingParticipation,
  } = useQuery(["getVewaParticipation"], () => customerGetVewaAllParticipantBillingParticipants(billingId), {
    enabled: !!billingId,
    onError: setError,
  })

  const handleCreateBills = () => history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/bills`)

  const { mutate: createBills, isLoading: isCreatingBills } = useMutation(
    ["createBillsForBilling"],
    () => customerCreateVewaParticipantBillingBills(billingId),
    {
      onSuccess: handleCreateBills,
      onError: setError,
    },
  )

  const { mutate: updateBillingCostShares, isSuccess: isUpdatingSuccess } = useMutation(
    ["updateBillingCostShares"],
    (vewaCostShares: VewaCostShares) => customerUpdateBillingCostShares(billingId, vewaCostShares),
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const { mutate: reloadBillingData, isLoading: isReloadingData } = useMutation(
    ["reloadBillingData"],
    () => customerReloadVewaParticipantBillingData(billingId),
    {
      onSuccess: () => history.push(`/${areaId}/billings`),
      onError: setError,
    },
  )

  useEffect(() => {
    return () => {
      removeBill()
      removeParticipantData()
    }
  }, [removeBill, removeParticipantData])

  const handleNavigateToUtilityUnitDetails = (unitId: string) => history.push(`/${areaId}/utility-units/${unitId}`)

  const handleUpdate = (vewaCostShareData: VewaCostShare[]) => {
    updateBillingCostShares({ costShares: vewaCostShareData })
  }

  const billDateRange = `${DateUtils.getDeFormatDate(bill?.startDate || "", "DD. MMM")} - ${DateUtils.getDeFormatDate(
    bill?.endDate || "",
    "DD. MMM YY",
  )}`

  bill && dispatch(setPageSubtitle(billDateRange))

  return (
    <Box p={4}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <VewaBillingConsumptionOverview
        totalColdConsumption={bill?.totalColdConsumption ?? 0}
        totalColdWaterConsumption={bill?.totalColdWaterConsumption ?? 0}
        totalHeatConsumption={bill?.totalHeatConsumption ?? 0}
        totalWarmWaterConsumption={bill?.totalWarmWaterConsumption ?? 0}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingParticipatioins
        isLoading={isLoadingParticipation}
        participants={participations?.elements || []}
        onGoToUnitClick={handleNavigateToUtilityUnitDetails}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingCostShares
        onUpdateSubmit={handleUpdate}
        isUpdateSuccess={isUpdatingSuccess}
        costShares={bill?.costAllocations?.costShares}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingAggregateConsumption
        isLoading={isLoadingParticipation}
        billStartDate={bill?.startDate ?? ""}
        billEndDate={bill?.endDate ?? ""}
        participants={participations?.elements || []}
      />
      <Stack p={5} pt={10} alignItems={"center"} justifyContent={"flex-end"} flexDirection={"row"}>
        <ConfirmReloadBillingDialog onConfirmClick={() => reloadBillingData()} isLoading={isReloadingData} />
        <PrimaryButton
          disabled={isCreatingBills}
          isLoading={isCreatingBills}
          onClick={() => createBills()}
          label={t("settlements:list-billing-makeBill")}
        />
      </Stack>
    </Box>
  )
}

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import { IBuildingAdminResponse } from "../../../../domain/portal/admin/buildings/Buildings.Model"

export interface BuildingsState {
  buildings: IBuildingAdminResponse[]
}

const initialState: BuildingsState = {
  buildings: [],
}

export const buildingsSlice = createSlice({
  name: "buildings",
  initialState,
  reducers: {
    setBuildingsList: (state, { payload }: PayloadAction<IBuildingAdminResponse[]>) => {
      state.buildings = payload
    },
  },
})

export const { setBuildingsList } = buildingsSlice.actions

const buildingsState = (state: RootState) => state.adminPortal.buildings
export const selectBuildings = createSelector(buildingsState, (state) => state.buildings)

export default buildingsSlice.reducer

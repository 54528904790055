import { useHistory } from "react-router-dom"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import TableCell from "@mui/material/TableCell"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { useQuery } from "@tanstack/react-query"
import { getProductByIdNew } from "../../../../../domain/portal/admin/products/Products.Repository"
import { CircularProgress } from "@mui/material"
import DateUtils from "../../../../../services/utils/DateUtils"

interface AreaContractsTableInterface {
  itemConfig: ContractAdminResponse
}

const AreaContractsTableComponent = (props: AreaContractsTableInterface) => {
  const history = useHistory()
  const { itemConfig } = props
  const {
    data: productData,
    isLoading: productIsLoading,
    isError: productIsError,
  } = useQuery(["getProductByIdForContractTable", itemConfig.productId], () => getProductByIdNew(itemConfig.productId))

  const productNameLayout = (): React.ReactNode | string | undefined => {
    if (productIsLoading) return <CircularProgress color="primary" />
    if (productIsError) return "-"
    return productData?.data.name
  }

  return (
    <TableRowClickable<ContractAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(item: ContractAdminResponse) => {
        if (item.id) {
          history.push(`/management-contracts/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
      <TableCell align="left">{productNameLayout()}</TableCell>
      <TableCell align="left">{DateUtils.getDeFormatWithDots(itemConfig.startDate)}</TableCell>
      <TableCell align="left">{itemConfig.endDate ? DateUtils.getDeFormatWithDots(itemConfig.endDate) : "-"}</TableCell>
    </TableRowClickable>
  )
}

export default AreaContractsTableComponent

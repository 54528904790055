import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_STRING_COMPARATOR, ORDERED_STRING_LOCALE_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { AreaBuildingsEnum } from "./AreaBuildings.Enum"

export const AreaBuildingsColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case AreaBuildingsEnum.STATUS:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case AreaBuildingsEnum.NAME:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        a.name && b.name ? ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction) : 1
    case AreaBuildingsEnum.STREET:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.address.street, b.address.street, orderBy.direction)
    case AreaBuildingsEnum.CITY:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.address.city, b.address.city, orderBy.direction)
  }
}

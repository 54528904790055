import { v4 as uuidv4 } from "uuid"
import { ProfileContractsApiEnum } from "../Components.Enums"
import { ContractsColumns } from "../contracts/Contracts.Enum"
import { contractsColumnComparator } from "../contracts/Contracts.Comparator"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import ContractsTableComponent from "../contracts/ContractsTable.Component"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"

export const PROFILE_DETAILS_CONFIG = {
  profileDetailContracts: {
    name: "profileDetailContracts",
    header: {
      title: "contracts:table.title",
      showButton: true,
      showFilter: false,
      buttonIcon: <EditIcon />,
    },
    columnEnums: ContractsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 10000,
      orderBy: "",
    },
    defaultSorting: {
      column: ContractsColumns.PRODUCT,
      direction: "desc",
    },
    defaultRowsPerPage: 10,
    columnComparator: contractsColumnComparator,
    tableHeaders: [
      {
        column: ContractsColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: ContractsColumns.CONTRACT_NAME,
        label: "contracts:table.header.contractName",
        width: "30%",
        orderable: true,
      },
      {
        column: ContractsColumns.PRODUCT,
        label: "contracts:table.header.product",
        width: "25%",
        orderable: true,
      },
      {
        column: ContractsColumns.START_DATE,
        label: "contracts:table.header.startDate",
        width: "17.5%",
        orderable: true,
      },
      {
        column: ContractsColumns.END_DATE,
        label: "contracts:table.header.endDate",
        width: "17.5%",
        orderable: true,
      },
    ],
    renderTableRows: (item: ContractAdminResponse) => (
      <ContractsTableComponent itemConfig={item} key={uuidv4() + item?.id} />
    ),
  },
}

export const PROFILE_DETAILS_API_CONFIG = {
  profileDetailContracts: ProfileContractsApiEnum,
}

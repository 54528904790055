import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"

import { TariffScheduleResponse } from "../../../../../data/generated-sources/openapi"
import { PrimaryButton } from "../../../../../uikit/button"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { TariffSectionTitle } from "../fragments/TariffTypographies"
import { TariffTimePeriodText } from "../fragments/TariffTimePeriodText"

interface TariffEnergySupplierEditProps {
  isUpdating: boolean
  tariffs: TariffScheduleResponse[]
  selectedTariff: TariffScheduleResponse
  onSubmit: (tariffId: string) => void
}

export const TariffEnergySupplierEdit = ({
  tariffs,
  selectedTariff,
  isUpdating,
  onSubmit,
}: TariffEnergySupplierEditProps) => {
  const { t } = useTranslation("tariff")

  const handleOnSubmit = (tariff: TariffScheduleResponse) => onSubmit(tariff.id)

  const renderTariffTimePeriodText = (tariffId: string) => {
    const tariff = tariffs?.find((tariff) => tariff.id === tariffId)
    return tariff ? <TariffTimePeriodText {...tariff} /> : <></>
  }

  return (
    <Formik<TariffScheduleResponse> enableReinitialize initialValues={{ ...selectedTariff }} onSubmit={handleOnSubmit}>
      {({ values, isValid, dirty, setFieldValue }) => {
        return (
          <Form>
            <SelectPicker
              name="tariffs"
              variant="outlined"
              value={values?.id}
              data-testid="tariffSelectPicker"
              defaultValue={selectedTariff.id}
              items={tariffs.map((tariff) => ({
                value: tariff.id,
                label: tariff.name,
              }))}
              onChange={({ target }) => setFieldValue("id", target.value)}
              customStyle={{
                paddingTop: 0,
                borderRadius: "8px",
                marginBottom: "16px",
                background: "#FAFAFA",
              }}
            />

            <TariffSectionTitle>{t("tariffTimes")}</TariffSectionTitle>
            {renderTariffTimePeriodText(values.id)}
            <Box mt={4}>
              <PrimaryButton
                type="submit"
                isLoading={isUpdating}
                disabled={!isValid || !dirty}
                label={t("shared:form.action.save")}
              />
            </Box>
          </Form>
        )
      }}
    </Formik>
  )
}

import { Box, Typography, styled } from "@mui/material"
import {
  mediumBlueLightColor,
  mediumGrayColor,
  mediumPinkColor,
  mediumPurpleColor,
  mediumPurpleLightColor,
  mediumRedColor,
} from "../../../../Shared.Theme"
import { BillingScope } from "../../../../../data/generated-sources/openapi"

const vewaBillingScopeColor = (billingScope: BillingScope) => {
  switch (billingScope) {
    case "HEATING_BILL":
      return {
        backgroundColor: mediumPinkColor,
        borderColor: mediumRedColor,
      }
    case "WATER_BILL":
      return {
        backgroundColor: mediumPurpleLightColor,
        borderColor: mediumPurpleColor,
      }
    case "COOLING_BILL":
      return {
        backgroundColor: mediumGrayColor,
        borderColor: mediumBlueLightColor,
      }
    default:
      return {
        backgroundColor: mediumGrayColor,
        borderColor: mediumRedColor,
      }
  }
}

export const VewaCostSharesBox = styled(Box, {
  shouldForwardProp: (prop) => !["billingScope", "editMode"].includes(prop as string),
})<{ billingScope: BillingScope; editMode?: boolean }>(({ theme, billingScope, editMode }) => ({
  borderLeft: `10px solid ${vewaBillingScopeColor(billingScope).borderColor}`,
  padding: theme.spacing(3.75, 1, 3.75, 5),
  paddingBottom: theme.spacing(2),
  borderRadius: theme.spacing(1),
  margin: theme.spacing(4, 0),
  backgroundColor: editMode
    ? theme.palette.background.editPrimary
    : vewaBillingScopeColor(billingScope).backgroundColor,
}))

export const VewaCostShareTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 350,
  paddingBottom: theme.spacing(1),
  paddingRight: theme.spacing(2),
  color: theme.palette.text.secondary,
  fontSize: theme.typography.pxToRem(16),
}))

export const VewaCostSharePercentage = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(16),
  alignSelf: "end",
}))

import { I18nNamespace } from "../../App.i18n"
import { ContractsManagementI18n } from "./contractsManagement/ContractsManagement.i18n"
import { ProfilesI18n } from "./profiles/Profiles.i18n"
import { ZevsI18n } from "./zevs/Zevs.i18n"
import { TaskListI18n } from "./taskList/TaskList.i18n"
import { ContractsI18n } from "./contracts/Contracts.i18n"
import { BuildingsI18n } from "./buildings/Buildings.i18n"
import { BillingsInitialI18n } from "./billings/initial/BillingsInitial.i18n"
import { ProductI18n } from "./products/Products.i18n"
import { ServiceComponentsI18n } from "./service-components/ServiceComponents.i18n"
import { BillingsRecurringI18n } from "./billings/recurring/BillingsRecurring.i18n"
import { SettingsComponentsI18n } from "./settings/Settings.i18n"
import { BillingsParticipantI18n } from "./billings/participant/BillingsParticipant.i18n"
import { PricesI18n } from "./prices/Prices.i18n"
import { MetersI18n } from "./meters/Meters.i18n"
import { UtilityUnitI18n } from "./sharedComponentsConfig/utilityUnits/UtilityUnit.i18n"
import { AreaI18n } from "./sharedComponentsConfig/area/Area.i18n"
import { AnaliseI18n } from "../shared/analiyse/Analise.i18n"
import { MediumI18n } from "./contractsManagement/medium/Medium.i18n"
import { ParticipantI18n } from "./participant/Participant.i18n"
import { UtilityUnitParticipationsI18n } from "./utilityUnit/participations/UtilityUnitParticipations.i18n"
import { TariffManagement18n } from "./tariffManagement/TariffManagement.i18n"
import { ParticipantBillingsManagementI18n } from "./billings/participantBillingManagement/ParticipantBillingsManagement.i18n"
import { ServiceBillingsI18n } from "./billings/serviceBillings/ServiceBilings.i18n"

export const adminPortalI18n: I18nNamespace[] = [
  ZevsI18n,
  ProfilesI18n,
  TaskListI18n,
  ServiceComponentsI18n,
  ContractsI18n,
  BuildingsI18n,
  BillingsInitialI18n,
  BillingsRecurringI18n,
  BillingsParticipantI18n,
  ServiceBillingsI18n,
  ProductI18n,
  SettingsComponentsI18n,
  PricesI18n,
  ParticipantI18n,
  MetersI18n,
  UtilityUnitI18n,
  UtilityUnitParticipationsI18n,
  AreaI18n,
  AnaliseI18n,
  ContractsManagementI18n,
  MediumI18n,
  TariffManagement18n,
  ParticipantBillingsManagementI18n,
]

import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import { EnergySupplierIcon } from "../../../../uikit/Shared.Icon"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"

export const TariffsBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("tariff")
  const goToTariffHome = () => history.push("/tariff")
  return (
    <Switch>
      <Route path="/tariff/:tariffId/edit">
        <BreadcrumbsHomeIcon redirectUrl={goToTariffHome} icon={<EnergySupplierIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton label={t("energySupplier")} onClick={goToTariffHome} />
        <BreadcrumbsNavButton disabled label={t("editSupplier")} />
      </Route>

      <Route path="/tariff/add">
        <BreadcrumbsHomeIcon redirectUrl={goToTariffHome} icon={<EnergySupplierIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton label={t("energySupplier")} onClick={goToTariffHome} />
        <BreadcrumbsNavButton disabled label={t("addSupplier")} />
      </Route>

      <Route path="/tariff">
        <BreadcrumbsHomeIcon redirectUrl={goToTariffHome} icon={<EnergySupplierIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("energySupplier")} />
      </Route>
    </Switch>
  )
}

import { Map } from "immutable"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Box } from "@mui/material"

import { FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { UpdatePersonalUpsert } from "../../../../../domain/portal/manager/profile/Profile.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { validateProfileNameForm } from "./ProfileNameForm.validation"
import { PrimaryButton } from "../../../../../uikit/button"
import { bgColorGreen } from "../../../../Shared.Theme"

interface ProfileNameFormProps {
  editMode: boolean
  isLoading: boolean
  profileNameValues: UpdatePersonalUpsert
  dataChanged: (profileNameValues: UpdatePersonalUpsert) => void
}

export const ProfileNameForm = ({ editMode, dataChanged, profileNameValues, isLoading }: ProfileNameFormProps) => {
  const { t } = useTranslation("profile")
  const [touched, setTouched] = useState<Map<string, boolean>>(Map())
  const [editBackgroundColor, setEditBackgroundColor] = useState("#ffffff")

  useEffect(() => {
    setEditBackgroundColor(editMode ? bgColorGreen : "#ffffff")
  }, [setEditBackgroundColor, editMode])

  return (
    <>
      <div
        style={{
          padding: "16px",
          marginBottom: "32px",
          backgroundColor: editBackgroundColor,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <FormView>
          {!editMode ? (
            <FormRowColumn>
              <Typography variant="body1" textAlign="start">
                {`${profileNameValues.personal.firstName} ${profileNameValues.personal.lastName}`}
              </Typography>
            </FormRowColumn>
          ) : (
            <>
              <FormRowColumn>
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="firstName"
                    label={t("personal.firstName")}
                    value={profileNameValues.personal.firstName}
                    onChange={(event) =>
                      dataChanged({ personal: { ...profileNameValues.personal, firstName: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("firstName", true))}
                    helperText={validateProfileNameForm.firstName(
                      `${profileNameValues.personal.firstName}`,
                      touched.has("totalInvestmentAmount"),
                      t,
                    )}
                  />
                </Box>
              </FormRowColumn>
              <FormRowColumn>
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="lastName"
                    label={t("personal.lastName")}
                    value={profileNameValues.personal.lastName}
                    onChange={(event) =>
                      dataChanged({ personal: { ...profileNameValues.personal, lastName: event.target.value } })
                    }
                  />
                </Box>
              </FormRowColumn>
            </>
          )}
        </FormView>
        {editMode && (
          <PrimaryButton type="submit" sx={{ mt: 2 }} label={t("shared:form.action.save")} isLoading={isLoading} />
        )}
      </div>
    </>
  )
}

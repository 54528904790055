import { MinusIcon } from "../Shared.Icon"
import { FabButton } from "./FabButton"

interface SmallPrimaryMinusButtonProps {
  onClick: () => void
  disabled?: boolean
  background?: string[]
  color?: string
  customSize?: number
}

export const SmallPrimaryMinusButton = (props: SmallPrimaryMinusButtonProps) => {
  const { onClick, disabled, color, background, customSize } = props
  return (
    <FabButton
      customSize={customSize}
      color={color}
      background={background}
      size="small"
      icon={<MinusIcon fontSize="small" />}
      onClick={onClick}
      disabled={disabled}
    />
  )
}

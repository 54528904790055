import { Grid } from "@mui/material"
import { useMemo } from "react"
import { appThemeGrey10, appThemeGrey40, appThemeGrey50, appThemeGrey100 } from "../../app/Shared.Theme"
import { SmallPrimaryMinusButton } from "../button/PrimaryMinusButton"
import { SmallPrimaryPlusButton } from "../button/PrimaryPlusButton"
import { InlineDatePicker } from "./InlineDatePicker"

interface DateYearSelectorViewProps {
  selectedDateValue: number | string
  onDateChanged: (newDateTime: number) => void
  disabled?: boolean
}

export const DateYearSelectorView = (props: DateYearSelectorViewProps) => {
  const { selectedDateValue, onDateChanged, disabled } = props
  const selectedDate: Date = useMemo(() => new Date(selectedDateValue), [selectedDateValue])
  const disabledPlusButton = useMemo(
    () => new Date(selectedDateValue).getFullYear() >= new Date().getFullYear(),
    [selectedDateValue],
  )
  const buttonBackground = [appThemeGrey40, appThemeGrey50]
  const selectorStyle = { background: appThemeGrey10, padding: 8, borderRadius: 3 }
  const dateOutputStyle = { flex: 1, minWidth: 180, justifyContent: "center", alignItems: "center", display: "flex" }
  return (
    <>
      <Grid style={selectorStyle} container>
        <SmallPrimaryMinusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabled}
          onClick={() => {
            onDateChanged(selectedDate.setFullYear(selectedDate.getFullYear() - 1))
          }}
        />
        <output style={dateOutputStyle}>
          <InlineDatePicker
            pickerType="year"
            selectedDate={selectedDate}
            onChange={(date) => {
              if (date) onDateChanged(selectedDate.setFullYear(date.getFullYear()))
            }}
          />
        </output>
        <SmallPrimaryPlusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabledPlusButton || disabled}
          onClick={() => {
            onDateChanged(selectedDate.setFullYear(selectedDate.getFullYear() + 1))
          }}
        />
      </Grid>
    </>
  )
}

import { Form, Formik, FormikProps } from "formik"
import { useRef } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { emptyProductUpsert, ProductUpsert } from "../../../../domain/portal/admin/products/Products.Model"
import { DividerBox } from "../../../../uikit/box/DividerBox"
import { PrimaryButton } from "../../../../uikit/button"
import { ClearForm } from "../../../../uikit/form/ClearForm"
import { FormActions } from "../../../../uikit/form/FormView"
import { ErrorAlert, SuccessAlertLink } from "../../../../uikit/Shared.Alert"
import { PlusIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { ProductsForm } from "./form/ProductsForm"
import { validateProduct } from "./form/ProductsForm.Validation"
import { mapDispatchToProps } from "./ProductsCreate.Connect"
import { ProductsCreateState } from "./ProductsCreate.Reducer"
import { Paper } from "@mui/material"

interface ProductsCreateComponentProps
  extends ProductsCreateState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const ProductsCreateComponent = (props: ProductsCreateComponentProps) => {
  const { t } = useTranslation("products")
  const { createViewState, createProduct, navigateToProduct } = props
  const formRef = useRef<FormikProps<ProductUpsert>>(null)
  return (
    <>
      <ClearForm<ProductUpsert> viewState={createViewState} formRef={formRef} />
      <Formik
        validateOnBlur
        initialValues={emptyProductUpsert}
        onSubmit={(values) => createProduct(values)}
        validate={(values) => validateProduct(values, t)}
        innerRef={formRef}
      >
        {({ isValid, dirty, ...rest }) => (
          <Form onSubmit={rest.handleSubmit}>
            <SuccessAlertLink
              visible={!!createViewState.domainResult}
              message={t("create.alert.success")}
              onClick={() => navigateToProduct(createViewState.domainResult ?? "")}
            />
            {createViewState.domainError && (
              <ErrorAlert scrollOnDisplay message={createViewState.domainError.message} />
            )}
            {createViewState.domainError && <ErrorAlert message={createViewState.domainError.message} />}
            <Paper sx={{ pb: 3 }}>
              <ProductsForm createForm {...rest} />
            </Paper>
            <DividerBox />
            <FormActions>
              <PrimaryButton
                startIcon={<PlusIcon />}
                isLoading={createViewState.isLoading}
                label={t("create.form.action.cta")}
                type="submit"
                disabled={!isValid || !dirty || createViewState.isLoading}
              />
            </FormActions>
          </Form>
        )}
      </Formik>
    </>
  )
}

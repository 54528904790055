import { styled } from "@mui/material/styles"
import { CSSProperties } from "react"
import { useTranslation } from "react-i18next"
import { Link, LinkProps, RouteComponentProps, withRouter } from "react-router-dom"

import { AdminPortalRouteParams } from "../../../portal/admin/AdminPortal.Routes"

export const FootWrapper = styled("div")(({ theme }) => ({
  borderTop: "1px solid black",
  backgroundColor: theme.palette.grey[100],
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexWrap: "wrap",
  color: "#979797",
  padding: theme.spacing(3, 2),
  [theme.breakpoints.down("md")]: {
    padding: theme.spacing(2),
  },
}))

export const StaticLink = styled((props: LinkProps) => <Link {...props} />)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  color: "#979797",
  textDecoration: "none",
  fontSize: "0.875rem",
  fontWeight: 300,
}))

export const StaticHref = styled((props: React.LinkHTMLAttributes<HTMLAnchorElement>) => (
  <a target="_blank" {...props} />
))(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  color: "#979797",
  textDecoration: "none",
  fontSize: "0.875rem",
  fontWeight: 300,
}))

interface StaticFooterViewProps extends RouteComponentProps<AdminPortalRouteParams> {
  style?: CSSProperties
}

export const StaticFooterView = withRouter(({ style, match }: StaticFooterViewProps) => {
  const { t } = useTranslation("appContainer")
  const { areaId } = match.params

  return (
    <FootWrapper style={style}>
      <StaticLink to="/signin">{t("footer.home")}</StaticLink>|
      <StaticLink to={areaId ? `/${areaId}/faq` : "/faq"}>{t("footer.faq")}</StaticLink>|
      <StaticHref href="mailto:zev@ckw.ch">{t("footer.contact")}</StaticHref>|
      <StaticHref href="https://www.ckw.ch/abrechnungsmanager" rel="noopener noreferrer">
        {t("footer.tutorial")}
      </StaticHref>
      |
      <StaticHref href="https://www.ckw.ch/footer/impressum.html" rel="noopener noreferrer">
        {t("footer.impressum")}
      </StaticHref>
      |
      <StaticHref href="https://www.ckw.ch/footer/datenschutz.html" rel="noopener noreferrer">
        {t("footer.privacy")}
      </StaticHref>
      |
      <StaticHref href="https://www.ckw.ch/footer/agb.html" rel="noopener noreferrer">
        {t("footer.legal")}
      </StaticHref>
    </FootWrapper>
  )
})

import { TabContext, TabPanel } from "@mui/lab"
import React, { useEffect, useState } from "react"
import { ChartTabsEnum } from "../Analise.Enum"
import { useTranslation } from "react-i18next"
import { getSolarLegendConfig } from "./chart.config"
import { ChartWrapperComponentPropsInterface, PieDataInterface } from "../Analise.Model"
import PieChartComponent from "./PieChart.Component"
import { BarChartComponent } from "./BarChart.Component"
import { LegendWrapper } from "../components/Legend"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import { SpaceBetweenBox } from "../../../../../uikit/box/AlignmentBox"
import { mediumGreenColor, solarPrimaryColor } from "../../../../Shared.Theme"
import {
  getRemainingMonthDays,
  getRemainingMonths,
  getRemainingWeekDays,
} from "../../../../../domain/analyse/Analyse.Mapper"
import { MeterReadingsCalcResolution } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"

const SolarChartWrapper = (props: ChartWrapperComponentPropsInterface) => {
  const { meterReadingDateRange, selectedTab, barColors, analiseData, isLoading } = props
  const { t } = useTranslation("analise")
  const [data, setData] = useState()
  const [pieChartData, setPieChartData] = useState<PieDataInterface[]>([])

  useEffect(() => {
    setData(undefined)
    analiseData && mapAnalyseData()
    setPieChartData(pieInfoChartMapper())
  }, [isLoading])

  const mapAnalyseData = () => {
    if (!analiseData.data || !analiseData.data.length) return setData(analiseData.data)
    const mappedData = analiseData
    const firstDate = mappedData?.data[mappedData?.data?.length - 1]?.dateTime

    if (meterReadingDateRange === MeterReadingsCalcResolution.MONTH) {
      getRemainingMonthDays(firstDate).forEach((date: string) => {
        mappedData.data.push({
          dateTime: date,
        })
      })
    }

    if (meterReadingDateRange === MeterReadingsCalcResolution.WEEK && mappedData.data.length < 7) {
      getRemainingWeekDays(firstDate, firstDate).forEach((date: string) => {
        mappedData.data.push({
          dateTime: date,
        })
      })
    }

    if (meterReadingDateRange === MeterReadingsCalcResolution.YEAR) {
      getRemainingMonths(firstDate).forEach((date: string) => {
        mappedData.data.push({
          dateTime: date,
        })
      })
      DateUtils.getPreviousMonths(mappedData?.data[0]?.dateTime)
        .reverse()
        .forEach((date: string) => {
          mappedData.data.unshift({
            dateTime: date,
          })
        })
    }
    setData(mappedData)
  }

  const pieInfoChartMapper = (): PieDataInterface[] => {
    switch (selectedTab) {
      case ChartTabsEnum.ELECTRICITY:
        return [
          {
            name: "totalProduction",
            value: Number(analiseData?.totalEnergyHighTariff),
            color: solarPrimaryColor,
          },
          {
            name: "totalHighTariff",
            value: Number(analiseData?.totalProduced),
            color: mediumGreenColor,
          },
        ]
      default:
        return []
    }
  }

  return (
    <TabContext value={selectedTab}>
      <TabPanel value={ChartTabsEnum.ELECTRICITY}>
        <SpaceBetweenBox>
          <Subtitle1 style={{ fontSize: "23px" }} my={5} mt={3} color={"#1A1A1A"}>
            {t("section.solarTitle")}
          </Subtitle1>
        </SpaceBetweenBox>
        {Number(analiseData?.totalConsumption) ? (
          <LegendWrapper data={pieChartData} config={getSolarLegendConfig(t, selectedTab)} />
        ) : null}
        <div style={{ display: "flex", maxHeight: "500px", marginTop: "70px" }}>
          <PieChartComponent
            selectedTab={selectedTab}
            isLoading={isLoading}
            data={pieChartData}
            total={analiseData?.totalConsumption}
            legendIconConfig={getSolarLegendConfig(t, selectedTab ?? "")}
          />
          <BarChartComponent
            isLoading={isLoading}
            meterReadingDateRange={meterReadingDateRange as MeterReadingsCalcResolution}
            barColors={barColors}
            analiseData={data}
          />
        </div>
      </TabPanel>
    </TabContext>
  )
}

export default SolarChartWrapper

/* eslint-disable quotes */
/* eslint-disable prettier/prettier */
import { I18nNamespace } from "../../../App.i18n"

export const MyZevI18n: I18nNamespace = {
  name: "my-zev",
  de: {
    vatTitle: "MwSt-ID",
    noVat: "nicht mehrwertsteuerpflichtig",
    "section-tab.billing.preferences": "Abrechnungseinstellungen",
    "section-tab.zev.product": "ZEV Produkt",
    "section-tab.electricity.calculator": "Strompreiskalkulator",
    "section-tab.prices": "Preise",
    "section-info.subtitle":
      "Die folgenden Informationen werden den Teilnehmern auf der Abrechnung zur Verfügung gestellt:",
    "section-main.contact": "Ansprechpartner",
    "section-main.email": "Email",
    "section-main.phone": "Telefon",
    "section-main.mobile": "Mobile",
    "section-main.payment.info": "Zahlungsinformationen",
    "section-main.payment.recipient.name": "Empfängername",
    "section-main.payment.recipient.surname": "Name",
    "section-main.iban": "IBAN",
    "section-main.payment.period": "Zahlungsfrist in Tagen",
    "section-main.billing.preferences": "Billing preferences ",
    "section-main.current.billing.frequency": "Aktuelle Abrechnungsfrequenz",
    "section-main.next.billing.frequency": "Nächste Abrechnungsfrequenz",
    "section-main.next.billing.frequency.BIANNUAL": "Halbjährlich",
    "section-main.next.billing.frequency.MONTHLY": "Monatlich",
    "section-main.next.billing.frequency.QUARTERLY": "Quartal",
    "section-main.next.billing.frequency.YEARLY": "Jährlich",
    "section-main.next.billing.frequency.YEARLY_ROLLING": "Jährliches Rollen",
    "section-my-zev.product.product": "Produkt:",
    "section-my-zev.product.service-fee": "Dienstleistungsgebühr pro Monat:",
    "section-my-zev.product.monthly-running-costs": "Laufende Kosten pro Monat und Zähler",
    "section-my-zev.product.ex.vat": "exkl. MwSt.",
    "section-my-zev.product.service.fee": "Dienstleistungsgebühr pro Monat:",
    "section-table.header.unit": "Einheit",
    "section-table.header.price": "Preis",
    "section-table.header.total": "Summe",
    "section-subsection-title-monthly": "Monatliche Gebühr",
    "section-subsection-title-initial": "Vorgebühr Erstgebühr",
    "section-subsection-title-initialPerConsumptionPoint": "Startgebühr pro Verbrauchsgebühr",
    "section-subsection-title-monthlyPerConsumptionPoint": "Monatlicher Betrag pro Verbrauchsstelle",
    "section-subsection-title-monthlySpecificPerConsumptionPoint":
      "Monatlicher Betrag pro Ausführungs-spezifische Verbrauchsstelle",
    "section-subsection-service-fee-total": "Dienstleistungsgebühr pro Monat:",
    "section-main.tab.priceCalculator.title": "Strompreiskalkulator",
    "section-main.tab.priceCalculator.body":
      "Mit dem Preiskalkulator von CKW bieten wir Ihnen die Möglichkeit, mit wenigen individuellen Angaben zu Ihrem  ZEV, die Strompreise für das ausgewählte Jahr zu berechnen. Dabei werden die gesetzlichen Vorgaben (Mietergesetz) berücksichtigt und Ihnen für die jeweiligen Preiskomponenten Preisvorschläge unterbreitet. Sie können diese annehmen oder selbst berechnete Strompreise verwenden. Die Verantwortung liegt bei Ihnen.",
    "section-main.tab.priceCalculator.calculatorVideo":
      "Informationen zur Nutzung des Strompreiskalkulators finden sie im",
    "section-main.tab.priceCalculator.calculatorVideoLink": "Erklärvideo",
    "section-main.tab.priceCalculator.dataAvailable": "Daten vorliegend",
    "section-main.tab.priceCalculator.dataMissing": "Daten fehlend",
    "section-main.tab.priceCalculator.notSelected": "nicht ausgewählt",
    "section-main.tab.priceCalculator.dataReset": "Daten zurücksetzen",
    "section-main.tab.priceCalculator.error":
      "Leider konnten wir nicht die Tarife oder Energiedaten für Ihr ZEV finden. Die Energiedaten können erst verwendet werden, wenn ein komplettes Vorjahr abgeschlossen ist. Bitte probieren Sie es erneut oder kontaktieren Sie unseren Support.",
    "section-main.tab.priceCalculator.reset.button.explanation":
      'Sie können die Verbräuche anpassen, indem Sie diese im entsprechenden Feld überschreiben. Dies kann nützlich sein, wenn Ihr ZEV noch nicht ein ganzes Jahr in Betrieb ist. Mit dem Button "Daten zurücksetzten", werden wieder die bestehenden Daten eingesetzt.',
    "section-main.tab.priceCalculator.textfield.units.years": "  Jahre",

    "section-main.tab.priceCalculator.investmentInformation.constraint":
      "Die Rendite auf Investitionskosten muss grösser sein als null.",
    "section-main.tab.priceCalculator.investmentInformation.title": "Investitionsangaben & Betriebskosten",
    "section-main.tab.priceCalculator.investmentInformation.subtitle": "Investitionsangaben",
    "section-main.tab.priceCalculator.investmentInformation.investmentCosts": "Investitionskosten",
    "section-main.tab.priceCalculator.investmentInformation.investmentCosts.info":
      "Beschaffungskosten für Ihre ZEV Solaranlage und deren Bau. Einmalvergütungen und sonstige Vergünstigungen müssen hier abgezogen werden.",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod": "Abschreibedauer",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod.info":
      "Als Standardwert wird hier 25 Jahre definiert. Dieser Wert kann individuell angepasst werden.",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod.default": "25",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts": "Rendite auf Investitionskosten",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts.info":
      "Bei vermieteten Objekten muss hier das Mietrecht eingehalten werden. Eigentümern von Objekten sind keine Grenzen gesetzt. Der Wert kann individuell angepasst werden.",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts.default": "0.0325",

    "section-main.tab.priceCalculator.operation.subtitle": "Einspeisung & Betrieb",
    "section-main.tab.priceCalculator.operation.returnOnDelivery": "Rückliefervergütung",
    "section-main.tab.priceCalculator.operation.returnOnDelivery.info":
      "Die Einspeisevergütung für Energie richtet sich nach der Berechnung des Referenzmarktpreises gemäss Art. 15 EnFV durch das Bundesamt für Energie (BFE). Dieser wird quartalsweise festgelegt und ist auf der BFE-Website (bfe.admin.ch) abrufbar.",
    "section-main.tab.priceCalculator.operation.operatingCostsProduction": "Betriebskosten Produktion",
    "section-main.tab.priceCalculator.operation.operatingCostsProduction.info":
      "Der Standardwert beträgt 3.0 Rp/kWh. Dieser Wert kann individuell angepasst werden.",
    "section-main.tab.priceCalculator.operation.operatingCostsProduction.default": "0.03",

    "section-main.tab.priceCalculator.consumptionAndTariff.title": "Verbrauch und Tarif",
    "section-main.tab.priceCalculator.consumptionAndTariff.yearlyCalculation": "Jahr für die Berechnung der Abrechnung",
    "section-main.tab.priceCalculator.consumptionAndTariff.previousYearNote":
      "Hinweis: Es müssen die Vebrauchsdaten für das Vorjahr des gewählten Jahres vorliegen",

    "section-main.tab.priceCalculator.networkTariff.subtitle": "Netztarif",
    "section-main.tab.priceCalculator.networkTariff.purchasedFromGrid": "Stromprodukt Netzbezug",
    "section-main.tab.priceCalculator.networkTariff.purchasedFromGrid.info":
      "Das verwendete Stromprodukt der ZEV kann der letzten Stromrechnung von CKW entnommern werden.",

    "section-main.tab.priceCalculator.referenceTariff.subtitle": "Referenztarif",
    "section-main.tab.priceCalculator.referenceTariff.zevParticipants": "Referenzstromprodukt für ZEV-Teilnehmer",
    "section-main.tab.priceCalculator.referenceTariff.zevParticipants.info":
      "Das Referenzstromprodukt dient dazu um sicherzustellen, dass bei vermieteten Objekten die gesetzlichen Vorgaben eingehaltet werden.",

    "section-main.tab.priceCalculator.tariff.highTariff": "Hochtarif",
    "section-main.tab.priceCalculator.tariff.highTariff.info": "highTariff_info",
    "section-main.tab.priceCalculator.tariff.lowTariff": "Niedertarif",
    "section-main.tab.priceCalculator.tariff.lowTariff.info": "lowTariff_info",
    "section-main.tab.priceCalculator.tariff.basicPricePerformance": "Grundpreis",
    "section-main.tab.priceCalculator.tariff.basicPricePerformance.info": "basicPricePerformance_info",
    "section-main.tab.priceCalculator.tariff.spikePrice": "Leistungspreis",
    "section-main.tab.priceCalculator.tariff.spikePrice.info": "spikePrice_info",

    "section-main.tab.priceCalculator.yearlyConsumption.subtitle": "Verbrauch pro Jahr",
    "section-main.tab.priceCalculator.yearlyConsumption.info":
      "Die Daten wurden aus dem Vorjahr {{ year }} übernommen.",
    "section-main.tab.priceCalculator.calculatePrices.button": "Preise berechnen",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseHighTariff": "Netzbezug Hochtarif",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseHighTariff.info": "purchaseHighTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseLowTariff": "Netzbezug Niedertarif",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseLowTariff.info": "purchaseLowTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseTotal": "Netzbezug Gesamt",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseTotal.info": "purchaseTotal_info",
    "section-main.tab.priceCalculator.yearlyConsumption.solarSystemProduction": "Produktion Solaranlage",
    "section-main.tab.priceCalculator.yearlyConsumption.solarSystemProduction.info": "solarSystemProduction_info",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionHighTariff": "Eigenverbrauch Hochtarif",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionHighTariff.info":
      "selfConsumptionHighTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionLowTariff": "Eigenverbrauch Niedertarif",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionLowTariff.info": "selfConsumptionLowTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.ownConsumptionTotal": "Eigenverbrauch Gesamt",
    "section-main.tab.priceCalculator.yearlyConsumption.ownConsumptionTotal.info": "ownConsumptionTotal_info",
    "section-main.tab.priceCalculator.yearlyConsumption.feed": "Einspeisung",
    "section-main.tab.priceCalculator.yearlyConsumption.feed.info": "feed_info",
    "section-main.tab.priceCalculator.yearlyConsumption.totalConsumption": "Gesamtverbrauch",
    "section-main.tab.priceCalculator.yearlyConsumption.totalConsumption.info": "totalConsumption_info",
    "section-main.tab.priceCalculator.yearlyConsumption.averagePerformance": "Durchschnittliche Leistung",
    "section-main.tab.priceCalculator.yearlyConsumption.averagePerformance.info": "averagePerformance_info",

    "section-main.tab.priceCalculator.calculatedValues.title": "Berechnete Werte für Ihr ZEV",
    "section-main.tab.priceCalculator.calculatedValues.rpkwh": " Rp/kWh",
    "section-main.tab.priceCalculator.calculatedValues.chfmt": " CHF/Mt.",
    "section-main.tab.priceCalculator.calculatedValues.solarPowerPrice": "Preis Sonnenstrom",
    "section-main.tab.priceCalculator.calculatedValues.solarPowerPrice.info": "solarPowerPrice",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceHighTariff": "Preis Netzbezug Hochtarif",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceHighTariff.info": "gridPurchasePriceHighTariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceLowTariff": "Preis Netzbezug Niedertarif",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceLowTariff.info": "gridPurchasePriceLowTariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPriceMeasurement": "Preis Messung & DL",
    "section-main.tab.priceCalculator.calculatedValues.gridPriceMeasurement.info": "gridPriceMeasurement",
    "section-main.tab.priceCalculator.calculatedValues.cta": "Speichern und Preise übernehmen",
    "section-main.tab.priceCalculator.calculatedValues.notCalculated":
      "Die Preise wurden noch nicht berechnet. Vervollständigen Sie die Angaben und berechnen Sie die Preise.",
    "section-main.tab.priceCalculator.calculatedValues.referenceProductCheaper":
      "Die Gestehungskosten Ihres ZEVs ({{ value }} Rp/kWh) übersteigen die Kosten des Referenzprodukts. Daher wurde der Preis für Sonnenstrom so angepasst, dass diese das Referenzprodukt nicht übersteigen.",
    "section-main.tab.priceCalculator.prices": "Preise",
    "section-main.tab.priceCalculator.vat.excluded": "exkl. MwSt.",
    "section-main.tab.priceCalculator.vat.included": "inkl. MwSt.",
    "section-main.tab.priceCalculator.update.consumptionPointsPrices": "Preise übernehmen für Verbrauchsstellen",
    "section-main.tab.priceCalculator.consumptionPointsPrices.newPricePackage": "Name vom neuen Preispaket",
    "section-main.tab.priceCalculator.consumptionPointsPrices.consumptionPoint": "Verbrauchsstellen",
    "section-main.tab.priceCalculator.consumptionPointsPrices.title": "Preise für Verbrauchsstellen übernehmen",

    "section-main.tab.prices.zev.solar.power": "ZEV Sonnenstrom / kWh",
    "section-main.tab.prices.mains.electricity.high.tarrif.power": "Netzstrom Hochtarif / kWh",
    "section-main.tab.prices.mains.power.off-peak": "Netzstrom Niedertarif / kWh",
    "section-main.tab.prices.performance.price": "Leistungspreis / KW / Monat",
    "section-main.tab.prices.metering.service": "Messung und Dienstleistung / kWh",
    "section-main.tab.prices.metering.service.isFixed": "Messung und Dienstleistung / Monat",
    "section-main.tab.prices.zev.title": "Titel",
    "form.field.title": "Titel",
    "section-main.tab.prices.without.performance": "Ohne Leistungspreis",
    "section-main.tab.prices.with.performance": "Mit Leistungspreis",
    "section-main.tab.prices.consumption": "Verbrauch",
    "section-main.tab.prices.dependent": "abhängig",
    "section-main.tab.prices.lump.sum": "Pauschal",
    "section-main.tab.prices.without": "Ohne",
    "section-main.tab.prices.with": "Mit",
    "section-main.tab.prices.performance": "Leistungspreis",
    "form.field.solarPower": "ZEV Sonnenstrom / kWh",
    "form.field.highTariff": "Netzstrom Hochtarif / kWh",
    "form.field.lowTariff": "Netzstrom Niedertarif / kWh",
    "form.toggle.consumptionDependant": "Verbrauchsabhängig",
    "form.toggle.flatRate": "Pauschal",
    "form.toggle.spike.without": "Ohne Leistungspreis",
    "form.toggle.spike.with": "Mit Leistungspreis",
    "form.field.measurementServicePerHour": "Messung und Dienstleistung / kWh",
    "form.field.new.form.title": "Neues Preispaket",
    "form.field.name.validation.error": "Name",
    "form.field.recipient.name.validation.error": "Empfänger Name",
    "form.field.recipient.iban.validation.error": "Empfänger IBAN",
    "form.field.recipient.paymentDeadlineInDays.validation.error": "Zahlungsfrist in Tagen",
    "form.select.picker": "Bitte auswählen",
    "form.field.telephone": "Telefon",
    "form.field.mobile": "Mobile",
  },
  en: {
    vatTitle: "MwSt-ID",
    noVat: "not subject to VAT",
    "section-tab.billing.preferences": "Billing preferences",
    "section-tab.zev.product": "ZEV product",
    "section-tab.electricity.calculator": "Electricity Price Calculator",
    "section-tab.prices": "Prices",
    "section-info.subtitle": "The following information will be provided to participants on the statement:",
    "section-main.contact": "Contact person",
    "section-main.email": "Email",
    "section-main.phone": "Phone",
    "section-main.mobile": "Mobile",
    "section-main.payment.info": "Payment information",
    "section-main.payment.recipient.name": "Recipient name",
    "section-main.payment.recipient.surname": "Name",
    "section-main.iban": "IBAN",
    "section-main.payment.period": "Payment period in days",
    "section-main.billing.preferences": "Abrechnungseinstellungen",
    "section-main.current.billing.frequency": "Current billing frequency",
    "section-main.next.billing.frequency.BIANNUAL": "Biannual",
    "section-main.next.billing.frequency.MONTHLY": "Monthly",
    "section-main.next.billing.frequency.QUARTERLY": "Quarterly",
    "section-main.next.billing.frequency.YEARLY": "Annual",
    "section-main.next.billing.frequency.YEARLY_ROLLING": "Annual Rolling",
    "section-main.next.billing.frequency": "Next billing frequency",
    "section-my-zev.product.product": "product:",
    "section-my-zev.product.service-fee": "Service fee per month:",
    "section-my-zev.product.monthly-running-costs": "Running costs per month and meter:",
    "section-my-zev.product.ex.vat": "excl. VAT",
    "section-my-zev.product.service.fee": "Service fee per month",
    "section-table.header.unit": "Unit",
    "section-table.header.price": "Price",
    "section-table.header.total": "Total",
    "section-subsection-title-monthly": "Monthly fee",
    "section-subsection-title-initial": "Initial fee",
    "section-subsection-title-initialPerConsumptionPoint": "Initial fee per consumption fee",
    "section-subsection-title-monthlyPerConsumptionPoint": "Monthly fee per consumption fee",
    "section-subsection-title-monthlySpecificPerConsumptionPoint": "Monthly specific fee per consumption fee",
    "section-subsection-service-fee-total": "Service fee per month:",
    "section-main.tab.priceCalculator.title": "Electricity price calculator",
    "section-main.tab.priceCalculator.body":
      "With the price calculator from CKW, we offer you the option of calculating the electricity prices for the selected year with just a few individual details about your ZEV. The legal requirements (tenant law) are taken into account and price proposals are made for the respective price components. You can accept them or use the electricity prices you have calculated yourself. The responsibility is yours.",
    "section-main.tab.priceCalculator.calculatorVideo":
      "Information on using the electricity price calculator can be found in the",
    "section-main.tab.priceCalculator.calculatorVideoLink": "Tutorial",
    "section-main.tab.priceCalculator.dataAvailable": "Data available",
    "section-main.tab.priceCalculator.dataMissing": "Missing data",
    "section-main.tab.priceCalculator.notSelected": "Not selected",
    "section-main.tab.priceCalculator.dataReset": "Daten Reset",
    "section-main.tab.priceCalculator.error":
      "Unfortunately we could not find the tariffs or energy data for your ZEV. The energy data can only be used after a complete previous year has been completed. Please try again or contact our support.",
    "section-main.tab.priceCalculator.reset.button.explanation":
      'You can adjust the consumption by overwriting it in the corresponding field. This can be useful if your ZEV has not been in operation for a full year. With the "Reset data" button, the existing data are used again.',
    "section-main.tab.priceCalculator.textfield.units.years": "  Years",

    "section-main.tab.priceCalculator.investmentInformation.constraint":
      "The return on investment must be bigger than zero.",
    "section-main.tab.priceCalculator.investmentInformation.title": "Investment information & operating costs",
    "section-main.tab.priceCalculator.investmentInformation.subtitle": "Investment information",
    "section-main.tab.priceCalculator.investmentInformation.investmentCosts": "Investment Costs",
    "section-main.tab.priceCalculator.investmentInformation.investmentCosts.info": "investmentCosts_info_here",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod": "Depreciation period",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod.info": "depreciationPeriod_info_here",
    "section-main.tab.priceCalculator.investmentInformation.depreciationPeriod.default": "25",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts": "Return on investment costs",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts.info":
      "returnOnInvestmentCosts_info_here",
    "section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts.default": "0.0325",
    "section-main.tab.priceCalculator.operation.subtitle": "Feed-in & operation",
    "section-main.tab.priceCalculator.consumptionAndTariff.title": "Consumption and tariff",
    "section-main.tab.priceCalculator.consumptionAndTariff.yearlyCalculation": "Year for the calculation of the bill",
    "section-main.tab.priceCalculator.consumptionAndTariff.previousYearNote":
      "Note: The consumption data for the previous year of the selected year must be available",

    "section-main.tab.priceCalculator.networkTariff.subtitle": "Network Tariff",
    "section-main.tab.priceCalculator.networkTariff.purchasedFromGrid": "Electricity tariff purchased from the grid",
    "section-main.tab.priceCalculator.networkTariff.purchasedFromGrid.info": "purchasedFromGrid_info",

    "section-main.tab.priceCalculator.referenceTariff.subtitle": "Reference Tariff",
    "section-main.tab.priceCalculator.referenceTariff.zevParticipants":
      "Reference electricity tariff for ZEV participants",
    "section-main.tab.priceCalculator.referenceTariff.zevParticipants.info": "zevParticipants_info",

    "section-main.tab.priceCalculator.tariff.highTariff": "High Tariff",
    "section-main.tab.priceCalculator.tariff.highTariff.info": "highTariff_info",
    "section-main.tab.priceCalculator.tariff.lowTariff": "Low Tariff",
    "section-main.tab.priceCalculator.tariff.lowTariff.info": "lowTariff_info",
    "section-main.tab.priceCalculator.tariff.basicPricePerformance": "Basic price performance",
    "section-main.tab.priceCalculator.tariff.basicPricePerformance.info": "basicPricePerformance_info",
    "section-main.tab.priceCalculator.tariff.spikePrice": "Performance price",
    "section-main.tab.priceCalculator.tariff.spikePrice.info": "spikePrice_info",

    "section-main.tab.priceCalculator.yearlyConsumption.subtitle": "Consumption per year",
    "section-main.tab.priceCalculator.yearlyConsumption.info": "The data was taken from the previous year {{ year }}.",
    "section-main.tab.priceCalculator.calculatePrices.button": "Preise berechnen",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseHighTariff": "Purchase high tariff",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseHighTariff.info": "purchaseHighTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseLowTariff": "Purchase low tariff",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseLowTariff.info": "purchaseLowTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseTotal": "Purchase Total",
    "section-main.tab.priceCalculator.yearlyConsumption.purchaseTotal.info": "purchaseTotal_info",
    "section-main.tab.priceCalculator.yearlyConsumption.solarSystemProduction": "Solar system production",
    "section-main.tab.priceCalculator.yearlyConsumption.solarSystemProduction.info": "solarSystemProduction_info",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionHighTariff": "Consumption high tariff",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionHighTariff.info":
      "selfConsumptionHighTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionLowTariff": "Consumption low tariff",
    "section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionLowTariff.info": "selfConsumptionLowTariff_info",
    "section-main.tab.priceCalculator.yearlyConsumption.ownConsumptionTotal": "Own consumption total",
    "section-main.tab.priceCalculator.yearlyConsumption.ownConsumptionTotal.info": "ownConsumptionTotal_info",
    "section-main.tab.priceCalculator.yearlyConsumption.feed": "Feed",
    "section-main.tab.priceCalculator.yearlyConsumption.feed.info": "feed_info",
    "section-main.tab.priceCalculator.yearlyConsumption.totalConsumption": "Total consumption",
    "section-main.tab.priceCalculator.yearlyConsumption.totalConsumption.info": "totalConsumption_info",
    "section-main.tab.priceCalculator.yearlyConsumption.averagePerformance": "Average performance",
    "section-main.tab.priceCalculator.yearlyConsumption.averagePerformance.info": "averagePerformance_info",

    "section-main.tab.priceCalculator.calculatedValues.title": "Calculated values for your ZEV",
    "section-main.tab.priceCalculator.calculatedValues.rpkwh": " Rp/kWh",
    "section-main.tab.priceCalculator.calculatedValues.chfmt": " CHF/Mt.",
    "section-main.tab.priceCalculator.calculatedValues.solarPowerPrice": "Solar power price",
    "section-main.tab.priceCalculator.calculatedValues.solarPowerPrice.info": "solarPowerPrice",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceHighTariff": "Grid purchase price high tariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceHighTariff.info": "gridPurchasePriceHighTariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceLowTariff": "Grid purchase price low tariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceLowTariff.info": "gridPurchasePriceLowTariff",
    "section-main.tab.priceCalculator.calculatedValues.gridPriceMeasurement": "Price measurement & DL",
    "section-main.tab.priceCalculator.calculatedValues.gridPriceMeasurement.info": "gridPriceMeasurement",
    "section-main.tab.priceCalculator.calculatedValues.cta": "Save and apply prices",
    "section-main.tab.priceCalculator.calculatedValues.notCalculated":
      "The prices have not yet been calculated. Complete the information and calculate the prices.",
    "section-main.tab.priceCalculator.calculatedValues.referenceProductCheaper":
      "The production costs of your ZEV ({{ value }} Rp / kWh) exceed the costs of the reference product. Therefore, the prices have been adjusted so that they do not exceed the reference product.",
    "section-main.tab.priceCalculator.prices": "Prices",
    "section-main.tab.priceCalculator.vat.excluded": "excl. VAT",
    "section-main.tab.priceCalculator.vat.included": "incl. VAT",
    "section-main.tab.priceCalculator.update.consumptionPointsPrices": "Adopt prices for consumption points",
    "section-main.tab.priceCalculator.consumptionPointsPrices.newPricePackage": "New price package name",
    "section-main.tab.priceCalculator.consumptionPointsPrices.consumptionPoint": "Consumption Points",
    "section-main.tab.priceCalculator.consumptionPointsPrices.title": "Update prices for consumption points",

    "section-main.tab.prices.zev.title": "Title",
    "form.field.title": "Title",
    "section-main.tab.prices.zev.solar.power": "ZEV solar power / kWh",
    "section-main.tab.prices.mains.electricity.high.tarrif.power": "Mains electricity high tariff / kWh",
    "section-main.tab.prices.mains.power.off-peak": "Mains power off peak / kWh",
    "section-main.tab.prices.performance.price": "Performance price / kWh / month",
    "section-main.tab.prices.metering.service": "Measurement and service / kWh",
    "section-main.tab.prices.without": "Without",
    "section-main.tab.prices.performance": "performance",
    "section-main.tab.prices.price": "price",
    "section-main.tab.prices.with.performance": "With performance price",
    "section-main.tab.prices.consumption": "Consumption",
    "section-main.tab.prices.dependent": "dependent",
    "section-main.tab.prices.lump": "Lump",
    "section-main.tab.prices.sum": "sum",
    "form.field.solarPower": "ZEV solar power / kWh",
    "form.field.highTariff": "Mains electricity high tariff / kWh",
    "form.field.lowTariff": "Low tariff grid electricity / kWh",
    "form.toggle.consumptionDependant": "Consumption-dependent",
    "form.toggle.flatRate": "Flat-rate",
    "form.toggle.spike.without": "Without spike prices",
    "form.toggle.spike.with": "With spike prices",
    "form.field.measurementServicePerHour": "Measurement and service / kWh",
    "form.field.new.form.title": "New price package",
    "section-main.tab.prices.lump.sum": "Lump sum",
    "form.field.name.validation.error": "Name",
    "form.field.recipient.name.validation.error": "Recipient name",
    "form.field.recipient.iban.validation.error": "Recipient IBAN",
    "form.field.recipient.paymentDeadlineInDays.validation.error": "Payment period in days",
    "form.select.picker": "Please select",
    "form.field.telephone": "PHONE",
    "form.field.mobile": "MOBILE",
  },
}

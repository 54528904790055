import i18next, { TOptions } from "i18next"
import { StatusType } from "../domain/Domain.Model"
import { matchPath } from "react-router-dom"
import { AxiosError } from "axios"
import { AdminPortalRouteParams } from "./portal/admin/AdminPortal.Routes"
import { FormikComputedProps, FormikHandlers, FormikHelpers, FormikState } from "formik"
import { AdminPortalRouteEnums } from "./portal/admin/AdminPortal.Enums"
import { ColumnEnumInterface } from "./portal/admin/sharedComponentsConfig/Components.Interfaces"
import { ServiceComponentFeature } from "../data/generated-sources/openapi"
import { DOMAIN_DEPENDENCIES } from "./App.Config"
import { AuthType } from "../domain/auth/Auth.Model"

export type StatusTypeObjectKey = keyof typeof StatusType

export interface CommonFormikProps<T>
  extends Pick<FormikHandlers, "handleChange" | "handleBlur">,
    Pick<FormikHelpers<T>, "setFieldValue" | "setFieldTouched">,
    Partial<FormikComputedProps<T>>,
    Pick<FormikState<T>, "errors" | "values" | "touched"> {}

export type AxiosErrorDataType = AxiosError<{ code: string; message: string }>

export const scrollToPageTop = () => window.scrollTo({ top: 0, behavior: "smooth" })

export const isActiveStatus = (status: string) => status === "ACTIVE"

export const isInactiveStatus = (status: string) => status === StatusType.INACTIVE

export const getPropertyFromObject = <T, K extends keyof T>(o: T, propertyName: K): T[K] => {
  return o[propertyName]
}

export const removeEmptyPropertiesFromObject = (obj: ObjectConstructor) =>
  Object.fromEntries(Object.entries(obj).filter(([value]) => value))

export const getTranslateValue = (label: string, options?: TOptions) => i18next.t(label, options)

export const getParamsFromUrl = (path: string): AdminPortalRouteParams => {
  const { pathname } = window.location
  const match = matchPath(pathname, { path })

  return match?.params as AdminPortalRouteParams
}

export const getExactParamFromUrl = (url: string, param: string) => {
  type objectKey = keyof typeof AdminPortalRouteEnums
  const params = getParamsFromUrl(url)
  return params && getPropertyFromObject(params, param as objectKey)
}

export const useQueryDefaultOptions = {
  refetchOnWindowFocus: false,
}

export const getParamFromResponseUrl = (url: string, separator: string) => url.split(separator)[1]

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterObject = { [key: string | number | symbol]: any }

const compareObjValue = (value: object, query: string): string | undefined => {
  const stack: (string | object)[] = [value]

  while (stack.length > 0) {
    const valCurrent = stack.shift()
    if (typeof valCurrent === "string") {
      if (
        valCurrent?.toLowerCase().includes(query?.toLowerCase()) ||
        (getTranslateValue(`shared:status.${valCurrent?.toUpperCase()}`) || "").includes(query?.toUpperCase())
      ) {
        return valCurrent
      }
    } else if (typeof valCurrent === "object" && valCurrent !== null) {
      for (const val of Object.values(valCurrent)) {
        stack.push(val)
      }
    }
  }
  return undefined
}

export const filterArrayBySearchInput = <T extends FilterObject>(arrayData: Array<T>, query?: string): Array<T> => {
  if (!query) return arrayData

  return arrayData?.filter((object) => {
    if (!object) return

    return Object?.values(object).some((value) => {
      if (typeof value === "string") {
        return (
          value?.toLowerCase().includes(query?.toLowerCase()) ||
          (getTranslateValue(`shared:status.${value?.toUpperCase()}`) || "").includes(query?.toUpperCase())
        )
      }
      if (typeof value === "object" && value !== null && value !== undefined) {
        return Object?.values(value).some((val) => {
          if (typeof val === "string") {
            return (
              val?.toLowerCase().includes(query?.toLowerCase()) ||
              (getTranslateValue(`shared:status.${val?.toUpperCase()}`) || "").includes(query?.toUpperCase())
            )
          }
          if (typeof val === "object" && val) {
            return compareObjValue(val, query)
          }
        })
      }
    })
  })
}

export const generateRedirectUrlWithQueries = (url: string, searchParams: ColumnEnumInterface): string => {
  let formattedUrl: string = url

  Object.keys(searchParams).forEach((key: string, index: number): void => {
    if (formattedUrl.includes(`${key}=`)) return
    formattedUrl += `${!index ? "?" : "&"}${key}=${searchParams[key]}`
  })

  return formattedUrl
}

export const hasCollectionFeature = (features: ServiceComponentFeature[]): boolean =>
  features?.includes(ServiceComponentFeature.COLLECTION)

export const getPortalType = () => {
  const APP_NAME: string = DOMAIN_DEPENDENCIES.config.appName
  return DOMAIN_DEPENDENCIES.cookie.readCookieAuthType(APP_NAME)
}

export const isManagerPortal = () => getPortalType() === AuthType.MANAGER
export const isAdminPortal = () => getPortalType() === AuthType.ADMINISTRATOR

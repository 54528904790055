import { Grid } from "@mui/material"
import { useMemo } from "react"
import { appThemeGrey10, appThemeGrey40, appThemeGrey50, appThemeGrey100 } from "../../app/Shared.Theme"

import { SmallPrimaryMinusButton } from "../button/PrimaryMinusButton"
import { SmallPrimaryPlusButton } from "../button/PrimaryPlusButton"
import { InlineDatePicker } from "./InlineDatePicker"
import moment from "moment"

interface DateDaySelectorViewProps {
  selectedDateValue: number
  onDateChanged: (newDateTime: number) => void
  disabled?: boolean
}

export const DateDaySelectorView = (props: DateDaySelectorViewProps) => {
  const { selectedDateValue, onDateChanged, disabled } = props
  const selectedDate: Date = useMemo(() => new Date(selectedDateValue), [selectedDateValue])
  const disabledPlusButton = useMemo(
    () => new Date(selectedDateValue).getTime() >= new Date().getTime() - 48 * 3600 * 1000,
    [selectedDateValue],
  )
  const maxDate = moment().subtract(1, "days").toDate()
  const buttonBackground = [appThemeGrey40, appThemeGrey50]
  const selectorStyle = { background: appThemeGrey10, padding: 8, borderRadius: 3 }
  const dateOutputStyle = { flex: 1, minWidth: 180, justifyContent: "center", alignItems: "center", display: "flex" }
  return (
    <>
      <Grid style={selectorStyle} container>
        <SmallPrimaryMinusButton
          customSize={30}
          color={appThemeGrey100}
          background={buttonBackground}
          disabled={disabled}
          onClick={() => {
            onDateChanged(selectedDate.setDate(selectedDate.getDate() - 1))
          }}
        />
        <output style={dateOutputStyle}>
          {maxDate ? (
            <InlineDatePicker
              pickerType="day"
              maxDate={maxDate}
              selectedDate={selectedDate}
              onChange={(date) => {
                if (date) onDateChanged(date.getTime())
              }}
            />
          ) : null}
        </output>
        <SmallPrimaryPlusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabledPlusButton || disabled}
          onClick={() => {
            onDateChanged(selectedDate.setDate(selectedDate.getDate() + 1))
          }}
        />
      </Grid>
    </>
  )
}

import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { AddTariffToContractRequest } from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"

export const customerGetAllTariffSchedules = async () => {
  const { data } = await DOMAIN_DEPENDENCIES.customerTariffSchedulesApi.customerGetAllTariffSchedules(
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const customerGetTariffScheduleByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerTariffSchedulesApi.customerGetTariffScheduleForContract(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerAddTariffScheduleToContract = async (
  contractId: string,
  addTariffToContractRequest: AddTariffToContractRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerTariffSchedulesApi.customerAddTariffScheduleToContract(
    contractId,
    addTariffToContractRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

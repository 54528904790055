import { Box, Dialog, DialogTitle, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"

import { AbortIcon } from "../../../../../uikit/Shared.Icon"
import { getParamFromResponseUrl } from "../../../../Shared.Utils"
import { ParticipantRegisterForm } from "./ParticipantRegisterForm"

interface ParticipantCreateProps {
  show: boolean
  onClose: () => void
  onCreateSuccess: (participantId: string) => void
}

export const ParticipantRegister = ({ show, onClose, onCreateSuccess }: ParticipantCreateProps) => {
  const { t } = useTranslation("customer-participants")

  const handleCreateSuccess = (url: string) => {
    const createdParticipantId = getParamFromResponseUrl(url, "participants/")
    onCreateSuccess && onCreateSuccess(createdParticipantId)
    onClose()
  }

  return (
    <Dialog open={show} onClose={onClose} maxWidth="md" fullWidth>
      <Box p={2}>
        <DialogTitle>{t("registerForm.headerTitle")}</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            right: 35,
            top: 28,
            color: "#000",
          }}
        >
          <AbortIcon />
        </IconButton>
        <ParticipantRegisterForm onClose={onClose} onCreateSuccess={handleCreateSuccess} />
      </Box>
    </Dialog>
  )
}

import { LOCATION_CHANGE } from "connected-react-router"
import { AnyAction } from "redux"
import { CustomerProfile } from "../../../../domain/portal/manager/profile/Profile.Model"
import {
  asError,
  asStarted,
  asSuccess,
  initialViewState,
  startLoading,
  ViewState,
  withDomainError,
  withDomainResult,
} from "../../../Shared.Reducer"
import { ProfileActionType } from "./Profile.Epic"

export interface ProfileDetailState {
  viewState: ViewState<CustomerProfile>
  updatePersonalViewState: ViewState<boolean>
  updateAddressViewState: ViewState<boolean>
  updateContactViewState: ViewState<boolean>
  updateEmailViewState: ViewState<boolean>
}

export const initialState: ProfileDetailState = {
  viewState: initialViewState(),
  updatePersonalViewState: initialViewState(),
  updateAddressViewState: initialViewState(),
  updateContactViewState: initialViewState(),
  updateEmailViewState: initialViewState(),
}

export const ProfileDetailReducer = (
  state: ProfileDetailState = initialState,
  action: AnyAction,
): ProfileDetailState => {
  switch (action.type) {
    case asStarted(ProfileActionType.CUSTOM_PROFILE_GET):
      return {
        ...state,
        viewState: startLoading<CustomerProfile>(state.viewState),
      }
    case asSuccess(ProfileActionType.CUSTOM_PROFILE_GET):
      return {
        ...state,
        viewState: withDomainResult<CustomerProfile>(state.viewState, action.result),
      }
    case asError(ProfileActionType.CUSTOM_PROFILE_GET):
      return {
        ...state,
        viewState: withDomainError<CustomerProfile>(state.viewState, action.result),
      }
    case asStarted(ProfileActionType.CUSTOM_PROFILE_UPDATE_ADDRESS):
      return {
        ...state,
        updateAddressViewState: startLoading<boolean>(state.updateAddressViewState),
      }
    case asSuccess(ProfileActionType.CUSTOM_PROFILE_UPDATE_ADDRESS):
      return {
        ...state,
        updateAddressViewState: withDomainResult<boolean>(state.updateAddressViewState, action.result),
      }
    case asError(ProfileActionType.CUSTOM_PROFILE_UPDATE_ADDRESS):
      return {
        ...state,
        updateAddressViewState: withDomainError<boolean>(state.updateAddressViewState, action.result),
      }
    case asStarted(ProfileActionType.CUSTOM_PROFILE_UPDATE_CONTACT):
      return {
        ...state,
        updateContactViewState: startLoading<boolean>(state.updateContactViewState),
      }
    case asSuccess(ProfileActionType.CUSTOM_PROFILE_UPDATE_CONTACT):
      return {
        ...state,
        updateContactViewState: withDomainResult<boolean>(state.updateContactViewState, action.result),
      }
    case asError(ProfileActionType.CUSTOM_PROFILE_UPDATE_CONTACT):
      return {
        ...state,
        updateContactViewState: withDomainError<boolean>(state.updateContactViewState, action.result),
      }
    case asStarted(ProfileActionType.CUSTOM_PROFILE_UPDATE_EMAIL):
      return {
        ...state,
        updateEmailViewState: startLoading<boolean>(state.updateEmailViewState),
      }
    case asSuccess(ProfileActionType.CUSTOM_PROFILE_UPDATE_EMAIL):
      return {
        ...state,
        updateEmailViewState: withDomainResult<boolean>(state.updateEmailViewState, action.result),
      }
    case asError(ProfileActionType.CUSTOM_PROFILE_UPDATE_EMAIL):
      return {
        ...state,
        updateEmailViewState: withDomainError<boolean>(state.updateEmailViewState, action.result),
      }
    case asStarted(ProfileActionType.CUSTOM_PROFILE_UPDATE_PERSONAL):
      return {
        ...state,
        updatePersonalViewState: startLoading<boolean>(state.updatePersonalViewState),
      }
    case asSuccess(ProfileActionType.CUSTOM_PROFILE_UPDATE_PERSONAL):
      return {
        ...state,
        updatePersonalViewState: withDomainResult<boolean>(state.updatePersonalViewState, action.result),
      }
    case asError(ProfileActionType.CUSTOM_PROFILE_UPDATE_PERSONAL):
      return {
        ...state,
        updatePersonalViewState: withDomainError<boolean>(state.updatePersonalViewState, action.result),
      }
    case LOCATION_CHANGE:
      return initialState
    default:
      return {
        ...state,
      }
  }
}

export const ProductIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      d="M20.5 11H19V7C19 5.9 18.1 5 17 5H13V3.5C13 2.12 11.88 1 10.5 1C9.12 1 8 2.12 8 3.5V5H4C2.9 5 2.01 5.9 2.01 7V10.8H3.5C4.99 10.8 6.2 12.01 6.2 13.5C6.2 14.99 4.99 16.2 3.5 16.2H2V20C2 21.1 2.9 22 4 22H7.8V20.5C7.8 19.01 9.01 17.8 10.5 17.8C11.99 17.8 13.2 19.01 13.2 20.5V22H17C18.1 22 19 21.1 19 20V16H20.5C21.88 16 23 14.88 23 13.5C23 12.12 21.88 11 20.5 11Z"
      fill="#4F544F"
    />
  </svg>
)
export const ProfileIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 12C17.88 12 18.99 10.88 18.99 9.5C18.99 8.12 17.88 7 16.5 7C15.12 7 14 8.12 14 9.5C14 10.88 15.12 12 16.5 12ZM9 11C10.66 11 11.99 9.66 11.99 8C11.99 6.34 10.66 5 9 5C7.34 5 6 6.34 6 8C6 9.66 7.34 11 9 11ZM16.5 14C14.67 14 11 14.92 11 16.75V19H22V16.75C22 14.92 18.33 14 16.5 14ZM9 13C6.67 13 2 14.17 2 16.5V19H9V16.75C9 15.9 9.33 14.41 11.37 13.28C10.5 13.1 9.66 13 9 13Z"
      fill="#4F544F"
    />
  </svg>
)
export const DocumentIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M19 3H14.82C14.4 1.84 13.3 1 12 1C10.7 1 9.6 1.84 9.18 3H5C3.9 3 3 3.9 3 5V19C3 20.1 3.9 21 5 21H19C20.1 21 21 20.1 21 19V5C21 3.9 20.1 3 19 3ZM12 3C12.55 3 13 3.45 13 4C13 4.55 12.55 5 12 5C11.45 5 11 4.55 11 4C11 3.45 11.45 3 12 3ZM14 17H7V15H14V17ZM17 13H7V11H17V13ZM17 9H7V7H17V9Z"
      fill="#4F544F"
    />
  </svg>
)
export const AreaIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17 11V3H7V7H3V21H11V17H13V21H21V11H17ZM7 19H5V17H7V19ZM7 15H5V13H7V15ZM7 11H5V9H7V11ZM11 15H9V13H11V15ZM11 11H9V9H11V11ZM11 7H9V5H11V7ZM15 15H13V13H15V15ZM15 11H13V9H15V11ZM15 7H13V5H15V7ZM19 19H17V17H19V19ZM19 15H17V13H19V15Z"
      fill="#4F544F"
    />
  </svg>
)
export const BuildingIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.17 5.70001L1 10.48V21H6V13H10V21H15V10.25L8.17 5.70001Z" fill="#4F544F" />
    <path
      d="M10 3V4.51L12 5.84L13.73 7H15V7.85L17 9.19V11H19V13H17V15H19V17H17V21H23V3H10ZM19 9H17V7H19V9Z"
      fill="#4F544F"
    />
  </svg>
)
export const UtilityUnitIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.99 18.54L4.62 12.81L3 14.07L12 21.07L21 14.07L19.37 12.8L11.99 18.54ZM12 16L19.36 10.27L21 9L12 2L3 9L4.63 10.27L12 16Z"
      fill="#4F544F"
    />
  </svg>
)
export const MeterIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 5H1V21C1 22.1 1.9 23 3 23H19V21H3V5ZM14 15H16V5H12V7H14V15ZM21 1H7C5.9 1 5 1.9 5 3V17C5 18.1 5.9 19 7 19H21C22.1 19 23 18.1 23 17V3C23 1.9 22.1 1 21 1ZM21 17H7V3H21V17Z"
      fill="#4F544F"
    />
  </svg>
)
export const BillIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M18 17H6V15H18V17ZM18 13H6V11H18V13ZM18 9H6V7H18V9ZM3 22L4.5 20.5L6 22L7.5 20.5L9 22L10.5 20.5L12 22L13.5 20.5L15 22L16.5 20.5L18 22L19.5 20.5L21 22V2L19.5 3.5L18 2L16.5 3.5L15 2L13.5 3.5L12 2L10.5 3.5L9 2L7.5 3.5L6 2L4.5 3.5L3 2V22Z"
      fill="#4F544F"
    />
  </svg>
)
export const TaskListIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M3 13H5V11H3V13ZM3 17H5V15H3V17ZM3 9H5V7H3V9ZM7 13H21V11H7V13ZM7 17H21V15H7V17ZM7 7V9H21V7H7Z"
      fill="#4F544F"
    />
  </svg>
)
export const ElectricityIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8737 5L11.0499 7.63082L5.35261 10.6335V11.6561H5.93686V13.4109H6.96014V11.6561H9.78843L9.05896 13.9884L3 16.991V18.0143H3.87739V19.7684H4.90068V18.0143H7.7975L5.92329 24H7.18L12.5 20.3853L17.8214 24H19.0774L17.2025 18.0143H20.1007V19.7684H21.1233V18.0143H22V16.991L15.9424 13.9884L15.2116 11.6561H17.7487V13.4109H18.7714V11.6561H19.6481V10.6335L13.9508 7.62946L13.127 5H11.8737ZM12.5 6.61364L12.8115 7.62946H12.1892L12.5 6.61364ZM11.8764 8.65411H13.125L13.7302 10.6335H11.2697L11.8764 8.65411ZM14.3349 8.85632L17.7074 10.6335H14.8913L14.3349 8.85632ZM10.6665 8.85632L10.1094 10.6335H7.29332L10.6665 8.85632ZM10.9569 11.6561H14.0437L14.7576 13.9884H10.2437L10.9569 11.6561ZM9.93025 15.0116H15.0704L15.6764 16.991H9.32429L9.93025 15.0116ZM16.3217 15.1996L19.9365 16.991H16.8829L16.3217 15.1996ZM8.67964 15.1996L8.11846 16.991H5.06421L8.67964 15.1996ZM13.4106 19.7657L15.9892 18.0143L17.343 22.4379L13.4106 19.7657ZM10.8321 18.0143H14.1693L12.5 19.1475L10.8321 18.0143ZM9.01146 18.0143L11.59 19.7657L7.65704 22.4379L9.01146 18.0143Z"
      fill="#4F544F"
    />
  </svg>
)
export const EnergySupplierIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.87368 0L8.04989 2.63082L2.35261 5.6335V6.65611H2.93686V8.41089H3.96014V6.65611H6.78843L6.05896 8.98836L0 11.991V13.0143H0.877393V14.7684H1.90068V13.0143H4.7975L2.92329 19H4.18L9.5 15.3853L14.8214 19H16.0774L14.2025 13.0143H17.1007V14.7684H18.1233V13.0143H19V11.991L12.9424 8.98836L12.2116 6.65611H14.7487V8.41089H15.7714V6.65611H16.6481V5.6335L10.9508 2.62946L10.127 0H8.87368ZM9.5 1.61364L9.81146 2.62946H9.18921L9.5 1.61364ZM8.87639 3.65411H10.125L10.7302 5.6335H8.26975L8.87639 3.65411ZM11.3349 3.85632L14.7074 5.6335H11.8913L11.3349 3.85632ZM7.6665 3.85632L7.10939 5.6335H4.29332L7.6665 3.85632ZM7.95693 6.65611H11.0437L11.7576 8.98836H7.24375L7.95693 6.65611ZM6.93025 10.0116H12.0704L12.6764 11.991H6.32429L6.93025 10.0116ZM13.3217 10.1996L16.9365 11.991H13.8829L13.3217 10.1996ZM5.67964 10.1996L5.11846 11.991H2.06421L5.67964 10.1996ZM10.4106 14.7657L12.9892 13.0143L14.343 17.4379L10.4106 14.7657ZM7.83207 13.0143H11.1693L9.5 14.1475L7.83207 13.0143ZM6.01146 13.0143L8.59004 14.7657L4.65704 17.4379L6.01146 13.0143Z"
      fill="#4F544F"
    />
  </svg>
)

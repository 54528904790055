import { Map } from "immutable"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box } from "@mui/material"

import { FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { Body1 } from "../../../../../uikit/typography/Typography"
import { UpdateContactUpsert } from "../../../../../domain/portal/manager/profile/Profile.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { validateProfileContactForm } from "./ProfileContactForm.validation"
import { PrimaryButton } from "../../../../../uikit/button"
import { bgColorGreen } from "../../../../Shared.Theme"

interface ProfileContactFormProps {
  editMode: boolean
  isLoading: boolean
  profileContactValues: UpdateContactUpsert
  dataChanged: (profileContactValues: UpdateContactUpsert) => void
}

export const ProfileContactForm = ({
  editMode,
  dataChanged,
  profileContactValues,
  isLoading,
}: ProfileContactFormProps) => {
  const { t } = useTranslation("profile")
  const [touched, setTouched] = useState<Map<string, boolean>>(Map())
  const [editBackgroundColor, setEditBackgroundColor] = useState("#ffffff")

  useEffect(() => {
    setEditBackgroundColor(editMode ? bgColorGreen : "#ffffff")
  }, [setEditBackgroundColor, editMode])

  return (
    <>
      <div
        style={{
          padding: "16px",
          marginBottom: "32px",
          backgroundColor: editBackgroundColor,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <FormView>
          <>
            <FormRowColumn>
              {editMode ? (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="mobile"
                    label={t("contact.mobile")}
                    value={profileContactValues.contact.mobile}
                    onChange={(event) =>
                      dataChanged({ contact: { ...profileContactValues.contact, mobile: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("mobile", true))}
                    helperText={validateProfileContactForm.mobile(
                      `${profileContactValues.contact.mobile}`,
                      touched.has("mobile"),
                    )}
                  />
                </Box>
              ) : (
                <Body1>{`${profileContactValues.contact.mobile ?? "-"}`}</Body1>
              )}
            </FormRowColumn>
            <FormRowColumn>
              {editMode ? (
                <Box sx={{ padding: "8px 0", flex: 1 }}>
                  <SingleLineTextField
                    name="phone"
                    label={t("contact.telephone")}
                    value={profileContactValues.contact.phone}
                    onChange={(event) =>
                      dataChanged({ contact: { ...profileContactValues.contact, phone: event.target.value } })
                    }
                    onBlur={() => setTouched(touched.set("phone", true))}
                    helperText={validateProfileContactForm.phone(
                      `${profileContactValues.contact.phone}`,
                      touched.has("phone"),
                    )}
                  />
                </Box>
              ) : (
                <Body1>{`${profileContactValues.contact.phone ?? "-"}`}</Body1>
              )}
            </FormRowColumn>
          </>
        </FormView>
        {editMode && (
          <PrimaryButton type="submit" sx={{ mt: 2 }} label={t("shared:form.action.save")} isLoading={isLoading} />
        )}
      </div>
    </>
  )
}

import { styled } from "@mui/material"
import { ReloadDataIcon } from "../Shared.Icon"
import { useTranslation } from "react-i18next"
import { LoadingButton, LoadingButtonProps } from "@mui/lab"

const ReloadButton = styled(LoadingButton)(({ theme }) => ({
  fontWeight: 500,
  textTransform: "none",
  marginRight: theme.spacing(2),
  fontSize: theme.typography.pxToRem(18),
}))

export const ReloadDataButton = (props: LoadingButtonProps) => {
  const { t } = useTranslation("shared")
  return (
    <ReloadButton variant="text" startIcon={<ReloadDataIcon />} loadingPosition="start" {...props}>
      {t("label.reloadData")}
    </ReloadButton>
  )
}

import { SmallPaddedBox } from "../../../../uikit/box/PaddedBox"
import { FormSectionTitle } from "../../../../uikit/form/FormView"
import { DateDaySelectorView } from "../../../../uikit/input/DateDaySelectorView"
import { Box, Paper } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useQuery } from "@tanstack/react-query"
import { isAdminPortal, useQueryDefaultOptions } from "../../../Shared.Utils"
import { Medium, MeterReadingsCalcResolution } from "../../../../data/generated-sources/openapi"
import { ContractAnalysisPropsInterface } from "./Analise.Model"
import DateUtils from "../../../../services/utils/DateUtils"
import { useTranslation } from "react-i18next"
import { ZevDailyComponent } from "./charts/ZevDaily.Component"
import { VewaDailyComponent } from "./charts/VewaDaily.Component"
import { ZevDayMeterReadingItem } from "../../../../domain/analyse/Analyse.Model"
import { adminGetContractDaily, customerGetContractDaily } from "../../../../domain/analyse/Analyse.Repository"

export const AnaliseDailyWrapComponent = (props: ContractAnalysisPropsInterface) => {
  const { contractId, contractProductType } = props
  const { t } = useTranslation("analise")
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1),
  )
  const [selectedTab, setSelectedTab] = useState<string>(Medium.HEAT)
  const [chartData, setChartData] = useState<ZevDayMeterReadingItem[]>([])
  const [isVewa, setIsVewa] = useState(contractProductType === "VEWA")
  const getDate = () => DateUtils.getDeFormatDate(selectedDateTime)

  const { data, isLoading, isPreviousData, remove, refetch } = useQuery(
    ["getDailyContractAnalise"],
    () =>
      isAdminPortal()
        ? adminGetContractDaily(contractId, getDate(), getDate(), MeterReadingsCalcResolution.MINUTE)
        : customerGetContractDaily(contractId, getDate(), getDate(), MeterReadingsCalcResolution.MINUTE),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  useEffect(() => {
    if (data?.zev) {
      setChartData(data?.zev?.data as ZevDayMeterReadingItem[])
      return setIsVewa(false)
    }
    formatVewaData()
    setIsVewa(true)
  }, [data, isPreviousData, selectedTab, contractId])

  useEffect(() => {
    refetch()
  }, [selectedDateTime, contractId])

  useEffect(() => () => remove(), [remove])

  const formatVewaData = () => {
    const total1 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.HEAT : Medium.WARM_WATER]
    const total2 = data?.vewa?.data[selectedTab === Medium.HEAT ? Medium.COLD : Medium.COLD_WATER]
    // eslint-disable-next-line
    const combinedConsumption: any = {}

    if (total1) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total1?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        combinedConsumption[dateTime] = { produced: consumption }
      }
    }

    if (total2) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      for (const item of total2?.data) {
        const dateTime = item.dateTime
        const consumption = parseFloat(item.consumption)

        if (combinedConsumption[dateTime]) {
          combinedConsumption[dateTime].energyHighTariff = consumption
        } else {
          combinedConsumption[dateTime] = { energyHighTariff: consumption }
        }
      }
    }

    const combinedArray = Object.keys(combinedConsumption).map((dateTime) => {
      return {
        dateTime,
        ...combinedConsumption[dateTime],
      }
    })

    setChartData(combinedArray)
  }

  return (
    <Paper>
      <Box
        sx={{
          marginBottom: "30px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <FormSectionTitle transform="inherit" label={t(isVewa ? "daily.vewa.title" : "daily.title")} />
        </Box>
        <SmallPaddedBox>
          <DateDaySelectorView
            selectedDateValue={selectedDateTime}
            onDateChanged={(newDateTime) => {
              setSelectedDateTime(newDateTime)
            }}
            disabled={isLoading}
          />
        </SmallPaddedBox>
      </Box>
      {isVewa ? (
        <VewaDailyComponent
          chartData={chartData}
          selectedTab={selectedTab}
          setSelectTab={setSelectedTab}
          isLoading={isLoading}
        />
      ) : (
        <ZevDailyComponent chartData={chartData} isLoading={isLoading} />
      )}
    </Paper>
  )
}

import { ORDERED_NUMBER_COMPARATOR, ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ParticipantPeriodicBillingEnum } from "../participantBilling/ParticipantBilling.Enum"
import { AllParticipantsBilling, VewaAllParticipantsBilling } from "../../../../../data/generated-sources/openapi"

export const ParticipantPeriodicBillingComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ParticipantPeriodicBillingEnum.STATUS:
      return (a: AllParticipantsBilling, b: AllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ParticipantPeriodicBillingEnum.PERIOD:
      return (a: AllParticipantsBilling, b: AllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
    case ParticipantPeriodicBillingEnum.AMOUNT:
      return (a: AllParticipantsBilling, b: AllParticipantsBilling) =>
        ORDERED_NUMBER_COMPARATOR(Number(a.totalSum), Number(b.totalSum), orderBy.direction)
    default:
      return (a: AllParticipantsBilling, b: AllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.id, b.id, orderBy.direction)
  }
}

export const VewaParticipantPeriodicBillingComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ParticipantPeriodicBillingEnum.STATUS:
      return (a: VewaAllParticipantsBilling, b: VewaAllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ParticipantPeriodicBillingEnum.PERIOD:
      return (a: VewaAllParticipantsBilling, b: VewaAllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
    default:
      return (a: VewaAllParticipantsBilling, b: VewaAllParticipantsBilling) =>
        ORDERED_STRING_COMPARATOR(a.id, b.id, orderBy.direction)
  }
}

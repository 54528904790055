import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridActionsCellItem, GridActionsColDef, GridRowParams } from "@mui/x-data-grid"
import { TariffScheduleResponse } from "../../../../../data/generated-sources/openapi"
import { DataGridTable } from "../../../../../uikit/dataGridTable"
import { TariffTimePeriodText } from "../../../shared/tariffs/fragments/TariffTimePeriodText"
import DeleteTariff from "./DeleteTariff"
import { useState } from "react"

export const TariffSuppliersTable = ({
  tariffsData,
  isLoading,
}: {
  tariffsData: TariffScheduleResponse[]
  isLoading: boolean
}) => {
  const { t } = useTranslation("tariff")
  const history = useHistory()
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [tariffId, setTariffId] = useState("")

  const tariffSupplierColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 100,
      field: "name",
      headerName: t("energySupplier"),
    },
    {
      flex: 300,
      field: "tariffTimes",
      headerName: t("tariffTimes"),
      sortable: false,
      valueGetter: (_, row) => row,
      renderCell: ({ value }) => TariffTimePeriodText(value),
    },
    {
      field: "actions",
      type: "actions",
      flex: 50,
      getActions: ({ row }: GridRowParams) => {
        return [
          <GridActionsCellItem
            divider
            showInMenu
            key="editTariff"
            label={t("shared:form.action.edit")}
            onClick={() => history.push(`/tariff/${row.id}/edit`)}
          />,
          <GridActionsCellItem
            showInMenu
            key="removeSupplier"
            label={t("removeSupplier")}
            onClick={() => {
              setIsModalVisible(true)
              setTariffId(row.id)
            }}
          />,
        ]
      },
    },
  ]
  return (
    <>
      <DeleteTariff tariffId={tariffId} isModalVisible={isModalVisible} setIsModalVisible={setIsModalVisible} />
      <DataGridTable<TariffScheduleResponse>
        tariff
        rows={tariffsData}
        loading={isLoading}
        getRowHeight={() => "auto"}
        columns={tariffSupplierColumns}
        data-testid="suppliersTariffTable"
        defaultPageSize={25}
      />
    </>
  )
}

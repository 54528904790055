import { Redirect, RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useTranslation } from "react-i18next"
import { useEffect, useMemo } from "react"
import { firstViewState } from "../../../Shared.Reducer"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { ProfilesDetailState } from "./ProfilesDetail.Reducer"
import { coerce } from "../../../Shared.View"
import { SpaceBetweenBox, AlignBottomBox } from "../../../../uikit/box/AlignmentBox"
import { SmallPaddedBox, TinyPaddedBox } from "../../../../uikit/box/PaddedBox"
import { StatusView } from "../../../../uikit/label/StatusView"
import { Divider, Paper, Typography } from "@mui/material"
import CallIcon from "@mui/icons-material/Call"
import { AdminFormSubtitle, FormRowCell, FormRowColumn } from "../../../../uikit/form/FormView"
import { NotDisturbIcon, RemoveIcon, ResetIcon, UserProfileIcon, LocationOn } from "../../../../uikit/Shared.Icon"
import { FormDataItemBox, PhoneFormDataItemBox } from "../../../../uikit/box/DataItemBox"
import { Body1, Heading4 } from "../../../../uikit/typography/Typography"
import { FlexOneBox } from "../../../../uikit/box/FlexBox"
import { PrimaryButton } from "../../../../uikit/button"
import { DividerBox } from "../../../../uikit/box/DividerBox"
import { PrimaryEditButton } from "../../../../uikit/button/PrimaryEditButton"
import { mapDispatchToProps } from "./ProfilesDetail.Connect"
import { ProfileStatusTypeView } from "./form/ProfileStatusType"
import { ProfileStatusType } from "../../../../domain/portal/admin/profiles/Profiles.Model"
import { ErrorAlert, SuccessAlert } from "../../../../uikit/Shared.Alert"
import { formatEmail, formatPhone } from "../../../../uikit/Shared.Formatters"
import SharedListComponent from "../sharedListComponet/SharedList.Component"
import { UserType } from "../../../../data/generated-sources/openapi"
import { COMPONENTS_CONFIG } from "../sharedComponentsConfig/Components.Config"

interface ProfilesDetailComponentProps
  extends ProfilesDetailState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const ProfilesDetailComponent = (props: ProfilesDetailComponentProps) => {
  const { t } = useTranslation("profiles")
  const {
    viewState,
    getProfileById,
    navigateToUpdateProfile,
    navigateToUpdateProfileInContract,
    deleteProfileViewState,
    deleteProfileById,
    directDeleteProfile,
    createProfileLoginViewState,
    createProfileLogin,
    activateViewState,
    activateProfile,
    deactivateViewState,
    deactivateProfile,
    resetPasswordViewState,
    resetPassword,
    navigateToEditContract,
    match,
    showUpdateAlert,
  } = props
  const profileId = useMemo(() => match.params.profileId, [match])

  const handleUpdateProfile = () => {
    if (match.path.includes("/management-contracts/")) {
      navigateToUpdateProfileInContract(match.params.id, profileId)
    } else {
      navigateToUpdateProfile(profileId)
    }
  }

  useEffect(() => {
    if (firstViewState(viewState)) {
      getProfileById(match.params.profileId)
    }
  }, [viewState, match, getProfileById])

  if (viewState.isLoading) return <ProgressIndicator />
  if (deleteProfileViewState.domainResult) return <Redirect to="/profiles" />
  if (viewState.domainError)
    return <ErrorAlert message={viewState.domainError.message} retry={() => getProfileById(match.params.profileId)} />
  return (
    <>
      {coerce(viewState.domainResult, (profileDetail) => (
        <>
          {showUpdateAlert && <SuccessAlert message={t("update.alert.success")} />}
          <Paper>
            <SpaceBetweenBox>
              <SmallPaddedBox>
                <StatusView id="profile-status" statusType={profileDetail.statusType} />
              </SmallPaddedBox>
              <SmallPaddedBox>
                {/* <PrimaryEditButton id="profile-edit-cta" onClick={() => navigateToUpdateProfile(profileId)} /> */}
                <PrimaryEditButton id="profile-edit-cta" onClick={handleUpdateProfile} />
              </SmallPaddedBox>
            </SpaceBetweenBox>
            <SmallPaddedBox>
              <Body1 id="profile-label-title">{profileDetail.userTitle}</Body1>
              <Heading4 id="profile-label-name">{profileDetail.profileName}</Heading4>
            </SmallPaddedBox>
            <FormRowColumn>
              <FormRowCell>
                <AdminFormSubtitle label={t("form.subtitle.address")} icon={<LocationOn fontSize="small" />} />
                <FormRowColumn>
                  <FlexOneBox>
                    <FormDataItemBox id="street" title={t("form.field.street")} value={profileDetail.address} />
                  </FlexOneBox>
                  <FlexOneBox>
                    <FormDataItemBox id="city" title={t("form.field.city")} value={profileDetail.city} />
                  </FlexOneBox>
                </FormRowColumn>
              </FormRowCell>
            </FormRowColumn>

            <TinyPaddedBox>
              <AdminFormSubtitle label={t("form.title.phoneDetails")} icon={<CallIcon fontSize="small" />} />
              <FormRowColumn>
                <FlexOneBox>
                  <FormDataItemBox
                    id="telephone"
                    title={t("form.field.telephone")}
                    value={formatPhone(profileDetail.telephone)}
                  />
                </FlexOneBox>
                <FlexOneBox>
                  <PhoneFormDataItemBox
                    id="mobile"
                    title={t("form.field.mobile")}
                    value={formatPhone(profileDetail.mobile)}
                  />
                </FlexOneBox>
              </FormRowColumn>
            </TinyPaddedBox>
            <TinyPaddedBox>
              <FlexOneBox>
                <FormDataItemBox id="email" title={t("form.field.email")} value={formatEmail(profileDetail.email)} />
              </FlexOneBox>
            </TinyPaddedBox>
            {deleteProfileViewState.domainResult && <Redirect to="/profiles" />}
            {deleteProfileViewState.domainError && (
              <ErrorAlert
                retry={() => directDeleteProfile(profileDetail.id)}
                message={deleteProfileViewState.domainError.message}
              />
            )}
            <SmallPaddedBox>
              <AlignBottomBox>
                <PrimaryButton
                  id="delete-cta"
                  startIcon={<RemoveIcon fontSize="medium" />}
                  label={t("details.button.delete")}
                  isLoading={deleteProfileViewState.isLoading}
                  disabled={deleteProfileViewState.isLoading}
                  onClick={() =>
                    deleteProfileById(profileDetail.id, t("form.alert.delete.body"), t("form.alert.delete.cta"))
                  }
                />
              </AlignBottomBox>
            </SmallPaddedBox>
          </Paper>
          <Paper>
            <SmallPaddedBox>
              <ProfileStatusTypeView id="profile-status-type" statusType={profileDetail.profileStatusType} />
              <DividerBox />
              <Typography variant="subtitle1" sx={{ fontWeight: 300, fontSize: "2em", fontFamily: "Montserrat" }}>
                {t("details.label.account")}
              </Typography>
            </SmallPaddedBox>
            <FormRowColumn>
              <FormRowCell>
                <FormRowColumn>
                  <FlexOneBox>
                    <FormDataItemBox id="username" title={t("form.field.username")} value={profileDetail.username} />
                  </FlexOneBox>
                  <FlexOneBox>
                    <FormDataItemBox
                      id="userType"
                      title={t("form.field.userType")}
                      value={t(`userType.${profileDetail.type}`)}
                    />
                  </FlexOneBox>
                </FormRowColumn>
              </FormRowCell>
            </FormRowColumn>
            {createProfileLoginViewState.domainError && (
              <ErrorAlert
                retry={() => createProfileLogin(profileDetail.id)}
                message={createProfileLoginViewState.domainError.message}
              />
            )}
            {activateViewState.domainError && (
              <ErrorAlert
                retry={() => activateProfile(profileDetail.id)}
                message={activateViewState.domainError.message}
              />
            )}
            {activateViewState.domainResult && <SuccessAlert message={t("details.alert.success.activate")} />}
            {deactivateViewState.domainError && (
              <ErrorAlert
                retry={() => deactivateProfile(profileDetail.id)}
                message={deactivateViewState.domainError.message}
              />
            )}
            {deactivateViewState.domainResult && <SuccessAlert message={t("details.alert.success.deactivate")} />}
            {resetPasswordViewState.domainResult && (
              <SuccessAlert message={t("details.alert.success.reset-password")} />
            )}
            {resetPasswordViewState.domainError && (
              <ErrorAlert
                retry={() => resetPassword(profileDetail.id)}
                message={resetPasswordViewState.domainError.message}
              />
            )}
            <SmallPaddedBox>
              <AlignBottomBox>
                {profileDetail.profileStatusType === ProfileStatusType.CREATED && (
                  <>
                    <PrimaryButton
                      id="create-login-cta"
                      startIcon={<UserProfileIcon fontSize="large" />}
                      label={t("details.button.create-login")}
                      isLoading={createProfileLoginViewState.isLoading}
                      disabled={createProfileLoginViewState.isLoading}
                      onClick={() => createProfileLogin(profileDetail.id)}
                    />
                    <DividerBox />
                  </>
                )}
                {profileDetail.profileStatusType === ProfileStatusType.DEACTIVATED && (
                  <>
                    <PrimaryButton
                      id="activate-cta"
                      startIcon={<UserProfileIcon fontSize="large" />}
                      label={t("details.button.activate")}
                      isLoading={activateViewState.isLoading}
                      disabled={activateViewState.isLoading}
                      onClick={() => activateProfile(profileDetail.id)}
                    />
                    <DividerBox />
                  </>
                )}
                {profileDetail.profileStatusType === ProfileStatusType.ACTIVE && (
                  <>
                    <PrimaryButton
                      id="deactivate-cta"
                      startIcon={<NotDisturbIcon fontSize="large" />}
                      label={t("details.button.deactivate")}
                      isLoading={deactivateViewState.isLoading}
                      disabled={deactivateViewState.isLoading}
                      onClick={() => deactivateProfile(profileDetail.id)}
                    />
                    <DividerBox />
                  </>
                )}
                {(profileDetail.profileStatusType === ProfileStatusType.ACTIVE ||
                  profileDetail.profileStatusType === ProfileStatusType.DEACTIVATED) && (
                  <>
                    <PrimaryButton
                      id="reset-password-cta"
                      startIcon={<ResetIcon fontSize="large" />}
                      label={t("details.button.reset-password")}
                      isLoading={resetPasswordViewState.isLoading}
                      disabled={resetPasswordViewState.isLoading}
                      onClick={() => resetPassword(profileDetail.id)}
                    />
                    <DividerBox />
                  </>
                )}
                <Divider sx={{ my: 6 }} />
              </AlignBottomBox>
            </SmallPaddedBox>
          </Paper>
          {profileDetail.type === UserType.ZEV_MANAGER && (
            <SharedListComponent
              queryParam={profileId}
              configNew={COMPONENTS_CONFIG.profileDetailContracts}
              onHeaderBtnClick={() => navigateToEditContract(profileId)}
            />
          )}
        </>
      ))}
    </>
  )
}

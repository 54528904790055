import { GetMeterHook } from "../CustomGetHooks"
import { AxiosResponse } from "axios"
import DateUtils from "../../../../../services/utils/DateUtils"
import { getTranslateValue } from "../../../../Shared.Utils"
import { MeterResponse } from "../../../../../data/generated-sources/openapi"

export const METERS_MASTER_DATA_CONFIG = {
  meter: {
    name: "meter",
    useQueryHook: (paramId: string) => GetMeterHook({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: "",
          getValue: (response: AxiosResponse<MeterResponse>) => response.data.activeState,
        },
      },
      {
        firstColumn: {
          label: "meters:label.meterIdSpec",
          getValue: (response: AxiosResponse<MeterResponse>) => response.data.meteringCode,
        },
      },
      {
        firstColumn: {
          label: "meters:label.billableFrom",
          getValue: (response: AxiosResponse<MeterResponse>) =>
            response.data?.billableFrom ? DateUtils.getDeFormatDateWithMonthString(response.data?.billableFrom) : "-",
        },
        secondColumn: {
          label: "meters:label.billableTo",
          getValue: (response: AxiosResponse<MeterResponse>) =>
            response.data?.billableTo ? DateUtils.getDeFormatDateWithMonthString(response.data?.billableTo) : "-",
        },
      },
      {
        firstColumn: {
          label: "meters:label.medium",
          getValue: (response: AxiosResponse<MeterResponse>) =>
            getTranslateValue(`meters:label.${response.data?.medium?.toLowerCase()}`),
        },
      },
      {
        firstColumn: {
          label: "meters:label.meterDesign",
          getValue: (response: AxiosResponse<MeterResponse>) =>
            getTranslateValue(`meters:label.${response.data?.powerMeterType?.toLowerCase()}`),
        },
      },
    ],
  },
}

import { BillingCalculations, EnergyType, ZevPricePackage, ZevPrices } from "../../../../data/generated-sources/openapi"

import { isEqual } from "lodash-es"

const defaultPrice: ZevPrices = {
  solarPrice: "0",
  highTariffPrice: "0",
  lowTariffPrice: "0",
  isFixedRate: true,
  additionalServicesPrice: "0",
}

const defaultPricePackage: ZevPricePackage = {
  id: 0,
  order: 0,
  name: "-",
  prices: defaultPrice,
}

export const mapZevPricePackage = (
  billingData: BillingCalculations,
  pricePackages?: ZevPricePackage[],
): ZevPricePackage => {
  defaultPrice.additionalServicesPrice = billingData.otherPositions[0].price ?? "0"
  billingData.energyPositions.map((energy) => {
    switch (energy.energyType) {
      case EnergyType.SOLAR:
        defaultPrice.solarPrice = energy.price
        break
      case EnergyType.HIGH_TARIFF:
        defaultPrice.highTariffPrice = energy.price
        break
      case EnergyType.LOW_TARIFF:
        defaultPrice.lowTariffPrice = energy.price
        break
    }
  })

  const pricePackage = pricePackages?.find((pricePackage) => {
    defaultPrice.isFixedRate = !!pricePackage.prices?.isFixedRate

    const isSamePrice = isEqual(defaultPrice, {
      ...pricePackage.prices,
      additionalServicesPrice: Number(pricePackage.prices?.additionalServicesPrice).toFixed(4),
    })
    return isSamePrice && pricePackage
  })

  return pricePackage ?? defaultPricePackage
}

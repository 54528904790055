import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

interface RunningCostPropsInterface {
  billingType: string
  name: string
}

const RunningCost = (props: RunningCostPropsInterface) => {
  const { billingType, name } = props
  const { t } = useTranslation("settings")

  return (
    <Box>
      {billingType.length ? (
        <Typography fontWeight={600} fontSize={16} mb={2}>
          {t(`shared:billingType.${billingType}`)}
        </Typography>
      ) : (
        ""
      )}
      <Typography fontSize={13}>{name}</Typography>
    </Box>
  )
}

export default RunningCost

import { Paper } from "@mui/material"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { DataGridListPage } from "../dataGrid/DataGridListPage"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { adminGetBuildingsList } from "../../../../domain/portal/admin/buildings/Buildings.Repository"
import { BuildingsTableColumns } from "./fragments/BuildingsListTableColumns"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { IBuildingAdminResponse } from "../../../../domain/portal/admin/buildings/Buildings.Model"

export const BuildingsList = () => {
  const { t } = useTranslation("buildings")
  const history = useHistory()

  const { data: buildings, isFetching, isError, error } = useQuery(["getBuildingsList"], () => adminGetBuildingsList())
  const errorMsg = error as AxiosErrorDataType

  const handleCellClick = (field: string, { id, areaId }: IBuildingAdminResponse) => {
    if (field === "areaName") {
      return history.push(`/area/${areaId}`)
    }
    return history.push(`/buildings/${id}`)
  }

  return (
    <Paper data-testid="buildingsListPage">
      <ErrorAlert
        scrollOnDisplay
        visible={isError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <DataGridListPage<IBuildingAdminResponse>
        loading={isFetching}
        rows={buildings ?? []}
        showButton={false}
        title={t("list.title")}
        data-testid="buildingsListTable"
        columns={BuildingsTableColumns()}
        onCellClick={({ field, row }) => handleCellClick(field, row)}
      />
    </Paper>
  )
}

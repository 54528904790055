import {
  MultiParticipantResponse,
  ParticipantAdminUpdateRequest,
  ParticipantResponse,
} from "../../data/generated-sources/openapi"
import { apiHeaders } from "../Domain.Calls"
import { DOMAIN_DEPENDENCIES } from "../../app/App.Config"

export const getParticipantById = async (participantId: string): Promise<ParticipantResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminParticipantsApi.adminGetParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const confirmSapSyncParticipantById = async (participantId: string) => {
  return DOMAIN_DEPENDENCIES.adminParticipantsApi.adminConfirmSapSyncParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deleteParticipant = async (participantId: string) => {
  return DOMAIN_DEPENDENCIES.adminParticipantsApi.adminDeleteParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const updateParticipantById = async (participantId: string, payload: ParticipantAdminUpdateRequest) => {
  return DOMAIN_DEPENDENCIES.adminParticipantsApi.adminUpdateParticipantById(
    participantId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getListParticipantsByIds = async (ids: { ids: string[] }): Promise<MultiParticipantResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminParticipantsApi.adminGetParticipantBulkByIds(
    ids,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

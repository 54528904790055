import {
  GroupedPeriodResponse,
  GroupedPeriodResponses,
  TariffScheduleI,
} from "../../app/portal/admin/tariffManagement/TariffModels"
import {
  DayInAWeek,
  PeriodResponse,
  TariffScheduleRequest,
  TariffScheduleResponse,
} from "../../data/generated-sources/openapi"

const groupAndSortTariffsByTime = (period: PeriodResponse[]): GroupedPeriodResponse[] => {
  const groupedTariff: { [key: string]: GroupedPeriodResponse } = {}
  const weekDays = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"]

  period.forEach(({ day, startTime, endTime = "00:00" }) => {
    const key = `${startTime}-${endTime}`
    if (!groupedTariff[key]) {
      groupedTariff[key] = { startTime, endTime, days: [] }
    }
    groupedTariff[key].days.push(day)
  })

  const tariffs = Object.values(groupedTariff).map((group) => {
    group.days.sort((a, b) => weekDays.indexOf(a) - weekDays.indexOf(b))
    return group
  })

  const detectTariffSequenceStart = (days: DayInAWeek[]): number => {
    const daysIndexes = days.map((day) => weekDays.indexOf(day))
    for (let i = 0; i < daysIndexes.length; i++) {
      let isConsecutive = true
      for (let j = 0; j < daysIndexes.length - 1; j++) {
        if ((daysIndexes[(i + j) % daysIndexes.length] + 1) % 7 !== daysIndexes[(i + j + 1) % daysIndexes.length]) {
          isConsecutive = false
          break
        }
      }
      if (isConsecutive) {
        return i
      }
    }
    return 0
  }

  // Adjust the days array based on tariff sequence start
  tariffs.forEach((sequence) => {
    const startIndex = detectTariffSequenceStart(sequence.days)
    sequence.days = sequence.days.slice(startIndex).concat(sequence.days.slice(0, startIndex))
  })

  return tariffs
}

export const groupTariffsByTimeSlots = (data: TariffScheduleResponse): GroupedPeriodResponses => {
  const { lowTariffPeriods, highTariffPeriods } = data
  const lowTariff = groupAndSortTariffsByTime(lowTariffPeriods)
  const highTariff = groupAndSortTariffsByTime(highTariffPeriods)

  return { lowTariff, highTariff }
}

export const formatPeriods = (periods: TariffScheduleI[]): TariffScheduleRequest => {
  const tariffPeriods: PeriodResponse[] = []
  periods.map((period: TariffScheduleI) => {
    period.days.map((day: string) => {
      const newPeriod: PeriodResponse = {
        day: getDayInWeek(day),
        startTime: period.startTime,
      }
      if (period.endTime !== "00:00") newPeriod.endTime = period.endTime
      tariffPeriods.push(newPeriod)
    })
  })

  return {
    name: "",
    lowTariffPeriods: tariffPeriods,
  }
}

const mapPeriodsTariff = (groupedPeriods: GroupedPeriodResponse[], period: PeriodResponse): void => {
  const existingPeriod = groupedPeriods.find(
    (periodItem: GroupedPeriodResponse) =>
      periodItem.startTime === period.startTime && (!period.endTime || periodItem.endTime === period.endTime),
  )
  if (existingPeriod) {
    existingPeriod.days.push(period.day)
  } else {
    groupedPeriods.push({
      startTime: period.startTime,
      endTime: period.endTime ?? "00:00",
      days: [period.day],
    })
  }
}

export const mapTariffResponse = (data: TariffScheduleResponse): TariffScheduleI[] => {
  const periods: TariffScheduleI[] = []
  const groupedPeriods: GroupedPeriodResponse[] = []
  data.lowTariffPeriods.forEach((period: PeriodResponse) => {
    mapPeriodsTariff(groupedPeriods, period)
  })

  groupedPeriods.map((period: GroupedPeriodResponse, index: number) => {
    const newPeriod: TariffScheduleI = {
      name: String(index + 1),
      days: period.days.map((day: DayInAWeek) => getDayInWeekEnglish(day)),
      startTime: period.startTime,
      endTime: period.endTime,
    }
    periods.push(newPeriod)
  })

  return periods
}

export const getDayInWeek = (day: string) => {
  switch (day) {
    case "Mo":
      return DayInAWeek.MONDAY
    case "Di":
      return DayInAWeek.TUESDAY
    case "Mi":
      return DayInAWeek.WEDNESDAY
    case "Do":
      return DayInAWeek.THURSDAY
    case "Fr":
      return DayInAWeek.FRIDAY
    case "Sa":
      return DayInAWeek.SATURDAY
    default:
      return DayInAWeek.SUNDAY
  }
}

export const getDayInWeekEnglish = (day: DayInAWeek) => {
  switch (day) {
    case DayInAWeek.MONDAY:
      return "Mo"
    case DayInAWeek.TUESDAY:
      return "Di"
    case DayInAWeek.WEDNESDAY:
      return "Mi"
    case DayInAWeek.THURSDAY:
      return "Do"
    case DayInAWeek.FRIDAY:
      return "Fr"
    case DayInAWeek.SATURDAY:
      return "Sa"
    default:
      return "So"
  }
}

export const defaultPeriod: TariffScheduleI = {
  name: "1",
  days: ["Mo", "Di", "Mi", "Do", "Fr"],
  startTime: "00:00",
  endTime: "06:00",
}

export const daysOfWeek: string[] = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]

import * as yup from "yup"
import { BillingScope, ConsumptionScope, UsageType } from "../../../../data/generated-sources/openapi"

export const VewaCostSharesSchema = yup.array().of(
  yup
    .object()
    .optional()
    .shape({
      type: yup.object().shape({
        billingScope: yup.mixed().oneOf(Object.values(BillingScope)),
        consumptionScope: yup.mixed().oneOf(Object.values(ConsumptionScope)),
        usageType: yup.mixed().oneOf(Object.values(UsageType)),
      }),
      costStructure: yup.object().shape({
        basicCosts: yup.number().min(0).max(100).required(),
        consumptionBasedCosts: yup.number().min(0).max(100).required(),
      }),
    }),
)

import { useEffect, useState } from "react"
import { Paper, CircularProgress } from "@mui/material"
import { RouteComponentProps } from "react-router"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useQuery, useMutation } from "@tanstack/react-query"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../Shared.Utils"

import { useTranslation } from "react-i18next"
import { getParticipantById, updateParticipantById } from "../../../../domain/participant/Participant.Repository"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { Form, Formik } from "formik"
import { ParticipantAdminUpdateRequest } from "../../../../data/generated-sources/openapi"
import { ParticipantAdminCreateSchema } from "../../manager/participants/ParticipantsValidation.Schema"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { ParticipantCreateUpdateFormFields } from "../../manager/participants/components/ParticipantCreateUpdateFormFields"
import { initialParticipantValues } from "../../../../domain/participant/Participant.Model"

const ParticipantDetailsEdit = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { participantId } = params
  const { t } = useTranslation("participant")
  const [participantError, setParticipantError] = useState<AxiosErrorDataType>()

  const {
    remove: removeParticipant,
    isLoading,
    isFetching,
    data,
  } = useQuery(["getParticipant"], () => getParticipantById(participantId), {
    enabled: !!participantId,
    ...useQueryDefaultOptions,
    onError: setParticipantError,
  })

  useEffect(() => {
    return () => {
      removeParticipant()
    }
  }, [removeParticipant])

  const { mutate: updateParticipant, isLoading: isUpdating } = useMutation(
    ["updateParticipant"],
    (updateData: ParticipantAdminUpdateRequest) => updateParticipantById(participantId, updateData),
    {
      onSuccess: () => history.goBack(),
      onError: setParticipantError,
    },
  )

  const handleOnSubmit = ({
    sapGp,
    gpType,
    contactDetails,
    personalData,
    domicileAddress,
  }: ParticipantAdminUpdateRequest) => {
    if (!gpType || !personalData || !domicileAddress) return

    updateParticipant({
      sapGp,
      gpType,
      personalData,
      domicileAddress,
      contactDetails: {
        ...initialParticipantValues.contactDetails,
        ...contactDetails,
      },
    })
  }

  if (isLoading && isFetching) return <CircularProgress color="primary" size={48} />

  return (
    <>
      <ErrorAlert
        visible={!!participantError}
        message={t(`error-codes:${participantError?.response?.data?.code || participantError?.code || "OTHER"}`)}
      />
      <Formik<ParticipantAdminUpdateRequest>
        enableReinitialize
        initialValues={{ ...initialParticipantValues, ...data }}
        validationSchema={ParticipantAdminCreateSchema}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Paper>
              <ParticipantCreateUpdateFormFields {...props} adminCreate />
            </Paper>
            <FormUpdateActionsView
              isValid={props.isValid}
              dirty={props.dirty}
              isLoading={isLoading || isUpdating}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("form.action.cta")}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default ParticipantDetailsEdit

import { MetersColumns } from "../meters/Meters.Enum"
import { MeterApiEnum } from "../Components.Enums"
import MeterTableComponent from "../meters/MeterTable.Component"
import { metersColumnComparator } from "../meters/Meters.Comparator"
import { MeterResponse } from "../../../../../data/generated-sources/openapi"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const METERS_LIST_COMPONENT_CONFIG = {
  meters: {
    name: "meters",
    header: {
      title: "meters:table.title",
      showButton: false,
      showFilter: true,
    },
    columnEnums: MetersColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: MetersColumns.METER_ID,
      direction: "desc",
    },
    columnComparator: metersColumnComparator,
    tableHeaders: [
      {
        column: MetersColumns.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: MetersColumns.METER_ID,
        label: "meters:label.meterId",
        width: "30%",
        orderable: true,
      },
      {
        column: MetersColumns.UNIT,
        label: "meters:label.unitId",
        width: "30%",
        orderable: true,
      },
      {
        column: MetersColumns.MEDIUM,
        label: "meters:label.medium",
        width: "10%",
        orderable: true,
      },
    ],
    renderTableRows: (item: MeterResponse) => <MeterTableComponent itemConfig={item} key={getUniqueId()} />,
  },
}

export const METERS_API_CONFIG = {
  meters: MeterApiEnum,
}

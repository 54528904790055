import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders, fileAttachmentDownload, fileAttachmentName } from "../../../Domain.Calls"
import { AxiosResponseHeaders } from "axios/index"

export const customerCreateVewaParticipantBillingBills = (billingId: string) =>
  DOMAIN_DEPENDENCIES.customerBillsApi.customerCreateVewaParticipantBillingBills(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerGetVewaParticipantBillingBills = async (
  billingId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerBillsApi.customerGetVewaParticipantBillingBills(
    billingId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerVewaParticipantBillsDownloadCsv = async (billId: string): Promise<void> => {
  const csv = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerVewaParticipantBillingCsv(
    billId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return fileAttachmentDownload(fileAttachmentName(csv.headers as AxiosResponseHeaders), new Blob([csv.data]))
}

export const customerDownloadAllParticipantBillingPdfs = async (billingId: string) => {
  const pdf = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerAllParticipantBillingPdfs(billingId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  return fileAttachmentDownload(fileAttachmentName(pdf.headers as AxiosResponseHeaders), new Blob([pdf.data]))
}

export const customerDownloadBillPdf = async (billId: string) => {
  const response = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerBillPdf(billId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

export const customerDownloadAllParticipantBillingCsv = async (billId: string) => {
  const csv = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerAllParticipantBillingCsv(billId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  return fileAttachmentDownload(fileAttachmentName(csv.headers as AxiosResponseHeaders), new Blob([csv.data]))
}

export const customerUpdateBillPaidOrUnpaid = async (billId: string, paidOrUnpaid: "paid" | "unpaid") => {
  return DOMAIN_DEPENDENCIES.customerBillsApi.updateCustomerBillPaidOrUnpaid(
    billId,
    paidOrUnpaid,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerDownloadVewaBillPdf = async (vewaAllParticipantBillingId: string, participantId: string) => {
  const response = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerVewaParticipantBillPdf(
    vewaAllParticipantBillingId,
    participantId,
    {
      responseType: "blob",
      headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
    },
  )

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

export const customerDownloadAllVewaBillPdf = async (vewaAllParticipantBillingId: string) => {
  const response = await DOMAIN_DEPENDENCIES.customerBillsApi.getCustomerAllVewaBillPdfs(vewaAllParticipantBillingId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

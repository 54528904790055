import { ServiceInitialBillingApiEnum } from "../Components.Enums"
import { ServiceBillingComparator } from "../serviceBilling/ServiceBilling.Comparator"
import { ServiceBillingEnum } from "../serviceBilling/ServiceBilling.Enum"
import ServiceBillingTableComponent from "../serviceBilling/ServiceBillingTable.Component"
import { ServiceBillingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const SERVICE_INITIAL_BILLING_LIST_COMPONENT_CONFIG = {
  serviceInitialBilling: {
    name: "serviceInitialBilling",
    header: {
      title: "",
      showButton: false,
      showFilter: false,
    },
    columnEnums: ServiceBillingEnum,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: ServiceBillingEnum.STATUS,
      direction: "desc",
    },
    columnComparator: ServiceBillingComparator,
    tableHeaders: [
      {
        column: ServiceBillingEnum.STATUS,
        label: "shared:status.label",
        width: "15%",
        orderable: true,
      },
      {
        column: ServiceBillingEnum.BILLING_NUMBER,
        label: "billings-initial:list.label.bill-number",
        width: "30%",
        orderable: true,
      },
      {
        column: ServiceBillingEnum.AMOUNT,
        label: "billings-recurring:field.label.totalAmount",
        width: "30%",
        orderable: true,
      },
      {
        column: ServiceBillingEnum.DATE,
        label: "billings-initial:list.label.date",
        width: "25%",
        orderable: true,
      },
    ],
    renderTableRows: (item: ServiceBillingAdminResponse) => (
      <ServiceBillingTableComponent isInitialBilling={true} itemConfig={item} key={getUniqueId()} />
    ),
    defaultRowsPerPage: 10,
  },
}

export const SERVICE_INITIAL_BILLING_API_CONFIG = {
  serviceInitialBilling: ServiceInitialBillingApiEnum,
}

/* eslint-disable indent */
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useHistory } from "react-router-dom"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridActionsCellItem, GridActionsColDef, GridRowParams } from "@mui/x-data-grid"
import { useAppDispatch, useAppSelector } from "../../../hooks"

import { setResetVewaNextBillingPeriod } from "../store/uiSlice"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { selectVewaNotDonePeriodicBillings } from "../store/billingsSlice"
import { VewaAllParticipantsBillingState } from "../../../../data/generated-sources/openapi"

import {
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { customerReloadVewaParticipantBillingData } from "../../../../domain/portal/manager/billings/Billings.Repository"

import { DataGridTable } from "../../../../uikit/dataGridTable"
import { PrimaryButton } from "../../../../uikit/button"
import { DialogCloseIcon } from "../../../../uikit/icons/Icons"
import { periodicBillingsColumns } from "./fragments/PeriodicBillningColumns"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const VewaPeriodicBillingTable = ({ areaId }: { areaId: string }) => {
  const { t } = useTranslation("settlements")
  const history = useHistory()
  const dispatch = useAppDispatch()
  const queryClient = useQueryClient()

  const { contractId } = useAppSelector(selectSelectedContractData)
  const vewaNotDoneBills = useAppSelector(selectVewaNotDonePeriodicBillings)

  const [error, setError] = useState<AxiosErrorDataType>()
  const [showDialog, setShowDialog] = useState<boolean>(false)
  const [reloadDialogData, setReloadDialogData] = useState<{ showDialog: boolean; billingid: string }>({
    showDialog: false,
    billingid: "",
  })
  const [selectedBill, setSelectedBill] = useState<string>("")

  const { IN_PROGRESS, DONE, WAITING_FOR_DATA } = VewaAllParticipantsBillingState

  const onCloseDialog = () => setShowDialog(false)

  const onCloseReloadDialog = () => setReloadDialogData({ showDialog: false, billingid: "" })

  const invalidateVewaQuery = () => queryClient.invalidateQueries({ queryKey: ["getAllVewaParticipantsBillings"] })

  const handleVewaBillingNavigation = (billingId: string, activeState: VewaAllParticipantsBillingState) => {
    if (activeState === IN_PROGRESS) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/validate`)
    }
    if (activeState === DONE) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/bills`)
    }
  }

  const openChangeBillingModal = (billingId: string) => {
    setSelectedBill(billingId)
    setShowDialog(true)
  }

  const onConfirmChangeBilling = () => {
    dispatch(setResetVewaNextBillingPeriod({ canReset: true, billingId: selectedBill }))
    setShowDialog(false)
    history.push(`/${areaId}/settings`)
  }

  const { mutate: reloadBillingData, isLoading: isReloadingData } = useMutation(
    ["reloadBillingData"],
    (billingId: string) => customerReloadVewaParticipantBillingData(billingId),
    {
      onSuccess: invalidateVewaQuery,
      onError: setError,
    },
  )

  const vewaBillingsColumns: Array<GridBaseColDef | GridActionsColDef> = [
    ...periodicBillingsColumns(),
    {
      field: "actions",
      type: "actions",
      flex: 50,
      getActions: ({ row }: GridRowParams) => {
        const { id, activeState, makeBill } = row
        return [
          <GridActionsCellItem
            divider
            key="goToDetails"
            label={t("settlements:list-billing-makeBill")}
            disabled={!makeBill}
            onClick={() => handleVewaBillingNavigation(id, activeState)}
            showInMenu
          />,
          <GridActionsCellItem
            divider
            showInMenu
            disabled={[WAITING_FOR_DATA].includes(activeState)}
            key="reloadData"
            label={t("shared:label.reloadData")}
            onClick={() => setReloadDialogData({ showDialog: true, billingid: id })}
          />,
          <GridActionsCellItem
            showInMenu
            disabled={![WAITING_FOR_DATA].includes(activeState)}
            key="changBillingPeriod"
            label={t("shared:label.change.billingPeriod")}
            onClick={() => openChangeBillingModal(id)}
          />,
        ]
      },
    },
  ]

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
      />
      <DataGridTable<ZevPeriodicBillingItem | VewaPeriodicBillingItem>
        rows={vewaNotDoneBills}
        columns={vewaBillingsColumns}
        loading={isReloadingData}
        defaultPageSize={25}
      />

      <Dialog open={showDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
        <DialogTitle>{t("vewaPeriodic.dialog.title")}</DialogTitle>
        <DialogContent>{t("vewaPeriodic.dialog.content")}</DialogContent>
        <DialogCloseIcon onClick={onCloseDialog} />
        <DialogActions>
          <PrimaryButton label={t("vewaPeriodic.dialog.deleteBtn")} onClick={onConfirmChangeBilling} />
          <Button variant="outlined" onClick={onCloseDialog}>
            {t("shared:form.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog open={reloadDialogData?.showDialog} onClose={onCloseReloadDialog} fullWidth maxWidth="sm">
        <DialogCloseIcon onClick={onCloseReloadDialog} />
        <DialogTitle>{t("shared:label.reloadData")}</DialogTitle>
        <DialogContent>{t("shared:reloadData.dialogContent")}</DialogContent>
        <DialogActions>
          <PrimaryButton
            label={t("shared:dialog.confirm.cta")}
            onClick={() => {
              onCloseReloadDialog()
              reloadDialogData?.billingid && reloadBillingData(reloadDialogData?.billingid)
            }}
          />
          <Button variant="outlined" onClick={onCloseReloadDialog}>
            {t("shared:form.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

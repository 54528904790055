import { FormControlLabel, Radio, RadioGroup, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CommonFormikProps } from "../../../../../Shared.Utils"

import { ContractsSelector } from "./ContractsSelector"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import {
  IServiceBillingCreate,
  PeriodOfYearType,
} from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

export const CreateRecurringBillingsFormFileds = (props: CommonFormikProps<IServiceBillingCreate>) => {
  const { values, setFieldValue } = props
  const { t } = useTranslation("billings-recurring")

  const handleFirstPeriodOfYear = (selectedYear: number) => {
    const firstDayOfYear = new Date(selectedYear, 0, 1)
    const halfOfYearDate = new Date(selectedYear, 5, 30)

    setFieldValue("startDate", firstDayOfYear)
    setFieldValue("endDate", new Date(halfOfYearDate))
  }

  const handleSecondPeriodOfYear = (selectedYear: number) => {
    const startDateOfSecondPart = new Date(selectedYear, 6, 1)
    const endOfYear = new Date(selectedYear, 11, 31)

    setFieldValue("startDate", startDateOfSecondPart)
    setFieldValue("endDate", new Date(endOfYear))
  }

  const handleYearChange = (value: number, whatHalfOfYear?: PeriodOfYearType) => {
    const selectedYear = new Date(value).getFullYear()
    const yearlySection = whatHalfOfYear ?? values.halfOfYear

    if (yearlySection === "first") {
      handleFirstPeriodOfYear(selectedYear)
    } else {
      handleSecondPeriodOfYear(selectedYear)
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const whatHalfOfYear = (event.target as HTMLInputElement).value
    setFieldValue("halfOfYear", (event.target as HTMLInputElement).value)
    handleYearChange(values.startDate, whatHalfOfYear as PeriodOfYearType)
  }

  return (
    <>
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={4} mb={2}>
        <SingleLineDatePicker
          name="startDate"
          views={["year"]}
          value={values.startDate}
          label={t("form.field.startDate")}
          onChange={handleYearChange}
        />
        <SingleLineDatePicker
          disabled
          name="endDate"
          value={values.endDate}
          label={t("form.field.endDate")}
          onChange={(value) => setFieldValue("endDate", value)}
        />
      </Stack>
      <RadioGroup row color="secondary" name="setHalfOfYear" value={values.halfOfYear} onChange={handleChange}>
        <FormControlLabel
          value={"first"}
          control={<Radio />}
          label={`${t("form.field.periodOfYear", { part: "1" })}`}
        />
        <FormControlLabel
          value={"second"}
          control={<Radio />}
          label={`${t("form.field.periodOfYear", { part: "2" })}`}
        />
      </RadioGroup>

      <ContractsSelector {...props} />
    </>
  )
}

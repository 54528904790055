/* eslint-disable @typescript-eslint/no-explicit-any */
import { GridComparatorFn, GetApplyQuickFilterFn, GridFilterInputValue, GridFilterOperator } from "@mui/x-data-grid"
import { t } from "i18next"
import { getTranslateValue } from "../../../app/Shared.Utils"
import DateUtils from "../../../services/utils/DateUtils"

export const chfMoneyLabelComparator: GridComparatorFn<string> = (v1, v2) => {
  const val1 = parseInt(v1.replace(/\D/g, ""), 10)
  const val2 = parseInt(v2.replace(/\D/g, ""), 10)

  return val1 - val2
}

export const dateSortComparator: GridComparatorFn<Date> = (v1, v2) => (v1 > v2 ? 1 : -1)

export const statusSortComparator: GridComparatorFn<string> = (v1, v2) => {
  return t(`shared:status.${v1}`).localeCompare(t(`shared:status.${v2}`))
}

export const filterByStatus: GridFilterOperator<any, string>[] = [
  {
    label: "enthält",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (value, row) => {
        const translateStatus = getTranslateValue(`shared:status.${row?.activeState?.toUpperCase()}`)
        const hasStatusMatch =
          translateStatus.includes(filterItem.value?.toUpperCase()) || value.includes(filterItem.value?.toUpperCase())

        return hasStatusMatch ? row?.activeState : false
      }
    },
    InputComponent: GridFilterInputValue,
  },
]
export const filterByDate: GridFilterOperator<any, string>[] = [
  {
    label: "enthält",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.field || !filterItem.value || !filterItem.operator) {
        return null
      }
      return (value, row) => {
        const formatedValue = DateUtils.getDeFormatWithDots(value)
        const hasDateMatch = formatedValue.includes(filterItem.value?.toUpperCase())

        return hasDateMatch ? row : false
      }
    },
    InputComponent: GridFilterInputValue,
  },
]

export const getApplyQuickStatusFilter: GetApplyQuickFilterFn<any, unknown> = (value) => {
  if (!value) {
    return null
  }

  return (cellValue) => {
    const translateStatus = getTranslateValue(`shared:status.${(cellValue as string)?.toUpperCase()}`)
    return (
      translateStatus.includes(value?.toUpperCase()) || (cellValue as string).includes((value as string)?.toUpperCase())
    )
  }
}

export const getApplyQuickDateFilter: GetApplyQuickFilterFn<any, unknown> = (value) => {
  if (!value) {
    return null
  }

  return (cellValue) => {
    const formatedValue = DateUtils.getDeFormatWithDots(cellValue as string)
    return formatedValue.includes(value?.toUpperCase())
  }
}

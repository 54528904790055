import { Box, styled, Typography } from "@mui/material"
import { Link } from "react-router-dom"

import Logo from "url:../../../../../res/logo.svg"

const Wrapper = styled("div")(({ theme }) => ({
  width: "100%",
  padding: theme.spacing(4),
  paddingLeft: 0,
  background:
    "linear-gradient(to bottom, rgba(255, 255, 255, 1) 29%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0))",
}))

const CkwLogo = styled("img")(({ theme }) => ({
  width: 150,
  paddingLeft: theme.spacing(4),
  [theme.breakpoints.down("md")]: {
    width: 120,
  },
}))

const Header = styled(Typography)(({ theme }) => ({
  fontWeight: 100,
  textAlign: "center",
  fontSize: theme.spacing(5),
  [theme.breakpoints.down("md")]: {
    fontSize: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.spacing(3.5),
  },
}))

export const CustomerStaticHeaderView = () => {
  return (
    <Wrapper>
      <Box position={"relative"}>
        <Box position={"absolute"} top={0} left={0}>
          <Link to="/signin">
            <CkwLogo loading="eager" alt={"CKW Abrechnungs-Manager"} title={"CKW Abrechnungs-Manager"} src={Logo} />
          </Link>
        </Box>
      </Box>
      <Header>Abrechnungs-Manager</Header>
    </Wrapper>
  )
}

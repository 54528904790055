import { I18nNamespace } from "../../../../App.i18n"

export const AreaI18n: I18nNamespace = {
  name: "area",
  de: {
    "label.name": "Areale",
    "utilityType.residence": "Wohneinheit",
    "utilityType.business": "Gewerbeeinheit",
    "utilityType.general_supply": "Allgemeine Versorgung",
    "utilityType.heating_pump": "Wärmepumpe",
    "label.currentParticipant": "Teilnehmer",
    "label.building": "Anschlussobjekt",
    "label.type": "Typ",
    "status.label": "Status",
    "label.unitSpace": "Nutzfläche in m²",
    "label.saveChanges": "Nutzeinheit speichern",
    "errorMsg.consumption_point_update_too_many_participants":
      "Einer oder mehrere Teilnehmer wurden bereits hinzugefügt.",
    "dialog.bodyText": "Wollen sie das Areal inkl. alle dazugehörigen Anschlussobjekte und Nutzeinheiten löschen?",
    "dialog.deactivateBodyText":
      "Wollen sie das Areal inkl. alle dazugehörigen Anschlussobjekte und Nutzeinheiten deaktivieren?",
    "label.deactivate": "Areal deaktivieren",
    "label.areaDelete": "Areal Löschen",
    "label.saveArea": "Areal speichern",
    "label.startDate": "ANFANGSDATUM",
    "breadcrumb.areaCreate": "AREAL ERSTELLEN",
    "breadcrumb.areaEdit": "AREAL BEARBEITEN",
    "breadcrumb.contract": "VERTRAG ERSTELLEN",
    "breadcrumb.building": "ANSCHLUSSOBJEKT",
    "breadcrumb.building.create": "ANSCHLUSSOBJEKT ERSTELLEN",
    "breadcrumb.building.update": "ANSCHLUSSOBJEKT BEARBEITEN",
    "breadcrumb.participantCreate": "TEILNEHMER ERSTELLEN",
  },
  en: {
    "label.name": "Areas",
    "utilityType.residence": "Wohneinheit",
    "utilityType.business": "Gewerbeeinheit",
    "utilityType.general_supply": "Allgemeine Versorgung",
    "utilityType.heating_pump": "Wärmepumpe",
    "label.currentParticipant": "Teilnehmer",
    "label.building": "Anschlussobjekt",
    "label.type": "Type",
    "status.label": "Status",
    "label.unitSpace": "Usable area in m²",
    "label.saveChanges": "Save Unit",
    "errorMsg.consumption_point_update_too_many_participants": "One or more participants have already been added.",
    "dialog.bodyText":
      "Wollen sie das Areal inkl. alle dazugehörigen Anschlussobjekte und Nutzeinheiten inaktiv setzen?",
    "label.deactivate": "Areal deaktivieren",
    "label.areaDelete": "Delete Area",
    "label.saveArea": "Save Area",
    "label.startDate": "START DATE",
    "breadcrumb.areaCreate": "CREATE AREA",
    "breadcrumb.areaEdit": "EDIT AREA",
    "breadcrumb.contract": "CREATE CONTRACT",
    "breadcrumb.building": "BUILDING",
    "breadcrumb.building.create": "CREATE BUILDING",
    "breadcrumb.building.update": "BUILDING UPDATE",
    "breadcrumb.participantCreate": "CREATE PARTICIPANTS",
  },
}

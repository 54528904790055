import { useMemo } from "react"
import { Grid } from "@mui/material"
import { appThemeGrey10, appThemeGrey40, appThemeGrey50, appThemeGrey100 } from "../../app/Shared.Theme"
import { SmallPrimaryMinusButton } from "../button/PrimaryMinusButton"
import { SmallPrimaryPlusButton } from "../button/PrimaryPlusButton"
import { InlineDatePicker } from "./InlineDatePicker"

interface DateMonthSelectorViewProps {
  selectedDateValue: number | string
  onDateChanged: (newDateTime: number) => void
  disabled?: boolean
}

export const DateMonthSelectorView = (props: DateMonthSelectorViewProps) => {
  const { selectedDateValue, onDateChanged, disabled } = props
  const selectedDate: Date = useMemo(() => new Date(selectedDateValue), [selectedDateValue])
  const disabledPlusButton = useMemo(() => {
    const currentDate = new Date()
    return selectedDate.getMonth() >= currentDate.getMonth() && selectedDate.getFullYear() === currentDate.getFullYear()
  }, [selectedDate])
  const buttonBackground = [appThemeGrey40, appThemeGrey50]
  const selectorStyle = { background: appThemeGrey10, padding: 8, borderRadius: 3 }
  const dateOutputStyle = { flex: 1, minWidth: 180, justifyContent: "center", alignItems: "center", display: "flex" }
  return (
    <>
      <Grid style={selectorStyle} container>
        <SmallPrimaryMinusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabled}
          onClick={() => {
            onDateChanged(selectedDate.setMonth(selectedDate.getMonth() - 1))
          }}
        />
        <output style={dateOutputStyle}>
          <InlineDatePicker
            pickerType="month"
            selectedDate={selectedDate}
            onChange={(date) => {
              if (date) onDateChanged(date.getTime())
            }}
          />
        </output>
        <SmallPrimaryPlusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabledPlusButton || disabled}
          onClick={() => {
            onDateChanged(selectedDate.setMonth(selectedDate.getMonth() + 1))
          }}
        />
      </Grid>
    </>
  )
}

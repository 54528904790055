import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import { AddTariffToContractRequest, TariffScheduleRequest } from "../../../../data/generated-sources/openapi"

export const adminGetTariffScheduleById = async (tariffId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminGetTariffScheduleById(
    tariffId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminGetAllTariffSchedules = async () => {
  const { data } = await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminGetAllTariffSchedules(
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const adminGetTariffScheduleForContract = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminGetTariffScheduleForContract(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminCreateTariffSchedule = async (tariffSchedule: TariffScheduleRequest) =>
  DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminCreateTariffSchedule(tariffSchedule, apiHeaders(DOMAIN_DEPENDENCIES))

export const adminAddTariffScheduleToContract = async (
  contractId: string,
  addTariffToContractRequest: AddTariffToContractRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminAddTariffScheduleToContract(
    contractId,
    addTariffToContractRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminUpdateTariffSchedule = async (tariffId: string, tariffSchedule: TariffScheduleRequest) => {
  await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminUpdateTariffScheduleById(
    tariffId,
    tariffSchedule,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminDeleteTariffSchedule = async (tariffId: string) => {
  await DOMAIN_DEPENDENCIES.adminTariffSchedulesApi.adminDeleteTariffScheduleById(
    tariffId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

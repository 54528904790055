export const DashboardChartGridIcon = () => {
  return (
    <svg viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <mask id="dashboardIconGrid_svg__a" maskUnits="userSpaceOnUse" x={0} y={0} width={40} height={40}>
        <path
          d={
            "M18.68 0l-1.734 5.471-11.993 6.243v2.128h1.23v3.649h2.154v-3.65h5.954l-1.536 4.85L0 " +
            "24.936v2.13h1.847v3.648H4v-3.648H10.1L6.154 39.511h2.645L20 31.994l11.203 7.517h2.644L29.9 " +
            "27.065h6.102v3.647h2.152v-3.648H40v-2.129l-12.752-6.243-1.54-4.85h5.343v3" +
            ".649h2.152v-3.65h1.846v-2.126L23.054" +
            " 5.467 21.32 0h-2.64zM20 3.355l.655 2.112h-1.31L20 3.355zM18.687 " +
            "7.6h2.627l1.274 4.116H17.41l1.277-4.116zm5.176.42l7.099" +
            " 3.695h-5.929L23.862 8.02zm-7.723 0l-1.173 3.695H9.039L16.14 8.02zm.612 " +
            "5.823h6.497l1.504 4.85H15.25l1.502-4.85zm-2.162" +
            " 6.977h10.822l1.275 4.116H13.314l1.276-4.116zm13.455.392l7.609 3.724h-6.428l-1.181-3.723v-." +
            "002zm-16.088 0l-1.181 3.724h-6.43l7.612-3.723-.001-.002zm9.96 9.495l5.428-3.642 2.85 9.2-8.278-5.558zm-" +
            "5.429-3.64h7.026L20 29.42l-3.512-2.356zm-3.833 0l5.43 3.641-8.281 5.557 2.851-9.199z"
          }
          fill="#fff"
        />
      </mask>
      <g mask="url(#dashboardIconGrid_svg__a)">
        <path fillRule="evenodd" clipRule="evenodd" d="M-7.778 46.097h54.444v-53.78H-7.779v53.78z" fill="#979797" />
      </g>
    </svg>
  )
}

import { useState, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { Form, Formik } from "formik"
import { priceSchema } from "./ContractElectricityPricesValidation.Schema"

import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { initialZevPriceItem } from "../../../../../domain/portal/admin/prices/Prices.Model"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { ContractElectricityPricesFields } from "./ContractElectricityPricesFields"
import { ZevPricePackage } from "../../../../../data/generated-sources/openapi"
import {
  getContractPricePackages,
  updatePricePackagesByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { useMutation, useQuery } from "@tanstack/react-query"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

export const ContractElectricityPricesForm = ({
  history,
  match: { params },
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { id: contractId } = params
  const { t } = useTranslation("contracts-management")

  const [pricePackages, setPricesPackages] = useState<ZevPricePackage[]>([initialZevPriceItem])
  const [pricesError, setPricesError] = useState<AxiosErrorDataType>()

  const {
    remove: removePrices,
    isLoading,
    isFetching,
    isError: isFetchingPricesError,
  } = useQuery(["getPricePackages"], () => getContractPricePackages(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setPricesError,
    onSuccess: (data) => setPricesPackages(data),
  })

  const {
    mutate: updatePrices,
    isLoading: isUpdatingPrices,
    isError: isUpdatingError,
  } = useMutation(
    ["createMeter"],
    (updateData: ZevPricePackage[]) => updatePricePackagesByContractId(contractId, updateData),
    {
      onError: setPricesError,
      onSuccess: () => history.push(`/management-contracts/${contractId}`),
    },
  )

  useEffect(() => () => removePrices(), [removePrices])

  const handleSubmit = (packagesData: ZevPricePackage[]) => updatePrices(packagesData)

  const addPricePackage = (values: ZevPricePackage[]) => {
    let lastId = [...values]?.pop()?.id || 0
    const nextIdValue = ++lastId
    setPricesPackages([...values, { ...initialZevPriceItem, id: nextIdValue, order: nextIdValue }])
  }

  const removePricePackage = (values: ZevPricePackage[], id: number) => {
    const prices = [...values]?.filter((p) => p.id !== id)
    setPricesPackages(prices)
  }

  return (
    <Formik<ZevPricePackage[]>
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={pricePackages}
      validationSchema={priceSchema}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <ErrorAlert
            visible={!!pricesError && (isUpdatingError || isFetchingPricesError)}
            title={pricesError?.message}
            message={t(`error-codes:${pricesError?.response?.data?.code || pricesError?.code || "OTHER"}`)}
          />
          <ContractElectricityPricesFields
            {...props}
            addPricePackage={() => addPricePackage(props.values)}
            removePricePackage={(id) => removePricePackage(props.values, id)}
          />
          <FormUpdateActionsView
            dirty
            isValid={props.isValid}
            isLoading={(isLoading && isFetching) || isUpdatingPrices}
            navigateBack={() => history.goBack()}
            buttonCtaLabel={t("priceForm.savePrice")}
          />
        </Form>
      )}
    </Formik>
  )
}

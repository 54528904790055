import { I18nNamespace } from "../../../../App.i18n"

export const MediumI18n: I18nNamespace = {
  name: "medium",
  de: {
    "detail.title": "MEDIUM",
    "costShares.title": "Kostenanteile und Zählerkonfiguration",
    "tab.label.ELECTRICITY": "Elektrizität",
    "label.COOLING_BILL": "Kältekosten",
    "label.HEATING_BILL": "Wärmekosten",
    "label.WATER_HEATING": "Warmwasser",
    "label.COOLING_WATER": "Kaltwasser",
    "label.WATER_BILL": "Wasserkosten",
    "label.ROOM_HEATING": "Heizung",
    "label.municipality": "Gemeinde",
    "label.incomingMeters": "Zählpunkte Bezug",
    "label.outgoingMeters": "Zählpunkte Einspeisung",
    "label.basicCost": "Grundkosten",
    "label.consumptionBasedCost": "Verbrauchsabhängige Kosten",
    "label.consumptionCost": "Verbrauchsabhängig",
    "label.commercialUnits": "Gewerbeeinheiten",
    "label.residentalUnits": "Wohneinheiten",
    "label.saveBtn": "Medium speichern",
    "label.INCOMING": "Bezug",
    "label.OUTGOING": "Einspeisung",
    "label.costAllocationTitle": "Ist die Kostenaufteilung so korrekt?",
    "label.deliveryType": "Zählpunkt Typ",
    "table.header.deliveryId": "Zählpunkt-ID",
    "table.header.startDate": "Startdatum",
    "table.header.endDate": "Enddatum",
    "confirmDialog.title": "Wollen Sie den Abrechnungsbereich, inkl. Kostenanteile und Zählerkonfiguration, löschen?",
    "confirmDialog.button": "Abrechnungsbereich löschen",

    "label.groupMeters.HEATING_BILL": "Wärmekosten Zählerkonfiguration",
    "label.groupMeters.COOLING_BILL": "Kältekosten Zählerkonfiguration",
    "label.groupMeters.WATER_BILL": "Wasserkosten Zählerkonfiguration",
    "label.heating.meterTotalConsumption": "Wärmezähler Gesamtverbrauch",
    "label.heating.meterTotalConsumptionHeating": "Wärmezähler Gesamtverbrauch Heizung",
    "label.cooling.meterTotalConsumption": "Kältezähler",
    "label.water.meterTotalConsumption": "Verteilschlüssel für verbrauchsabhängigen Anteil",
    "label.heating.meterHeating": "Wärmezähler Heizung",
    "label.meter": "Zähler",
    "label.measuredHotWaterConsumption": "Gemessener Warmwasserverbrauch",
    "label.heating.waterMeter": "Wärmezähler Wasseraufbereitung",
    "label.findAddMeters": "Zähler suchen und hinzufügen",
    "label.heating.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Heizung' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.cooling.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kälte' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.water.groupCounterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.heating.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Warmwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.coolingWater.groupCounterWaterText":
      "Gruppenzähler für Anrechnung an den Verbrauch 'Kaltwasser' (zusätzlich zu den Nutzeinheiten-Zählern)",
    "label.noMeters": "Keine Zähler gefunden",
    "message.successUpdateVewaConfig": "Vewa-Konfiguration erfolgreich aktualisiert",
  },
  en: {
    "detail.title": "MEDIUM",
    "costShares.title": "Cost Shares and Counter Configuration",
    "tab.label.ELECTRICITY": "Electricity",
    "label.COOLING_BILL": "Cooling costs",
    "label.HEATING_BILL": "Heat costs",
    "label.WATER_HEATING": "Hot water",
    "label.COOLING_WATER": "Cooling water",
    "label.WATER_BILL": "Water costs",
    "label.ROOM_HEATING": "Heating",
    "label.municipality": "Municipality",
    "label.incomingMeters": "Incoming Meters",
    "label.outgoingMeters": "Outgoing Meters",
    "label.basicCost": "Basic costs",
    "label.consumptionBasedCost": "Consumption-based costs",
    "label.consumptionCost": "Consumption-based",
    "label.commercialUnits": "Commercial units",
    "label.residentalUnits": "Residential units",
    "label.saveBtn": "Save Medium",
    "label.INCOMING": "Incoming",
    "label.OUTGOING": "Outgoing",
    "label.costAllocationTitle": "Is the cost allocation correct?",
    "label.deliveryType": "Delivery type",
    "table.header.deliveryId": "Delivery ID",
    "table.header.startDate": "Start date",
    "table.header.endDate": "End date",
    "confirmDialog.title": "Do you want to delete the billing area, including cost shares and meter configuration?",
    "confirmDialog.button": "Delete billing area",

    "label.groupMeters.HEATING_BILL": "Heat costs meter configuration",
    "label.groupMeters.COOLING_BILL": "Cooling costs meter configuration",
    "label.groupMeters.WATER_BILL": "Water costs meter configuration",
    "label.heating.meterTotalConsumption": "Total heat meter consumption",
    "label.heating.meterTotalConsumptionHeating": "Total heat meter consumption heating",
    "label.cooling.meterTotalConsumption": "Cooling meter",
    "label.water.meterTotalConsumption": "Distribution key for consumption-based share",
    "label.heating.meterHeating": "Heating meter",
    "label.meter": "Meter",
    "label.measuredHotWaterConsumption": "Measured hot water consumption",
    "label.heating.waterMeter": "Heating water meter",
    "label.findAddMeters": "Find and add meters",
    "label.heating.groupCounterText":
      "Group counter for allocation to 'Heating' consumption (in addition to the unit meters)",
    "label.cooling.groupCounterText":
      "Group counter for allocation to 'Cooling' consumption (in addition to the unit meters)",
    "label.water.groupCounterText":
      "Group counter for allocation to 'Hot water' consumption (in addition to the unit meters)",
    "label.heating.groupCounterWaterText":
      "Group counter for allocation to 'Hot water' consumption (in addition to the unit meters)",
    "label.coolingWater.groupCounterWaterText":
      "Group counter for allocation to 'Cooling water' consumption (in addition to the unit meters)",
    "label.noMeters": "No meters found",
    "message.successUpdateVewaConfig": "Vewa configuration updated successfully",
  },
}

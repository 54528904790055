/* eslint-disable max-len */
export const MeterReadingSoldIcon = () => {
  return (
    <svg width="2em" height="2em" viewBox="0 0 34 36" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        fill="#6C9C30"
        d="M25.072 21.246l-1.076-3.436h3.738v2.585h1.508V17.81h1.292v-1.507l-8.396-4.426L20.924 8h-1.847l-1.215 3.877-8.396 4.426v1.507h.862v2.585h1.508V17.81h4.168l-1.076 3.436L6 25.672v1.507h1.293v2.585H8.8v-2.585h4.27L10.308 36h1.851L20 30.673 27.841 36h1.852l-2.763-8.82h4.27v2.584h1.508v-2.585H34v-1.507l-8.928-4.426zm-9.752 4.426l.893-2.918h7.575l.893 2.918H15.32zm7.14 1.507L20 28.85l-2.459-1.67h4.918zm5.214-10.876h-4.15l-.82-2.62 4.97 2.62zm-5.861 0h-3.626l.893-2.918h1.84l.893 2.918zm-1.354-4.426h-.917L20 10.378l.459 1.499zm-8.132 4.426l4.97-2.62-.82 2.62h-4.15zm9.947 1.507l1.052 3.436h-6.652l1.051-3.436h4.55zM9.042 25.672l5.327-2.64-.827 2.64h-4.5zm3.821 8.027l1.996-6.52 3.8 2.582-5.796 3.939zm8.479-3.938l3.8-2.582 1.995 6.52-5.795-3.938zm5.116-4.09l-.827-2.64 5.327 2.64h-4.5z"
      />
    </svg>
  )
}

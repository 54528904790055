import { useEffect } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Paper, Stack, Typography } from "@mui/material"
import {
  approveAdminServiceBillingRunById,
  deleteAdminServiceBillingRunById,
  getAdminServiceBillingRunById,
  sapSendAdminServiceBillingRunById,
} from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Repository"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { StatusType } from "../../../../../domain/Domain.Model"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { ConfirmDialog } from "../../../../../uikit/confirmDialog/ConfirmDialog"
import { RemoveIcon, SendIcon } from "../../../../../uikit/Shared.Icon"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { ServiceBillingRunState } from "../../../../../data/generated-sources/openapi"
import { RecurringBillingDetailsTable } from "./fragments/RecurringBillingDetailsTable"

export const RecurringDetails = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId } = params
  const { t } = useTranslation("billings-recurring")

  const {
    data: recurringBilling,
    remove: removeRecurringBilling,
    isFetching: isFetchingBilling,
    isError: isBillError,
    error: billError,
    refetch,
  } = useQuery(["getRecurringBilling"], () => getAdminServiceBillingRunById(billingId), {
    enabled: !!billingId,
  })

  const {
    mutate: deleteRecBilling,
    isLoading: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation(["deleteRecBilling"], () => deleteAdminServiceBillingRunById(billingId), {
    onSuccess: () => history.goBack(),
  })

  const {
    mutate: approveBilling,
    isLoading: isApproving,
    isError: isApproveError,
    error: aprroveError,
    isSuccess: isAprroveSuccess,
  } = useMutation(["approveBilling"], () => approveAdminServiceBillingRunById(billingId), {
    onSuccess: () => refetch(),
  })

  const {
    mutate: sendToSap,
    isLoading: isSendingToSap,
    isError: isSapError,
    error: sapError,
    isSuccess: isSapSuccess,
  } = useMutation(["sendToSap"], () => sapSendAdminServiceBillingRunById(billingId), {})

  useEffect(() => {
    return () => removeRecurringBilling()
  }, [removeRecurringBilling])

  const showError = isBillError || isDeletingError || isApproveError || isSapError
  const errorMsg = (billError || deletingError || aprroveError || sapError) as AxiosErrorDataType
  const successMsg = isSapSuccess ? t("details.action.billingSap.success") : t("details.action.approve.success")

  return (
    <Paper data-testid="recurringBillingDetailsTableWrapper">
      <OptionalSuccessAlert message={successMsg} show={isAprroveSuccess || isSapSuccess} scrollOnDisplay />
      <ErrorAlert
        scrollOnDisplay
        visible={showError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      {recurringBilling && (
        <>
          <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} mb={4}>
            <StatusView
              statusType={StatusType[recurringBilling?.activeState]}
              data-testid="recurringBillingDetailsStatus"
            />
            <ConfirmDialog
              onConfirm={deleteRecBilling}
              actionButtonLoading={isDeleting}
              actionButtonDisabled={recurringBilling?.activeState !== ServiceBillingRunState.DRAFT || isDeleting}
              actionButtonStartIcon={<RemoveIcon fontSize="large" />}
              actionButtonText={t("details.button.extinguish")}
              confirmButtonText={t("details.button.extinguish")}
              data-testid="recurringBillingDetailsDeleteBtn"
            />
          </Stack>

          <Typography variant="h4" mb={5} data-testid="recurringBillingDetailsTitle">
            {recurringBilling?.billingDatePeriod}
          </Typography>
        </>
      )}

      <RecurringBillingDetailsTable
        isLoading={isFetchingBilling}
        serviceBillings={recurringBilling?.serviceBillings ?? []}
      />

      <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-end"}>
        {recurringBilling?.activeState === ServiceBillingRunState.DRAFT && (
          <ConfirmDialog
            onConfirm={() => approveBilling()}
            actionButtonLoading={isApproving}
            actionButtonDisabled={isFetchingBilling}
            actionButtonText={t("details.button.shareAll")}
            confirmButtonText={t("details.button.shareAll")}
            data-testid="recurringBillingDetailsApproveBtn"
          />
        )}
        <ConfirmDialog
          onConfirm={() => sendToSap()}
          actionButtonLoading={isSendingToSap}
          actionButtonStartIcon={<SendIcon fontSize="large" />}
          actionButtonText={t("details.button.submitAllToSAP")}
          confirmButtonText={t("details.button.submitAllToSAP")}
          actionButtonDisabled={recurringBilling?.activeState !== ServiceBillingRunState.APPROVED || isFetchingBilling}
          data-testid="recurringBillingDetailsSapBtn"
        />
      </Stack>
    </Paper>
  )
}

import { AreaBuildingsApiEnum } from "../Components.Enums"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { AreaBuildingsEnum } from "../areaBuildings/AreaBuildings.Enum"
import { AreaBuildingsColumnComparator } from "../areaBuildings/AreaBuildingsColumnComparator"
import AreaBuildingsTableComponent from "../areaBuildings/AreaBuildingsTable.Component"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const AREA_BUILDINGS_LIST_COMPONENT_CONFIG = {
  areaBuildings: {
    name: "areaBuildings",
    header: {
      title: "buildings:list.title",
      showButton: true,
      showFilter: false,
      redirectUrl: () => `${location.pathname}/building/create`,
    },
    columnEnums: AreaBuildingsEnum,
    defaultRowsPerPage: 10,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: AreaBuildingsEnum.NAME,
      direction: "desc",
    },
    columnComparator: AreaBuildingsColumnComparator,
    tableHeaders: [
      {
        column: AreaBuildingsEnum.STATUS,
        label: "shared:status.label",
        width: "25%",
        orderable: true,
      },
      {
        column: AreaBuildingsEnum.NAME,
        label: "shared:label.name",
        width: "25%",
        orderable: true,
      },
      {
        column: AreaBuildingsEnum.STREET,
        label: "buildings:list.label.street",
        width: "25%",
        orderable: true,
      },
      {
        column: AreaBuildingsEnum.CITY,
        label: "buildings:list.label.city",
        width: "25%",
        orderable: true,
      },
    ],
    renderTableRows: (item: BuildingAdminResponse) => (
      <AreaBuildingsTableComponent itemConfig={item} key={getUniqueId()} />
    ),
  },
}

export const AREA_BUILDINGS_API_CONFIG = {
  areaBuildings: AreaBuildingsApiEnum,
}

import { useQuery } from "@tanstack/react-query"
import React, { useEffect, useState } from "react"
import { customerGetAreaManagerContracts } from "../../../../domain/portal/manager/contracts/Contract.Repository"
import { ONBOARDING_REQUIRED_COOKIE_NAME, createCookie, eraseCookie } from "../../../Shared.Cookie"
import { ContractCustomerResponse, ContractState, ProductType } from "../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"
import { Box, Stack, Step, Stepper, Typography } from "@mui/material"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { OnboardingContactInfo } from "./ContactInfo/OnboardingContactInfo"
import { OnboardingBillingInfo } from "./OnboardingBillingInfo"
import { OnboardingElectricityPricesInfo } from "./OnboardingElectricityPricesInfo"
import { OnboardingParticipationsInfo } from "./OnboardingParticipationsInfo"
import { OnboardingVewaCostShares } from "./OnboardingVewaCostShares"
import { OnboardingFinalize } from "./OnboardingFinalize"
import { OnboardingWelcome } from "./OnboardingWelcome"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export interface OnboardingPagesProps {
  contractId: string
  onBackClick: () => void
  onNextClick: () => void
  contractName?: string
  areaId?: string
  startDate?: string
}

export const Onboarding = ({ areaId }: { areaId: string }) => {
  const { t } = useTranslation("onboarding")
  const [error, setError] = useState<AxiosErrorDataType>()
  const [selectedContract, setSelectedContract] = useState<ContractCustomerResponse>()
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)
  const handleBack = () => setActiveStep((prevActiveStep) => prevActiveStep - 1)

  const {
    isLoading,
    isFetching,
    remove: removeContracts,
    data: contractList,
    refetch,
  } = useQuery(["getContracts", areaId], () => customerGetAreaManagerContracts(areaId), {
    enabled: !!areaId,
    onError: setError,
  })

  useEffect(() => {
    return () => removeContracts()
  }, [removeContracts])

  const handleBackToOverview = () => {
    refetch()
    setSelectedContract(undefined)
    setActiveStep(0)
  }

  const contractsForOnboarding = contractList?.filter((contract) =>
    [ContractState.RELEASED, "RELEASED_AND_MODIFIED"].includes(contract.activeState),
  )

  if (contractsForOnboarding?.length) {
    createCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${areaId}`, "true", 1 / 24)
  } else {
    eraseCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${areaId}`)
  }
  if (!contractsForOnboarding) return <></>

  const OnboardingHeader = () => {
    if (activeStep) {
      return (
        <>
          <Stack px={11} justifyContent={"center"} sx={{ height: "172px" }}>
            <Typography component={"h3"} fontSize={14} fontWeight={700} textTransform={"uppercase"}>
              {t("header.contractSetup")}
            </Typography>
            <Typography component={"h1"} fontSize={36} fontWeight={300} textTransform={"uppercase"}>
              {selectedContract?.name}
            </Typography>
          </Stack>
          {activeStep !== 5 && (
            <Stepper activeStep={activeStep} variant="outlined">
              {[1, 2, 3, 4, 5].map((index) => (
                <Step key={index} />
              ))}
            </Stepper>
          )}
        </>
      )
    }
    return (
      <Stack px={11} justifyContent={"center"} sx={{ height: "180px", borderBottom: "1px solid #D5D8D5" }}>
        <Typography component={"h1"} fontSize={36} fontWeight={300} textTransform={"uppercase"}>
          {t("header.wellcome")}
        </Typography>
      </Stack>
    )
  }

  return (
    <Stack flex={1}>
      <OnboardingHeader />
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <Box p={11}>
        {activeStep === 0 && (
          <OnboardingWelcome
            onNextClick={handleNext}
            contracts={contractsForOnboarding}
            isLoading={isLoading && isFetching}
            onContractSelect={setSelectedContract}
          />
        )}
        {selectedContract && selectedContract?.id && (
          <>
            {activeStep === 1 && (
              <OnboardingContactInfo
                contractId={selectedContract?.id}
                onBackClick={handleBack}
                onNextClick={handleNext}
              />
            )}
            {activeStep === 2 && (
              <OnboardingBillingInfo
                contractId={selectedContract?.id}
                onBackClick={handleBack}
                onNextClick={handleNext}
              />
            )}
            {activeStep === 3 && (
              <>
                {selectedContract?.product?.productType === ProductType.ZEV ? (
                  <OnboardingElectricityPricesInfo
                    contractId={selectedContract?.id}
                    onBackClick={handleBack}
                    onNextClick={handleNext}
                  />
                ) : (
                  <OnboardingVewaCostShares
                    contractId={selectedContract?.id}
                    onBackClick={handleBack}
                    onNextClick={handleNext}
                  />
                )}
              </>
            )}
            {activeStep === 4 && (
              <OnboardingParticipationsInfo
                contractId={selectedContract?.id}
                startDate={selectedContract?.startDate}
                onBackClick={handleBack}
                onNextClick={handleNext}
              />
            )}
            {activeStep === 5 && (
              <OnboardingFinalize
                areaId={areaId}
                backToOnboardOverview={handleBackToOverview}
                onboardingContracts={contractsForOnboarding}
                selectedContract={selectedContract?.id}
                contractName={selectedContract?.name}
              />
            )}
          </>
        )}
      </Box>
    </Stack>
  )
}

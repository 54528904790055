import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CancelButton } from "../button/CancelButton"
import { Dialog, DialogActions, DialogContent, DialogContentText, Box, DialogTitle, IconButton } from "@mui/material"
import { PrimaryButton } from "../button"
import { AbortIcon } from "../Shared.Icon"

export interface ConfirmDialogProps {
  onConfirm: () => void
  onCancel?: () => void
  actionButtonText?: string
  actionButtonStartIcon?: React.ReactNode
  actionButtonStyle?: object
  actionButtonLoading?: boolean
  actionButtonDisabled?: boolean
  dialogTitle?: string
  dialogBodyText?: string
  confirmButtonText?: string
  confirmButtonDisabled?: boolean
  confirmButtonLoading?: boolean
  cancelButtonText?: string
  dialogBody?: React.ReactNode
  keepOpenAfterConfirm?: boolean
  openModal?: boolean
  showPrimaryButton?: boolean
}

export const ConfirmDialog = ({
  onConfirm,
  onCancel,
  actionButtonText,
  actionButtonStartIcon,
  actionButtonStyle,
  actionButtonDisabled,
  actionButtonLoading = false,
  dialogTitle,
  dialogBody,
  dialogBodyText,
  confirmButtonText,
  confirmButtonDisabled,
  confirmButtonLoading = false,
  cancelButtonText,
  keepOpenAfterConfirm = false,
  openModal = false,
  showPrimaryButton = true,
}: ConfirmDialogProps) => {
  const { t } = useTranslation("shared")
  const [showDialog, setShowDialog] = useState(openModal)
  const [submitted, setSubmitted] = useState(false)
  const openDialog = () => setShowDialog(true)
  const closeDialog = () => {
    setShowDialog(false)
    if (onCancel) {
      onCancel()
    }
  }

  useEffect(() => {
    setShowDialog(openModal)
  }, [openModal])

  const onConfirmPress = () => {
    closeDialog()
    onConfirm()
  }

  const onConfirmSubmit = () => {
    if (submitted) {
      return closeDialog()
    }
    onConfirm()
    setSubmitted((submitted) => !submitted)
  }

  const handleConfirmPress = () => {
    keepOpenAfterConfirm ? onConfirmSubmit() : onConfirmPress()
  }

  let confirmLabel = t("dialog.confirm.cta")
  if (confirmButtonText) {
    confirmLabel = confirmButtonText
  }
  if (submitted) {
    confirmLabel = t("dialog.close.cta")
  }

  return (
    <>
      {showPrimaryButton ? (
        <PrimaryButton
          onClick={openDialog}
          label={actionButtonText ?? ""}
          disabled={actionButtonDisabled}
          startIcon={actionButtonStartIcon}
          isLoading={actionButtonLoading}
          sx={{ mr: 2, mb: 2, ...actionButtonStyle }}
        />
      ) : null}

      <Dialog open={showDialog} onClose={closeDialog} fullWidth data-testid="confirmDialog">
        <IconButton
          aria-label="close"
          onClick={closeDialog}
          sx={{
            top: 35,
            right: 35,
            position: "absolute",
          }}
        >
          <AbortIcon />
        </IconButton>
        <Box p={2}>
          <DialogTitle data-testid="confirmDialogTitle">{dialogTitle ?? t("dialog.confirm.title")}</DialogTitle>
          <DialogContent>
            {dialogBody ? dialogBody : dialogBodyText && <DialogContentText>{dialogBodyText}</DialogContentText>}
          </DialogContent>
          <DialogActions>
            <CancelButton label={cancelButtonText} onClick={closeDialog} data-testid="confirmDialogCancelBtn" />
            <PrimaryButton
              type="submit"
              onClick={handleConfirmPress}
              label={confirmLabel}
              disabled={confirmButtonDisabled || actionButtonLoading}
              isLoading={confirmButtonLoading}
              data-testid="confirmDialogSubmitBtn"
            />
          </DialogActions>
        </Box>
      </Dialog>
    </>
  )
}

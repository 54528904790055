import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { billingSchema } from "./ContractBillingValidation.Schema"
import { omit } from "lodash-es"

import ContractBillingDataFields from "./ContractBillingDataFields"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import {
  getBillingSettingsByContractId,
  updateBillingSettingsByContractId,
  getProductAndContractByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import {
  BillingSettingsResponse,
  BillingFrequency,
  ProductType,
  BillingSettingsUpdateAdminRequest,
} from "../../../../../data/generated-sources/openapi/api"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

const initialBilling: BillingSettingsResponse = {
  nextBillingDate: "",
  billingFrequency: BillingFrequency.MONTHLY,
  billingPeriod: {
    startDate: "",
    endDate: "",
  },
  mainContactPerson: {
    name: "",
    contact: {},
  },
  paymentInformation: {
    payee: "",
    iban: "",
    paymentDeadlineInDays: 0,
  },
}

export const ContractBillingForm = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { id: contractId } = params
  const { t } = useTranslation("contracts-management")
  const [billingError, setBillingError] = useState<AxiosErrorDataType>()

  const {
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
    isError: isBillingError,
    data: billing,
    remove: removeBilling,
  } = useQuery(["getBilling"], () => getBillingSettingsByContractId(contractId), {
    enabled: !!contractId,
    refetchOnWindowFocus: false,
    onError: setBillingError,
  })

  const {
    isLoading: isLoadingProduct,
    isFetching: isFetchingProduct,
    isError: isProductError,
    data: contractProductData,
    remove: removeProductAndContract,
  } = useQuery(["getProductAndContract"], () => getProductAndContractByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setBillingError,
  })

  const contractProductType = contractProductData?.product?.productType ?? ProductType.ZEV
  const lastBillingDate = billing?.data?.billingPeriod.startDate ?? contractProductData?.contract?.startDate

  const {
    mutate: updateBilling,
    isLoading: isUpdatingBilling,
    isError: isUpdatingError,
  } = useMutation(
    ["updateBillingSettings"],
    (billingData: BillingSettingsUpdateAdminRequest) => updateBillingSettingsByContractId(contractId, billingData),
    {
      onSuccess: () => history.push(`/management-contracts/${contractId}`),
      onError: setBillingError,
    },
  )

  const handleSubmit = (billingData: BillingSettingsUpdateAdminRequest) => {
    const billing = omit(billingData, "billingPeriod")
    updateBilling(billing)
  }

  useEffect(() => {
    return () => {
      removeBilling()
      removeProductAndContract()
    }
  }, [removeBilling, removeProductAndContract])

  if ((isLoadingBilling && isFetchingBilling) || (isLoadingProduct && isFetchingProduct)) return <FullScreenLoader />

  return (
    <Formik<BillingSettingsResponse>
      initialValues={{ ...initialBilling, ...billing?.data }}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur
      validationSchema={billingSchema}
    >
      {(props) => (
        <Form>
          <ErrorAlert
            visible={(isBillingError || isProductError || isUpdatingError) && !!billingError}
            message={t(`error-codes:${billingError?.response?.data?.code || "OTHER"}`)}
          />
          <ContractBillingDataFields {...props} productType={contractProductType} vewaStartDate={lastBillingDate} />
          <FormUpdateActionsView
            isLoading={
              (isLoadingProduct && isFetchingProduct) || (isLoadingBilling && isFetchingBilling) || isUpdatingBilling
            }
            navigateBack={() => history.push(`/management-contracts/${contractId}`)}
            buttonCtaLabel={t("billingForm.saveBilling")}
            {...props}
          />
        </Form>
      )}
    </Formik>
  )
}

import {
  BuildingCustomerResponse,
  PriceCalculatorTariffs,
  ContractCustomerResponse,
  ZevPriceCalculatorInput,
  ZevPricePackage,
} from "../../../../data/generated-sources/openapi"
import { formatNumber } from "../../../Domain.Formatters"
import { MyZevPriceUpsert, MyZevSummary, ZevPriceCalculatorTariffs } from "./MyZev.Model"

//TODO test data after contrat endpoint is fixed
export const myZevSummaryMapper = (
  buildingCustomerResponse: BuildingCustomerResponse[],
  zevCustomerResponse?: ContractCustomerResponse,
  zevPricePackage?: ZevPricePackage[],
): MyZevSummary => {
  const allConsumptionPointIds = buildingCustomerResponse.reduce(
    // (acc, itm) => acc.concat(itm.utilityUnit),
    (acc, itm) => acc.concat(itm.utilityUnitIds),
    [] as string[],
  )
  const buildingsMap = buildingCustomerResponse.reduce(
    (acc, itm) => acc.set(itm.id, itm),
    new Map<string, BuildingCustomerResponse>(),
  )

  return {
    id: zevCustomerResponse?.id ?? "",
    name: zevCustomerResponse?.name ?? "",
    streetAddress: zevCustomerResponse?.address?.street || "",
    postCodeCity: zevCustomerResponse?.address?.postalCode || "",
    // pricePackages: zevCustomerResponse?.pricePackages,
    pricePackages: zevPricePackage,
    buildings: zevCustomerResponse?.buildings ?? [],
    vatNumber: zevCustomerResponse?.vatNumber ?? "",
    allConsumptionPointIds,
    buildingsMap,
  }
}

export const zevPriceCalculatorInputMapper = (
  zevPriceCalculatorEnergyResponse: ZevPriceCalculatorInput | undefined,
): ZevPriceCalculatorInput | null => {
  if (zevPriceCalculatorEnergyResponse) {
    let { operatingCostsPerKwh, interestRateOnInvestment, sellingElectricityPricePerKwh } =
      zevPriceCalculatorEnergyResponse
    operatingCostsPerKwh *= 100
    interestRateOnInvestment *= 100
    sellingElectricityPricePerKwh *= 100

    return {
      ...zevPriceCalculatorEnergyResponse,
      operatingCostsPerKwh,
      interestRateOnInvestment,
      sellingElectricityPricePerKwh,
    }
  }
  return zevPriceCalculatorEnergyResponse ?? null
}

export const zevPriceCalculatorTariffsMapper = (
  zevPriceCalculatorTariffsResponse: PriceCalculatorTariffs[],
): ZevPriceCalculatorTariffs[] => {
  return zevPriceCalculatorTariffsResponse.map((tariffsYearly) => {
    const tweakedTariffs = tariffsYearly.tariffs.map((tariff) => {
      let { high, low } = tariff
      high *= 100
      low *= 100

      return {
        ...tariff,
        high,
        low,
      }
    })
    return {
      ...tariffsYearly,
      tariffs: tweakedTariffs,
    }
  })
}

export const tariffsByYear = (response: ZevPriceCalculatorTariffs[], year: number) => {
  const tariff = response.find((entry) => entry.year === year)
  if (tariff !== undefined)
    return tariff.tariffs.map((tariff) => ({
      ...tariff,
      basePrice: parseFloat(formatNumber(tariff.basePrice)),
      peakPower: parseFloat(formatNumber(tariff.peakPower)),
      high: parseFloat(formatNumber(tariff.high)),
      low: parseFloat(formatNumber(tariff.low)),
    }))
  return []
}

export const zevPricePackageMapper = (contractId: string, pricePackages: ZevPricePackage[]): MyZevPriceUpsert[] => {
  const prices: MyZevPriceUpsert[] = pricePackages.map((p) => {
    return {
      contractId: contractId,
      id: p.id,
      formTitle: p.name,
      name: p.name,
      order: p.order,
      solarPrice: p.prices?.solarPrice ?? "",
      highTariffPrice: p.prices?.highTariffPrice ?? "",
      lowTariffPrice: p.prices?.lowTariffPrice ?? "",
      additionalServicesPrice: p.prices?.additionalServicesPrice ?? "",
      spikePrice: p.prices?.spikePrice ?? "",
      isFixedRate: p.prices?.isFixedRate ?? false,
    }
  })
  return prices
}

import { Paper, Stack, Typography, styled } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { MutationFilters, useQuery, useQueryClient } from "@tanstack/react-query"
import { adminGetAllTariffSchedules } from "../../../../domain/portal/admin/tariffs/Tariffs.Repository"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { PrimaryButton } from "../../../../uikit/button"
import { PlusIcon } from "../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { TariffSuppliersTable } from "./fragments/TariffSuppliersTable"

export const TariffPageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(24),
  color: theme.palette.text.primary,
}))

export const TariffSuppliers = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("tariff")
  const [error, setError] = useState<AxiosErrorDataType>()
  const queryClient = useQueryClient()

  const {
    data: tariffsData,
    isFetching,
    remove: removeTariffs,
  } = useQuery(["getTariffs"], () => adminGetAllTariffSchedules(), {
    onError: (error: AxiosErrorDataType) => {
      setError(error)
    },
    onSettled: () => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const deleteTariffMutations = queryClient.getMutationCache()?.findAll((mutation: MutationFilters) => {
        if (mutation.mutationKey && mutation.mutationKey.includes("deleteTariff")) return mutation
      })
      const lastMutation = deleteTariffMutations[deleteTariffMutations?.length - 1]

      setError(lastMutation?.state.error ? (lastMutation.state.error as AxiosErrorDataType) : undefined)
    },
  })

  useEffect(() => {
    return () => removeTariffs()
  }, [])

  return (
    <Paper>
      <Stack flexDirection={"row"} alignItems={"start"} justifyContent={"space-between"} pb={3}>
        <TariffPageTitle data-testid="energySupplierTitle" textTransform={"uppercase"}>
          {t("energySupplier")}
        </TariffPageTitle>
        <PrimaryButton
          data-testid="addEnergySupplier"
          startIcon={<PlusIcon />}
          sx={{ textTransform: "capitalize" }}
          label={t("shared:input.hint.add")}
          onClick={() => history.push("/tariff/add")}
        />
      </Stack>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${(error as AxiosErrorDataType)?.response?.data?.code ?? "OTHER"}`)}
      />
      <TariffSuppliersTable tariffsData={tariffsData || []} isLoading={isFetching} />
    </Paper>
  )
}

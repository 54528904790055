import { Box, Tab } from "@mui/material"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import { SmallPaddedBox } from "../../../../../uikit/box/PaddedBox"
import { INTRA_DAY_METER_CHART_HEIGHT, LineChartView } from "../../../../../uikit/chart/LineChartView"
import { MiddleCircularProgress } from "../../../../../uikit/indicator/ProgressIndicator"
import { vewaTabList } from "./chart.config"
import { EmptyChartDisplay } from "../../../../../uikit/chart/EmptyChartView"
import React, { SyntheticEvent } from "react"
import { TabItemModel, VewaDailyPropsInterface } from "../Analise.Model"
import { TabContext, TabList, TabPanel } from "@mui/lab"
import { ChartTabsEnum } from "../Analise.Enum"
import { useTranslation } from "react-i18next"
import { Medium } from "../../../../../data/generated-sources/openapi"
import { TFunction } from "i18next"
import { ChartConfig } from "../../../../../uikit/chart/ChartConfig"
import { mediumBlueLightColor, mediumPurpleColor, mediumPurpleMidColor, mediumRedColor } from "../../../../Shared.Theme"

export const VewaDailyComponent = (props: VewaDailyPropsInterface) => {
  const { t } = useTranslation("analise")
  const { chartData, isLoading, setSelectTab, selectedTab } = props

  const tabListLayout = () => {
    return vewaTabList?.map((tab: TabItemModel, index: number) => {
      return <Tab label={t(tab.translateLabel)} sx={{ textTransform: "initial" }} value={tab.value} key={index} />
    })
  }

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setSelectTab(newValue)
  }

  const getVewaDailyChartConfig = (isHeat: boolean, t: TFunction): ChartConfig[] => {
    const configArray: ChartConfig[] = [
      {
        id: "produced",
        color: isHeat ? mediumRedColor : mediumPurpleColor,
        title: t(isHeat ? "legend.heat" : "legend.heatWater"),
        chartUnit: isHeat ? "kWh" : "m3",
        unit: isHeat ? (
          "kWh"
        ) : (
          <span>
            m<sup>3</sup>
          </span>
        ),
        type: "line",
      },
      {
        id: "energyHighTariff",
        color: isHeat ? mediumBlueLightColor : mediumPurpleMidColor,
        title: t(isHeat ? "legend.cooling" : "legend.coldWater"),
        chartUnit: isHeat ? "kWh" : "m3",
        unit: isHeat ? (
          "kWh"
        ) : (
          <span>
            m<sup>3</sup>
          </span>
        ),
        type: "line",
      },
    ]
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!chartData.filter((item) => !!item.produced).length) configArray.splice(0, 1)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!chartData.filter((item) => !!item.energyHighTariff).length) configArray.splice(1, 1)

    return configArray
  }

  return (
    <>
      <TabContext value={selectedTab}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="ckw-tabs-wrapper">
          <TabList
            onChange={handleChange}
            aria-label="chart-tabs"
            sx={{ paddingLeft: "20px", textTransform: "capitalize" }}
            indicatorColor="secondary"
            textColor="inherit"
          >
            {tabListLayout()}
          </TabList>
        </Box>
        <TabPanel value={ChartTabsEnum.HEAT}>
          {!!chartData.length && (
            <Box pb={4} pl={2}>
              <Subtitle1>{t("shared:chart.legend.subtitle")}</Subtitle1>
            </Box>
          )}
          <SmallPaddedBox>
            <Box
              sx={{
                width: "100%",
                height: INTRA_DAY_METER_CHART_HEIGHT,
              }}
            >
              {isLoading && <MiddleCircularProgress height={INTRA_DAY_METER_CHART_HEIGHT} />}
              {chartData.length > 0 && (
                <LineChartView
                  dataKey="dateTime"
                  data={chartData}
                  chartConfig={getVewaDailyChartConfig(selectedTab === Medium.HEAT, t)}
                />
              )}
              {chartData.length === 0 && <EmptyChartDisplay />}
            </Box>
          </SmallPaddedBox>
        </TabPanel>
        <TabPanel value={ChartTabsEnum.WATER}>
          {!!chartData.length && (
            <Box pb={4} pl={2}>
              <Subtitle1>{t("shared:chart.legend.subtitle")}</Subtitle1>
            </Box>
          )}
          <SmallPaddedBox>
            <Box
              sx={{
                width: "100%",
                height: INTRA_DAY_METER_CHART_HEIGHT,
              }}
            >
              {isLoading && <MiddleCircularProgress height={INTRA_DAY_METER_CHART_HEIGHT} />}
              {chartData.length > 0 && (
                <LineChartView
                  dataKey="dateTime"
                  data={chartData}
                  chartConfig={getVewaDailyChartConfig(selectedTab === Medium.HEAT, t)}
                />
              )}
              {chartData.length === 0 && <EmptyChartDisplay />}
            </Box>
          </SmallPaddedBox>
        </TabPanel>
      </TabContext>
    </>
  )
}

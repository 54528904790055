import * as yup from "yup"
import { VALIDATION_DEPS } from "../../../../Shared.Validation"
import { getTranslateValue } from "../../../../Shared.Utils"

export const contactSchema = yup.object().shape({
  contact: yup
    .object({
      mobile: yup.string().test(
        "mobile",
        () =>
          getTranslateValue("shared:validation.valid.mobileNumber", {
            field: getTranslateValue("contracts-management:contactForm.mobile"),
          }),
        (val) => (val ? VALIDATION_DEPS.validSwissMobile(val) : true),
      ),
      telephone: yup
        .string()
        .optional()
        .test(
          "telephone",
          () =>
            getTranslateValue("shared:validation.valid.phoneNumber", {
              field: getTranslateValue("contracts-management:contactForm.phone"),
            }),
          (val) => (val ? VALIDATION_DEPS.validSwissPhoneNumber(val) : true),
        ),
      email: yup
        .string()
        .optional()
        .test(
          "email",
          () =>
            getTranslateValue("shared:validation.valid.email", {
              field: getTranslateValue("contracts-management:contactForm.email"),
            }),
          (val) => (val ? VALIDATION_DEPS.validEmail(val) : true),
        ),
    })
    .optional(),
  addressData: yup.object({
    addressLineOne: yup.string().max(255).trim().nullable().optional(),
    addressLineTwo: yup.string().max(150).trim().nullable().optional(),
    street: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:contactForm.street"),
        }),
      ),
    houseNumber: yup
      .string()
      .max(50)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:contactForm.houseNumber"),
        }),
      ),
    postalCode: yup
      .string()
      .max(50)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:contactForm.postalCode"),
        }),
      ),
    city: yup
      .string()
      .max(150)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:contactForm.city"),
        }),
      ),
  }),
  externalReferenceNumber: yup
    .string()
    .max(250)
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("contracts-management:refNumb"),
      }),
    ),
})

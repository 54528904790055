import { Box, Stack, Typography } from "@mui/material"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

import { RecurringBillingPositionsHeader } from "./RecurringBillingPositionsHeader"
import { IServiceBillings } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"
import { DataGridTable } from "../../../../../../uikit/dataGridTable"
import { ServiceBillingPosition, ServiceBillingState } from "../../../../../../data/generated-sources/openapi"
import { ConfirmDialog } from "../../../../../../uikit/confirmDialog/ConfirmDialog"
import { RecurringBillingPositionsTableColumns } from "./RecurringBillingPositionsTableColumns"
import { DeactivateIcon, RefreshIcon } from "../../../../../../uikit/Shared.Icon"
import { adminChangeServiceBillingState } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Repository"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"
import { recalculateAdminServiceBillingById } from "../../../../../../domain/portal/admin/billings/initial/BillingsInitial.Repository"

export const RecurringBillingPositionsTable = (props: IServiceBillings & { onSuccessAction: () => void }) => {
  const { formatedTotalAmountDue, positions, id: billingId, activeState, onSuccessAction } = props
  const { t } = useTranslation("billings-recurring")

  const queryClient = useQueryClient()
  const invalidateVewaQuery = () => queryClient.invalidateQueries({ queryKey: ["getRecurringBilling"] })

  const {
    mutate: changeBillingState,
    isLoading: isChangingState,
    isError: isChangeStateError,
    error: changeStateError,
    isSuccess: isChangeStateSuccess,
  } = useMutation(["changeBillingState"], () => adminChangeServiceBillingState(billingId, "cancel"), {
    onSuccess: () => onSuccessAction(),
  })

  const {
    mutate: recalculateBilling,
    isLoading: isRecalculating,
    isError: isRecalculateError,
    error: recalculateError,
    isSuccess: isRecalcSuccess,
  } = useMutation(["recalculateBilling"], () => recalculateAdminServiceBillingById(billingId), {
    onSuccess: () => onSuccessAction(),
  })

  const errorMsg = (changeStateError || recalculateError) as AxiosErrorDataType

  const Footer = () => {
    return (
      <Box mr={4}>
        <Typography fontWeight={500} textAlign={"right"}>
          {t("details.label.total")}
        </Typography>
        <Typography fontSize={24} textAlign={"right"}>
          {formatedTotalAmountDue}
        </Typography>
      </Box>
    )
  }
  const successMsg = isChangeStateSuccess ? t("details.action.cancel.success") : t("details.action.recalculate.success")

  return (
    <Box p={2} data-testid="recurringBillingPositionsTableWrapper">
      <OptionalSuccessAlert message={successMsg} show={isChangeStateSuccess || isRecalcSuccess} scrollOnDisplay />
      <ErrorAlert
        scrollOnDisplay
        visible={isChangeStateError || isRecalculateError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />

      <RecurringBillingPositionsHeader {...props} onInvalidateQuery={() => invalidateVewaQuery()} />

      <DataGridTable<ServiceBillingPosition>
        admin
        rows={positions || []}
        columns={RecurringBillingPositionsTableColumns}
        getRowId={({ name }) => name}
        slots={{ footer: Footer }}
        sx={{ ".MuiDataGrid-row": { cursor: "default" } }}
        data-testid="recurringBillingPositionsTable"
      />
      <Stack direction="row">
        <ConfirmDialog
          onConfirm={() => changeBillingState()}
          actionButtonStartIcon={<DeactivateIcon fontSize="large" />}
          actionButtonText={t("details.button.cancel")}
          confirmButtonText={t("details.button.cancel")}
          actionButtonLoading={isChangingState}
          actionButtonDisabled={activeState !== ServiceBillingState.APPROVED}
        />
        <ConfirmDialog
          onConfirm={() => recalculateBilling()}
          actionButtonStartIcon={<RefreshIcon fontSize="large" />}
          actionButtonText={t("details.button.recalculate")}
          confirmButtonText={t("details.button.recalculate")}
          actionButtonLoading={isRecalculating}
          actionButtonDisabled={activeState !== ServiceBillingState.DRAFT}
        />
      </Stack>
    </Box>
  )
}

import { styled } from "@mui/material/styles"

import MuiAccordion, { AccordionProps } from "@mui/material/Accordion"
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary"
import MuiAccordionDetails from "@mui/material/AccordionDetails"
import { ArrowForwardIcon } from "../Shared.Icon"

export const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  borderTop: `1px solid ${theme.palette.divider}`,
  paddingTop: 16,
  paddingBottom: 16,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:is(:first-of-type)": {
    borderTop: 0,
  },
  "&:before": {
    display: "none",
  },
}))

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary {...props} expandIcon={<ArrowForwardIcon sx={{ fontSize: "0.9rem" }} />} />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, 1)" : "rgba(0, 0, 0, 0)",
  flexDirection: "row-reverse",
  paddingLeft: theme.spacing(0),
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}))

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}))

import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"
import { ContractBillingFrequencyValues } from "../../../../../domain/portal/admin/contracts/Contracts.Models"
import { VALIDATION_DEPS } from "../../../../Shared.Validation"

export const billingSchema = yup.object().shape({
  nextBillingDate: yup.date().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("contracts-management:billingForm.nextBillingDate"),
    }),
  ),
  billingFrequency: yup.mixed().oneOf(ContractBillingFrequencyValues).required(),
  nextBillingFrequency: yup.mixed().oneOf(ContractBillingFrequencyValues).optional(),
  mainContactPerson: yup.object({
    name: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:billingForm.name"),
        }),
      ),
    contact: yup.object({
      mobile: yup
        .string()
        .test(
          "mobile",
          () =>
            getTranslateValue("shared:validation.valid.mobileNumber", {
              field: getTranslateValue("contracts-management:billingForm.mobile"),
            }),
          (value) => (value ? VALIDATION_DEPS.validSwissMobile(value) : true),
        )
        .optional(),
      telephone: yup
        .string()
        .test(
          "telephone",
          () =>
            getTranslateValue("shared:validation.valid.phoneNumber", {
              field: getTranslateValue("contracts-management:billingForm.phone"),
            }),
          (value) => (value ? VALIDATION_DEPS.validSwissPhoneNumber(value) : true),
        )
        .optional(),
      email: yup
        .string()
        .test(
          "email",
          () =>
            getTranslateValue("shared:validation.valid.email", {
              field: getTranslateValue("contracts-management:billingForm.email"),
            }),
          (value) => (value ? VALIDATION_DEPS.validEmail(value) : true),
        )
        .optional(),
    }),
  }),
  paymentInformation: yup.object({
    payee: yup
      .string()
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:billingForm.payee"),
        }),
      ),
    iban: yup
      .string()
      .test(
        "iban",
        () =>
          getTranslateValue("shared:validation.valid.iban", {
            field: getTranslateValue("contracts-management:billingForm.iban"),
          }),
        (val) => VALIDATION_DEPS.validIban(val || ""),
      )
      .required(),
    vatNumber: yup.string().optional(),
  }),
})

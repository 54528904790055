import { Map } from "immutable"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { Body1 } from "../../../../../uikit/typography/Typography"
import { UpdateEmailUpsert } from "../../../../../domain/portal/manager/profile/Profile.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { validateProfileEmailForm } from "./ProfileEmailForm.validation"
import { PrimaryButton } from "../../../../../uikit/button"
import { bgColorGreen } from "../../../../Shared.Theme"

interface ProfileEmailFormProps {
  editMode: boolean
  isLoading: boolean
  profileEmailValues: UpdateEmailUpsert
  dataChanged: (profileEmailValues: UpdateEmailUpsert) => void
}

export const ProfileEmailForm = ({ editMode, dataChanged, profileEmailValues, isLoading }: ProfileEmailFormProps) => {
  const { t } = useTranslation("profile")
  const [touched, setTouched] = useState<Map<string, boolean>>(Map())
  const [editBackgroundColor, setEditBackgroundColor] = useState("#ffffff")

  useEffect(() => {
    setEditBackgroundColor(editMode ? bgColorGreen : "#ffffff")
  }, [setEditBackgroundColor, editMode])

  return (
    <>
      <div
        style={{
          padding: "16px",
          marginBottom: "32px",
          backgroundColor: editBackgroundColor,
          borderBottomLeftRadius: "8px",
          borderBottomRightRadius: "8px",
        }}
      >
        <FormView>
          <>
            <FormRowColumn>
              {editMode ? (
                <SingleLineTextField
                  name="email"
                  label={t("contact.email")}
                  value={profileEmailValues.contact.email}
                  onChange={(event) => {
                    dataChanged({ contact: { ...profileEmailValues.contact, email: event.target.value } })
                  }}
                  onBlur={() => setTouched(touched.set("email", true))}
                  helperText={validateProfileEmailForm.email(
                    `${profileEmailValues.contact.email}`,
                    touched.has("email"),
                    t,
                  )}
                />
              ) : (
                <Body1>{`${profileEmailValues.contact.email ?? "-"}`}</Body1>
              )}
            </FormRowColumn>
          </>
        </FormView>
        {editMode && (
          <PrimaryButton type="submit" sx={{ mt: 2 }} label={t("shared:form.action.save")} isLoading={isLoading} />
        )}
      </div>
    </>
  )
}

import { useEffect } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CircularProgress, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import { PrimaryOutlinedButton } from "../../../../uikit/button"
import { VewaBillingDoneBills } from "../../shared/vewaBillings/VewaBillingDoneBills"
import {
  adminGetVewaParticipantBillingBills,
  adminVewaParticipantBillsDownloadCsv,
  adminDownloadAllVewaBillPdf,
} from "../../../../domain/portal/admin/billings/participant/Bill.Repository"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const VewaParticipantsDoneBillsPage = ({ match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId } = params
  const { t } = useTranslation("settlements")

  const {
    data: bill,
    remove: removeBill,
    isFetching,
    isError: isBillError,
    error: billError,
  } = useQuery(["getVewaBill"], () => adminGetVewaParticipantBillingBills(billingId), {
    enabled: !!billingId,
  })

  const {
    mutate: downloadCSV,
    isLoading: isDownloadingCSV,
    isError: isDownloadCsvError,
    error: csvError,
  } = useMutation(["adminDownloadVewaCsv"], () => adminVewaParticipantBillsDownloadCsv(billingId))

  const {
    mutate: downloadAllVewaPdfs,
    isLoading: isDownloadingPdfs,
    isError: isPdfError,
    error: pdfError,
  } = useMutation(["downloadAllVewaPdfs"], () => adminDownloadAllVewaBillPdf(billingId))

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  const errorMsg = (billError || csvError || pdfError) as AxiosErrorDataType

  return (
    <Paper>
      <ErrorAlert
        scrollOnDisplay
        visible={isBillError || isDownloadCsvError || isPdfError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      {isFetching || !bill ? (
        <CircularProgress />
      ) : (
        <>
          <VewaBillingDoneBills {...bill} />
          <Stack py={6} flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
            <PrimaryOutlinedButton
              onClick={() => downloadCSV()}
              isLoading={isDownloadingCSV}
              label={t("bills.downloadCSV")}
              sx={{ mr: 2 }}
            />
            <PrimaryButton
              isLoading={isDownloadingPdfs}
              label={t("bills.downloadAllBills")}
              onClick={() => downloadAllVewaPdfs()}
            />
          </Stack>
        </>
      )}
    </Paper>
  )
}

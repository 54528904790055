import { UtilityUnitResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { UtilityUnitsColumns } from "./UtilityUnits.Enum"

export const utilityUnitsColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case UtilityUnitsColumns.STATUS:
      return (a: UtilityUnitResponse, b: UtilityUnitResponse) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case UtilityUnitsColumns.TYPE:
      return (a: UtilityUnitResponse, b: UtilityUnitResponse) =>
        ORDERED_STRING_COMPARATOR(a.usageType, b.usageType, orderBy.direction)
    case UtilityUnitsColumns.BUILDING:
      return (a: UtilityUnitResponse, b: UtilityUnitResponse) =>
        ORDERED_STRING_COMPARATOR(a.buildingId, b.buildingId, orderBy.direction)
    default:
      return (a: UtilityUnitResponse, b: UtilityUnitResponse) =>
        ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction)
  }
}

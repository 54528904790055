import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"

export const priceSchema = yup.array().of(
  yup.object().shape({
    name: yup
      .string()
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("contracts-management:priceForm.name"),
        }),
      ),
    prices: yup.object().shape({
      solarPrice: yup.number().min(0).required(),
      highTariffPrice: yup.number().min(0).required(),
      lowTariffPrice: yup.number().min(0).required(),
      additionalServicesPrice: yup.number().min(0).required(),
      isFixedRate: yup.boolean().required(),
      spikePrice: yup.number().min(0).nullable().optional(),
    }),
  }),
)

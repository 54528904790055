import { I18nNamespace } from "../../../App.i18n"

export const SettlementsI18n: I18nNamespace = {
  name: "settlements",
  de: {
    "chart.solarProduction": "Solar-Produktion",
    "chart.ownConsumption": "Eigenverbrauch",
    "chart.powerSupplied": "Einspeisung",
    "chart.powerPurchased": "Netzbezug",
    "section.title.doneBills": "Abgeschlossene Abrechnungen",
    "section-title": "Abrechnungs-Manager",
    "section-subtitle": "Übersicht {{lastDataPointDate}}",
    "section-overview.detail.analysis": "Detailliert Analyse",
    "section-overview.most.recent.bills": "Ihre letzten Abrechnungen",
    "section-tab.periodic.settlements": "Periodische Abrechnungen",
    "section-tab.tenants.change": "Mieterwechsel",
    "section-view.all.bills": "Alle ansehen",
    "section-list-header.bill.period": "Zeitraum",
    "section-list-header.bill.total": "Summe",
    "section-list-header.bill.status": "Status",
    "section-list-header.bill.contractType": "Vertragsart",
    "section-list-header.bill.consumption": "Verbrauchsstelle",
    "section-list-header.bill.building": "Anschlussobjekt",
    "section-list-header.bill.subscriber": "Teilnehmer",
    "section-list-header.bill.fromTo": "von - bis",
    "aggregateConsumption.title": "Übersicht Verbrauch pro Nutzeinheit ",

    "bills.invoiceDate": "Rechnungsdatum",
    "bills.createdInfo":
      "Wärme- und Wasserkostenabrechnungen sind erstellt und bereit für den Versand an Ihre Teilnehmer",
    "bills.downloadAllBills": "Alle Rechnungen herunterladen",
    "bills.downloadCSV": "Als CSV herunterladen",

    "list-billing-status.WAITING_FOR_DATA": "Warte auf Daten",
    "list-billing-status.DATA_AVAILABLE": "Daten liegen vor",
    "list-billing-status.SUSPENDED": "Suspendiert",
    "list-billing-status.IN_PROGRESS": "In Bearbeitung",
    "list-billing-status.IN_PROGRESS_REOPENED": "In Bearbeitung (neu eröffnet)",
    "list-billing-status.DONE": "Abgeschlossen",
    "list-billing-status.PAID": "Bezahlt",
    "list.noParticipation.name": "Leerstand",
    "list-billing-makeBill": "Rechnung erstellen",
    "list-billing-status.WAITING_FOR_DATA_ETA": "Voraussichtliche Ankunft der Daten: \nca. {{ estDate }}",
    "list-billing-status.WAITING_FOR_DATA_ETA_PAST": "CKW Support wurde informiert.",
    "list-billing-ckw-support-informed": "CKW Support wurde informiert",
    "billingEdit.title.usage": "Gesamtverbrauch der Einheit",
    "billingEditDone.title.usage": "Übersicht Verbrauch",

    "billingEdit.subtitle.consumption": "Übersicht Verbrauch",
    "billingEdit.subtitle.change.participants": "Sind alle Teilnehmer korrekt zugeordnet?",
    "billingEdit.subtitle.change.prices": "Sind die Strompreise so korrekt?",

    "billingEdit.confirm.and.send.to.ckw": "Bestätigen und an CKW senden",
    "billingEdit.create.billing": "Rechnung erstellen",
    "billingEdit.change.pricing": "Preise aktualisieren",

    "billingEdit.confirmation.dialog.title": "Sind Sie sicher?",
    "billingEdit.confirmation.dialog.body":
      "Sie werden nicht mehr zu diesem Screen zurückkehren können. CKW wird für Sie basierend auf Ihren Angaben die Rechnungen für Ihre ZEV Teilnehmer erstellen.",
    "billingEdit.confirmation.dialog.action.cancel": "Abbrechen",
    "billingEdit.confirmation.dialog.action.confirm": "Ja, fortfahren",

    "billingEdit.more.consumptionInfo": "Detailiertere Informationen finden Sie im Bereich ",
    "billingEdit.more.pricingInfo": "Neue Preise mit dem <b>Strompreiskalkulator</b> berechnen",
    "billingEdit.all.participants.containsErrorMessages":
      "Es gibt zurzeit Unklarheiten mit den Teilnehmern. Bitte beheben Sie die weiter oben aufgelisteten Fehler",
    "billingEdit.timerange": "Zeitraum",
    "billingEdit.participant.consumptionPoint": "Verbrauchsstelle",
    "billingEdit.participant.participantName": "Teilnehmer",
    "billingEdit.participant.building": "Anschlussobjekt",
    "billingEdit.participant.price": "Preise",
    "billingEdit.participant.edit": "Bearbeiten",
    "billingEdit.participant.assign": "zuordnen",
    "detail.finalise.title": "Abrechnung",
    "detail.finalise.period": "ZEITRAUM",
    "detail.finalise.invoiceDate": "RECHNUNGSDATUM",
    "detail.finalise.total": "SUMME",
    "detail.finalise.status": "STATUS",
    "detail.finalise.invoiceReady.title": "Rechnungen sind erstellt und bereit für den Versand an Ihre ZEV-Teilnehmer",
    "detail.finalise.invoiceReady.body":
      "Sie haben die Möglichkeit den Status der einzelnen Rechnungen manuell auf bezahlt zu " +
      "setzen. Wenn alle Rechnungen auf bezahlt gesetzt sind, wird der Status der Abrechnung " +
      "automatisch auf «bezahlt» gesetzt.",
    "detail.finalise.downloadAll": "Alle herunterladen",
    "detail.finalise.downloadCsv": "CSV herunterladen",
    "detail.finalise.createCollection.message.title": "Daten an CKW übermittelt",
    "detail.finalise.createCollection.message.body":
      "Die Daten wurden an CKW übermittelt und CKW wird für Ihre Teilnehmer Rechnungen erstellen und diese versenden. Falls Sie einen Fehler bemerkt haben, bitte melden Sie sich beim Support.",
    "total.kwh": "Summe kWh",
    "total.m3": "Summe m³",
    "vewaPeriodic.dialog.title": "Hinweis: Abrechnung wird gelöscht",
    "vewaPeriodic.dialog.content":
      "Um den Abrechnungszeitraum anzupassen, muss die aktuelle Abrechnung gelöscht und neu erstellt werden. ",
    "vewaPeriodic.dialog.deleteBtn": "Löschen und anpassen",
  },
  en: {
    "chart.solarProduction": "Solar production",
    "chart.ownConsumption": "Own consumption",
    "chart.powerSupplied": "Supplied",
    "chart.powerPurchased": "Grid power",
    "section.title.doneBills": "Done Bills",
    "section-title": "Billing Manager",
    "section-subtitle": "Overview",
    "section-overview.detail.analysis": "Detailed Analysis",
    "section-overview.most.recent.bills": "your most recent bills",
    "section-tab.periodic.settlements": "Periodic settlements",
    "section-tab.tenants.change": "Change of tenants",
    "section-view.all.bills": "View all",
    "section-list-header.bill.period": "Period",
    "section-list-header.bill.total": "Total",
    "section-list-header.bill.status": "Status",
    "section-list-header.bill.contractType": "Contract Type",
    "section-list-header.bill.consumption": "Consumption point",
    "section-list-header.bill.building": "Building name",
    "section-list-header.bill.subscriber": "subscriber",
    "section-list-header.bill.fromTo": "from - to",
    "aggregateConsumption.title": "Overview of consumption per usage unit",

    "bills.createdInfo": "Heat and water cost bills are created and ready to be sent to your participants",
    "bills.invoiceDate": "Date of invoice",
    "bills.downloadAllBills": "Download all invoices",
    "bills.downloadCSV": "Download as CSV",

    "list-billing-status.WAITING_FOR_DATA": "Waiting for data",
    "list-billing-status.DATA_AVAILABLE": "Data available",
    "list-billing-status.SUSPENDED": "Suspended",

    "list-billing-status.IN_PROGRESS": "In progress",
    "list-billing-status.IN_PROGRESS_REOPENED": "In progress reopened",
    "list-billing-status.DONE": "Done",
    "list-billing-status.PAID": "Paid",
    "list-billing-makeBill": "Make a bill",
    "list.noParticipation.name": "Empty",
    "list-billing-status.WAITING_FOR_DATA_ETA": "Estimated arrival of the data: \napprox. {{ estDate }}",
    "list-billing-status.WAITING_FOR_DATA_ETA_PAST": "CKW support has been informed.",

    "list-billing-ckw-support-informed": "CKW Support has been informed",
    "billingEdit.title.usage": "Total consumption",
    "billingEditDone.title.usage": "Overview consumption",

    "billingEdit.subtitle.consumption": "Overview consumption",
    "billingEdit.subtitle.change.participants": "Are the participants correct?",
    "billingEdit.subtitle.change.prices": "Do you wish to change the pricing?",

    "billingEdit.confirm.and.send.to.ckw": "Confirm and send to CKW",
    "billingEdit.create.billing": "Create bill",
    "billingEdit.change.pricing": "Change prices",
    "billingEdit.change.pricing.general": "Adjust prices",

    "billingEdit.confirmation.dialog.title": "Are you sure?",
    "billingEdit.confirmation.dialog.body":
      "You will not be able to return to this screen. CKW will create the invoices for your ZEV participants based on your information.",
    "billingEdit.confirmation.dialog.action.cancel": "Cancel",
    "billingEdit.confirmation.dialog.action.confirm": "Yes, continue",

    "billingEdit.more.consumptionInfo": "Detailed information can be found within ",
    "billingEdit.more.pricingInfo": "Further information about the calculations",
    "billingEdit.all.participants.containsErrorMessages":
      "There are currently ambiguities with the participants. Please correct the errors listed above",
    "billingEdit.timerange": "Timerange",
    "billingEdit.participant.consumptionPoint": "Consumption point",
    "billingEdit.participant.participantName": "Participant",
    "billingEdit.participant.building": "Building",
    "billingEdit.participant.price": "Price Package",
    "billingEdit.participant.edit": "Edit",
    "billingEdit.participant.assign": "assign",
    "detail.finalise.title": "Bill",
    "detail.finalise.period": "Period",
    "detail.finalise.invoiceDate": "Invoid date",
    "detail.finalise.total": "Total",
    "detail.finalise.status": "Status",
    "detail.finalise.invoiceReady.title": "Invoices are created and ready to be sent to your ZEV participants",
    "detail.finalise.invoiceReady.body":
      "You have the option of manually setting the status of the individual " +
      "invoices to paid. If all invoices are set to paid, the status of the " +
      "statement is automatically set to 'paid'.",
    "detail.finalise.downloadAll": "Download all",
    "detail.finalise.downloadCsv": "Download CSV",
    "detail.finalise.createCollection.message.title": "Daten an CKW übermittelt",
    "detail.finalise.createCollection.message.body":
      "Die Daten wurden an CKW übermittelt und CKW wird für Ihre Teilnehmer Rechnungen erstellen und diese versenden. Falls Sie einen Fehler bemerkt haben, bitte melden Sie sich beim Support.",
    "total.kwh": "Total kWh",
    "total.me": "Total m³",
    "vewaPeriodic.dialog.title": "Note: Billing will be deleted",
    "vewaPeriodic.dialog.content": "To adjust the billing period, the current billing must be deleted and recreated.",
    "vewaPeriodic.dialog.deleteBtn": "Delete and adjust",
  },
}

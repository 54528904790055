import { TableCell } from "@mui/material"
import { useHistory, useParams } from "react-router-dom"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"
import { GreenCirle, RedCirle } from "../../participant/fragments/SyncIndicators"

const ContractParticipantsTable = ({ itemConfig }: { itemConfig: ParticipantResponse }) => {
  const history = useHistory()
  const { id: contractId } = useParams<{ id: string }>()

  return (
    <TableRowClickable<ParticipantResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={({ id }) => history.push(`/management-contracts/${contractId}/participants/${id}`)}
    >
      <TableCell align="left">{itemConfig.syncStatus ? <GreenCirle /> : <RedCirle />}</TableCell>
      <TableCell align="left">{itemConfig.personalData?.firstName}</TableCell>
      <TableCell align="left">{itemConfig.personalData?.lastName}</TableCell>
      <TableCell align="left">{itemConfig?.sapGp}</TableCell>
      <TableCell align="left">{itemConfig.contactDetails?.email || "-"}</TableCell>
    </TableRowClickable>
  )
}

export default ContractParticipantsTable

import { forkJoin, from, map, mergeMap, Observable, of } from "rxjs"

import { DomainDependencies } from "../../../Domain.Dependencies"
import { DomainResponse } from "../../../Domain.Response"
import { apiCall, apiHeaders } from "../../../Domain.Calls"
import { MyZevSummary, ZevPriceCalculatorTariffs, EnrichedConsumptionPoint, MyZevPriceUpsert } from "./MyZev.Model"
import {
  myZevSummaryMapper,
  zevPriceCalculatorInputMapper,
  zevPriceCalculatorTariffsMapper,
  zevPricePackageMapper,
} from "./MyZev.Mapper"
import {
  BuildingCustomerResponse,
  ZevPricePackage,
  ZevPriceCalculatorInput,
  ContractCustomerResponse,
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorPricesResponse,
} from "../../../../data/generated-sources/openapi"

export const getMyZevDetails = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<MyZevSummary>> => {
  return apiCall(
    getCustomerZevById(contractId, deps).pipe(
      mergeMap((contractCustomerResponse) =>
        getCustomerBuildingById(contractCustomerResponse.result?.buildings, deps).pipe(
          mergeMap((buildingCustomerResponse) =>
            getContractPricePackages(contractId, deps).pipe(
              map((zevPricePackage) =>
                myZevSummaryMapper(buildingCustomerResponse, contractCustomerResponse?.result, zevPricePackage?.result),
              ),
            ),
          ),
        ),
      ),
    ),
  )
}

export const getContractPricePackages = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPricePackage[]>> => {
  return apiCall(
    from(deps.customerContractApi.customerGetPricePackagesByContractId(contractId, apiHeaders(deps))).pipe(
      map((response) => response.data),
    ),
  )
}

export const getZevPricePackages = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<MyZevPriceUpsert[]>> => {
  return apiCall(
    from(deps.customerContractApi.customerGetPricePackagesByContractId(contractId, apiHeaders(deps))).pipe(
      map((response) => zevPricePackageMapper(contractId, response.data)),
    ),
  )
}

const getCustomerZevById = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<ContractCustomerResponse>> => {
  return apiCall(
    from(deps.customerContractApi.customerGetContractById(contractId, apiHeaders(deps))).pipe(
      map((response) => response.data),
    ),
  )
}

export const getZevPriceCalculatorEnergyById = (
  contractId: string,
  year: number,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPriceCalculatorEnergy>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerGetContractPriceCalculatorEnergyById(contractId, year, apiHeaders(deps)),
    ).pipe(map((zevPriceCalculatorEnergyResponse) => zevPriceCalculatorEnergyResponse.data)),
  )
}

export const getZevPriceCalculatorInputById = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPriceCalculatorInput | null>> => {
  return apiCall(
    from(deps.customerContractApi.customerGetContractPriceCalculatorInputById(contractId, apiHeaders(deps))).pipe(
      map((zevPriceCalculatorEnergyResponse) => {
        return zevPriceCalculatorInputMapper(zevPriceCalculatorEnergyResponse.data.inputPrices)
      }),
    ),
  )
}

export const getZevPriceCalculatorTariffsById = (
  contractId: string,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPriceCalculatorTariffs[]>> => {
  return apiCall(
    from(deps.customerContractApi.customerGetContractPriceCalculatorTariffsById(contractId, apiHeaders(deps))).pipe(
      map((zevPriceCalculatorTariffsResponse) =>
        zevPriceCalculatorTariffsMapper(zevPriceCalculatorTariffsResponse.data.entries),
      ),
    ),
  )
}

export const getZevPriceCalculatorPricesById = (
  contractId: string,
  year: number,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPriceCalculatorPricesResponse>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerGetContractPriceCalculatorPricesById(contractId, year, apiHeaders(deps)),
    ).pipe(map((zevPriceCalculatorPricesResponse) => zevPriceCalculatorPricesResponse.data)),
  )
}

export const updateZevPriceCalculatorInputById = (
  contractId: string,
  inputPrices: ZevPriceCalculatorInput,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerUpdateContractPriceCalculatorInputById(
        contractId,
        {
          inputPrices,
        },
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

export const updateZevPricePackageById = (
  contractId: string,
  pricePackageId: number,
  pricePackage: ZevPricePackage,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerUpdateContractPricePackageById(
        contractId,
        pricePackageId,
        pricePackage,
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

export const getZevPriceCalculatorPricesSuppliedEnergyById = (
  contractId: string,
  year: number,
  zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy,
  deps: DomainDependencies,
): Observable<DomainResponse<ZevPriceCalculatorPricesResponse>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerGetContractPriceCalculatorPricesSuppliedEnergyById(
        contractId,
        year,
        zevPriceCalculatorEnergy,
        apiHeaders(deps),
      ),
    ).pipe(map((zevPriceCalculatorEnergyResponse) => zevPriceCalculatorEnergyResponse.data)),
  )
}

export const getConsumptionPoints = (
  utilityUnitIds: string[],
  buildingsMap: Map<string, BuildingCustomerResponse>,
  deps: DomainDependencies,
): Observable<DomainResponse<EnrichedConsumptionPoint[]>> => {
  if (utilityUnitIds.length === 0) return apiCall(of([]))
  return apiCall(
    forkJoin(
      utilityUnitIds.map((utilityUnitId) =>
        from(deps.customerUtilityUnitApi.customerGetUtilityUnitById(utilityUnitId, apiHeaders(deps))).pipe(
          map((utilityUnitResponse) => {
            return {
              ...utilityUnitResponse.data,
              buildingCustomer: buildingsMap.get(utilityUnitResponse.data.buildingId),
            }
          }),
        ),
      ),
    ),
  )
}

const getCustomerBuildingById = (
  buildingIds: string[] | undefined,
  deps: DomainDependencies,
): Observable<BuildingCustomerResponse[]> => {
  if (!buildingIds || buildingIds.length === 0) return of([])
  return forkJoin(
    buildingIds.map((id) =>
      from(deps.customerBuildingApi.customerGetBuildingById(id, apiHeaders(deps))).pipe(
        map((response) => response.data),
      ),
    ),
  )
}

export const createZevPricePackageById = (
  contractId: string,
  pricePackageId: number,
  zevPricePackage: ZevPricePackage,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(
      deps.customerContractApi.customerUpdateContractPricePackageById(
        contractId,
        pricePackageId,
        zevPricePackage,
        apiHeaders(deps),
      ),
    ).pipe(map(() => true)),
  )
}

import { Stack, Button, styled } from "@mui/material"
import { appThemePrimaryColor, newDesignSelectColor, newDesignSelectColorDark } from "../../app/Shared.Theme"

interface NavigationButtonProps {
  icon?: React.ReactNode
  label: string
  selected?: boolean
  onClick?: () => void
}

export const NavButton = styled(Button, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected?: boolean }>(({ theme, selected }) => ({
  fontSize: "16px",
  padding: theme.spacing(1.75, 1),
  margin: theme.spacing(0, 1),
  justifyContent: "start",
  borderRadius: "8px",
  ".sidebar-label": { color: newDesignSelectColorDark, fontSize: "16px", fontWeight: 400 },
  ".sidebar-svg-wrapper > svg": { marginRight: "10px", display: "flex" },
  ...(selected && {
    background: newDesignSelectColor,
    ".sidebar-svg-wrapper > svg path": { fill: appThemePrimaryColor },
    ".sidebar-label": { color: theme.palette.primary.main, fontWeight: 600 },
  }),
  ":hover": { color: theme.palette.primary.main, background: newDesignSelectColor },
}))

export const NavigationButton = ({ icon, label, ...rest }: NavigationButtonProps) => {
  return (
    <NavButton disableRipple variant="text" {...rest}>
      <Stack direction={"row"} alignItems={"center"} spacing={1}>
        <span className={"sidebar-svg-wrapper"}>{icon}</span>
        <span className={"sidebar-label"}>{label}</span>
      </Stack>
    </NavButton>
  )
}

import { RouteComponentProps, withRouter } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { createArea, getAreaById, updateAreaById } from "../../../../domain/portal/admin/areas/Areas.Repository"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useEffect, useState } from "react"
import { FullScreenLoader } from "../../../../uikit/indicator/ProgressIndicator"
import { Form, Formik } from "formik"
import * as Yup from "yup"
import { AreaStateInterface } from "./Area.Interface"
import { useTranslation } from "react-i18next"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { DividerBox } from "../../../../uikit/box/DividerBox"
import AreaCreateUpdateForm from "./components/AreaCreateUpdate.Form"
import { AxiosErrorDataType, getParamFromResponseUrl, getTranslateValue, isInactiveStatus } from "../../../Shared.Utils"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AreaAdminResponse } from "../../../../data/generated-sources/openapi"

const CreateUpdateAreaSchema = Yup.object().shape({
  name: Yup.string()
    .max(255)
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("shared:label.name"),
      }),
    ),
})

const areaInitialState: AreaStateInterface = { name: "" }

const AreaCreateUpdateComponent = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const {
    match: {
      params: { id },
    },
    history,
  } = props
  const { t } = useTranslation("area")
  const [areaError, setAreaError] = useState<AxiosErrorDataType>()

  const apiAreaGetResponse = useQuery(["getArea"], () => getAreaById(id), {
    enabled: !!id,
    onError: setAreaError,
  })
  const { mutate: updateAreaMutate, isLoading: isLoadingUpdateArea } = useMutation(
    ["updateArea"],
    (area: AreaStateInterface) => updateAreaById(id, area),
    {
      onSuccess: () => history.push(`/area/${id}`),
      onError: setAreaError,
    },
  )

  const { mutate: createAreaMutate, isLoading: isLoadingCreateArea } = useMutation(
    ["createArea"],
    (areaObj: AreaStateInterface) => createArea(areaObj),
    {
      onSuccess: (data) => history.push(`/area/${getParamFromResponseUrl(data?.data, "areas/")}`),
      onError: setAreaError,
    },
  )

  useEffect(() => () => apiAreaGetResponse.remove(), [])

  const loaderLayout = () => {
    if (isLoadingCreateArea || isLoadingUpdateArea) return <FullScreenLoader />
  }

  if (apiAreaGetResponse.isLoading && apiAreaGetResponse.isFetching) return <FullScreenLoader />

  return (
    <>
      {loaderLayout()}
      <Formik<AreaAdminResponse>
        initialValues={apiAreaGetResponse.data ?? areaInitialState}
        validationSchema={CreateUpdateAreaSchema}
        onSubmit={(values) => {
          const payload: AreaStateInterface = {
            name: values.name,
          }
          if (id) return updateAreaMutate(payload)
          createAreaMutate(payload)
        }}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <ErrorAlert
              visible={!!areaError}
              message={t(`error-codes:${areaError?.response?.data?.code || areaError?.code || "OTHER"}`)}
            />
            <AreaCreateUpdateForm
              handleBlur={handleBlur}
              handleChange={handleChange}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setFieldValue}
            />
            <DividerBox />
            <FormUpdateActionsView
              buttonCtaLabel={t("label.saveArea")}
              isValid={isValid && !isInactiveStatus(apiAreaGetResponse.data?.activeState)}
              dirty={dirty}
              isLoading={id ? isLoadingUpdateArea : isLoadingCreateArea}
              navigateBack={() => history.goBack()}
            />
          </Form>
        )}
      </Formik>
    </>
  )
})

export default AreaCreateUpdateComponent

import { Box, Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { useAppSelector } from "../../../hooks"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { filterArrayBySearchInput } from "../../../Shared.Utils"
import { DataGridTable } from "../../../../uikit/dataGridTable"
import { selectZevDonePeriodicBillings, selectVewaDonePeriodicBillings } from "../store/billingsSlice"
import {
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { ProductType } from "../../../../data/generated-sources/openapi"
import { periodicBillingsColumns } from "./fragments/PeriodicBillningColumns"

interface PeriodicBillingsDoneStatusProps {
  areaId: string
}

export const PeriodicBillingsDoneStatus = ({ areaId }: PeriodicBillingsDoneStatusProps) => {
  const history = useHistory()
  const { t } = useTranslation("settlements")
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const zevDoneBills = useAppSelector(selectZevDonePeriodicBillings)
  const vewaDoneBills = useAppSelector(selectVewaDonePeriodicBillings)

  const [filterQuery, setFilterQuery] = useState<string>("")
  const [billingsList, setBillingsList] = useState<ZevPeriodicBillingItem[] | VewaPeriodicBillingItem[]>()
  const isVewaProduct = contractProductType === ProductType.VEWA

  useEffect(() => {
    if (isVewaProduct) {
      return setBillingsList(vewaDoneBills)
    }
    setBillingsList(zevDoneBills)
  }, [zevDoneBills, vewaDoneBills])

  const filterZevData = (searchInput: string) => {
    if (zevDoneBills) {
      const filtered = filterArrayBySearchInput<ZevPeriodicBillingItem>(zevDoneBills, searchInput)
      setBillingsList(filtered)
    }
  }

  const filterVewaData = (searchInput: string) => {
    if (vewaDoneBills) {
      const filtered = filterArrayBySearchInput<VewaPeriodicBillingItem>(vewaDoneBills, searchInput)
      setBillingsList(filtered)
    }
  }

  const handleFilterInput = (searchInput: string) => {
    isVewaProduct ? filterVewaData(searchInput) : filterZevData(searchInput)
  }

  return (
    <Box p={0} pt={6}>
      <Typography variant="h5" fontWeight={400} mb={4}>
        {t("section.title.doneBills")}
      </Typography>
      <SingleLineTextField
        id="filter"
        name="filter"
        type="text"
        sx={{ width: "24rem", mb: 4 }}
        label={t("shared:form.search.label")}
        value={filterQuery}
        onChange={(event) => {
          setFilterQuery(event.target.value)
          handleFilterInput(event.target.value)
        }}
      />
      <DataGridTable<ZevPeriodicBillingItem | VewaPeriodicBillingItem>
        rows={billingsList || []}
        columns={periodicBillingsColumns()}
        redirectParamName={"id"}
        redirectDescription={t("")}
        getRowClassName={({ row }) => (row?.makeBill ? "RowClickable" : "RowNotClickable")}
        onRowClick={({ row }) => {
          if (isVewaProduct) {
            return history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${row.id}/bills`)
          }
          if (!row?.makeBill) return
          history.push(`/${areaId}/contracts/${contractId}/billings/all/${row.id}/finalize`)
        }}
        defaultPageSize={25}
      />
    </Box>
  )
}

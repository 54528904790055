import { createEpic } from "../../../Shared.Epic"
import { DOMAIN_DEPENDENCIES } from "../../../App.Config"
import {
  getCustomerProfile,
  updateCustomerProfilePersonal,
  updateCustomerProfileAddress,
  updateCustomerProfileContact,
  updateCustomerProfileEmail,
} from "../../../../domain/portal/manager/profile/Profile.Repository"
import { CustomerProfile } from "../../../../domain/portal/manager/profile/Profile.Model"

export enum ProfileActionType {
  CUSTOM_PROFILE_UPDATE_ADDRESS = "CUSTOM_PROFILE_UPDATE_ADDRESS",
  CUSTOM_PROFILE_UPDATE_CONTACT = "CUSTOM_PROFILE_UPDATE_CONTACT",
  CUSTOM_PROFILE_UPDATE_PERSONAL = "CUSTOM_PROFILE_UPDATE_PERSONAL",
  CUSTOM_PROFILE_UPDATE_EMAIL = "CUSTOM_PROFILE_UPDATE_EMAIL",
  CUSTOM_PROFILE_UPDATE_PASSWORD = "CUSTOM_PROFILE_UPDATE_PASSWORD",
  CUSTOM_PROFILE_GET = "CUSTOM_PROFILE_GET",
}

export const profileEpic = [
  createEpic<CustomerProfile>(ProfileActionType.CUSTOM_PROFILE_GET, () => getCustomerProfile(DOMAIN_DEPENDENCIES)),

  createEpic<boolean>(ProfileActionType.CUSTOM_PROFILE_UPDATE_ADDRESS, (action) =>
    updateCustomerProfileAddress(action.address, DOMAIN_DEPENDENCIES),
  ),

  createEpic<boolean>(ProfileActionType.CUSTOM_PROFILE_UPDATE_CONTACT, (action) =>
    updateCustomerProfileContact(action.contact, DOMAIN_DEPENDENCIES),
  ),

  createEpic<boolean>(ProfileActionType.CUSTOM_PROFILE_UPDATE_PERSONAL, (action) =>
    updateCustomerProfilePersonal(action.personal, DOMAIN_DEPENDENCIES),
  ),

  createEpic<boolean>(ProfileActionType.CUSTOM_PROFILE_UPDATE_EMAIL, (action) =>
    updateCustomerProfileEmail(action.email, DOMAIN_DEPENDENCIES),
  ),
]

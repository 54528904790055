import {
  Medium,
  MediumZevAdminUpdateRequest,
  ZevMeter,
  ZevMeterType,
} from "../../../../../../../data/generated-sources/openapi"
import { Grid } from "@mui/material"
import { DotIcon } from "../../../../../../../uikit/icons/Icons"
import { MediumColorEnum, mediumZevMeterTypeValues, mediumZevTypeValues } from "../../Medium.Enums"
import { useTranslation } from "react-i18next"
import { FormikErrors } from "formik"
import { SingleLineTextField } from "../../../../../../../uikit/input/SingleLineTextField"
import { SelectPicker } from "../../../../../../../uikit/input/SelectPicker"
import { SingleLineDatePicker } from "../../../../../../../uikit/input/SingleLineDatePicker"
import DateUtils from "../../../../../../../services/utils/DateUtils"
import { RemoveIcon } from "../../../../../../../uikit/Shared.Icon"
import { CommonFormikProps } from "../../../../../../Shared.Utils"

const MediumZevCardDetailComponent = (props: CommonFormikProps<MediumZevAdminUpdateRequest>) => {
  const { setFieldValue, handleBlur, values, errors, touched } = props
  const { t } = useTranslation("medium")

  const removeMedium = (index: number) => {
    if (values?.meters?.length > 1) {
      const mediums: ZevMeter[] = values.meters.filter((_, i: number) => i !== index)
      setFieldValue("meters", mediums)
    }
  }

  const renderRemoveIcon = (meterType: ZevMeterType, index: number) => {
    if (
      (meterType === ZevMeterType.OUTGOING &&
        values.meters.filter((item: ZevMeter) => item.meterType === ZevMeterType.OUTGOING).length < 2) ||
      (meterType === ZevMeterType.INCOMING &&
        values.meters.filter((item: ZevMeter) => item.meterType === ZevMeterType.INCOMING).length < 2)
    ) {
      return null
    }
    return <RemoveIcon onClick={() => removeMedium(index)} sx={{ cursor: "pointer" }} />
  }

  return (
    <>
      {values?.meters?.map(({ meterType, meteringCode, dateFrom, dateTo }, index) => {
        const metersError = errors.meters as FormikErrors<ZevMeter[]>
        const touchedFileds = touched as FormikErrors<ZevMeter>
        return (
          <Grid
            item
            container
            key={`${meterType}-${index}`}
            xs={3.5}
            sx={{
              height: "400px",
              p: 2,
              mb: 2,
              mr: 2,
              border: "1px solid #EFEFEF",
              boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06)",
            }}
          >
            <Grid item xs={12} display="flex" justifyContent="space-between">
              <DotIcon color={MediumColorEnum[Medium.ELECTRICITY]} />
              {renderRemoveIcon(meterType, index)}
            </Grid>
            <Grid item xs={12}>
              <SelectPicker
                disabled
                name="type"
                type="text"
                id={`${meterType}-electricity-${index}`}
                key={`${meterType}-electricity-${index}`}
                label={t("detail.title")}
                value={Medium.ELECTRICITY}
                items={mediumZevTypeValues.map((key) => {
                  return {
                    label: t(`tab.label.${key}`),
                    value: key,
                  }
                })}
              />
            </Grid>
            <Grid item xs={12}>
              <SelectPicker
                required
                type="text"
                name="meterType"
                id={`${meterType}-meterType-${index}`}
                key={`${meterType}-meterType-${index}`}
                label={t("label.deliveryType")}
                value={meterType}
                items={mediumZevMeterTypeValues.map((key: string) => {
                  return {
                    label: t(`label.${key}`),
                    value: key,
                  }
                })}
                onChange={(e) => setFieldValue(`meters[${index}].meterType`, e.target.value)}
                onBlur={handleBlur}
                helperText={
                  touchedFileds?.meterType && metersError?.[index]?.meterType
                    ? metersError?.[index]?.meterType
                    : undefined
                }
              />
            </Grid>
            <Grid item xs={12}>
              <SingleLineTextField
                required
                name={"meteringCode"}
                id={`${meterType}-meteringCode-${index}`}
                key={`${meterType}-meteringCode-${index}`}
                type={"text"}
                helperText={
                  touchedFileds?.meteringCode && metersError?.[index]?.meteringCode
                    ? metersError?.[index]?.meteringCode
                    : undefined
                }
                value={meteringCode ?? ""}
                onChange={(e) => {
                  setFieldValue(`meters[${index}].meteringCode`, e.target.value)
                }}
                onBlur={handleBlur}
                aria-label={t("table.header.deliveryId")}
                label={t("table.header.deliveryId")}
              />
            </Grid>
            <Grid item xs={12} display={"flex"} justifyContent={"space-between"}>
              <Grid item xs={5.5}>
                <SingleLineDatePicker
                  required
                  name="dateFrom"
                  key={`${meterType}-dateFrom-${index}`}
                  label={t("table.header.startDate")}
                  value={DateUtils.getTimeStamp(dateFrom)}
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue(`meters[${index}].dateFrom`, value <= 0 ? 0 : value)}
                  helperText={
                    touchedFileds?.dateFrom && metersError?.[index]?.dateFrom
                      ? metersError?.[index]?.dateFrom
                      : undefined
                  }
                />
              </Grid>
              <Grid item xs={5.5}>
                <SingleLineDatePicker
                  name="dateTo"
                  key={`${meterType}-dateTo-${index}`}
                  label={t("table.header.endDate")}
                  value={dateTo ? DateUtils.getTimeStamp(dateTo) : 0}
                  onBlur={handleBlur}
                  onChange={(value) => setFieldValue(`meters[${index}].dateTo`, value <= 0 ? 0 : value)}
                  helperText={
                    touchedFileds?.dateTo && metersError?.[index]?.dateTo ? metersError?.[index]?.dateTo : undefined
                  }
                />
              </Grid>
            </Grid>
          </Grid>
        )
      })}
    </>
  )
}

export default MediumZevCardDetailComponent

/* eslint-disable max-len */
export const MeterReadingSolarPowerIcon = () => {
  return (
    <svg width="2em" height="2em" viewBox="0 0 34 36" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M29.81 29.95c.804 0 1.435-.643 1.438-1.403v-.018c.009-.113.043.149-2.417-14.349A1.43 1.43 0 0027.412 13H6.837c-.703 0-1.303.5-1.418 1.18C2.854 29.295 3.002 28.364 3 28.537a1.426 1.426 0 001.44 1.413H29.81zm-9.598-9.888h-6.174l.431-4.237h5.312l.43 4.237zm-8.342 0H7.337l.719-4.237H12.3l-.43 4.237zm15.043 0H22.38l-.432-4.237h4.246l.72 4.237zm-15.762 7.063H6.138l.84-4.944h4.677l-.504 4.944zm9.78 0h-7.612l.503-4.944h6.606l.503 4.944zm7.181 0H23.1l-.504-4.944h4.678l.84 4.944zM12.182 35.6l9.886-.012c.39 0 .705-.316.705-.705l.002-1.4a.706.706 0 00-.707-.708l-2.117.003v-1.416H14.3v1.422l-2.118.002a.706.706 0 00-.706.706l-.002 1.4c0 .391.317.708.707.708z"
        fill="#c9d935"
        fillRule="nonzero"
        strokeWidth={0.5}
      />
    </svg>
  )
}

import { UsageType } from "../../../../../data/generated-sources/openapi"

export enum UtilityUnitsColumns {
  STATUS = "STATUS",
  NAME = "NAME",
  TYPE = "TYPE",
  CURRENT_PARTICIPANT = "CURRENT_PARTICIPANT",
  BUILDING = "BUILDING",
}

export const utilityUnitTypeValues = Object.values(UsageType)
export type UtilityUnitTypeObjectKeys = keyof typeof UsageType

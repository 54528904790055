/* tslint:disable */
/* eslint-disable */
/**
 * CKW API
 * Endpoints for both CKW customers and administrators to create assets, user accounts, link them and update their details
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: support@ckw.noumenadigital.com
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export * from "./api";
export * from "./configuration";


import { AnyAction } from "redux"
import { LOCATION_CHANGE } from "connected-react-router"
import { AuthSession } from "../../domain/auth/Auth.Model"
import { AuthActionType } from "../auth/Auth.Epic"
import {
  asStarted,
  startLoading,
  asSuccess,
  asError,
  withDomainError,
  ViewState,
  withDomainResult,
  initialViewState,
} from "../Shared.Reducer"
import { AppContainerActionType } from "./AppContainer.Epic"
import { AppConfirmDialogState } from "./views/AppConfirmDialog"
import { AppToastState } from "./views/AppToast"

export interface AppContainerState {
  viewState: ViewState<AuthSession>
  appConfirmDialogState?: AppConfirmDialogState
  appToastState?: AppToastState
  sessionExpired: boolean
  pageSubTitle: string
}

export const initialState: AppContainerState = {
  viewState: initialViewState(),
  sessionExpired: false,
  pageSubTitle: "",
}

export const appContainerReducer = (state: AppContainerState = initialState, action: AnyAction): AppContainerState => {
  switch (action.type) {
    case asStarted(AuthActionType.AUTH_REFRESH_SESSION):
      return {
        ...state,
        viewState: startLoading(state.viewState),
      }
    case asSuccess(AuthActionType.AUTH_CREATE_SESSION):
    case asSuccess(AuthActionType.AUTH_REFRESH_SESSION):
      return {
        ...state,
        viewState: withDomainResult(state.viewState, action.result),
      }

    case asError(AuthActionType.AUTH_CREATE_SESSION):
    case asError(AuthActionType.AUTH_REFRESH_SESSION):
      return {
        ...state,
        viewState: withDomainError(state.viewState, action.result),
      }
    case AppContainerActionType.APP_CONTAINER_CONFIRM_DIALOG_SHOW:
      return {
        ...state,
        appConfirmDialogState: {
          dialogTitle: action.dialogTitle,
          dialogBody: action.dialogBody,
          dialogCta: action.dialogCta,
          nextAction: action.nextAction,
        },
      }
    case AppContainerActionType.APP_TOAST_SHOW:
      return {
        ...state,
        appToastState: {
          message: action.message,
          serverity: action.serverity ?? "success",
        },
      }
    case AppContainerActionType.APP_CONTAINER_CONFIRM_DIALOG_HIDE:
      return {
        ...state,
        appConfirmDialogState: undefined,
      }
    case AppContainerActionType.APP_TOAST_HIDE:
      return {
        ...state,
        appToastState: undefined,
      }
    case AppContainerActionType.APP_CONTAINER_LOGOUT:
      return initialState
    case AppContainerActionType.APP_CONTAINER_PAGE_SUBTITLE:
      return {
        ...state,
        pageSubTitle: action.pageSubTitle,
      }
    case LOCATION_CHANGE:
      return {
        ...state,
        pageSubTitle: "",
      }
    default:
      return {
        ...state,
        sessionExpired: action.type.endsWith("SESSION_TIMEOUT"),
      }
  }
}

import { I18nNamespace } from "../../../App.i18n"

export const ParticipantI18n: I18nNamespace = {
  name: "participant",
  de: {
    "detail.label.synced": "Datensatz synchron",
    "detail.label.unsynced": "Datensatz nicht synchron",
    "detail.action.delete": "Teilnehmer löschen",
    "detail.action.sapSync": "Synchronisierung in SAP bestätigen",
    "form.subtitle.personaldata": "Personendaten",
    "form.subtitle.correspondence": "Korrespondenz",
    "form.field.businessPartnerName": "Geschäftspartner (GP)",
    "form.field.businessPartnerType": "Geschäftspartner Typ",
    "form.field.gpType.PERSON": "Person",
    "form.field.salutation": "Anrede",
    "form.field.title": "Titel",
    "form.field.firstName": "Vorname",
    "form.field.lastName": "Nachname",
    "form.field.firstNameSecondPerson": "Vorname (2. Person)",
    "form.field.lastNameSecondPerson": "Nachname (2. Person)",
    "form.field.contactTelephone": "Telefon",
    "form.field.contactMobile": "Mobil",
    "form.field.contactEmail": "E-Mail",
    "form.subtitle.address": "Domiziladresse",
    "form.field.addressStreet": "Strasse",
    "form.field.addressHouseNumber": "Hausnummer",
    "form.field.addressCO": "c/o",
    "form.field.addressPostBox": "Postfach",
    "form.field.addressPostCode": "Postleitzahl",
    "form.field.addressCity": "Ort",
    "form.field.addressCountry": "Land",
    "form.action.cta": "Teilnehmer speichern",
    "form.alert.delete.label": "Möchten Sie den Teilnehmer endgültig löschen?",
    "form.alert.delete.cta": "Bestätigen",
    "label.noParticipants": "Zugewiesen an die ZEV",
  },
  en: {
    "detail.label.synced": "Data record in sync",
    "detail.label.unsynced": "Data record out of sync",
    "detail.action.delete": "Delete participant",
    "detail.action.sapSync": "Confirm synchronization in SAP",
    "form.subtitle.personaldata": "Personal data",
    "form.subtitle.correspondence": "Correspondence",
    "form.field.businessPartnerName": "Business partner (BP)",
    "form.field.businessPartnerType": "Business partner type",
    "form.field.gpType.PERSON": "Person",
    "form.field.salutation": "Salutation",
    "form.field.title": "Title",
    "form.field.firstName": "First name",
    "form.field.lastName": "Last name",
    "form.field.firstNameSecondPerson": "Vorname (2. Person)",
    "form.field.lastNameSecondPerson": "Nachname (2. Person)",
    "form.field.contactTelephone": "Telephone",
    "form.field.contactMobile": "Mobile",
    "form.field.contactEmail": "E-mail",
    "form.subtitle.address": "DOMICILE ADDRESS",
    "form.field.addressStreet": "Street",
    "form.field.addressHouseNumber": "House number",
    "form.field.addressCO": "c/o",
    "form.field.addressPostBox": "Postfach",
    "form.field.addressPostCode": "Post code",
    "form.field.addressCity": "City",
    "form.field.addressCountry": "Country",
    "form.action.cta": "Save participants",
    "form.alert.delete.label": "Would you like to permanently delete the participant?",
    "form.alert.delete.cta": "Confirm",
    "label.noParticipants": "Zugewiesen an die ZEV",
  },
}

import { TableFixed } from "../../../../../../uikit/table/Table.Fixed"
import { TableHeaderNoOrderingView } from "../../../../../../uikit/table/Table.HeaderView"
import { SimpleTableRowView } from "../../../../../../uikit/table/Table.RowView"
import { TableContainer, TableRow } from "@mui/material"
import { MediumTableEnum } from "../Medium.Enums"
import { MeterResponse } from "../../../../../../data/generated-sources/openapi"
import TableCell from "@mui/material/TableCell"
import DateUtils from "../../../../../../services/utils/DateUtils"

interface MediumZevTablePropsInterface {
  isLoading: boolean
  tableId: string
  meters?: MeterResponse[]
}

const tableHeaders = [
  {
    column: MediumTableEnum.DELIVERY_ID,
    label: "medium:table.header.deliveryId",
    width: "40%",
    orderable: false,
  },
  {
    column: MediumTableEnum.START_DATE,
    label: "medium:table.header.startDate",
    width: "30%",
    orderable: false,
  },
  {
    column: MediumTableEnum.END_DATE,
    label: "medium:table.header.endDate",
    width: "30%",
    orderable: false,
  },
]

const MediumZevTableComponent = ({ meters, isLoading, tableId }: MediumZevTablePropsInterface) => {
  const renderMediumZevRow = (item: MeterResponse) => {
    return (
      <TableRow key={item.id}>
        <TableCell align="left">{item.meteringCode}</TableCell>
        <TableCell align="left">
          {item.billableFrom ? DateUtils.getDeFormatDateWithMonthString(item.billableFrom) : "-"}
        </TableCell>
        <TableCell align="left">
          {item.billableTo ? DateUtils.getDeFormatDateWithMonthString(item.billableTo) : "-"}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <TableContainer>
      <TableFixed>
        <TableHeaderNoOrderingView headers={tableHeaders} isLoading={isLoading} />
        <SimpleTableRowView
          tableId={tableId}
          rows={meters}
          render={(item: MeterResponse) => renderMediumZevRow(item)}
        />
      </TableFixed>
    </TableContainer>
  )
}

export default MediumZevTableComponent

import moment from "moment"
import { ProductType, AllParticipantsBillingState, BillingFrequency } from "../../data/generated-sources/openapi"
import { ContractBillingFrequencyKeys } from "../../domain/portal/admin/contracts/Contracts.Models"

export const isNewStateBillingProduct = (activeState: AllParticipantsBillingState, hasCollectionFeature: boolean) => {
  if (
    hasCollectionFeature &&
    [AllParticipantsBillingState.DATA_AVAILABLE, AllParticipantsBillingState.IN_PROGRESS].includes(activeState)
  ) {
    return true
  }

  if (
    !hasCollectionFeature &&
    [
      AllParticipantsBillingState.DATA_AVAILABLE,
      AllParticipantsBillingState.IN_PROGRESS,
      AllParticipantsBillingState.IN_PROGRESS_REOPENED,
    ].includes(activeState)
  ) {
    return true
  }

  return false
}

export const calculateNextFrequency = (
  currentFrequency: BillingFrequency,
  nextFrequency: BillingFrequency,
): { currentFrequencyEnd: string; currentFrequencyLastActiveDate: string; nextFrequencyStart: string } => {
  let currentFrequencyEnd: moment.Moment
  let nextFrequencyStart: moment.Moment

  switch (currentFrequency) {
    case BillingFrequency.MONTHLY:
      currentFrequencyEnd = moment().endOf("month").startOf("day")
      break
    case BillingFrequency.QUARTERLY:
      currentFrequencyEnd = moment().endOf("quarter").startOf("day")
      break
    case BillingFrequency.BIANNUAL:
      if (moment().month() < 5) {
        currentFrequencyEnd = moment().month(5).endOf("month").startOf("day")
      } else {
        currentFrequencyEnd = moment().endOf("year")
      }
      break
    default:
      currentFrequencyEnd = moment().endOf("year")
  }

  switch (nextFrequency) {
    case BillingFrequency.MONTHLY:
      nextFrequencyStart = currentFrequencyEnd.clone().add(1, "day").startOf("month")
      break
    case BillingFrequency.QUARTERLY:
      nextFrequencyStart = currentFrequencyEnd.clone().endOf("quarter").add(1, "day").startOf("day")
      break
    case BillingFrequency.BIANNUAL:
      nextFrequencyStart = currentFrequencyEnd
        .clone()
        .startOf("year")
        .add(currentFrequencyEnd.month() < 5 ? 6 : 12, "months")
      break
    default:
      nextFrequencyStart = currentFrequencyEnd.clone().add(1, "year").startOf("year")
  }

  return {
    nextFrequencyStart: nextFrequencyStart.format("DD.MM.YYYY"),
    currentFrequencyEnd: currentFrequencyEnd.format("DD.MM.YYYY"),
    currentFrequencyLastActiveDate: nextFrequencyStart.subtract(1, "day").format("DD.MM.YYYY"),
  }
}

interface NextBillingData {
  nextBillingAvailableDates: string[]
  vewaMinDate: Date | undefined
  vewaMaxDate: Date | undefined
  nextBillingFrequencyOptions: string[]
}

/**
 * Calculate available Date options for nexBilling Dates / Options
 *
 * @param productType ProductType
 * @param startDate billingPeriod.startDate
 * @param billingFrequency BillingFrequency
 * @param vewaStartDate For VEWA contracts only, if existing billingPeriod.endDate or contract.startDate
 */

export const getNextBillingData = (
  productType: ProductType,
  startDate: string,
  billingFrequency: BillingFrequency,
  vewaStartDate?: string,
): NextBillingData => {
  const contractBillingFrequencyKeys = ContractBillingFrequencyKeys.filter(
    (bill) => ![BillingFrequency.YEARLY_ROLLING, BillingFrequency[billingFrequency]].includes(bill as BillingFrequency),
  )

  const nextBillingFrequencyOptions =
    productType === ProductType.ZEV
      ? contractBillingFrequencyKeys
      : [BillingFrequency.YEARLY, BillingFrequency.YEARLY_ROLLING]

  let vewaMinDate = undefined
  let vewaMaxDate = undefined
  let firstBillingDate = ""
  let middleBillingDate = ""
  let lastBillingDate = ""

  if (productType === ProductType.ZEV && startDate) {
    switch (billingFrequency) {
      case BillingFrequency.YEARLY:
      case BillingFrequency.YEARLY_ROLLING:
        firstBillingDate = moment(startDate).endOf("year").format("YYYY-MM-DD")
        middleBillingDate = moment(startDate).add(1, "year").endOf("year").format("YYYY-MM-DD")
        lastBillingDate = moment(startDate).add(2, "year").endOf("year").format("YYYY-MM-DD")
        break
      case BillingFrequency.BIANNUAL:
        firstBillingDate = moment(startDate).add(1, "quarter").endOf("quarter").format("YYYY-MM-DD")
        middleBillingDate = moment(startDate).add(3, "quarter").endOf("quarter").format("YYYY-MM-DD")
        lastBillingDate = moment(startDate).add(5, "quarter").endOf("quarter").format("YYYY-MM-DD")
        break
      case BillingFrequency.QUARTERLY:
        firstBillingDate = moment(startDate).endOf("quarter").format("YYYY-MM-DD")
        middleBillingDate = moment(startDate).add(1, "quarter").endOf("quarter").format("YYYY-MM-DD")
        lastBillingDate = moment(startDate).add(2, "quarter").endOf("quarter").format("YYYY-MM-DD")
        break
      default:
        firstBillingDate = moment(startDate).endOf("month").format("YYYY-MM-DD")
        middleBillingDate = moment(startDate).add(1, "month").endOf("month").format("YYYY-MM-DD")
        lastBillingDate = moment(startDate).add(2, "month").endOf("month").format("YYYY-MM-DD")
        break
    }
  } else {
    const startDate = vewaStartDate ? new Date(vewaStartDate) : new Date()
    vewaMinDate = startDate
    vewaMaxDate = moment(startDate).add(2, "y").toDate()
  }

  const nextBillingAvailableDates = [firstBillingDate, middleBillingDate, lastBillingDate]

  return {
    nextBillingAvailableDates,
    vewaMinDate,
    vewaMaxDate,
    nextBillingFrequencyOptions,
  }
}

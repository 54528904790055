import { useTranslation } from "react-i18next"
import { Paper, Grid } from "@mui/material"
import { CommonFormikProps } from "../../../../Shared.Utils"

import { ContractFormRow, ContractFormTitle } from "../common/CommonFormFragments"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { SwitchToggleView } from "../../../../../uikit/toggle/SwitchToggleView"
import { ContractUpdateContactAdminRequestExtended } from "../../../../../domain/portal/admin/contracts/Contracts.Models"

const ContractContactDataFields = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
}: CommonFormikProps<ContractUpdateContactAdminRequestExtended>) => {
  const { t } = useTranslation("contracts-management")
  const touchedFileds = Object.keys(touched)

  return (
    <Paper>
      <ContractFormTitle>{t("contactForm.title")}</ContractFormTitle>
      <Grid container rowGap={5}>
        <Grid container item>
          <ContractFormRow>
            <SwitchToggleView
              label={t("contactForm.takeName")}
              onChange={(checked) => {
                if (checked) {
                  setFieldValue("addressData.addressLineOne", values.contractName)
                }
                setFieldValue("useContractName", checked)
              }}
            />
          </ContractFormRow>
          <ContractFormRow />
          <ContractFormRow>
            <SingleLineTextField
              name="addressLineOne"
              type="text"
              label={t("contactForm.contractor")}
              value={values.addressData.addressLineOne}
              disabled={values.useContractName}
              helperText={
                touchedFileds.includes("addressLineOne") && errors.addressData?.addressLineOne
                  ? errors.addressData?.addressLineOne
                  : undefined
              }
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("addressData.addressLineOne", event.target.value)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="externalReferenceNumber"
              type="text"
              required
              label={t("contactForm.refNumb")}
              helperText={
                touched.externalReferenceNumber && errors.externalReferenceNumber
                  ? errors.externalReferenceNumber
                  : undefined
              }
              value={values.externalReferenceNumber}
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("externalReferenceNumber", event.target.value)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="addressLineTwo"
              type="text"
              label={t("contactForm.addressAdditional")}
              value={values.addressData?.addressLineTwo}
              onBlur={handleBlur}
              helperText={
                touchedFileds.includes("addressLineTwo") && errors.addressData?.addressLineTwo
                  ? errors.addressData?.addressLineTwo
                  : undefined
              }
              onChange={(event) => setFieldValue("addressData.addressLineTwo", event.target.value)}
            />
          </ContractFormRow>
          <ContractFormRow />
        </Grid>
        <Grid container item>
          <ContractFormRow>
            <SingleLineTextField
              name="telephone"
              type="text"
              label={t("contactForm.phone")}
              value={values.contact.telephone}
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("contact.telephone", event.target.value)}
              helperText={
                touchedFileds.includes("telephone") && errors.contact?.telephone ? errors.contact?.telephone : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="mobile"
              type="text"
              label={t("contactForm.mobile")}
              value={values.contact.mobile}
              onBlur={handleBlur}
              helperText={
                touchedFileds.includes("mobile") && errors.contact?.mobile ? errors.contact?.mobile : undefined
              }
              onChange={(event) => setFieldValue("contact.mobile", event.target.value)}
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              name="email"
              type="text"
              label={t("contactForm.email")}
              onBlur={handleBlur}
              value={values.contact.email}
              onChange={(event) => setFieldValue("contact.email", event.target.value)}
              helperText={touchedFileds.includes("email") && errors.contact?.email ? errors.contact?.email : undefined}
            />
          </ContractFormRow>
          <ContractFormRow />
        </Grid>
        <Grid container item>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="street"
              type="text"
              label={t("contactForm.street")}
              onBlur={handleBlur}
              value={values.addressData.street}
              onChange={(event) => setFieldValue("addressData.street", event.target.value)}
              helperText={
                touchedFileds.includes("street") && errors.addressData?.street ? errors.addressData?.street : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="houseNumber"
              type="text"
              label={t("contactForm.houseNumber")}
              value={values.addressData?.houseNumber}
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("addressData.houseNumber", event.target.value)}
              helperText={
                touchedFileds.includes("houseNumber") && errors.addressData?.houseNumber
                  ? errors.addressData?.houseNumber
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="postalCode"
              type="text"
              label={t("contactForm.postalCode")}
              onBlur={handleBlur}
              value={values.addressData.postalCode}
              onChange={(event) => setFieldValue("addressData.postalCode", event.target.value)}
              helperText={
                touchedFileds.includes("postalCode") && errors.addressData?.postalCode
                  ? errors.addressData?.postalCode
                  : undefined
              }
            />
          </ContractFormRow>
          <ContractFormRow>
            <SingleLineTextField
              required
              name="city"
              type="text"
              label={t("contactForm.city")}
              value={values.addressData.city}
              onBlur={handleBlur}
              onChange={(event) => setFieldValue("addressData.city", event.target.value)}
              helperText={
                touchedFileds.includes("city") && errors.addressData?.city ? errors.addressData?.city : undefined
              }
            />
          </ContractFormRow>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ContractContactDataFields

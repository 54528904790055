import { ConsumptionScope, Medium, UsageType } from "../../../../../data/generated-sources/openapi"
import { GroupMetersProps } from "./Medium.Interfaces"
import { Typography } from "@mui/material"
import { RemoveIcon } from "../../../../../uikit/Shared.Icon"
import { getTranslateValue } from "../../../../Shared.Utils"

export const selectedMeterItemView = (
  usageType: UsageType,
  consumptionScope: ConsumptionScope,
  meters: GroupMetersProps[],
  setMeters: (meters: GroupMetersProps[]) => void,
  medium?: Medium,
) => {
  const linkedMeters = meters.filter(
    (meter: GroupMetersProps) =>
      meter.isLinkedToVewaConfig &&
      meter.linkedConsumptionScope === consumptionScope &&
      meter.usageType === usageType &&
      (medium ? meter.medium === medium : true),
  )

  if (linkedMeters.length === 0) return null

  return linkedMeters?.map((item: GroupMetersProps, index: number) => {
    return (
      <div key={item.id}>
        <Typography variant="body1" textAlign="start" sx={{ fontSize: "12px", mt: 3, mb: 2, color: "gray" }}>
          {getTranslateValue("medium:label.meter") + ` ${index + 1}`}
        </Typography>
        <Typography
          fontSize={16}
          fontWeight={400}
          mb={2}
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid gray",
            paddingBottom: "5px",
          }}
        >
          {item.meteringCode}
          <RemoveIcon
            fontSize="small"
            style={{
              cursor: "pointer",
            }}
            onClick={() => {
              setMeters(
                meters.map((meter: GroupMetersProps) => {
                  if (meter.id === item.id) {
                    return { ...meter, isLinkedToVewaConfig: false, linkedConsumptionScope: "" }
                  }
                  return meter
                }),
              )
            }}
          />
        </Typography>
      </div>
    )
  })
}

export const getMetersOptions = (usageType: UsageType, meters: GroupMetersProps[]) => {
  const filteredMeters = meters
    .filter((meter: GroupMetersProps) => !meter.isLinkedToVewaConfig && meter.usageType === usageType)
    .map((meter: GroupMetersProps) => ({
      label: meter.meteringCode,
      value: meter.id,
    }))

  return filteredMeters.length ? filteredMeters : [{ label: getTranslateValue("medium:label.noMeters"), value: "" }]
}

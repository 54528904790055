import { ForwardedRef, forwardRef, useMemo, useState } from "react"
import { DateView, LocalizationProvider, DesktopDatePicker, DesktopDatePickerSlots } from "@mui/x-date-pickers"
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { SxProps, TextField, TextFieldProps, Theme } from "@mui/material"
import de from "date-fns/locale/de"

export enum DatePickerMonthDay {
  CURRENT = "CURRENT",
  BEGIN = "BEGIN",
  END = "END",
}

interface SingleLineDatePickerProps {
  name: string
  label: string
  value: number
  required?: boolean
  disabled?: boolean
  helperText?: string
  onChange: (date: number) => void
  onBlur?: React.FocusEventHandler<HTMLInputElement | HTMLTextAreaElement>
  minDate?: Date
  maxDate?: Date
  views?: DateView[]
  range?: DatePickerMonthDay
  textInputStyle?: SxProps<Theme>
  desctopSlots?: DesktopDatePickerSlots<Date>
}

export const SingleLineDatePicker = (props: SingleLineDatePickerProps) => {
  const {
    label,
    value,
    disabled,
    onChange,
    minDate,
    maxDate,
    views = ["year", "month", "day"],
    range = DatePickerMonthDay.CURRENT,
    textInputStyle,
    desctopSlots,
  } = props

  const [open, setOpen] = useState(false)

  const DateTextField = useMemo(() => {
    const DateText = forwardRef((props: TextFieldProps, ref: ForwardedRef<HTMLDivElement | HTMLInputElement>) => {
      return (
        <TextField
          ref={ref}
          {...props}
          sx={{
            width: "100%",
            ...textInputStyle,
          }}
          variant="standard"
          autoComplete="off"
          error={props?.helperText !== undefined}
          onClick={() => setOpen(true)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              setOpen(false)
            }
          }}
        />
      )
    })

    DateText.displayName = "CustomTextField"
    return DateText
  }, [])

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={de}>
      <DesktopDatePicker<Date>
        open={open}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        label={label}
        disabled={disabled}
        value={value !== -1 && value !== 0 ? new Date(value) : null}
        onChange={(date: Date | null) => {
          if (date === null) {
            onChange(-1)
          } else if (views.includes("month")) {
            switch (range) {
              case DatePickerMonthDay.CURRENT:
                onChange(date.getTime())
                break
              case DatePickerMonthDay.BEGIN:
                onChange(new Date(date.getFullYear(), date.getMonth(), 1).getTime())
                break
              case DatePickerMonthDay.END:
                onChange(new Date(date.getFullYear(), date.getMonth() + 1, 0).getTime())
                break
            }
          } else {
            onChange(date.getTime())
          }
        }}
        minDate={minDate}
        maxDate={maxDate}
        views={views}
        defaultValue={minDate}
        format="dd.MM.yyyy"
        slots={{ textField: DateTextField, ...desctopSlots }}
      />
    </LocalizationProvider>
  )
}

import { I18nNamespace } from "../../../../App.i18n"

export const ParticipantBillingsManagementI18n: I18nNamespace = {
  name: "participantBillingManagement",
  de: {
    "page.title": "Teilnehmer-Abrechnungen",
    "table.label.datePeriod": "Datum / Zeitraum",
    "table.label.billingNumber": "Rechnungsnummer",
    "table.label.status": "Status",
    "table.label.billingType": "Abrechnungsart",
    "table.label.zev": "ZEV",
    "table.label.contractName": "Vertragsname",
  },
  en: {
    "page.title": "Participant Billings",
    "table.label.datePeriod": "Date / Period",
    "table.label.billingNumber": "Billing Number",
    "table.label.status": "Status",
    "table.label.billingType": "Billing Type",
    "table.label.zev": "ZEV",
    "table.label.contractName": "Contract Name",
  },
}

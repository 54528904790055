import { Box, Button, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { ProductType } from "../../../../data/generated-sources/openapi"
import { useAppSelector } from "../../../hooks"

import { DashboardChartView } from "./view/DashboardChartView"
import { ErrorAlert, InfoAlert } from "../../../../uikit/Shared.Alert"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { getDashboardOverview } from "../../../../domain/portal/manager/dashboard/Dashboard.Repository"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { DashboardVewaChartView } from "./view/DashboardVewaChartView"
import {
  DashboardVewaOverview,
  DashboardZevOverview,
} from "../../../../domain/portal/manager/dashboard/Dashboard.Model"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const DashboardMeterReading = () => {
  const { t } = useTranslation("dashboard")
  const { areaId } = useParams<{ areaId: string }>()
  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const [error, setError] = useState<AxiosErrorDataType>()
  const history = useHistory()

  const navigateToAnalyses = () => history.push(`/${areaId}/analyses`)

  const {
    data: dashboardOverview,
    remove: removeDashboardOverview,
    refetch: refetchConsumption,
    isFetching,
  } = useQuery(
    ["getDashboardOverview", contractId, contractProductType],
    () => getDashboardOverview(contractId, contractProductType),
    {
      enabled: !!contractId && !!contractProductType,
      onError: setError,
    },
  )

  useEffect(() => {
    setError(undefined)
    if (contractId && contractProductType) {
      refetchConsumption()
    }
  }, [areaId, contractId, contractProductType, refetchConsumption])

  useEffect(() => {
    return () => {
      removeDashboardOverview()
    }
  }, [removeDashboardOverview])

  const DashboardReadings = useCallback(() => {
    return (
      <>
        {dashboardOverview ? (
          <>
            {contractProductType === ProductType.ZEV && (
              <DashboardChartView {...(dashboardOverview as DashboardZevOverview)} />
            )}
            {contractProductType === ProductType.VEWA && (
              <DashboardVewaChartView {...(dashboardOverview as DashboardVewaOverview)} />
            )}
          </>
        ) : (
          <InfoAlert message={t("section-overview.alert")} />
        )}
      </>
    )
  }, [dashboardOverview, contractProductType, t])

  return (
    <Box p={6}>
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} pb={5}>
        <Typography sx={{ fontWeight: 400 }} variant="h5">
          {t("section-subtitle", {
            lastDataPointDate: dashboardOverview?.latestCalcAvailabilityDate,
          })}
        </Typography>
        <Button
          variant="text"
          onClick={navigateToAnalyses}
          startIcon={<ArrowForwardIcon fontSize="small" color="inherit" />}
        >
          {t("section-overview.detail.analysis")}
        </Button>
      </Stack>
      {isFetching ? <ProgressIndicator /> : <DashboardReadings />}
    </Box>
  )
}

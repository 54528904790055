import {
  MeterReadingsContractElectricityCalcResponse,
  VewaMeterReadingsResponse,
} from "../../../../data/generated-sources/openapi"
import DateUtils from "../../../../services/utils/DateUtils"
import { DashboardVewaOverview, DashboardZevOverview } from "./Dashboard.Model"

export const dashboardOverviewZevMapper = (
  latestCalcAvailability: string,
  meterReadingsZev: MeterReadingsContractElectricityCalcResponse,
): DashboardZevOverview => {
  return {
    latestCalcAvailabilityDate: DateUtils.getDeFormatWithDots(latestCalcAvailability),
    totalProduction: `${parseFloat(meterReadingsZev.totalProduction).toFixed(2)} kWh`,
    totalSumProduction: `${parseFloat(meterReadingsZev.totalSumProduction).toFixed(2)} kWh`,
    totalSold: `${parseFloat(meterReadingsZev.totalSold)} kWh`,
    totalBought: `${parseFloat(meterReadingsZev.totalBought).toFixed(2)} kWh`,
  }
}

export const dashboardOverviewVewaMapper = (
  latestCalcAvailability: string,
  meterReadingsVewa: VewaMeterReadingsResponse,
): DashboardVewaOverview => {
  const consumptionData = {
    totalHeatConsumption: 0,
    totalColdConsumption: 0,
    totalWarmWaterConsumption: 0,
    totalColdWaterConsumption: 0,
    latestCalcAvailabilityDate: DateUtils.getDeFormatWithDots(latestCalcAvailability),
  }

  for (const [key, value] of Object.entries(meterReadingsVewa.data)) {
    switch (key) {
      case "HEAT":
        consumptionData.totalHeatConsumption = parseFloat(value.totalConsumption ?? "0")
        break
      case "COLD":
        consumptionData.totalColdConsumption = parseFloat(value.totalConsumption ?? "0")
        break
      case "WARM_WATER":
        consumptionData.totalWarmWaterConsumption = parseFloat(value.totalConsumption ?? "0")
        break
      default:
        consumptionData.totalColdWaterConsumption = parseFloat(value.totalConsumption ?? "0")
        break
    }
  }
  return consumptionData
}

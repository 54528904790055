import { BuildingAdminResponse } from "../../../../data/generated-sources/openapi"
import { StatusType } from "../../../Domain.Model"

export interface IBuildingAdminResponse extends BuildingAdminResponse {
  areaName?: string
  fullAddress: string
}

export const initialBuildingCreateAdminRequest = {
  statusType: StatusType.DRAFT,
  name: "",
  address: {
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  },
}

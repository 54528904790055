import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"

export const EmptyChartDisplay = () => {
  const { t } = useTranslation("shared")
  return (
    <Grid container justifyContent="center" alignItems="center" sx={{ height: "100%" }}>
      {t("label.noChartData")}
    </Grid>
  )
}

import { Typography } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik, FormikProps } from "formik"
import { groupCostShares } from "../../../../domain/portal/admin/mediu/MediumVewaCostShares.Mapperts"
import { BillingScope, VewaCostShare } from "../../../../data/generated-sources/openapi"

import { VewaCostSharesData } from "../vewaCostShares/VewaCostSharesData"
import { VewaCostSharesDataUpdate } from "../vewaCostShares/VewaCostSharesDataUpdate"
import { VewaCostSharesDataBox } from "../vewaCostShares/VewaCostSharesDataBox"
import { VewaCostSharesSchema } from "./VewaBillingValidatioin.Schema"

interface VewaBillingCostSharesProps {
  costShares?: VewaCostShare[]
  onUpdateSubmit: (costShares: VewaCostShare[]) => void
  isUpdateSuccess?: boolean
}

export const VewaBillingCostShares = ({ costShares, isUpdateSuccess, onUpdateSubmit }: VewaBillingCostSharesProps) => {
  const { t } = useTranslation("medium")
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<BillingScope | undefined>(undefined)
  const groupedCostShares = groupCostShares(costShares)

  useEffect(() => {
    if (isUpdateSuccess) {
      setEditMode(undefined)
      setIsSubmitting(false)
    }
    if (!isUpdateSuccess && isSubmitting) {
      setIsSubmitting(false)
    }
  }, [isUpdateSuccess, isSubmitting])

  const RenderBillingScopeShares = ({ resetForm, ...rest }: FormikProps<VewaCostShare[]>) => {
    return (
      <>
        {Array.from(groupedCostShares).map(([billingType, costSharesMap]) => (
          <VewaCostSharesDataBox
            showEditIcon
            key={billingType}
            billingScope={billingType}
            editModeInit={editMode}
            onEditClick={(scope) => {
              resetForm()
              setEditMode(scope)
            }}
          >
            {editMode === billingType ? (
              <VewaCostSharesDataUpdate {...rest} vewaCostSharesMap={costSharesMap} isSubmitting={isSubmitting} />
            ) : (
              <VewaCostSharesData vewaCostSharesMap={costSharesMap} />
            )}
          </VewaCostSharesDataBox>
        ))}
      </>
    )
  }

  const handleSubmit = (costShares: VewaCostShare[]) => {
    setIsSubmitting(true)
    onUpdateSubmit(costShares)
  }

  return (
    <>
      <Typography fontSize={24} fontWeight={400}>
        {t("label.costAllocationTitle")}
      </Typography>
      {!costShares?.length ? (
        <Typography color="inherit" p={1}>
          {t("shared:label.empty.list")}
        </Typography>
      ) : (
        <Formik<VewaCostShare[]>
          validateOnBlur
          validateOnChange
          enableReinitialize
          onSubmit={handleSubmit}
          initialValues={costShares}
          validationSchema={VewaCostSharesSchema}
        >
          {(props) => {
            return (
              <Form>
                <RenderBillingScopeShares {...props} />
              </Form>
            )
          }}
        </Formik>
      )}
    </>
  )
}

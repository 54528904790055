import { useTranslation } from "react-i18next"
import { Grid, Box, Typography, Stack } from "@mui/material"

import PricecalculatorFooter3x from "../../../../../../res/PricecalculatorFooter@3x.png"
import { ElectricityCalculatorItemView } from "./ElectricityCalculatorItemView"
import {
  SolarIconSvgIcon,
  PowerPoleDaySvgIcon,
  PowerPoleNightSvgIcon,
  SettingIcon,
} from "../../../../../uikit/Shared.Icon"
import { ViewState } from "../../../../Shared.Reducer"
import { InfoAlert } from "../../../../../uikit/Shared.Alert"
import { PrimaryButton } from "../../../../../uikit/button"
import { ZevPriceCalculatorPricesResponse } from "../../../../../data/generated-sources/openapi"

interface MyZevElectricityCalculatorFooterViewProps {
  zevHasVat: boolean
  updatePrices: ViewState<boolean>
  priceCalculatorPrices?: ZevPriceCalculatorPricesResponse
  updateZevPricePackageById: () => void
}

const FooterStyle = {
  backgroundSize: "cover",
  backgroundImage: `url('${PricecalculatorFooter3x}')`,
  borderRadius: "0px 0px 7px 7px",
}

export const MyZevElectricityCalculatorFooterView = (props: MyZevElectricityCalculatorFooterViewProps) => {
  const { zevHasVat, priceCalculatorPrices, updatePrices, updateZevPricePackageById } = props
  const { t } = useTranslation("my-zev")

  return (
    <>
      <Typography fontWeight={400} variant="h5" mb={2}>
        {t("section-main.tab.priceCalculator.calculatedValues.title")}
      </Typography>
      {zevHasVat && (
        <Typography fontWeight={400} variant="h5" mb={2}>
          {t("section-main.tab.priceCalculator.prices")} {t("section-main.tab.priceCalculator.vat.excluded")}
        </Typography>
      )}
      <Grid container style={FooterStyle}>
        <Box
          sx={{
            flex: 1,
            minWidth: 200,
          }}
        >
          <Box ml={7.5} mr={10} mt={45}>
            <PriceCalculatorInfo priceCalculatorPrices={priceCalculatorPrices} />
          </Box>
        </Box>
        <Box flex={1}>
          <Box sx={{ minWidth: 300 }} mr={7.5} mt={7.5}>
            <Box mt={7.5}>
              <Stack my={4} flexDirection={"row"}>
                <ElectricityCalculatorItemView
                  value={kpValues(priceCalculatorPrices?.prices.solarPrice)}
                  icon={<SolarIconSvgIcon />}
                  label={t("section-main.tab.priceCalculator.calculatedValues.solarPowerPrice")}
                />
                <ElectricityCalculatorItemView
                  value={kpValues(priceCalculatorPrices?.prices.highTariffPrice)}
                  icon={<PowerPoleDaySvgIcon />}
                  label={t("section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceHighTariff")}
                />
              </Stack>
              <Stack my={4} flexDirection={"row"}>
                <ElectricityCalculatorItemView
                  value={kpValues(priceCalculatorPrices?.prices.lowTariffPrice)}
                  icon={<PowerPoleNightSvgIcon />}
                  label={t("section-main.tab.priceCalculator.calculatedValues.gridPurchasePriceLowTariff")}
                />
                <ElectricityCalculatorItemView
                  lumpSum={priceCalculatorPrices?.prices.isFixedRate}
                  value={
                    priceCalculatorPrices?.prices.isFixedRate
                      ? priceCalculatorPrices?.prices.additionalServicesPrice
                      : kpValues(priceCalculatorPrices?.prices.additionalServicesPrice)
                  }
                  icon={<SettingIcon />}
                  label={t("section-main.tab.priceCalculator.calculatedValues.gridPriceMeasurement")}
                />
              </Stack>
            </Box>
            <Stack flexDirection={"row"} alignItems={"flex-end"} justifyContent={"flex-end"} flex={1} py={3}>
              <PrimaryButton
                label={t("section-main.tab.priceCalculator.calculatedValues.cta")}
                isLoading={updatePrices.isLoading}
                disabled={!priceCalculatorPrices || updatePrices.isLoading}
                onClick={() => updateZevPricePackageById()}
              />
            </Stack>
          </Box>
        </Box>
      </Grid>
    </>
  )
}

interface PriceCalculatorInfoProps {
  priceCalculatorPrices?: ZevPriceCalculatorPricesResponse
}

const PriceCalculatorInfo = (props: PriceCalculatorInfoProps) => {
  const { t } = useTranslation("my-zev")
  const { priceCalculatorPrices } = props

  if (!priceCalculatorPrices)
    return <InfoAlert message={t("section-main.tab.priceCalculator.calculatedValues.notCalculated")} />
  if (priceCalculatorPrices.isReferenceProductCheaper)
    return <InfoAlert message={t("section-main.tab.priceCalculator.calculatedValues.referenceProductCheaper")} />
  return <></>
}

const kpValues = (value: string | undefined) => {
  if (value === undefined) return "-"
  return ((parseFloat(value) || 0) * 100).toFixed(2)
}

import { Grid, InputAdornment, Stack, TextField, Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ConsumptionScope, UsageType, VewaCostShare } from "../../../../data/generated-sources/openapi"
import { VewaCostShareTitle } from "./fragments/CostShareFragments"
import { CommonFormikProps } from "../../../Shared.Utils"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import { EditCostStructure } from "./fragments/EditCostStructure"

interface VewaCostShareDataUpdateProps extends CommonFormikProps<VewaCostShare[]> {
  vewaCostSharesMap?: Map<ConsumptionScope, VewaCostShare[]>
  hideSubmitBtn?: boolean
  isSubmitting?: boolean
}

const EditInput = styled(TextField)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  alignSelf: "flex-end",
  "&.MuiFormControl-root": {
    "& .MuiInputBase-root": {
      minWidth: "50px",
      maxWidth: "76px",
      background: "white",
      paddingRight: theme.spacing(2),
      border: `1px solid ${theme.palette.text.secondary}`,
      borderRadius: theme.spacing(1),
      "& .MuiInputBase-input": {
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),
        padding: theme.spacing(1, 2),
        paddingRight: 0,
        textAlign: "right",
      },
      "& .MuiInputAdornment-root": {
        marginLeft: 0,
        "& .MuiTypography-root ": {
          fontWeight: 700,
          fontSize: theme.typography.pxToRem(16),
          color: theme.palette.text.primary,
        },
      },
      "& .MuiOutlinedInput-notchedOutline": {
        border: "none",
      },
    },
  },
}))

export const VewaCostSharesDataUpdate = ({
  vewaCostSharesMap,
  hideSubmitBtn,
  isSubmitting,
  values,
  dirty,
  isValid,
  setFieldValue,
}: VewaCostShareDataUpdateProps) => {
  const { t } = useTranslation("medium")

  const handleOnChange = (inputValue: string, index: number, basicCosts: boolean) => {
    let value = parseInt(inputValue || "0")

    if (value && value > 100) value = 100
    if (value && value < 0) value = 0
    if (basicCosts) {
      setFieldValue(`${index}.costStructure.basicCosts`, value)
      setFieldValue(`${index}.costStructure.consumptionBasedCosts`, 100 - value)
    } else {
      setFieldValue(`${index}.costStructure.consumptionBasedCosts`, value)
      setFieldValue(`${index}.costStructure.basicCosts`, 100 - value)
    }
  }

  if (!vewaCostSharesMap) {
    return <Typography fontWeight={500}>{t("shared:message.noConfigurationAvailable")}</Typography>
  }

  return (
    <Grid container>
      {Array.from(vewaCostSharesMap || []).map(([consumptionScope, costShares]) => {
        return (
          <Grid container item key={consumptionScope}>
            <Grid item xs={12}>
              {(consumptionScope === ConsumptionScope.ROOM_HEATING ||
                consumptionScope === ConsumptionScope.WATER_HEATING) && (
                <Typography fontSize={16} fontWeight={500} mb={2} key={consumptionScope}>
                  {t(`label.${consumptionScope}`)}
                </Typography>
              )}
            </Grid>

            {costShares.map((costShare) => {
              const index = values?.findIndex(
                (value) =>
                  value.type.consumptionScope === costShare.type.consumptionScope &&
                  value.type.usageType === costShare.type.usageType,
              )

              const showSubmitBtn =
                !hideSubmitBtn &&
                costShare.type.usageType === UsageType.RESIDENCE &&
                costShare.type.consumptionScope !== ConsumptionScope.ROOM_HEATING

              return (
                <Grid
                  container
                  item
                  sm={6}
                  xs={12}
                  mb={4}
                  pr={4}
                  alignContent={"flex-start"}
                  key={costShare.type.usageType}
                >
                  <Grid item xs={12}>
                    <Typography fontSize={14} fontWeight={500} mb={2}>
                      {costShare.type.usageType === UsageType.RESIDENCE
                        ? t("label.residentalUnits")
                        : t("label.commercialUnits")}
                    </Typography>
                  </Grid>

                  <Grid item sm={12} md={6} display={"grid"} mb={3}>
                    <VewaCostShareTitle>{t("label.basicCost")}</VewaCostShareTitle>
                    <EditInput
                      id="basicCosts"
                      name="basicCosts"
                      value={values[index]?.costStructure?.basicCosts}
                      onChange={({ target }) => handleOnChange(target.value, index, true)}
                      InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                    />
                  </Grid>

                  <Grid item sm={12} md={6} alignSelf={"flex-end"} mb={3}>
                    <VewaCostShareTitle textAlign={"end"}>{t("label.consumptionBasedCost")}</VewaCostShareTitle>
                    <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-end"}>
                      <EditInput
                        id="basicCosts"
                        name="basicCosts"
                        value={values[index]?.costStructure?.consumptionBasedCosts}
                        onChange={({ target }) => handleOnChange(target.value, index, false)}
                        InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
                      />
                    </Stack>
                  </Grid>

                  <Grid item xs={12}>
                    <EditCostStructure
                      costShareIndex={index}
                      values={values}
                      onSliderChange={(newValue) => {
                        if (newValue) {
                          setFieldValue(`${index}.costStructure.basicCosts`, newValue)
                          setFieldValue(`${index}.costStructure.consumptionBasedCosts`, 100 - newValue)
                        }
                      }}
                    />
                  </Grid>
                  {showSubmitBtn && (
                    <Grid item xs={12}>
                      <PrimaryButton
                        label={t("shared:form.action.save")}
                        type={"submit"}
                        isLoading={isSubmitting}
                        disabled={!dirty || !isValid || isSubmitting}
                      />
                    </Grid>
                  )}
                </Grid>
              )
            })}
          </Grid>
        )
      })}
    </Grid>
  )
}

/*
  https://brand.ckw.ch/document/82#/umsetzung-digital/pattern-library/buttons-links
*/
import Button, { ButtonTypeMap } from "@mui/material/Button"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
interface GradientButtonProps {
  id?: string
  label: string
  startColor: string
  endColor: string
  labelColor?: string
  labelStyle?: object
  size?: string
  height?: string
  fWeight?: number
  useNewDesign?: boolean
}

export const GradientButton = (props: GradientButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const {
    height,
    labelColor,
    size,
    id,
    label,
    disabled,
    startIcon,
    endIcon,
    onClick,
    type,
    startColor,
    endColor,
    labelStyle,
    fWeight,
    sx,
    useNewDesign,
  } = props
  const buttonTextColor = labelColor ?? "#fff"
  const buttonHeight = height ?? undefined
  const fontWeight = fWeight ?? 400
  const buttonLabelStyle = labelStyle ?? {}
  return (
    <Button
      id={id}
      type={type}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
      size={size}
      sx={{
        color: buttonTextColor,
        background: useNewDesign
          ? "linear-gradient(256.56deg, #9AB20F -22.21%, #4E7818 65.58%)"
          : `linear-gradient(to bottom,${startColor},${endColor})`,
        backgroundSize: "102%",

        position: "relative",
        height: buttonHeight,
        cursor: "pointer",
        display: "inline-flex",
        overflow: "hidden",
        userSelect: "none",
        verticalAlign: "middle",
        transition: ".3s ease-out",
        fontWeight: fontWeight,
        fontSize: "0.9em",
        padding: ".75em 2em",
        lineHeight: "inherit",
        boxShadow: "none",
        borderRadius: "7.144em",
        border: "none",
        textTransform: "inherit",
        zIndex: 1,

        "&:hover": { boxShadow: "none", color: "#fff" },

        "&:disabled": {
          color: "#fff",
          opacity: 0.5,
          cursor: "not-allowed",
        },

        "&::before": {
          position: "absolute",
          content: "''",
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          background: `linear-gradient(to bottom, ${startColor}, ${startColor})`,
          zIndex: -1,
          transition: ".3s ease-out",
          opacity: 0,
        },
        "&:hover::before": { opacity: 1 },
        ...sx,
      }}
    >
      <span style={buttonLabelStyle}>{label}</span>
    </Button>
  )
}

import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import { BillingType, CostPosition, CustomerContractResponse } from "../../../../data/generated-sources/openapi"

export const getContractFeesByContractId = async (contractId: string): Promise<CustomerContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractContractDetailsById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const collections = data?.costPositions?.sort((a, b) => a.billingType.localeCompare(b.billingType))
  let prevBillData = collections[0].billingType

  data.costPositions = collections?.map((item: CostPosition, index: number) => {
    if (index && prevBillData === item?.billingType) {
      item.billingType = "" as BillingType
    } else {
      prevBillData = item.billingType
    }
    return item
  })

  return data
}

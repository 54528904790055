export enum AdminPortalRouteEnums {
  buildingId = "buildingId",
  serviceComponentId = "serviceComponentId",
  billingId = "billingId",
  contractId = "contractId",
  profileId = "profileId",
  consumptionPointId = "consumptionPointId",
  participantId = "participantId",
  productId = "productId",
  priceId = "priceId",
  meterId = "meterId",
  utilityUnitId = "utilityUnitId",
  areaId = "areaId",
  id = "id",
  productType = "productType",
}

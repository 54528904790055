import { GetContractManagementContactHook } from "../CustomGetHooks"
import { AxiosResponse } from "axios"
import React from "react"
import { getTranslateValue } from "../../../../Shared.Utils"
import RedirectButton from "../../../../../uikit/button/RedirectButton"
import { ContractUpdateContactAdminRequest } from "../../../../../data/generated-sources/openapi"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"

export const CONTRACT_MANAGEMENT_CONTACT = {
  contractManagementContact: {
    name: getTranslateValue("contracts-management:contactForm.title"),
    useQueryHook: (paramId: string) => GetContractManagementContactHook({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: " ",
          getValue: () => <FormSectionTitle label={getTranslateValue("profiles:details.label.contact")} />,
        },
        secondColumn: {
          label: "",
          getComponent: () => <RedirectButton redirectUrl={`${location.pathname}/contact/update`} />,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.contractor",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) =>
            response.data.addressData.addressLineOne,
        },
        secondColumn: {
          label: "contracts:field.label.externalReferenceNumber",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) =>
            response.data.externalReferenceNumber,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.address",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) =>
            response.data.addressData.addressLineTwo,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.street",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) => response.data.addressData.street,
        },
        secondColumn: {
          label: "contracts:field.label.houseNumber",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) =>
            response.data.addressData.houseNumber,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.postalCode",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) =>
            response.data.addressData.postalCode,
        },
        secondColumn: {
          label: "contracts:field.label.city",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) => response.data.addressData.city,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.telephone",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) => response.data.contact.telephone,
        },
        secondColumn: {
          label: "contracts:field.label.mobile",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) => response.data.contact.mobile,
        },
      },
      {
        firstColumn: {
          label: "contracts:field.label.email",
          getValue: (response: AxiosResponse<ContractUpdateContactAdminRequest>) => response.data.contact.email,
        },
      },
    ],
  },
}

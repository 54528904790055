export type ReadCookie = (name: string) => string

export type CreateCookie = (name: string, value: string | undefined) => void

export interface ApiCookie {
  readCookieBearerToken: ReadCookie
  createCookieBearerToken: CreateCookie
  readCookieRefreshToken: ReadCookie
  createCookieRefreshToken: CreateCookie
  readCookieAuthType: ReadCookie
  createCookieAuthType: CreateCookie
  createLastSelectedZev: CreateCookie
  readLastSelectedZev: ReadCookie
  removeCookies: (appName: string) => void
}

export const readCookieBearerToken = (appName: string) => readCookie(`jwttoken_${appName}`)

export const createCookieBearerToken = (appName: string, value: string | undefined) =>
  createCookie(`jwttoken_${appName}`, value)

export const readCookieRefreshToken = (appName: string) => readCookie(`jwttokenrefresh_${appName}`)

export const createCookieRefreshToken = (appName: string, value: string | undefined) =>
  createCookie(`jwttokenrefresh_${appName}`, value)

export const readCookieAuthType = (appName: string) => readCookie(`authtype_${appName}`)

export const createCookieAuthType = (appName: string, value: string | undefined) =>
  createCookie(`authtype_${appName}`, value)

export const readLastSelectedZev = (appName: string) => readCookie(`lastzev_${appName}`)

export const createLastSelectedZev = (appName: string, value: string | undefined) =>
  createCookie(`lastzev_${appName}`, value)

export const removeCookies = (appName: string) => {
  localStorage.removeItem(`jwttoken_${appName}`)
  localStorage.removeItem(`jwttokenrefresh_${appName}`)
  localStorage.removeItem(`authtype_${appName}`)
}

export const createCookie = (name: string, value: string | undefined): void => {
  localStorage.setItem(name, value ?? "")
}

export const readCookie = (name: string): string => {
  return localStorage.getItem(name) ?? ""
}

import { Form, Formik, FormikHelpers } from "formik"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useMutation } from "@tanstack/react-query"
import { initialBuildingCreateAdminRequest } from "../../../../domain/portal/admin/buildings/Buildings.Model"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { BuildingCreateAdminRequest } from "../../../../data/generated-sources/openapi"

import { FormMode } from "../../../../uikit/form/FormView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { PlusIcon } from "../../../../uikit/Shared.Icon"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { adminCreateBuildingForArea } from "../../../../domain/portal/admin/buildings/Buildings.Repository"
import { BuildingCreateSchema } from "./fragments/BuildingCreate.Schema"
import { BuildingCreateUpdate } from "./fragments/BuildingCreateUpdateForm"

export const BuildingCreate = ({ match: { params }, history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("buildings")
  const { id: areaId } = params

  const {
    mutate: createBuilding,
    isError,
    error,
    isLoading,
  } = useMutation(["createBuilding"], (values: BuildingCreateAdminRequest) =>
    adminCreateBuildingForArea(areaId, values),
  )

  const handleOnSubmit = (
    values: BuildingCreateAdminRequest,
    formikHelpers: FormikHelpers<BuildingCreateAdminRequest>,
  ) => {
    const createBuildingData = {
      name: values.name,
      address: values.address,
    }

    createBuilding(createBuildingData, {
      onSuccess: () => {
        formikHelpers.resetForm()
        history.push(`/area/${areaId}`)
      },
    })
  }

  const errorMsg = error as AxiosErrorDataType

  return (
    <Formik<BuildingCreateAdminRequest>
      onSubmit={handleOnSubmit}
      validationSchema={BuildingCreateSchema}
      initialValues={initialBuildingCreateAdminRequest}
    >
      {({ handleSubmit, isValid, dirty, ...rest }) => (
        <Form onSubmit={handleSubmit}>
          <ErrorAlert
            scrollOnDisplay
            visible={isError}
            message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
          />
          <BuildingCreateUpdate mode={FormMode.CREATE} {...rest} />

          <FormUpdateActionsView
            dirty={dirty}
            isValid={isValid}
            startIcon={<PlusIcon />}
            isLoading={isLoading}
            navigateBack={() => history.goBack()}
            buttonCtaLabel={t("create.form.action.cta")}
          />
        </Form>
      )}
    </Formik>
  )
}

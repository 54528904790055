import { Typography } from "@mui/material"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi/api"
import { ContractParticipantsApiEnum } from "../Components.Enums"
import { getTranslateValue } from "../../../../Shared.Utils"

import { PlusIcon } from "../../../../../uikit/Shared.Icon"
import { TableAlign } from "../../../../../uikit/table/Table.HeaderView"
import { participantsColumnComparator } from "../participants/Participants.Comparator"
import { ContractParticipantsColumns } from "../participants/ContractParticipants.Enum"
import ContractParticipantsTable from "../participants/ContractParticipantsTable"

export const CONTRACT_PARTICIPANTS_CONFIG = {
  contractManagementParticipants: {
    name: "contractManagementParticipants",
    header: {
      title: "contracts-management:participants.title",
      showButton: true,
      showDownloadBtn: true,
      showFilter: false,
      buttonIcon: <PlusIcon />,
      children: (
        <Typography variant="h4" pl={2} pb={2} textTransform={"uppercase"} fontSize={16} fontWeight={500}>
          {getTranslateValue("contracts-management:participants.current")}
        </Typography>
      ),
    },
    columnEnums: ContractParticipantsColumns,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultRowsPerPage: 25,
    defaultSorting: {
      column: ContractParticipantsColumns.SYNC,
      direction: "asc",
    },
    showPagination: true,
    tableHeaders: [
      {
        column: ContractParticipantsColumns.SYNC,
        label: "contracts-management:participants.synchronized",
        width: "20%",
        orderable: true,
      },
      {
        column: ContractParticipantsColumns.FIRST_NAME,
        label: "contracts-management:participants.firstName",
        width: "20%",
        orderable: true,
      },
      {
        column: ContractParticipantsColumns.LAST_NAME,
        label: "contracts-management:participants.lastName",
        width: "20%",
        orderable: true,
        align: TableAlign.left,
      },
      {
        column: ContractParticipantsColumns.GP_TYPE,
        label: "contracts-management:participants.gpNumber",
        width: "20%",
        orderable: true,
      },
      {
        column: ContractParticipantsColumns.EMAIL,
        label: "contracts-management:participants.email",
        width: "20%",
        orderable: true,
      },
    ],
    columnComparator: participantsColumnComparator,
    renderTableRows: (item: ParticipantResponse) => <ContractParticipantsTable itemConfig={item} key={item.id} />,
  },
}

export const CONTRACT_PARTICIPANTS_API_CONFIG = {
  contractManagementParticipants: ContractParticipantsApiEnum,
}

import { MeterResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_STRING_COMPARATOR, ORDERED_STRING_LOCALE_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { MetersColumns } from "./Meters.Enum"

export const metersColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case MetersColumns.STATUS:
      return (a: MeterResponse, b: MeterResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case MetersColumns.MEDIUM:
      return (a: MeterResponse, b: MeterResponse) => ORDERED_STRING_COMPARATOR(a.medium, b.medium, orderBy.direction)
    case MetersColumns.UNIT:
      return (a: MeterResponse, b: MeterResponse) =>
        ORDERED_STRING_COMPARATOR(a.meterAttachmentName, b.meterAttachmentName, orderBy.direction)
    default:
      return (a: MeterResponse, b: MeterResponse) =>
        ORDERED_STRING_COMPARATOR(a.meteringCode || "", b.meteringCode || "", orderBy.direction)
  }
}

import { TFunction } from "i18next"

import { FieldTouched, validate, ValidField } from "../../../../Shared.Validation"

export const validateProfileEmailForm = {
  email: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("contact.email"),
        })
      }
      return ValidField
    }, touched),
}

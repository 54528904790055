import { Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Redirect, RouteComponentProps } from "react-router"
import { SingleLineTextField } from "../../uikit/input/SingleLineTextField"
import { PageHeader } from "../../uikit/typography/Header"
import { SmallPaddedBox } from "../../uikit/box/PaddedBox"
import { Typography, Box } from "@mui/material"
import { FormActions } from "../../uikit/form/FormView"
import { AuthAdminContainerView } from "./views/AuthAdminContainerView"
import { PrimaryButton } from "../../uikit/button"
import { ErrorAlert } from "../../uikit/Shared.Alert"
import { validationError, VALIDATION_DEPS } from "../Shared.Validation"
import { AuthNewAccountPasswordState } from "./AuthNewAccountPassword.Reducer"
import { validateNewAccountPassword } from "./AuthNewAccountPassword.Validation"
import { AdminPortalRouteParams } from "../portal/admin/AdminPortal.Routes"
import { useEffect, useMemo, useState } from "react"
import { mapDispatchToProps } from "./AuthNewAccountPassword.Connect"
import jwt_decode from "jwt-decode"
import moment from "moment"
import Logo from "../../../res/logo.svg"
import { Link } from "react-router-dom"
import { DividerBox } from "../../uikit/box/DividerBox"

export interface AuthNewAccountPasswordComponentProps
  extends AuthNewAccountPasswordState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export interface AuthNewAccountPasswordValues {
  password: string
  confirmPassword: string
}
export interface DecodedTokenInterface {
  firstname: string
  iss: string
  personId: string
  unblockOnExec: string
  exp: number
  userId: string
  email: string
  lastname: string
  timestamp: number
}
export const AuthNewAccountPasswordComponent = (props: AuthNewAccountPasswordComponentProps) => {
  const { t } = useTranslation("auth")
  const { viewState, resendLinkViewState, newAccountPassword, resendLinkAct, location } = props
  const token = useMemo(() => location.search.split("?token=").pop() ?? "", [location])
  const [decodedToken, setDecodedToken] = useState<DecodedTokenInterface>({
    firstname: "",
    iss: "",
    personId: "",
    unblockOnExec: "",
    exp: 0,
    userId: "",
    email: "",
    lastname: "",
    timestamp: 0,
  })

  useEffect(() => {
    if (token) {
      setDecodedToken(jwt_decode(token))
    }
  }, [token])

  const sendLink = () => {
    resendLinkAct(token)
  }
  const isTokenValid = () => {
    return !moment(decodedToken?.exp * 1000).isBefore(new Date().getTime())
  }
  return (
    <div
      className={"aaa"}
      style={{
        display: "flex",
        backgroundImage:
          "url('https://verwalterportal.ckw-test.noumenadigital.com/static/media/LoginBackground_w1280.0554cc49.jpg')",
        height: "100%",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        width: "100vw",
        position: "relative",
      }}
    >
      <Link
        to="/signin"
        style={{
          position: "absolute",
          zIndex: "100",
          margin: "50px",
          height: "fit-content",
        }}
      >
        <img
          loading="eager"
          alt={"CKW Abrechnungs-Manager"}
          title={"CKW Abrechnungs-Manager"}
          style={{ width: 150 }}
          src={Logo}
        />
      </Link>
      <div
        style={{
          top: "0",
          width: "100%",
          height: "400px",
          position: "absolute",
          background:
            "linear-gradient(to bottom, rgba(255, 255, 255, 1) 29%, rgba(255, 255, 255, .8) 50%, rgba(255, 255, 255, 0))",
        }}
      ></div>
      <AuthAdminContainerView>
        <PageHeader id="new-account-password-title">{t("newAccountPassword.title")}</PageHeader>
        {isTokenValid() ? (
          <>
            <Box ml={2} mr={2} mb={2}>
              <Typography variant="body1" id="new-account-password-subtitle">
                {t("newAccountPassword.label")}
              </Typography>
            </Box>
            <Formik<AuthNewAccountPasswordValues>
              validateOnBlur
              initialValues={{
                password: "",
                confirmPassword: "",
              }}
              onSubmit={(values) => newAccountPassword(token, values.password)}
              validate={(values) => validateNewAccountPassword(values, VALIDATION_DEPS, t)}
            >
              {({ values, errors, handleChange, handleBlur, handleSubmit, isValid, touched }) => (
                <form onSubmit={handleSubmit}>
                  {viewState.domainResult && <Redirect to="/signin" />}
                  {viewState.domainError && <ErrorAlert message={viewState.domainError.message} />}
                  <SmallPaddedBox>
                    <SingleLineTextField
                      id="password"
                      autoFocus={true}
                      name="password"
                      type="password"
                      label={t("newAccountPassword.field.password")}
                      helperText={validationError(errors.password, touched.password)}
                      value={values.password}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SmallPaddedBox>
                  <SmallPaddedBox>
                    <SingleLineTextField
                      id="confirmPassword"
                      name="confirmPassword"
                      type="password"
                      label={t("newAccountPassword.field.confirmPassword")}
                      helperText={validationError(errors.confirmPassword, touched.confirmPassword)}
                      value={values.confirmPassword}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                  </SmallPaddedBox>
                  <FormActions>
                    <SmallPaddedBox>
                      <PrimaryButton
                        id="new-account-password-cta"
                        disabled={!isValid || viewState.isLoading}
                        isLoading={viewState.isLoading}
                        label={t("newAccountPassword.action.cta")}
                        type="submit"
                      />
                    </SmallPaddedBox>
                  </FormActions>
                </form>
              )}
            </Formik>
          </>
        ) : (
          <div style={{ padding: "16px" }}>
            {resendLinkViewState.domainError ? (
              <ErrorAlert title={t("shared:alert.error")} message={resendLinkViewState.domainError.message} />
            ) : null}
            <DividerBox />
            {resendLinkViewState.domainResult ? (
              <p>{t("newAccountPassword.linkSent.success")}</p>
            ) : (
              <ErrorAlert title={t("shared:alert.error")} message={t("error-codes:FORGOT_PASSWORD_ACTION_EXPIRED")} />
            )}
            {resendLinkViewState.domainResult ? null : (
              <Box
                pt={4}
                pb={5}
                m="auto"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
              >
                <PrimaryButton
                  id="login-cta"
                  isLoading={resendLinkViewState.isLoading}
                  disabled={resendLinkViewState.isLoading}
                  label={t("newAccountPassword.linkSend.cta")}
                  type="submit"
                  onClick={() => sendLink()}
                />
              </Box>
            )}
          </div>
        )}
      </AuthAdminContainerView>
    </div>
  )
}

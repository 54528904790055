import { LegendWrapper } from "../analiyse/components/Legend"
import { getLegendConfig } from "../analiyse/charts/chart.config"
import { useTranslation } from "react-i18next"
import { ZevBillingPieChartProps, mapZevChartData } from "./ZevBillingPieChart"

export const ZevBillingsChartLegend = ({
  totalEnergyHighTariff,
  totalEnergyLowTariff,
  totalEnergyProduction,
}: ZevBillingPieChartProps) => {
  const { t } = useTranslation()

  return (
    <LegendWrapper
      config={getLegendConfig(t, "ELECTRICITY")}
      data={mapZevChartData({ totalEnergyHighTariff, totalEnergyLowTariff, totalEnergyProduction })}
    />
  )
}

import { ChangeEvent, useState, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { Typography, Link, Box, TextField } from "@mui/material"
import { FaqTopics } from "./Faq.Stub"

import { Accordion, AccordionDetails, AccordionSummary } from "../../../../uikit/accordion/Accordion"

export interface IFaqTopic {
  id: string
  title: string
  content: string
  externalUrl?: string
  externalUrlText?: string
}

export const FaqComponent = () => {
  const { t } = useTranslation("faq")
  const [searchTerm, setSearchTerm] = useState<string>("")
  const [foundTermItems, setFoundTermItems] = useState<IFaqTopic[]>([])
  const [accordionExpanded, setAccordionExpanded] = useState<string | false>(false)

  const faqTopics: IFaqTopic[] = Object.entries(FaqTopics).map(([key, value]) => ({ id: key, ...value }))
  const searchTermActive = useMemo(() => searchTerm.length >= 1, [searchTerm])

  const expandAccordion = (accId: string, isExpanded: boolean) => setAccordionExpanded(isExpanded ? accId : false)

  const handleSearchInput = (searchText: string) => {
    if (!searchText) {
      return setFoundTermItems([])
    }

    const termRegex = new RegExp(`${searchText}`, "i")
    const foundItems = faqTopics.filter(
      ({ title, content, externalUrlText }) =>
        termRegex.test(title) || termRegex.test(content) || termRegex.test(externalUrlText ?? ""),
    )
    setFoundTermItems(foundItems)
  }

  const faqsArray = useMemo(
    () => (searchTermActive ? foundTermItems : faqTopics),
    [searchTermActive, foundTermItems, faqTopics],
  )

  return (
    <Box p={8}>
      <Typography fontWeight={400} pb={7.5} variant="h3">
        {t("faq.title")}
      </Typography>
      <TextField
        autoFocus
        id="filter"
        type="text"
        variant="standard"
        value={searchTerm}
        placeholder={t("faq.search.placeholder")}
        onChange={(event: ChangeEvent<HTMLInputElement>) => {
          setSearchTerm(event.target.value)
          handleSearchInput(event.target.value)
        }}
        sx={{ width: "100%", mb: "50px" }}
      />

      {searchTermActive && (
        <Typography variant="subtitle2" color="#9e9e9e">
          {t("faq.search.result", { count: foundTermItems.length })}
        </Typography>
      )}

      {faqsArray ? (
        faqsArray.map(({ id, title, content, externalUrl, externalUrlText }) => {
          const isExpanded = accordionExpanded === id
          return (
            <Accordion
              key={id}
              expanded={isExpanded}
              onChange={(_, expanded: boolean) => expandAccordion(id, expanded)}
            >
              <AccordionSummary id={id} sx={{ cursor: "pointer" }}>
                <Typography color={isExpanded ? "#6c9c30" : "inherit"} variant="h5" fontWeight={300}>
                  {title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Typography pt={1.25} variant="body1">
                  {content}
                  {externalUrl && (
                    <Link target={"_blank"} href={externalUrl} color={"primary"}>
                      {externalUrlText ?? "Linkinformationen"}
                    </Link>
                  )}
                </Typography>
              </AccordionDetails>
            </Accordion>
          )
        })
      ) : (
        <Typography variant="subtitle2" color="#9e9e9e">
          {t("faq.noresults")}
        </Typography>
      )}
    </Box>
  )
}

import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { TextField } from "@mui/material"

import { FormView } from "../../../../../uikit/form/FormView"
import { PriceCalculatorRow } from "../views/PricecalculatorFormRow"
import { ZevPriceCalculatorInput } from "../../../../../data/generated-sources/openapi"

interface CalculatorOperationFormProps {
  zevHasVat: boolean
  zevPriceCalculatorInput: ZevPriceCalculatorInput
  dataStatusChanged: (zevPriceCalculatorInput: ZevPriceCalculatorInput) => void
}

export const CalculatorOperationForm = (props: CalculatorOperationFormProps) => {
  const { zevHasVat, zevPriceCalculatorInput, dataStatusChanged } = props
  const { t } = useTranslation("my-zev")

  return (
    <FormView>
      <PriceCalculatorRow
        label={`${t("section-main.tab.priceCalculator.operation.returnOnDelivery")}${
          zevHasVat ? ` ${t("section-main.tab.priceCalculator.vat.excluded")}` : ""
        }`}
        info={t("section-main.tab.priceCalculator.operation.returnOnDelivery.info")}
        childField={
          <NumberFormat
            fullWidth
            key={"section-main.tab.priceCalculator.operation.returnOnDelivery"}
            name={"section-main.tab.priceCalculator.operation.returnOnDelivery"}
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale
            value={zevPriceCalculatorInput.sellingElectricityPricePerKwh}
            suffix=" Rp/kWh"
            thousandSeparator="'"
            onValueChange={(sellingElectricityPricePerKwh) =>
              dataStatusChanged({
                ...zevPriceCalculatorInput,
                sellingElectricityPricePerKwh: sellingElectricityPricePerKwh.floatValue ?? 0,
              })
            }
          />
        }
      />
      <PriceCalculatorRow
        label={t("section-main.tab.priceCalculator.operation.operatingCostsProduction")}
        info={t("section-main.tab.priceCalculator.operation.operatingCostsProduction.info")}
        childField={
          <NumberFormat
            fullWidth
            key={"section-main.tab.priceCalculator.operation.operatingCostsProduction"}
            name={"section-main.tab.priceCalculator.operation.operatingCostsProduction"}
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale
            value={zevPriceCalculatorInput.operatingCostsPerKwh}
            suffix=" Rp/kWh"
            thousandSeparator="'"
            onValueChange={(operatingCostsPerKwh) =>
              dataStatusChanged({
                ...zevPriceCalculatorInput,
                operatingCostsPerKwh: operatingCostsPerKwh.floatValue ?? 0,
              })
            }
          />
        }
      />
    </FormView>
  )
}

import CircularProgress from "@mui/material/CircularProgress"
export interface ProgressIndicatorProps {
  size?: number | undefined
}
export const ProgressIndicator = (props: ProgressIndicatorProps) => {
  const { size = 32 } = props
  return <CircularProgress size={size} thickness={4.6} color="secondary" />
}

export const SmallProgressIndicator = () => {
  return <CircularProgress size={20} thickness={4.6} color="secondary" />
}

import { I18nNamespace } from "../../../App.i18n"

export const FaqI18n: I18nNamespace = {
  name: "faq",
  de: {
    title: "FAQ",
    "faq.title": "Wie können wir Ihnen helfen?",
    "faq.search.placeholder": "Was suchen Sie?",
    "faq.search.result": "{{count}} Ergebnis",
    result_plural: "{{count}} Ergebnisse",
    "faq.noresults": "Ups, Ihre Suche lieferte kein Ergebnis. Versuche Sie es erneut mit einem anderen Begriff.",
  },
  en: {
    title: "FAQ",
    "faq.title": "How can we help you?",
    "faq.search.placeholder": "What are you looking for?",
    "faq.search.result": "{{count}} Result",
    result_plural: "{{count}} Results",
    "faq.noresults": "Oops, your search returned no results. Try again with a different term.",
  },
}

import { I18nNamespace } from "../../../App.i18n"

export const Onboarding18n: I18nNamespace = {
  name: "onboarding",
  de: {
    "header.wellcome": "Willkommen",
    "header.contractSetup": "Vertragseinrichtung",
    "startPage.title": "Willkommen zu den ersten Schritten Ihres neuen Vertrages",
    "startPage.subTitle": "Wählen Sie einen Vertrag aus, den Sie  einrichten wollen.",
    "startPage.picker": "Vertrag auswählen",
    "startPage.button": "Vertrag einrichten",
    "contactInfo.title": "Kontaktinformationen überprüfen",
    "contactInfo.details": "Kontaktangaben",
    "contactInfo.adress": "Adresse",
    "contactInfo.contactMedia": "Kontaktmedien",
    "billingInfo.title": "Abrechnungseinstellungen überprüfen",
    "electricityPricesInfo.title": "Strompreise definieren",
    "electricityPricesInfo.subTitle1":
      "Bitte definieren Sie den Standard Preis für ihre ZEV. Sie können diesen, sowie weitere Preise, jederzeit im Bereich",
    "participantsInfo.title": "Teilnehmer erfassen",
    "participantsInfo.subTitle": "Fast geschafft! Jetzt müssen Sie nur noch die Zuordnung der Teilnehmer vornehmen.",
    "participantsInfo.selectParticipant": "Teilnehmer auswählen",
    "finalize.title": "Vertrag {{contractName}} wurde erfolgreich eingerichtet",
    "finalize.subTitle": "Sie haben den Vertrag {{contractName}} erfolgreich eingerichtet. ",
    "finalize.subTitle.setup":
      "Damit Sie Abrechnungen oder Anpassungen am Vertrag vornehmen können, müssen erst alle weiteren Verträge eingerichtet werden. ",
    "finalize.subTitle.overview":
      "Sie können jetzt auf den Abrechnungs-Manager zugreifen und die Verbräuche für diesen Vertrag einsehen und abrechnen.",
    "finalize.setupButton": "Weiteren Vertrag einrichten",
    "finalize.overviewButton": "Zur Übersicht",
    "header.step": "Schritt",
    "header.subTitle1":
      "Überprüfen Sie alle Angaben auf ihre Korrektheit. Sie können diese jederzeit später im Bereich",
    "header.subTitle2": " „Einstellungen“ ",
    "header.subTitle3": "anpassen.",
    "nextPage.button": "Nächster Schritt",
    "nextPage.completeSetup": "Einrichtung abschliessen",
    "tooltip.solarPower": "Dies sind die Kosten für den Eigenverbrauch ihrer Solaranlage.",
    "tooltip.netzstrom": "Summe Hochtarif gemäss Energie- und Netzprodukt, Abgaben, Konzession",
    "tooltip.niedertarif": "Summe Niedertarif gemäss Energie- und Netzprodukt, Abgaben, Konzession",
    "tooltip.measurement":
      "Hier können Sie wählen, ob Sie diese Kosten in CHF/Monat (Angaben unter “Einstellungen” - “Gebühren“) oder verbrauchsabhängig (wird automatisch berechnet) verrechnen möchten.“",
    "tooltip.performancePrice": "Leistungspreis- und Netzprodukt, Abgaben, Konzession",
    "participation.building": "Anschlussbojekt",
    "participation.utilityUnit": "Nutzeinheit",
    "participation.participant": "Teilnehmer",
    "costShares.title": "Wärme- und Wasserkostenabrechnung Kostenanteile",
    "costShares.subTitle1":
      "Bitte definieren Sie die Kostenanteile von Grund- und Verbrauchsabhängigen Kosten. Sie können diesen, sowie weitere Preise, jederzeit im Bereich",
    "costShares.linkText": "Mehr Informationen zur Berechnung",
  },
  en: {
    "header.wellcome": "Welcome",
    "header.contractSetup": "Contract setup",
    "startPage.title": "Welcome to the first steps of your new contract",
    "startPage.subTitle": "Select a contract that you want to set up.",
    "startPage.picker": "Select contract",
    "startPage.button": "Set up contract",
    "contactInfo.title": "Check contact information",
    "contactInfo.details": "contact details",
    "contactInfo.adress": "Adrress",
    "contactInfo.contactMedia": "Contact Media",
    "billingInfo.title": "Check billing settings",
    "electricityPricesInfo.title": "Define electricity prices",
    "electricityPricesInfo.subTitle": "More information on the ZEV price calculation",
    "participantsInfo.title": "Create participants",
    "participantsInfo.subTitle": "Almost there! Now you only have to assign the participants.",
    "finalize.title": "Contract {{contractName}} was set up successfully",
    "finalize.subTitle": "You have successfully set up the {{contractName}} contract. ",
    "finalize.subTitle.setup":
      "In order for you to be able to make billing or adjustments to the contract, all other contracts must first be set up. ",
    "finalize.subTitle.overview":
      "You can now access the billing manager and view and bill consumption for this contract.",
    "finalize.setupButton": "Set up another contract",
    "finalize.overviewButton": "To overview",
    "header.step": "Step",
    "header.subTitle1": "Check that all information is correct. You can always do this later in the area",
    "header.subTitle2": "Settings ",
    "header.subTitle3": "adjust.",
    "nextPage.button": "Next Step",
    "nextPage.completeSetup": "Complete Setup",
    "tooltip.solarPower": "These are the costs for self-consumption of your solar system.",
    "tooltip.netzstrom": "Total high tariff according to energy and grid product, taxes, concession",
    "tooltip.niedertarif": "TTotal off-peak tariff according to energy and grid product, charges, concession",
    "tooltip.measurement":
      "Here you can choose whether you want to charge these costs in CHF/month (details under “Settings“ - “Charges“) or based on consumption (calculated automatically).",
    "tooltip.performancePrice": "Performance price and network product, charges, concession",
    "participation.building": "Building",
    "participation.utilityUnit": "Utility Unit",
    "participation.participant": "Participant",
    "costShares.title": "Heat and water cost billing cost shares",
    "costShares.subTitle1":
      "Please define the cost shares of basic and consumption-dependent costs. You can adjust this and other prices at any time in the",
    "costShares.linkText": "More information about the calculation",
  },
}

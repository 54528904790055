// import { Observable, from, map } from "rxjs"
// import { MeterReadingsCalcResolution } from "../../data/generated-sources/openapi"
// import { apiCall, apiHeaders } from "../Domain.Calls"
// import { DomainDependencies } from "../Domain.Dependencies"
// import { timestampToApiFormattedDate } from "../Domain.Formatters"
// import { DomainResponse } from "../Domain.Response"
// import { intraDayMeterReadingMapper, zevConsumptionMeterReadingMapper } from "./MeterReadings.Mapper"
// import { MeterReadingDateRange } from "./MeterReadings.Model"
// import { startOfWeek, endOfWeek } from "date-fns"
// import { PortalContext } from "../Domain.Model"
// import { DOMAIN_DEPENDENCIES } from "../../app/App.Config"

// const deps = DOMAIN_DEPENDENCIES

// export const getIntraDayMeterReadings = (
//   contractId: string,
//   startDate: number,
//   endDate: number,
//   portalContext: PortalContext,
// ): Observable<DomainResponse<IntraDayMeterReading>> => {
//   switch (portalContext) {
//     case PortalContext.ADMIN:
//       return apiCall(
//         from(
//           deps.adminContractApi.adminGetMeterReadingByContractId(
//             contractId,
//             timestampToApiFormattedDate(startDate, deps),
//             timestampToApiFormattedDate(endDate, deps),
//             MeterReadingsCalcResolution.MINUTE,
//             apiHeaders(deps),
//           ),
//         ).pipe(map((meterReadings) => intraDayMeterReadingMapper(meterReadings.data))),
//       )
//     case PortalContext.MANAGER:
//       return apiCall(
//         from(
//           deps.customerMeterReadingsApi.customerGetMeterReadingsByContractId(
//             contractId,
//             timestampToApiFormattedDate(startDate, deps),
//             timestampToApiFormattedDate(endDate, deps),
//             MeterReadingsCalcResolution.MINUTE,
//             apiHeaders(deps),
//           ),
//         ).pipe(map((meterReadings) => intraDayMeterReadingMapper(meterReadings.data))),
//       )
//   }
// }
//
// export const getContractMeterReadings = (
//   contractId: string,
//   selectedDate: number,
//   meterReadingDateRange: MeterReadingDateRange,
//   portalContext: PortalContext,
// ): Observable<DomainResponse<ZevMeterReading>> => {
//   switch (portalContext) {
//     case PortalContext.ADMIN:
//       return apiCall(
//         from(
//           deps.adminContractApi.adminGetMeterReadingByContractId(
//             contractId,
//             timestampToApiFormattedDate(firstDayDate(selectedDate, meterReadingDateRange, deps).getTime(), deps),
//             timestampToApiFormattedDate(lastDayDate(selectedDate, meterReadingDateRange, deps).getTime(), deps),
//             meterReadingResolution(meterReadingDateRange),
//             apiHeaders(deps),
//           ),
//         ).pipe(map((meterReadings) => zevConsumptionMeterReadingMapper(meterReadings.data, meterReadingDateRange))),
//       )
//     case PortalContext.MANAGER:
//       return apiCall(
//         from(
//           deps.customerMeterReadingsApi.customerGetMeterReadingsByContractId(
//             contractId,
//             timestampToApiFormattedDate(firstDayDate(selectedDate, meterReadingDateRange, deps).getTime(), deps),
//             timestampToApiFormattedDate(lastDayDate(selectedDate, meterReadingDateRange, deps).getTime(), deps),
//             meterReadingResolution(meterReadingDateRange),
//             apiHeaders(deps),
//           ),
//         ).pipe(map((meterReadings) => zevConsumptionMeterReadingMapper(meterReadings.data, meterReadingDateRange))),
//       )
//   }
// }

// const firstDayDate = (dateTime: number, meterReadingDateRange: MeterReadingDateRange, deps: DomainDependencies) => {
//   const date = new Date(dateTime)
//   switch (meterReadingDateRange) {
//     case MeterReadingDateRange.WEEK:
//       return startOfWeek(date, { locale: deps.config.locale })
//     case MeterReadingDateRange.MONTH:
//       return new Date(date.getFullYear(), date.getMonth(), 1)
//     case MeterReadingDateRange.YEAR:
//       return new Date(date.getFullYear(), 0, 1)
//   }
// }

// const lastDayDate = (dateTime: number, meterReadingDateRange: MeterReadingDateRange, deps: DomainDependencies) => {
//   const date = new Date(dateTime)
//   switch (meterReadingDateRange) {
//     case MeterReadingDateRange.WEEK:
//       return endOfWeek(date, { locale: deps.config.locale })
//     case MeterReadingDateRange.MONTH:
//       return new Date(date.getFullYear(), date.getMonth() + 1, 0)
//     case MeterReadingDateRange.YEAR:
//       return new Date(date.getFullYear(), 11, 31)
//   }
// }

// const meterReadingResolution = (meterReadingDateRange: MeterReadingDateRange) => {
//   switch (meterReadingDateRange) {
//     case MeterReadingDateRange.WEEK:
//       return MeterReadingsCalcResolution.DAY
//     case MeterReadingDateRange.MONTH:
//       return MeterReadingsCalcResolution.DAY
//     case MeterReadingDateRange.YEAR:
//       return MeterReadingsCalcResolution.MONTH
//   }
// }

import { MeterReadingsCalcResolution, MeterReadingsContractResponse } from "../../data/generated-sources/openapi"
import { DOMAIN_DEPENDENCIES } from "../../app/App.Config"
import { apiHeaders } from "../Domain.Calls"
import { DayMeterReadingMapper } from "./Analyse.Mapper"
import { ZevDayMeterReading } from "./Analyse.Model"

export const customerGetContractDaily = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  resolution: MeterReadingsCalcResolution,
): Promise<ZevDayMeterReading | MeterReadingsContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetMeterReadingsByContractId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  if (data.zev) return DayMeterReadingMapper(data)
  return data
}

export const adminGetContractDaily = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  resolution: MeterReadingsCalcResolution,
): Promise<ZevDayMeterReading | MeterReadingsContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetMeterReadingByContractId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  if (data.zev) return DayMeterReadingMapper(data)
  return data
}

export const adminGetContractAnalyse = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  resolution: MeterReadingsCalcResolution,
): Promise<MeterReadingsContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetMeterReadingByContractId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetContractAnalyse = async (
  utilityUnitId: string,
  startDate: string,
  endDate: string,
  resolution: MeterReadingsCalcResolution,
): Promise<MeterReadingsContractResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetMeterReadingsByContractId(
    utilityUnitId,
    startDate,
    endDate,
    resolution,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

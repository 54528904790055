import { AuthSession } from "../../domain/auth/Auth.Model"
import {
  adminNewActivationEmail,
  createAdminSession,
  forgottenPassword,
  newAccountPassword,
  refreshAdminSession,
  resetPassword,
} from "../../domain/auth/Auth.Repository"
import { createEpic } from "../Shared.Epic"

export enum AuthActionType {
  AUTH_CREATE_SESSION = "AUTH_CREATE_SESSION",
  AUTH_REFRESH_SESSION = "AUTH_REFRESH_SESSION",
  AUTH_FORGOTTEN_PASSWORD = "AUTH_FORGOTTEN_PASSWORD",
  AUTH_NEW_ACCOUNT_PASSWORD = "AUTH_NEW_ACCOUNT_PASSWORD",
  AUTH_RESEND_LINK = "AUTH_RESEND_LINK",
  AUTH_RESET_PASSWORD = "AUTH_RESET_PASSWORD",
}

export const authEpics = [
  createEpic<AuthSession>(AuthActionType.AUTH_REFRESH_SESSION, () => refreshAdminSession()),
  createEpic<AuthSession>(AuthActionType.AUTH_CREATE_SESSION, (action) =>
    createAdminSession(action.emailAddress, action.password, action.authType),
  ),
  createEpic<boolean>(AuthActionType.AUTH_FORGOTTEN_PASSWORD, (action) => forgottenPassword(action.emailAddress)),
  createEpic<boolean>(AuthActionType.AUTH_NEW_ACCOUNT_PASSWORD, (action) =>
    newAccountPassword(action.token, action.newPassword),
  ),
  createEpic<boolean>(AuthActionType.AUTH_RESEND_LINK, (action) => adminNewActivationEmail(action.token)),
  createEpic<boolean>(AuthActionType.AUTH_RESET_PASSWORD, (action) => resetPassword(action.passwordObj)),
]

import React from "react"
import { Snackbar, Alert as MuiAlert } from "@mui/material"
import { AlertProps } from "@mui/material/Alert"

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />
})

export interface AppToastState {
  message?: string
  serverity: "success" | "error" | "warning" | "info"
}

interface AppToastProps {
  appToast?: AppToastState
  toastClose: () => void
}

export const AppToast = (props: AppToastProps) => {
  const { appToast, toastClose } = props

  return (
    <Snackbar
      open={appToast !== undefined}
      autoHideDuration={400000}
      onClose={toastClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
    >
      {appToast && (
        <Alert onClose={toastClose} severity={appToast.serverity} sx={{ width: "100%" }}>
          {appToast.message}
        </Alert>
      )}
    </Snackbar>
  )
}

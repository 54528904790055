/* eslint-disable react/no-is-mounted */
import moment from "moment"
import "moment/locale/de"

type DateTypes = string | number | moment.Moment | Date

class DateUtils {
  static getTimeStamp(date: string) {
    return moment(date ? date : "1970-01-01").unix() * 1000
  }

  static getDeFormatDate(date: DateTypes, formatType?: string) {
    return moment(date)
      .locale("de")
      .format(formatType || "YYYY-MM-DD")
  }

  static getDeFormatDateWithMonthString(date: DateTypes) {
    return moment(date).locale("de").format("DD. MMM YYYY")
  }

  static getDeFormatWithDots(date: DateTypes) {
    return moment(date).locale("de").format("DD.MM.YYYY")
  }

  static getNow(): string {
    return moment().locale("de").format("YYYY-MM-DD")
  }

  static getNowTimestamp() {
    return moment().unix() * 1000
  }

  static getYear(date: DateTypes) {
    return moment(date).year()
  }

  static getMonth(date: DateTypes) {
    return moment(date).month()
  }

  static getWeek(date: DateTypes) {
    return moment(date).week()
  }

  static getDay(date: DateTypes) {
    return moment(date).date()
  }

  static addDays(date: string, amount: number) {
    return this.getDeFormatDate(moment(date).add(amount, "d"))
  }

  static addDateDays(date: string, amount: number) {
    return moment(date).add(amount, "d").toDate()
  }

  static substructDateDays(date: string, amount: number) {
    return moment(date).subtract(amount, "d").toDate()
  }

  static getWeekDay(date: DateTypes) {
    return moment(date).locale("de").format("YYYY-MM-DD")
  }

  static getCurrentWeek(): number {
    return moment().week()
  }

  static getFormattedDay(date: DateTypes) {
    return this.getDeFormatDate(moment(date))
  }

  static getFirstDayOfWeek(date: number): string {
    const week = DateUtils.getWeek(date)
    return this.getDeFormatDate(moment(date).week(week).startOf("week"))
  }

  static getLastDayOfWeek(date: number): string {
    const week = DateUtils.getWeek(date)
    return this.getDeFormatDate(moment(date).week(week).endOf("week"))
  }

  static getFirstDayOfMonth(date: number): string {
    const month = DateUtils.getMonth(date)
    return this.getDeFormatDate(moment(date).month(month).startOf("month"))
  }

  static getLastDayOfMonth(date: number): string {
    const month = DateUtils.getMonth(date)
    return this.getDeFormatDate(moment(date).month(month).endOf("month"))
  }

  static getFirstDayOfYear(date: number): string {
    const year = DateUtils.getYear(date)
    return this.getDeFormatDate(moment().year(year).startOf("year"))
  }

  static getLastDayOfYear(date: number): string {
    const year = DateUtils.getYear(date)
    return this.getDeFormatDate(moment(date).year(year).endOf("year"))
  }

  static getDaysInMonth(date: string) {
    return moment(date ?? new Date()).daysInMonth()
  }

  static getPreviousMonths(dateString: string) {
    const date = new Date(dateString)
    const currentMonth = date.getMonth()
    const previousMonths = []

    for (let i = 0; i < currentMonth; i++) {
      const monthDate = DateUtils.getDeFormatDate(new Date(date.getFullYear(), i, 1))
      previousMonths.push(monthDate)
    }

    return previousMonths
  }

  static addMonthsBetweenDates(startDate: DateTypes, endDate: DateTypes) {
    const start = moment(startDate)
    const end = moment(endDate)
    const months = []

    while (!DateUtils.isSameMonth(start, end) && start.isBefore(end)) {
      months.push(start.format("YYYY-MM-DD"))
      start.add(1, "month")
    }

    return months
  }

  static isSameMonth(date1: DateTypes, date2: DateTypes) {
    const d1 = moment(date1)
    const d2 = moment(date2)

    return d1.month() === d2.month() && d1.year() === d2.year()
  }

  static addMonths(date: DateTypes, amount: number) {
    return DateUtils.getDeFormatDate(moment(date).add(amount, "M"))
  }

  static getFirstDayOfNetYear(date: DateTypes) {
    return DateUtils.getDeFormatDate(moment(date).add(1, "y").startOf("year"))
  }
}

export default DateUtils

import * as yup from "yup"
import { Medium, MeterAttachmentType } from "../../../../../data/generated-sources/openapi"
import { getTranslateValue } from "../../../../Shared.Utils"
import { buildingMetersUsageTypeValues, meterMediumTypeValues, usageTypeValues } from "../Meters.Model"

export const meterCreateSchema = yup.object().shape({
  meteringCode: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.meterIdSpec"),
      }),
    ),
  medium: yup
    .mixed()
    .oneOf(meterMediumTypeValues)
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.medium"),
      }),
    ),
})

export const meterUpdateSchema = yup.object().shape({
  meteringCode: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.meterIdSpec"),
      }),
    ),
  medium: yup
    .mixed()
    .oneOf(meterMediumTypeValues)
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.medium"),
      }),
    ),
  billableFrom: yup.date().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("meters:label.billableFrom"),
    }),
  ),
  powerMeterType: yup.string().when("medium", {
    is: (medium: Medium, meterAttachmentType: MeterAttachmentType) => {
      return medium === Medium.ELECTRICITY && meterAttachmentType === MeterAttachmentType.UTILITY_UNIT
    },
    then: yup.string().required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.meterDesign"),
      }),
    ),
  }),
  pricePackageId: yup.string().when("medium", {
    is: (medium: Medium, meterAttachmentType: MeterAttachmentType) => {
      return medium === Medium.ELECTRICITY && meterAttachmentType === MeterAttachmentType.UTILITY_UNIT
    },
    then: yup.string().required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.price"),
      }),
    ),
  }),
  usageType: yup.mixed().oneOf(usageTypeValues).optional(),
})

export const buildingMeterCreateSchema = yup.object().shape({
  meteringCode: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.meterIdSpec"),
      }),
    ),
  medium: yup
    .mixed()
    .oneOf(meterMediumTypeValues)
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.medium"),
      }),
    ),
  usageType: yup
    .mixed()
    .oneOf(buildingMetersUsageTypeValues)
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("meters:label.usageType"),
      }),
    ),
})

import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"

export interface UIState {
  afterMoveOutStep: boolean
}

const initialState: UIState = {
  afterMoveOutStep: false,
}

export const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setMoveInParticipantDialog: (state, { payload }: PayloadAction<boolean>) => {
      state.afterMoveOutStep = payload
    },
  },
})

export const { setMoveInParticipantDialog } = uiSlice.actions

const uiState = (state: RootState) => state.adminPortal.ui
export const selectShowMoveInParticipantDialog = createSelector(uiState, (state) => state.afterMoveOutStep)

export default uiSlice.reducer

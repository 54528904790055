import { apiHeaders, fileAttachmentDownload, fileAttachmentName } from "../../../../Domain.Calls"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import { PagedVewaBillsResponse } from "../../../../../data/generated-sources/openapi"
import { AxiosResponseHeaders } from "axios"

export const adminGetVewaParticipantBillingBills = async (
  billingId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<PagedVewaBillsResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminBillsApi.adminGetVewaParticipantBillingBills(
    billingId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminVewaParticipantBillsDownloadCsv = async (billId: string): Promise<void> => {
  const csv = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminVewaParticipantBillingCsv(
    billId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return fileAttachmentDownload(fileAttachmentName(csv.headers as AxiosResponseHeaders), new Blob([csv.data]))
}

export const downloadAllParticipantBillingPdfs = async (billingId: string) => {
  const pdf = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminAllParticipantBillingPdfs(billingId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  return fileAttachmentDownload(fileAttachmentName(pdf.headers as AxiosResponseHeaders), new Blob([pdf.data]))
}

export const downloadBillPdf = async (billId: string) => {
  const response = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminBillPdf(billId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

export const downloadAllParticipantBillingCsv = async (billId: string) => {
  const csv = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminAllParticipantBillingCsv(billId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  return fileAttachmentDownload(fileAttachmentName(csv.headers as AxiosResponseHeaders), new Blob([csv.data]))
}

export const updateAdminBillPaidOrUnpaid = async (billingId: string, paidOrUnpaid: "paid" | "unpaid") =>
  DOMAIN_DEPENDENCIES.adminBillsApi.updateAdminBillPaidOrUnpaid(
    billingId,
    paidOrUnpaid,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const downloadVewaBillPdf = async (vewaAllParticipantBillingId: string, participantId: string) => {
  const response = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminVewaParticipantBillPdf(
    vewaAllParticipantBillingId,
    participantId,
    {
      responseType: "blob",
      headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
    },
  )

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

export const adminDownloadAllVewaBillPdf = async (vewaAllParticipantBillingId: string) => {
  const response = await DOMAIN_DEPENDENCIES.adminBillsApi.getAdminAllVewaBillPdfs(vewaAllParticipantBillingId, {
    responseType: "blob",
    headers: { ...apiHeaders(DOMAIN_DEPENDENCIES).headers, accept: "*/*" },
  })

  fileAttachmentDownload(fileAttachmentName(response.headers as AxiosResponseHeaders), new Blob([response.data]))
}

import { useMemo } from "react"
import { Grid } from "@mui/material"

import { appThemeGrey10, appThemeGrey40, appThemeGrey50, appThemeGrey100 } from "../../app/Shared.Theme"
import { SmallPrimaryMinusButton } from "../button/PrimaryMinusButton"
import { SmallPrimaryPlusButton } from "../button/PrimaryPlusButton"
import { InlineDatePicker } from "./InlineDatePicker"

interface DateWeekSelectorViewProps {
  selectedDateValue: number | string
  onDateChanged: (newDateTime: number) => void
  disabled?: boolean
}

export const DateWeekSelectorView = (props: DateWeekSelectorViewProps) => {
  const { selectedDateValue, onDateChanged, disabled } = props
  const selectedDate: Date = useMemo(() => new Date(selectedDateValue), [selectedDateValue])
  const disabledPlusButton = useMemo(
    () => new Date(selectedDateValue).getTime() > new Date().getTime() - 1,
    [selectedDateValue],
  )
  const buttonBackground = [appThemeGrey40, appThemeGrey50]
  const selectorStyle = { background: appThemeGrey10, padding: 8, borderRadius: 3 }
  const dateOutputStyle = { flex: 1, minWidth: 180, justifyContent: "center", alignItems: "center", display: "flex" }
  return (
    <>
      <Grid style={selectorStyle} container>
        <SmallPrimaryMinusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabled}
          onClick={() => onDateChanged(selectedDate.setDate(selectedDate.getDate() - 7))}
        />
        <output style={dateOutputStyle}>
          <InlineDatePicker
            pickerType="week"
            selectedDate={selectedDate}
            onChange={(date) => {
              if (date) onDateChanged(date.getTime())
            }}
          />
        </output>
        <SmallPrimaryPlusButton
          color={appThemeGrey100}
          customSize={30}
          background={buttonBackground}
          disabled={disabledPlusButton || disabled}
          onClick={() => onDateChanged(selectedDate.setDate(selectedDate.getDate() + 7))}
        />
      </Grid>
    </>
  )
}

import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Stack, Typography } from "@mui/material"

import { ElectricityPricePackageForm } from "../electricityPrices/ElectricityPricePackageForm"
import { ElectricityPricePackageView } from "../electricityPrices/ElectricityPricePackageView"
import { ZevPricePackage } from "../../../../data/generated-sources/openapi"

export interface ZevBillingPricePackagesProps {
  billingId: string
  isUpdatingPrices: boolean
  pricePackages: ZevPricePackage[]
  onPriceUpdate: (updateData: ZevPricePackage) => void
}

export const ZevBillingPricePackages = ({
  pricePackages,
  isUpdatingPrices,
  onPriceUpdate,
}: ZevBillingPricePackagesProps) => {
  const { t } = useTranslation("settlements")
  const [editMode, setEditMode] = useState<number | null>(null)

  const closeEditMode = () => setEditMode(null)

  return (
    <>
      <Typography fontSize={23} mb={4}>
        {t("billingEdit.subtitle.change.prices")}
      </Typography>
      <Stack flexDirection={"row"} alignItems={"top"} justifyContent={"flex-start"} flexWrap={"wrap"}>
        {(pricePackages || []).map((pricePackage) => (
          <Box key={pricePackage.id} p={1} mr={3}>
            {editMode === pricePackage.id ? (
              <ElectricityPricePackageForm
                pricePackage={{ ...pricePackage, hasSpikePrice: !!pricePackage.prices?.spikePrice }}
                onEditCancel={closeEditMode}
                onCloseNewPackage={closeEditMode}
                onUpdatePricePackage={onPriceUpdate}
                isModifying={isUpdatingPrices}
                onCreatePricePackage={() => ({})}
                onDeletePackageConfirm={() => ({})}
              />
            ) : (
              <ElectricityPricePackageView pricePackage={pricePackage} onSetEditMode={(id) => setEditMode(id)} />
            )}
          </Box>
        ))}
      </Stack>
    </>
  )
}

import { connect } from "react-redux"
import { AppState } from "../../App.Reducer"
import { AuthResetPasswordComponent } from "./AuthResetPassword.Component"

const mapStateToProps = (state: AppState) => {
  return {
    ...state.adminPortal.authResetPassword,
  }
}

export default connect(mapStateToProps)(AuthResetPasswordComponent)

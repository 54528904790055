import { CircularProgress, IconButton, Stack, Typography } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType, isAdminPortal } from "../../../../Shared.Utils"
import {
  customerAddTariffScheduleToContract,
  customerGetAllTariffSchedules,
  customerGetTariffScheduleByContractId,
} from "../../../../../domain/portal/manager/tariffs/Tariffs.Repository"
import { TariffTimePeriodText } from "../fragments/TariffTimePeriodText"
import { AbortIcon, CancelRoundIcon, EditIcon } from "../../../../../uikit/Shared.Icon"
import { appThemeGrey200 } from "../../../../Shared.Theme"
import { TariffSectionTitle, TariffSectionWrapper, TariffText } from "../fragments/TariffTypographies"
import { TariffEnergySupplierEdit } from "./TariffEnergySupplierEdit"
import { TariffScheduleResponse } from "../../../../../data/generated-sources/openapi"
import {
  adminAddTariffScheduleToContract,
  adminGetAllTariffSchedules,
  adminGetTariffScheduleForContract,
} from "../../../../../domain/portal/admin/tariffs/Tariffs.Repository"
import { FabButton } from "../../../../../uikit/button"

export const TariffEnergySupplier = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation("tariff")
  const [editMode, setEditMode] = useState<boolean>(false)
  const [selectedTariff, setSelectedTariff] = useState<TariffScheduleResponse>()

  const isAdmin = useMemo(() => isAdminPortal(), [])

  const {
    data: contractTariffData,
    error,
    isError,
    isFetching,
    remove: removeTariff,
    refetch: refetchContractTariff,
  } = useQuery(
    ["getContractTariff"],
    () => (isAdmin ? adminGetTariffScheduleForContract(contractId) : customerGetTariffScheduleByContractId(contractId)),
    {
      enabled: !!contractId,
      onSuccess: setSelectedTariff,
    },
  )

  const {
    data: tariffsArray,
    error: errorTariffs,
    isError: isErrorTariffs,
    isFetching: isFetchingTariffs,
    remove: removeTariffs,
  } = useQuery(["getTariffs"], () => (isAdmin ? adminGetAllTariffSchedules() : customerGetAllTariffSchedules()), {
    enabled: !!editMode,
  })

  const {
    error: updateError,
    mutate: updateTariff,
    isLoading: isUpdatingTariff,
    isError: isUpdatingError,
  } = useMutation(
    ["addTarif"],
    (tariffId: string) =>
      isAdmin
        ? adminAddTariffScheduleToContract(contractId, { tariffId })
        : customerAddTariffScheduleToContract(contractId, { tariffId }),
    {
      onSuccess: () => {
        refetchContractTariff()
        setEditMode(false)
      },
    },
  )

  useEffect(() => {
    return () => {
      removeTariff()
      removeTariffs()
    }
  }, [])

  const handleEditMode = () => {
    setEditMode((prevState) => !prevState)
    contractTariffData && setSelectedTariff(contractTariffData)
  }

  const errorObject = error || errorTariffs || isUpdatingError

  return (
    <>
      <ErrorAlert
        visible={isError || isErrorTariffs || !!updateError}
        message={t(`error-codes:${(errorObject as AxiosErrorDataType)?.response?.data?.code ?? "OTHER"}`)}
      />
      {isAdmin && (
        <Stack flexDirection={"row"} alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h5" fontWeight={300} textTransform={"uppercase"}>
            {t("energySupplier")}
          </Typography>
          <FabButton size="medium" icon={editMode ? <AbortIcon /> : <EditIcon />} onClick={handleEditMode} />
        </Stack>
      )}
      <TariffSectionWrapper editMode={editMode} data-testid="tariffSectionWrapper">
        {isFetching || isFetchingTariffs ? (
          <CircularProgress />
        ) : (
          <>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={3}>
              <Typography fontWeight={700} color={appThemeGrey200}>
                {t("shared:label.generally")}
              </Typography>
              {!isAdmin && (
                <IconButton onClick={handleEditMode} size="small" color="primary" data-testid="tariffEditButton">
                  {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                </IconButton>
              )}
            </Stack>
            <TariffSectionTitle data-testid="tariffEnergySupplierTitle">{t("energySupplier")}</TariffSectionTitle>

            {editMode && selectedTariff ? (
              <TariffEnergySupplierEdit
                tariffs={tariffsArray || []}
                isUpdating={isUpdatingTariff}
                selectedTariff={selectedTariff}
                onSubmit={(tariffId) => updateTariff(tariffId)}
              />
            ) : (
              <>
                <TariffText data-testid="tariffName" mb={3} sx={{ fontWeight: 500 }}>
                  {selectedTariff?.name}
                </TariffText>
                <TariffSectionTitle data-testid="tariffTimesTitle">{t("tariffTimes")}</TariffSectionTitle>
                {selectedTariff && <TariffTimePeriodText {...selectedTariff} />}
              </>
            )}
          </>
        )}
      </TariffSectionWrapper>
    </>
  )
}

import { apiHeaders } from "../../../../Domain.Calls"
import { IServiceBillingRunAdminResponse } from "./BillingsRecurring.Model"
import { formatMoneyLabel } from "../../../../Domain.Formatters"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import DateUtils from "../../../../../services/utils/DateUtils"
import { getTranslateValue } from "../../../../../app/Shared.Utils"
import { ServiceBillingRunCreateAdminRequest } from "../../../../../data/generated-sources/openapi"

export const getAdminServiceBillingRunById = async (billingId: string): Promise<IServiceBillingRunAdminResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingRunById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  const serviceBillings = await Promise.all(
    data.serviceBillingIds.map(async (serviceBillingId) => {
      const { data: serviceBilling } = await DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(
        serviceBillingId,
        apiHeaders(DOMAIN_DEPENDENCIES),
      )
      const { data: contract } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractById(
        serviceBilling.contractId,
        apiHeaders(DOMAIN_DEPENDENCIES),
      )
      return {
        ...serviceBilling,
        contractName: contract?.name,
        formatedTotalAmountDue: formatMoneyLabel(serviceBilling.totalAmountDue),
        accountingStatusTranslated: getTranslateValue(`shared:accounting.status.${serviceBilling.accountingStatus}`),
        datePeriod: `${DateUtils.getDeFormatDateWithMonthString(
          serviceBilling?.startDate,
        )} - ${DateUtils.getDeFormatDateWithMonthString(serviceBilling?.endDate)}`,
      }
    }),
  )

  const billingDatePeriod = `${DateUtils.getDeFormatDateWithMonthString(
    data?.startDate,
  )} - ${DateUtils.getDeFormatDateWithMonthString(data?.endDate)}`

  return { ...data, billingDatePeriod, serviceBillings }
}

export const createAdminServiceBillingRun = async (
  serviceBillingRunCreateAdminRequest: ServiceBillingRunCreateAdminRequest,
): Promise<string> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminServiceBillingsApi.createAdminServiceBillingRun(
    serviceBillingRunCreateAdminRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.split("/").pop() ?? ""
}

export const approveAdminServiceBillingRunById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.approveAdminServiceBillingRunById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sapSendAdminServiceBillingRunById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.sapSendAdminServiceBillingRunById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sapSendAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.sapSendAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const recalculateAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.recalculateAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminChangeServiceBillingState = async (
  billingId: string,
  approveOrCancelAction: "approve" | "cancel",
) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(
    billingId,
    approveOrCancelAction,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deleteAdminServiceBillingRunById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.deleteAdminServiceBillingRunById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const removeAdminServiceBillingRunContractById = async (billingId: string, contractId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.removeAdminServiceBillingRunContractById(
    billingId,
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"

import { UsersNewIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton } from "./fragments/BreadcrumbsFragments"

export const SettingsBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("settings")
  const { history } = props
  return (
    <Switch>
      <Route path="/settings/reset-password">
        <UsersNewIcon />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton label={t("detail.title")} onClick={() => history.push("/settings")} />
        <BreadcrumbsNavButton disabled id="breadcrumb-1" label={t("form.title")} />
      </Route>
      <Route path="/settings">
        <UsersNewIcon />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled id="breadcrumb-0" label={t("detail.title")} />
      </Route>
    </Switch>
  )
}

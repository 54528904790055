import { styled, Typography } from "@mui/material"
import { ContractAdminResponse, UtilityUnitResponse } from "../../../../../data/generated-sources/openapi"
import { AxiosResponse } from "axios"
import { GetUtilityUnitById } from "../CustomGetHooks"
import { getExactParamFromUrl, getTranslateValue } from "../../../../Shared.Utils"
import { AdminPortalRouteEnums } from "../../AdminPortal.Enums"
import { COMPONENTS_CONFIG } from "../../sharedComponentsConfig/Components.Config"
import SharedListComponent from "../../sharedListComponet/SharedList.Component"

import RedirectButton from "../../../../../uikit/button/RedirectButton"
import { UtilityUnitMasterDataButtons } from "../../utilityUnit/views/UtilityUnitMasterDataButtons"
import { UtilityUnitParticipationsTable } from "../../utilityUnit/UtilityUnitParticipationsTable"
import { UseQueryResult } from "@tanstack/react-query"
import { history } from "../../../../App.Reducer"
import UtilityUnitAnalise from "../../utilityUnit/views/UtilityUnitAnalise"

const UtilityNameTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  padding: theme.spacing(1),
  fontSize: theme.spacing(4.5),
}))

export const UTILITY_UNIT_MASTER_DATA_CONFIG = {
  utilityUnitDetails: {
    name: "utilityUnitDetails",
    componentName: "utilityUnitMasterData",
    useQueryHook: (paramId: string) => GetUtilityUnitById({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: "",
          getValue: (response: AxiosResponse<UtilityUnitResponse>) => response?.data?.activeState,
        },
        secondColumn: {
          label: "",
          getComponent: (response: AxiosResponse<UtilityUnitResponse>) => (
            <RedirectButton redirectUrl={`/utility-units/${response.data?.id}/update`} />
          ),
        },
      },
      {
        firstColumn: {
          label: "",
          getComponent: ({ data }: AxiosResponse<UtilityUnitResponse>) => (
            <UtilityNameTypography>{data?.name}</UtilityNameTypography>
          ),
        },
      },
      {
        firstColumn: {
          label: "utilityUnit:label.type",
          getValue: ({ data }: AxiosResponse<UtilityUnitResponse>) => (
            <>{getTranslateValue(`utilityUnit:utilityType.${data?.usageType}`)}</>
          ),
        },
      },
      {
        firstColumn: {
          label: "utilityUnit:label.unitSpace",
          getValue: ({ data }: AxiosResponse<UtilityUnitResponse>) =>
            data?.unitSpace && !isNaN(data?.unitSpace) ? String(data?.unitSpace?.toFixed(2)) : "-",
        },
      },
      {
        firstColumn: {
          label: "",
        },
        row: {
          label: "",
          getComponentRow: (response: UseQueryResult<UtilityUnitResponse>) => (
            <UtilityUnitMasterDataButtons
              {...(response?.data as UtilityUnitResponse)}
              onSuccessDeactivated={() => (response as UseQueryResult).refetch()}
              onSuccessDelete={() => history.push(`/buildings/${response?.data?.buildingId}`)}
            />
          ),
        },
      },
    ],
    childrenWithResponse: (response: AxiosResponse<UtilityUnitResponse & { contract: ContractAdminResponse }>) => (
      <>
        <UtilityUnitParticipationsTable utilityUnit={response.data} />
        <SharedListComponent
          configNew={COMPONENTS_CONFIG.utilityUnitMeters}
          getParamFromUrl={() => getExactParamFromUrl("/utility-units/:id", AdminPortalRouteEnums.id)}
        />
        <UtilityUnitAnalise
          utilityUnitId={response.data?.id}
          productType={response.data?.contract?.product?.productType}
        />
      </>
    ),
  },
}

import { PrimaryTab, PrimaryTabContainer } from "../../../../../../uikit/tabs/PrimaryTab"
import { useEffect, useMemo, useState } from "react"
import { Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../Shared.Utils"

import { BillingScope } from "../../../../../../data/generated-sources/openapi"
import { getVewaCostSharesByContractId } from "../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { groupCostShares } from "../../../../../../domain/portal/admin/mediu/MediumVewaCostShares.Mapperts"
import { MiddleCircularProgress } from "../../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { VewaCostSharesDataBox } from "../../../../shared/vewaCostShares/VewaCostSharesDataBox"
import { VewaCostSharesData } from "../../../../shared/vewaCostShares/VewaCostSharesData"

export const VewaCostSharesTabs = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation("medium")
  const [error, setError] = useState<AxiosErrorDataType>()
  const [selectedTab, setSelectedTab] = useState<BillingScope>(BillingScope.HEATING_BILL)

  const {
    data: vewaCostSharesData,
    isFetching,
    remove: removeCostShares,
  } = useQuery(["getCostShares"], () => getVewaCostSharesByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setError,
  })

  const groupedCostShares = useMemo(() => groupCostShares(vewaCostSharesData?.costShares), [vewaCostSharesData])

  useEffect(() => {
    return () => {
      removeCostShares()
    }
  }, [removeCostShares])

  useEffect(() => {
    const billingScopes = groupedCostShares.keys()
    const billingScope = billingScopes.next().value
    if (billingScope && billingScope !== selectedTab) {
      setSelectedTab(billingScope)
    }
  }, [groupedCostShares])

  const renderBillingScopeTabs = () => {
    return Array.from(groupedCostShares).map(([billingScope]) => {
      return <PrimaryTab key={billingScope} value={billingScope} label={t(`label.${billingScope}`)}></PrimaryTab>
    })
  }

  if (isFetching) return <MiddleCircularProgress height={300} />

  if (!vewaCostSharesData?.costShares.length) {
    return <Typography p={1}>{t("shared:message.noConfigurationAvailable")}</Typography>
  }

  return (
    <Stack spacing={5}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <PrimaryTabContainer
        value={selectedTab}
        sx={{
          paddingLeft: 0,
          borderBottom: "2px solid #EEEEEE",
        }}
        onChange={(_, newValue) => setSelectedTab(newValue)}
      >
        {renderBillingScopeTabs()}
      </PrimaryTabContainer>
      <VewaCostSharesDataBox billingScope={selectedTab}>
        <VewaCostSharesData vewaCostSharesMap={groupedCostShares.get(selectedTab)} />
      </VewaCostSharesDataBox>
    </Stack>
  )
}

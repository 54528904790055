import { useEffect, useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { Dialog, DialogContent, DialogActions, DialogTitle, DialogContentText, Button } from "@mui/material"

import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { CancelButton } from "../../../../../uikit/button/CancelButton"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { validationError } from "../../../../Shared.Validation"
import { PrimaryButton } from "../../../../../uikit/button"
import { moveoutUtilityUnitParticipationVacancy } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"

import DateUtils from "../../../../../services/utils/DateUtils"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"
import { useAppDispatch } from "../../../../hooks"
import { setMoveInParticipantDialog } from "../../store/uiSlice"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

interface UtilityUnitMoveOutDialogProps {
  onMoveOutSuccess: () => void
  participation: UtilityUnitParticipationParticipant
}

export const UtilityUnitMoveOutDialog = ({ participation, onMoveOutSuccess }: UtilityUnitMoveOutDialogProps) => {
  const dispatch = useAppDispatch()
  const { t } = useTranslation("utilityUnitParticipations")
  const { utilityUnitId, id: participationId, moveInDate } = participation
  const [showDialog, setShowDialog] = useState(false)
  const [moveOutError, setMoveOutError] = useState<AxiosErrorDataType>()

  const currentTime = new Date().getTime()
  const minMoveOutDate = DateUtils.getTimeStamp(DateUtils.addDays(moveInDate ?? "", 1))
  const initialMoveOutDate = minMoveOutDate > currentTime ? minMoveOutDate : currentTime

  const closeDialog = () => setShowDialog(false)

  const handleMoveOutSuccess = () => {
    dispatch(setMoveInParticipantDialog(true))
    onMoveOutSuccess()
    closeDialog()
  }

  const { mutate: moveOutVacancy, isLoading: isMovingOutVacancy } = useMutation(
    (moveOutDate: string) => moveoutUtilityUnitParticipationVacancy(utilityUnitId, participationId, moveOutDate),
    {
      onSuccess: handleMoveOutSuccess,
      onError: setMoveOutError,
    },
  )

  const handleMoveOutSubmit = ({ moveOutDate }: { moveOutDate: number }) => {
    moveOutVacancy(DateUtils.getDeFormatDate(moveOutDate))
  }

  useEffect(() => {
    return () => setMoveOutError(undefined)
  }, [])

  return (
    <>
      <Button variant="text" onClick={() => setShowDialog(true)} sx={{ textTransform: "none", p: 0 }}>
        {t("list.button.reportMoveOut")}
      </Button>
      <Dialog open={showDialog} onClose={closeDialog} fullWidth maxWidth="sm">
        <DialogTitle>{t("move.form.title")}</DialogTitle>
        <ErrorAlert
          visible={!!moveOutError}
          message={t(`error-codes:${moveOutError?.response?.data?.code || moveOutError?.code || "OTHER"}`)}
        />

        <Formik<{ moveOutDate: number }>
          initialValues={{
            moveOutDate: initialMoveOutDate,
          }}
          onSubmit={handleMoveOutSubmit}
        >
          {({ values, touched, handleBlur, setFieldValue, errors, isValid }) => (
            <Form>
              <DialogContent>
                <DialogContentText pb={3} fontWeight={"bold"}>
                  {t("move.form.subtitle.currentParticipant")}
                </DialogContentText>
                <SingleLineDatePicker
                  name="moveOutDate"
                  label={t("move.form.moveOutDate")}
                  value={values.moveOutDate}
                  minDate={DateUtils.addDateDays(moveInDate ?? "", 1)}
                  helperText={validationError(errors.moveOutDate, touched.moveOutDate)}
                  onChange={(value) => value !== -1 && setFieldValue("moveOutDate", value)}
                  onBlur={handleBlur}
                  textInputStyle={{ width: "60%" }}
                />
              </DialogContent>

              <DialogActions>
                <CancelButton noIcon onClick={closeDialog} />
                <PrimaryButton
                  type="submit"
                  disabled={!isValid}
                  isLoading={isMovingOutVacancy}
                  label={t("shared:form.action.save")}
                />
              </DialogActions>
            </Form>
          )}
        </Formik>
      </Dialog>
    </>
  )
}

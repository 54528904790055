import { Stack, styled } from "@mui/material"
import ConsumptionOverviewBox from "../../../shared/analiyse/charts/ConsumptionOverviewBox"
import dashboardChartBackground from "url:../../../../../../res/dashboardChartBackground.jpg"
import { DashboardVewaOverview } from "../../../../../domain/portal/manager/dashboard/Dashboard.Model"
import { vewaTotalConsumptionDataMapper } from "../../../shared/analiyse/charts/ConsumptionOverviewMapper"

const ZevDashboardImage = styled("div")({
  flexShrink: 0,
  width: "180px",
  height: "200px",
  padding: "44px 0px 44px 422px",
  background: `url('${dashboardChartBackground}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
})

const ConsumptionBox = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  width: "100%",
  height: "240px",
  background: "#f7f9f7",
  borderTopRightRadius: theme.spacing(1),
  borderBottomRightRadius: theme.spacing(1),
  paddingRight: theme.spacing(6),
  marginLeft: theme.spacing(-20),
  [theme.breakpoints.down("lg")]: {
    marginLeft: theme.spacing(2),
    background: "transparent",
  },
}))

export const DashboardVewaChartView = (props: DashboardVewaOverview) => {
  const consumptionData = vewaTotalConsumptionDataMapper(props)

  return (
    <Stack flexDirection={{ md: "column", lg: "row" }} alignItems={"center"}>
      <ZevDashboardImage />
      <ConsumptionBox>
        <ConsumptionOverviewBox
          data={consumptionData}
          boxSize={"120px"}
          containerWidth={"100%"}
          justifyStyle="flex-start"
        />
      </ConsumptionBox>
    </Stack>
  )
}

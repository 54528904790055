import { useEffect } from "react"
import { Grid, List, ListItem, ListItemText, Paper, Stack } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { RedirectButton } from "../../../../../uikit/button"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"
import { ProfileManagerNameListItem } from "../../../../../domain/portal/admin/profiles/Profiles.Model"
import { useQuery } from "@tanstack/react-query"
import { getManagersByContractId } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { useQueryDefaultOptions } from "../../../../Shared.Utils"
import { appThemePrimaryLighterColor } from "../../../../Shared.Theme"

const ManagerListComponent = () => {
  const { t } = useTranslation("contracts-management")
  const { id } = useParams<{ id: string }>()
  const history = useHistory()
  const { isError, data, remove } = useQuery(["adminGetManagersByContractId"], () => getManagersByContractId(id), {
    enabled: !!id,
    ...useQueryDefaultOptions,
  })

  useEffect(() => remove, [remove])

  const renderManagerList = () => {
    if (!data?.length) return <ListItem>{t("shared:label.empty.list")}</ListItem>

    return data?.map((item: ProfileManagerNameListItem, index: number) => {
      return (
        <ListItem
          key={index}
          sx={{
            borderBottom: "1px solid #E5E5E5",
            padding: "15px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: appThemePrimaryLighterColor,
            },
          }}
          onClick={() => history.push(`/management-contracts/${id}/profiles/${item.id}`)}
        >
          <ListItemText primary={item.fullNameAddress} />
        </ListItem>
      )
    })
  }

  return (
    <Paper>
      <Stack flexDirection={"row"} justifyContent={"space-between"} alignItems={"center"}>
        <FormSectionTitle label={t("header.title")} />
        <RedirectButton redirectUrl={`/management-contracts/${id}/managers/update`} />
      </Stack>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <>
            {isError ? (
              <ErrorAlert message={t("error-codes:OTHER")} visible={isError} />
            ) : (
              <List dense={true}>{renderManagerList()}</List>
            )}
          </>
        </Grid>
      </Grid>
    </Paper>
  )
}

export default ManagerListComponent

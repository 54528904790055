import { AREA_API_CONFIG, AREA_LIST_COMPONENT_CONFIG } from "./configs/Area.Config"
import {
  PARTICIPANT_PERIODIC_BILLING_API_CONFIG,
  PARTICIPANT_PERIODIC_BILLING_LIST_COMPONENT_CONFIG,
} from "./configs/ParticipantPeriodicBilling.Config"
import { METERS_API_CONFIG, METERS_LIST_COMPONENT_CONFIG } from "./configs/Meters.Config"
import {
  SERVICE_INITIAL_BILLING_API_CONFIG,
  SERVICE_INITIAL_BILLING_LIST_COMPONENT_CONFIG,
} from "./configs/ServiceInitialBilling.Config"
import { UTILITY_UNIT_API_CONFIG, UTILITY_UNIT_LIST_COMPONENT_CONFIG } from "./configs/UtilityUnits.Config"
import { BUILDING_DETAILS_API_CONFIG, BUILDING_DETAILS_CONFIG } from "./configs/BuildingDetails.Config"
import { PROFILE_DETAILS_API_CONFIG, PROFILE_DETAILS_CONFIG } from "./configs/ProfileDetail.Config"
import { AREA_CONTRACTS_API_CONFIG, AREA_CONTRACTS_LIST_COMPONENT_CONFIG } from "./configs/AreaContracts.Config"
import { AREA_BUILDINGS_API_CONFIG, AREA_BUILDINGS_LIST_COMPONENT_CONFIG } from "./configs/AreaBuildings.Config"
import {
  PARTICIPANT_INDIVIDUAL_BILLING_API_CONFIG,
  PARTICIPANT_INDIVIDUAL_BILLING_LIST_COMPONENT_CONFIG,
} from "./configs/ParticipantIndividualBilling.Config"
import {
  SERVICE_RECURRING_BILLING_API_CONFIG,
  SERVICE_RECURRING_BILLING_LIST_COMPONENT_CONFIG,
} from "./configs/ServiceRecurringBilling.Config"
import {
  UTILITY_UNIT_METERS_API_CONFIG,
  UTILITY_UNIT_METERS_LIST_COMPONENT_CONFIG,
} from "./configs/UtilityUnitMeters.Config"
import { CONTRACT_PARTICIPANTS_API_CONFIG, CONTRACT_PARTICIPANTS_CONFIG } from "./configs/ContractParticipants.Config"

export const COMPONENTS_CONFIG = {
  ...AREA_LIST_COMPONENT_CONFIG,
  ...AREA_CONTRACTS_LIST_COMPONENT_CONFIG,
  ...AREA_BUILDINGS_LIST_COMPONENT_CONFIG,
  ...METERS_LIST_COMPONENT_CONFIG,
  ...UTILITY_UNIT_LIST_COMPONENT_CONFIG,
  ...BUILDING_DETAILS_CONFIG,
  ...CONTRACT_PARTICIPANTS_CONFIG,
  ...PARTICIPANT_INDIVIDUAL_BILLING_LIST_COMPONENT_CONFIG,
  ...PARTICIPANT_PERIODIC_BILLING_LIST_COMPONENT_CONFIG,
  ...PROFILE_DETAILS_CONFIG,
  ...SERVICE_INITIAL_BILLING_LIST_COMPONENT_CONFIG,
  ...SERVICE_RECURRING_BILLING_LIST_COMPONENT_CONFIG,
  ...UTILITY_UNIT_METERS_LIST_COMPONENT_CONFIG,
}

export const COMPONENTS_API_CONFIG = {
  ...AREA_API_CONFIG,
  ...AREA_CONTRACTS_API_CONFIG,
  ...AREA_BUILDINGS_API_CONFIG,
  ...METERS_API_CONFIG,
  ...UTILITY_UNIT_API_CONFIG,
  ...BUILDING_DETAILS_API_CONFIG,
  ...CONTRACT_PARTICIPANTS_API_CONFIG,
  ...PARTICIPANT_PERIODIC_BILLING_API_CONFIG,
  ...PARTICIPANT_INDIVIDUAL_BILLING_API_CONFIG,
  ...SERVICE_INITIAL_BILLING_API_CONFIG,
  ...SERVICE_RECURRING_BILLING_API_CONFIG,
  ...PROFILE_DETAILS_API_CONFIG,
  ...UTILITY_UNIT_METERS_API_CONFIG,
}

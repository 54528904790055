import { Typography } from "@mui/material"
import { getTranslateValue } from "../../../app/Shared.Utils"
import { GridPagination, GridRenderCellParams } from "@mui/x-data-grid"

export const EmptyTableRow = () => (
  <Typography color="inherit" p={1}>
    {getTranslateValue("shared:label.empty.list")}
  </Typography>
)

export const LightCell = ({ value }: GridRenderCellParams) => <Typography fontWeight={400}>{value}</Typography>

export const DefaultCell = ({ value }: GridRenderCellParams) => (
  <Typography fontWeight={500} fontSize={14}>
    {value}
  </Typography>
)

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const CustomPagination = (props: any) => <GridPagination {...props} labelRowsPerPage="Zeilen pro Seite:" />

import { useEffect, useState } from "react"
import { GridRowId } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { useMutation, useQuery } from "@tanstack/react-query"
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, IconButton } from "@mui/material"
import { MeterResponse, ZevPricePackage } from "../../../../../../data/generated-sources/openapi"
import {
  customerGetMetersForUtilityUnit,
  updateMetersPricePackage,
} from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { AbortIcon } from "../../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { PrimaryButton } from "../../../../../../uikit/button"
import { DataGridTable, DataGridRedirectRowTable, DefaultCell } from "../../../../../../uikit/dataGridTable"
import { customerGetPricePackagesByContractId } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../Shared.Utils"
import { FullScreenLoader } from "../../../../../../uikit/indicator/ProgressIndicator"

interface UtilityUnitMetersTablePropsInterface {
  utilityUnitId: string
  contractId: string | undefined
}

export const UtilityUnitMetersTable = ({ utilityUnitId, contractId }: UtilityUnitMetersTablePropsInterface) => {
  const { t } = useTranslation("meters")
  const [showPriceEdit, setShowPriceEdit] = useState(false)
  const [selectedPrice, setSelectedPrice] = useState<GridRowId[]>([])
  const [selectedMeter, setSelectedMeter] = useState<MeterResponse | null>(null)

  const {
    data: meters,
    isFetching,
    isError,
    error,
    remove: removeMeters,
    refetch: refetchMeter,
  } = useQuery(["getMeters"], () => customerGetMetersForUtilityUnit(utilityUnitId), {
    enabled: !!utilityUnitId,
  })

  const {
    data: pricePackagesList,
    remove: removePricePackages,
    isError: isPriceError,
    error: priceError,
  } = useQuery(["getPricePackage", contractId], () => customerGetPricePackagesByContractId(contractId ?? ""), {
    ...useQueryDefaultOptions,
    enabled: !!contractId,
  })

  const {
    mutate: updateMeter,
    isLoading: isMeterUpdating,
    isError: isUpdateError,
    error: updateError,
  } = useMutation(["updateMeter"], () => updateMetersPricePackage(selectedMeter?.id ?? "", Number(selectedPrice[0])), {
    onSuccess: () => refetchMeter(),
  })

  useEffect(() => {
    return () => {
      removeMeters()
      removePricePackages()
    }
  }, [removePricePackages, removeMeters])

  const metersColumns: Array<GridBaseColDef> = [
    {
      field: "meteringCode",
      headerName: t("label.meterIdSpec"),
      renderCell: DefaultCell,
      flex: 250,
    },
    {
      field: "medium",
      headerName: t("label.medium"),
      flex: 150,
      valueGetter: (_, row) => t(`label.${row.medium || ""}`),
      renderCell: DefaultCell,
    },
    {
      field: "pricePackageName",
      headerName: t("label.electricityPrice"),
      renderCell: DefaultCell,
      flex: 150,
    },
  ]

  const handleRowClick = (meter: MeterResponse) => {
    setSelectedMeter(meter)
    setSelectedPrice([meter?.pricePackageId ?? 0])
    setShowPriceEdit(true)
  }

  return (
    <Box p={6} flex={1}>
      {isMeterUpdating && <FullScreenLoader />}
      <ErrorAlert
        visible={isError || isUpdateError || isPriceError}
        message={t(`error-codes:${((error || priceError || updateError) as AxiosErrorDataType)?.code ?? "OTHER"}`)}
      />
      <DataGridRedirectRowTable<MeterResponse>
        rows={meters || []}
        columns={metersColumns}
        loading={isFetching}
        redirectDescription={t("label.adjustPrices")}
        onRowClick={({ row }) => handleRowClick(row)}
        defaultPageSize={5}
      />
      <Dialog
        open={showPriceEdit}
        onClose={() => {
          setShowPriceEdit(false)
          setSelectedPrice([])
        }}
        fullWidth
        maxWidth="md"
      >
        <Box>
          <DialogTitle>{t("label.adjustPrices")}</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={() => setShowPriceEdit(false)}
            sx={{
              top: 28,
              right: 35,
              color: "#000",
              position: "absolute",
            }}
          >
            <AbortIcon />
          </IconButton>
        </Box>

        <DialogContent>
          {pricePackagesList?.length && (
            <DataGridTable<ZevPricePackage>
              checkboxSelection={true}
              sx={{
                ".MuiDataGrid-columnHeaderCheckbox > div": {
                  display: "none",
                },
              }}
              onCellClick={({ id }) => {
                if (id === selectedPrice[0]) {
                  setSelectedPrice([])
                } else {
                  setSelectedPrice([id])
                }
              }}
              rowSelectionModel={selectedPrice}
              rows={pricePackagesList}
              columns={[
                {
                  field: "selectedPrice",
                  headerName: t("label.electricityPrice"),
                  flex: 250,
                  valueGetter: (_, row) => row?.name,
                  renderCell: DefaultCell,
                },
              ]}
            />
          )}
        </DialogContent>
        <DialogActions>
          <PrimaryButton
            disabled={!selectedPrice.length}
            onClick={() => {
              if (selectedPrice.length) {
                updateMeter()
                setShowPriceEdit(false)
              }
            }}
            label={t("label.adjustPrices")}
          />
        </DialogActions>
      </Dialog>
    </Box>
  )
}

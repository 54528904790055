import { I18nNamespace } from "../../../App.i18n"

export const ProfileI18n: I18nNamespace = {
  name: "profile",
  de: {
    "profile title": "Mein Profil",
    logout: "Abmelden",

    "subtitle.personal": "Name",
    "subtitle.address": "Adresse",
    "subtitle.contact": "Kontakt",
    "subtitle.email": "E-Mail Adresse",
    "subtitle.password": "Passwort",

    "personal.salutation": "Anrede",
    "personal.fullName": "Name",
    "personal.firstName": "Vorname",
    "personal.lastName": "Nachname",
    "address.city": "Ort",
    "address.street": "Strasse",
    "address.houseNumber": "Hausnummer",
    "address.postalCode": "Postleitzahl",
    "contact.email": "E-Mail",
    "contact.mobile": "Mobile",
    "contact.telephone": "Telefon",

    "email change": "E-Mail ändern",
    "password change": "Passwort ändern",
    "submit update": "Profil speichern",
    "personal update successful": "Ihre Personalien wurden erfolgreich gespeichert",
    "address update successful": "Ihre Addresse wurde erfolgreich gespeichert",
    "contact update successful": "Ihre Kontaktdaten wurden erfolgreich gespeichert",
    "email update successful":
      "Ihre E-Mail Addresse wurde erfolgreich gespeichert. Um sich mit der neuen E-Mail Adresse anmelden zu können, müssen Sie zuerst auf den Bestätigungslink im Ihnen eben zugesandtem E-Mail klicken.",
  },
  en: {
    "profile title": "My profile",
    logout: "Log out",

    "subtitle.personal": "Personal",
    "subtitle.address": "Address",
    "subtitle.contact": "Contact",
    "subtitle.email": "Email",
    "subtitle.password": "Password",

    "personal.salutation": "Salutation",
    "personal.fullName": "Name",
    "personal.firstName": "Name",
    "personal.lastName": "Last name",
    "address.city": "City",
    "address.street": "Street",
    "address.houseNumber": "House number",
    "address.postalCode": "Postal code",
    "contact.email": "Email",
    "contact.mobile": "Mobile",
    "contact.telephone": "Telephone",

    "email change": "Change email",
    "password change": "Change password",
    "submit update": "Save profile",
    "personal update successful": "Your personal data was successfully updated",
    "address update successful": "Your address was successfully updated",
    "contact update successful": "Your contact data was successfully updated",
    "email update successful":
      "Your email was successfully updated. To use the new email, you have to click on a confirmation link that was sent to you.",
  },
}

import { ParticipantPeriodicBillingApiEnum, VewaParticipantPeriodicBillingApiEnum } from "../Components.Enums"
import { ParticipantPeriodicBillingEnum } from "../participantBilling/ParticipantBilling.Enum"
import {
  ParticipantPeriodicBillingComparator,
  VewaParticipantPeriodicBillingComparator,
} from "../participations/ParticipantPeriodicBilling.Comparator"
import ParticipantPeriodicBillingTable from "../participantBilling/ParticipanPeriodicBillingTable.Component"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"
import {
  getAllParticipantsBillingsByContractIdWithNewField,
  getVewaAllParticipantsBillingsByContractIdWithNewField,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { AllParticipantsBillingExtended } from "../../store/billingsSlice"

const ParticipantBilling = {
  name: "participantPeriodicBilling",
  columnEnums: ParticipantPeriodicBillingEnum,
  header: {
    title: "",
    showButton: false,
    showFilter: false,
  },
  apiDefaultParams: {
    page: 1,
    limit: 100000,
    orderBy: "",
  },
  defaultSorting: {
    column: ParticipantPeriodicBillingEnum.PERIOD,
    direction: "desc",
  },
  columnComparator: ParticipantPeriodicBillingComparator,
  getDataFromRepository: getAllParticipantsBillingsByContractIdWithNewField,
  tableHeaders: [
    {
      column: ParticipantPeriodicBillingEnum.PERIOD,
      label: "shared:label.period",
      width: "40%",
      orderable: true,
    },
    {
      column: ParticipantPeriodicBillingEnum.AMOUNT,
      label: "billings-recurring:field.label.totalAmount",
      width: "30%",
      orderable: true,
    },
    {
      column: ParticipantPeriodicBillingEnum.STATUS,
      label: "shared:status.label",
      width: "30%",
      orderable: true,
    },
  ],
  renderTableRows: (item: AllParticipantsBillingExtended) => (
    <ParticipantPeriodicBillingTable itemConfig={item} key={getUniqueId()} isZevProduct />
  ),
  defaultRowsPerPage: 5,
}

export const PARTICIPANT_PERIODIC_BILLING_LIST_COMPONENT_CONFIG = {
  zevParticipantPeriodicBilling: {
    ...ParticipantBilling,
  },
  vewaParticipantPeriodicBilling: {
    ...ParticipantBilling,
    name: "vewaParticipantPeriodicBilling",
    columnComparator: VewaParticipantPeriodicBillingComparator,
    getDataFromRepository: getVewaAllParticipantsBillingsByContractIdWithNewField,
    renderTableRows: (item: AllParticipantsBillingExtended) => (
      <ParticipantPeriodicBillingTable itemConfig={item} key={getUniqueId()} isZevProduct={false} />
    ),
  },
}

export const PARTICIPANT_PERIODIC_BILLING_API_CONFIG = {
  participantPeriodicBilling: ParticipantPeriodicBillingApiEnum,
  vewaParticipantPeriodicBilling: VewaParticipantPeriodicBillingApiEnum,
}

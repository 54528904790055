import { useTranslation } from "react-i18next"
import { FormRowCell, FormView } from "../../../../../uikit/form/FormView"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"

import { PriceCalculatorTariff } from "../../../../../domain/portal/manager/my-zev/MyZev.Model"
import { PriceCalculatorRow } from "../views/PricecalculatorFormRow"
import { NumberFormatView } from "../views/NumberFormatView"
import { ZevPriceCalculatorInput } from "../../../../../data/generated-sources/openapi"

type TariffType = "referencePower" | "purchasingPower"

interface CalculatorTariffFormProps {
  tariffType: TariffType
  tariffName: string
  tariffInfo: string
  zevPriceCalculatorInput: ZevPriceCalculatorInput
  tariffs: PriceCalculatorTariff[]
  zevHasVat: boolean
  dataStatusChanged: (zevPriceCalculatorInput: ZevPriceCalculatorInput) => void
}

export const getTariff = (
  tariffType: TariffType,
  zevPriceCalculatorInput: ZevPriceCalculatorInput,
  tariffs: PriceCalculatorTariff[],
) => {
  return tariffs.find((tariff) => tariff.id === getSelectedTariffId(tariffType, zevPriceCalculatorInput))
}

const getSelectedTariffId = (tariffType: TariffType, zevPriceCalculatorInput: ZevPriceCalculatorInput) => {
  switch (tariffType) {
    case "referencePower":
      return zevPriceCalculatorInput?.referencePowerProductId
    case "purchasingPower":
      return zevPriceCalculatorInput?.purchasingPowerProductId
  }
}

const reducePriceCalculatorInput = (
  tariffId: number,
  tariffType: TariffType,
  zevPriceCalculatorInput: ZevPriceCalculatorInput,
) => {
  switch (tariffType) {
    case "referencePower":
      return {
        ...zevPriceCalculatorInput,
        referencePowerProductId: tariffId,
      }
    case "purchasingPower":
      return {
        ...zevPriceCalculatorInput,
        purchasingPowerProductId: tariffId,
      }
  }
}

export const CalculatorTariffForm = (props: CalculatorTariffFormProps) => {
  const { tariffName, zevHasVat, tariffInfo, tariffType, zevPriceCalculatorInput, tariffs, dataStatusChanged } = props
  const { t } = useTranslation("my-zev")

  return (
    <FormView>
      <FormRowCell>
        <PriceCalculatorRow
          label={tariffName}
          sublabel={
            zevHasVat
              ? `${t("section-main.tab.priceCalculator.prices")}
              ${t("section-main.tab.priceCalculator.vat.included")}`
              : undefined
          }
          info={tariffInfo}
          childField={
            <SelectPicker
              id="tariffs"
              name="tariffs"
              type="text"
              key={tariffName}
              emptyValue="None"
              value={getSelectedTariffId(tariffType, zevPriceCalculatorInput)}
              items={tariffs.map((tariff) => ({
                value: tariff.id,
                label: tariff.name,
              }))}
              onChange={(event) => {
                dataStatusChanged(
                  reducePriceCalculatorInput((event.target.value ?? -1) as number, tariffType, zevPriceCalculatorInput),
                )
              }}
            />
          }
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorRow
          label={t("section-main.tab.priceCalculator.tariff.highTariff")}
          childField={
            <NumberFormatView
              tariffValue={getTariff(tariffType, zevPriceCalculatorInput, tariffs)?.high ?? 0.0}
              suffix="  Rp/kWh"
            />
          }
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorRow
          label={t("section-main.tab.priceCalculator.tariff.lowTariff")}
          childField={
            <NumberFormatView
              tariffValue={getTariff(tariffType, zevPriceCalculatorInput, tariffs)?.low ?? 0.0}
              suffix="  Rp/kWh"
            />
          }
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorRow
          label={t("section-main.tab.priceCalculator.tariff.basicPricePerformance")}
          childField={
            <NumberFormatView
              tariffValue={getTariff(tariffType, zevPriceCalculatorInput, tariffs)?.basePrice ?? 0.0}
              suffix="  CHF/Mt."
            />
          }
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorRow
          label={t("section-main.tab.priceCalculator.tariff.spikePrice")}
          childField={
            <NumberFormatView
              tariffValue={getTariff(tariffType, zevPriceCalculatorInput, tariffs)?.peakPower ?? 0.0}
              suffix="  CHF/kW/Mt."
            />
          }
        />
      </FormRowCell>
    </FormView>
  )
}

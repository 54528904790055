import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"

import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParticipantById } from "../../../../domain/participant/Participant.Repository"
import { adminGetBreadcrumbInfo } from "../../../../domain/portal/admin/breadcrumps/Breadcrumps.Repository"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { AreaIcon, UtilityUnitIcon } from "../../../../uikit/Shared.Icon"

export const UtilityUnitsBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("utilityUnit")
  const utilityUnitUrlParams =
    getParamsFromUrl("/utility-units/:id/participants/:participantId") || getParamsFromUrl("/utility-units/:id") || ""

  const { data, remove } = useQuery(
    ["getUnitBreadcrumb"],
    () => adminGetBreadcrumbInfo({ utilityUnitId: utilityUnitUrlParams.id }),
    {
      enabled: !!utilityUnitUrlParams.id,
    },
  )

  const { data: participantData, remove: removeParticipant } = useQuery(
    ["getParticipant"],
    () => getParticipantById(utilityUnitUrlParams?.participantId),
    {
      enabled: !!utilityUnitUrlParams?.participantId,
    },
  )

  useEffect(() => {
    return () => {
      remove()
      removeParticipant()
    }
  }, [removeParticipant, remove, utilityUnitUrlParams?.id, utilityUnitUrlParams?.participantId])

  const UnitAreaBuildingBreadcrumb = useCallback(
    (props) => (
      <>
        <BreadcrumbsHomeIcon redirectUrl={() => history.push("/area")} icon={<AreaIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton
          label={data?.utilityUnit?.building?.area?.name || ""}
          onClick={() => history.push(`/area/${data?.utilityUnit?.building?.area?.id}`)}
        />
        <BreadcrumbsNavButton
          label={data?.utilityUnit?.building?.name || ""}
          onClick={() => history.push(`/buildings/${data?.utilityUnit?.building?.id}`)}
        />
        <BreadcrumbsNavButton
          disabled={props?.unitLinkDisabled}
          label={data?.utilityUnit?.name || ""}
          onClick={() => history.push(`/utility-units/${data?.utilityUnit?.id}`)}
        />
      </>
    ),
    [data?.utilityUnit, history],
  )

  return (
    <Switch>
      <Route path="/utility-units/:id/participants/:participantId/update">
        <UnitAreaBuildingBreadcrumb />
        <BreadcrumbsNavButton
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
          onClick={() =>
            history.push(
              `/utility-units/${utilityUnitUrlParams.id}/participants/${utilityUnitUrlParams?.participantId}`,
            )
          }
        />
        <BreadcrumbsNavButton disabled label={t("customer-participants:participant.edit")} />
      </Route>

      <Route path="/utility-units/:id/participants/:participantId">
        <UnitAreaBuildingBreadcrumb />
        <BreadcrumbsNavButton
          label={`${participantData?.personalData?.firstName} ${participantData?.personalData?.lastName}`}
          disabled
        />
      </Route>

      <Route path="/utility-units/:utilityUnitId/meter/create" exact>
        <UnitAreaBuildingBreadcrumb />
        <BreadcrumbsNavButton label={t("buildings:breadcrumb.meter.create")} disabled />
      </Route>

      <Route path="/utility-units/:utilityUnitId/update" exact>
        <UnitAreaBuildingBreadcrumb />
        <BreadcrumbsNavButton label={t("breadcrumb.utilityUnit.update")} disabled />
      </Route>

      <Route path="/utility-units/:id" exact>
        <UnitAreaBuildingBreadcrumb unitLinkDisabled />
      </Route>

      <Route path="/utility-units" exact>
        <BreadcrumbsHomeIcon redirectUrl={() => history.push("/utility-units")} icon={<UtilityUnitIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton label={t("label.name")} disabled />
      </Route>
    </Switch>
  )
}

export enum ParticipantPeriodicBillingEnum {
  STATUS = "STATUS",
  PERIOD = "PERIOD",
  AMOUNT = "AMOUNT",
  UNIT = "UNIT",
  PARTICIPANT = "PARTICIPANT",
}
export enum ParticipantIndividualBillingEnum {
  STATUS = "STATUS",
  PERIOD = "PERIOD",
  AMOUNT = "AMOUNT",
  BUILDING = "BUILDING",
  UNIT = "UNIT",
  PARTICIPANT = "PARTICIPANT",
}

export enum ParticipantBillingTab {
  ALL_PARTICIPANTS = 0,
  INDIVIDUAL_PARTICIPANTS = 1,
}

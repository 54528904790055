import { Paper, Stack } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useHistory } from "react-router-dom"
import { ServiceBillingRunCreateAdminRequest } from "../../../../../data/generated-sources/openapi"
import { createAdminServiceBillingRun } from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Repository"
import { IServiceBillingCreate } from "../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

import { PrimaryButton } from "../../../../../uikit/button"
import { PlusIcon } from "../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import DateUtils from "../../../../../services/utils/DateUtils"
import { CreateRecurringBillingsFormFileds } from "./create/CreateRecurringBillingsFormFileds"
import { recurringBilingsSchema } from "./create/CreateBillingValidation.Schema"

export const CreateRecurringBillings = () => {
  const { t } = useTranslation("billings-recurring")
  const history = useHistory()

  const {
    mutate: createBilling,
    isLoading: isCreating,
    isError: isCreateBillingError,
    error: createBillingError,
  } = useMutation(
    ["createBilling"],
    (serviceBillingRunCreate: ServiceBillingRunCreateAdminRequest) =>
      createAdminServiceBillingRun(serviceBillingRunCreate),
    {
      onSuccess: (billingId: string) => history.push(`/billings/recurring/details/${billingId}`),
    },
  )
  const errorMsg = createBillingError as AxiosErrorDataType

  const handleSubmit = (values: IServiceBillingCreate) => {
    const createData: ServiceBillingRunCreateAdminRequest = {
      contractIds: values.contractIds,
      startDate: DateUtils.getDeFormatDate(values.startDate),
      endDate: DateUtils.getDeFormatDate(values.endDate),
    }

    createBilling(createData)
  }

  const firstDayOfYear = new Date(new Date().getFullYear(), 0, 1)
  const halfOfYearDate = new Date(new Date().getFullYear(), 5, 30)

  return (
    <Paper data-testid="createRecurringBillingsPaper">
      <ErrorAlert
        scrollOnDisplay
        visible={isCreateBillingError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <Formik<IServiceBillingCreate>
        validateOnBlur
        validateOnChange
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={recurringBilingsSchema}
        initialValues={{
          contractIds: [],
          startDate: firstDayOfYear.getTime(),
          endDate: halfOfYearDate.getTime(),
          halfOfYear: "first",
        }}
        data-testid="createRecurringBillingsForm"
      >
        {({ isValid, dirty, ...props }) => (
          <Form>
            <CreateRecurringBillingsFormFileds {...props} />

            <Stack direction={"row"} justifyContent={"flex-end"}>
              <PrimaryButton
                type="submit"
                startIcon={<PlusIcon />}
                label={t("form.action.create.cta")}
                isLoading={isCreating}
                disabled={!isValid || !dirty}
              />
            </Stack>
          </Form>
        )}
      </Formik>
    </Paper>
  )
}

import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useCallback, useEffect, useState } from "react"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getPagedContractsByProfileId,
  updateContractsByProfileId,
} from "../../../../domain/portal/admin/profiles/Profiles.Repository"
import { useTranslation } from "react-i18next"
import {
  Autocomplete,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TextField,
} from "@mui/material"
import { ContractAdminResponse } from "../../../../data/generated-sources/openapi"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../Shared.Utils"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { RemoveIcon, SaveIcon } from "../../../../uikit/Shared.Icon"
import { CancelButton } from "../../../../uikit/button/CancelButton"
import { PrimaryButton } from "../../../../uikit/button"
import { ContractFormTitle } from "../contractsManagement/common/CommonFormFragments"
import { getAllContracts } from "../../../../domain/portal/admin/contracts/Contracts.Repository"

const ProfileContractsModify = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { profileId } = params
  const { t } = useTranslation("contracts")
  const [contractsError, setContractsError] = useState<AxiosErrorDataType>()
  const [selectedContracts, setSelectedContracts] = useState<ContractAdminResponse[]>([])

  const { data: allContracts, isLoading: isLoadingAllContracts } = useQuery(
    ["getAllAdminContracts"],
    () => getAllContracts(),
    {
      ...useQueryDefaultOptions,
      onError: setContractsError,
    },
  )

  const {
    isLoading,
    isFetching,
    remove: removeContracts,
  } = useQuery(["getMeter"], () => getPagedContractsByProfileId(profileId), {
    enabled: !!profileId,
    ...useQueryDefaultOptions,
    onError: setContractsError,
    onSuccess: (data) => setSelectedContracts(data),
  })

  const { mutate: updateContractsList, isLoading: isUpdatingContracts } = useMutation(
    ["updateContractsList"],
    (contractIds: string[]) => updateContractsByProfileId(profileId, contractIds),
    {
      onError: setContractsError,
      onSuccess: () => history.goBack(),
    },
  )

  const saveContractList = () => updateContractsList(selectedContracts.map((contract) => contract.id))

  const removeContractFromList = (contractId: string) =>
    setSelectedContracts((prevState) => prevState.filter((contract) => contract.id !== contractId))

  useEffect(() => {
    return () => {
      removeContracts()
    }
  }, [removeContracts])

  const ContractListItem = useCallback(
    ({ id, name }: ContractAdminResponse) => (
      <ListItem
        key={id}
        divider
        secondaryAction={
          <IconButton aria-label="delete-icon" onClick={() => removeContractFromList(id)}>
            <RemoveIcon color="primary" />
          </IconButton>
        }
      >
        <ListItemText>{name}</ListItemText>
      </ListItem>
    ),
    [],
  )

  return (
    <>
      <Paper sx={{ minHeight: "75vh" }}>
        <ErrorAlert
          visible={!!contractsError}
          message={t(`error-codes:${contractsError?.response?.data?.code || contractsError?.code || "OTHER"}`)}
        />
        <ContractFormTitle>{t("table.title")}</ContractFormTitle>
        {(isLoading && isFetching) || isLoadingAllContracts ? (
          <CircularProgress color="primary" />
        ) : (
          <>
            <Autocomplete
              sx={{ px: 1 }}
              clearOnBlur
              multiple
              filterSelectedOptions
              limitTags={0}
              clearIcon={null}
              renderTags={() => <></>}
              options={allContracts?.elements ?? []}
              getOptionLabel={(contract) => contract.name}
              onChange={(_, values, reason, details) => {
                if (reason === "selectOption" && values && details && details?.option) {
                  setSelectedContracts((prevState) => [...prevState, details.option])
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label={t("dropDown.placeholder")}
                  InputProps={{
                    ...params.InputProps,
                    type: "search",
                  }}
                />
              )}
            />
            <List sx={{ px: 1, maxHeight: "60vh", overflow: "scroll" }}>
              {selectedContracts?.map(ContractListItem)}
            </List>
          </>
        )}
      </Paper>
      <Stack direction={"row"} alignItems="center" justifyContent="space-between">
        <CancelButton onClick={() => history.goBack()} />
        <PrimaryButton
          startIcon={<SaveIcon />}
          onClick={saveContractList}
          label={t("form.updateContract")}
          disabled={isUpdatingContracts}
          isLoading={(isLoading && isFetching) || isUpdatingContracts}
        />
      </Stack>
    </>
  )
}
export default ProfileContractsModify

import { AreaCustomerResponse } from "../../data/generated-sources/openapi"

export enum AuthType {
  MANAGER = "MANAGER",
  ADMINISTRATOR = "ADMINISTRATOR",
}

export interface AuthSession {
  authType: AuthType
  areas: AreaCustomerResponse[]
  lastSelectedArea?: AreaCustomerResponse
}

export enum TutorialStatus {
  COMPLETED = "COMPLETED",
  OPEN = "OPEN",
}

export interface AuthLoginValues {
  authType: AuthType
  emailAddress: string
  password: string
}

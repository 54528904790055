import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { BarChart, Label, ResponsiveContainer, XAxis, YAxis, Bar, Tooltip, CartesianGrid, Cell } from "recharts"
import { ChartTooltip, IPropertyToDisplay } from "./ChartTooltip"
import { ChartConfig } from "./ChartConfig"
import { MeterReadingsCalcResolution } from "../../data/generated-sources/openapi"
import { getDateLocale } from "../../app/App.i18n"
import { formatNumber } from "../../domain/Domain.Formatters"
import { formatXAxisLabel, getTickFormatter, getYDomainForMeterChart } from "../../services/utils/AnalyseUtils"
import { BarChartDataPoint, ExceptionI } from "../../app/portal/shared/analiyse/Analise.Model"

interface BarChartViewProps {
  dataKey: string
  data: BarChartDataPoint[]
  meterReadingDateRange: MeterReadingsCalcResolution
  chartConfig: ChartConfig[]
  exception?: ExceptionI | null
  unit?: string | ReactNode
}

export const BAR_CHART_VIEW_HEIGHT = 300

export const BarChartView = (props: BarChartViewProps) => {
  const { t } = useTranslation("analise")
  const dateLocale = getDateLocale()
  const { dataKey, data, meterReadingDateRange, chartConfig: barsMetadata, unit, exception } = props

  const getDomainForYAxis = () => {
    if (exception) return getYDomainForMeterChart(data, meterReadingDateRange)
    return ["auto", "auto"]
  }

  return (
    <ResponsiveContainer width={"99%"}>
      <BarChart
        data={data}
        margin={{
          top: 40,
          right: 0,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid vertical={false} />
        <XAxis
          dataKey={dataKey}
          tickLine={false}
          axisLine={false}
          tick={{
            fontSize: 11,
            fontFamily: "Montserrat",
          }}
          tickMargin={10}
          tickFormatter={(date) => getTickFormatter(date, meterReadingDateRange, dateLocale, !!exception)}
        >
          <Label
            content={() => (
              <g transform={`translate(${0},${BAR_CHART_VIEW_HEIGHT - 6})`} fontSize={11}>
                <text fontFamily="Montserrat" textAnchor="start">
                  {formatXAxisLabel(meterReadingDateRange, t)}
                </text>
              </g>
            )}
          />
        </XAxis>
        <YAxis
          tickLine={false}
          axisLine={false}
          tickMargin={0}
          tickCount={6}
          domain={getDomainForYAxis() as [number, number]}
          tick={(tick: {
            y: number
            payload: {
              value: number
            }
          }) => {
            return (
              <g transform={`translate(${0},${tick.y})`} fontSize={11}>
                <text fontFamily="Montserrat" x={0} y={0} textAnchor="start">
                  {formatNumber(tick.payload.value)}
                </text>
              </g>
            )
          }}
        >
          <Label
            content={() => {
              return (
                <g fontSize={11}>
                  <text fontFamily="Montserrat" x={0} y={11} textAnchor="start">
                    {unit}
                  </text>
                </g>
              )
            }}
          />
        </YAxis>
        <Tooltip
          allowEscapeViewBox={{
            x: true,
            y: true,
          }}
          position={{ y: -(40 + barsMetadata?.length * 25) }}
          wrapperStyle={{ left: "-65px" }}
          content={
            <ChartTooltip
              propertyToDisplay={barsMetadata?.map(
                ({ id, unit, color }) =>
                  ({
                    id,
                    text: unit,
                    color,
                  } as IPropertyToDisplay),
              )}
            />
          }
        />
        {barsMetadata?.map((bar: ChartConfig) => (
          <Bar key={bar.id + new Date()} dataKey={bar.id} stackId="bars" fill={bar.color} barSize={bar.barSize ?? 8}>
            {data.map((entry, index) => {
              return (
                <Cell
                  key={`cell-${index}`}
                  fill={
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    exception && !exception?.noException && Number(entry[bar.id]) !== exception.value
                      ? exception.color
                      : bar.color
                  }
                />
              )
            })}
          </Bar>
        ))}
      </BarChart>
    </ResponsiveContainer>
  )
}

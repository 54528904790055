import { FormControl, FormHelperText, InputLabel, MenuItem, Select, SelectProps } from "@mui/material"

interface SelectPickerProps {
  helperText?: string
  emptyValue?: string
  items: SelectItem[]
  customStyle?: object
}

interface SelectItem {
  label: string
  value: string | number
}

export function SelectPicker<T>(props: SelectPickerProps & SelectProps<T>) {
  const {
    emptyValue,
    helperText,
    required,
    label,
    items,
    name,
    value,
    onBlur,
    onChange,
    multiple,
    defaultValue,
    variant,
    customStyle,
    disabled,
    ...rest
  } = props
  const selectStyle = customStyle ?? {}
  const valueType = typeof value

  return (
    <FormControl
      required={required}
      variant={variant ?? "standard"}
      error={helperText !== undefined}
      sx={{
        width: "100%",
      }}
    >
      <InputLabel sx={disabled ? { color: "#0000006b" } : {}}>{label}</InputLabel>
      <Select
        variant={variant ?? "standard"}
        sx={{ ...selectStyle, ".Mui-disabled": { color: "#0000006b" } }}
        defaultValue={defaultValue}
        name={name}
        value={value}
        multiple={multiple}
        onChange={onChange}
        onBlur={onBlur}
        disabled={disabled}
        {...rest}
      >
        {emptyValue && (
          <MenuItem value={valueType === "number" ? -1 : ""}>
            <p>{emptyValue}</p>
          </MenuItem>
        )}
        {items.map((item, index) => (
          <MenuItem key={`${item.value}-${index}`} value={item.value}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  )
}

/* eslint-disable react/destructuring-assignment */
import { RouteComponentProps } from "react-router-dom"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import {
  getUtilityUnitById,
  updateUtilityUnitById,
} from "../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { AxiosErrorDataType } from "../../../Shared.Utils"

import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { UtilityUnitModifyFormFields } from "./views/UtilityUnitModifyFormFields"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { UtilityUnitModifySchema } from "./views/UtilityUnitValidation.Schema"
import { UtilityUnitResponse, UtilityUnitUpdateAdminRequest } from "../../../../data/generated-sources/openapi"

const UtilityUnitDetailUpdateComponent = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const {
    match: { params },
    history,
  } = props
  const { t } = useTranslation()
  const [updateError, setUpdateError] = useState<AxiosErrorDataType>()

  const {
    data: utilityUnit,
    remove,
    isLoading,
  } = useQuery(["getUtilityUnit", params?.utilityUnitId], () => getUtilityUnitById(params?.utilityUnitId))

  const { mutate: updateUnit, isLoading: isUpdating } = useMutation(
    ["createUtilityUnit"],
    (updateData: UtilityUnitUpdateAdminRequest) => updateUtilityUnitById(params?.utilityUnitId, updateData),
    {
      onError: setUpdateError,
      onSuccess: () => history.push(`/utility-units/${params.utilityUnitId}`),
    },
  )

  const handleOnSubmit = ({ name, usageType, unitSpace }: Partial<UtilityUnitResponse>) => {
    if (!name || !usageType) return
    const unitSpaceNumber = unitSpace ? Math.round(unitSpace * 100) / 100 : undefined
    setUpdateError(undefined)
    updateUnit({ name, usageType, ...{ unitSpace: unitSpaceNumber } })
  }

  useEffect(() => {
    return () => remove()
  }, [remove])

  if (isLoading) return <CircularProgress color="primary" />

  return (
    <Formik<Partial<UtilityUnitResponse>>
      enableReinitialize
      initialValues={{ name: "", usageType: undefined, ...utilityUnit }}
      validationSchema={UtilityUnitModifySchema}
      onSubmit={handleOnSubmit}
    >
      {(props) => (
        <Form onSubmit={props.handleSubmit}>
          <ErrorAlert
            visible={!!updateError}
            message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
          />
          <UtilityUnitModifyFormFields {...props} />
          <FormUpdateActionsView
            buttonCtaLabel={t("utilityUnit:label.saveChanges")}
            isLoading={isUpdating || isLoading}
            navigateBack={() => history.goBack()}
            dirty={props.dirty}
            isValid={props.isValid}
          />
        </Form>
      )}
    </Formik>
  )
}

export default UtilityUnitDetailUpdateComponent

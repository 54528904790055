import { useTranslation } from "react-i18next"
import { Box, Paper, Typography } from "@mui/material"
import { InitialBillingDetailsTableColumns } from "./InitialBillingDetailsTableColumns"
import { DataGridTable } from "../../../../../../uikit/dataGridTable"
import { ServiceBillingPosition } from "../../../../../../data/generated-sources/openapi"
import { formatMoneyLabel } from "../../../../../../domain/Domain.Formatters"
import { InitialBillingAdminResponse } from "../../../../../../domain/portal/admin/billings/initial/BillingsInitial.Model"

export const InitialBillingDetailsTable = (initialBilling: InitialBillingAdminResponse) => {
  const { t } = useTranslation("billings-initial")
  return (
    <Paper>
      <Typography variant="h4" fontWeight={100} mb={4} data-testid="initialBillingDetailsTableTitle">
        {t("detail.consumptionpoint.title")}
      </Typography>
      <DataGridTable<ServiceBillingPosition>
        admin
        hideFooter
        data-testid="initialBillingDetailsTable"
        rows={initialBilling?.positions ?? []}
        columns={InitialBillingDetailsTableColumns()}
        getRowClassName={() => "RowNotClickable"}
        getRowId={({ externalReferenceNumber }) => externalReferenceNumber}
      />
      <Box pr={4}>
        <Typography variant="subtitle1" textAlign="end" data-testid="initialBillingDetailsTotal">
          {t("detail.label.total")}
        </Typography>
        <Typography variant="h4" textAlign="end" data-testid="initialBillingTotalAmount">
          {formatMoneyLabel(initialBilling?.totalAmountDue)}
        </Typography>
      </Box>
    </Paper>
  )
}

import { useTranslation } from "react-i18next"

import { FormRowCell, FormView } from "../../../../../uikit/form/FormView"
import { PriceCalculatorNumberRow } from "../views/PricecalculatorFormRow"
import { ZevPriceCalculatorEnergy } from "../../../../../data/generated-sources/openapi"

interface CalculatorYearlyConsumptionFormProps {
  energy: ZevPriceCalculatorEnergy
  dataStatusChanged: (energyInputs: ZevPriceCalculatorEnergy) => void
}

export const computeTotal = () => {
  const getBoughtTotal = (energy: ZevPriceCalculatorEnergy) => {
    return parseFloat(energy.boughtHighTariff) + parseFloat(energy.boughtLowTariff)
  }
  const getTotalSelfConsumption = (energy: ZevPriceCalculatorEnergy) => {
    return parseFloat(energy.consumptionOfProductionHighTariff) + parseFloat(energy.consumptionOfProductionLowTariff)
  }
  const getTotalSold = (energy: ZevPriceCalculatorEnergy) => {
    return parseFloat(energy.production) - getTotalSelfConsumption(energy)
  }
  const getTotalConsumption = (energy: ZevPriceCalculatorEnergy) => {
    return getBoughtTotal(energy) + getTotalSelfConsumption(energy)
  }
  const getComputedProperties = (energy: ZevPriceCalculatorEnergy) => ({
    consumptionOfProductionTotal: getTotalSelfConsumption(energy).toString(),
    consumptionTotal: getTotalConsumption(energy).toString(),
    soldTotal: getTotalSold(energy).toString(),
    boughtTotal: getBoughtTotal(energy).toString(),
  })
  return {
    getBoughtTotal,
    getTotalSelfConsumption,
    getTotalSold,
    getTotalConsumption,
    getComputedProperties,
  }
}

export const CalculatorYearlyConsumptionForm = (props: CalculatorYearlyConsumptionFormProps) => {
  const { energy, dataStatusChanged } = props
  const { t } = useTranslation("my-zev")

  const makeEnergyPropertyUpdater = (prop: keyof ZevPriceCalculatorEnergy) => (values: { value: string }) =>
    dataStatusChanged({
      ...energy,
      [prop]: values.value,
    })

  const { getTotalConsumption, getBoughtTotal, getTotalSelfConsumption, getTotalSold } = computeTotal()

  return (
    <FormView>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.purchaseHighTariff")}
          value={energy.boughtHighTariff}
          suffix="  kWh"
          decimalScale={0}
          change={makeEnergyPropertyUpdater("boughtHighTariff")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.purchaseLowTariff")}
          value={energy.boughtLowTariff}
          suffix="  kWh"
          decimalScale={0}
          change={makeEnergyPropertyUpdater("boughtLowTariff")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.solarSystemProduction")}
          value={energy.production}
          suffix="  kWh"
          decimalScale={0}
          change={makeEnergyPropertyUpdater("production")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionHighTariff")}
          value={energy.consumptionOfProductionHighTariff}
          suffix="  kWh"
          decimalScale={0}
          change={makeEnergyPropertyUpdater("consumptionOfProductionHighTariff")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.selfConsumptionLowTariff")}
          value={energy.consumptionOfProductionLowTariff}
          suffix="  kWh"
          decimalScale={0}
          change={makeEnergyPropertyUpdater("consumptionOfProductionLowTariff")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.averagePerformance")}
          value={energy.averageBoughtMonthlyPeak}
          suffix="  kW/Mt."
          decimalScale={1}
          change={makeEnergyPropertyUpdater("averageBoughtMonthlyPeak")}
        />
      </FormRowCell>
      <FormRowCell>
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.totalConsumption")}
          value={getTotalConsumption(energy)}
          suffix="  kWh"
          decimalScale={0}
          displayType="text"
        />
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.purchaseTotal")}
          value={getBoughtTotal(energy)}
          suffix="  kWh"
          displayType="text"
          decimalScale={0}
        />
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.ownConsumptionTotal")}
          value={getTotalSelfConsumption(energy)}
          suffix="  kWh"
          displayType="text"
          decimalScale={0}
        />
        <PriceCalculatorNumberRow
          label={t("section-main.tab.priceCalculator.yearlyConsumption.feed")}
          value={getTotalSold(energy)}
          suffix="  kWh"
          displayType="text"
          decimalScale={0}
        />
      </FormRowCell>
    </FormView>
  )
}

import { useCallback, useState } from "react"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { GridRow, GridRowProps } from "@mui/x-data-grid"
import { removeAdminServiceBillingRunContractById } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Repository"
import { IServiceBillings } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

import { DataGridListPage } from "../../../dataGrid/DataGridListPage"
import { RecurringDetailsColumns } from "./RecurringDetailsColumns"
import { RecurringBillingPositionsTable } from "./RecurringBillingPositionsTable"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../../uikit/Shared.Alert"
import { ConfirmDialog } from "../../../../../../uikit/confirmDialog/ConfirmDialog"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface RecurringDetailsTableProps {
  isLoading: boolean
  serviceBillings: IServiceBillings[]
}

export const RecurringBillingDetailsTable = ({ serviceBillings, isLoading }: RecurringDetailsTableProps) => {
  const { t } = useTranslation("billings-recurring")
  const [expandedRowId, setExpandedRowId] = useState<string>("")
  const [showRemoveDialog, setShowRemoveDialog] = useState(false)
  const [selectedServiceBilling, setSelectedServiceBilling] = useState<IServiceBillings>()

  const queryClient = useQueryClient()
  const invalidateVewaQuery = () => queryClient.invalidateQueries({ queryKey: ["getRecurringBilling"] })

  const {
    mutate: removeBilling,
    isError: isRemoveError,
    error: removeError,
    isSuccess,
  } = useMutation(
    ["removeBilling"],
    () =>
      removeAdminServiceBillingRunContractById(
        selectedServiceBilling?.billingRunId ?? "",
        selectedServiceBilling?.contractId ?? "",
      ),
    {
      onSuccess: () => invalidateVewaQuery(),
      onSettled: () => setShowRemoveDialog(false),
    },
  )

  const handleRemoveBilling = (rowData: IServiceBillings) => {
    setShowRemoveDialog(true)
    setSelectedServiceBilling(rowData)
  }

  const handleExpandRow = (rowId: string) => {
    const sameRowId = expandedRowId === rowId
    const expandId = sameRowId ? "" : rowId
    setExpandedRowId(expandId)
  }

  const ExpandableTableRow = useCallback(
    (prop: GridRowProps) => {
      const { rowId, row } = prop
      return (
        <>
          <GridRow {...prop} key={rowId} />
          {rowId === expandedRowId && (
            <RecurringBillingPositionsTable {...(row as IServiceBillings)} onSuccessAction={invalidateVewaQuery} />
          )}
        </>
      )
    },
    [expandedRowId],
  )

  const errorMsg = removeError as AxiosErrorDataType
  const expandedTableHeight = 1000 + (serviceBillings.length ?? 1) * 48

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={isRemoveError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <OptionalSuccessAlert message={t("details.action.remove.success")} show={isSuccess} scrollOnDisplay />

      <DataGridListPage<IServiceBillings>
        hideFooter
        disableColumnMenu
        showButton={false}
        loading={isLoading}
        title={t("detail.subtitle")}
        data-testid="recurringBillingDetailsTable"
        rows={serviceBillings ?? []}
        columns={RecurringDetailsColumns(expandedRowId, handleExpandRow, handleRemoveBilling)}
        slots={{
          row: ExpandableTableRow,
        }}
        sx={{
          height: expandedRowId ? `${expandedTableHeight}px !important` : "auto",
          ".MuiDataGrid-row": { cursor: "default" },
        }}
      />

      <ConfirmDialog
        actionButtonText={""}
        showPrimaryButton={false}
        openModal={showRemoveDialog}
        onConfirm={() => removeBilling()}
        onCancel={() => setShowRemoveDialog(false)}
        confirmButtonText={t("billings-recurring:remove.billing")}
      />
    </>
  )
}

import { DialogActions, DialogContent, Button, RadioGroup, FormControlLabel, Radio, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import * as yup from "yup"

import {
  UtilityUnitMoveInFormData,
  UtilityUnitMoveInType,
} from "../../../../../../domain/participant/Participant.Model"

import { validationError } from "../../../../../Shared.Validation"
import { MoveInParticipantList } from "./MoveInParticipantList"
import { PrimaryButton } from "../../../../../../uikit/button"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import { ParticipantCreateAndMoveInForm } from "./ParticipantRegisterAndMoveInForm"
import { AxiosErrorDataType, getTranslateValue } from "../../../../../Shared.Utils"

export const participantMoveInSchema = yup.object().shape({
  moveInDate: yup
    .number()
    .positive(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("utilityUnitParticipations:moveIn.form.moveInDate"),
      }),
    )
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("utilityUnitParticipations:moveIn.form.moveInDate"),
      }),
    ),
  participantId: yup.string().when("moveInType", {
    is: (moveInType: UtilityUnitMoveInType) => {
      return moveInType !== UtilityUnitMoveInType.VACANCY
    },
    then: yup.string().required(),
  }),
})

interface MoveInFormProps {
  isLoading: boolean
  participationId: string
  utilityUnitId: string
  showVacancy?: boolean
  afterMoveOutStep?: boolean
  minMoveInDate?: number
  onClose: () => void
  onUpdateSuccess: () => void
  setMoveInError: (error: AxiosErrorDataType | undefined) => void
  onMoveInSubmit: (data: UtilityUnitMoveInFormData) => void
}

export const MoveInForm = ({
  afterMoveOutStep,
  minMoveInDate,
  isLoading,
  showVacancy,
  participationId,
  utilityUnitId,
  onClose,
  onMoveInSubmit,
  setMoveInError,
  onUpdateSuccess,
}: MoveInFormProps) => {
  const { t } = useTranslation("utilityUnitParticipations")

  const currentTime = new Date().getTime()
  const initialMoveInDate = minMoveInDate && minMoveInDate > currentTime ? minMoveInDate : currentTime

  const handleMoveInSubmit = (moveInData: UtilityUnitMoveInFormData) => {
    setMoveInError(undefined)
    if (afterMoveOutStep && moveInData.moveInType === UtilityUnitMoveInType.VACANCY) {
      return onClose()
    }
    onMoveInSubmit(moveInData)
  }

  return (
    <Formik<UtilityUnitMoveInFormData>
      enableReinitialize
      validationSchema={participantMoveInSchema}
      initialValues={{
        participantId: "",
        moveInDate: initialMoveInDate,
        moveInType: UtilityUnitMoveInType.VACANCY,
      }}
      onSubmit={handleMoveInSubmit}
    >
      {({ values, touched, errors, isValid, handleBlur, handleChange, setFieldValue }) => (
        <DialogContent>
          <Form>
            <RadioGroup row color="secondary" name="moveInType" defaultValue={UtilityUnitMoveInType.VACANCY}>
              <FormControlLabel
                label={`${t("move.form.toggle.create")}`}
                value={UtilityUnitMoveInType.CREATE}
                control={<Radio onChange={handleChange} />}
              />
              <FormControlLabel
                label={`${t("move.form.toggle.select")}`}
                value={UtilityUnitMoveInType.EXISTING}
                control={<Radio onChange={handleChange} />}
              />
              {(afterMoveOutStep || showVacancy) && (
                <FormControlLabel
                  label={`${t("move.form.toggle.vacancy")}`}
                  value={UtilityUnitMoveInType.VACANCY}
                  control={<Radio onChange={handleChange} />}
                />
              )}
            </RadioGroup>
            <Box py={3}>
              {values.moveInType !== UtilityUnitMoveInType.VACANCY && (
                <SingleLineDatePicker
                  name="moveInDate"
                  label={t("moveIn.form.moveInDate")}
                  value={values.moveInDate}
                  minDate={minMoveInDate ? new Date(minMoveInDate) : undefined}
                  helperText={validationError(errors.moveInDate, touched.moveInDate)}
                  onChange={(value) => setFieldValue("moveInDate", value)}
                  onBlur={handleBlur}
                />
              )}
            </Box>

            {values.moveInType === UtilityUnitMoveInType.EXISTING && (
              <MoveInParticipantList
                onFetchError={(error) => setMoveInError(error)}
                onSelectParticipant={(id) => setFieldValue("participantId", id)}
              />
            )}

            {values.moveInType !== UtilityUnitMoveInType.CREATE && (
              <DialogActions sx={{ "&.MuiDialogActions-root": { pl: 0 } }}>
                <Button variant="outlined" onClick={onClose} disabled={isLoading}>
                  {t("shared:form.action.cancel")}
                </Button>
                <PrimaryButton
                  type="submit"
                  isLoading={isLoading}
                  label={t("shared:form.action.save")}
                  disabled={isLoading || !isValid}
                />
              </DialogActions>
            )}
          </Form>

          {values.moveInType === UtilityUnitMoveInType.CREATE && (
            <ParticipantCreateAndMoveInForm
              utilityUnitId={utilityUnitId}
              participationId={participationId}
              moveInDate={values.moveInDate}
              onClose={onClose}
              onUpdateSuccess={onUpdateSuccess}
            />
          )}
        </DialogContent>
      )}
    </Formik>
  )
}

import {
  ParticipantCustomerUpdateRequest,
  ParticipantGpType,
  ParticipantResponse,
} from "../../../../data/generated-sources/openapi"
import {
  ParticipantCustomerUpsert,
  ParticipantItem,
  ParticipantListItem,
  ParticipantUpsert,
} from "./Participants.Model"

export const participantListItemMapper = (value: ParticipantResponse[]): ParticipantListItem[] => {
  return value.map((participant) => ({
    id: participant.id,
    firstName: participant.personalData.firstName,
    lastName: participant.personalData.lastName,
    email: participant.contactDetails.email ?? "",
    telephone: participant.contactDetails.phone ?? "",
  }))
}

export const participantItemMapper = (value: ParticipantItem): ParticipantUpsert => {
  return {
    id: value.id,
    businessPartnerName: value.sapGp ?? "",
    businessPartnerType: value.gpType,
    salutation: value.personalData.salutation,
    title: value.personalData.title ?? "",
    firstName: value.personalData.firstName,
    lastName: value.personalData.lastName,
    firstNameSecondPerson: value.personalData.firstNameSecondPerson ?? "",
    lastNameSecondPerson: value.personalData.lastNameSecondPerson ?? "",
    contactTelephone: value.contactDetails.phone ?? "",
    contactMobile: value.contactDetails.mobile ?? "",
    contactEmail: value.contactDetails.email ?? "",
    addressStreet: value.domicileAddress.street,
    addressHouseNumber: value.domicileAddress.houseNumber,
    addressCO: value.domicileAddress.co ?? "",
    addressPostBox: value.domicileAddress.poBox ?? "",
    addressPostCode: value.domicileAddress.postCode,
    addressCity: value.domicileAddress.city,
    addressCountry: value.domicileAddress.land,
    participantName: `${value.personalData.firstName} ${value.personalData.lastName}`.trim(),
    participantSecondName: `${value.personalData.firstNameSecondPerson ?? ""} ${
      value.personalData.lastNameSecondPerson ?? ""
    }`.trim(),
  }
}

export const participantUpsertMapper = (participant: ParticipantUpsert): ParticipantCustomerUpsert => {
  return {
    gpType: participant.businessPartnerType as ParticipantGpType,
    personalData: {
      salutation: participant.salutation,
      title: participant.title,
      firstName: participant.firstName,
      lastName: participant.lastName,
      firstNameSecondPerson: participant.firstNameSecondPerson,
      lastNameSecondPerson: participant.lastNameSecondPerson,
    },
    contactDetails: {
      email: participant.contactEmail,
      phone: participant.contactTelephone,
      mobile: participant.contactMobile,
    },
    domicileAddress: {
      city: participant.addressCity,
      postCode: participant.addressPostCode,
      houseNumber: participant.addressHouseNumber,
      street: participant.addressStreet,
      land: participant.addressCountry,
      co: participant.addressCO,
      poBox: participant.addressPostBox,
    },
  }
}

export const participantUpdateMapper = (participant: ParticipantResponse): ParticipantCustomerUpdateRequest => {
  return {
    gpType: participant?.gpType as ParticipantGpType,
    personalData: {
      salutation: participant.personalData?.salutation,
      title: participant.personalData?.title,
      firstName: participant.personalData?.firstName,
      lastName: participant.personalData?.lastName,
      firstNameSecondPerson: participant.personalData?.firstNameSecondPerson,
      lastNameSecondPerson: participant.personalData?.lastNameSecondPerson,
    },
    contactDetails: {
      email: participant.contactDetails?.email,
      phone: participant.contactDetails?.phone,
      mobile: participant.contactDetails?.mobile,
    },
    domicileAddress: {
      city: participant.domicileAddress?.city,
      postCode: participant.domicileAddress?.postCode,
      houseNumber: participant.domicileAddress?.houseNumber,
      street: participant.domicileAddress?.street,
      land: participant.domicileAddress?.land,
      co: participant.domicileAddress?.co,
      poBox: participant.domicileAddress?.poBox,
    },
  }
}

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Box, Button, CircularProgress, Dialog, DialogActions, DialogTitle, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { GridActionsCellItem, GridActionsColDef, GridCellParams } from "@mui/x-data-grid"
import {
  customerCompleteContractOnboardingById,
  customerGetUtilityUnitsByContractId,
} from "../../../../domain/portal/manager/contracts/Contract.Repository"
import DateUtils from "../../../../services/utils/DateUtils"
import { AxiosErrorDataType, filterArrayBySearchInput } from "../../../Shared.Utils"
import { getParticipantFullName } from "../../../../services/utils/ParticipantUtils"
import { UtilityUnitResponse } from "../../../../data/generated-sources/openapi"

import { OnboardingPagesProps } from "./Onboarding"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { SearchIcon } from "../../../../uikit/Shared.Icon"
import { DialogCloseIcon } from "../../../../uikit/icons/Icons"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { DataGridTable, DefaultCell } from "../../../../uikit/dataGridTable"
import { OnboardingActionButtons, OnboardingSteperInfo } from "./fragments/OnboardingUI"
import { ParticipantMoveIn } from "../utilityUnit/components/participations/ParticipantMoveIn"
import { DeleteParticipationParticipant } from "../utilityUnit/components/participations/DeleteParticipationParticipant"
import ParticipantsDetail from "../participants/components/ParticipantsDetail"
import {
  UtilityUnitParticipant,
  UtilityUnitParticipationParticipant,
} from "../../../../domain/participant/Participant.Model"

export const OnboardingParticipationsInfo = ({
  contractId,
  startDate,
  onBackClick,
  onNextClick,
}: OnboardingPagesProps) => {
  const { t } = useTranslation("onboarding")
  const [showMoveIn, setShowMoveIn] = useState(false)
  const [showEdit, setShowEdit] = useState(false)
  const [showRemove, setShowRemove] = useState(false)
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [updateError, setUpdateError] = useState<AxiosErrorDataType | null>(null)
  const [unitList, setUnitList] = useState<UtilityUnitResponse[]>([])
  const [selectedParticipant, setSelectedParticipant] = useState<UtilityUnitParticipationParticipant>()

  const mapSelectedParticipant = ({ row }: GridCellParams) => {
    const currentParticipation = [...row.participationObjects].pop()
    const participant: UtilityUnitParticipant = {
      ...currentParticipation?.participant,
      fullName: "-",
    }
    const participationData: UtilityUnitParticipationParticipant = {
      participant: participant,
      ...currentParticipation,
      moveInDate: DateUtils.getTimeStamp(currentParticipation?.moveInDate ?? startDate),
      utilityUnitId: row.id,
    }
    setSelectedParticipant(participationData)
  }

  const participantsColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      field: "buildingName",
      headerName: t("participation.building"),
      valueGetter: (_, row) => row?.building?.name,
      renderCell: DefaultCell,
      flex: 300,
    },
    {
      field: "utilityUnitName",
      headerName: t("participation.utilityUnit"),
      valueGetter: (_, row) => row?.name,
      renderCell: DefaultCell,
      flex: 300,
    },
    {
      field: "participant",
      headerName: t("participation.participant"),
      valueGetter: (_, row) => {
        const currentParticipation = [...row.participationObjects].pop()
        if (!currentParticipation) {
          return "-"
        }

        return currentParticipation?.participant
          ? getParticipantFullName(currentParticipation?.participant?.personalData)
          : t("customer-participants:participant.empty")
      },
      renderCell: DefaultCell,
      flex: 300,
    },
    {
      field: "actions",
      type: "actions",
      flex: 50,
      getActions: ({ row }) => {
        const currentParticipation = [...row.participationObjects].pop()
        return [
          currentParticipation && currentParticipation?.participant ? (
            <GridActionsCellItem
              key="editParticipant"
              label={t("customer-participants:participant.edit")}
              onClick={() => setShowEdit(true)}
              showInMenu
            />
          ) : (
            <></>
          ),
          currentParticipation ? (
            <GridActionsCellItem
              showInMenu
              key="removeParticipant"
              label={t("customer-participants:participant.remove")}
              onClick={() => setShowRemove(true)}
            />
          ) : (
            <></>
          ),
          <GridActionsCellItem
            showInMenu
            key="moveInParticipant"
            label={t("customer-participants:participant.add")}
            onClick={() => {
              setShowMoveIn(true)
            }}
          />,
        ]
      },
    },
  ]

  const {
    isLoading,
    isFetching,
    remove: removeUnits,
    data: utilityUnits,
    refetch,
  } = useQuery(["getUtilityUnits"], () => customerGetUtilityUnitsByContractId(contractId), {
    enabled: !!contractId,
    onError: setUpdateError,
    onSuccess({ elements }) {
      setUnitList(elements)
    },
  })

  const { mutate: completeOnboarding, isLoading: isCompletingONboard } = useMutation(
    ["completeOnboarding", contractId],
    () => customerCompleteContractOnboardingById(contractId),
    {
      onError: setUpdateError,
      onSuccess: () => onNextClick(),
    },
  )

  useEffect(() => {
    return () => {
      removeUnits()
    }
  }, [removeUnits])

  const filterTableData = (searchInput: string) => {
    if (utilityUnits?.elements) {
      const filtered = filterArrayBySearchInput<UtilityUnitResponse>(utilityUnits?.elements, searchInput)
      setUnitList(filtered)
    }
  }

  const handleEditClose = () => {
    refetch()
    setShowEdit(false)
  }

  return (
    <>
      <OnboardingSteperInfo
        stepNumber={"4"}
        title={t("participantsInfo.title")}
        subHeader={t("participantsInfo.subTitle")}
      />
      <Box py={3}>
        <ErrorAlert
          visible={!!updateError}
          message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
        />
      </Box>
      {isLoading && isFetching && <CircularProgress />}
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pb={5}>
        <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"center"}>
          <SearchIcon sx={{ mr: 1 }} />
          <SingleLineTextField
            id="filter"
            name="filter"
            type="text"
            variant="standard"
            InputProps={{ disableUnderline: true }}
            label={t("shared:form.search.label")}
            value={filterQuery}
            onChange={(event) => {
              setFilterQuery(event.target.value)
              filterTableData(event.target.value)
            }}
          />
        </Stack>
      </Stack>
      <DataGridTable<UtilityUnitResponse>
        rows={unitList}
        columns={participantsColumns}
        loading={isLoading && isFetching}
        onCellClick={mapSelectedParticipant}
        defaultPageSize={10}
      />

      <OnboardingActionButtons
        onBackClick={onBackClick}
        onNextClick={completeOnboarding}
        isNextBtnLoading={isCompletingONboard}
        nextBtnText={t("nextPage.completeSetup")}
      />
      {selectedParticipant && (
        <>
          <ParticipantMoveIn
            showDialog={showMoveIn}
            participation={selectedParticipant}
            onClose={() => {
              setShowMoveIn(false)
            }}
            onUpdateSuccess={() => {
              refetch()
              setShowMoveIn(false)
            }}
          />
          <DeleteParticipationParticipant
            showDialog={showRemove}
            participation={selectedParticipant}
            onUpdateSuccess={() => {
              refetch()
              setShowRemove(false)
            }}
            onClose={() => setShowRemove(false)}
          />
          <Dialog open={showEdit} onClose={handleEditClose} fullWidth maxWidth="lg">
            <DialogTitle>{t("utilityUnitParticipations:dialog.editParticipant")}</DialogTitle>
            <DialogCloseIcon onClick={handleEditClose} />
            <ParticipantsDetail selectedParticipantId={selectedParticipant.participant?.id ?? ""} />
            <DialogActions>
              <Button variant="outlined" onClick={handleEditClose} sx={{ my: 3 }}>
                {t("shared:form.action.cancel")}
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </>
  )
}

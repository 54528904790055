import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import {
  BuildingCreateAdminRequest,
  BuildingUpdateAdminRequest,
  ContractAdminResponse,
  UtilityUnitCreateAdminRequest,
  UtilityUnitResponse,
} from "../../../../data/generated-sources/openapi"
import { apiHeaders } from "../../../Domain.Calls"
import { buildingsListMapper } from "./Buildings.Mapper"
import { getAllParticipationsForUtilityUnit } from "../utility-units/UtilityUnits.Repository"
import { appStore } from "../../../../app/App.Store"
import { setBuildingsList } from "../../../../app/portal/admin/store/buildingsSlice"

export const adminGetBuildingsList = async () => {
  const { data: buildingData } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetBuildings(
    1,
    10000,
    undefined,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  const { data: areasData } = await DOMAIN_DEPENDENCIES.adminAreaApi.adminGetAreas(
    1,
    10000,
    undefined,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  const buildings = buildingsListMapper(buildingData, areasData.elements)
  appStore.dispatch(setBuildingsList(buildings))
  return buildings
}

export const adminGetBuildingById = async (buildingId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetBuildingById(
    buildingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const adminCreateBuildingForArea = async (
  areaId: string,
  buildingCreateAdminRequest: BuildingCreateAdminRequest,
) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminCreateBuildingForArea(
    areaId,
    buildingCreateAdminRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminUpdateBuildingById = async (
  buildingId: string,
  buildingUpdateAdminRequest: BuildingUpdateAdminRequest,
) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminUpdateBuildingById(
    buildingId,
    buildingUpdateAdminRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminDeactivateBuildingById = (buildingId: string, fromDate: string) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminDeactivateBuildingById(
    buildingId,
    fromDate,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminDeleteBuilding = (buildingId: string) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminDeleteBuilding(buildingId, apiHeaders(DOMAIN_DEPENDENCIES))
}

export const createBuildingUtilityUnit = (buildingId: string, createUnitData: UtilityUnitCreateAdminRequest) => {
  return DOMAIN_DEPENDENCIES.adminBuildingApi.adminCreateUtilityUnitForBuilding(
    buildingId,
    createUnitData,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const adminGetContractForBuilding = async (buildingId: string): Promise<ContractAdminResponse[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetContractForBuilding(
    buildingId,
    1,
    undefined,
    undefined,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const getUtilityUnitForBuilding = async (
  buildingId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
) => {
  const authHeaders = apiHeaders(DOMAIN_DEPENDENCIES)

  const { data: units } = await DOMAIN_DEPENDENCIES.adminBuildingApi.adminGetUtilityUnitForBuilding(
    buildingId,
    page,
    limit,
    orderBy,
    authHeaders,
  )

  const mappedUnits = await Promise.allSettled(
    units.elements.map(async (unit) => {
      const participantData = await getAllParticipationsForUtilityUnit(unit.id)

      return {
        ...unit,
        participationParticipants: participantData,
      }
    }),
  )

  const fulfilledResult = mappedUnits
    .filter((el) => el.status === "fulfilled")
    .map((e) => (e as PromiseFulfilledResult<UtilityUnitResponse>)?.value)

  const data = {
    page: units.page,
    elements: fulfilledResult,
  }
  return { data }
}

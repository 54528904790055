import { GridActionsColDef } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { IconButton, Typography } from "@mui/material"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { OpenIconNew } from "../../../../../uikit/Shared.Icon"
import { DefaultCell } from "../../../../../uikit/dataGridTable"

export const BuildingsTableColumns = () => {
  const { t } = useTranslation("buildings")

  const buildingsTableColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 120,
      field: "activeState",
      headerName: t("shared:status.label"),
      valueGetter: (value: string) => t(`shared:status.${value}`),
      renderCell: ({ row }) => <StatusView statusType={row.activeState} />,
    },
    {
      flex: 200,
      field: "name",
      headerName: t("list.label.building-object"),
      renderCell: DefaultCell,
    },
    {
      flex: 200,
      field: "fullAddress",
      headerName: t("list.label.address"),
      renderCell: DefaultCell,
    },
    {
      flex: 200,
      field: "areaName",
      headerName: t("field.label.area"),
      renderCell: ({ value }) => (
        <Typography fontWeight={500} fontSize={14}>
          {value}
          <IconButton data-testid="goToAreaBtn">
            <OpenIconNew />
          </IconButton>
        </Typography>
      ),
    },
  ]
  return buildingsTableColumns
}

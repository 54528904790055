import { Box, CircularProgress, Stack, Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

import { formatMoneyLabel } from "../../../../domain/Domain.Formatters"
import { ActivateIconFilled } from "../../../../uikit/Shared.Icon"
import { PrimaryOutlinedButton, PrimaryButton } from "../../../../uikit/button"
import DateUtils from "../../../../services/utils/DateUtils"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import {
  ProductName,
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { ZevBillingAggregateConsumption } from "./ZevBillingAggregateConsumption"
import { appThemeGrey200, appThemePrimaryColor } from "../../../Shared.Theme"
import { isAdminPortal, isManagerPortal } from "../../../Shared.Utils"

const InfoBox = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  borderRadius: theme.spacing(1),
  marginBottom: theme.spacing(6.25),
  background: theme.palette.background.editPrimary,
}))

const BillingSumTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: appThemeGrey200,
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(1),
}))

const BillingSumData = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
  marginBottom: theme.spacing(1),
}))

const BillingSumBox = ({ title, value }: { title: string; value?: string }) => {
  return (
    <Box mr={1.5}>
      <BillingSumTitle>{title}</BillingSumTitle>
      <BillingSumData>{value ?? "-"}</BillingSumData>
    </Box>
  )
}

interface ZevBillingFinalizeProps {
  isLoading: boolean
  billing?:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
  isIndividualBill?: boolean
  isReopeningBilling?: boolean
  isDownloadingAllPdf?: boolean
  isDownloadingAllCsv?: boolean
  isSendingToSAP?: boolean
  onSendSAP?: () => void
  onReopenBilling: () => void
  onDownloadAllCsv?: () => void
  onDownloadAllBills?: () => void
  onDownloadBill: (billId: string) => void
  onToggleBillPay: (billId: string, paidOrUnpaid: "paid" | "unpaid") => void
}

export const ZevBillingFinalize = ({
  isLoading,
  billing,
  isIndividualBill,
  isReopeningBilling,
  isDownloadingAllPdf,
  isDownloadingAllCsv,
  isSendingToSAP,
  onSendSAP,
  onReopenBilling,
  onDownloadBill,
  onDownloadAllCsv,
  onDownloadAllBills,
  onToggleBillPay,
}: ZevBillingFinalizeProps) => {
  const { t } = useTranslation("settlements")

  const billDateRange = `${DateUtils.getDeFormatDate(
    billing?.billingStartDate ?? "",
    "DD. MMM YY",
  )} - ${DateUtils.getDeFormatDate(billing?.billingEndDate ?? "", "DD. MMM YY")}`

  const canSendToSapAdmin = isAdminPortal() && billing?.billingStatus === "DONE"
  const canSendToSapManager = isManagerPortal() && billing?.billingStatus === "IN_PROGRESS"
  const premiumWithCollection = billing?.productName === ProductName.ZEV_PREMIUM_PLUS && billing?.hasCollection
  const showSapButton = premiumWithCollection && (canSendToSapAdmin || canSendToSapManager)

  const canEditBillZevConfort = billing?.productName?.toLowerCase()?.includes(ProductName.ZEV_COMFORT.toLowerCase())
  const canEditBillZevPremium =
    billing?.productName?.toLowerCase()?.includes(ProductName.ZEV_PREMIUM_PLUS.toLowerCase()) && isAdminPortal()

  if (isLoading && !billing) return <CircularProgress />

  return (
    <>
      <InfoBox>
        <Typography
          mb={1.5}
          fontSize={18}
          fontWeight={400}
          display={"flex"}
          alignItems={"center"}
          color={appThemePrimaryColor}
        >
          <ActivateIconFilled sx={{ mr: 1.5, color: appThemePrimaryColor }} />
          {premiumWithCollection
            ? t("detail.finalise.createCollection.message.title")
            : t("detail.finalise.invoiceReady.title")}
        </Typography>
        <Typography fontSize={12} fontWeight={400}>
          {premiumWithCollection
            ? t("detail.finalise.createCollection.message.body")
            : t("detail.finalise.invoiceReady.body")}
        </Typography>
      </InfoBox>

      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={5.25} flexWrap={"wrap"}>
        <BillingSumBox title={t("section-list-header.bill.period")} value={billDateRange} />
        <BillingSumBox
          title={t("bills.invoiceDate")}
          value={`${DateUtils.getDeFormatDate(billing?.billingDate ?? "", "DD. MMM YY")}`}
        />
        <BillingSumBox title={t("section-list-header.bill.total")} value={formatMoneyLabel(billing?.totalCosts)} />
        <BillingSumBox
          title={t("section-list-header.bill.status")}
          value={t(`shared:billing.status.${billing?.billingStatus}`)}
        />
        {(canEditBillZevConfort || canEditBillZevPremium) && (
          <PrimaryOutlinedButton label="Bearbeiten" onClick={onReopenBilling} isLoading={isReopeningBilling} />
        )}
      </Stack>
      <Box mb={6}>
        <ZevBillingAggregateConsumption
          isFinallizeBilling
          billing={billing}
          isLoading={isLoading}
          onDownloadClick={onDownloadBill}
          onToggleBillPay={onToggleBillPay}
        />
      </Box>
      <Stack flexDirection={"row"} justifyContent={"flex-end"}>
        {((!isIndividualBill && billing?.productName === ProductName.ZEV_COMFORT) ||
          (!isIndividualBill && isAdminPortal())) && (
          <>
            <PrimaryOutlinedButton
              disabled={isDownloadingAllCsv}
              isLoading={isDownloadingAllCsv}
              label={t("bills.downloadCSV")}
              onClick={onDownloadAllCsv}
              sx={{ textTransform: "none" }}
            />
            <PrimaryButton
              sx={{ mx: 3 }}
              disabled={isDownloadingAllPdf}
              isLoading={isDownloadingAllPdf}
              onClick={onDownloadAllBills}
              label={t("bills.downloadAllBills")}
            />
          </>
        )}
        {showSapButton && (
          <PrimaryButton
            disabled={isSendingToSAP}
            isLoading={isSendingToSAP}
            label={t("billings-participant:detail.finalise.sendToSap")}
            onClick={onSendSAP}
          />
        )}
      </Stack>
    </>
  )
}

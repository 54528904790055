import { Paper, styled, Typography } from "@mui/material"

export const TariffText = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  fontSize: theme.typography.pxToRem(14),
  letterSpacing: 1.2,
  color: theme.palette.text.secondary,
}))

export const TariffSectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: "#3C403C",
  paddingBottom: theme.spacing(1),
  fontSize: theme.typography.pxToRem(14),
}))

export const TariffSectionWrapper = styled(Paper, {
  shouldForwardProp: (prop) => prop !== "editMode",
})<{ editMode: boolean }>(({ theme, editMode }) => ({
  width: "fit-content",
  padding: theme.spacing(3),
  paddingLeft: editMode ? theme.spacing(3) : 0,
  borderRadius: theme.spacing(1),
  boxShadow: editMode ? "auto" : "none",
  background: editMode ? theme.palette.background.editPrimary : "transparent",
}))

import { useTranslation } from "react-i18next"
import dashboardChartBackground from "url:../../../../../../res/dashboardChartBackground.jpg"
import { Box, Grid, Typography } from "@mui/material"
import { DashboardChartGridIcon } from "./DashboardChartGridIcon"
import { DashboardChartArrowLongIcon } from "./DashboardChartArrowLongIcon"
import { DashboardChartArrowShortIcon } from "./DashboardChartArrowShortIcon"
import { CSSProperties } from "react"
import { DashboardZevOverview } from "../../../../../domain/portal/manager/dashboard/Dashboard.Model"

const rootStyle: CSSProperties = {
  display: "flex",
  flexDirection: "row",
  alignContent: "flex-start",
  minHeight: "290px",
}

const productionChartStyle: CSSProperties = {
  flexShrink: 0,
  width: "180px",
  height: "200px",
  padding: "44px 0px 44px 422px",
  background: `url('${dashboardChartBackground}')`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "100%",
}

const consumptionChartStyle: CSSProperties = {
  maxWidth: "266px",
  padding: "44px 8px",
}

const arrowsStyle: CSSProperties = {
  position: "relative",
  flexGrow: 0,
  width: "266px",
  padding: "8px 0",
}

const gridIconWrapperStyle: CSSProperties = {
  position: "absolute",
  top: "50%",
  right: "-8px",
  transform: "translate(100%, -50%)",
  width: "52px",
  height: "52px",
  padding: "21px",
  borderRadius: "50%",
  background: "#f6f9f6",
}

export const DashboardChartView = (props: DashboardZevOverview) => {
  const { t } = useTranslation("dashboard")
  const { totalProduction, totalSumProduction, totalSold, totalBought } = props

  const legendStyle = { fontSize: "0.875em" }
  return (
    <div style={rootStyle}>
      <div style={productionChartStyle}>
        <Grid container direction="column" spacing={2}>
          <Grid container item direction="column" spacing={0}>
            <Typography component="h6" variant="h6">
              {totalProduction}
            </Typography>
            <Typography sx={legendStyle}>{t("chart.solarProduction")}</Typography>
          </Grid>
          <Grid container item justifyContent="center" style={{ width: "120px" }}>
            <DashboardChartArrowShortIcon />
          </Grid>
          <Grid container item direction="column" spacing={0}>
            <Typography component="h6" variant="h6">
              {totalSumProduction}
            </Typography>
            <Typography sx={legendStyle}>{t("chart.ownConsumption")}</Typography>
          </Grid>
        </Grid>
      </div>

      <Grid container direction="column" justifyContent="center" style={consumptionChartStyle}>
        <Grid container item direction="column">
          <Typography align="center" component="h6" variant="h6">
            {totalSold}
          </Typography>
          <Typography sx={legendStyle} align="center">
            {t("chart.powerSupplied")}
          </Typography>
        </Grid>

        <Grid style={arrowsStyle} item>
          <DashboardChartArrowLongIcon color="#EC6A0A" />
          <DashboardChartArrowLongIcon color="#0099DB" style={{ transform: "scaleX(-1)" }} />
          <Box style={gridIconWrapperStyle}>
            <DashboardChartGridIcon />
          </Box>
        </Grid>

        <Grid container item direction="column">
          <Typography align="center" component="h6" variant="h6">
            {totalBought}
          </Typography>
          <Typography sx={legendStyle} align="center">
            {t("chart.powerPurchased")}
          </Typography>
        </Grid>
      </Grid>
    </div>
  )
}

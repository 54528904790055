import { I18nNamespace } from "./App.i18n"

export const SharedErrorI18n: I18nNamespace = {
  name: "error-codes",
  de: {
    ZEV_PRICE_PACKAGE_NOT_FOUND: "Das Preispaket konnte nicht gefunden werden",

    PRICE_PACKAGE_ALREADY_EXISTS: "Das Preispaket existiert bereits",
    PRICE_PACKAGE_ONLY_AVAILABLE_ON_POWER_METER: "Das Preispaket kann nur für physische Leistungsmesser zahler",
    FORGOT_PASSWORD_TOKEN_VALIDATION:
      "Der von Ihnen genutzte Link ist ungültig. Bitte fordern Sie einen neuen Link an.",
    FORGOT_PASSWORD_ACTION_DONE:
      "Sie haben Ihr Passwort bereits mit diesem Link zurückgesetzt. Bitte fordern Sie einen neuen Link an.",
    FORGOT_PASSWORD_ACTION_EXPIRED:
      "Der von Ihnen genutzte Link ist abgelaufen. Bitte fordern Sie einen neuen Link an.",
    USER_ACCOUNT_MISCONFIGURED_PERSON:
      "Ihr Benutzerkonto ist noch nicht freigeschaltet. Bitte kontaktieren Sie den Support.",
    USER_ACCOUNT_MISCONFIGURED_ROLE:
      "Ihr Benutzerkonto hat keine Berechtigung für dieses Portal. Bitte kontaktieren Sie den Support.",
    USER_ACCOUNT_MISCONFIGURED_MANAGER:
      "Your user account does not have the appropriate authorization. Please contact support.",
    USER_ACCOUNT_VERIFICATION_PENDING:
      "Ihr Benutzerkonto ist noch nicht verifiziert. Bitte bestätigen Sie Ihre E-Mail-Adresse.",
    USER_ACCOUNT_INVALID_CREDENTIALS: "Ihr Benutzername oder Passwort ist ungültig. Bitte versuchen Sie es erneut.",
    USER_ACCOUNT_INVALID_PASSWORD: "Das angegebene Passwort ist ungültig. Bitte versuchen Sie es erneut.",
    USER_ACCOUNT_EMAIL_ACTION_DONE:
      "Der von Ihnen genutzte Link wurde bereits verwenden. Bitte kontaktieren Sie den Support, " +
      "falls Sie sich nicht im Portal anmelden können.",
    USER_ACCOUNT_EMAIL_UNAVAILABLE:
      "Die eingegebene E-Mail-Adresse ist bereits in Gebrauch. Bitte versuchen Sie es mit einer anderen " +
      "E-Mail-Adresse oder kontaktieren Sie den Support.",
    USER_ACCOUNT_EMAIL_TOKEN_VALIDATION:
      "Die von Ihnen genutze Link ist ungültig. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",
    USER_ACCOUNT_SET_PASSWORD_ACTION_DONE:
      "Sie haben Ihr Passwort bereits mit diesem Link zurückgesetzt. Bitte fordern Sie einen neuen Link an.",
    USER_ACCOUNT_SET_PASSWORD_ACTION_EXPIRED:
      "Der von Ihnen genutzte Link ist abgelaufen. Bitte fordern Sie einen neuen Link an.",
    USER_ACCOUNT_SET_PASSWORD_TOKEN_VALIDATION:
      "Die von Ihnen genutze Link ist ungültig. Bitte versuchen Sie es erneut oder kontaktieren Sie den Support.",
    USER_ACCOUNT_ALREADY_SETUP: "Dieses Profil hat bereits ein Benutzerkonto.",
    USER_ACCOUNT_VERIFICATION_EXPIRED:
      "Der von Ihnen genutzte Link ist abgelaufen. Bitte fordern Sie einen neuen Link an.",
    USER_ACCOUNT_BLOCKED:
      "Dieses Benutzerkonto ist derzeit deaktiviert. Versuchen Sie mit einem 'Passwort vergessen' " +
      "Ihr Benutzerkonto zu aktivieren. Falls dies nicht erfolgreich ist, bitte kontaktieren Sie den Support.",
    USER_ACCOUNT_INVALID_BLOCK_ACTION_USER: "Sie können nicht Ihr eigenes Benutzerkonto sperren.",
    INVALID_PASSWORD: "Das angegebene Passwort ist ungültig. Bitte versuchen Sie es erneut.",
    INVALID_EMAIL: "Die angegebene E-Mail ist ungültig. Bitte versuchen Sie es erneut.",
    ZEV_INVALID_ZEV_MANAGER: "Sie können keinen Administrator als ZEV Verwalter für ein ZEV verwenden.",
    ZEV_INVALID_BILLING_DATE:
      "Das Datum, ab dem der Zähler abgerechnet werden kann, muss mit dem Datum des Beginns der Dienstleistung oder nach dem Datum des Beginns der aktuellen Abrechnung des Zählers liegen.",
    OBJECT_NOT_FOUND: "Das gewünschte Objekt konnte nicht gefunden werden.",
    VALIDATION_ERROR:
      "„Für den Abrechnungsbereich WASSERRECHNUNG müssen Wasserrechnungszuordnungsmedien vorhanden sein, für andere Abrechnungsbereiche müssen sie leer sein“",
    OTHER: "Etwas ist schief gelaufen :(",
    PERMISSION_ERROR: "Sie haben nicht die erforderlichen Rechte.",
    INVALID_ADMIN_EMAIL_DOMAIN: "Diese E-Mail Domäne ist für Administratoren nicht zugelassen.",
    INVALID_PRICE_COMPONENT_VALID_FROM_MONTH_START:
      "Das 'Gültig von'-Datum der Preiskomponente muss der erste Tag des Monats sein.",
    INVALID_PRICE_COMPONENT_VALID_UNTIL_BLANK_MONTH_END:
      "Das 'Gültig bis'-Datum der Preiskomponente muss entweder leer sein oder der letzte Tag eines Monats sein.",
    INVALID_STATE_TRANSITION: "Das Objekt kann nicht in gewünschten Status gesetzt werden.",
    INVALID_PRICE_COMPONENT_PRODUCT_LINK: "Die Preiskomponente ist keinem Produkt zugewiesen",
    INVALID_HEATING_COST_SHARES: "Es müssen entweder Heizung und Warmwasser beide oder keine konfiguriert sein",
    SERVICE_COMPONENT_NAME_UNAVAILABLE: "Der Name der Servicekomponente ist nicht verfügbar.",
    CONTRACT_ZEV_HAS_RELEASED_OR_ACTIVE_CONTRACT:
      "Es kann jeweils nur ein freigegebener Vertrag für ein ZEV vorliegen. Verwenden Sie die Ersetzen " +
      "Funktion, falls Sie einen Vertrag ändern möchten.",
    CONTRACT_ZEV_ACTIVATION_WITHOUT_PREDECESSOR:
      "Der Vertrag kann ohne den vorherigen Vertrag nicht aktiviert werden, ausser es ist der erste Vertrag.",
    INVALID_CONSUMPTION_POINT_STATE: "Die Verbrauchsstelle ist in einem ungültigem Zustand.",
    PRODUCT_USED_IN_CONTRACT:
      "Das Produkt kann nicht gelöscht werden, da es in einem oder mehreren Verträgen verwendet wird.",
    PRODUCT_NOT_LISTED:
      "Das Produkt kann nicht gelöscht werden, da es nicht aufgeführt ist oder bereits gelöscht wurde.",
    INVALID_METERING_CODE_LENGTH: "Der Metering Code muss 33 Zeichen lang sein.",
    NO_VALID_CONTRACT_FOUND: "Es wurde kein aktiver Vertrag für diesen ZEV gefunden.",
    PARTICIPATION_VACANCY_MODIFICATION:
      "Ein Leerstand kann nicht geändert werden. Ändern Sie das Einzugs- oder Auszugsdatum des " +
      "vorherigen oder nachfolgenden ZEV-Teilnehmers.",
    PARTICIPATION_FIRST_ENTRY_DATE_MODIFICATION:
      "Das Einzugsdatum des ersten ZEV-Teilnehmers oder Leerstands kann nicht geändert werden. " +
      "Es wird automatisch anhand des ZEV Startdatums gesetzt.",
    PARTICIPATION_MOVE_OUT_DATE_UNDEFINED:
      "Es ist noch kein Auszug definiert. Daher kann das Auszugsdatum nicht geändert werden.",
    PARTICIPATION_MOVE_BILL_EXISTS:
      "Dieser ZEV-Teilnehmer kann nicht geändert werden, da bereits ein oder mehrere freigegebene " +
      "Abrechnungen vorliegen. Falls Sie den Teilnehmer ändern möchten, müssen die betroffenen " +
      "Abrechnungen zuerst in den Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung " +
      "und klicken Sie auf 'Bearbeiten'.",
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_NO_MODIFICATION:
      "Ein Leerstand kann nicht geändert werden. Ändern Sie das Einzugs- oder Auszugsdatum des " +
      "vorherigen oder nachfolgenden ZEV-Teilnehmers.",
    PARTICIPATION_CANCEL_MOVE_IN_KNOWN_SUCCESSOR:
      "Dieser ZEV-Teilnehmer kann nicht gelöscht werden, wenn bereits ein Nachfolger definiert ist.",
    PARTICIPATION_CANCEL_MOVE_IN_BILL_EXISTS:
      "Dieser ZEV-Teilnehmer kann nicht gelöscht werden, da bereits ein oder mehrere freigegebene " +
      "Abrechnungen vorliegen. Falls Sie den Teilnehmer ändern möchten, müssen die betroffenen Abrechnungen " +
      "zuerst in den Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung und klicken Sie auf " +
      "'Bearbeiten'.",
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_PERIOD_BILLED:
      "Dieser ZEV-Teilnehmer kann nicht gelöscht werden, da bereits Leerstände vollständig abgerechnet wurden. " +
      "Falls Sie den Teilnehmer löschen möchten, müssen die betroffenen Abrechnungen zuerst in den " +
      "Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung und klicken Sie auf 'Bearbeiten'. ",
    PARTICIPATION_CANCEL_MOVE_OUT_VACANCY_NO_MODIFICATION:
      "Ein Leerstand kann nicht geändert werden. Ändern Sie das Einzugs- oder Auszugsdatum des vorherigen " +
      "oder nachfolgenden ZEV-Teilnehmers.",
    PARTICIPATION_CANCEL_MOVE_OUT_DATE_UNDEFINED:
      "Es ist kein Auszug gelöscht werden, da noch kein Auszug gemeldet wurde.",
    PARTICIPATION_CANCEL_MOVE_OUT_KNOWN_SUCCESSOR:
      "Ein Auszug kann nicht gelöscht werden, wenn bereits ein neuer ZEV-Teilnehmer als Nachfolger definiert ist.",
    PARTICIPATION_CANCEL_MOVE_OUT_NEXT_PARTICIPANT_KNOWN_SUCCESSOR:
      "Ein Auszug kann nicht gelöscht werden, wenn bereits ein neuer ZEV-Teilnehmer als Nachfolger definiert ist.",
    PARTICIPATION_CANCEL_MOVE_OUT_BILL_EXISTS:
      "Der Auszug des ZEV-Teilnehmers kann nicht gelöscht werden, da bereits ein oder mehrere freigegebene " +
      "Abrechnungen vorliegen. Falls Sie den Auszug löschen möchten, müssen die betroffenen Abrechnungen " +
      "zuerst in den Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung und klicken " +
      "Sie auf 'Bearbeiten'.",
    PARTICIPATION_MOVE_IN_VACANCY_ONLY: "Ein Einzug kann nur auf einem Leerstand erfasst werden.",
    PARTICIPATION_MOVE_IN_DATE_BEFORE_VACANCY_START:
      "Das Einzugsdatum des ZEV-Teilnehmers kann nicht vor dem Beginn des Leerstands liegen.",
    PARTICIPATION_MOVE_IN_VACANCY_NO_SUCCESSOR:
      "Ein Einzug kann nicht auf einem Leerstand erfasst werden, wenn bereits ein Nachfolger erfasst ist.",
    PARTICIPATION_MOVE_IN_UNKNOWN_ZEV_PARTICIPANT: "Dieser ZEV-Teilnehmer ist nicht Teil dieses ZEVs.",
    PARTICIPATION_MOVE_IN_BILL_EXISTS:
      "Der Einzug des ZEV-Teilnehmers kann nicht erfasst werden, da für den Leerstand bereits ein oder mehrere " +
      "freigegebene Abrechnungen vorliegen. Falls Sie den Einzug erfassen möchten, müssen die betroffenen " +
      "Abrechnungen zuerst in den Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung und " +
      "klicken Sie auf 'Bearbeiten'.",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_VACANCY_NO_MODIFICATION:
      "Ein Leerstand kann nicht geändert werden. Ändern Sie das Einzugs- oder Auszugsdatum des vorherigen " +
      "oder nachfolgenden ZEV-Teilnehmers.",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_KNOWN_SUCCESSOR:
      "Es kann kein Auszug erfasst werden, wenn bereits ein Nachfolger erfasst ist.",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_DATE_AFTER_MOVE_IN_DATE:
      "Das Auszugsdatum des ZEV-Teilnehmers muss nach dem Einzugsdatum des ZEV-Teilnehmers sein.",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_BILL_EXISTS:
      "Der Auszug des ZEV-Teilnehmers kann nicht erfasst werden, da bereits ein oder mehrere freigegebene " +
      "Abrechnungen vorliegen. Falls Sie den Auszug erfassen möchten, müssen die betroffenen Abrechnungen " +
      "zuerst in den Bearbeitungs-Modus gesetzt werden. Gehen Sie dazu zu einer Abrechnung und klicken Sie " +
      "auf 'Bearbeiten'.",
    SERVICE_BILLING_PROVIDE_START_END_DATES:
      "Sie müssen das Start- und Enddatum für eine wiederkehrende Abrechnung definieren.",
    SERVICE_BILLING_BILLED_ZEV_ACTIVE_TERMINATED:
      "Es können nur aktive oder inaktive & terminierte Zevs in Rechnung gestellt werden.",
    BILL_PRICES_NOT_PROVIDED:
      "Um eine Abrechnung zu erstellen, müssen Sie die ZEV Strompreise eintragen. Diese können Sie jederzeit im Bereich Einstellungen anpassen.",
    SERVICE_BILLING_RUN_OVERLAPPING_OBJECT: "Es gibt bereits eine Dienstleistungsabrechnung für diese Periode.",
    CONTRACT_TERMINATION_DATE: "Das Enddatum muss nach dem Startdatum sein.",
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_INITIAL_BILLS:
      "Der vorherige Vertrag kann nicht komplett ersetzt werden, da noch initiale Dienstleistungsabrechnungen " +
      "für diesen existieren. Stornieren oder entfernen Sie diese, um den Vertrag zu ersetzen.",
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_RECURRING_BILLS:
      "Der vorherige Vertrag kann nicht komplett ersetzt werden, da noch wiederkehrende Dienstleistungsabrechnungen " +
      "für diesen existieren. Stornieren oder entfernen Sie diese, um den Vertrag zu ersetzen.",
    PARTICIPATION_START_BEFORE_END: "Das Einzugsdatum des Teilnehmers muss vor dem Auszugsdatum sein.",
    PARTICIPATION_END_AFTER_START: "Das Auszugsdatum des Teilnehmers muss nach dem Einzugsdatum sein.",
    ALL_BILLING_INVALID_STATE_CUSTOMER:
      "Die Daten stehen nicht mehr zur Verfügung. Wir werden Sie per E-Mail benachrichtigen, sobald dieser" +
      " wieder verfügbar sind.",
    INDV_BILLING_INVALID_STATE_CUSTOMER:
      "Die Daten stehen nicht mehr zur Verfügung. Wir werden Sie per E-Mail benachrichtigen, sobald dieser" +
      " wieder verfügbar sind.",
    INDV_BILLING_BEFORE_SYSTEM_START:
      "Die Rechnungsperiode einer Mieterwechselabrechnung muss nach dem Systemstart liegen.",
    USER_ACCOUNT_ZEV_MANAGER_UNASSIGNED:
      "Für dieses Profil kann noch kein Login erstellt werden, da dieses Profil noch keinem ZEV als ZEV Verwalter " +
      "zugeordnet ist.",
    ZEV_EXTERNAL_REFERENCE_NUMBER_MISSING: "Zevs externe Referenznummer fehlt.",
    PRICE_COMPONENT_EXTERNAL_REFERENCE_NUMBER_MISSING:
      "Einer oder mehreren Preiskomponenten fehlt die externe Referenznummer.",
    SAP_SERVICE_BILLING_RECURRING_DATES_NULL:
      "Bei der wiederkehrenden Service-Abrechnung fehlt entweder das Startdatum oder das Enddatum.",
    SAP_SERVICE_BILLING_TYPES_MISMATCH: "Nicht alle Service Billing-Typen sind gleich.",
    SAP_GENERAL_FAILURE: "Beim SAP-Anbieter ist ein Fehler aufgetreten.",
    PARTICIPANT_DELETE_IN_USE:
      "Der Teilnehmer kann nicht gelöscht werden, da er in mindestens einer anderen Teilnahme verwendet wird.",
    SERVICE_BILLING_RUN_SAP_UPDATE_SOME_WITHOUT_RESULT: "Ein oder mehrere Einträge haben kein Ergebnis",
    SERVICE_BILLING_RUN_SAP_UPDATE_MISMATCH:
      "Ein oder mehrere Einträge gehören nicht zu diesem Service-Abrechnungslauf",
    SERVICE_BILLING_RUN_SAP_UPDATE_MISSING_DATA: "Ein oder mehrere Einträge fehlen",
    PRODUCT_COLLECTION_RESTRICTED_ACCESS:
      "Der Zugriff auf diese Ressource wird basierend auf dem Typ der Servicekomponente des Produkts eingeschränkt: " +
      "Inkasso",
    PARTICIPANTS_ALREADY_CONFIRMED_SAP_SYNC: "Der Teilnehmer wurde bereits synchronisiert.",
    BILLING_ZEV_MANAGER_ALREADY_APPROVED:
      "Der Zev-Manager kann keine Operation für eine bestätigte Abrechnung ausführen",
    ADMIN_EMAIL_CHANGE_NOT_PERMITTED: "Die E-Mail-Adresse des Administrators kann nicht geändert werden.",
    ADMIN_PROFILE_DELETION_NOT_PERMITTED: "Das Administratorprofil kann nicht gelöscht werden.",
    ZEV_PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA: "Für das angegebene Jahr sind nicht genügend ZEV-Daten verfügbar.",
    ZEV_PRICE_CALCULATOR_MISSING_INPUT: "Dem Zev fehlt der Eingabepreisrechner.",
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_TARIFFS: "Für das angegebene Jahr gibt es keine Tarife.",
    ZEV_PRICE_CALCULATOR_PRICES_HIGHER_THAN_REFERENCE:
      "Die berechneten ZEV-Netzkauf- und DL-Kosten sind höher als die des Referenzprodukts.",
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_VAT_RATE:
      "Der Mehrwertsteuersatz für das angegebene Jahr konnte nicht gefunden werden.",
    SAP_MISCONFIGURED_PARTICIPANT: "Einem oder mehreren Teilnehmern fehlt ihre externe Referenznummer.",
    ZEV_PRICE_CALCULATOR_MISSING_ENERGY_DATA: "Zev-Preisrechner fehlen Energiedaten.",
    USER_ACCOUNT_ZEV_MANAGER_INACTIVE_CONTRACT:
      "Das Profil ist nicht an mindestens ein ZEV gehängt, welches aktiv ist und einen aktiven Vertrag hat.",
    ZEV_PRICE_CALCULATOR_INCONSISTENT_INPUT_VALUES:
      "Basierend auf Ihren Angaben kann kein Preis berechnet werden. Dieser Fehler tritt auf, wenn ihre Angaben " +
      "inkonsistent sind. Bitte überprüfen Sie Ihre Angaben oder kontaktieren Sie unseren Support.",
    USER_DELETION_FAILED:
      "Das Löschen des Benutzers ist fehlgeschlagen. Bitte kontaktieren Sie Ihren Systemadministrator.",
    PROFILE_DELETION_MANAGING_ZEV:
      "Der ZEV-Verwalter konnte nicht gelöscht werden, da er in einer ZEV registriert ist. Bitte entfernen Sie den " +
      "ZEV-Verwalter aus der ZEV, bevor Sie fortfahren.",
    INVALID_BILLING_FREQUENCY_ON_VEWA_CONTRACT: "Ungültige abrechnungshäufigkeit im VEWA-vertrag",
    INVALID_BILLING_FREQUENCY_ON_ZEV_CONTRACT: "Ungültige abrechnungshäufigkeit bei ZEV-vertragsart",
    CANNOT_MODIFY_BILLING_FREQUENCY_ON_VEWA_CONTRACT:
      "Abrechnungshäufigkeit bei VEWA-vertragsart kann nicht geändert werden",
    INVALID_NEXT_BILLING_FREQUENCY_ON_ZEV_CONTRACT: "Ungültige nächste abrechnungshäufigkeit bei ZEV-vertrag",
    BILLING_DATE_DOES_NOT_MATCH_BILLING_FREQUENCY:
      "Sie haben ein Abrechnungsdatum angegeben, das nicht mit der Abrechnungshäufigkeit übereinstimmt",
    BILLING_DATE_OUTSIDE_OF_VALID_DATE_RANGE: "Rechnungsdatum ausserhalb des gültigen datum bereichs",
    BUILDING_METERS_BLOCK_REMOVAL_OF_MEDIUM:
      "Sie können vorhandene Abrechnungsbereiche nicht entfernen. Sie können Prozentsätze nur für bestehende Abrechnungsbereiche aktualisieren",
    UTILITY_UNIT_METERS_BLOCK_REMOVAL_OF_MEDIUM: "Nutzeinheit meter, blockentfernung vom medium",
    NO_ADDITIONAL_METER_IS_ALLOWED_ON_THIS_TOPOLOGY_ELEMENT:
      "Auf diesem Topologie Element ist kein zusätzlicher Zähler dieses Mediums und Nutzungstyps zulässig",
    CONTRACT_PRODUCT_SHOULD_MATCH_PREDECESSOR_PRODUCT:
      "Das vertragsprodukt sollte dasselbe wie das vorgängerprodukt sein",
    INVALID_METER_MEDIUM:
      "Ungültiges zählermedium. \n Medium sollte sich in einer Vertragskonfigurator befinden, damit ein Medium hinzugefügt werden kann",
    SECOND_PHYSICAL_POWER_METER_NOT_SUPPORTED:
      "Das Hinzufügen eines zweiten physischen Leistungsmessers zu dieser Versorgungseinheit wird nicht unterstützt",
    PRICE_PACKAGE_ID_MUST_BE_SET: "Preispaket-ID muss eingestellt werden",
    PRICE_PACKAGE_ID_CAN_ONLY_BE_SET_ON_PHYSICAL_METER:
      "Preispaket-ID kann nur am physikalischen zähler eingestellt werden",
    POWERMETER_TYPE_MISSING_FOR_PHYSICAL_METER: "Leistungszähler typ für physikalischen zähler fehlt",
    UTILITY_UNIT_FOR_BILLING_MUST_HAVE_ONE_PHYSICAL_POWERMETER:
      "Ein physischer Stromzähler muss mit der Versorgungseinheit verbunden sein",
    PRICES_NOT_AVAILABLE_FOR_INDIVIDUAL_PARTICIPANT_BILLING:
      "Die Preise sollen für die individuelle Teilnehmer Abrechnung verfügbar sein",
    INVALID_PRICE_PACKAGE_ID: "Ungültige Preispaket-ID",
    INVALID_USAGE_TYPE: "Ungültiger Verwendungstyp",
    ILLEGAL_PROTOCOL_STATE: "Die Aktion wird nur für den Status „Aktiv“ unterstützt",
    PRICES_CONTAIN_INVALID_NUMBERS: "Preis enthält ungültige zahlen/zeichen",
    INVALID_PRICE_PACKAGE_ORDER: "UNGÜLTIGE PREISPAKETBESTELLUNG",
    CANNOT_DELETE_PRICES_IN_DEFAULT_PRICE_PACKAGE: "Die Standardpreise im Preispaket können nicht gelöscht werden",
    CANNOT_DELETE_DEFAULT_PRICE_PACKAGE: "Das Standard-Preis Paket kann nicht gelöscht werden",
    CANNOT_CHANGE_PRODUCT: "Produkt kann nicht geändert werden",
    CANNOT_DELETE_BUILDING_LINKED_TO_CONTRACT: "Das mit dem Vertrag verknüpfte Gebäude kann nicht gelöscht werden",
    CANNOT_DELETE_BUILDING_WITH_LINKED_METER: "Gebäude mit verknüpftem Zähler kann nicht gelöscht werden",
    METER_WITH_GIVEN_MEDIUM_AND_USAGE_TYPE_ALREADY_EXISTS:
      "Zähler mit angegebenem medium und anwendungsart besteht bereits",
    CONTRACT_END_DATE_SHOULD_BE_AT_THE_END_OF_BILLING_PERIOD:
      "Das Vertragsende sollte zum Ende eines Abrechnungszeitraums liegen",
    POWER_METER_TYPE_MUST_ONLY_BE_SET_FOR_PHYSICAL_METER:
      "Der Leistungsmesser Typ darf nur für physische Leistungsmesser in Versorgungseinheiten eingestellt werden",
    PRICE_PACKAGE_NOT_SET_ON_POWER_METER:
      "Für physische Leistungsmesser in Versorgungseinheiten muss eine Preispaket-ID festgelegt werden",
    BUSINESS_RULES_NOT_SATISFIED: "Bei einem Grundkostenanteil ungleich null darf die Summe der Fläche nicht null sein",
    BILLING_FREQUENCY_CAN_ONLY_BE_SET_IN_DRAFT_STATUS:
      "Die Abrechnungshäufigkeit kann nur im ENTWURF-Status festgelegt werden",
    ZEV_PRICE_PACKAGE_NOT_IN_BILLING:
      "Das Preispaket kann dem Zähler nicht zugewiesen werden, da für diesen Zähler offene Rechnungen existieren, welche das neue Preispaket nicht enthalten.",
    ALL_UTILITY_UNITS_MUST_HAVE_ELECTRICITY_METER: "Alle Nutzeinheiten müssen einen Stromzähler haben",
    AT_LEAST_ONE_INCOMING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG:
      "Mindestens ein Zähler muss mit der ZEV-Konfiguration verknüpft sein",
    AT_LEAST_ONE_OUTGOING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG:
      "Mindestens ein Zähler muss mit der ZEV-Konfiguration verknüpft sein",
    BUILDING_CAN_ONLY_BE_LINKED_TO_ONE_CONTRACT_PER_PRODUCT:
      "Ein Gebäude kann nur mit einem Vertrag pro Produkt verknüpft werden",
    BUILDINGS_CAN_ONLY_BE_REMOVED_IN_DRAFT_STATE: "Gebäude können nur im ENTWURF-Status entfernt werden",
    BUILDINGS_MUST_BELONG_TO_SAME_AREA_AS_CONTRACT: "Gebäude müssen demselben Areal wie der Vertrag angehören",
    INITIAL_CONTRACT_WITHOUT_PREDECESSOR_MUST_BE_PARENT: "Ein Vertrag ohne Vorgänger muss ein Elternvertrag sein",
    THERE_MUST_BE_AT_LEAST_ONE_PARTICIPANT_FOR_EACH_UTILITY_UNIT:
      "Für jede Nutzeinheit muss mindestens ein Teilnehmer vorhanden sein",
    THERE_MUST_BE_ONE_POWER_METER_FOR_EACH_UTILITY_UNIT: "Für jede Nutzeinheit muss ein Stromzähler vorhanden sein",
    NOT_PRESENT_MOVE_IN_DATE_CAN_NOT_BE_CHANGED: "Zum Ändern muss das Einzugsdatum vorhanden sein",
    PARTICIPANT_FROM_DIFFERENT_AREA: "Die Teilnehmer gehören einem anderen Bereich an",
    SERVICE_BILLING_BILLED_AREA_ACTIVE_TERMINATED: "Only active or inactive  terminated  areas can be billed",
    INDIVIDUAL_BILLING_AFTER_SERVICE_START_DATE: "Einzelabrechnung nach Service-Startdatum-Ausnahme",
    THE_BILLING_PROCESS_CONTAINS_NO_BILL: "Der Abrechnungsprozess enthält keine Rechnung",
    METER_INACTIVE_BILLABLE_DATES: "„Abrechenbar Bis“ muss nach „Abrechenbar Von“ liegen",
    METER_INACTIVE_NOT_LINKED_TO_CONTRACT: "Inaktive Zähler müssen mit einer Vertragskonfiguration verknüpft sein",
    CONTRACT_SHOULD_NOT_HAVE_SUCCESSOR: "Der Vertrag sollte keinen Nachfolger haben",
    METER_START_BEFORE_END: "Das Startdatum des Zählers muss vor seinem Enddatum liegen",
    METER_UPDATE_START_DATE_WITH_EXISTING_BILLS:
      "Der erste Teilnehmer hat bereits genehmigte Rechnungen. Das Startdatum kann nicht mehr geändert werden",
    SERVICE_BILLING_RUN_IS_MISSING_FOR_RECURRING_SERVICE_BILLING:
      "Für die wiederkehrende Serviceabrechnung muss ein Serviceabrechnungslauf verfügbar sein",
    START_DATE_MUST_BE_EQUALS_TO_END_DATE_FOR_INITIAL_SERVICE_BILLING:
      "Das Startdatum muss mit dem Enddatum für die erste Serviceabrechnung übereinstimmen",
    START_DATE_MUST_BE_BEFORE_END_DATE_FOR_RECURRING_SERVICE_BILLING:
      "Das Startdatum muss vor dem Enddatum für die wiederkehrende Serviceabrechnung liegen",
    ZEV_CONFIG_MUST_BE_LINKED_TO_A_CONTRACT_CONFIG:
      "Die ZEV-Konfiguration muss mit einer Vertragskonfiguration verknüpft sein",
    INDIVIDUAL_PARTICIPANT_BILLINGS_ONLY_AVAILABLE_FOR_MEDIUM_ELECTRICITY:
      "Individuelle Teilnehmerabrechnungen sollten nur für ZEVs erstellt werden",
    BILLING_PROCESS_CAN_NOT_START_BEFORE_SERVICE_START_DATE:
      "Ein Abrechnungsprozess kann erst am oder nach dem Service-Startdatum beginnen",
    USAGE_TYPE_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT:
      "Dieser Verwendungstyp wird für dieses Topologieelement nicht unterstützt",
    USAGE_TYPE_ON_METER_NOT_IN_SYNC_WITH_UTILITY_UNIT_USAGE_TYPE:
      "Der Nutzungstyp auf dem Zähler muss mit dem Nutzungstyp auf der Versorgungseinheit übereinstimmen",
    THIS_MEDIUM_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT:
      "Dieses Medium wird für dieses Topologieelement nicht unterstützt",
    POWER_METER_TYPE_NOT_SET_FOR_PHYSICAL_METER:
      "Der Stromzählertyp muss für Stromzähler in Versorgungseinheiten eingestellt werden",
    THE_ZEV_CONFIG_NOT_LINKED_TO_CONTRACT_CONFIG:
      "Die ZEV-Konfiguration ist nicht mit einer Vertragskonfiguration verknüpft",
    USAGE_TYPE_UNDEFINED_IS_NOT_SUPPORTED_ON_UTILITY_UNITS:
      "Der nicht definierte Nutzungstyp wird für Versorgungseinheiten nicht unterstützt",
    EMAIL_UPDATE: "Die E-Mail-Aktualisierung ist fehlgeschlagen",
    MISSING_PHYSICAL_METER:
      "Eine in einen Abrechnungsprozess einbezogene Versorgungseinheit muss über einen Stromzähler verfügen",
    START_DATE_MUST_BE_BEFORE_END_DATE: "Das Startdatum muss vor dem Enddatum liegen",
    BILLING_PERIODS_NOT_ALIGNED_WITH_SERVICE_DATES:
      "Die Abrechnungszeiträume überschreiten das Service-Startdatum und/oder das Service-Enddatum des Zev",
    APPROPRIATE_CONTRACT_FOR_CONTRACT_CONFIG_IS_MISSING:
      "Wenn kein Vorgängervertrag vorhanden ist, kann keine bestehende Vertragskonfiguration weitergegeben werden",
    PERSON_IS_NOT_ZEV_MANAGER: "Nur Personen mit der Rolle ZEV MANAGER können Vertragskonfigurationsmanager sein",
    PAYMENT_DEADLINE_MUST_BE_POSITIVE_INTEGER: "Die Zahlungsfrist muss eine Zahl größer oder gleich Null sein",
    DEFAULT_PRICE_PACKAGE_AT_INDEX_ZERO_MISSING: "Bei Index 0 muss ein Standardpreispaket vorhanden sein",
    CANNOT_PROVIDE_BOTH_LUMP_SUM_AND_VARIABLE_BILLING_METHODS:
      "Für zusätzliche Abrechnungsleistungen können Sie nicht sowohl Pauschalbeträge als auch variable Abrechnungsmethoden anbieten",
    UNIQUE_ORDER_OF_PRICE_PACKAGES_MISSING: "Jedes Preispaket muss eine eindeutige Reihenfolge haben",
    PRICE_PACKAGE_ORDERS_MUST_BE_POSITIVE: "Preispaketbestellungen müssen positiv sein",
    WRONG_NUMBER_OF_PRICES_PROVIDED: "Sie haben die falsche Anzahl an Preisen angegeben",
    DEFAULT_PRICE_PACKAGE_ID_MUST_NOT_BE_CHANGED:
      "Der ID-Schlüssel für das Standardpreispaket darf nicht geändert werden",
    INVALID_VEWA_MEDIUM: "Einer der Zähler, Medium, wird im jeweiligen Abrechnungsumfang nicht unterstützt",
    INPUT_PARAMETERS_FROM_DIFFERENT_BILLING_SCOPE:
      "Grundkostenanteile sollten nur Kostenanteile innerhalb des jeweiligen Abrechnungsumfangs enthalten",
    CONTRACT_CONFIGURATION_MISSING_ON_SUCCESSOR_CONTRACT:
      "Bei einem Nachfolgevertrag muss bereits eine Vertragskonfiguration vorhanden sein",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_RECURRING_SERVICE_BILLING:
      "Das Vertragsende sollte nicht vor der letzten wiederkehrenden Serviceabrechnung liegen",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INITIAL_SERVICE_BILLING:
      "Vertragsende sollte nach der letzten ersten Serviceabrechnung liegen",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_ALL_PARTICIPANT_BILLING:
      "Das Vertragsende sollte nach der letzten Abrechnung für alle Teilnehmer liegen",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INDIVIDUAL_PARTICIPANT_BILLING:
      "Das Vertragsende sollte nach der letzten Einzelteilnehmerabrechnung liegen",
    CONTRACT_NOT_PART_OF_BILLING: "Der Zev, den Sie entfernen möchten, ist nicht Teil dieser Abrechnung",
    AREA_WITH_REGISTERED_CONTRACT: "Bereich mit registriertem Vertrag kann nicht gelöscht werden",
    CANNOT_REPLACE_IN_VACANCIES: "Teilnehmer können bei vakanten Stellen nicht ersetzt werden",
    CANNOT_REPLACE_AFTER_CREATION_OF_BILL:
      "Der Teilnehmer kann nach der Erstellung der Abrechnung für einzelne oder alle Teilnehmer nicht ersetzt werden.",
    BUILDING_IS_NOT_IN_SCOPE_OF_CONTRACT:
      "Einer Vewa-Gruppenzählerkonfiguration können nur Zähler hinzugefügt werden, die an ein Gebäude angeschlossen sind",
    METER_IS_NOT_ATTACHED_TO_BUILDING:
      "Zähler, die zur Vewa-Konfiguration hinzugefügt werden sollen, müssen im Rahmen des Vertrags an ein Gebäude angeschlossen werden",
    METER_STATE_IS_INVALID:
      "Einer Gruppenzählerkonfiguration können nur AKTIV- und INAKTIV-Zähler hinzugefügt werden, wenn sich der Vertrag bereits im Status FREIGEGEBEN oder AKTIV befindet",
    BILLING_SCOPE_ALREADY_EXIST:
      "Dieser Abrechnungsumfang ist in der Vewa-Kostenbeteiligungskonfiguration bereits vorhanden",
    UNIT_SPACE_IS_NOT_VALID_FOR_PROVIDED_UTILITY_UNIT_TYPE:
      "Auf einer Nutzeinheit dieser Nutzungsart ist der bereitgestellte Einheitenplatz ungültig. Für den Typ Wohneinheit und Gewerbeeinheit beträgt das Minimum 0  m². Für den Typ Allgemeinversorgung und Wärmepumpe ist nur ein leerer Wert zulässig.",
    BILLING_SCOPE_IS_NOT_PRESENT_IN_VEWA_CONFIG:
      "Sie können nur Abrechnungsbereiche aktualisieren, die zuvor hinzugefügt wurden.",
    BILLING_IS_NOT_PRESENT_IN_VEWA_PARTICIPANT_BILLINGS_HISTORY:
      "Damit eine Vewa-Teilnehmerabrechnung gelöscht und entfernt werden kann, muss sie im Abrechnungsverlauf vorhanden sein",
    PRODUCT_HAS_NO_PRICE_COMPONENT_DEFINED_FOR_ONE_ZEV_METER_POWERTYPE:
      "Für das Produkt ist kein Preisbestandteil für einen powerMeterType der ZEV-Zähler definiert",
    INITIAL_BILLINGS_CAN_ONLY_BE_STARTED_FOR_ACTIVE_AREAS:
      "Erstabrechnungen können nur für aktive Bereiche gestartet werden",
    ZEV_CONTRACT_CAN_HAVE_ONLY_ONE_INITIAL_BILLING: "Ein Zev-Vertrag kann nur eine Erstabrechnung haben",
    BILLING_FREQUENCY_NOT_SUPPORTED: "Abrechnungshäufigkeit wird nicht unterstützt",
    SERVICE_BILLING_MUST_BE_OF_TYPE_INITIAL: "Die Serviceabrechnung muss vom Serviceabrechnungstyp Initial sein",
    ALL_PARTICIPANT_BILLING_ALREADY_EXISTS_FOR_REGISTERED_NEXT_BILLING_DATE:
      "Es liegt bereits eine Teilnehmerabrechnung für den eingetragenen nächsten Abrechnungstermin vor",
    ZEV_ALL_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_VEWA_BILLINGS_ARE_PRESENT:
      "Zev alle teilnehmer rechnungen können nicht erstellt werden, wenn vewa-rechnungen vorliegen",
    VEWA_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_ZEV_PARTICIPANT_BILLINGS_ARE_PRESENT:
      "Vewa-Teilnehmerabrechnungen können nicht erstellt werden, wenn Zev-Teilnehmerabrechnungen vorhanden sind",
    NO_RELEASED_OR_ACTIVE_CONTRACT_FOUND_FOR_PROVIDED_DATE:
      "Für das angegebene Datum wurde kein freigegebener oder aktiver Vertrag gefunden.",
    MORE_THAN_ONE_CONTRACT_FOUND_FOR_PROVIDED_DATE: "Für das angegebene Datum wurden mehr als ein Vertrag gefunden",
    NEXT_BILLING_DATE_CAN_ONLY_BE_UPDATED_FOR_VEWA_CONTRACTS:
      "Das nächste Rechnungsdatum kann nur für VEWA-Verträge aktualisiert werden.",
    NEXT_BILLING_DATE_FREQUENCY_CAN_ONLY_BE_UPDATED_FOR_ZEV_CONTRACTS:
      "Die Häufigkeit des nächsten Abrechnungstermins kann nur für Zev-Verträge aktualisiert werden.",
    SAME_DAY_TARIFSS_OVERLAPPING:
      "Überschneidung an Tag zwischen Start- und Endzeit des aktuellen Zeitraums und Start- und Endzeit des nächsten Zeitraums erkannt",
    TARIFFS_MUST_BE_DIVISIBLE_BY_15_MINUTES: "Tarife müssen durch 15 Minuten teilbar sein",
    INVALID_START_OR_END_TIME: "Die Endzeit des Tages muss nach der Startzeit startTime liegen.",
    COMMENT_TEXT_LENGTH_TOO_LONG: "Die Notiz Länge darf nicht mehr als 500 Zeichen betragen",

    // Custom Generic errors
    GENERIC_401: "Sie sind nicht berechtigt diese Aktion auszuführen.",
    GENERIC_FATAL_ERROR: "Etwas ist schief gelaufen",
    GENERIC_TIMEOUT_ERROR: "Die Anfrage konnte nicht erfolgreich bearbeitet werden. Bitte versuchen Sie es erneut.",
    AREA_INACTIVE_TITLE: "Error - Ein Fehler ist aufgetreten",
    ERR_BAD_REQUEST: "Die Anfrage konnte nicht verarbeitet werden oder wurde nicht gefunden.",
    TARIFF_USED_BY_CONTRACT_CANNOT_BE_REMOVED:
      "Tarif kann nicht gelöscht werden da er bereits bei einem Vertrag angewendet wird.",
    METERING_CODE_ALREADY_ASSIGNED_TO_BILLABLE_METER:
      "Der eingegebene Metering Code wird in dieser Topologie bereits verwendet und ist abrechnungsfähig.",
  },
  en: {
    ZEV_PRICE_PACKAGE_NOT_FOUND: "The pricepackage could not be found",
    PRICE_PACKAGE_ONLY_AVAILABLE_ON_POWER_METER: "The price package can only be paid for physical power meters",
    PRICE_PACKAGE_ALREADY_EXISTS: "The pricepackage already exists",
    FORGOT_PASSWORD_TOKEN_VALIDATION: "FORGOT_PASSWORD_TOKEN_VALIDATION",
    FORGOT_PASSWORD_ACTION_DONE: "FORGOT_PASSWORD_ACTION_DONE",
    FORGOT_PASSWORD_ACTION_EXPIRED: "FORGOT_PASSWORD_ACTION_EXPIRED",
    USER_ACCOUNT_MISCONFIGURED_ROLE:
      "Your user account does not have permission for this portal. Please contact support.",
    USER_ACCOUNT_MISCONFIGURED_MANAGER:
      "Ihr Benutzerkonto verfügt nicht über die entsprechende Berechtigung. Bitte wenden Sie sich an den Support.",
    USER_ACCOUNT_MISCONFIGURED_PERSON: "USER_ACCOUNT_MISCONFIGURED_PERSON",
    USER_ACCOUNT_VERIFICATION_PENDING: "USER_ACCOUNT_VERIFICATION_PENDING",
    USER_ACCOUNT_INVALID_CREDENTIALS: "USER_ACCOUNT_INVALID_CREDENTIALS",
    USER_ACCOUNT_INVALID_PASSWORD: "USER_ACCOUNT_INVALID_PASSWORD",
    USER_ACCOUNT_EMAIL_ACTION_DONE: "E-mail action is done",
    USER_ACCOUNT_EMAIL_UNAVAILABLE: "E-mail unavailable",
    USER_ACCOUNT_EMAIL_TOKEN_VALIDATION: "Action not valid",
    USER_ACCOUNT_SET_PASSWORD_ACTION_DONE: "New password setup is done",
    USER_ACCOUNT_SET_PASSWORD_ACTION_EXPIRED: "New password setup has expired",
    USER_ACCOUNT_SET_PASSWORD_TOKEN_VALIDATION: "Invalid new password setup authorisation",
    USER_ACCOUNT_ALREADY_SETUP: "Profile already setup",
    USER_ACCOUNT_VERIFICATION_EXPIRED: "Action has expired",
    USER_ACCOUNT_BLOCKED: "USER_ACCOUNT_BLOCKED",
    USER_ACCOUNT_INVALID_BLOCK_ACTION_USER: "You can't use this action on yourself",
    INVALID_PASSWORD: "INVALID_PASSWORD",
    INVALID_EMAIL: "E-mail is not valid",
    ZEV_INVALID_ZEV_MANAGER: "An administrator must not be a ZEV manager of a ZEV.",
    ZEV_INVALID_BILLING_DATE:
      "The date from which the meter can be billed must coincide with the start date of the service or after the start date of the current billing of the meter.",
    OBJECT_NOT_FOUND: "OBJECT_NOT_FOUND",
    VALIDATION_ERROR:
      "Water bill allocation mediums must be provided for billing scope WATER_BILL and must be empty for other billing scopes",
    OTHER: "something went wrong :(",
    PERMISSION_ERROR: "You don't have the rights to access this resource",
    INVALID_ADMIN_EMAIL_DOMAIN: "Invalid email domain for administrator",
    INVALID_PRICE_COMPONENT_VALID_FROM_MONTH_START:
      "Price Component date of 'valid from' must be at the first day of the month",
    INVALID_PRICE_COMPONENT_VALID_UNTIL_BLANK_MONTH_END:
      "Price Component date of 'valid until' must be either blank or the last day of the month",
    INVALID_STATE_TRANSITION: "Invalid state transition",
    INVALID_PRICE_COMPONENT_PRODUCT_LINK: "Price component is not linked to a product",
    INVALID_HEATING_COST_SHARES: "Either both or none of heating and hot water must be configured",
    SERVICE_COMPONENT_NAME_UNAVAILABLE: "Service component name is unavailable",
    CONTRACT_ZEV_HAS_RELEASED_OR_ACTIVE_CONTRACT: "Approved contract already exists; use replace",
    CONTRACT_ZEV_ACTIVATION_WITHOUT_PREDECESSOR: "Cannot activate contract without a predecessor",
    INVALID_CONSUMPTION_POINT_STATE: "The consumption point is in an invalid state",
    PRODUCT_USED_IN_CONTRACT: "Product cannot be deleted as it is used in one or more contracts",
    PRODUCT_NOT_LISTED: "Product cannot be deleted as it is not listed",
    INVALID_METERING_CODE_LENGTH: "MeteringCode must be 33 characters in length",
    NO_VALID_CONTRACT_FOUND: "No currently active contract found for this zev",
    PARTICIPATION_VACANCY_MODIFICATION: "Vacancy cannot be modified",
    PARTICIPATION_FIRST_ENTRY_DATE_MODIFICATION: "Cannot modify the move-in date of the first entry",
    PARTICIPATION_MOVE_OUT_DATE_UNDEFINED: "There is no move-out defined so it cannot be changed",
    PARTICIPATION_MOVE_BILL_EXISTS: "There already exists an approved bill at the time of the move date",
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_NO_MODIFICATION: "Vacancy cannot be modified",
    PARTICIPATION_CANCEL_MOVE_IN_KNOWN_SUCCESSOR: "Cannot cancel the move-in when there is already a known successor",
    PARTICIPATION_CANCEL_MOVE_IN_BILL_EXISTS: "There already exists an approved bill at the time of the move-in date",
    PARTICIPATION_CANCEL_MOVE_IN_VACANCY_PERIOD_BILLED:
      "Change would extend the vacancy period which is already fully billed",
    PARTICIPATION_CANCEL_MOVE_OUT_VACANCY_NO_MODIFICATION: "Vacancy cannot be modified",
    PARTICIPATION_CANCEL_MOVE_OUT_DATE_UNDEFINED: "There is no move-out date defined so nothing can be cancelled",
    PARTICIPATION_CANCEL_MOVE_OUT_KNOWN_SUCCESSOR:
      "A move-out cannot be cancelled if a new participant is already defined",
    PARTICIPATION_CANCEL_MOVE_OUT_NEXT_PARTICIPANT_KNOWN_SUCCESSOR:
      "A move-out cannot be cancelled if the next participant also already has a successor",
    PARTICIPATION_CANCEL_MOVE_OUT_BILL_EXISTS: "There already exists an approved bill at time of the move-out date",
    PARTICIPATION_MOVE_IN_VACANCY_ONLY: "Can only move into a vacancy",
    PARTICIPATION_MOVE_IN_DATE_BEFORE_VACANCY_START: "The move-in date cannot before the start of the vacancy",
    PARTICIPATION_MOVE_IN_VACANCY_NO_SUCCESSOR: "Can only move into a vacancy if there is no successor",
    PARTICIPATION_MOVE_IN_UNKNOWN_ZEV_PARTICIPANT: "This ZEV participant does not belong to this ZEV",
    PARTICIPATION_MOVE_IN_BILL_EXISTS: "There already exists an approved bill at time of the move-in date",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_VACANCY_NO_MODIFICATION: "Vacancy cannot be modified",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_KNOWN_SUCCESSOR: "There already exists a successor",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_DATE_AFTER_MOVE_IN_DATE:
      "The move-out date of the current participant must be after its move-in date",
    PARTICIPATION_MOVE_OUT_WITHOUT_NP_BILL_EXISTS:
      "There already exists an approved bill at the time of the move-out date",
    SERVICE_BILLING_PROVIDE_START_END_DATES: "You must provide startdate and enddate for a recurring billing",
    SERVICE_BILLING_BILLED_ZEV_ACTIVE_TERMINATED: "Only active or inactive & terminated Zevs can be billed",
    INDV_BILLING_BEFORE_SYSTEM_START:
      "The billing period of an individual billing process must be after the system start date",
    BILL_PRICES_NOT_PROVIDED: "To create a Bill you must provide the zev prices",
    SERVICE_BILLING_RUN_OVERLAPPING_OBJECT:
      "There is another service billing overlapping with the one you are trying to create",
    CONTRACT_TERMINATION_DATE: "Termination must be after start date",
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_INITIAL_BILLS:
      "Cannot replace contract as predecessor has active initial bills.",
    CONTRACT_CANNOT_REPLACE_CONTRACT_WITH_RECURRING_BILLS:
      "Cannot replace contract as predecessor has active recurring bills.",
    PARTICIPATION_START_BEFORE_END: "The start date of the participant must be before the its end date",
    PARTICIPATION_END_AFTER_START: "The end date of the participant must be after the its start date",
    ALL_BILLING_INVALID_STATE_CUSTOMER:
      "The data for the billing is no longer available. We will notify you when the data is available again.",
    INDV_BILLING_INVALID_STATE_CUSTOMER:
      "The data for the billing is no longer available. We will notify you when the data is available again.",
    USER_ACCOUNT_ZEV_MANAGER_UNASSIGNED:
      "You have not yet been assigned to a ZEV. Please contact your contact person or try again later.",
    ZEV_EXTERNAL_REFERENCE_NUMBER_MISSING: "The Zev external reference number is missing.",
    PRICE_COMPONENT_EXTERNAL_REFERENCE_NUMBER_MISSING:
      "One or more price component is missing its external reference number.",
    SAP_SERVICE_BILLING_RECURRING_DATES_NULL: "Recurring Service Billing is missing either its startDate or endDate.",
    SAP_SERVICE_BILLING_TYPES_MISMATCH: "Not all Service Billing types are the same.",
    SAP_GENERAL_FAILURE: "Something went wrong with the SAP provider.",
    PARTICIPANT_DELETE_IN_USE: "Cannot delete participant as it is used in at least one more participation.",
    SERVICE_BILLING_RUN_SAP_UPDATE_SOME_WITHOUT_RESULT: "One or more entries do not have a result",
    SERVICE_BILLING_RUN_SAP_UPDATE_MISMATCH: "One or more entries do not belong to this service billing run",
    SERVICE_BILLING_RUN_SAP_UPDATE_MISSING_DATA: "One or more entries are missing",
    PRODUCT_COLLECTION_RESTRICTED_ACCESS:
      "Access to this resource is restricted based on the product's service component's type: collection",
    PARTICIPANTS_ALREADY_CONFIRMED_SAP_SYNC: "Participant has already been synchronised.",
    BILLING_ZEV_MANAGER_ALREADY_APPROVED:
      "The Zev Manager can't perform any operation on a billing that has been confirmed",
    ADMIN_EMAIL_CHANGE_NOT_PERMITTED: "The Admin's email cannot be changed.",
    ADMIN_PROFILE_DELETION_NOT_PERMITTED: "The Admin profile cannot be deleted.",
    ZEV_PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA: "Insufficient available ZEV data for the specified year.",
    ZEV_PRICE_CALCULATOR_MISSING_INPUT: "The Zev is missing its price calculator input.",
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_TARIFFS: "Tariffs for the provided year are missing.",
    ZEV_PRICE_CALCULATOR_PRICES_HIGHER_THAN_REFERENCE:
      "Calculated ZEV grid purchase & DL costs are higher than those of the reference product.",
    PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA: "Insufficient available ZEV data for the specified year.",
    PRICE_CALCULATOR_MISSING_INPUT: "The Zev is missing its price calculator input.",
    PRICE_CALCULATOR_MISSING_YEAR_TARIFFS: "Tariffs for the provided year are missing.",
    PRICE_CALCULATOR_PRICES_HIGHER_THAN_REFERENCE:
      "Calculated ZEV grid purchase & DL costs are higher than those of the reference product.",
    ZEV_PRICE_CALCULATOR_MISSING_YEAR_VAT_RATE: "Could not find the vat rate for the given year.",
    SAP_MISCONFIGURED_PARTICIPANT: "One or more participant is missing its external reference number.",
    ZEV_PRICE_CALCULATOR_MISSING_ENERGY_DATA: "Zev price calculator is missing energy data.",
    USER_ACCOUNT_ZEV_MANAGER_INACTIVE_CONTRACT:
      "The profile is not linked to at least one active ZEV that has an active contract.",
    ZEV_PRICE_CALCULATOR_INCONSISTENT_INPUT_VALUES:
      "No price can be calculated based on your information. This error occurs when your information is " +
      "inconsistent. Please check your details or contact our support.",
    USER_DELETION_FAILED: "User deletion failed. Please contact your system administrator.",
    PROFILE_DELETION_MANAGING_ZEV:
      "The ZEV manager could not be deleted as it is registered in a ZEV. Please remove the ZEV manager " +
      "from the ZEV before proceeding.",
    INVALID_BILLING_FREQUENCY_ON_VEWA_CONTRACT: "Invalid billing frequency in VEWA contract",
    INVALID_BILLING_FREQUENCY_ON_ZEV_CONTRACT: "Invalid billing frequency for ZEV contract type",
    CANNOT_MODIFY_BILLING_FREQUENCY_ON_VEWA_CONTRACT: "Billing frequency with VEWA contract type cannot be changed",
    INVALID_NEXT_BILLING_FREQUENCY_ON_ZEV_CONTRACT: "Invalid next billing frequency for ZEV contract",
    BILLING_DATE_DOES_NOT_MATCH_BILLING_FREQUENCY:
      "You entered a billing date that does not match the billing frequency",
    BILLING_DATE_OUTSIDE_OF_VALID_DATE_RANGE: "Invoice date outside valid date range",
    BUILDING_METERS_BLOCK_REMOVAL_OF_MEDIUM:
      "You can not remove existing billing scopes. You are only allowed to update percentages on existing billing scopes",
    UTILITY_UNIT_METERS_BLOCK_REMOVAL_OF_MEDIUM: "Utility unit meter, blocks removal from medium",
    NO_ADDITIONAL_METER_IS_ALLOWED_ON_THIS_TOPOLOGY_ELEMENT:
      "No additional meter of this medium and usage type is allowed on this topology element",
    CONTRACT_PRODUCT_SHOULD_MATCH_PREDECESSOR_PRODUCT:
      "The contractual product should be the same as the predecessor product",
    INVALID_METER_MEDIUM: "Invalid meter medium. Medium should be in a contract config for a medium to be added",
    SECOND_PHYSICAL_POWER_METER_NOT_SUPPORTED:
      "Adding a second physical powermeter to this utility unit is not supported",
    PRICE_PACKAGE_ID_MUST_BE_SET: "Price package id must be set",
    PRICE_PACKAGE_ID_CAN_ONLY_BE_SET_ON_PHYSICAL_METER: "Price package id can only be set on the Physical meter",
    POWERMETER_TYPE_MISSING_FOR_PHYSICAL_METER: "Power meter type missing for physical meter",
    UTILITY_UNIT_FOR_BILLING_MUST_HAVE_ONE_PHYSICAL_POWERMETER:
      "A physical powermeter must be linked to the utility unit",
    PRICES_NOT_AVAILABLE_FOR_INDIVIDUAL_PARTICIPANT_BILLING:
      "Prices should be available for individual participant billing",
    INVALID_PRICE_PACKAGE_ID: "Invalid price package ID",
    INVALID_USAGE_TYPE: "Invalid usage type",
    PRICES_CONTAIN_INVALID_NUMBERS: "Price contains invalid numbers/characters",
    INVALID_PRICE_PACKAGE_ORDER: "INVALID PACKAGE ORDER",
    CANNOT_DELETE_PRICES_IN_DEFAULT_PRICE_PACKAGE: "The standard Prices in price package cannot be deleted",
    CANNOT_DELETE_DEFAULT_PRICE_PACKAGE: "The default pricepackage can not be deleted",
    CANNOT_CHANGE_PRODUCT: "Cannot change product",
    CANNOT_DELETE_BUILDING_LINKED_TO_CONTRACT: "The building linked to the contract cannot be deleted",
    CANNOT_DELETE_BUILDING_WITH_LINKED_METER: "Cannot delete building with connected meter",
    METER_WITH_GIVEN_MEDIUM_AND_USAGE_TYPE_ALREADY_EXISTS: "Meter with given medium and usage type already exists",
    CONTRACT_END_DATE_SHOULD_BE_AT_THE_END_OF_BILLING_PERIOD:
      "Contract end date should be at the end of a billing period",
    POWER_METER_TYPE_MUST_ONLY_BE_SET_FOR_PHYSICAL_METER:
      "Powermeter type must only be set for physical powermeters in utility units",
    PRICE_PACKAGE_NOT_SET_ON_POWER_METER: "Price package ID must be set for physical powermeters in utility units",
    BUSINESS_RULES_NOT_SATISFIED: "Only one contract configurator can be added per medium",
    BILLING_FREQUENCY_CAN_ONLY_BE_SET_IN_DRAFT_STATUS: "Billing frequency can only be set in DRAFT status",
    ILLEGAL_PROTOCOL_STATE: "Action is supported only for Active state",
    ZEV_PRICE_PACKAGE_NOT_IN_BILLING: "The new price package is missing in an open individual participant billing.",
    ALL_UTILITY_UNITS_MUST_HAVE_ELECTRICITY_METER: "All utility units must have an electricity meter",
    AT_LEAST_ONE_INCOMING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG:
      "At least one incoming meter must be linked to ZEV config",
    AT_LEAST_ONE_OUTGOING_METER_MUST_BE_LINKED_TO_ZEV_CONFIG:
      "At least one outgoing meter must be linked to ZEV config",
    BUILDING_CAN_ONLY_BE_LINKED_TO_ONE_CONTRACT_PER_PRODUCT:
      "A building can only be linked to one contract per product",
    BUILDINGS_CAN_ONLY_BE_REMOVED_IN_DRAFT_STATE: "Buildings can only be removed in DRAFT state",
    BUILDINGS_MUST_BELONG_TO_SAME_AREA_AS_CONTRACT: "Buildings must belong to the same area as the contract",
    INITIAL_CONTRACT_WITHOUT_PREDECESSOR_MUST_BE_PARENT: "Initial contract without predecessor must be parent",
    THERE_MUST_BE_AT_LEAST_ONE_PARTICIPANT_FOR_EACH_UTILITY_UNIT:
      "There must be at least one participant for each utility unit",
    THERE_MUST_BE_ONE_POWER_METER_FOR_EACH_UTILITY_UNIT: "There must be one power meter for each utility unit",
    NOT_PRESENT_MOVE_IN_DATE_CAN_NOT_BE_CHANGED: "Move in date must be present to be changed",
    PARTICIPANT_FROM_DIFFERENT_AREA: "The participants belong to a different area",
    SERVICE_BILLING_BILLED_AREA_ACTIVE_TERMINATED: "Only active or inactive  terminated  areas can be billed",
    INDIVIDUAL_BILLING_AFTER_SERVICE_START_DATE: "Individual Billing After Service Start Date Exception",
    THE_BILLING_PROCESS_CONTAINS_NO_BILL: "The billing process doesn't contains Bill",
    METER_INACTIVE_BILLABLE_DATES: "billable To needs to be after billable From",
    METER_INACTIVE_NOT_LINKED_TO_CONTRACT: "Inactive meters must be linked to a contract configuration",
    CONTRACT_SHOULD_NOT_HAVE_SUCCESSOR: "Contract should not have a successor",
    METER_START_BEFORE_END: "The meter's start date must be before its end date",
    METER_UPDATE_START_DATE_WITH_EXISTING_BILLS:
      "The first participant already has approved bills. The start date can no longer be changed",
    SERVICE_BILLING_RUN_IS_MISSING_FOR_RECURRING_SERVICE_BILLING:
      "Service billing run must be available for recurring service billing",
    START_DATE_MUST_BE_EQUALS_TO_END_DATE_FOR_INITIAL_SERVICE_BILLING:
      "Start date must be equals to end date for initial service billing",
    START_DATE_MUST_BE_BEFORE_END_DATE_FOR_RECURRING_SERVICE_BILLING:
      "Start date must be before end date for recurring service billing",
    ZEV_CONFIG_MUST_BE_LINKED_TO_A_CONTRACT_CONFIG: "ZEV Config must be linked to a contract config",
    INDIVIDUAL_PARTICIPANT_BILLINGS_ONLY_AVAILABLE_FOR_MEDIUM_ELECTRICITY:
      "Individual Participant Billings should only be created for ZEVs",
    BILLING_PROCESS_CAN_NOT_START_BEFORE_SERVICE_START_DATE:
      "A Billing process can only start at or after the service start date",
    USAGE_TYPE_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT: "This usage type is not supported on this topology element",
    USAGE_TYPE_ON_METER_NOT_IN_SYNC_WITH_UTILITY_UNIT_USAGE_TYPE:
      "Usage type on meter must be in sync with usage type on utility unit",
    THIS_MEDIUM_IS_NOT_SUPPORTED_ON_THIS_TOPOLOGY_ELEMENT: "This medium is not supported on this topology element",
    POWER_METER_TYPE_NOT_SET_FOR_PHYSICAL_METER: "Power Meter type must be set for power meters in utility units",
    THE_ZEV_CONFIG_NOT_LINKED_TO_CONTRACT_CONFIG: "The ZEV config not linked to a contract config",
    USAGE_TYPE_UNDEFINED_IS_NOT_SUPPORTED_ON_UTILITY_UNITS: "Usage type undefined is not supported on utility units",
    EMAIL_UPDATE: "Email update failed",
    MISSING_PHYSICAL_METER: "A utility unit included in a billing process must have one powermeter",
    START_DATE_MUST_BE_BEFORE_END_DATE: "Start date must be before end date",
    BILLING_PERIODS_NOT_ALIGNED_WITH_SERVICE_DATES:
      "The billing periods exceeds the service start date and/or service end date of the Zev",
    APPROPRIATE_CONTRACT_FOR_CONTRACT_CONFIG_IS_MISSING:
      "When there is no predecessor contract, no existing contract configuration can be passed on co",
    PERSON_IS_NOT_ZEV_MANAGER: "Only Person with role ZEV MANAGER can be contract config managers",
    PAYMENT_DEADLINE_MUST_BE_POSITIVE_INTEGER: "Payment deadline must be an numer greater of equal zero",
    DEFAULT_PRICE_PACKAGE_AT_INDEX_ZERO_MISSING: "There must be a default price package at index 0",
    CANNOT_PROVIDE_BOTH_LUMP_SUM_AND_VARIABLE_BILLING_METHODS:
      "You cannot provide both lump sum and variable billing methods for additional billing services",
    UNIQUE_ORDER_OF_PRICE_PACKAGES_MISSING: "Every price package must have a unique order",
    PRICE_PACKAGE_ORDERS_MUST_BE_POSITIVE: "Price package orders must be positive",
    WRONG_NUMBER_OF_PRICES_PROVIDED: "You have provided the wrong number of prices",
    DEFAULT_PRICE_PACKAGE_ID_MUST_NOT_BE_CHANGED: "The ID key, for the default price package may not be changed",
    INVALID_VEWA_MEDIUM: "One of the meters, medium is not supported within the respective billing scope",
    INPUT_PARAMETERS_FROM_DIFFERENT_BILLING_SCOPE:
      "Base cost shares should only contain cost shares within the respective billing scope",
    CONTRACT_CONFIGURATION_MISSING_ON_SUCCESSOR_CONTRACT:
      "On a successor contract, a contract configuration must already exist",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_RECURRING_SERVICE_BILLING:
      "Contract end date should not be before the last recurring service billing",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INITIAL_SERVICE_BILLING:
      "Contract end date should be after the last initial service billing",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_ALL_PARTICIPANT_BILLING:
      "Contract end date should be after the last all participant billing",
    CONTRACT_END_DATE_SHOULD_BE_AFTER_LAST_INDIVIDUAL_PARTICIPANT_BILLING:
      "Contract end date should be after the last individual participant billing",
    CONTRACT_NOT_PART_OF_BILLING: "The Zev you are trying to remove is not part of this billing",
    AREA_WITH_REGISTERED_CONTRACT: "Area with registered contract can not be deleted",
    CANNOT_REPLACE_IN_VACANCIES: "Participants cannot be replaced in vacancies",
    CANNOT_REPLACE_AFTER_CREATION_OF_BILL:
      "Participant cannot be replaced after the creation of individual or all participant(s) billing.",
    BUILDING_IS_NOT_IN_SCOPE_OF_CONTRACT:
      "Only meters attached to a building can be added to a Vewa group meter configuration",
    METER_IS_NOT_ATTACHED_TO_BUILDING:
      "Meters to be added to the Vewa configuration must be attached to a building in the scope of the contract",
    METER_STATE_IS_INVALID:
      "Only ACTIVE and INACTIVE meters can be added to a group meter configuration when the contract is already in RELEASED or ACTIVE state",
    BILLING_SCOPE_ALREADY_EXIST: "This billing scope is already present in the Vewa cost shares configuration",
    UNIT_SPACE_IS_NOT_VALID_FOR_PROVIDED_UTILITY_UNIT_TYPE:
      "On a utility unit of this usage type, provided unit space is not valid. For the residential and commercial unit type, the minimum is 0 m². For the general supply and heat pump type, only an empty value is allowed.",
    BILLING_SCOPE_IS_NOT_PRESENT_IN_VEWA_CONFIG: "You can only update billing scopes that have been added before",
    BILLING_IS_NOT_PRESENT_IN_VEWA_PARTICIPANT_BILLINGS_HISTORY:
      "For a Vewa participant billing to be deleted and removed, it must be present in the billing history",
    PRODUCT_HAS_NO_PRICE_COMPONENT_DEFINED_FOR_ONE_ZEV_METER_POWERTYPE:
      "Product does not have a price component defined for one powerMeterType of the ZEV meters",
    INITIAL_BILLINGS_CAN_ONLY_BE_STARTED_FOR_ACTIVE_AREAS: "Initial billings can only be started for active Areas",
    ZEV_CONTRACT_CAN_HAVE_ONLY_ONE_INITIAL_BILLING: "A Zev contract can only have one initial billing",
    BILLING_FREQUENCY_NOT_SUPPORTED: "Billing frequency is not supported",
    SERVICE_BILLING_MUST_BE_OF_TYPE_INITIAL: "Service billing must be of service billing type initial",
    ALL_PARTICIPANT_BILLING_ALREADY_EXISTS_FOR_REGISTERED_NEXT_BILLING_DATE:
      "There already exists an all participant billing for the registered next billing date",
    ZEV_ALL_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_VEWA_BILLINGS_ARE_PRESENT:
      "Zev all participants invoices cannot be created if vewa invoices are available",
    VEWA_PARTICIPANT_BILLING_CANNOT_BE_CREATED_WHEN_ZEV_PARTICIPANT_BILLINGS_ARE_PRESENT:
      "Vewa participant billing cannot be created when zev participant billings are present",
    NO_RELEASED_OR_ACTIVE_CONTRACT_FOUND_FOR_PROVIDED_DATE:
      "No released or active contract was found for the specified date.",
    MORE_THAN_ONE_CONTRACT_FOUND_FOR_PROVIDED_DATE: "More than one contract found for provided date",
    NEXT_BILLING_DATE_CAN_ONLY_BE_UPDATED_FOR_VEWA_CONTRACTS:
      "Next Billing Date can only be updated for VEWA contracts",
    NEXT_BILLING_DATE_FREQUENCY_CAN_ONLY_BE_UPDATED_FOR_ZEV_CONTRACTS:
      "The frequency of the next billing date can only be updated for Zev contracts.",
    SAME_DAY_TARIFSS_OVERLAPPING:
      "Overlap detected on day between current period startTime-endTime and next Period startTime-endTime",
    TARIFFS_MUST_BE_DIVISIBLE_BY_15_MINUTES: "Tariffs Must Be Divisible By 15 Minutes",
    INVALID_START_OR_END_TIME: "Die Endzeit des Tages muss nach der Startzeit startTime liegen.",
    COMMENT_TEXT_LENGTH_TOO_LONG: "Comment length cannot be greater than 500 characters",

    // Custom Generic errors
    GENERIC_401: "You don't have permission to perform this action",
    GENERIC_FATAL_ERROR: "something went wrong.",
    GENERIC_TIMEOUT_ERROR: "the operation you intended had a timeout error",
    AREA_INACTIVE_TITLE: "Error - An error has occurred",
    ERR_BAD_REQUEST: "Die Anfrage konnte nicht verarbeitet werden oder wurde nicht gefunden.",
    TARIFF_USED_BY_CONTRACT_CANNOT_BE_REMOVED: "Tariff used by contract cannot be removed",
    METERING_CODE_ALREADY_ASSIGNED_TO_BILLABLE_METER: "Metering code already assigned to billable meter",
  },
}

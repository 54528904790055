import { RouteComponentProps, withRouter } from "react-router-dom"
import { AdminPortalRouteParams } from "../../../AdminPortal.Routes"
import { ProductType } from "../../../../../../data/generated-sources/openapi"
import MediumZevUpdateComponent from "./components/MediumZevUpdate.Component"
import { VewaCostSharesUpdate } from "./components/VewaCostSharesUpdate"

const MediumUpdateWrapperComponent = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { match, history } = props

  const renderProperUpdateComponent = () => {
    switch (props.match.params.productType.toUpperCase()) {
      case ProductType.ZEV:
        return <MediumZevUpdateComponent contractId={match.params.id} />
      case ProductType.VEWA:
        return <VewaCostSharesUpdate contractId={match.params.id} />
      default:
        return history.push(`/management-contracts/${match.params.id}`)
    }
  }
  return <>{renderProperUpdateComponent()}</>
})

export default MediumUpdateWrapperComponent

import { CSSProperties } from "react"
import { AuthType } from "../../../domain/auth/Auth.Model"
import { HasChildren } from "../../../uikit/Shared.Prop"
import { AuthTypeProps } from "../AppContainer.Component"

const adminPortalStyle: CSSProperties = {
  flex: 1,
  padding: 16,
  position: "relative",
  flexGrow: 1,
  paddingTop: "80px",
  maxWidth: "calc(100% - 272px)",
  left: 240,
  backgroundColor: "#FaFaFa",
}

const customerPortalStyle: CSSProperties = {
  left: "281px",
  position: "relative",
  display: "flex",
  flexDirection: "column",
  width: "calc(100vw - 281px)",
  paddingTop: "100px",
}

export const AppPageView = (props: HasChildren & AuthTypeProps) => {
  const { children, authType } = props
  return <div style={authType === AuthType.ADMINISTRATOR ? adminPortalStyle : customerPortalStyle}>{children}</div>
}

import { Route, Switch, Redirect } from "react-router-dom"
import { ParticipantsList } from "./participants/ParticipantsList"
import UtilityUnitLists from "./utilityUnit/UtilityUnitLists"
import { UtilityUnitDetail } from "./utilityUnit/UtilityUnitDetail"
import { ManagerSettings } from "./managerSettings/ManagerSettings"
import { FaqComponent } from "./faq/Faq.Component"
import { AnalysesDetailComponent } from "./analyses/AnalysesDetail.Component"
import { BillingsOverview } from "./billings/BillingsOverview"
import { VewaBillingValidateDetails } from "./billings/VewaBillingValidateDetails"
import { DashboardOverview } from "./dashboard/DashboardOverview"
import ParticipantsDetail from "./participants/components/ParticipantsDetail"
import { VewaParticipantsDoneBills } from "./billings/VewaParticipantsDoneBills"
import { ZevAllBillingDetails } from "./billings/ZevAllBillingDetails"
import { ZevIndividualBillingDetails } from "./billings/ZevIndividualBillingDetails"
import { ZevAllDoneBilling } from "./billings/ZevAllDoneBilling"
import { ZevIndividualDoneBilling } from "./billings/ZevIndividualDoneBilling"

export interface ManagerPortalRoutesProps {
  areaId: string
  billingId: string
  contractId: string
  participantId: string
  utilityUnitId: string
}

export const ManagerPortalRoutes = ({ selectedAreaId }: { selectedAreaId: string }) => {
  const defaultRoute = (selectedAreaId: string) =>
    selectedAreaId !== undefined ? <Redirect to={`/${selectedAreaId}/dashboard`} /> : <></>

  return (
    <Switch>
      <Route path="/:areaId/faq" component={FaqComponent} />
      <Route path="/:areaId/dashboard" component={DashboardOverview} />
      <Route path="/:areaId/analyses" component={AnalysesDetailComponent} />
      <Route exact path="/:areaId/participants" component={ParticipantsList} />
      <Route exact path="/:areaId/participants/:participantId" component={ParticipantsDetail} />
      <Route exact path="/:areaId/billings" component={BillingsOverview} />
      <Route exact={true} path="/:areaId/utility-units/:utilityUnitId" component={UtilityUnitDetail} />
      <Route exact={true} path="/:areaId/utility-units" component={UtilityUnitLists} />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/all/:billingId/edit"
        component={ZevAllBillingDetails}
      />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/individual/:billingId/edit"
        component={ZevIndividualBillingDetails}
      />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/vewa/:billingId/validate"
        component={VewaBillingValidateDetails}
      />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/vewa/:billingId/bills"
        component={VewaParticipantsDoneBills}
      />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/individual/:billingId/finalize"
        component={ZevIndividualDoneBilling}
      />
      <Route
        exact
        path="/:areaId/contracts/:contractId/billings/all/:billingId/finalize"
        component={ZevAllDoneBilling}
      />
      <Route path="/:areaId/settings" component={ManagerSettings} />
      <Route path="/">{defaultRoute(selectedAreaId)}</Route>
    </Switch>
  )
}

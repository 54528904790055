import { useEffect, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PrimaryButton } from "../../../../../../uikit/button"
import { Formik, Form } from "formik"
import { useMutation } from "@tanstack/react-query"
import { customerMoveoutUtilityUnitParticipationVacancy } from "../.././../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import DateUtils from "../../../../../../services/utils/DateUtils"
import * as yup from "yup"

import { ParticipationDialogPros } from "./UtilityUnitParticipations"
import { DialogCloseIcon } from "../../../../../../uikit/icons/Icons"
import { AxiosErrorDataType, getTranslateValue } from "../../../../../Shared.Utils"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { validationError } from "../../../../../Shared.Validation"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"

const participantMoveOutSchema = yup.object().shape({
  moveOutDate: yup.number().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("utilityUnitParticipations:move.form.moveOutDate"),
    }),
  ),
})

export const ParticipantMoveOut = ({
  showDialog,
  participation,
  onUpdateSuccess,
  onClose,
}: ParticipationDialogPros) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [moveOutError, setMoveOutError] = useState<AxiosErrorDataType>()

  const currentTime = new Date().getTime()
  const minMoveOutDate = participation?.moveInDate ? DateUtils.addDateDays(participation?.moveInDate, 1) : undefined
  const initialDate = minMoveOutDate && minMoveOutDate.getTime() > currentTime ? minMoveOutDate.getTime() : currentTime

  useEffect(() => {
    return () => {
      setMoveOutError(undefined)
    }
  }, [setMoveOutError])

  const { mutate: moveOutVacancy, isLoading } = useMutation(
    (moveOutDate: number) =>
      customerMoveoutUtilityUnitParticipationVacancy(
        participation?.utilityUnitId || "",
        participation?.id || "",
        moveOutDate,
      ),
    {
      onSuccess: onUpdateSuccess,
      onError: setMoveOutError,
    },
  )

  return (
    <Dialog open={showDialog} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>{t("move.form.title")}</DialogTitle>
      <DialogCloseIcon onClick={onClose} />
      <ErrorAlert
        scrollOnDisplay
        visible={!!moveOutError}
        message={t(`error-codes:${moveOutError?.response?.data?.code || moveOutError?.code || "OTHER"}`)}
      />
      <Formik<{ moveOutDate: number }>
        initialValues={{ moveOutDate: initialDate }}
        validationSchema={participantMoveOutSchema}
        onSubmit={(values) => {
          moveOutVacancy(values.moveOutDate)
        }}
      >
        {({ values, touched, errors, isValid, handleBlur, setFieldValue }) => (
          <Form>
            <DialogContent>
              <DialogContentText
                sx={{ "&.MuiTypography-root.MuiDialogContentText-root": { fontWeight: 600, fontSize: 16 } }}
              >
                {t("move.form.subtitle.currentParticipant")}
              </DialogContentText>

              <SingleLineDatePicker
                name="moveOutDate"
                value={values.moveOutDate}
                label={t("move.form.moveOutDate")}
                minDate={minMoveOutDate}
                onBlur={handleBlur}
                onChange={(value) => setFieldValue("moveOutDate", value)}
                helperText={validationError(errors.moveOutDate, touched.moveOutDate)}
              />
            </DialogContent>

            <DialogActions>
              <Button variant="outlined" onClick={onClose} disabled={isLoading}>
                {t("shared:form.action.cancel")}
              </Button>
              <PrimaryButton
                type="submit"
                isLoading={isLoading}
                disabled={isLoading || !isValid}
                label={t("moveOut.saveAndInvoiceBtn")}
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

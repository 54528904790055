import { I18nNamespace } from "../../../App.i18n"

export const ParticipantsI18n: I18nNamespace = {
  name: "customer-participants",
  de: {
    "tableHeader.firstName": "Vorname",
    "tableHeader.lastName": "Name",
    "tableHeader.email": "E-Mail",
    "tableHeader.phone": "Telefonnummer",
    "tableHeader.utilityUnit": "Nutzeinheit",
    "table.createParticipantBtn": "Neuen Teilnehmer hinzufügen",
    "detail.personalData": "Personendaten",
    "detail.domicileAddress": "Domiziladresse",
    "button.deleteParticipant": "Teilnehmer löschen",
    "label.businessPartnerType": "GeschäftsTyp",
    "label.goToParticipant": "Zum Teilnehmer",
    "detail.contactPerson": "Ansprechsperson",
    "detail.secondContactPerson": "2. Ansprechsperson",
    "form.field.businessPartnerType": "Geschäftspartner",
    "registerForm.businessPartnerType": "Geschäftspartner (GPI)",
    "registerForm.headerTitle": "Teilnehmer erfassen",
    "registerForm.gpType": "Geschäftspartner Typ",
    "registerForm.gptype.PERSON": "Person",
    "registerForm.salutation": "Anrede",
    "registerForm.title": "Titel",
    "registerForm.firstName": "Vorname",
    "registerForm.lastName": "Nachname",
    "registerForm.firstNameSecondPerson": "Vorname (2. Person)",
    "registerForm.lastNameSecondPerson": "Nachname (2. Person)",
    "registerForm.secondPerson": "Zweite Person ",
    "registerForm.phone": "Telefon",
    "registerForm.mobile": "Mobil",
    "registerForm.email": "E-Mail",
    "registerForm.address": "DOMIZILADRESSE",
    "registerForm.street": "Strasse",
    "registerForm.houseNumber": "Hausnummer",
    "registerForm.co": "c/o",
    "registerForm.poBox": "Postfach",
    "registerForm.postCode": "Postleitzahl",
    "registerForm.city": "Ort",
    "registerForm.land": "Land",
    "registerForm.save": "Speichern",
    "modal.delete.question": "Wollen Sie diesen Teilnehmer wirklich endgültig löschen?",
    "modal.delete.desc": "Dieser Schritt kann nicht rückgängig gemacht werden.",
    "participant.empty": "Leerstand",
    "participant.known": "Teilnehmer bekannt",
    "participant.add": "Teilnehmer hinzufügen",
    "participant.edit": "Teilnehmer bearbeiten",
    "participant.remove": "Teilnehmer entfernen",
    "participant.choose": "Teilnehmer auswählen",
    "participant.add.new": "Neuen Teilnehmer hinzufügen",
  },
  en: {
    "tableHeader.firstName": "First Name",
    "tableHeader.lastName": "Last Name",
    "tableHeader.email": "E-mail",
    "tableHeader.phone": "Telephone",
    "tableHeader.utilityUnit": "UtilityUnit",
    "table.createParticipantBtn": "Register new participant",
    "detail.personalData": "Personal data",
    "button.deleteParticipant": "Delete participant",
    "label.businessPartnerType": "Partner type",
    "label.goToParticipant": "Go to participant",
    "registerForm.businessPartnerType": "Partner type (GPI)",
    "form.field.businessPartnerType": "Bussiness partner type",
    "detail.contactPerson": "Contact person",
    "detail.secondContactPerson": "2. Contact person",
    "registerForm.headerTitle": "Register User",
    "registerForm.gpType": "Business partner type",
    "registerForm.gptype.PERSON": "Person",
    "registerForm.salutation": "salutation",
    "registerForm.title": "Title",
    "registerForm.firstName": "First name",
    "registerForm.lastName": "Last name",
    "registerForm.firstNameSecondPerson": "First name (2nd person)",
    "registerForm.lastNameSecondPerson": "Last name (2nd person)",
    "registerForm.secondPerson": "Second Person ",
    "registerForm.phone": "telephone",
    "registerForm.mobile": "mobile",
    "registerForm.email": "email",
    "registerForm.address": "Address",
    "registerForm.street": "Street",
    "registerForm.houseNumber": "House number",
    "registerForm.co": "c/o",
    "registerForm.poBox": "P.O. box",
    "registerForm.postCode": "Post Code",
    "registerForm.city": "City",
    "registerForm.land": "Country",
    "registerForm.save": "Save",
    "modal.delete.question": "Do you really want to permanently delete this participant?",
    "modal.delete.desc": "This step cannot be undone.",
    "participant.empty": "Empty",
    "participant.known": "Known participant",
    "participant.add": "Add participant",
    "participant.edit": "Edit participant",
    "participant.remove": "Remove participant",
    "participant.choose": "Choose participant",
    "participant.add.new": "Add new participant",
  },
}

import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { BillingIcon } from "../../../../uikit/Shared.Icon"

export const ParticipantBillingManagementBreadcrumb = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("billings-recurring")
  const { history } = props

  const breadcrumbHomeIcon = () => (
    <BreadcrumbsHomeIcon redirectUrl={() => history.push("/participant-billing-management")} icon={<BillingIcon />} />
  )

  return (
    <Switch>
      <Route path="/participant-billing-management">
        {breadcrumbHomeIcon()}
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton disabled label={t("participantBillingManagement:page.title")} />
      </Route>
    </Switch>
  )
}

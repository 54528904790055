import { I18nNamespace } from "../../../App.i18n"

export const ProductI18n: I18nNamespace = {
  name: "products",
  de: {
    "list.title": "PRODUKTE",
    "productHistory.title": "PRODUKTVERLAUF",
    "field.label.products": "Produkt",
    "field.label.serviceComponents": "Service Komponenten",
    "field.header.base-data": "STAMMDATEN",
    "cta.delete-product": "Produkt löschen",
    "list.label.name": "NAME",
    "list.label.billing-type": "ABRECHNUNGSTYP",
    "list.label.price-ex-vat": "PREIS OHNE MWST.",
    "list.label.valid-from": "GÜLTIG VON",
    "field.label.expiry-date": "GÜLTIG BIS",
    "list.label.ext-ref-number": "EXTERNE REFERENZNUMMER",
    "detail.title": "PRODUKT DETAIL",
    "form.field.name": "Produkt",
    "form.field.productType": "Produkttyp",
    "create.title": "PRODUKT ERSTELLEN",
    "create.form.action.cta": "Produkt erstellen",
    "create.alert.success": "Das Produkt wurde erfolgreich angelegt. Gehen Sie zum Produkt",
    "update.title": "PRODUKT BEARBEITEN",
    "update.form.action.cta": "Produkt speichern",
    "update.alert.success": "Die Änderungen am Produkt wurde erfolgreich gespeichert",
    "delete.alert.success": "Produkt wurde erfolgreich gelöscht",
    "price.form.title.createPriceComponent": "Neue Preiskomponente erstellen",
    "price.form.title.updatePriceComponent": "Preiskomponente bearbeiten",
    "price.form.field.name": "Name",
    "price.form.field.externalRef": "Externe Referenznummer",
    "price.form.field.billingType": "Abrechnungstyp",
    "price.form.field.powermeterType": "Ausführung",
    "price.form.field.priceWithoutVat": "Preis ohne MwSt.",
    "price.form.field.validFrom": "Gültig von",
    "price.form.field.validUntil": "Gültig bis",
    "create.price.form.action.cta": "Preiskomponente erstellen",
    "create.price.alert.success": "Die Preiskomponente wurde erfolgreich erstellt.",
    "update.price.form.action.cta": "Preis speichern",
    "update.price.alert.success": "Die Änderungen am Preis wurden erfolgreich gespeichert",
    "delete.price.alert.success": "Die Preiskomponente wurde erfolgreich erstellt.",
  },
  en: {
    "list.title": "Products",
    "productHistory.title": "Product history",
    "detail.title": "Product Detail",
    "field.label.products": "Product",
    "field.label.serviceComponents": "Service Components",
    "field.header.base-data": "BASE DATA",
    "cta.delete-product": "Delete procts",
    "list.label.name": "NAME",
    "list.label.billing-type": "BILLING TYPE",
    "list.label.price-ex-vat": "PRICE EXCLUDING VAT.",
    "list.label.valid-from": "VALID FROM",
    "list.label.expiry-date": "DATE OF EXPIRY",
    "list.label.ext-ref-number": "EXTERNAL REFERENCE NUMBER",
    "form.field.name": "Product",
    "form.field.productType": "Produkt type",
    "create.title": "CREATE PRODUCT",
    "create.form.action.cta": "Create product",
    "create.alert.success": "The product was created successfully. Go to the product",
    "update.title": "UPDATE PRODUCT",
    "update.form.action.cta": "Save product",
    "update.alert.success": "The changes to the product have been saved successfully",
    "delete.alert.success": "Product was successfully deleted",
    "price.form.title.createPriceComponent": "Create a new price component",
    "price.form.title.updatePriceComponent": "Edit price component",
    "price.form.field.name": "Name",
    "price.form.field.externalRef": "Externe Referenznummer",
    "price.form.field.billingType": "Billing type",
    "price.form.field.powermeterType": "Powermeter type",
    "price.form.field.priceWithoutVat": "Price without VAT",
    "price.form.field.validFrom": "Valid from",
    "price.form.field.validUntil": "Valid until",
    "create.price.form.action.cta": "Create price component",
    "create.price.alert.success": "Die Preiskomponente wurde erfolgreich erstellt.",
    "update.price.form.action.cta": "Update price component",
    "update.price.alert.success": "The changes to the price have been saved successfully",
    "delete.price.alert.success": "The price component was created successfully.",
  },
}

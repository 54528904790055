import { useEffect } from "react"
import { ConfirmDialog } from "../../../../../uikit/confirmDialog/ConfirmDialog"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { adminDeleteTariffSchedule } from "../../../../../domain/portal/admin/tariffs/Tariffs.Repository"
import { useTranslation } from "react-i18next"
import { DeleteTariffPropsI } from "../TariffModels"

const DeleteTariff = (props: DeleteTariffPropsI) => {
  const { tariffId, isModalVisible, setIsModalVisible } = props
  const { t } = useTranslation("tariff")
  const queryClient = useQueryClient()

  const { mutate } = useMutation(["deleteTariff"], (id: string) => adminDeleteTariffSchedule(id), {
    onSettled: () => {
      queryClient.invalidateQueries(["getTariffs"])
    },
  })

  useEffect(() => {
    setIsModalVisible(isModalVisible)
  }, [isModalVisible, tariffId])

  const handleOk = () => {
    mutate(tariffId)
    setIsModalVisible(false)
  }

  return (
    <ConfirmDialog
      onConfirm={handleOk}
      onCancel={() => setIsModalVisible(false)}
      openModal={isModalVisible}
      showPrimaryButton={false}
      dialogBody={<p>{t("label.areYouSureWantToDelete")}</p>}
    />
  )
}

export default DeleteTariff

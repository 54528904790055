import { connect } from "react-redux"
import AppContainerComponent from "./AppContainer.Component"
import { AppState } from "../App.Reducer"
import { Dispatch } from "react"
import { AnyAction } from "redux"
import { push } from "connected-react-router"
import { AuthActionType } from "../auth/Auth.Epic"
import { AppContainerActionType } from "./AppContainer.Epic"

const mapStateToProps = (state: AppState) => {
  return {
    ...state.appContainer,
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    navigateAdminRoot: () => {
      dispatch(push("/"))
    },
    navigateAdminProduct: () => {
      dispatch(push("/products"))
    },
    navigateAdminProfile: () => {
      dispatch(push("/profiles"))
    },
    navigateAdminConnectionObjects: () => {
      dispatch(push("/buildings"))
    },
    navigateAdminContracts: () => {
      dispatch(push("/management-contracts"))
    },
    navigateAdminBillingInitial: () => {
      dispatch(push("/billings/initial"))
    },
    navigateAdminBillingRecurring: () => {
      dispatch(push("/billings/recurring"))
    },
    navigateAdminTodoList: () => {
      dispatch(push("/tasklist"))
    },
    navigateAdminMyProfile: () => {
      dispatch(push("/my-profile"))
    },
    navigateAdminSettings: () => {
      dispatch(push("/settings"))
    },
    navigateCustomerRoot: (areaId: string) => {
      dispatch(push(`/${areaId}/dashboard`))
    },
    navigateCustomerAnalysis: (areaId: string) => {
      dispatch(push(`/${areaId}/analyses`))
    },
    navigateCustomerBuildings: (areaId: string) => {
      dispatch(push(`/${areaId}/buildings`))
    },
    navigateCustomerParticipants: (areaId: string) => {
      dispatch(push(`/${areaId}/participants`))
    },
    navigateCustomerBillings: (areaId: string) => {
      dispatch(push(`/${areaId}/billings`))
    },
    navigateCustomerUtilityUnits: (areaId: string) => {
      dispatch(push(`/${areaId}/utility-units`))
    },
    navigateCustomerSettings: (areaId: string) => {
      dispatch(push(`/${areaId}/settings`))
    },
    checkSession: () => {
      dispatch({
        type: AuthActionType.AUTH_REFRESH_SESSION,
      })
    },
    logout: (previousPathname?: string) => {
      dispatch({
        type: AppContainerActionType.APP_CONTAINER_LOGOUT,
        previousPathname: previousPathname,
      })
    },
    dialogAbortClick: () => {
      dispatch({
        type: AppContainerActionType.APP_CONTAINER_CONFIRM_DIALOG_HIDE,
      })
    },
    dialogConfirmClick: (action: AnyAction) => {
      dispatch(action)
      dispatch({
        type: AppContainerActionType.APP_CONTAINER_CONFIRM_DIALOG_HIDE,
      })
    },
    toastClose: () => {
      dispatch({
        type: AppContainerActionType.APP_TOAST_HIDE,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppContainerComponent)

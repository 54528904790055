import { Paper } from "@mui/material"
import Grid from "@mui/material/Grid"
import { HasChildren } from "../../../uikit/Shared.Prop"
import loginHeader from "url:../../../../res/auth.png"
import { StaticPageView } from "../../appcontainer/views/StaticPageView"
import { AppContainerView } from "../../appcontainer/views/AppContainerView"

export const AuthAdminContainerView = (props: HasChildren) => {
  const { children } = props
  return (
    <AppContainerView>
      <StaticPageView>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          sx={{
            height: "100%",
          }}
        >
          <Paper sx={{ width: 480, marginTop: 4 }}>
            <Grid container justifyContent="center">
              <img src={loginHeader} width={275} height={275} />
            </Grid>
            {children}
          </Paper>
        </Grid>
      </StaticPageView>
    </AppContainerView>
  )
}

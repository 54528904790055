import {
  Alert,
  AlertTitle,
  Box,
  Button,
  Icon,
  Link,
  Stack,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material"
import { styled } from "@mui/material/styles"
import { useEffect, useRef } from "react"
import { useTranslation } from "react-i18next"
import { TextButton } from "./button/TextButton"
import { InfoIcon, InfoIconOutlined, RetryIcon } from "./Shared.Icon"

interface AlertProps {
  title?: string
  icon?: React.ReactNode
  message?: string | React.ReactNode
  ctaLabel?: string
  retry?: () => void
  ctaAction?: () => void
  retryButtonLabel?: string
  scrollOnDisplay?: boolean
  visible?: boolean
}

export const HintButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(2),
  borderColor: theme.palette.info.main,
  color: theme.palette.info.main,
  borderWidth: 1,
  borderStyle: "solid",
  borderRadius: theme.spacing(50),
  textTransform: "none",
  "&:hover": {
    borderColor: theme.palette.info.dark,
    color: theme.palette.info.dark,
  },
  "&:active": {
    color: theme.palette.info.light,
    borderColor: theme.palette.info.light,
  },
}))

export const OptionalErrorAlert = (props: AlertProps) => {
  const { message } = props
  if (!message) return <></>
  return <ErrorAlert {...props} />
}

export const TableRowErrorAlert = (props: AlertProps & { colSpan: number }) => {
  const { colSpan } = props
  return (
    <TableRow>
      <TableCell colSpan={colSpan}>
        <ErrorAlert {...props} />
      </TableCell>
    </TableRow>
  )
}

export const ErrorAlert = (props: AlertProps) => {
  const { title, message, icon, retry, scrollOnDisplay, retryButtonLabel, visible = true } = props
  const { t } = useTranslation("shared")

  if (!visible) return <></>

  return (
    <AlertContainer
      id="app-alert-error"
      title={title ? title : t("alert.error")}
      message={message}
      severity="error"
      icon={icon}
      retry={retry}
      scrollOnDisplay={scrollOnDisplay}
      retryButtonLabel={retryButtonLabel}
    />
  )
}

export const OptionalSuccessAlert = (props: AlertProps & { show: unknown | undefined }) => {
  const { show } = props
  if (!show) return <></>
  return <SuccessAlert {...props} />
}

export const SuccessAlert = (props: AlertProps) => {
  const { title, message, icon, scrollOnDisplay } = props
  const { t } = useTranslation("shared")

  return (
    <AlertContainer
      id="app-alert-success"
      title={title ? title : t("alert.successful")}
      message={message}
      severity="success"
      icon={icon}
      scrollOnDisplay={scrollOnDisplay}
    />
  )
}

export const SuccessAlertLink = ({ message, onClick, visible }: AlertProps & { onClick: () => void }) => {
  if (!visible) return <></>
  return <SuccessAlert scrollOnDisplay message={<AlertLink label={message} onClick={onClick} />} />
}

export const InfoAlert = (props: AlertProps) => {
  const { title, message, icon, scrollOnDisplay } = props
  return (
    <AlertContainer
      id="app-alert-info"
      title={title}
      message={message}
      severity="info"
      icon={icon}
      scrollOnDisplay={scrollOnDisplay}
    />
  )
}

export const HintAlertContainer = (
  props: AlertProps & {
    severity: "success" | "error" | "info"
    retry?: () => void
  },
) => {
  const { title, message, icon, severity, ctaAction, ctaLabel, scrollOnDisplay } = props
  const { t } = useTranslation("shared")
  const alertRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (alertRef !== null && scrollOnDisplay) {
      alertRef.current?.scrollIntoView()
    }
  }, [alertRef, scrollOnDisplay])

  return (
    <Alert
      ref={alertRef}
      severity={severity}
      icon={icon ? icon : <InfoIcon fontSize="inherit" sx={{ alignSelf: "center" }} />}
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      <Box color="info">
        {message}
        {ctaAction && <HintButton onClick={ctaAction}>{ctaLabel ? ctaLabel : t("shared:label.ok")}</HintButton>}
      </Box>
    </Alert>
  )
}

const AlertContainer = (
  props: AlertProps & {
    id: string
    severity: "success" | "error" | "info"
    retry?: () => void
  },
) => {
  const { id, title, message, icon, severity, retry, retryButtonLabel, scrollOnDisplay } = props
  const { t } = useTranslation("shared")
  const alertRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (alertRef !== null && scrollOnDisplay) {
      alertRef.current?.scrollIntoView({ block: "center" })
    }
  }, [alertRef, scrollOnDisplay])

  return (
    <Alert id={id} ref={alertRef} severity={severity} icon={icon} sx={{ mb: 2 }}>
      {title && <AlertTitle>{title}</AlertTitle>}
      <Box>{message}</Box>
      {retry && (
        <Box>
          <TextButton
            sx={{ marginLeft: -1 }}
            id="app-alert-retry"
            color="error"
            startIcon={<RetryIcon />}
            label={retryButtonLabel ? retryButtonLabel : t("shared:button.retry")}
            onClick={retry}
          />
        </Box>
      )}
    </Alert>
  )
}

export const AlertLink = (props: { label: string | React.ReactNode; onClick: () => void }) => {
  const { label, onClick } = props
  return (
    <Link color="textPrimary" onClick={onClick} sx={{ cursor: "pointer" }}>
      {label}
    </Link>
  )
}

export const ManagerBlackAlert = ({
  label,
  buttonText,
  tooltipText,
  onClick,
}: {
  label: string
  tooltipText?: string
  buttonText?: string
  onClick?: () => void
}) => {
  return (
    <Stack
      my={1}
      p={[2, 3]}
      flexDirection={"row"}
      justifyContent={"space-between"}
      alignItems={"center"}
      sx={{ backgroundColor: "#3C403C", borderRadius: "0.5rem" }}
    >
      <Tooltip title={tooltipText || ""} placement={"top-start"}>
        <Typography
          color="white"
          fontSize={18}
          fontWeight={500}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
        >
          <Icon sx={{ mr: 1 }}>
            <InfoIconOutlined color="inherit" />
          </Icon>
          {label}
        </Typography>
      </Tooltip>
      {buttonText && (
        <Button
          variant="outlined"
          sx={{
            color: "white",
            borderColor: "white",
            ":hover": { color: "#3C403C", background: "white", borderColor: "white" },
          }}
          onClick={onClick}
        >
          {buttonText}
        </Button>
      )}
    </Stack>
  )
}

import { I18nNamespace } from "../../../App.i18n"

export const TaskListI18n: I18nNamespace = {
  name: "tasklist",
  de: {
    "list.title": "AUFGABENLISTE",
    "list.toggle.showcompleted": "Erledigte Tasks anzeigen",
    "list.label.id": "ID",
    "list.label.status": "STATUS",
    "list.label.date": "DATUM",
    "list.label.event": "EVENT",
    "field.label.zev": "ZEV",
    "field.label.contract": "VERTRAG / AREAL",
    "list.label.sync": "SYNC",
    "list.billing.title": "ABRECHNUNG",
    "details.label.participants": "Teilnehmer",
    "details.label.contactData": "Ansprechpartner",
    "details.label.paymentInformation": "ZEV Zahlungsinformationen",
    "details.action.cta": "Als erledigt markieren",
    "state.NEW_PARTICIPANT": "Teilnehmer anlegen",
    "state.MODIFIED_PARTICIPANT": "Teilnehmer anpassen",
    "state.BILLING_CONFIRMATION": "Abrechnungsdaten bestätigen",
    "state.MODIFIED_CONTACT_DATA": "Ansprechpartner anpassen",
    "state.MODIFIED_PAYMENT_INFORMATION": "Zahlungsinformationen anpassen",
    "dialog.title.updateNotes": "Notizen aktualisieren",
  },
  en: {
    "list.title": "TASK LIST",
    "list.toggle.showcompleted": "Show completed tasks",
    "list.label.id": "ID",
    "list.label.status": "STATUS",
    "list.label.date": "DATE",
    "list.label.event": "EVENT",
    "field.label.zev": "ZEV",
    "field.label.contract": "CONTRACT / AREA",
    "list.label.sync": "SYNC",
    "list.billing.title": "ABRECHNUNG",
    "details.label.participants": "Participants",
    "details.label.contactData": "Contact data",
    "details.label.paymentInformation": "ZEV Payment Information",
    "details.action.cta": "Mark as done",
    "state.NEW_PARTICIPANT": "Create participants",
    "state.MODIFIED_PARTICIPANT": "Adapt participants",
    "state.BILLING_CONFIRMATION": "Confirm billing data",
    "state.MODIFIED_CONTACT_DATA": "Adapt ontact data",
    "state.MODIFIED_PAYMENT_INFORMATION": "Adapt payment information",
    "dialog.title.updateNotes": "Update note",
  },
}

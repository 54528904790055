import { I18nNamespace } from "../../../App.i18n"

export const TariffManagement18n: I18nNamespace = {
  name: "tariff",
  de: {
    energySupplier: "Energieversorger",
    tariffTimes: "Tarifzeiten",
    lowTariff: "Niedertarif",
    highTariff: "Hochtarif",
    removeSupplier: "Energieversorger entfernen",
    addSupplier: "Energieversorger hinzufügen",
    editSupplier: "Energieversorger bearbeiten",
    clock: "Uhr",
    validOnTheseDays: "Gilt an diesen Tagen",
    validFrom: "Gültig von",
    validUntil: "Gültig bis",
    noteMessage: "Hinweis: Der Hochtarif wird automatisch auf Basis der angegebenen Niedertarif-Perioden berechnet.",
    addPeriod: "Niedertarif-Periode hinzufügen",
    definePeriod: "Niedertarifzeiten definieren",
    "errorMessage.pleaseSetEnergySupplier": "Bitte geben Sie einen Energieversorger ein",
    "errorMessage.pleaseAddPeriod": "Bitte fügen Sie eine Periode hinzu",
    "weekday.MONDAY": "Mo",
    "weekday.TUESDAY": "Di",
    "weekday.WEDNESDAY": "Mi",
    "weekday.THURSDAY": "Do",
    "weekday.FRIDAY": "Fr",
    "weekday.SATURDAY": "Sa",
    "weekday.SUNDAY": "So",
    "label.areYouSureWantToDelete": "Sollen die Tarifzeiten wirklich vollständig gelöscht werden?",
  },
  en: {
    energySupplier: "Energy supplier",
    tariffTimes: "Tariff times",
    lowTariff: "Low tariff",
    highTariff: "High tariff",
    removeSupplier: "Remove energy supplier",
    addSupplier: "Add energy supplier",
    editSupplier: "Edit energy supplier",
    clock: "O'clock",
    validOnTheseDays: "valid on these days",
    validFrom: "Valid from",
    validUntil: "Valid until",
    noteMessage: "Note: The high tariff is automatically calculated based on the specified low tariff periods.",
    addPeriod: "Add low tariff period",
    definePeriod: "Define period",
    "errorMessage.pleaseSetEnergySupplier": "Please enter a energy supplier",
    "errorMessage.pleaseAddPeriod": "Please add a period",
    "weekday.MONDAY": "Mo",
    "weekday.TUESDAY": "Tu",
    "weekday.WEDNESDAY": "We",
    "weekday.THURSDAY": "Th",
    "weekday.FRIDAY": "Fr",
    "weekday.SATURDAY": "Sa",
    "weekday.SUNDAY": "Su",
    "label.areYouSureWantToDelete": "Do you really want to delete the tariff times completely?",
  },
}

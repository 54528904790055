import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"
import { Box, Typography } from "@mui/material"
import { FormRowCell, FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { validationError } from "../../../../Shared.Validation"
import { participantAddressCountryKeys } from "../../../../../domain/participant/Participant.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"

const ParticipantDomicileAddressEditForm = (
  props: Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<ParticipantResponse>, "errors" | "values" | "touched"> &
    Pick<FormikHelpers<ParticipantResponse>, "setFieldValue">,
) => {
  const { t } = useTranslation("customer-participants")
  const { handleChange, handleBlur, touched, values, errors, setFieldValue } = props

  const labelLayout = (label: string) => (
    <Typography variant="body1" textAlign="start" sx={{ fontSize: "12px", mt: 2 }}>
      {label}
    </Typography>
  )

  return (
    <>
      <FormView>
        <Box p={0}>
          <FormRowColumn>
            <FormRowCell sx={{ pr: 5 }}>
              {labelLayout(t("registerForm.street"))}
              <SingleLineTextField
                required
                sx={{ paddingRight: "10px" }}
                name="addressStreet"
                type="text"
                helperText={validationError(errors.domicileAddress?.street, touched.domicileAddress?.street)}
                value={values.domicileAddress?.street}
                onChange={handleChange("domicileAddress.street")}
                onBlur={handleBlur}
              />
            </FormRowCell>
            <FormRowCell sx={{ pl: 5 }}>
              {labelLayout(t("registerForm.houseNumber"))}
              <SingleLineTextField
                required
                name="addressHouseNumber"
                type="text"
                helperText={validationError(errors.domicileAddress?.houseNumber, touched.domicileAddress?.houseNumber)}
                value={values.domicileAddress?.houseNumber}
                onChange={handleChange("domicileAddress.houseNumber")}
                onBlur={handleBlur}
              />
            </FormRowCell>
          </FormRowColumn>
          <FormRowColumn>
            <FormRowCell sx={{ pr: 5 }}>
              {labelLayout(t("registerForm.co"))}
              <SingleLineTextField
                name="addressCO"
                type="text"
                helperText={validationError(errors.domicileAddress?.co, touched.domicileAddress?.co)}
                value={values.domicileAddress?.co}
                onChange={handleChange("domicileAddress.co")}
                onBlur={handleBlur}
              />
            </FormRowCell>
            <FormRowCell sx={{ pl: 5 }}>
              {labelLayout(t("registerForm.poBox"))}
              <SingleLineTextField
                name="addressPostBox"
                type="text"
                helperText={validationError(errors.domicileAddress?.poBox, touched.domicileAddress?.poBox)}
                value={values.domicileAddress?.poBox}
                onChange={handleChange("domicileAddress.poBox")}
                onBlur={handleBlur}
              />
            </FormRowCell>
          </FormRowColumn>
          <FormRowColumn>
            <FormRowCell sx={{ pr: 5 }}>
              {labelLayout(t("registerForm.postCode"))}
              <SingleLineTextField
                required
                name="addressPostCode"
                type="text"
                helperText={validationError(errors.domicileAddress?.postCode, touched.domicileAddress?.postCode)}
                value={values.domicileAddress?.postCode}
                onChange={handleChange("domicileAddress.postCode")}
                onBlur={handleBlur}
                inputProps={{ maxLength: 6 }}
              />
            </FormRowCell>
            <FormRowCell sx={{ pl: 5 }}>
              {labelLayout(t("registerForm.city"))}
              <SingleLineTextField
                required
                name="addressCity"
                type="text"
                helperText={validationError(errors.domicileAddress?.city, touched.domicileAddress?.city)}
                value={values.domicileAddress?.city}
                onChange={handleChange("domicileAddress.city")}
                onBlur={handleBlur}
              />
            </FormRowCell>
          </FormRowColumn>
          <FormRowColumn>
            <FormRowCell sx={{ pr: 5 }}>
              {labelLayout(t("registerForm.land"))}
              <SelectPicker
                name="addressCountry"
                type="text"
                helperText={validationError(errors.domicileAddress?.land, touched.domicileAddress?.land)}
                value={values.domicileAddress?.land}
                emptyValue="None"
                items={participantAddressCountryKeys.map((participantAddressCountry) => ({
                  label: participantAddressCountry,
                  value: participantAddressCountry,
                }))}
                onChange={({ target }) => setFieldValue("domicileAddress.land", target.value)}
                onBlur={handleBlur}
              />
            </FormRowCell>
            <FormRowCell sx={{ pl: 5 }}></FormRowCell>
          </FormRowColumn>
        </Box>
      </FormView>
    </>
  )
}

export default ParticipantDomicileAddressEditForm

import { DataGrid } from "@mui/x-data-grid"
import { LinearProgress } from "@mui/material"
import { EmptyTableRow, CustomPagination } from "./"
import { DataGridTableProps, RowData } from "./dataGridTable.Model"

const defaultDataTableSlots = {
  loadingOverlay: () => <LinearProgress />,
  noRowsOverlay: EmptyTableRow,
  pagination: CustomPagination,
}

export const DataGridTable = <T extends RowData<T>>({
  rows,
  columns,
  defaultPageSize,
  ...props
}: DataGridTableProps<T>) => {
  return (
    <DataGrid<T>
      rows={rows}
      columns={columns}
      autoHeight
      ignoreDiacritics
      rowHeight={64}
      disableColumnMenu
      disableColumnFilter
      disableRowSelectionOnClick
      hideFooterSelectedRowCount
      hideFooter={!rows?.length}
      sortingOrder={["asc", "desc"]}
      pageSizeOptions={[5, 10, 25, 50]}
      {...props}
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: defaultPageSize ?? 10,
          },
        },
        ...props.initialState,
      }}
      slots={{ ...defaultDataTableSlots, ...props.slots }}
    />
  )
}

import { useState } from "react"
import { Paper } from "@mui/material"
import { RouteComponentProps } from "react-router"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { initialParticipantValues } from "../../../../../domain/participant/Participant.Model"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ParticipantAdminCreateSchema } from "../../../manager/participants/ParticipantsValidation.Schema"
import { ParticipantCreateUpdateFormFields } from "../../../manager/participants/components/ParticipantCreateUpdateFormFields"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import {
  ParticipantAdminCreateRequest,
  ParticipantAdminUpdateRequest,
} from "../../../../../data/generated-sources/openapi"
import { createAreaParticipant } from "../../../../../domain/portal/admin/areas/Areas.Repository"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

const AreaCreateParticipant = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { areaId } = params
  const { t } = useTranslation("participant")
  const [participantError, setParticipantError] = useState<AxiosErrorDataType>()

  const { mutate: createParticipant, isLoading: isCreating } = useMutation(
    ["createParticipant"],
    (payload: ParticipantAdminUpdateRequest) => createAreaParticipant(areaId, payload),
    {
      onSuccess: () => history.goBack(),
      onError: setParticipantError,
    },
  )

  const handleOnSubmit = ({
    sapGp,
    gpType,
    contactDetails,
    personalData,
    domicileAddress,
  }: ParticipantAdminCreateRequest) => {
    createParticipant({
      sapGp,
      gpType,
      personalData,
      domicileAddress,
      contactDetails: {
        ...initialParticipantValues.contactDetails,
        ...contactDetails,
      },
    })
  }

  return (
    <>
      <ErrorAlert
        visible={!!participantError}
        message={t(`error-codes:${participantError?.response?.data?.code || participantError?.code || "OTHER"}`)}
      />
      <Formik<ParticipantAdminCreateRequest>
        enableReinitialize
        initialValues={initialParticipantValues}
        validationSchema={ParticipantAdminCreateSchema}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <Paper>
              <ParticipantCreateUpdateFormFields {...props} adminCreate />
            </Paper>
            <FormUpdateActionsView
              isValid={props.isValid}
              dirty={props.dirty}
              isLoading={isCreating}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("form.action.cta")}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default AreaCreateParticipant

import { TFunction } from "i18next"

import { FieldTouched, validate, ValidField } from "../../../../Shared.Validation"

export const validateProfileNameForm = {
  firstName: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("subtitle.firstName"),
        })
      }
      return ValidField
    }, touched),
  lastName: (value: string, touched: FieldTouched, t: TFunction) =>
    validate(() => {
      if (!value) {
        return t("shared:validation.mandatory", {
          field: t("subtitle.lastName"),
        })
      }
      return ValidField
    }, touched),
}

import { useTranslation } from "react-i18next"
import { Paper, Grid } from "@mui/material"
import { CommonFormikProps } from "../../../../Shared.Utils"
import { ContractMasterDataModify } from "../../../../../domain/portal/admin/contracts/Contracts.Models"
import { ContractState } from "../../../../../data/generated-sources/openapi"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"
import DateUtils from "../../../../../services/utils/DateUtils"

import { ContractFormRow } from "../common/CommonFormFragments"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"

interface ContractMasterDataProps extends CommonFormikProps<ContractMasterDataModify> {
  isEditForm: boolean
}

const ContractMasterDataFields = ({
  values,
  errors,
  touched,
  isEditForm,
  handleBlur,
  setFieldValue,
  handleChange,
}: ContractMasterDataProps) => {
  const { t } = useTranslation("shared")
  let disabledFields: string[] = []
  switch (values?.activeState) {
    case ContractState.DRAFT:
      disabledFields = ["endDate", "areaName"]
      break
    case ContractState.ACTIVE:
    case ContractState.RELEASED:
      disabledFields = ["startDate", "endDate", "productId", "areaName"]
      break
    case ContractState.TERMINATED:
      disabledFields = ["name", "startDate", "endDate", "productId", "areaName"]
      break
    default:
      disabledFields = []
      break
  }

  return (
    <Paper>
      <Grid container rowSpacing={2}>
        <ContractFormRow>
          <SingleLineTextField
            required
            autoFocus
            name="name"
            type="text"
            label={t("label.name")}
            helperText={touched.name && errors.name ? errors.name : undefined}
            value={values.name}
            onChange={(event) => setFieldValue("name", event.target.value)}
            onBlur={handleBlur}
            disabled={disabledFields?.includes("name")}
          />
        </ContractFormRow>
        <ContractFormRow />
        <ContractFormRow>
          <SingleLineDatePicker
            required
            name="startDate"
            label={t("form.startDate")}
            value={apiFormattedDateToTimestamp(values.startDate)}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue("startDate", DateUtils.getDeFormatDate(value))}
            disabled={disabledFields?.includes("startDate")}
            helperText={touched.startDate && errors.startDate ? errors.startDate : undefined}
          />
        </ContractFormRow>
        <ContractFormRow>
          <SingleLineDatePicker
            name="endDate"
            label={t("form.endDate")}
            value={apiFormattedDateToTimestamp(values.endDate)}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue("endDate", DateUtils.getDeFormatDate(value))}
            disabled={disabledFields?.includes("endDate")}
          />
        </ContractFormRow>
        <ContractFormRow>
          <SelectPicker
            required
            type="text"
            name="productId"
            label={t("form.product")}
            value={values.productId}
            items={(values.products ?? []).map((product) => ({
              label: product.name,
              value: product.id,
            }))}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled={disabledFields?.includes("productId")}
            helperText={touched.productId && errors.productId ? errors.productId : undefined}
          />
        </ContractFormRow>
        {isEditForm && (
          <ContractFormRow>
            <SingleLineTextField type="text" name="areaName" label={t("label.area")} value={values.areaName} disabled />
          </ContractFormRow>
        )}
      </Grid>
    </Paper>
  )
}

export default ContractMasterDataFields

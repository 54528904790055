import { apiHeaders } from "../../../../Domain.Calls"
import { InitialBillingAdminResponse } from "./BillingsInitial.Model"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"

export const getOnlyInitialBillingById = (billingId: string) => {
  const headers = apiHeaders(DOMAIN_DEPENDENCIES)
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(billingId, headers)
}

export const getAdminServiceBillingById = async (billingId: string): Promise<InitialBillingAdminResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.adminServiceBillingsApi.getAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  const { data: contractData } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractById(
    data.contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return { ...data, contractName: contractData.name }
}

export const recalculateAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.recalculateAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const approveOrCancelInitialBillingAction = async (
  billingId: string,
  approveOrCancelAction: "approve" | "cancel",
) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.putAdminServiceBillingStateChangeById(
    billingId,
    approveOrCancelAction,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sapSendAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.sapSendAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const deleteAdminServiceBillingById = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminServiceBillingsApi.deleteAdminServiceBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

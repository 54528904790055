import { ORDERED_NUMBER_COMPARATOR, ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ExtendedIndividualParticipantBilling } from "../participantBilling/ParticipanIndividualBillingTable.Component"
import { ParticipantIndividualBillingEnum } from "../participantBilling/ParticipantBilling.Enum"

export const ParticipantIndividualBillingComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ParticipantIndividualBillingEnum.STATUS:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ParticipantIndividualBillingEnum.PERIOD:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
    case ParticipantIndividualBillingEnum.AMOUNT:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_NUMBER_COMPARATOR(Number(a?.finalAmountDue) ?? 0, Number(b?.finalAmountDue) ?? 0, orderBy.direction)
    case ParticipantIndividualBillingEnum.UNIT:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_STRING_COMPARATOR(a?.utilityUnit?.name ?? "", b?.utilityUnit?.name ?? "", orderBy.direction)
    case ParticipantIndividualBillingEnum.PARTICIPANT:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_STRING_COMPARATOR(
          a.participants[0]?.participant?.personalData?.salutation
            ? a.participants[0]?.participant?.personalData?.salutation
            : "",
          b?.participants[0]?.participant?.personalData?.salutation
            ? b.participants[0]?.participant?.personalData?.salutation
            : "",
          orderBy.direction,
        )
    default:
      return (a: ExtendedIndividualParticipantBilling, b: ExtendedIndividualParticipantBilling) =>
        ORDERED_STRING_COMPARATOR(a.id, b.id, orderBy.direction)
  }
}

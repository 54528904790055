import { useTranslation } from "react-i18next"
import { SpaceBetweenBox } from "../../../../uikit/box/AlignmentBox"
import { PageHeader } from "../../../../uikit/typography/Header"
import { NavigationButton } from "../../../../uikit/button/NavigationButton"
import { Grid, Paper } from "@mui/material"

interface SettingsDetailComponentProps {
  navigateToChangePassword: () => void
}

export const SettingsDetailComponent = (props: SettingsDetailComponentProps) => {
  const { t } = useTranslation("settings")
  const { navigateToChangePassword } = props
  return (
    <Paper>
      <SpaceBetweenBox>
        <PageHeader>{t("detail.title")}</PageHeader>
      </SpaceBetweenBox>
      <Grid
        container
        direction="column"
        sx={{
          padding: 1,
        }}
      >
        <NavigationButton
          label={t("detail.navigation.reset-password")}
          selected={false}
          onClick={navigateToChangePassword}
        />
      </Grid>
    </Paper>
  )
}

import { LOCATION_CHANGE } from "connected-react-router"
import { AnyAction } from "redux"

import {
  asError,
  asStarted,
  asSuccess,
  initialViewState,
  startLoading,
  ViewState,
  withDomainError,
  withDomainResult,
} from "../../../Shared.Reducer"
import {
  ZevPriceCalculatorTariffs,
  EnrichedConsumptionPoint,
  MyZevPriceUpsert,
  MyZevSummary,
} from "../../../../domain/portal/manager/my-zev/MyZev.Model"
import { MyZevElectricityCalculatorActionType } from "./MyZevElectricityCalculator.Epic"
import {
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorInput,
  ZevPriceCalculatorPricesResponse,
} from "../../../../data/generated-sources/openapi"

export interface MyZevElectricityCalculatorState {
  priceCalculatorEnergyViewState: ViewState<ZevPriceCalculatorEnergy>
  priceCalculatorInputViewState: ViewState<ZevPriceCalculatorInput>
  priceCalculatorTariffsViewState: ViewState<ZevPriceCalculatorTariffs[]>
  priceCalculatorPricesViewState: ViewState<ZevPriceCalculatorPricesResponse>
  priceCalculatorPricesSuppliedEnergyViewState: ViewState<ZevPriceCalculatorPricesResponse>
  updatePriceCalculatorInputViewState: ViewState<boolean>
  updatePrices: ViewState<boolean>
  enrichConsumptionPointsViewState: ViewState<EnrichedConsumptionPoint[]>
  createPricePackageViewState: ViewState<boolean>
  pricesPackagesViewState: ViewState<MyZevPriceUpsert[]>
  contractViewState: ViewState<MyZevSummary>
}

export const initialState: MyZevElectricityCalculatorState = {
  priceCalculatorEnergyViewState: initialViewState(),
  priceCalculatorInputViewState: initialViewState(),
  priceCalculatorTariffsViewState: initialViewState(),
  priceCalculatorPricesViewState: initialViewState(),
  priceCalculatorPricesSuppliedEnergyViewState: initialViewState(),
  updatePriceCalculatorInputViewState: initialViewState(),
  updatePrices: initialViewState(),
  enrichConsumptionPointsViewState: initialViewState(),
  createPricePackageViewState: initialViewState(),
  pricesPackagesViewState: initialViewState(),
  contractViewState: initialViewState(),
}

export const myZevElectricityCalculatorReducer = (
  state: MyZevElectricityCalculatorState = initialState,
  action: AnyAction,
): MyZevElectricityCalculatorState => {
  switch (action.type) {
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_ENERGY):
      return {
        ...state,
        priceCalculatorEnergyViewState: startLoading<ZevPriceCalculatorEnergy>(state.priceCalculatorEnergyViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_ENERGY):
      return {
        ...state,
        priceCalculatorEnergyViewState: withDomainResult<ZevPriceCalculatorEnergy>(
          state.priceCalculatorEnergyViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_ENERGY):
      return {
        ...state,
        priceCalculatorEnergyViewState: withDomainError<ZevPriceCalculatorEnergy>(
          state.priceCalculatorEnergyViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT):
      return {
        ...state,
        priceCalculatorInputViewState: startLoading<ZevPriceCalculatorInput>(state.priceCalculatorInputViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT):
      return {
        ...state,
        priceCalculatorInputViewState: withDomainResult<ZevPriceCalculatorInput>(
          state.priceCalculatorInputViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT):
      return {
        ...state,
        priceCalculatorInputViewState: withDomainError<ZevPriceCalculatorInput>(
          state.priceCalculatorInputViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID):
      return {
        ...state,
        priceCalculatorTariffsViewState: startLoading<ZevPriceCalculatorTariffs[]>(
          state.priceCalculatorTariffsViewState,
        ),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID):
      return {
        ...state,
        priceCalculatorTariffsViewState: withDomainResult<ZevPriceCalculatorTariffs[]>(
          state.priceCalculatorTariffsViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID):
      return {
        ...state,
        priceCalculatorTariffsViewState: withDomainError<ZevPriceCalculatorTariffs[]>(
          state.priceCalculatorTariffsViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES):
      return {
        ...state,
        priceCalculatorPricesViewState: startLoading<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesViewState,
        ),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES):
      return {
        ...state,
        priceCalculatorPricesViewState: withDomainResult<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES):
      return {
        ...state,
        priceCalculatorPricesViewState: withDomainError<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE):
      return {
        ...state,
        updatePriceCalculatorInputViewState: startLoading<boolean>(state.updatePriceCalculatorInputViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE):
      return {
        ...state,
        updatePriceCalculatorInputViewState: withDomainResult<boolean>(
          state.updatePriceCalculatorInputViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE):
      return {
        ...state,
        updatePriceCalculatorInputViewState: withDomainError<boolean>(
          state.updatePriceCalculatorInputViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICES_UPDATE):
      return {
        ...state,
        updatePrices: startLoading<boolean>(state.updatePrices),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICES_UPDATE):
      return {
        ...state,
        updatePrices: withDomainResult<boolean>(state.updatePrices, action.result),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICES_UPDATE):
      return {
        ...state,
        updatePrices: withDomainError<boolean>(state.updatePrices, action.result),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET):
      return {
        ...state,
        priceCalculatorPricesSuppliedEnergyViewState: startLoading<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesSuppliedEnergyViewState,
        ),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET):
      return {
        ...state,
        priceCalculatorPricesSuppliedEnergyViewState: withDomainResult<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesSuppliedEnergyViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET):
      return {
        ...state,
        priceCalculatorPricesSuppliedEnergyViewState: withDomainError<ZevPriceCalculatorPricesResponse>(
          state.priceCalculatorPricesSuppliedEnergyViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_CONSUMPTION_POINTS):
      return {
        ...state,
        enrichConsumptionPointsViewState: startLoading<EnrichedConsumptionPoint[]>(
          state.enrichConsumptionPointsViewState,
        ),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_CONSUMPTION_POINTS):
      return {
        ...state,
        enrichConsumptionPointsViewState: withDomainResult<EnrichedConsumptionPoint[]>(
          state.enrichConsumptionPointsViewState,
          action.result,
        ),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_CONSUMPTION_POINTS):
      return {
        ...state,
        enrichConsumptionPointsViewState: withDomainError<EnrichedConsumptionPoint[]>(
          state.enrichConsumptionPointsViewState,
          action.result,
        ),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGE_CREATE):
      return {
        ...state,
        createPricePackageViewState: startLoading<boolean>(state.createPricePackageViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGE_CREATE):
      return {
        ...state,
        createPricePackageViewState: withDomainResult<boolean>(state.createPricePackageViewState, action.result),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGE_CREATE):
      return {
        ...state,
        createPricePackageViewState: withDomainError<boolean>(state.createPricePackageViewState, action.result),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGES):
      return {
        ...state,
        pricesPackagesViewState: startLoading<MyZevPriceUpsert[]>(state.pricesPackagesViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGES):
      return {
        ...state,
        pricesPackagesViewState: withDomainResult<MyZevPriceUpsert[]>(state.pricesPackagesViewState, action.result),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGES):
      return {
        ...state,
        pricesPackagesViewState: withDomainError<MyZevPriceUpsert[]>(state.pricesPackagesViewState, action.result),
      }
    case asStarted(MyZevElectricityCalculatorActionType.MY_CONTRACT):
      return {
        ...state,
        contractViewState: startLoading<MyZevSummary>(state.contractViewState),
      }
    case asSuccess(MyZevElectricityCalculatorActionType.MY_CONTRACT):
      return {
        ...state,
        contractViewState: withDomainResult<MyZevSummary>(state.contractViewState, action.result),
      }
    case asError(MyZevElectricityCalculatorActionType.MY_CONTRACT):
      return {
        ...state,
        contractViewState: withDomainError<MyZevSummary>(state.contractViewState, action.result),
      }
    case LOCATION_CHANGE:
      return initialState

    default:
      return {
        ...state,
      }
  }
}

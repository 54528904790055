import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Divider, Paper, Stack, Typography } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { VewaBillingConsumptionOverview } from "../../shared/vewaBillings/VewaBillingConsumptionOverview"
import { VewaBillingCostShares } from "../../shared/vewaBillings/VewaBillingCostShares"
import {
  adminCreateVewaParticipantBillingBills,
  adminGetVewaAllParticipantBillingDetailsById,
  adminGetVewaAllParticipantBillingParticipants,
  adminReloadVewaParticipantBillingData,
  adminUpdateBillingCostShares,
} from "../../../../domain/portal/admin/billings/Billings.Repository"
import { BillingStatusTypeView } from "./participant/view/BillingStatusTypeView"
import { BillingStatusType } from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { VewaBillingParticipatioins } from "../../shared/vewaBillings/VewaBillingParticipatioins"
import { VewaBillingAggregateConsumption } from "../../shared/vewaBillings/VewaBillingAggregateConsumption"
import { PrimaryButton } from "../../../../uikit/button/PrimaryButton"
import {
  VewaAllParticipantsBillingState,
  VewaCostShare,
  VewaCostShares,
} from "../../../../data/generated-sources/openapi"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { ConfirmReloadBillingDialog } from "../../../../uikit/confirmDialog/ConfirmReloadBillingDialog"

export const VewaBillingValidateDetailsPage = ({
  match: { params },
  history,
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { billingId, id: contractId } = params
  const { t } = useTranslation()
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: bill,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getVewaBill"], () => adminGetVewaAllParticipantBillingDetailsById(billingId), {
    enabled: !!billingId,
    onError: setError,
    onSuccess: (billData) => {
      if (billData.activeState !== VewaAllParticipantsBillingState.IN_PROGRESS) {
        history.push(`/management-contracts/${contractId}`)
      }
    },
  })

  const {
    data: participations,
    remove: removeParticipantData,
    isLoading: isLoadingParticipation,
  } = useQuery(["getVewaParticipation"], () => adminGetVewaAllParticipantBillingParticipants(billingId), {
    enabled: !!billingId,
    onError: setError,
  })

  useEffect(() => {
    return () => {
      removeBill()
      removeParticipantData()
    }
  }, [removeBill, removeParticipantData])

  const navigateToUtilityUnitDetails = (unitId: string) => {
    history.push(`/utility-units/${unitId}`)
  }

  const handleCreateBills = () => history.push(`/management-contracts/${contractId}/billings/vewa/${billingId}/bills`)

  const { mutate: createBills, isLoading: isCreatingBills } = useMutation(
    ["createBillsForBilling"],
    () => adminCreateVewaParticipantBillingBills(billingId),
    {
      onSuccess: handleCreateBills,
      onError: setError,
    },
  )

  const { mutate: updateBillingCostShares, isSuccess: isUpdatingSuccess } = useMutation(
    ["updateBillingCostShares"],
    (vewaCostShares: VewaCostShares) => adminUpdateBillingCostShares(billingId, vewaCostShares),
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const { mutate: reloadBillingData, isLoading: isReloadingData } = useMutation(
    ["reloadBillingData"],
    () => adminReloadVewaParticipantBillingData(billingId),
    {
      onSuccess: () => history.push(`/management-contracts/${contractId}`),
      onError: setError,
    },
  )

  const handleUpdate = (vewaCostShareData: VewaCostShare[]) => {
    updateBillingCostShares({ costShares: vewaCostShareData })
  }

  return (
    <Paper>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      {bill?.activeState && <BillingStatusTypeView billingStatusType={BillingStatusType[bill?.activeState]} />}
      <Typography my={4} variant="h2">
        {t("billings-participant:detail.all.title")}
      </Typography>
      <VewaBillingConsumptionOverview
        totalColdConsumption={bill?.totalColdConsumption ?? 0}
        totalColdWaterConsumption={bill?.totalColdWaterConsumption ?? 0}
        totalHeatConsumption={bill?.totalHeatConsumption ?? 0}
        totalWarmWaterConsumption={bill?.totalWarmWaterConsumption ?? 0}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingParticipatioins
        isLoading={isLoadingParticipation}
        participants={participations?.elements || []}
        onGoToUnitClick={navigateToUtilityUnitDetails}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingCostShares
        costShares={bill?.costAllocations?.costShares}
        isUpdateSuccess={isUpdatingSuccess}
        onUpdateSubmit={handleUpdate}
      />
      <Divider sx={{ my: 6 }} />
      <VewaBillingAggregateConsumption
        isLoading={isLoadingParticipation}
        billStartDate={bill?.startDate ?? ""}
        billEndDate={bill?.endDate ?? ""}
        participants={participations?.elements || []}
      />

      <Stack p={5} pt={10} alignItems={"center"} justifyContent={"flex-end"} flexDirection={"row"}>
        <ConfirmReloadBillingDialog onConfirmClick={() => reloadBillingData()} isLoading={isReloadingData} />
        <PrimaryButton
          disabled={isCreatingBills}
          isLoading={isCreatingBills}
          onClick={() => createBills()}
          label={t("settlements:list-billing-makeBill")}
        />
      </Stack>
    </Paper>
  )
}

import { ManagerDataItemBox } from "../../../../../uikit/box/DataItemBox"
import { ParticipantContactDetails, ParticipantPersonalData } from "../../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"

interface ParticipantDetailPersonalDataPropsInterface {
  personalData: ParticipantPersonalData
  contactDetails: ParticipantContactDetails
  gpType: string
}

const ParticipantDetailPersonalData = (props: ParticipantDetailPersonalDataPropsInterface) => {
  const { personalData, contactDetails, gpType } = props
  const { t } = useTranslation("customer-participants")

  const participantFirstPerson = () => {
    return `${personalData?.firstName} ${personalData?.lastName}`
  }
  const participantSecondPerson = () => {
    if (!personalData?.firstNameSecondPerson) return "-"
    return `${personalData?.firstNameSecondPerson} ${personalData?.lastNameSecondPerson}`
  }

  return (
    <>
      <ManagerDataItemBox title={t("label.businessPartnerType")} value={t(`registerForm.gptype.${gpType}`)} />
      <ManagerDataItemBox title={t("detail.contactPerson")} value={participantFirstPerson()} />
      <ManagerDataItemBox title={t("detail.secondContactPerson")} value={participantSecondPerson()} />
      <ManagerDataItemBox title={t("registerForm.phone")} value={contactDetails?.phone} />
      <ManagerDataItemBox title={t("registerForm.mobile")} value={contactDetails?.mobile} />
      <ManagerDataItemBox title={t("registerForm.email")} value={contactDetails?.email} />
    </>
  )
}

export default ParticipantDetailPersonalData

import { Map } from "immutable"
import { ZevPricePackage, ZevPrices } from "../../../../data/generated-sources/openapi"

export enum PriceMeasurementType {
  CONSUMPTION_DEPENDANT = "CONSUMPTION_DEPENDANT",
  FLAT_RATE = "FLAT_RATE",
}

export type PricePackageUpsert = Map<number, PricePackageItem>

export interface PricePackageItem {
  id: number
  order: number
  priceTitle: string
  priceSolarPower: string
  priceHighTariff: string
  priceLowTariff: string
  additionalServicesPrice: string
  measurementType: PriceMeasurementType
  spikePrice: string
  containsSpikePrice: boolean
}

export const initialPricePackageItem: PricePackageItem = {
  id: 0,
  order: 0,
  priceTitle: "",
  priceSolarPower: "",
  priceHighTariff: "",
  priceLowTariff: "",
  additionalServicesPrice: "",
  measurementType: PriceMeasurementType.CONSUMPTION_DEPENDANT,
  spikePrice: "",
  containsSpikePrice: false,
}

const initialPrices: ZevPrices = {
  solarPrice: "",
  highTariffPrice: "",
  lowTariffPrice: "",
  additionalServicesPrice: "",
  spikePrice: undefined,
  isFixedRate: false,
}

export const initialZevPriceItem: ZevPricePackage = {
  id: 1,
  order: 1,
  name: "",
  prices: initialPrices,
}

import { AreaEnum } from "../area/Area.Enum"
import { AreaApiEnum } from "../Components.Enums"
import AreaTableComponent from "../area/AreaTable.Component"
import { AreaAdminResponse } from "../../../../../data/generated-sources/openapi"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const AREA_LIST_COMPONENT_CONFIG = {
  area: {
    name: "area",
    header: {
      title: "area:label.name",
      showButton: true,
      showFilter: true,
      redirectUrl: () => "/area/create/new",
    },
    columnEnums: AreaEnum,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: AreaEnum.NAME,
      direction: "desc",
    },
    columnComparator: (orderBy: TableColumnSortNew) => {
      switch (orderBy.column) {
        case AreaEnum.STATUS:
          return (a: AreaAdminResponse, b: AreaAdminResponse) =>
            ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
        default:
          return (a: AreaAdminResponse, b: AreaAdminResponse) =>
            ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction)
      }
    },
    tableHeaders: [
      {
        column: AreaEnum.STATUS,
        label: "shared:status.label",
        width: "5%",
        orderable: true,
      },
      {
        column: AreaEnum.NAME,
        label: "shared:label.name",
        width: "40%",
        orderable: true,
      },
    ],
    renderTableRows: (item: AreaAdminResponse) => <AreaTableComponent itemConfig={item} key={getUniqueId()} />,
  },
}

export const AREA_API_CONFIG = {
  area: AreaApiEnum,
}

import { I18nNamespace } from "../../../App.i18n"

export const ContractsManagementI18n: I18nNamespace = {
  name: "contracts-management",
  de: {
    "actionButton.replace": "Vertrag ersetzen",
    "actionButton.release": "Vertrag freigeben",
    "actionButton.disable": "Vertrag deaktivieren",
    "actionButton.delete": "Vertrag löschen",
    "actionButton.activate": "Zählpunkte aktivieren",
    "header.title": "Verwalter",
    "breadcrumbs.contracts": "VERTRÄGE",
    "breadcrumbs.contract": "VERTRÄG",
    "breadcrumbs.contractDetails": "VERTRAG DETAILSEITE",
    "breadcrumbs.contract.edit": "VERTRAG BEARBEITEN",
    "breadcrumbs.contact.edit": "KONTAKT BEARBEITEN",
    "breadcrumbs.managers.edit": "VERWALTER BEARBEITEN",
    "breadcrumbs.building.edit": "ANSCHLUSSOBJEKTE BEARBEITEN",
    "breadcrumbs.medium.edit": "MEDIUM BEARBEITEN",
    "breadcrumbs.billing.edit": "ABRECHNUNGSEINSTELLUNGEN BEARBEITEN",
    "breadcrumbs.prices.edit": "INTERNE STROMPREISE BEARBEITEN",
    "breadcrumbs.comment.edit": "NOTIZ BEARBEITEN",
    "breadcrumbs.allParticipantBillings": "PERIODISCHE ABRECHNUNGEN",
    "breadcrumbs.individualParticipantBillings": "MIETERWECHSEL",
    "breadcrumbs.createBill": "RECHNUNG ERSTELLEN",
    "breadcrumbs.bills": "RECHNUNG",
    "breadcrumbs.meters.activate": "ZÄHLPUNKTE AKTIVIEREN",
    "billingForm.title": "ABRECHNUNGSEINSTELLUNGEN",
    "billingForm.period": "Abrechnungsperiode",
    "billingForm.nextBillingDate": "Nächstes Abrechnungsdatum",
    "billingForm.billingFrequency": "Abrechnungsfrequenz",
    "billingForm.nextBillingFrequency": "Nächste Abrechnungsfrequenz",
    "billingForm.emptyBillingFrequency": "Bitte auswählen",
    "billingForm.contactPerson": "Hauptansprechsperson",
    "billingForm.name": "Name",
    "billingForm.phone": "Telefon",
    "billingForm.mobile": "Mobile",
    "billingForm.email": "E-Mail",
    "billingForm.paymentInfo": "Zahlungsinformationen",
    "billingForm.payee": "Begünstigter",
    "billingForm.vatNumber": "MwSt-ID wenn zutreffend",
    "billingForm.iban": "IBAN",
    "billingForm.saveBilling": "Abrechnungseinstellungen speichern",
    "billingFrequency.BIANNUAL": "Halbjährlich",
    "billingFrequency.MONTHLY": "Monatlich",
    "billingFrequency.QUARTERLY": "Quartal",
    "billingFrequency.YEARLY": "Jährlich",
    "billingFrequency.YEARLY_ROLLING": "Jährliches Rollen",
    "managersForm.title": "Verwalter",
    "managersForm.search": "Verwalter suchen und hinzufügen",
    "managersForm.saveManager": "Verwalter speichern",
    "participants.title": "teilnehmerliste",
    "participants.current": "Aktuell",
    "participants.synchronized": "synchronisiert",
    "participants.firstName": "Vorname",
    "participants.lastName": "Name",
    "participants.gpNumber": "GP nummer",
    "participants.email": "email",
    "participants.gpType.PERSON": "Person",
    "priceForm.title": "INTERNE STROMPREISE",
    "priceForm.savePrice": "Interne Strompreise speichern",
    "priceForm.name": "Titel",
    "priceForm.solarPrice": "ZEV Sonnenstrom / kWh",
    "priceForm.highTariffPrice": "Netzstrom Hochtarif / kWh",
    "priceForm.lowTariffPrice": "Netzstrom Niedertarif / kWh",
    "priceForm.withSpike": "Mit Leistungspreis",
    "priceForm.withoutSpike": "Ohne Leistungspreis",
    "priceForm.flatRate": "Pauschal",
    "priceForm.consumptionDependant": "Verbrauchsabhängig",
    "priceForm.kwhMeasurement": "Messung und Dienstleistung / kWh",
    "priceForm.monthlyMeasurement": "Messung und Dienstleistung / Monat",
    "priceForm.dialog.subtitle": "Bestätigen, um mit dem Löschen fortzufahren.",
    "priceForm.spikePrice": "Leistungspreis / KW / Monat",
    "contactForm.title": "KONTAKT",
    "contactForm.takeName": "Name des Vertrags übernehmen",
    "contactForm.contractor": "Vertragspartner",
    "contactForm.address": "Anschrift",
    "contactForm.addressAdditional": "Adresszusatz",
    "contactForm.refNumb": "Externe Referenznummer",
    "contactForm.phone": "Telefon",
    "contactForm.mobile": "Mobile",
    "contactForm.email": "Korrespondenz E-Mail",
    "contactForm.street": "Strasse",
    "contactForm.houseNumber": "Nummer (Nr.)",
    "contactForm.postalCode": "PLZ",
    "contactForm.city": "Ort",
    "contactForm.saveContact": "Kontakt speichern",
    "contract.buildings.title": "anschlussobjekt",
    "contract.building": "Anschlussobjekt",
    "contract.update.success": "Vertrag erfolgreich aktualisiert",
    "contract.update.error": "Beim Versuch, den Vertrag zu aktualisieren, ist ein Fehler aufgetreten",
    "contract.updateBuilding.success": "Die Änderungen am Anschlussobjekt wurde erfolgreich gespeichert",
    "contract.paymentDeadlineInDays": "Zahlungsfrist in Tagen",
    "serviceBilling.title": "DIENSTLEISTUNGSKONFIGURATION",
    "serviceBilling.pricingModel": "Preismodell für Zählerinfrastruktur",
    "serviceBilling.RENTAL": "Miete",
    "serviceBilling.PURCHASE": "Kauf",
    "serviceBilling.updatePriceModel": "Preismodell speichern",
    "serviceBilling.labelPriceModel": "Preismodell",
  },
  en: {
    "actionButton.release": "Release contract",
    "actionButton.replace": "Replace contract",
    "actionButton.disable": "Disable contract",
    "actionButton.delete": "Delete contract",
    "actionButton.activate": "Activate Meters",
    "header.title": "Managers",
    "breadcrumbs.contracts": "CONTRACTS",
    "breadcrumbs.contract": "CONTRACT",
    "breadcrumbs.contractDetails": "CONTRACT DETAILS",
    "breadcrumbs.contract.edit": "EDIT CONTRACT",
    "breadcrumbs.contact.edit": "EDIT CONTACT",
    "breadcrumbs.managers.edit": "EDIT MANAGERS",
    "breadcrumbs.building.edit": "EDIT BUILDINGS",
    "breadcrumbs.medium.edit": "EDIT MEDIUM",
    "breadcrumbs.billing.edit": "EDIT BILLING SETTINGS",
    "breadcrumbs.prices.edit": "EDIT PRICES",
    "breadcrumbs.comment.edit": "EDIT COMMENT",
    "breadcrumbs.allParticipantBillings": "ALL BILLINGS",
    "breadcrumbs.individualParticipantBillings": "INDIVIDUAL BILLING",
    "breadcrumbs.createBill": "CREATE A BILL",
    "breadcrumbs.bills": "BILLS",
    "breadcrumbs.meters.activate": "ACTIVATE METERS",
    "billingForm.title": "BILLING SETTINGS",
    "billingForm.period": "Billing period",
    "billingForm.nextBillingDate": "Next billing date",
    "billingForm.billingFrequency": "Nächste Abrechnungsfrequenz",
    "billingForm.nextBillingFrequency": "Billing frequency",
    "billingForm.emptyBillingFrequency": "Please Select",
    "billingForm.contactPerson": "Primary Contact",
    "billingForm.name": "Name",
    "billingForm.phone": "Phone",
    "billingForm.mobile": "Mobile",
    "billingForm.email": "E-Mail",
    "billingForm.paymentInfo": "Payment Information",
    "billingForm.payee": "Beneficiary",
    "billingForm.vatNumber": "VAT ID if applicable",
    "billingForm.iban": "IBAN",
    "billingForm.saveBilling": "Save billing preferences",
    "billingFrequency.BIANNUAL": "Biannual",
    "billingFrequency.MONTHLY": "Monthly",
    "billingFrequency.QUARTERLY": "Quarterly",
    "billingFrequency.YEARLY": "Annually",
    "billingFrequency.YEARLY_ROLLING": "Annual Rolling",
    "managersForm.title": "Managers",
    "managersForm.search": "Search and add managers",
    "managersForm.saveManager": "Save manageres",
    "participants.title": "Participants List",
    "participants.current": "Current",
    "participants.synchronized": "Synchronized",
    "participants.firstName": "First Name",
    "participants.lastName": "Last Name",
    "participants.gpNumber": "GP number",
    "participants.email": "email",
    "participants.gpType.PERSON": "Person",
    "priceForm.title": "Internal electricity prices",
    "priceForm.savePrice": "Save prices",
    "priceForm.name": "Titel",
    "priceForm.solarPrice": "ZEV solar power / kWh",
    "priceForm.highTariffPrice": "Mains electricity high tariff / kWh",
    "priceForm.lowTariffPrice": "Low tariff grid electricity / kWh",
    "priceForm.withoutSpike": "Without spike prices",
    "priceForm.withSpike": "With spike prices",
    "priceForm.flatRate": "Flat-rate",
    "priceForm.consumptionDependant": "Consumption-dependent",
    "priceForm.kwhMeasurement": "Measurement and service / kWh",
    "priceForm.monthlyMeasurement": "Measurement and service / Monat",
    "priceForm.dialog.subtitle": "Confirm to proceed with deleting.",
    "priceForm.spikePrice": "Performance price / KW / month",
    "contactForm.title": "CONTACT",
    "contactForm.takeName": "Take name of the contract",
    "contactForm.address": "Address",
    "contactForm.refNumb": "External reference number",
    "contactForm.addressAdditional": "Additional address",
    "contactForm.phone": "Phone",
    "contactForm.mobile": "Mobile",
    "contactForm.email": "Correspondence Email",
    "contactForm.street": "Street",
    "contactForm.houseNumber": "Number (No.)",
    "contactForm.postalCode": "Postcode",
    "contactForm.city": "City",
    "contactForm.saveContact": "Save contact",
    "contract.building.title": "Buildgings",
    "contract.building": "Building",
    "contract.update.success": "Contract updated successfully",
    "contract.update.error": "An error occurred while attempting to update the contract",
    "contract.updateBuilding.success": "Successfully updated buildings",
    "contract.paymentDeadlineInDays": "Payment deadline in days",
    "serviceBilling.title": "SERVICE BILLING",
    "serviceBilling.pricingModel": "Pricing model",
    "serviceBilling.RENTAL": "Rental",
    "serviceBilling.PURCHASE": "Purchase",
    "serviceBilling.updatePriceModel": "Save Price Model",
    "serviceBilling.labelPriceModel": "Price Model",
  },
}

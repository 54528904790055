/* eslint-disable react/no-is-mounted */
const appHeaderOptions = {
  manager: {
    userType: "MANAGER",
    appTitle: "CKW Abrechnungs-Manager",
    iconId: "ckw-icon-orange",
  },
  admin: {
    userType: "ADMINISTRATOR",
    appTitle: "CKW Adminportal",
    iconId: "ckw-icon",
  },
}

class HelmetHandler {
  private static isAdminPortal() {
    const storage = localStorage
    const key = Object.keys(storage).find((item) => item.includes("authtype"))

    if (key) {
      return storage[key] === appHeaderOptions.admin.userType
    }
    return this.checkUrl()
  }

  private static checkUrl() {
    const pathname = window.location.pathname
    return pathname.includes("admin")
  }

  static configureHamlet() {
    const isAdmin = this.isAdminPortal()
    document.title = isAdmin ? appHeaderOptions.admin.appTitle : appHeaderOptions.manager.appTitle
    const favIcon: HTMLElement | null = document.getElementById(
      isAdmin ? appHeaderOptions.manager.iconId : appHeaderOptions.admin.iconId,
    )
    favIcon && favIcon.remove()
  }
}

export default HelmetHandler

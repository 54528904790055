import { Box, Grid, Paper, Stack, Typography } from "@mui/material"
import { Formik } from "formik"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Accordion, AccordionDetails, AccordionSummary } from "../../../../uikit/accordion/Accordion"
import { SpaceBetweenBox } from "../../../../uikit/box/AlignmentBox"
import { MediumPaddedBox } from "../../../../uikit/box/PaddedBox"
import { PrimaryButton } from "../../../../uikit/button"
import { SingleLineDatePicker } from "../../../../uikit/input/SingleLineDatePicker"
import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { ErrorAlert, ManagerBlackAlert } from "../../../../uikit/Shared.Alert"
import { Body1, Heading5 } from "../../../../uikit/typography/Typography"
import { mapDispatchToProps } from "./MyZevElectricityCalculator.Connect"
import { MyZevElectricityCalculatorState } from "./MyZevElectricityCalculator.Reducer"
import { tariffsByYear } from "../../../../domain/portal/manager/my-zev/MyZev.Mapper"
import {
  DataStatus,
  emptyZevPriceCalculatorInput,
  initialPriceCalculatorEnergyValues,
} from "../../../../domain/portal/manager/my-zev/MyZev.Model"
import { ModalComponent } from "../../../../uikit/modal/Modal"
import { CalculatorInvestmentInformationForm } from "./forms/CalculatorInvestmentInformationForm"
import { CalculatorOperationForm } from "./forms/CalculatorOperationForm"
import { CalculatorTariffForm, getTariff } from "./forms/CalculatorTariffForm"
import { CalculatorYearlyConsumptionForm, computeTotal } from "./forms/CalculatorYearlyConsumptionForm"
import {
  DataStatusView,
  hasInputPrices,
  interestIsHighEnough,
  investmentInformationDataStatus,
  isMissingConsumptionData,
  operationDataStatus,
  yearlyConsumptionDataStatus,
} from "./views/DataStatusView"
import { MyZevElectricityCalculatorFooterView } from "./views/MyZevElectricityCalculatorFooterView"
import { appThemePrimaryColor } from "../../../Shared.Theme"
import {
  ApiErrorCode,
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorInput,
  ZevPriceCalculatorPricesResponse,
} from "../../../../data/generated-sources/openapi"

interface MyZevElectricityCalculatorComponentProps
  extends MyZevElectricityCalculatorState,
    ReturnType<typeof mapDispatchToProps> {
  contractId: string
}

export const MyZevElectricityCalculatorComponent = (props: MyZevElectricityCalculatorComponentProps) => {
  const {
    contractId,
    priceCalculatorEnergyViewState,
    priceCalculatorInputViewState,
    priceCalculatorTariffsViewState,
    priceCalculatorPricesViewState,
    updatePriceCalculatorInputViewState,
    updatePrices,
    priceCalculatorPricesSuppliedEnergyViewState,
    contractViewState,
    getZevPriceCalculatorEnergyById,
    getZevPriceCalculatorInputById,
    getZevPriceCalculatorTariffsById,
    getZevPriceCalculatorPricesById,
    getZevPriceCalculatorPricesSuppliedEnergyById,
    getConsumptionPoints,
    updateZevPriceCalculatorInputById,
    updateZevPricePackageById,
    getMyContractDetails,
  } = props
  const { t } = useTranslation("my-zev")
  const currentDateTime = useMemo(() => new Date().getTime(), [])
  const minYear = useMemo(() => Math.max(new Date().getFullYear() - 5, 2020), [])
  const [selectedYear, setSelectedYear] = useState<number>(new Date(currentDateTime).getFullYear())
  const [expanded, setExpanded] = useState<string[]>([])
  const [priceCalculation, setPriceCalculation] = useState<ZevPriceCalculatorPricesResponse | undefined>(undefined)
  const [priceCalculatorEnergy, setPriceCalculatorEnergy] = useState<ZevPriceCalculatorEnergy>(
    initialPriceCalculatorEnergyValues,
  )

  const priceCalculatorInput = useMemo(
    () => priceCalculatorInputViewState.domainResult,
    [priceCalculatorInputViewState],
  )

  const priceCalculatorTariffs = useMemo(() => {
    const priceCalculatorTariffs = priceCalculatorTariffsViewState.domainResult
    return priceCalculatorTariffs ? tariffsByYear(priceCalculatorTariffs, selectedYear) : []
  }, [priceCalculatorTariffsViewState, selectedYear])

  useEffect(() => {
    if (priceCalculatorPricesSuppliedEnergyViewState.domainResult) {
      setPriceCalculation(priceCalculatorPricesSuppliedEnergyViewState.domainResult)
    }
  }, [priceCalculatorPricesSuppliedEnergyViewState])

  useEffect(() => {
    getZevPriceCalculatorInputById(contractId)
    getZevPriceCalculatorTariffsById(contractId)
    getZevPriceCalculatorPricesById(contractId, selectedYear)
    getZevPriceCalculatorEnergyById(contractId, selectedYear - 1)
    getMyContractDetails(contractId)
  }, [
    contractId,
    selectedYear,
    getZevPriceCalculatorInputById,
    getZevPriceCalculatorEnergyById,
    getZevPriceCalculatorTariffsById,
    getZevPriceCalculatorPricesById,
    getMyContractDetails,
  ])

  useEffect(() => {
    if (contractViewState?.domainResult?.allConsumptionPointIds) {
      getConsumptionPoints(
        contractViewState?.domainResult?.allConsumptionPointIds,
        contractViewState?.domainResult?.buildingsMap,
      )
    }
  }, [getConsumptionPoints, contractViewState?.domainResult])

  useEffect(() => {
    if (priceCalculatorEnergyViewState.domainResult && !priceCalculatorEnergyViewState.domainError) {
      setPriceCalculatorEnergy(priceCalculatorEnergyViewState.domainResult)
    } else {
      setPriceCalculatorEnergy(initialPriceCalculatorEnergyValues)
    }
  }, [priceCalculatorEnergyViewState, setPriceCalculatorEnergy])

  useEffect(() => {
    if (priceCalculatorPricesViewState.domainResult) {
      setPriceCalculation(priceCalculatorPricesViewState.domainResult)
    }
  }, [priceCalculatorPricesViewState, setPriceCalculation])

  useEffect(() => {
    if (updatePriceCalculatorInputViewState.domainResult) {
      const hasEnergyDataError = priceCalculatorEnergyViewState.domainError
      const currentEnergyValues = Object.values(priceCalculatorEnergy).sort().join()
      const originalEnergyValues = Object.values(
        priceCalculatorEnergyViewState.domainResult ?? initialPriceCalculatorEnergyValues,
      )
        .sort()
        .join()
      const modifiedEnergyValues = currentEnergyValues !== originalEnergyValues

      if (hasEnergyDataError || (!!priceCalculatorEnergyViewState.domainResult && modifiedEnergyValues)) {
        const { getComputedProperties } = computeTotal()
        getZevPriceCalculatorPricesSuppliedEnergyById(contractId, selectedYear, {
          ...priceCalculatorEnergy,
          ...getComputedProperties(priceCalculatorEnergy),
        })
      }
    }
  }, [updatePriceCalculatorInputViewState])

  const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    if (newExpanded) setExpanded([...expanded, panel])
    else setExpanded(expanded.filter((itm) => itm !== panel))
  }

  const handleSubmitPriceCalculatorInput = (values: ZevPriceCalculatorInput) => {
    const adjustedOperatingCostsPerKwh = (values.operatingCostsPerKwh ?? 0) / 100
    const adjustedInterestRateOnInvestment = (values.interestRateOnInvestment ?? 0) / 100
    const adjustedSellingElectricityPricePerKwh = (values.sellingElectricityPricePerKwh ?? 0) / 100

    updateZevPriceCalculatorInputById(contractId, {
      ...values,
      operatingCostsPerKwh: adjustedOperatingCostsPerKwh,
      interestRateOnInvestment: adjustedInterestRateOnInvestment,
      sellingElectricityPricePerKwh: adjustedSellingElectricityPricePerKwh,
    })
  }

  const handleUpdateZevPricePackageById = useCallback(() => {
    if (priceCalculation && contractViewState?.domainResult?.pricePackages) {
      updateZevPricePackageById(contractId, contractViewState?.domainResult?.pricePackages[0].id, {
        ...contractViewState?.domainResult?.pricePackages[0],
        prices: priceCalculation.prices,
      })
    }
  }, [priceCalculation, updateZevPricePackageById, contractViewState?.domainResult, contractId])

  const handleYearChange = (chosenYear: number) => {
    const year = new Date(chosenYear).getFullYear()
    setSelectedYear(year)
    if (!priceCalculatorEnergyViewState.isLoading) {
      getZevPriceCalculatorEnergyById(contractId, year - 1)
    }
  }

  const renderPriceCalculatorErrorMessage = () => {
    if (priceCalculatorEnergyViewState?.domainError?.code === ApiErrorCode.ZEV_PRICE_CALCULATOR_INSUFFICIENT_YEAR_DATA)
      return null

    return (
      <ErrorAlert
        visible={!!priceCalculatorPricesViewState.domainError || !!updatePriceCalculatorInputViewState.domainError}
        message={
          priceCalculatorPricesViewState?.domainError?.message ||
          updatePriceCalculatorInputViewState?.domainError?.message
        }
      />
    )
  }

  if (
    priceCalculatorEnergyViewState.isLoading ||
    priceCalculatorInputViewState.isLoading ||
    priceCalculatorTariffsViewState.isLoading
  )
    return <ProgressIndicator />

  return (
    <>
      <Paper>
        <ErrorAlert
          visible={
            !!priceCalculatorInputViewState.domainError ||
            !!priceCalculatorTariffsViewState.domainError ||
            !!contractViewState.domainError
          }
          message={
            priceCalculatorTariffsViewState?.domainError?.message ||
            priceCalculatorInputViewState?.domainError?.message ||
            contractViewState?.domainError?.message
          }
        />
        <MediumPaddedBox>
          <Formik
            initialValues={{
              inputPrices: priceCalculatorInput ?? emptyZevPriceCalculatorInput,
            }}
            onSubmit={(values) => handleSubmitPriceCalculatorInput(values.inputPrices)}
          >
            {({ values, setFieldValue, handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <Body1 fontWeight={400} fontSize={15} mb={3.5}>
                  {t("section-main.tab.priceCalculator.body")}222
                </Body1>
                <Stack flexDirection={"row"} alignItems={"flex-end"} justifyContent={"flex-start"} mb={8}>
                  <Body1 fontWeight={400} fontSize={15} mr={1}>
                    {t("section-main.tab.priceCalculator.calculatorVideo")}
                  </Body1>
                  <ModalComponent
                    body={
                      <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/0jDKon2Q_VE?autoplay=1"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                      ></iframe>
                    }
                    buttonText={t("section-main.tab.priceCalculator.calculatorVideoLink")}
                    buttonStyle={{
                      fontWeight: "bold",
                      background: "transparent",
                      fontSize: "15px",
                      color: appThemePrimaryColor,
                    }}
                    modalStyle={{ padding: 0, margin: 0 }}
                  />
                </Stack>
                <Heading5 mb={3} py={0}>
                  {t("section-main.tab.priceCalculator.investmentInformation.title")}
                </Heading5>
                <Box>
                  <Accordion expanded={expanded.includes("panel1")} onChange={handleChange("panel1")}>
                    <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                      <SpaceBetweenBox>
                        <Typography fontWeight={400}>
                          {t("section-main.tab.priceCalculator.investmentInformation.subtitle")}
                        </Typography>
                        <DataStatusView dataStatus={investmentInformationDataStatus(values.inputPrices)} />
                      </SpaceBetweenBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CalculatorInvestmentInformationForm
                        zevHasVat={!!contractViewState?.domainResult?.vatNumber}
                        zevPriceCalculatorInput={values.inputPrices}
                        dataStatusChanged={(zevPriceCalculatorInput) =>
                          setFieldValue("inputPrices", zevPriceCalculatorInput)
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded.includes("panel2")} onChange={handleChange("panel2")}>
                    <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                      <SpaceBetweenBox>
                        <Typography fontWeight={400}>
                          {t("section-main.tab.priceCalculator.operation.subtitle")}
                        </Typography>
                        <DataStatusView dataStatus={operationDataStatus(values.inputPrices)} />
                      </SpaceBetweenBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CalculatorOperationForm
                        zevHasVat={!!contractViewState?.domainResult?.vatNumber}
                        zevPriceCalculatorInput={values.inputPrices}
                        dataStatusChanged={(zevPriceCalculatorInput) =>
                          setFieldValue("inputPrices", zevPriceCalculatorInput)
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <Box mt={8} mb={2} py={0}>
                  <Heading5>{t("section-main.tab.priceCalculator.consumptionAndTariff.title")}</Heading5>
                </Box>
                <Box px={1} width={"40%"}>
                  <SingleLineDatePicker
                    required
                    name="validFrom"
                    label={t("section-main.tab.priceCalculator.consumptionAndTariff.yearlyCalculation")}
                    views={["year"]}
                    minDate={new Date(minYear, 0, 1)}
                    maxDate={new Date()}
                    value={new Date(selectedYear, 0, 1).getTime()}
                    onChange={handleYearChange}
                  />
                </Box>
                <Box>
                  <Accordion expanded={expanded.includes("panel3")} onChange={handleChange("panel3")}>
                    <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                      <SpaceBetweenBox>
                        <Typography fontWeight={400}>
                          {t("section-main.tab.priceCalculator.networkTariff.subtitle")}
                        </Typography>
                        <DataStatusView
                          dataStatus={DataStatus.DISPLAY_VALUE}
                          value={getTariff("purchasingPower", values.inputPrices, priceCalculatorTariffs)?.name}
                        />
                      </SpaceBetweenBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CalculatorTariffForm
                        zevHasVat={!!contractViewState?.domainResult?.vatNumber}
                        tariffType="purchasingPower"
                        tariffName={t("section-main.tab.priceCalculator.networkTariff.purchasedFromGrid")}
                        tariffInfo={t("section-main.tab.priceCalculator.networkTariff.purchasedFromGrid.info")}
                        zevPriceCalculatorInput={values.inputPrices}
                        tariffs={priceCalculatorTariffs}
                        dataStatusChanged={(zevPriceCalculatorInput) =>
                          setFieldValue("inputPrices", zevPriceCalculatorInput)
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded.includes("panel4")} onChange={handleChange("panel4")}>
                    <AccordionSummary aria-controls="panel4d-content" id="panel2d-header">
                      <SpaceBetweenBox>
                        <Typography fontWeight={400}>
                          {t("section-main.tab.priceCalculator.referenceTariff.subtitle")}
                        </Typography>
                        <DataStatusView
                          dataStatus={DataStatus.DISPLAY_VALUE}
                          value={getTariff("referencePower", values.inputPrices, priceCalculatorTariffs)?.name}
                        />
                      </SpaceBetweenBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <CalculatorTariffForm
                        zevHasVat={!!contractViewState?.domainResult?.vatNumber}
                        tariffType="referencePower"
                        tariffName={t("section-main.tab.priceCalculator.referenceTariff.zevParticipants")}
                        tariffInfo={t("section-main.tab.priceCalculator.referenceTariff.zevParticipants.info")}
                        zevPriceCalculatorInput={values.inputPrices}
                        tariffs={priceCalculatorTariffs}
                        dataStatusChanged={(zevPriceCalculatorInput) =>
                          setFieldValue("inputPrices", zevPriceCalculatorInput)
                        }
                      />
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded.includes("panel5")} onChange={handleChange("panel5")}>
                    <AccordionSummary aria-controls="panel5d-content" id="panel2d-header">
                      <SpaceBetweenBox>
                        <Typography fontWeight={400}>
                          {t("section-main.tab.priceCalculator.yearlyConsumption.subtitle")}
                        </Typography>
                        <DataStatusView dataStatus={yearlyConsumptionDataStatus(priceCalculatorEnergy)} />
                      </SpaceBetweenBox>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ManagerBlackAlert
                        label={t("section-main.tab.priceCalculator.yearlyConsumption.info", {
                          year: selectedYear - 1,
                        })}
                        buttonText={t("section-main.tab.priceCalculator.dataReset")}
                        tooltipText={t("section-main.tab.priceCalculator.reset.button.explanation")}
                        onClick={() => {
                          if (priceCalculatorEnergyViewState.domainResult)
                            setPriceCalculatorEnergy(priceCalculatorEnergyViewState.domainResult)
                        }}
                      />
                      <CalculatorYearlyConsumptionForm
                        energy={priceCalculatorEnergy}
                        dataStatusChanged={(priceCalculatorEnergy) => setPriceCalculatorEnergy(priceCalculatorEnergy)}
                      />
                    </AccordionDetails>
                  </Accordion>
                </Box>
                <SpaceBetweenBox>
                  <Grid item>{renderPriceCalculatorErrorMessage()}</Grid>
                  <Grid item>
                    <Box my={4}>
                      <PrimaryButton
                        id="app-dialog-cta"
                        label={t("section-main.tab.priceCalculator.calculatePrices.button")}
                        autoFocus
                        isLoading={updatePriceCalculatorInputViewState.isLoading}
                        disabled={
                          isMissingConsumptionData(priceCalculatorEnergy) ||
                          !interestIsHighEnough(values.inputPrices) ||
                          !hasInputPrices(values.inputPrices) ||
                          !priceCalculatorEnergy ||
                          updatePriceCalculatorInputViewState.isLoading ||
                          priceCalculatorPricesViewState.isLoading
                        }
                        type="submit"
                      />
                    </Box>
                  </Grid>
                </SpaceBetweenBox>
              </form>
            )}
          </Formik>
          <SpaceBetweenBox>
            <Grid item>{updatePrices.domainError && <ErrorAlert message={updatePrices.domainError.message} />}</Grid>
          </SpaceBetweenBox>
          <MyZevElectricityCalculatorFooterView
            priceCalculatorPrices={priceCalculation}
            updateZevPricePackageById={handleUpdateZevPricePackageById}
            zevHasVat={!!contractViewState?.domainResult?.vatNumber}
            updatePrices={updatePrices}
          />
        </MediumPaddedBox>
      </Paper>
    </>
  )
}

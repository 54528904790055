import { useEffect, useMemo, useState } from "react"
import { Box, CircularProgress, Paper, Typography } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { Field, Form, Formik, FormikProps } from "formik"
import {
  addVewaConfigByContractId,
  getVewaBuildingsMeterByContractId,
  getVewaCostSharesByContractId,
  removeVewaConfigByContractId,
  updateVewaConfigByContractId,
} from "../../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../../Shared.Utils"
import {
  BillingScope,
  GroupMeter,
  Medium,
  VewaConfigUpdateRequest,
  VewaCostShare,
} from "../../../../../../../data/generated-sources/openapi"
import {
  groupCostShares,
  mapCostSharesPayload,
  mapVewaCoolingGroupMeters,
  mapVewaHeatingGroupMeters,
  mapVewaWaterGroupMeters,
} from "../../../../../../../domain/portal/admin/mediu/MediumVewaCostShares.Mapperts"
import { FormUpdateActionsView } from "../../../../../../../uikit/form/FormUpdateActions"
import { PrimaryTab, PrimaryTabContainer } from "../../../../../../../uikit/tabs/PrimaryTab"
import { MiddleCircularProgress } from "../../../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert, SuccessAlert } from "../../../../../../../uikit/Shared.Alert"
import { VewaCostSharesDataUpdate } from "../../../../../shared/vewaCostShares/VewaCostSharesDataUpdate"
import { VewaCostSharesDataBox } from "../../../../../shared/vewaCostShares/VewaCostSharesDataBox"
import { VewaCostSharesSchema } from "../../../../../shared/vewaBillings/VewaBillingValidatioin.Schema"
import { PlusIcon, RemoveIcon } from "../../../../../../../uikit/Shared.Icon"
import { FabButton } from "../../../../../../../uikit/button/FabButton"
import { VewaHeatingGroupMeter } from "./vewaGroupMeters/VewaHeatingGroupMeter"
import { GroupMetersProps, SavedGroupMetersI } from "../../Medium.Interfaces"
import { VewaCoolingGroupMeter } from "./vewaGroupMeters/VewaCoolingGroupMeter"
import { VewaWaterGroupMeter } from "./vewaGroupMeters/VewaWaterGroupMeter"
import { ConfirmDialog } from "../../../../../../../uikit/confirmDialog/ConfirmDialog"

export const VewaCostSharesUpdate = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation("medium")
  const history = useHistory()
  const [selectedWaterAllocation, setSelectedWaterAllocation] = useState<Medium | null>(null)
  const [error, setError] = useState<AxiosErrorDataType>()
  const [success, setSuccess] = useState<boolean>(false)
  const [addRemoveTouched, setAddRemoveTouched] = useState(false)
  const [selectedTab, setSelectedTab] = useState<BillingScope>(BillingScope.HEATING_BILL)
  const [costShares, setCostShares] = useState<VewaCostShare[]>([])
  const [groupMeters, setGroupMeters] = useState<SavedGroupMetersI[]>([])
  const [heatMeters, setHeatMeters] = useState<GroupMetersProps[]>([])
  const [coolMeters, setCoolMeters] = useState<GroupMetersProps[]>([])
  const [waterMeters, setWaterMeters] = useState<GroupMetersProps[]>([])

  const {
    isFetching,
    remove: removeCostShares,
    refetch: refetchVewaConfigData,
  } = useQuery(["getCostShares"], () => getVewaCostSharesByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onError: setError,
    onSuccess(data) {
      const dataGroupMeters: SavedGroupMetersI[] = data?.groupMeters
        .map((meter: GroupMeter) => {
          return meter.meters.map((item) => {
            return {
              meter: item,
              consumptionScope: meter.costShare.consumptionScope,
            }
          })
        })
        .flat()
      setCostShares(data?.costShares)
      setGroupMeters(dataGroupMeters)
      refetchGroupMeters()
    },
  })

  const { remove: removeGroupMeters, refetch: refetchGroupMeters } = useQuery(
    ["getVewaBuildingGroupMeters"],
    () => getVewaBuildingsMeterByContractId(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
      onSuccess(data) {
        const meters = data.data.elements
        const heatMetersMapper = meters
          .filter((meter) => meter.medium === Medium.HEAT)
          .map((meter) => {
            const foundGroupMeter = groupMeters.find((groupMeter) => groupMeter.meter === meter.id)
            if (foundGroupMeter) {
              return {
                ...meter,
                linkedConsumptionScope: foundGroupMeter.consumptionScope,
              }
            }
            return meter
          })
        const coolMetersMapper = meters
          .filter((meter) => meter.medium === Medium.COLD)
          .map((meter) => {
            const foundGroupMeter = groupMeters.find((groupMeter) => groupMeter.meter === meter.id)
            if (foundGroupMeter) {
              return {
                ...meter,
                linkedConsumptionScope: foundGroupMeter.consumptionScope,
              }
            }
            return meter
          })
        const waterMetersMapper = meters
          .filter((meter) => meter.medium === Medium.WARM_WATER || meter.medium === Medium.COLD_WATER)
          .map((meter) => {
            const foundGroupMeter = groupMeters.find((groupMeter) => groupMeter.meter === meter.id)
            if (foundGroupMeter) {
              return {
                ...meter,
                linkedConsumptionScope: foundGroupMeter.consumptionScope,
              }
            }
            return meter
          })

        setHeatMeters(heatMetersMapper)
        setCoolMeters(coolMetersMapper)
        setWaterMeters(waterMetersMapper)
      },
    },
  )

  const groupedCostShares = useMemo(() => groupCostShares(costShares), [costShares])
  const consumptionScopeShares = useMemo(() => groupedCostShares.get(selectedTab), [selectedTab, costShares])

  const { mutate: updateVewaConfig, isLoading: updateVewaConfigIsLoading } = useMutation(
    ["updateVewaConfig"],
    (vewaConfig: VewaConfigUpdateRequest) => updateVewaConfigByContractId(contractId, vewaConfig),
    {
      onError: setError,
      onSuccess: () => setSuccess(true),
    },
  )

  const { mutate: removeBillingScope, isLoading: isRemoveBillingScope } = useMutation(
    ["removeBillingScope"],
    () => removeVewaConfigByContractId(contractId, selectedTab),
    {
      onSuccess: () => {
        setSuccess(true)
        refetchVewaConfigData()
      },
      onError: setError,
    },
  )

  const { mutate: addBillingScope, isLoading: isAddingBillingScope } = useMutation(
    ["addBillingScope"],
    () => addVewaConfigByContractId(contractId, { billingScope: selectedTab }),
    {
      onError: setError,
      onSuccess: () => {
        refetchVewaConfigData()
      },
    },
  )

  useEffect(() => {
    return () => {
      removeCostShares()
      removeGroupMeters()
    }
  }, [removeCostShares, removeGroupMeters])

  const renderBillingScopeTabs = () => {
    return Object.values(BillingScope).map((billingScope) => {
      return <PrimaryTab key={billingScope} value={billingScope} label={t(`label.${billingScope}`)}></PrimaryTab>
    })
  }

  const getGroupMeters = (): GroupMeter[] | [] => {
    switch (selectedTab) {
      case BillingScope.HEATING_BILL:
        return mapVewaHeatingGroupMeters(heatMeters)
      case BillingScope.COOLING_BILL:
        return mapVewaCoolingGroupMeters(coolMeters)
      case BillingScope.WATER_BILL:
        return mapVewaWaterGroupMeters(waterMeters)
      default:
        return []
    }
  }

  const getWaterAllocationMediums = () => {
    if (selectedTab !== BillingScope.WATER_BILL) return []
    if (selectedWaterAllocation === Medium.COLD_WATER) {
      return [Medium.COLD_WATER]
    }
    if (selectedWaterAllocation === Medium.WARM_WATER) {
      return [Medium.WARM_WATER]
    }
    return [Medium.WARM_WATER, Medium.COLD_WATER]
  }

  const handleSubmit = async (vewaCostShares: VewaCostShare[]) => {
    setError(undefined)
    const payload: VewaConfigUpdateRequest = {
      billingScope: selectedTab,
      vewaCostShares: {
        costShares: mapCostSharesPayload(vewaCostShares, selectedTab),
      },
      groupMeterConfig: {
        groupMeters: getGroupMeters(),
      },
      waterAllocationMediums: getWaterAllocationMediums(),
    }
    updateVewaConfig(payload)
  }

  const renderGroupMeters = () => {
    switch (selectedTab) {
      case BillingScope.HEATING_BILL:
        return (
          <VewaHeatingGroupMeter
            meters={heatMeters}
            setMeters={(meters) => {
              setHeatMeters(meters)
              setAddRemoveTouched(true)
            }}
          />
        )
      case BillingScope.COOLING_BILL:
        return (
          <VewaCoolingGroupMeter
            meters={coolMeters}
            setMeters={(meters) => {
              setCoolMeters(meters)
              setAddRemoveTouched(true)
            }}
          />
        )
      case BillingScope.WATER_BILL:
        return (
          <VewaWaterGroupMeter
            meters={waterMeters}
            setMeters={(meters) => {
              setWaterMeters(meters)
              setAddRemoveTouched(true)
            }}
            setSelectedWaterAllocation={(medium) => {
              setSelectedWaterAllocation(medium)
              setAddRemoveTouched(true)
            }}
            selectedWaterAllocation={selectedWaterAllocation}
          />
        )
      default:
        return null
    }
  }

  if (isFetching) return <MiddleCircularProgress height={300} />

  return (
    <Formik<VewaCostShare[]>
      validateOnChange
      enableReinitialize
      onSubmit={handleSubmit}
      initialValues={costShares}
      validationSchema={VewaCostSharesSchema}
    >
      {(props: FormikProps<VewaCostShare[]>) => {
        return (
          <Form>
            <Paper sx={{ mb: 2 }}>
              <ErrorAlert
                scrollOnDisplay
                visible={!!error}
                message={t(`error-codes:${error?.response?.data?.code ?? error?.code ?? "OTHER"}`)}
              />
              {success ? <SuccessAlert scrollOnDisplay message={t("message.successUpdateVewaConfig")} /> : null}
              <Typography fontSize={25} fontWeight={300} mb={2} textTransform={"uppercase"}>
                {t("costShares.title")}
              </Typography>
              <PrimaryTabContainer
                value={selectedTab}
                sx={{
                  paddingLeft: 0,
                  borderBottom: "2px solid #EEEEEE",
                }}
                onChange={(_, newValue) => setSelectedTab(newValue)}
              >
                {renderBillingScopeTabs()}
              </PrimaryTabContainer>
              {isFetching || isRemoveBillingScope || isAddingBillingScope ? (
                <CircularProgress sx={{ py: 6 }} />
              ) : (
                <>
                  {consumptionScopeShares ? (
                    <Box display={"flex"}>
                      <Box>
                        <VewaCostSharesDataBox billingScope={selectedTab} editModeInit={selectedTab}>
                          <Field type="hidden" name="addRemoveTouched" />
                          <VewaCostSharesDataUpdate
                            {...props}
                            hideSubmitBtn
                            vewaCostSharesMap={consumptionScopeShares}
                          />
                        </VewaCostSharesDataBox>
                        <VewaCostSharesDataBox
                          billingScope={selectedTab}
                          editModeInit={selectedTab}
                          groupMeters={selectedTab}
                        >
                          {renderGroupMeters()}
                        </VewaCostSharesDataBox>
                      </Box>
                      <Box px={1} py={4}>
                        <ConfirmDialog
                          actionButtonText={""}
                          actionButtonStartIcon={<RemoveIcon fontSize="large" />}
                          onConfirm={removeBillingScope}
                          dialogBodyText={t("confirmDialog.title")}
                          confirmButtonText={t("confirmDialog.button")}
                          actionButtonStyle={{
                            width: "35px",
                            height: "35px",
                            minWidth: 0,
                            padding: 0,
                            ".MuiButton-startIcon": {
                              margin: 0,
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box px={1} py={6}>
                      <FabButton size="medium" icon={<PlusIcon />} onClick={addBillingScope} />
                    </Box>
                  )}
                </>
              )}
            </Paper>
            <FormUpdateActionsView
              dirty={props.dirty || addRemoveTouched}
              isValid={props.isValid}
              buttonCtaLabel={t("shared:form.action.save")}
              isLoading={isFetching || updateVewaConfigIsLoading}
              navigateBack={() => history.push(`/management-contracts/${contractId}`)}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

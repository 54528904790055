import { IconButton, Stack, Typography } from "@mui/material"
import { ReactNode, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { BillingScope } from "../../../../data/generated-sources/openapi"
import { VewaCostSharesBox } from "./fragments/CostShareFragments"
import { CancelRoundIcon, EditIcon } from "../../../../uikit/Shared.Icon"
import { appThemeGrey800 } from "../../../Shared.Theme"

export interface VewaCostShareBoxProps {
  billingScope: BillingScope
  children: ReactNode
  editModeInit?: BillingScope
  showEditIcon?: boolean
  onEditClick?: (editScope: BillingScope | undefined) => void
  groupMeters?: string
}

export const VewaCostSharesDataBox = ({
  billingScope,
  children,
  editModeInit,
  showEditIcon,
  onEditClick,
  groupMeters,
}: VewaCostShareBoxProps) => {
  const { t } = useTranslation("medium")
  const isEditMode = editModeInit === billingScope

  const handleOnEditClick = (editScope: BillingScope | undefined) => {
    onEditClick && onEditClick(editScope)
  }

  const EditButton = useCallback(
    () => (
      <>
        {isEditMode ? (
          <IconButton onClick={() => handleOnEditClick(undefined)}>
            <CancelRoundIcon fontSize="small" color="primary" />
          </IconButton>
        ) : (
          <IconButton onClick={() => handleOnEditClick(billingScope)} sx={{ color: appThemeGrey800 }}>
            <EditIcon fontSize="small" color="inherit" />
          </IconButton>
        )}
      </>
    ),
    [isEditMode],
  )

  return (
    <VewaCostSharesBox key={billingScope} billingScope={billingScope} editMode={isEditMode}>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"} mb={2.625}>
        <Typography fontSize={18} fontWeight={500}>
          {t(`label.${groupMeters ? "groupMeters." : ""}${billingScope}`)}
        </Typography>
        {showEditIcon && <EditButton />}
      </Stack>
      {children}
    </VewaCostSharesBox>
  )
}

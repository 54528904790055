import React from "react"
import { useTranslation } from "react-i18next"
import { Box, Stack, Typography } from "@mui/material"

interface ElectricityCalculatorItemViewProps {
  icon: React.ReactElement
  value?: string
  label: string
  lumpSum?: boolean
}

export const ElectricityCalculatorItemView = (props: ElectricityCalculatorItemViewProps) => {
  const { t } = useTranslation("my-zev")
  const { icon, value, lumpSum, label } = props

  return (
    <Stack flexDirection={"row"} justifyItems={"flex-end"}>
      <Box
        sx={{
          width: 62,
          height: 48,
        }}
      >
        {icon}
      </Box>
      <Stack>
        <Stack
          flexDirection={"row"}
          alignItems={"center"}
          style={{
            width: 240,
          }}
        >
          <Typography fontWeight={500} fontSize={15} mb={1}>
            {value ?? "-"}
            {lumpSum
              ? t("section-main.tab.priceCalculator.calculatedValues.chfmt")
              : t("section-main.tab.priceCalculator.calculatedValues.rpkwh")}
          </Typography>
        </Stack>
        <Typography fontWeight={400} fontSize={12}>
          {label}
        </Typography>
      </Stack>
    </Stack>
  )
}

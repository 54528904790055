import { Typography, styled, Box, Grid, GridProps, CircularProgress } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import {
  customerGetBillingSettingsByContractId,
  customerGetContractById,
} from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import {
  BillingFrequency,
  BillingSettingsResponse,
  ProductType,
} from "../../../../../../data/generated-sources/openapi"
import BillingSettingsForm from "./BillingSettingsForm"
import BillingMainContactForm from "./BillingMainContactForm"
import BillingPaymentForm from "./BillingPaymentForm"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export const initialBillingResponse: BillingSettingsResponse = {
  nextBillingDate: "",
  billingFrequency: BillingFrequency.MONTHLY,
  billingPeriod: {
    startDate: "",
    endDate: "",
  },
  mainContactPerson: {
    name: "",
    contact: {},
  },
  paymentInformation: {
    payee: "",
    iban: "",
    paymentDeadlineInDays: 0,
  },
}

export const BillingSettingsRow = ({ children }: GridProps) => (
  <Grid item xs={12} md={6} pr={3} sx={{ width: "100%" }}>
    {children}
  </Grid>
)

export const BillingSettingsFormWrapper = styled(Grid, {
  shouldForwardProp: (prop) => prop !== "editMode",
})<{ editMode: boolean }>(({ theme, editMode }) => ({
  padding: theme.spacing(4),
  marginBottom: theme.spacing(editMode ? 3 : 0),
  marginLeft: theme.spacing(-4),
  marginRight: theme.spacing(-4),
  background: editMode ? theme.palette.background.editPrimary : "transparent",
  borderRadius: "0.5em",
}))

export const BillingSettingsFormTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  color: theme.palette.text.primary,
  fontSize: theme.typography.pxToRem(16),
}))

export const BillingSettingsLabel = styled(BillingSettingsFormTitle)(({ theme }) => ({
  fontWeight: 500,
  color: theme.palette.text.secondary,
}))

export const BillingSettingsValueLabel = styled(BillingSettingsFormTitle)(({ theme }) => ({
  fontWeight: 400,
  padding: theme.spacing(1, 0),
}))

export const BillingSettingsTab = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation("settings")
  const [error, setError] = useState<AxiosErrorDataType | null>(null)

  const {
    isLoading,
    isFetching,
    remove: removeContract,
    data: contractData,
  } = useQuery(["getContract", contractId], () => customerGetContractById(contractId), {
    enabled: !!contractId,
    onError: setError,
  })

  const {
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
    data: billingData,
    refetch: refetchBilling,
    remove: removeBilling,
  } = useQuery(["getBillingSetting", contractId], () => customerGetBillingSettingsByContractId(contractId), {
    enabled: !!contractId,
    onError: setError,
  })

  useEffect(() => {
    return () => {
      removeContract()
      removeBilling()
    }
  }, [removeContract, removeBilling])

  const onSuccessHandler = () => {
    refetchBilling()
    setError(null)
  }

  const contractProductType = contractData?.product?.productType ?? ProductType.ZEV
  const lastBillingDate = billingData?.billingPeriod.startDate || contractData?.startDate

  const billingResponse = { ...initialBillingResponse, ...billingData }

  return (
    <Box p={2} pb={4}>
      <BillingSettingsValueLabel sx={{ p: 4, pb: 3 }}>{t("billingPreferences.title")}</BillingSettingsValueLabel>
      <ErrorAlert
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      {(isLoading && isFetching) || (isLoadingBilling && isFetchingBilling) ? (
        <CircularProgress />
      ) : (
        <Grid container direction={"column"} px={4}>
          <BillingSettingsForm
            contractId={contractId}
            billingResponse={billingResponse}
            isZevProductType={contractProductType === ProductType.ZEV}
            vewaStartDate={lastBillingDate}
            onSuccessUpdate={onSuccessHandler}
            onErrorUpdate={setError}
          />
          <BillingMainContactForm
            formatContactFields
            contractId={contractId}
            billingResponse={billingResponse}
            onSuccessUpdate={onSuccessHandler}
            onErrorUpdate={setError}
          />
          <BillingPaymentForm
            contractId={contractId}
            billingResponse={billingResponse}
            onSuccessUpdate={onSuccessHandler}
            onErrorUpdate={setError}
          />
        </Grid>
      )}
    </Box>
  )
}

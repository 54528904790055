import { CircularProgress, Grid } from "@mui/material"

export const MiddleCircularProgress = (props: { height: number }) => {
  const { height } = props
  return (
    <Grid container justifyContent="center" alignContent="center" height={height}>
      <CircularProgress color="primary" size={48} />
    </Grid>
  )
}

export const Loader = (props: { height: number; justify: string; align: string; size: number }) => {
  const { height, justify, align, size } = props
  return (
    <Grid container justifyContent={justify} alignContent={align} height={height}>
      <CircularProgress color="primary" size={size} />
    </Grid>
  )
}

export const FullScreenLoader = () => {
  return (
    <>
      <div
        style={{
          width: "100vw",
          height: "100vh",
          position: "fixed",
          top: "0",
          left: "0",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: "rgba(250,250,250,0.8)",
          zIndex: "1500",
        }}
      >
        <CircularProgress color="primary" size={48} />
      </div>
    </>
  )
}

import { LegendConfigInterface, PieDataInterface } from "../Analise.Model"
import { Legend } from "recharts"
import { formatNumber } from "../../../../../domain/Domain.Formatters"
import { Box, Typography } from "@mui/material"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import { useTranslation } from "react-i18next"

interface LegendPropsInterface {
  config: LegendConfigInterface
  data: PieDataInterface[]
}

export const LegendWrapper = (props: LegendPropsInterface) => {
  const { t } = useTranslation()
  const { config, data } = props

  const formattedData = data.filter((item) => Number(item.value))
  return (
    <>
      <Subtitle1 mb={2}>{t("shared:chart.legend.subtitle")}</Subtitle1>
      <Box style={{ position: "relative" }}>
        <Legend
          verticalAlign="top"
          height={9}
          align="left"
          iconType="circle"
          iconSize={9}
          wrapperStyle={{ fontSize: 11 }}
          formatter={(value) => (
            <span style={{ color: "#000000", fontSize: 12, fontFamily: "Montserrat", position: "relative" }}>
              {value}
            </span>
          )}
          payload={formattedData.sort().map((entry: PieDataInterface) => {
            const meta = config[entry.name as keyof typeof config]
            return {
              id: meta?.title,
              value: meta?.title,
              type: "circle",
              color: meta?.color,
            }
          })}
        />
      </Box>
    </>
  )
}

export const LegendIconWrapper = (props: LegendPropsInterface) => {
  const { config, data } = props
  const total = data.reduce((sum, item) => sum + Number(item.value), 0)

  return (
    <>
      {data?.map((entry: PieDataInterface, index: number) => {
        const meta = config[entry.name as keyof typeof config]
        return (
          <Box key={`cell-info-${entry.name}`} display="flex" mt={index ? 3 : 0} sx={{ width: "150px" }}>
            {meta?.icon && <>{meta.icon}</>}
            <Box ml={1} mt={1}>
              <Typography fontFamily="Montserrat" fontSize={15} fontWeight={"500"}>{`${Math.round(
                (Number(entry?.value) / total) * 100,
              )}%`}</Typography>
              <Typography fontFamily="Montserrat" fontSize={11}>{`${formatNumber(
                Math.round(Number(entry.value) * 100) / 100,
              )} ${meta?.unit}`}</Typography>
            </Box>
          </Box>
        )
      })}
    </>
  )
}

import { BillingIcon, UsersIcon } from "../../../../../uikit/Shared.Icon"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import { getTranslateValue } from "../../../../Shared.Utils"
import { GetContractManagementBillingSettingsHook } from "../CustomGetHooks"
import { AxiosResponse } from "axios"
import React from "react"
import RedirectButton from "../../../../../uikit/button/RedirectButton"
import { BillingSettingsResponse } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"
import { FormSectionTitle } from "../../../../../uikit/form/FormView"

export const BILLING_SETTINGS_MASTER_DATA_CONFIG = {
  billingSettings: {
    name: getTranslateValue("contracts-management:billingForm.title"),
    useQueryHook: (paramId: string) => GetContractManagementBillingSettingsHook({ id: paramId }),
    renderingContent: [
      {
        firstColumn: {
          label: " ",
          getValue: () => <FormSectionTitle label={getTranslateValue("contracts-management:billingForm.title")} />,
        },
        secondColumn: {
          label: "",
          getComponent: () => <RedirectButton redirectUrl={`${location.pathname}/billing/update`} />,
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.nextBillingDate",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            DateUtils.getDeFormatDateWithMonthString(`${billingSettingsData?.billingPeriod?.endDate}`),
        },
        secondColumn: {
          label: "contracts-management:billingForm.period",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            `${DateUtils.getDeFormatDateWithMonthString(
              `${billingSettingsData?.billingPeriod?.startDate}`,
            )} - ${DateUtils.getDeFormatDateWithMonthString(`${billingSettingsData?.billingPeriod?.endDate}`)}`,
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.billingFrequency",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            getTranslateValue(`contracts-management:billingFrequency.${billingSettingsData?.billingFrequency}`),
        },
        secondColumn: {
          label: "contracts-management:billingForm.nextBillingFrequency",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            billingSettingsData?.nextBillingFrequency
              ? getTranslateValue(`contracts-management:billingFrequency.${billingSettingsData?.nextBillingFrequency}`)
              : "-",
        },
      },
      {
        firstColumn: {
          label: " ",
          getValue: () => (
            <Subtitle1>
              <div style={{ display: "flex" }}>
                <UsersIcon sx={{ marginRight: "10px" }} />
                {getTranslateValue("contracts-management:billingForm.contactPerson")}
              </div>
            </Subtitle1>
          ),
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.name",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            billingSettingsData?.mainContactPerson?.name,
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.phone",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string | undefined =>
            billingSettingsData?.mainContactPerson?.contact?.telephone,
        },
        secondColumn: {
          label: "contracts-management:billingForm.mobile",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string | undefined =>
            billingSettingsData?.mainContactPerson?.contact?.mobile,
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.email",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string | undefined =>
            billingSettingsData?.mainContactPerson?.contact?.email,
        },
      },
      {
        firstColumn: {
          label: " ",
          getValue: () => (
            <Subtitle1>
              <div style={{ display: "flex" }}>
                <BillingIcon sx={{ marginRight: "10px" }} />
                {getTranslateValue("contracts-management:billingForm.paymentInfo")}
              </div>
            </Subtitle1>
          ),
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.payee",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            billingSettingsData?.paymentInformation?.payee,
        },
        secondColumn: {
          label: "contracts-management:billingForm.iban",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string =>
            billingSettingsData?.paymentInformation?.iban,
        },
      },
      {
        firstColumn: {
          label: "contracts-management:billingForm.vatNumber",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): string | undefined =>
            billingSettingsData?.paymentInformation?.vatNumber,
        },
        secondColumn: {
          label: "contracts-management:contract.paymentDeadlineInDays",
          getValue: ({
            data: { data: billingSettingsData },
          }: AxiosResponse<AxiosResponse<BillingSettingsResponse>>): number =>
            billingSettingsData?.paymentInformation?.paymentDeadlineInDays,
        },
      },
    ],
  },
}

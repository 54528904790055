export const DashboardChartArrowShortIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={18} height={61}>
      <g fill="none" fillRule="evenodd">
        <path stroke="#A7B805" strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M9.5 2.5l-.302 53" />
        <path
          fill="#A7B805"
          d="M15.074 53.687l-5.355 5.355a1 1 0 01-1.414 0l-5.314-5.315a1 1 0 01.703-1.707l10.67-.04a1 1 0 01.71 1.706z"
        />
      </g>
    </svg>
  )
}

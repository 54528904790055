import { useCallback } from "react"
import { IconButton } from "@mui/material"
import { useHistory } from "react-router-dom"
import { GridRenderCellParams } from "@mui/x-data-grid"
import { ServiceBillingRunState } from "../../../../../../data/generated-sources/openapi"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { chfMoneyLabelComparator, DefaultCell } from "../../../../../../uikit/dataGridTable"
import { getTranslateValue } from "../../../../../Shared.Utils"
import { StatusView } from "../../../../../../uikit/label/StatusView"
import { AbortIcon, KeyboardArrowDownIcon, KeyboardArrowUpIcon, OpenIcon } from "../../../../../../uikit/Shared.Icon"
import { formatMoneyLabel } from "../../../../../../domain/Domain.Formatters"
import { IServiceBillings } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"

const ContractRedirectIcon = ({ value }: GridRenderCellParams) => {
  const history = useHistory()
  const goToContract = () => history.push(`/management-contracts/${value}`)

  return (
    <IconButton onClick={goToContract}>
      <OpenIcon color="primary" fontSize="small" />
    </IconButton>
  )
}

export const RecurringDetailsColumns = (
  expandedRowId: string,
  handleExpandRow: (rowId: string) => void,
  onRemoveBilling: (row: IServiceBillings) => void,
) => {
  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpandRow(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )

  const RemoveBillingCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      if (value.activeState !== ServiceBillingRunState.DRAFT) return <></>

      return (
        <IconButton onClick={() => onRemoveBilling(value)}>
          <AbortIcon color="secondary" />
        </IconButton>
      )
    },
    [onRemoveBilling],
  )

  const reccuringBillingColumns: Array<GridBaseColDef> = [
    {
      flex: 120,
      field: "activeState",
      headerName: getTranslateValue("shared:status.label"),
      valueGetter: (value: string) => getTranslateValue(`shared:status.${value}`),
      renderCell: ({ row }) => <StatusView statusType={row.activeState} />,
    },
    {
      flex: 150,
      field: "invoiceReferenceNumber",
      headerAlign: "left",
      headerName: getTranslateValue("billings-recurring:field.label.billingNumber"),
      renderCell: DefaultCell,
    },
    {
      flex: 180,
      field: "datePeriod",
      headerName: getTranslateValue("billings-recurring:field.label.period"),
      renderCell: DefaultCell,
    },
    {
      minWidth: 150,
      maxWidth: 250,
      field: "contractName",
      headerName: getTranslateValue("billings-recurring:field.label.contract"),
      renderCell: DefaultCell,
    },
    {
      flex: 10,
      headerName: "",
      field: "contractId",
      renderCell: ContractRedirectIcon,
    },
    {
      flex: 100,
      field: "totalAmountDue",
      headerName: getTranslateValue("billings-recurring:field.label.totalAmount"),
      renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={formatMoneyLabel(value)} />,
      sortComparator: chfMoneyLabelComparator,
    },
    {
      flex: 100,
      field: "accountingStatusTranslated",
      headerName: getTranslateValue("billings-recurring:field.label.billingStatus"),
      renderCell: DefaultCell,
    },
    {
      flex: 40,
      field: "removeBill",
      headerName: "",
      valueGetter: (_, row) => row,
      renderCell: RemoveBillingCell,
    },
    {
      field: "expand",
      flex: 40,
      headerName: "",
      sortable: false,
      valueGetter: (_, row) => row?.id,
      renderCell: ArrowCell,
    },
  ]

  return reccuringBillingColumns
}

import { Box, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { TariffScheduleResponse } from "../../../../../data/generated-sources/openapi"
import { TariffText } from "./TariffTypographies"
import { groupTariffsByTimeSlots } from "../../../../../services/utils/Tariffs.Utils"
import { GroupedPeriodResponse } from "../../../admin/tariffManagement/TariffModels"

const TariffPeriodText = ({
  days,
  startTime,
  endTime,
  tariffKind,
}: GroupedPeriodResponse & { tariffKind: "lowTariff" | "highTariff" }) => {
  const { t } = useTranslation("tariff")

  const startDay = t(`weekday.${days[0]}`)
  const endDay = t(`weekday.${days.at(-1)}`)
  const tariffTimeSlot = startTime === endTime ? t("shared:label.allDay") : `${startTime} - ${endTime} ${t("clock")}`

  return (
    <Stack flexDirection={"row"}>
      <Typography component={"span"} fontWeight={400} width={110}>
        {t(tariffKind)}
      </Typography>
      <TariffText key={endTime}>{` ${startDay} - ${endDay} ${tariffTimeSlot}`}</TariffText>
    </Stack>
  )
}

export const TariffTimePeriodText = (tariff: TariffScheduleResponse) => {
  if (!tariff) return <></>
  const groupedData = groupTariffsByTimeSlots(tariff)

  return (
    <Box>
      {groupedData?.lowTariff?.map((lowTariff, index) => (
        <TariffPeriodText key={index + lowTariff.startTime} tariffKind={"lowTariff"} {...lowTariff} />
      ))}
      {groupedData?.highTariff?.map((highTariff, index) => (
        <TariffPeriodText key={index + highTariff.startTime} tariffKind={"highTariff"} {...highTariff} />
      ))}
    </Box>
  )
}

import React from "react"
import { useTranslation } from "react-i18next"
import NumberFormat from "react-number-format"
import { Grid, Typography, Box, TextField } from "@mui/material"

import { InfoToolTipView } from "../../../../../uikit/tooltip/InfoTooltip"

interface IPriceCalculatorRowProps {
  label: string
  sublabel?: string | undefined
  childField: React.ReactNode
  info?: string
}

export const PriceCalculatorRow = (props: IPriceCalculatorRowProps) => {
  const { label, childField, info, sublabel } = props
  return (
    <Grid container justifyContent="space-between" alignItems="center" style={{ marginTop: 16 }}>
      <Grid item style={{ flex: 1 }}>
        <Typography variant="body1" fontWeight={400}>
          {label}
        </Typography>
        {sublabel && <Typography variant="caption">{sublabel}</Typography>}
      </Grid>
      <Grid item style={{ flex: 1 }}>
        <Grid container direction="row" alignItems="center">
          <Grid item style={{ flex: 1 }}>
            {childField}
          </Grid>
          <Grid item>
            <Box pl={2} pt={1}>
              {info ? (
                <InfoToolTipView body={info} />
              ) : (
                <span
                  style={{
                    display: "block",
                    width: 35,
                  }}
                ></span>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export type ChangeHandler = (values: { value: string }) => void
export interface ISimplePriceRow {
  label: string
  suffix: string
  value: string | number
  decimalScale: number
  change?: ChangeHandler
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}
export const PriceCalculatorNumberRow = (props: ISimplePriceRow) => {
  const { t } = useTranslation()
  const { value, suffix, label, change, decimalScale, ...rest } = props

  const getFormatedValue = () => {
    let numberValue
    if (typeof value === "string") {
      numberValue = parseFloat(value)
    } else {
      numberValue = value
    }
    const roundedValue = decimalScale ? numberValue.toFixed(decimalScale) : Math.round(numberValue)
    return roundedValue || 0
  }

  return (
    <PriceCalculatorRow
      label={t(label)}
      childField={
        <NumberFormat
          fullWidth
          allowEmptyFormatting
          fixedDecimalScale
          variant="standard"
          key={label}
          name={label}
          customInput={TextField}
          allowNegative={false}
          decimalScale={decimalScale}
          value={getFormatedValue()}
          suffix={suffix}
          thousandSeparator={"'"}
          onValueChange={change && change}
          style={{ fontFamily: "Montserrat,Arial,sans-serif,serif" }}
          {...rest}
        />
      }
    />
  )
}

import { useMutation, useQuery } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { Box } from "@mui/material"
import { RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { useAppDispatch } from "../../../hooks"

import DateUtils from "../../../../services/utils/DateUtils"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { setPageSubtitle } from "../../../appcontainer/AppContainer.Epic"
import {
  customerAllParticipantBillingDetailsById,
  customerApproveAllParticipantBilling,
  updateCustomerAllParticipantBillingPricesById,
} from "../../../../domain/portal/manager/billings/Billings.Repository"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { ZevBillingOverview } from "../../shared/zevBillings/ZevBillingOverview"
import { AllParticipantsBillingState, ZevPricePackage } from "../../../../data/generated-sources/openapi"

export const ZevAllBillingDetails = ({ match: { params }, history }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { billingId, contractId, areaId } = params
  const dispatch = useAppDispatch()
  const { t } = useTranslation("billings-participant")
  const [error, setError] = useState<AxiosErrorDataType>()

  const redirectToUtilityUnit = (utilityUnitId: string) => history.push(`/${areaId}/utility-units/${utilityUnitId}`)
  const redirectToFinalizeBill = () =>
    history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/finalize`)

  const {
    data: billing,
    isFetching: isFetchingBilling,
    remove: removeBill,
    refetch: refetchBill,
  } = useQuery(["getVewaBill"], () => customerAllParticipantBillingDetailsById(billingId, contractId), {
    enabled: !!billingId,
    onSuccess: (data) => {
      if ([AllParticipantsBillingState.DONE, AllParticipantsBillingState.PAID].includes(data.billingStatus)) {
        redirectToFinalizeBill()
      }
    },
    onError: setError,
  })

  const { mutate: approveAllBilling, isLoading: isApprovingBilling } = useMutation(
    ["approveAllBilling"],
    () => customerApproveAllParticipantBilling(billingId),
    {
      onSuccess: redirectToFinalizeBill,
      onError: setError,
    },
  )

  const { mutate: updatePricePackage, isLoading: isUpdatingPrices } = useMutation(
    ["updatePricePackage"],
    (zevPrices: ZevPricePackage) =>
      updateCustomerAllParticipantBillingPricesById(billingId, { pricePackage: zevPrices }),
    {
      onSuccess: () => refetchBill(),
      onError: setError,
    },
  )

  const billDateRange = `${DateUtils.getDeFormatDate(
    billing?.billingStartDate ?? "",
    "DD. MMM",
  )} - ${DateUtils.getDeFormatDate(billing?.billingEndDate ?? "", "DD. MMM YY")}`

  useEffect(() => {
    return () => {
      removeBill()
    }
  }, [removeBill])

  useEffect(() => {
    billing && dispatch(setPageSubtitle(`${t("shared:label.period")} ${billDateRange}`))
  }, [billing])

  return (
    <Box p={6}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <ZevBillingOverview
        billing={billing}
        onGoToUnitClick={redirectToUtilityUnit}
        onPriceUpdate={updatePricePackage}
        onClickApprove={approveAllBilling}
        isUpdatingPrices={isUpdatingPrices}
        isLoadingBilling={isFetchingBilling}
        isApprovingBilling={isApprovingBilling}
      />
    </Box>
  )
}

import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"

export const commentSchema = yup.object().shape({
  comment: yup
    .string()
    .max(499, () => {
      return getTranslateValue("shared:validation.max.length", {
        field: getTranslateValue("shared:label.comment"),
        length: "500",
      })
    })
    .trim()
    .optional(),
})

import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { apiHeaders } from "../../../Domain.Calls"
import DateUtils from "../../../../services/utils/DateUtils"
import { AreaStateInterface } from "../../../../app/portal/admin/area/Area.Interface"
import { ParticipantAdminUpdateRequest } from "../../../../data/generated-sources/openapi"

export const getAreaById = (id: string) => {
  return DOMAIN_DEPENDENCIES.adminAreaApi
    .adminGetAreaById(id, apiHeaders(DOMAIN_DEPENDENCIES))
    .then((res) => {
      return res.data
    })
    .catch((error) => {
      return error
    })
}

export const getAreaBuildings = async (areaId: string) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminGetAreaBuildings(
    areaId,
    undefined,
    undefined,
    undefined,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const deactivateAreaById = (id: string) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminDeactivateAreaById(id, DateUtils.getNow(), apiHeaders(DOMAIN_DEPENDENCIES))

export const deleteAreaById = (id: string) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminDeleteAreaById(id, apiHeaders(DOMAIN_DEPENDENCIES))

export const updateAreaById = (id: string, area: AreaStateInterface) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminUpdateAreaById(id, area, apiHeaders(DOMAIN_DEPENDENCIES))

export const createArea = (area: AreaStateInterface) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminCreateArea(area, apiHeaders(DOMAIN_DEPENDENCIES))

export const getAreaParticipants = async (areaId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.adminAreaApi.adminGetAreaParticipants(
    areaId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const createAreaParticipant = async (areaId: string, payload: ParticipantAdminUpdateRequest) =>
  DOMAIN_DEPENDENCIES.adminAreaApi.adminCreateAreaParticipant(areaId, payload, apiHeaders(DOMAIN_DEPENDENCIES))

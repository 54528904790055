import { VewaCostShare } from "../../../../../data/generated-sources/openapi"
import { Slider, SliderThumb, styled } from "@mui/material"
import { CostShareSliderThumbIcon } from "../../../../../uikit/Shared.Icon"
import { useCallback, useEffect, useState } from "react"

const CostShareSlider = styled(Slider)(({ theme }) => ({
  color: theme.palette.secondary.main,
  height: 8,
  marginBottom: theme.spacing(5),
  "& .MuiSlider-track": {
    background: theme.palette.secondary.lighter,
  },
  "& .MuiSlider-mark": {
    backgroundColor: "transparent",
  },

  "& .MuiSlider-thumb": {
    top: 1,
    height: 18,
    width: 18,
    backgroundColor: "rgb(200 230 164)",
    "&:active, &:hover": {
      boxShadow: "0px 0px 0px 12px rgb(200 230 164)",
    },
  },
}))

const sliderMarks = [
  { value: 0, label: "|" },
  { value: 10, label: "|" },
  { value: 20, label: "|" },
  { value: 30, label: "|" },
  { value: 40, label: "|" },
  { value: 50, label: "|" },
  { value: 60, label: "|" },
  { value: 70, label: "|" },
  { value: 80, label: "|" },
  { value: 90, label: "|" },
  { value: 100, label: "|" },
]

interface CostStructureUpdateProps {
  values: VewaCostShare[]
  costShareIndex: number
  onSliderChange: (value: number) => void
}

export const EditCostStructure = ({ values, costShareIndex, onSliderChange }: CostStructureUpdateProps) => {
  const [sliderValue, setSliderValue] = useState<number>(0)

  useEffect(() => {
    setSliderValue(values[costShareIndex]?.costStructure.basicCosts || 0)
  }, [sliderValue])

  const handleChange = useCallback(
    (newValue: number) => {
      setSliderValue(newValue)
      onSliderChange(newValue)
    },
    [onSliderChange],
  )

  const CostShareSliderTumb = useCallback(
    (props) => (
      <SliderThumb {...props} type="range">
        <CostShareSliderThumbIcon />
      </SliderThumb>
    ),
    [],
  )

  return (
    <CostShareSlider
      min={0}
      max={100}
      size="small"
      color="primary"
      track={"inverted"}
      defaultValue={50}
      valueLabelDisplay="off"
      aria-label="costShare"
      value={sliderValue}
      onChange={(_, value) => handleChange(value as number)}
      marks={sliderMarks}
      slots={{ thumb: CostShareSliderTumb }}
    />
  )
}

import { useContext, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter } from "react-router"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { useMutation } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { MasterDataContext } from "../../masterData/MasterData.Component"

import { AxiosErrorDataType, CommonFormikProps } from "../../../../Shared.Utils"
import { IReplaceContract, ReplaceContract, replaceContractSchema } from "./ReplaceContract"
import { ActivateIcon, EditIcon, DeactivateIcon, RemoveIcon } from "../../../../../uikit/Shared.Icon"
import { ContractAdminResponse, ContractState } from "../../../../../data/generated-sources/openapi"
import { ConfirmDialog } from "../../../../../uikit/confirmDialog/ConfirmDialog"
import {
  deleteContractById,
  approveContractById,
  replaceContractById,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { QueryObserverResult, RefetchOptions, RefetchQueryFilters } from "@tanstack/query-core/src/types"
import { PrimaryButton } from "../../../../../uikit/button/PrimaryButton"

enum DialogType {
  Form = "Form",
  Confirm = "Confirm",
}

interface MasterButton {
  buttonTitle: string
  buttonIcon: React.ReactNode
  handleConfirm: () => void
  disabled: boolean
  dialogType: DialogType
  isLoading: boolean
}
interface MasterButtonForm extends MasterButton {
  initialValues: IReplaceContract
  handleFormConfirm: (props: IReplaceContract) => void
  dialogBody: (props: CommonFormikProps<IReplaceContract>) => React.ReactNode
}

interface IContractMasterDataButtons extends RouteComponentProps<AdminPortalRouteParams>, ContractAdminResponse {
  refetch: (
    filters?: RefetchQueryFilters | undefined,
    options?: RefetchOptions | undefined,
  ) => Promise<QueryObserverResult<ContractAdminResponse, undefined>>
}

export const ContractMasterDataButtons = withRouter(
  ({ activeState, endDate, productId, id, history, refetch }: IContractMasterDataButtons) => {
    const { t } = useTranslation("contracts-management")
    const { setError, setComponentName, setSuccessMessage } = useContext(MasterDataContext)
    const [updateError, setUpdateError] = useState<AxiosErrorDataType>()

    const handleSuccessUpdate = async () => {
      setComponentName("contractMasterData")
      setSuccessMessage(t("contract.update.success"))
      await refetch()
    }

    const handleErrorUpdate = (error: AxiosErrorDataType) => {
      setError(error)
      setComponentName("contractMasterData")
    }

    const { mutate: releaseContract, isLoading: isReleasingContract } = useMutation(
      ["releaseContract"],
      () => approveContractById(id),
      {
        onSuccess: handleSuccessUpdate,
        onError: handleErrorUpdate,
      },
    )

    const {
      mutate: replaceContract,
      isLoading: isReplacingContract,
      isSuccess: replaceContractSuccess,
    } = useMutation(["replaceContract"], (contractData: IReplaceContract) => replaceContractById(id, contractData), {
      onSuccess: handleSuccessUpdate,
      onError: (error: AxiosErrorDataType) => {
        setUpdateError(error)
        handleErrorUpdate(error)
      },
    })

    const { mutate: deleteContract, isLoading: isDeletingContract } = useMutation(
      ["deleteContract"],
      () => deleteContractById(id),
      {
        onSuccess: handleSuccessUpdate,
        onError: handleErrorUpdate,
      },
    )

    const ActionButtonsConfig: Array<MasterButtonForm | MasterButton> = [
      {
        buttonTitle: "actionButton.replace",
        buttonIcon: <EditIcon fontSize="large" />,
        handleConfirm: () => null,
        handleFormConfirm: (props) => replaceContract(props),
        disabled: activeState !== ContractState.ACTIVE,
        dialogType: DialogType.Form,
        initialValues: {
          contractId: id,
          productId: productId || "",
          endDate: endDate ?? "",
        },
        dialogBody: (props) => (
          <ReplaceContract {...props} updateError={updateError} showSuccessMessage={replaceContractSuccess} />
        ),
        isLoading: isReplacingContract,
      },
      {
        buttonTitle: "actionButton.release",
        buttonIcon: <ActivateIcon fontSize="large" />,
        handleConfirm: () => {
          releaseContract()
        },
        disabled: activeState !== ContractState.DRAFT,
        dialogType: DialogType.Confirm,
        isLoading: isReleasingContract,
      },
      {
        buttonTitle: "actionButton.disable",
        buttonIcon: <DeactivateIcon fontSize="large" />,
        handleConfirm: () => {
          history.push(`/management-contracts/${id}/TODO`)
        },
        dialogType: DialogType.Confirm,
        disabled: true,
        isLoading: false,
      },
      {
        buttonTitle: "actionButton.delete",
        buttonIcon: <RemoveIcon fontSize="large" />,
        handleConfirm: () => deleteContract(),
        disabled: activeState !== ContractState.DRAFT,
        dialogType: DialogType.Confirm,
        isLoading: isDeletingContract,
      },
    ]

    const ContractActionButton = ({
      buttonTitle,
      buttonIcon,
      dialogType,
      disabled,
      handleConfirm,
      isLoading,
      ...props
    }: MasterButtonForm | MasterButton) => {
      const { initialValues, dialogBody, handleFormConfirm } = props as MasterButtonForm

      return dialogType === DialogType.Confirm ? (
        <ConfirmDialog
          key={buttonTitle}
          actionButtonText={t(buttonTitle)}
          actionButtonStartIcon={buttonIcon}
          actionButtonLoading={isLoading}
          actionButtonDisabled={disabled}
          dialogTitle={t(buttonTitle)}
          onConfirm={handleConfirm}
        />
      ) : (
        initialValues && dialogBody && (
          <Formik<typeof initialValues>
            initialValues={initialValues}
            validationSchema={replaceContractSchema}
            onSubmit={handleConfirm}
          >
            {(props) => (
              <Form>
                <ConfirmDialog
                  key={buttonTitle}
                  actionButtonText={t(buttonTitle)}
                  actionButtonStartIcon={buttonIcon}
                  actionButtonLoading={isLoading}
                  confirmButtonLoading={isLoading}
                  actionButtonDisabled={disabled}
                  dialogTitle={t(buttonTitle)}
                  dialogBody={dialogBody(props)}
                  onConfirm={() => handleFormConfirm(props.values)}
                  confirmButtonDisabled={!props.isValid || !props.dirty}
                  keepOpenAfterConfirm
                />
              </Form>
            )}
          </Formik>
        )
      )
    }

    return (
      <>
        {ActionButtonsConfig.map(ContractActionButton)}
        <PrimaryButton
          sx={{ mb: 2 }}
          label={t("actionButton.activate")}
          startIcon={<ActivateIcon fontSize="large" />}
          onClick={() => history.push(`/management-contracts/${id}/meters/activate`)}
          disabled={[ContractState.DRAFT, ContractState.TERMINATED].includes(activeState)}
        />
      </>
    )
  },
)

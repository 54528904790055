import { useState } from "react"
import { ReloadDataButton } from "../button/ReloadDataButton"
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { DialogCloseIcon } from "../icons/Icons"
import { PrimaryButton } from "../button"
import { useTranslation } from "react-i18next"

interface ConfirmReloadBillingDialogProps {
  isLoading: boolean
  onConfirmClick: () => void
}

export const ConfirmReloadBillingDialog = ({ onConfirmClick, isLoading }: ConfirmReloadBillingDialogProps) => {
  const { t } = useTranslation("shared")
  const [showDialog, setShowDialog] = useState(false)
  const onOpenDialog = () => setShowDialog(true)
  const onCloseDialog = () => setShowDialog(false)

  const onConfirm = () => {
    onCloseDialog()
    onConfirmClick()
  }

  return (
    <>
      <ReloadDataButton onClick={onOpenDialog} loading={isLoading} />
      <Dialog open={showDialog} onClose={onCloseDialog} fullWidth maxWidth="sm">
        <DialogCloseIcon onClick={onCloseDialog} />
        <DialogTitle>{t("label.reloadData")}</DialogTitle>
        <DialogContent>{t("reloadData.dialogContent")}</DialogContent>
        <DialogActions>
          <PrimaryButton label={t("dialog.confirm.cta")} onClick={onConfirm} />
          <Button variant="outlined" onClick={onCloseDialog}>
            {t("shared:form.action.cancel")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import TableCell from "@mui/material/TableCell"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { MeterResponse } from "../../../../../data/generated-sources/openapi"

interface MetersTableInterface {
  itemConfig: MeterResponse
}
const MeterTableComponent = (props: MetersTableInterface) => {
  const history = useHistory()
  const { t } = useTranslation("meters")
  const { itemConfig } = props
  return (
    <TableRowClickable<MeterResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(metersItem) => {
        if (metersItem.id) {
          history.push(`meters/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.meteringCode}</TableCell>
      <TableCell align="left">{itemConfig.meterAttachmentName}</TableCell>
      <TableCell align="left">{t(`label.${itemConfig.medium}`)}</TableCell>
    </TableRowClickable>
  )
}

export default MeterTableComponent

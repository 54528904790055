import { Fragment, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Paper, TableCell, TableContainer } from "@mui/material"
import { ProfileListItem } from "../../../../domain/portal/admin/profiles/Profiles.Model"
import { ProfileStatusTypeView } from "./form/ProfileStatusType"
import { TableFixed } from "../../../../uikit/table/Table.Fixed"
import { TableRowClickable, TableRowView } from "../../../../uikit/table/Table.RowView"
import { PageRowSlice, TablePaginationView } from "../../../../uikit/table/Table.PaginationView"
import { TableColumnSort, TableHeaderView } from "../../../../uikit/table/Table.HeaderView"
import { ORDERED_STRING_COMPARATOR } from "../../../../domain/Domain.Comparators"
import { firstViewState } from "../../../Shared.Reducer"
import { ProfilesListState } from "./ProfilesList.Reducer"
import { SuccessAlert, TableRowErrorAlert } from "../../../../uikit/Shared.Alert"
import { mapDispatchToProps } from "./ProfilesList.Connect"
import { SharedListHeader } from "../sharedListComponet/SharedListHeader.Component"

interface ProfilesListComponentProps extends ProfilesListState, ReturnType<typeof mapDispatchToProps> {}

enum ProfilesListColumns {
  STATUS = "STATUS",
  NAME = "NAME",
  EMAIL = "EMAIL",
  TYPE = "TYPE",
}

export const ProfilesListComponent = (props: ProfilesListComponentProps) => {
  const { t } = useTranslation("profiles")
  const { viewState, getProfiles, navigateToProfileDetail, navigateToCreateProfile, showDeleteAlert } = props

  useEffect(() => {
    if (firstViewState(viewState)) {
      getProfiles()
    }
  }, [viewState, getProfiles])

  const profileList = useMemo(
    () =>
      viewState.domainResult?.profiles?.map((profile) => ({
        ...profile,
        type: t(`userType.${profile.type}`),
      })) ?? [],
    [viewState, t],
  )

  const [orderBy, setOrderBy] = useState<TableColumnSort<ProfilesListColumns>>({
    column: ProfilesListColumns.NAME,
    direction: "asc",
  })

  const [pageRowSlice, setPageRowSlice] = useState<PageRowSlice>({
    start: 0,
    end: 25,
  })

  const [filterQuery, setFilterQuery] = useState<string>("")

  const headers = [
    {
      column: ProfilesListColumns.STATUS,
      label: t("list.label.status"),
      width: "10%",
      orderable: true,
    },
    {
      column: ProfilesListColumns.NAME,
      label: t("list.label.profileName"),
      width: "25%",
      orderable: true,
    },
    {
      column: ProfilesListColumns.EMAIL,
      label: t("list.label.userName"),
      width: "45%",
      orderable: true,
    },
    {
      column: ProfilesListColumns.TYPE,
      label: t("list.label.type"),
      width: "15%",
      orderable: true,
    },
  ]

  const columnComparator = () => {
    switch (orderBy.column) {
      case ProfilesListColumns.STATUS:
        return (a: ProfileListItem, b: ProfileListItem) =>
          ORDERED_STRING_COMPARATOR(a.sortableStatusType, b.sortableStatusType, orderBy.direction)
      case ProfilesListColumns.NAME:
        return (a: ProfileListItem, b: ProfileListItem) =>
          ORDERED_STRING_COMPARATOR(a.profileName, b.profileName, orderBy.direction)
      case ProfilesListColumns.EMAIL:
        return (a: ProfileListItem, b: ProfileListItem) =>
          ORDERED_STRING_COMPARATOR(a.userName, b.userName, orderBy.direction)
      default:
        return (a: ProfileListItem, b: ProfileListItem) => ORDERED_STRING_COMPARATOR(a.type, b.type, orderBy.direction)
    }
  }

  return (
    <>
      {showDeleteAlert && <SuccessAlert message={t("list.alert.delete.success")} />}
      <Paper>
        <SharedListHeader
          filterQuery={filterQuery}
          setFilterQuery={setFilterQuery}
          header={{
            title: t("list.title"),
            showButton: true,
            showFilter: true,
          }}
          onClick={navigateToCreateProfile}
        />
        <TableContainer>
          <TableFixed>
            <TableHeaderView<ProfilesListColumns>
              isLoading={viewState.isLoading}
              headers={headers}
              orderBy={orderBy}
              orderByChanged={(orderBy) => setOrderBy(orderBy)}
            />
            {viewState.domainError && (
              <TableRowErrorAlert colSpan={5} retry={() => getProfiles()} message={viewState.domainError.message} />
            )}
            <TableRowView<ProfileListItem>
              id="list-table"
              colSpan={5}
              rows={profileList}
              pageRowSlice={pageRowSlice}
              comparator={columnComparator}
              filterQuery={filterQuery}
              render={(profileListItem) => {
                return (
                  <Fragment key={profileListItem.id}>
                    <TableRowClickable
                      rowData={profileListItem}
                      rowClick={(profile) => navigateToProfileDetail(profile.id)}
                    >
                      <TableCell className={`row-${ProfilesListColumns.STATUS}`} align="left">
                        <ProfileStatusTypeView statusType={profileListItem.profileStatusType} />
                      </TableCell>
                      <TableCell className={`row-${ProfilesListColumns.NAME}`} align="left">
                        {profileListItem.profileName}
                      </TableCell>
                      <TableCell className={`row-${ProfilesListColumns.EMAIL}`} align="left">
                        {profileListItem.userName}
                      </TableCell>
                      <TableCell className={`row-${ProfilesListColumns.TYPE}`} align="left">
                        {profileListItem.type}
                      </TableCell>
                    </TableRowClickable>
                  </Fragment>
                )
              }}
            />
          </TableFixed>
          <TablePaginationView rowCount={profileList.length} onPageRowSliceChanged={setPageRowSlice} />
        </TableContainer>
      </Paper>
    </>
  )
}

import { BillNamePropsInterface } from "./UtilityUnitInterfaces"
import { Badge } from "@mui/material"

const BillNumber = ({ name, isNew }: BillNamePropsInterface) => {
  return (
    <>
      {isNew && <Badge badgeContent={""} color="warning" sx={{ mx: 2 }} />}
      {name}
    </>
  )
}

export default BillNumber

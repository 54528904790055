/* eslint-disable max-len */
export const MeterReadingLowTarrifIcon = () => {
  return (
    <svg width="2em" height="2em" viewBox="0 0 34 36" xmlns="http://www.w3.org/2000/svg">
      <g fillRule="evenodd">
        <path
          fill="#83d0f5"
          d="M16.077 8l-1.214 3.877-8.396 4.425v1.507h.86v2.586h1.509V17.81h4.168l-1.075 3.437-8.93 4.425v1.508h1.294v2.585H5.8V27.18h4.269L7.308 36H9.16L17 30.673 24.842 36h1.85l-2.762-8.82h4.27v2.584h1.508V27.18H31v-1.508l-8.927-4.425-1.077-3.437h3.739v2.586h1.507V17.81h1.292v-1.507l-8.396-4.427L17.924 8h-1.847zM17 10.378l.459 1.497h-.917L17 10.378zm-.92 3.007h1.84l.893 2.917h-3.626l.894-2.917zm3.624.298l4.97 2.62h-4.15l-.82-2.62zm-5.406 0l-.821 2.62h-4.15l4.97-2.62zm.428 4.126h4.549l1.052 3.437h-6.652l1.05-3.437zm-1.513 4.945h7.575l.893 2.917H12.32l.893-2.917zm9.419.277l5.327 2.64h-4.5l-.827-2.64zm-11.262 0l-.827 2.64H6.042l5.328-2.64zm6.972 6.73l3.8-2.582 1.995 6.52-5.795-3.939zm-3.8-2.582h4.918L17 28.85l-2.458-1.67zm-2.683 0l3.8 2.581-5.796 3.938 1.996-6.519zM24.698 6.34a5 5 0 015-5c.254 0 .664.037.914.084.215.04.262.326.073.434a3.917 3.917 0 002.671 7.253.234.234 0 01.226.377 5 5 0 01-8.884-3.148"
        />
      </g>
    </svg>
  )
}

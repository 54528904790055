/* eslint-disable max-len */
export const MeterReadingUsedIcon = () => {
  return (
    <svg width="2em" height="2em" viewBox="0 0 34 36" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        fill="#bccf02"
        d="M28.556 34.438V19.854l-8.342-7.206c-.143-.114-.285-.114-.428 0l-8.342 7.206v14.584h5.134v-7.55c0-.19.062-.352.187-.486a.6.6 0 01.454-.2h5.561a.6.6 0 01.455.2.686.686 0 01.187.486v7.55h5.134zm5.335-12.556v.056c.073.037.109.102.109.195a.554.554 0 01-.055.251l-.71.893c-.037.074-.101.112-.192.112s-.173-.038-.246-.112L30.5 21.435v12.78c0 .483-.173.902-.52 1.255-.346.354-.756.53-1.23.53h-6.344a.628.628 0 01-.465-.195.653.653 0 01-.19-.474v-7.366h-3.5v7.366a.654.654 0 01-.192.474.628.628 0 01-.465.195H11.25c-.474 0-.884-.176-1.23-.53a1.734 1.734 0 01-.52-1.255v-12.78l-2.296 1.842c-.074.074-.156.102-.247.084a.26.26 0 01-.191-.14l-.711-.837a.553.553 0 01-.055-.25c0-.094.036-.178.11-.252l12.795-10.491A1.721 1.721 0 0120 11c.401 0 .766.13 1.094.39l7.656 6.306v-2.79c0-.111.036-.195.11-.25a.349.349 0 01.218-.084h1.094c.073 0 .146.027.219.083.072.056.109.14.109.251v4.242l3.39 2.734z"
      />
    </svg>
  )
}

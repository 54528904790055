import ConsumptionOverviewBox from "../analiyse/charts/ConsumptionOverviewBox"
import { Box, Typography } from "@mui/material"
import { LegendWrapper } from "../analiyse/components/Legend"
import { MeterReadingSolarPowerIcon } from "../analiyse/icons/MeterReadingSolarPowerIcon"
import { MeterReadingHighTarrifIcon } from "../analiyse/icons/MeterReadingHighTarrifIcon"
import { MeterReadingLowTarrifIcon } from "../analiyse/icons/MeterReadingLowTarrifIcon"
import { useTranslation } from "react-i18next"
import { mediumBlueLightColor, mediumPurpleColor, mediumPurpleMidColor, mediumRedColor } from "../../../Shared.Theme"
import { vewaTotalConsumptionDataMapper } from "../analiyse/charts/ConsumptionOverviewMapper"
import { VewaTotalConsumption } from "../../../../domain/portal/manager/dashboard/Dashboard.Model"

export const VewaBillingConsumptionOverview = (props: VewaTotalConsumption) => {
  const { t } = useTranslation()
  const consumptionData = vewaTotalConsumptionDataMapper(props)

  const mapLegendConfig = {
    totalHeatConsumption: {
      id: "totalHeatConsumption",
      color: mediumRedColor,
      title: t("analise:legend.heat"),
      unit: "kWh",
      icon: MeterReadingSolarPowerIcon(),
    },
    totalColdConsumption: {
      id: "totalColdConsumption",
      color: mediumBlueLightColor,
      title: t("analise:legend.cooling"),
      unit: "kWh",
      icon: MeterReadingHighTarrifIcon(),
    },
    totalWarmWaterConsumption: {
      id: "totalWarmWaterConsumption",
      color: mediumPurpleColor,
      title: t("analise:legend.heatWater"),
      unit: "m3",
      icon: MeterReadingLowTarrifIcon(),
    },
    totalColdWaterConsumption: {
      id: "totalColdWaterConsumption",
      color: mediumPurpleMidColor,
      title: t("analise:legend.coldWater"),
      unit: "m3",
      icon: MeterReadingLowTarrifIcon(),
    },
  }

  if (!consumptionData.length) return null

  return (
    <>
      <Typography mb={4} fontSize={24} fontWeight={400}>
        {t("billings-participant:detail.all.consumption-overview")}
      </Typography>
      <Box mb={10}>
        <LegendWrapper data={consumptionData} config={mapLegendConfig} />
      </Box>
      <ConsumptionOverviewBox data={consumptionData} containerWidth={"100%"} boxSize={"160px"} />
    </>
  )
}

import { useTranslation } from "react-i18next"
import { Grid, Stack, IconButton } from "@mui/material"

import { CancelRoundIcon, EditIcon } from "../../../../../../uikit/Shared.Icon"
import { BillingFrequency, BillingSettingsResponse } from "../../../../../../data/generated-sources/openapi"

import { SingleLineTextField } from "../../../../../../uikit/input/SingleLineTextField"
import { Form, Formik } from "formik"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { customerUpdateContractMainContactPersonById } from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { PrimaryButton } from "../../../../../../uikit/button"

import {
  BillingSettingsFormWrapper,
  BillingSettingsFormTitle,
  BillingSettingsRow,
  BillingSettingsValueLabel,
  BillingSettingsLabel,
} from "./BillingSettingsTab"
import { mainContactSchema } from "./BillingPreferencesSchema.Validation"
import { formatEmail, formatPhone } from "../../../../../../uikit/Shared.Formatters"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface BillingMainContactFormProps {
  contractId: string
  billingResponse: BillingSettingsResponse
  onSuccessUpdate: () => void
  onErrorUpdate: (error: AxiosErrorDataType) => void
  formatContactFields?: boolean
}

const BillingMainContactForm = ({
  contractId,
  billingResponse,
  onSuccessUpdate,
  onErrorUpdate,
  formatContactFields,
}: BillingMainContactFormProps) => {
  const { t } = useTranslation("settings")
  const [editMode, setEditMode] = useState(false)

  const { mutate: updateSettings, isLoading } = useMutation(
    ["updateSettings"],
    ({ mainContactPerson }: BillingSettingsResponse) =>
      customerUpdateContractMainContactPersonById(contractId, { mainContactPerson }),
    {
      onError: onErrorUpdate,
      onSuccess: () => {
        setEditMode(false)
        onSuccessUpdate()
      },
    },
  )

  const handleSubmit = (billingData: BillingSettingsResponse) => {
    updateSettings(billingData)
  }

  return (
    <Formik<BillingSettingsResponse & { newNextBillingFrequency?: BillingFrequency }>
      initialValues={billingResponse}
      onSubmit={handleSubmit}
      enableReinitialize
      validateOnBlur
      validationSchema={mainContactSchema}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => {
        const touchedFileds = Object.keys(touched)
        return (
          <Form>
            <BillingSettingsFormWrapper container item rowGap={3} editMode={editMode}>
              <Grid item xs={12}>
                <Stack flexDirection={"row"} justifyContent={"flex-start"} alignItems={"center"} mb={2}>
                  <BillingSettingsFormTitle>{t("billingPreferences.mainContact")}</BillingSettingsFormTitle>
                  <IconButton
                    onClick={() => {
                      resetForm()
                      setEditMode((prevState) => !prevState)
                    }}
                    color="primary"
                  >
                    {editMode ? <CancelRoundIcon fontSize="small" /> : <EditIcon fontSize="small" />}
                  </IconButton>
                </Stack>
              </Grid>
              {editMode ? (
                <>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      required
                      name="name"
                      type="text"
                      label={t("contracts-management:billingForm.name")}
                      value={values.mainContactPerson?.name}
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("mainContactPerson.name", target.value)}
                      helperText={
                        touchedFileds.includes("name") && errors.mainContactPerson?.name
                          ? errors.mainContactPerson?.name
                          : undefined
                      }
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      name="email"
                      type="text"
                      label={t("contracts-management:billingForm.email")}
                      value={values.mainContactPerson?.contact?.email}
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("mainContactPerson.contact.email", target.value)}
                      helperText={
                        touchedFileds.includes("email") && errors.mainContactPerson?.contact?.email
                          ? errors.mainContactPerson?.contact?.email
                          : undefined
                      }
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      name="telephone"
                      type="text"
                      label={t("contracts-management:billingForm.phone")}
                      value={values.mainContactPerson?.contact?.telephone}
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("mainContactPerson.contact.telephone", target.value)}
                      helperText={
                        touchedFileds.includes("telephone") && errors.mainContactPerson?.contact?.telephone
                          ? errors.mainContactPerson?.contact?.telephone
                          : undefined
                      }
                    />
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <SingleLineTextField
                      name="mobile"
                      type="text"
                      label={t("contracts-management:billingForm.mobile")}
                      value={values.mainContactPerson?.contact?.mobile}
                      onBlur={handleBlur}
                      onChange={({ target }) => setFieldValue("mainContactPerson.contact.mobile", target.value)}
                      helperText={
                        touchedFileds.includes("mobile") && errors.mainContactPerson?.contact?.mobile
                          ? errors.mainContactPerson?.contact?.mobile
                          : undefined
                      }
                    />
                  </BillingSettingsRow>

                  <Grid item xs={12}>
                    <PrimaryButton
                      type="submit"
                      isLoading={isLoading}
                      label={t("shared:form.action.save")}
                      disabled={isLoading || !isValid || !dirty}
                    />
                  </Grid>
                </>
              ) : (
                <>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("contracts-management:billingForm.name")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>{values.mainContactPerson.name ?? "-"}</BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("contracts-management:billingForm.email")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>
                      {formatContactFields
                        ? formatEmail(values.mainContactPerson.contact?.email, "#365F10")
                        : values.mainContactPerson.contact?.email ?? "-"}
                    </BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("contracts-management:billingForm.phone")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>
                      {formatContactFields
                        ? formatPhone(values.mainContactPerson.contact?.telephone, "#365F10")
                        : values.mainContactPerson.contact?.telephone ?? "-"}
                    </BillingSettingsValueLabel>
                  </BillingSettingsRow>
                  <BillingSettingsRow>
                    <BillingSettingsLabel>{t("contracts-management:billingForm.mobile")}</BillingSettingsLabel>
                    <BillingSettingsValueLabel>
                      {formatContactFields
                        ? formatPhone(values.mainContactPerson.contact?.mobile, "#365F10")
                        : values.mainContactPerson.contact?.mobile ?? "-"}
                    </BillingSettingsValueLabel>
                  </BillingSettingsRow>
                </>
              )}
            </BillingSettingsFormWrapper>
          </Form>
        )
      }}
    </Formik>
  )
}

export default BillingMainContactForm

import { I18nNamespace } from "../../../../../App.i18n"

export const FeesI18n: I18nNamespace = {
  name: "fees",
  de: {
    "table.label.runningCosts": "Laufende Kosten pro Monat und Zähler",
    "table.label.number": "Anzahl",
    "table.label.price": "Preis",
    "table.label.sum": "Summe",
    "label.servicePerMonth": "Dienstleistungsgebühr pro Monat",
  },
  en: {
    "table.label.runningCosts": "Running costs per month and meter",
    "table.label.number": "Number",
    "table.label.price": "Price",
    "table.label.sum": "Sum",
    "label.servicePerMonth": "Service fee per month",
  },
}

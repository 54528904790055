import { useState, MouseEvent } from "react"
import { GridEventListener } from "@mui/x-data-grid"
import { useHistory } from "react-router-dom"
import { styled, Typography, Stack, Popover } from "@mui/material"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import { getTranslateValue } from "../../app/Shared.Utils"
import { DataGridTableProps, RowData } from "./dataGridTable.Model"
import { DataGridTable } from "./DataGridTable"

const RedirectStack = styled(Stack)(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.primary.dark,
  background: "rgba(251, 252, 226, 1)",
}))

export const DataGridRedirectRowTable = <T extends RowData<T>>({
  rows,
  columns,
  redirectDescription,
  redirectParamName,
  redirectUrl,
  onRowClickHandle,
  defaultPageSize,
  ...props
}: DataGridTableProps<T>) => {
  const history = useHistory()
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null)
  const open = Boolean(anchorEl)

  const handlePopoverOpen = (event: MouseEvent<HTMLElement>) => {
    const target = event.currentTarget as HTMLElement

    const canNotRedirect = target.classList.contains("RowNotClickable")
    if (!canNotRedirect) {
      setAnchorEl(event.currentTarget)
    }
  }
  const handlePopoverClose = () => setAnchorEl(null)

  const handleRowClick: GridEventListener<"rowClick"> = (params) => {
    if (onRowClickHandle) {
      return onRowClickHandle(params.row[redirectParamName ?? "id"])
    }
    const goTo = `${redirectUrl ?? ""}/${params.row[redirectParamName ?? "id"]}`
    history.push(goTo)
  }

  return (
    <>
      <DataGridTable<T>
        rows={rows}
        columns={columns}
        onRowClick={handleRowClick}
        {...props}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: defaultPageSize || 5,
            },
          },
          ...props.initialState,
        }}
        sx={{ cursor: "pointer", ...props.sx }}
        slotProps={{
          row: {
            onMouseEnter: handlePopoverOpen,
            onMouseLeave: handlePopoverClose,
            onWheel: handlePopoverClose,
          },
          ...props.slotProps,
        }}
      />
      <Popover
        sx={{ pointerEvents: "none" }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "center",
          horizontal: "right",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
        disablePortal
        disableScrollLock
        keepMounted={false}
        hideBackdrop
        slotProps={{
          paper: {
            sx: {
              padding: 2,
              borderRadius: 0,
              boxShadow: "none",
              background: "rgba(251, 252, 226, 0.6)",
            },
          },
        }}
      >
        <RedirectStack direction={"row"} spacing={1} p={0.5}>
          <Typography color="inherit" fontSize={14}>
            {redirectDescription ?? getTranslateValue("shared:link.goHere")}
          </Typography>
          <ArrowForwardIcon fontSize="small" color="inherit" />
        </RedirectStack>
      </Popover>
    </>
  )
}

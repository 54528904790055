import { Stack } from "@mui/material"
import { CancelButton } from "../button/CancelButton"
import { PrimaryButton } from "../button"
import { SaveIcon } from "../Shared.Icon"

interface FormUpdateActionsFormUpdateActionsViewProps {
  buttonCtaLabel: string
  isValid: boolean
  dirty: boolean
  isLoading: boolean
  navigateBack: () => void
  startIcon?: JSX.Element
}

export const FormUpdateActionsView = (props: FormUpdateActionsFormUpdateActionsViewProps) => {
  const { buttonCtaLabel, isValid, dirty, isLoading, navigateBack, startIcon } = props
  return (
    <Stack flex={1} flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
      <CancelButton id="form-abort" onClick={() => navigateBack()} data-testid="FormUpdateActionsView-CancelBtn" />
      <PrimaryButton
        type="submit"
        id="form-cta"
        label={buttonCtaLabel}
        isLoading={isLoading}
        startIcon={startIcon || <SaveIcon />}
        disabled={!isValid || !dirty || isLoading}
        data-testid="FormUpdateActionsView-SubmitBtn"
      />
    </Stack>
  )
}

import { I18nNamespace } from "../../../App.i18n"

export const DashboardI18n: I18nNamespace = {
  name: "dashboard",
  de: {
    "chart.solarProduction": "Solar-Produktion",
    "chart.ownConsumption": "Eigenverbrauch",
    "chart.powerSupplied": "Einspeisung",
    "chart.powerPurchased": "Netzbezug",
    "section-title": "Abrechnungs-Manager",
    "section-subtitle": "Übersicht {{lastDataPointDate}}",
    "section-overview.detail.analysis": "Detailierte Analyse",
    "section-overview.alert":
      "Hinweis: Sobald wir die Daten für Sie aufbereitet haben, sehen Sie hier eine Übersicht der wichtigsten Energiedaten.",
    "section-overview.most.recent.bills": "Offene Abrechnungen",
    "section-tab.periodic.settlements": "Periodische Abrechnungen",
    "section-tab.tenants.change": "Mieterwechsel",
    "section-view.all.bills": "Alle ansehen",
    "tutorial-not-completed": "Hinweis: Sie haben die Einrichtung Ihres ZEVs noch nicht beendet.",
    "tutorial-not-completed.tooltip": "Vollständiger Onboarding-Prozess",
    "button.tutorial": "Vervollständigen",
  },
  en: {
    "chart.solarProduction": "Solar production",
    "chart.ownConsumption": "Own consumption",
    "chart.powerSupplied": "Supplied",
    "chart.powerPurchased": "Grid power",
    "section-title": "Billing Manager",
    "section-subtitle": "Overview",
    "section-overview.detail.analysis": "Detailed Analysis",
    "section-overview.alert":
      "Note: As soon as we have prepared the data for you, you will see an overview of the most important energy data here.",
    "section-overview.most.recent.bills": "Open accounts",
    "section-tab.periodic.settlements": "Periodic settlements",
    "section-tab.tenants.change": "Change of tenants",
    "section-view.all.bills": "View all",
    "tutorial-not-completed": "Hint: You have not yet finished setting up your ZEV.",
    "tutorial-not-completed.tooltip": "Complete onboarding process",
    "button.tutorial": "Complete",
  },
}

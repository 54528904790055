import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit"
import { RootState } from "../../../App.Store"
import {
  IndividualParticipantBillingItem,
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import {
  AllParticipantsBillingState,
  IndividualParticipantBillingState,
  VewaAllParticipantsBillingState,
} from "../../../../data/generated-sources/openapi"

export interface PeriodicBillingsState {
  zevPeriodicBillings: ZevPeriodicBillingItem[]
}

export interface IndividualBillingsState {
  individualBillings: IndividualParticipantBillingItem[]
}

export interface BillingsState extends PeriodicBillingsState, IndividualBillingsState {
  vewaPeriodicBillings: VewaPeriodicBillingItem[]
}

const initialState: BillingsState = {
  individualBillings: [],
  zevPeriodicBillings: [],
  vewaPeriodicBillings: [],
}

const zevPeriodicDoneStatuses = [
  AllParticipantsBillingState.DONE,
  AllParticipantsBillingState.PAID,
  AllParticipantsBillingState.SUSPENDED,
]

const indvBillingDoneStatuses = [IndividualParticipantBillingState.DONE, IndividualParticipantBillingState.PAID]

export const billingsSlice = createSlice({
  name: "billings",
  initialState,
  reducers: {
    setZevPeriodicBillingsData: (state, { payload }: PayloadAction<ZevPeriodicBillingItem[]>) => {
      state.zevPeriodicBillings = payload
    },
    setVewaPeriodicBillingsData: (state, { payload }: PayloadAction<VewaPeriodicBillingItem[]>) => {
      state.vewaPeriodicBillings = payload
    },
    setIndividualBillingsData: (state, { payload }: PayloadAction<IndividualParticipantBillingItem[]>) => {
      state.individualBillings = payload
    },
    setBillingsToInitalState: (state) => {
      state.individualBillings = initialState.individualBillings
      state.zevPeriodicBillings = initialState.zevPeriodicBillings
      state.vewaPeriodicBillings = initialState.vewaPeriodicBillings
    },
  },
})

export const {
  setZevPeriodicBillingsData,
  setIndividualBillingsData,
  setVewaPeriodicBillingsData,
  setBillingsToInitalState,
} = billingsSlice.actions

const billingsState = (state: RootState) => state.managerPortal.billings
export const selectZevAllPeriodicBillings = createSelector(billingsState, (state) => state.zevPeriodicBillings)
export const selectZevDonePeriodicBillings = createSelector(billingsState, (state) =>
  state.zevPeriodicBillings.filter((bill) => zevPeriodicDoneStatuses.includes(bill.activeState)),
)
export const selectZevNotDonePeriodicBillings = createSelector(billingsState, (state) =>
  state.zevPeriodicBillings.filter((bill) => !zevPeriodicDoneStatuses.includes(bill.activeState)),
)
export const selectAllIndividualBillings = createSelector(billingsState, (state) => state.individualBillings)
export const selectDoneIndividualBillings = createSelector(billingsState, (state) =>
  state.individualBillings.filter((bill) => indvBillingDoneStatuses.includes(bill.activeState)),
)
export const selectNotDoneIndividualBillings = createSelector(billingsState, (state) =>
  state.individualBillings.filter((bill) => !indvBillingDoneStatuses.includes(bill.activeState)),
)
export const selectVewaPeriodicBillings = createSelector(billingsState, (state) => state.vewaPeriodicBillings)
export const selectVewaDonePeriodicBillings = createSelector(billingsState, (state) =>
  state.vewaPeriodicBillings.filter((bill) => bill.activeState === VewaAllParticipantsBillingState.DONE),
)
export const selectVewaNotDonePeriodicBillings = createSelector(billingsState, (state) =>
  state.vewaPeriodicBillings.filter((bill) => bill.activeState !== VewaAllParticipantsBillingState.DONE),
)

export default billingsSlice.reducer

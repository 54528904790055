import { createEpicMiddleware } from "redux-observable"
import { createLogger } from "redux-logger"
import { appEpic } from "./App.Epic"
import { createAppReducer, historyMiddleWare } from "./App.Reducer"
import { configureStore, Middleware, Dispatch, AnyAction } from "@reduxjs/toolkit"

const epicMiddleware = createEpicMiddleware()
const middleware = [epicMiddleware, historyMiddleWare]

// eslint-disable-next-line @typescript-eslint/ban-types, @typescript-eslint/no-explicit-any
type overideToolkit = Middleware<{}, any, Dispatch<AnyAction>>

const logger = createLogger({
  collapsed: true,
}) as overideToolkit

if (process.env.ENV_TYPE === "development") {
  middleware.push(logger)
}

export const appStore = configureStore({
  reducer: createAppReducer,
  middleware: middleware,
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof appStore.getState>
export type AppDispatch = typeof appStore.dispatch

epicMiddleware.run(appEpic)

import { I18nNamespace } from "../../../App.i18n"

export const ZevsI18n: I18nNamespace = {
  name: "zevs",
  de: {
    "list.title": "ZEVS",
    "list.navigation.create": "ZEV erstellen",
    "list.label.status": "STATUS",
    "field.label.zev": "ZEV",
    "list.label.address": "ADRESSE",
    "details.title": "ZEV DETAIL",
    "details.navigation.update": "ZEV BEARBEITEN",
    "form.field.nextBillingDate": "Nächstes Abrechnungsdatum",
    "form.field.zevStartDate": "ZEV Startdatum",
    "detail.title": "ZEV Detail",
    "participant.title": "Teilnehmer Detail",
    "edit.participant.title": "Teilnehmer Bearbeiten",
    "create.participant.title": "Teilnehmer Erstellen",
    "details.action.delete": "ZEV löschen",
    "details.action.delete.confirm": "Wollen Sie das ZEV endgültig löschen?",
    "details.action.delete.confirm.cta": "ZEV löschen",
    "details.action.delete.success": "ZEV wurde erfolgreich gelöscht",
    "details.action.activate": "ZEV aktivieren",
    "details.action.activate.success": "",
    "details.action.deactivate": "ZEV deaktivieren",
    "details.action.deactivate.success": "ZEV wurde erfolgreich deaktiviert",
    "details.activate.dialog.title": "ZEV Aktivieren",
    "details.activate.dialog.cta": "Aktivieren",
    "details.deactivate.dialog.title": "ZEV Deaktivieren",
    "details.action.create-initial-billing": "Initialrechnung erstellen",
    "create.title": "ZEV ERSTELLEN",
    "update.title": "ZEV BEARBEITEN",
    "form.field.name": "ZEV",
    "form.field.serviceStartDate": "Service Beginn",
    "form.field.serviceEndDate": "Service Ende",
    "form.field.billingFrequency": "Abrechnungsfrequenz",
    "form.field.nextBillingFrequency": "Nächste Abrechnungsfrequenz",
    "form.field.externalReferenceNumber": "Externe Referenznummer",
    "form.subtitle.measurement": "MESSUNG",
    "form.field.incomingMeteringCode": "Zählpunkt-ID Bezug",
    "form.field.outgoingMeteringCode": "Zählpunkt-ID Einspeisung",
    "form.field.MeteringCodePlaceholder": "CKW000000000000000000000000000000",
    "form.subtitle.contact": "KONTAKT",
    "form.subtitle.telephone": "TELEFON",
    "form.subtitle.mobile": "NATEL",
    "form.field.contactTelephoneNumber": "Telefon",
    "form.field.contactMobileNumber": "Mobile",
    "form.subtitle.correspondance": "KORRESPONDENZ",
    "form.field.contactEmail": "E-Mail",
    "form.subtitle.address": "ADRESSE",
    "form.field.addressLineOne": "ZEV Anschrift",
    "form.field.addressLineTwo": "Adresszusatz",
    "form.field.addressStreet": "Strasse",
    "form.field.addressHouseNumber": "Nr",
    "form.field.addressPostalCode": "PLZ",
    "form.field.addressCity": "Ort",
    "form.field.municipality": "Germeinde",
    "form.subtitle.mainContact": "HAUPTANSPRECHSPERSON",
    "form.field.mainContactName": "Name",
    "form.field.mainContactTelephoneNumber": "Telefon",
    "form.field.mainContactMobileNumber": "Mobile",
    "form.field.mainContactEmail": "E-Mail",
    "form.subtitle.paymentInformation": "ZAHLUNGSINFORMATIONEN",
    "form.field.paymentInformationPayee": "Begünstigter",
    "form.field.paymentInformationAccountNumber": "Kontonummer",
    "form.field.paymentInformationIban": "IBAN",
    "form.field.paymentInformationVatNumber": "MwSt-ID wenn zutreffend",
    "form.field.paymentInformationAddressStreet": "Strasse",
    "form.field.paymentInformationAddressHouseNumber": "Nr",
    "form.field.paymentInformationAddressPostalCode": "PLZ",
    "form.field.paymentInformationAddressCity": "Ort",
    "form.validation.length.incomingMeterCode": "Zählpunkt-ID Bezug muss mindestens 33 Zeichen lang sein",
    "form.field.billableFromDate": "Abrechnungsfähig ab",
    "form.field.billableUntilDate": "Abrechnungsfähig bis",
    "form.alert.success": "Die Änderungen am ZEV wurde erfolgreich gespeichert",
    "create.form.action.cta": "ZEV erstellen",
    "update.form.action.cta": "ZEV speichern",
    "detail.participant.billing.title": "TEILNEHMER ABRECHNUNGEN",
    "detail.participant.billing.tab.all": "Periodische Abrechnungen",
    "detail.participant.billing.tab.individual": "Mieterwechsel",
    "prices.title": "INTERNE STROMPREISE",
    "form.toggle.accept-zev-name": "ZEV Name übernehmen",
    "create.form.alert.success": "Der ZEV wurde erfolgreich angelegt. Gehen Sie zum ZEV",
    "form.subtitle.serviceInvoices": "DIENSTLEISTUNGSABRECHNUNGEN",
    "detail.participant.billing.tab.initial": "Initial",
    "detail.participant.billing.tab.recurring": "Wiederkehrend",
    "list.label.billing.status": "Status",
    "list.label.billing.bill-number": "Rechnungsnummer",
    "list.label.billing.date": "Datum",
    "list.label.billing.totalAmount": "Summe",
    "details.billingFrequency.BIANNUAL": "Halbjährlich",
    "details.billingFrequency.MONTHLY": "Monatlich",
    "details.billingFrequency.QUARTERLY": "Quartal",
    "details.billingFrequency.YEARLY": "Jährlich",
    "details.billingFrequency.YEARLY_ROLLING": "Jährliches Rollen",
  },
  en: {
    "list.title": "ZEVS",
    "list.navigation.create": "Create ZEV",
    "list.label.status": "STATUS",
    "field.label.zev": "ZEV",
    "list.label.address": "ADDRESS",
    "create.title": "CREATE ZEV",
    "update.title": "UPDATE ZEV",
    "detail.title": "ZEV Detail",
    "edit.participant.title": "Edit Participant",
    "create.participant.title": "Create Participant",
    "participant.title": "Participant Detail",
    "details.navigation.update": "ZEV UPDATE",
    "form.field.nextBillingDate": "Next billing date",
    "form.field.zevStartDate": "ZEV start date",
    "details.action.delete": "Clear ZEV",
    "details.action.delete.confirm": "Do you want to permanently delete the ZEV?",
    "details.action.delete.confirm.cta": "Clear ZEV",
    "details.action.delete.success": "ZEV was successfully deleted",
    "details.action.activate": "Activate ZEV",
    "details.action.activate.success": "",
    "details.action.deactivate": "Deactivate ZEV",
    "details.action.deactivate.success": "ZEV was successfully deactivated",
    "details.activate.dialog.title": "Activate",
    "details.activate.dialog.cta": "Activate",
    "details.deactivate.dialog.title": "Deactivate",
    "details.action.create-initial-billing": "Initial billing",
    "form.title": "CREATE ZEV",
    "form.field.name": "ZEV",
    "form.field.serviceStartDate": "Service start",
    "form.field.serviceEndDate": "Service end",
    "form.field.nextBillingFrequency": "Next billing frequency",
    "form.field.externalReferenceNumber": "External reference number",
    "form.subtitle.measurement": "MEASUREMENT",
    "form.field.incomingMeteringCode": "Incoming metering code",
    "form.field.outgoingMeteringCode": "Outgoing metering code",
    "form.field.MeteringCodePlaceholder": "CKW000000000000000000000000000000",
    "form.subtitle.contact": "CONTACT",
    "form.subtitle.telephone": "Telephone",
    "form.subtitle.mobile": "Mobile",
    "form.field.contactTelephoneNumber": "Telephone",
    "form.field.contactMobileNumber": "Mobile",
    "form.subtitle.correspondance": "Correspondance",
    "form.field.contactEmail": "E-Mail",
    "form.subtitle.address": "ADDRESS",
    "form.field.addressLineOne": "ZEV Address",
    "form.field.addressLineTwo": "Additional address",
    "form.field.addressStreet": "Street",
    "form.field.addressHouseNumber": "Number",
    "form.field.addressPostalCode": "Post code",
    "form.field.addressCity": "City",
    "form.field.municipality": "Municipality",
    "form.subtitle.mainContact": "MAIN CONTACT",
    "form.field.mainContactName": "Name",
    "form.field.mainContactTelephoneNumber": "Telephone",
    "form.field.mainContactMobileNumber": "Mobile number",
    "form.field.mainContactEmail": "E-mail",
    "form.subtitle.paymentInformation": "PAYMENT INFORMATION",
    "form.field.paymentInformationPayee": "Payee",
    "form.field.paymentInformationAccountNumber": "Account number",
    "form.field.paymentInformationIban": "IBAN",
    "form.field.paymentInformationVatNumber": "VAT ID if applicable",
    "form.field.paymentInformationAddressStreet": "Street",
    "form.field.paymentInformationAddressHouseNumber": "Number",
    "form.field.paymentInformationAddressPostalCode": "Post code",
    "form.field.paymentInformationAddressCity": "City",
    "form.validation.length.incomingMeterCode": "Meter point ID reference must be at least 33 characters long",
    "form.field.billableFromDate": "Billable from date",
    "form.field.billableUntilDate": "Billable until date",
    "form.alert.success": "The changes to the ZEV have been saved successfully",
    "create.form.action.cta": "Create ZEV",
    "update.form.action.cta": "Update ZEV",
    "detail.participant.billing.title": "TEILNEHMER ABRECHNUNGEN",
    "detail.participant.billing.tab.all": "Periodic billing",
    "detail.participant.billing.tab.individual": "Change of tenant",
    "prices.title": "INTERNAL ELECTRICITY PRICES",
    "form.toggle.accept-zev-name": "Accept ZEV name",
    "create.form.alert.success": "The ZEV was created successfully. Go to ZEV",
    "form.subtitle.serviceInvoices": "Service Invoices",
    "detail.participant.billing.tab.initial": "initial",
    "detail.participant.billing.tab.recurring": "recurring",
    "list.label.billing.status": "Status",
    "list.label.billing.bill-number": "Bill number",
    "list.label.billing.date": "Date",
    "list.label.billing.totalAmount": "Total",
    "details.billingFrequency.BIANNUAL": "Biannual",
    "details.billingFrequency.MONTHLY": "Monthly",
    "details.billingFrequency.QUARTERLY": "Quarterly",
    "details.billingFrequency.YEARLY": "Annual",
    "details.billingFrequency.YEARLY_ROLLING": "Annual Rolling",
  },
}

import { Box, Stack, Typography, styled } from "@mui/material"
import { BillIcon } from "../../../../../uikit/Shared.Icon"
import { IHeaderCard } from "./HeaderCardsWrapper"
import { appThemePrimaryLightColor } from "../../../../Shared.Theme"

const HeaderCardBox = styled(Box, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  cursor: "pointer",
  width: "18.75rem",
  minWidth: "18.75rem",
  minHeight: "7em",
  padding: theme.spacing(3),
  color: theme.palette.primary.dark,
  border: "1px solid #365F10",
  borderRadius: theme.spacing(3),
  background: selected ? theme.palette.background.lightPrimary : "transparent",
  ":hover": {
    background: theme.palette.background.lightPrimary,
  },
}))

const HeaderCardTitle = styled(Typography)(({ theme }) => ({
  flex: 1,
  fontWeight: 700,
  color: theme.palette.primary.dark,
  fontSize: theme.typography.pxToRem(18),
}))

const HeaderCardDescription = styled(Typography)(({ theme }) => ({
  width: "80%",
  fontWeight: 400,
  color: theme.palette.primary.dark,
  marginBottom: theme.spacing(2),
  fontSize: theme.typography.pxToRem(14),
}))

const HeaderCardProductDate = styled(Typography)(({ theme }) => ({
  fontWeight: 400,
  color: appThemePrimaryLightColor,
  fontSize: theme.typography.pxToRem(14),
}))

interface HeaderCardProps<T> extends IHeaderCard<T> {
  selected: boolean
  onSelect: () => void
}

export function HeaderCard<T = void>({
  title,
  descriptions,
  selected,
  icon,
  productChangeDate,
  onSelect,
}: Readonly<HeaderCardProps<T>>) {
  return (
    <HeaderCardBox selected={selected} onClick={onSelect}>
      <Stack direction={"row"} spacing={2} mb={2}>
        <HeaderCardTitle>{title}</HeaderCardTitle>
        {icon || <BillIcon fontSize="large" />}
      </Stack>
      {descriptions?.map((description, ind) => (
        <HeaderCardDescription key={ind}>{description}</HeaderCardDescription>
      ))}
      {productChangeDate && <HeaderCardProductDate>{productChangeDate}</HeaderCardProductDate>}
    </HeaderCardBox>
  )
}

import { apiHeaders } from "../../../Domain.Calls"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"

export const customerGetBuildingsByAreaId = async (areaId: string, page?: number, limit?: number, orderBy?: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAreaBuildingApi.customerGetAreaBuildings(
    areaId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const customerGetBuildingById = async (buildingId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerBuildingApi.customerGetBuildingById(
    buildingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

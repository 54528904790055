import { TFunction } from "i18next"
import RunningCost from "./RunningCost"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { DefaultCell } from "../../../../../../uikit/dataGridTable"

export const FeesTableConfig: (t: TFunction) => Array<GridBaseColDef> = (t: TFunction) => [
  {
    field: "name",
    headerName: t("fees:table.label.runningCosts"),
    flex: 250,
    renderCell: ({ row }) => {
      return <RunningCost billingType={row.billingType} name={row.name} />
    },
  },
  {
    field: "number",
    headerName: t("fees:table.label.number"),
    valueGetter: (_, row) => row?.units || "-",
    renderCell: DefaultCell,
    flex: 100,
  },
  {
    field: "price",
    headerName: t("fees:table.label.price"),
    valueGetter: (_, row) => (row?.priceWithoutVat ? `CHF ${row?.priceWithoutVat}` : "-"),
    renderCell: DefaultCell,
    flex: 100,
  },
  {
    field: "sum",
    headerName: t("fees:table.label.sum"),
    valueGetter: (_, row) => (row?.sum ? `CHF ${row?.sum}` : "-"),
    flex: 100,
  },
]

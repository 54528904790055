import { useEffect, useState } from "react"
import { Stack, Typography } from "@mui/material"
import { IconPositionEnum, PrimaryTab, PrimaryTabContainer } from "../../../../../../uikit/tabs/PrimaryTab"
import { useTranslation } from "react-i18next"
import { DotIcon } from "../../../../../../uikit/icons/Icons"
import { MediumColorEnum } from "../Medium.Enums"
import { useQuery } from "@tanstack/react-query"
import { getMediumZevByContractId } from "../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../Shared.Utils"
import { DataItemBoxNewDesign } from "../../../../../../uikit/box/DataItemBox"
import MediumZevTableComponent from "./MediumZevTable.Component"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"

const MediumZevComponent = ({ contractId }: { contractId: string }) => {
  const [selectedTab, setSelectedTab] = useState(0)
  const { t } = useTranslation("medium")
  const {
    data: mediumZevData,
    isError: isMediumZevError,
    isLoading: mediumZevIsLoading,
    remove: mediumZevRemove,
    error: mediumError,
  } = useQuery(["getMediumZevByContractId"], () => getMediumZevByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
  })
  const errorMsg = mediumError as AxiosErrorDataType

  useEffect(() => {
    return () => {
      mediumZevRemove()
    }
  }, [mediumZevRemove])

  return (
    <Stack spacing={3}>
      <ErrorAlert
        scrollOnDisplay
        visible={isMediumZevError}
        message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
      />
      <PrimaryTabContainer
        value={selectedTab}
        sx={{ paddingLeft: 0 }}
        onChange={(_, newValue) => setSelectedTab(newValue)}
      >
        <PrimaryTab
          label={t("tab.label.ELECTRICITY")}
          iconPosition={IconPositionEnum.END}
          icon={<DotIcon color={MediumColorEnum.ELECTRICITY} />}
        ></PrimaryTab>
      </PrimaryTabContainer>

      <DataItemBoxNewDesign title={t("label.municipality")} value={mediumZevData?.municipality} />

      <Typography variant="subtitle1" fontWeight={500} fontSize={14} pl={1}>
        {t("label.incomingMeters")}
      </Typography>
      <MediumZevTableComponent
        meters={mediumZevData?.incomingMeters}
        isLoading={mediumZevIsLoading}
        tableId="ckw-medium-incoming-meters-table"
      />

      <Typography variant="subtitle1" fontWeight={500} fontSize={14} pl={1}>
        {t("label.outgoingMeters")}
      </Typography>
      <MediumZevTableComponent
        meters={mediumZevData?.outgoingMeters}
        isLoading={mediumZevIsLoading}
        tableId="ckw-medium-outgoing-meters-table"
      />
    </Stack>
  )
}

export default MediumZevComponent

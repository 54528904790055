import { BILLING_SETTINGS_MASTER_DATA_CONFIG } from "./configs/BillingSettings.Config"
import { METERS_MASTER_DATA_CONFIG } from "./configs/Meter.Config"
import { AREA_MASTER_DATA_CONFIG } from "./configs/Area.Config"
import { CONTRACT_MANAGEMENT_MASTER_DATA_CONFIG } from "./configs/ContractManagement.Config"
import { CONTRACT_MANAGEMENT_CONTACT } from "./configs/ContractManagementContact.Config"
import { UTILITY_UNIT_MASTER_DATA_CONFIG } from "./configs/UtilityUnitDetails.Config"

export const MASTER_DATA_CONFIG = {
  ...METERS_MASTER_DATA_CONFIG,
  ...AREA_MASTER_DATA_CONFIG,
  ...CONTRACT_MANAGEMENT_MASTER_DATA_CONFIG,
  ...CONTRACT_MANAGEMENT_CONTACT,
  ...BILLING_SETTINGS_MASTER_DATA_CONFIG,
  ...UTILITY_UNIT_MASTER_DATA_CONFIG,
}

type objectKey = keyof typeof MASTER_DATA_CONFIG
export const getMasterDataConfig = (name: unknown) => {
  return MASTER_DATA_CONFIG[name as objectKey]
}

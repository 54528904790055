import { useEffect } from "react"

export const useHotJarScript = () => {
  useEffect(() => {
    // Load Hotjar script
    const script = document.createElement("script")
    script.innerHTML =
      "(function(h,o,t,j,a,r){ h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)}; h._hjSettings={hjid:4936004,hjsv:6}; a=o.getElementsByTagName('head')[0]; r=o.createElement('script');r.async=1; r.id='hotJarScript'; r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv; a.appendChild(r); })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');"
    script.async = true

    document.head.appendChild(script)
    const hotJarScript = document.getElementById("hotJarScript")

    return () => {
      document.head.removeChild(script)
      hotJarScript && document.head.removeChild(hotJarScript)
    }
  }, [])
}

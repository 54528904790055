import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { contractMasterDataSchema } from "./ContractMasterDataValidation.Schema"
import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import ContractMasterDataFields from "./ContractMasterDataFields"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import {
  getProductsForContract,
  updateContractById,
  getContractManagementById,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import {
  ContractMasterDataModify,
  initialContractMasterData,
} from "../../../../../domain/portal/admin/contracts/Contracts.Models"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { getAreaById } from "../../../../../domain/portal/admin/areas/Areas.Repository"
import { ContractUpdateAdminRequest } from "../../../../../data/generated-sources/openapi"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

const ContractMasterDataUpdateForm = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("contracts")
  const { id, contractId } = params
  const IDContract = contractId || id
  const [updateError, setUpdateError] = useState<AxiosErrorDataType>()

  const { data: products, isLoading: isLoadingProducts } = useQuery(["getAreaProducts"], () => getProductsForContract())

  const {
    isLoading: isLoadingContract,
    isFetching: isFetchingContract,
    data: contractData,
    remove: removeContract,
  } = useQuery(["getContract"], () => getContractManagementById(IDContract), {
    enabled: !!IDContract,
    ...useQueryDefaultOptions,
    onError: setUpdateError,
  })

  const {
    isLoading: isLoadingArea,
    isFetching: isFetchingArea,
    data: areaData,
    remove: removeArea,
  } = useQuery(["getArea"], () => getAreaById(contractData?.areaId || ""), {
    enabled: !!contractData?.areaId,
    ...useQueryDefaultOptions,
    onError: setUpdateError,
  })

  useEffect(
    () => () => {
      removeContract()
      removeArea()
    },
    [removeContract, removeArea],
  )

  const { mutate: updateContract, isLoading: isUpdatingArea } = useMutation(
    ["updateContract"],
    (updatePayload: ContractUpdateAdminRequest) => updateContractById(IDContract, updatePayload),
    {
      onSuccess: () => history.goBack(),
      onError: setUpdateError,
    },
  )

  const handleSubmit = ({ name, productId, startDate, endDate }: ContractUpdateAdminRequest) => {
    updateContract({ name, productId, startDate, endDate })
  }

  if ((isLoadingArea && isFetchingArea) || (isLoadingContract && isFetchingContract) || isLoadingProducts)
    return <FullScreenLoader />

  return (
    <Formik<ContractMasterDataModify>
      initialValues={{ ...initialContractMasterData, products: products, ...contractData, areaName: areaData?.name }}
      enableReinitialize
      validationSchema={contractMasterDataSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form {...props}>
          <ErrorAlert
            visible={!!updateError}
            message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
          />
          <ContractMasterDataFields {...props} isEditForm />
          <FormUpdateActionsView
            {...props}
            isLoading={isUpdatingArea}
            buttonCtaLabel={t("form.action.cta")}
            navigateBack={() => history.goBack()}
          />
        </Form>
      )}
    </Formik>
  )
}

export default ContractMasterDataUpdateForm

import {
  ApiCookie,
  createCookieAuthType,
  createCookieBearerToken,
  createCookieRefreshToken,
  createLastSelectedZev,
  readCookieAuthType,
  readCookieBearerToken,
  readCookieRefreshToken,
  readLastSelectedZev,
  removeCookies,
} from "../data/Api.Cookie"
import {
  AdminAllParticipantBillingApi,
  AdminAreaApi,
  AdminBillsApi,
  AdminBuildingApi,
  AdminContractApi,
  AdminIndividualParticipantBillingApi,
  AdminMeterApi,
  AdminMeterApiInterface,
  AdminMeterReadingsApi,
  AdminParticipantsApi,
  AdminProductsAndServicesApi,
  AdminProfileApi,
  AdminServiceBillingsApi,
  AdminSessionApi,
  AdminTariffSchedulesApi,
  AdminTasksApi,
  AdminUtilityUnitApi,
  AdminUtilityUnitApiInterface,
  Configuration,
  CustomerAllParticipantBillingApi,
  CustomerAreaApi,
  CustomerAreaBuildingApi,
  CustomerBillsApi,
  CustomerBuildingApi,
  CustomerContractApi,
  CustomerIndividualParticipantBillingApi,
  CustomerMeterApi,
  CustomerTariffSchedulesApi,
  CustomerMeterReadingsApi,
  CustomerParticipantsApi,
  CustomerProfileApi,
  CustomerSessionApi,
  CustomerUtilityUnitApi,
  DefaultApi,
} from "../data/generated-sources/openapi"

export interface DomainConfig {
  baseUrl: string
  appName: string
  locale: Locale
}

export interface DomainDependencies {
  defaultApi: DefaultApi
  adminProfileApi: AdminProfileApi
  adminSessionApi: AdminSessionApi
  adminAreaApi: AdminAreaApi
  adminBuildingApi: AdminBuildingApi
  adminProductsAndServicesApi: AdminProductsAndServicesApi
  adminContractApi: AdminContractApi
  adminMeterReadingsApi: AdminMeterReadingsApi
  adminServiceBillingsApi: AdminServiceBillingsApi
  adminBillsApi: AdminBillsApi
  adminAllParticipantBillingApi: AdminAllParticipantBillingApi
  adminIndividualParticipantBillingApi: AdminIndividualParticipantBillingApi
  adminParticipantsApi: AdminParticipantsApi
  adminTasksApi: AdminTasksApi
  adminTariffSchedulesApi: AdminTariffSchedulesApi
  adminMeterApi: AdminMeterApiInterface
  adminUtilityUnitApi: AdminUtilityUnitApiInterface
  customerSessionApi: CustomerSessionApi
  customerAreaApi: CustomerAreaApi
  customerAllParticipantBillingApi: CustomerAllParticipantBillingApi
  customerIndividualParticipantBillingApi: CustomerIndividualParticipantBillingApi
  customerAreaBuildingApi: CustomerAreaBuildingApi
  customerUtilityUnitApi: CustomerUtilityUnitApi
  customerBuildingApi: CustomerBuildingApi
  customerContractApi: CustomerContractApi
  customerIndividualParticipantApi: CustomerIndividualParticipantBillingApi
  customerParticipantsApi: CustomerParticipantsApi
  customerProfileApi: CustomerProfileApi
  customerMeterReadingsApi: CustomerMeterReadingsApi
  customerBillsApi: CustomerBillsApi
  customerMeterApi: CustomerMeterApi
  customerTariffSchedulesApi: CustomerTariffSchedulesApi
  cookie: ApiCookie
  config: DomainConfig
}

export const domainDependencies = (domainConfig: DomainConfig): DomainDependencies => {
  const apiConfig = new Configuration()
  return {
    defaultApi: new DefaultApi(apiConfig, domainConfig.baseUrl),
    adminProfileApi: new AdminProfileApi(apiConfig, domainConfig.baseUrl),
    adminSessionApi: new AdminSessionApi(apiConfig, domainConfig.baseUrl),
    adminAreaApi: new AdminAreaApi(apiConfig, domainConfig.baseUrl),
    adminBuildingApi: new AdminBuildingApi(apiConfig, domainConfig.baseUrl),
    adminProductsAndServicesApi: new AdminProductsAndServicesApi(apiConfig, domainConfig.baseUrl),
    adminContractApi: new AdminContractApi(apiConfig, domainConfig.baseUrl),
    adminMeterReadingsApi: new AdminMeterReadingsApi(apiConfig, domainConfig.baseUrl),
    adminServiceBillingsApi: new AdminServiceBillingsApi(apiConfig, domainConfig.baseUrl),
    adminBillsApi: new AdminBillsApi(apiConfig, domainConfig.baseUrl),
    adminAllParticipantBillingApi: new AdminAllParticipantBillingApi(apiConfig, domainConfig.baseUrl),
    adminIndividualParticipantBillingApi: new AdminIndividualParticipantBillingApi(apiConfig, domainConfig.baseUrl),
    adminParticipantsApi: new AdminParticipantsApi(apiConfig, domainConfig.baseUrl),
    adminTasksApi: new AdminTasksApi(apiConfig, domainConfig.baseUrl),
    adminTariffSchedulesApi: new AdminTariffSchedulesApi(apiConfig, domainConfig.baseUrl),
    adminMeterApi: new AdminMeterApi(apiConfig, domainConfig.baseUrl),
    adminUtilityUnitApi: new AdminUtilityUnitApi(apiConfig, domainConfig.baseUrl),
    customerSessionApi: new CustomerSessionApi(apiConfig, domainConfig.baseUrl),
    customerAreaApi: new CustomerAreaApi(apiConfig, domainConfig.baseUrl),
    customerAllParticipantBillingApi: new CustomerAllParticipantBillingApi(apiConfig, domainConfig.baseUrl),
    customerIndividualParticipantBillingApi: new CustomerIndividualParticipantBillingApi(
      apiConfig,
      domainConfig.baseUrl,
    ),
    customerAreaBuildingApi: new CustomerAreaBuildingApi(apiConfig, domainConfig.baseUrl),
    customerBuildingApi: new CustomerBuildingApi(apiConfig, domainConfig.baseUrl),
    customerUtilityUnitApi: new CustomerUtilityUnitApi(apiConfig, domainConfig.baseUrl),
    customerContractApi: new CustomerContractApi(apiConfig, domainConfig.baseUrl),
    customerIndividualParticipantApi: new CustomerIndividualParticipantBillingApi(apiConfig, domainConfig.baseUrl),
    customerParticipantsApi: new CustomerParticipantsApi(apiConfig, domainConfig.baseUrl),
    customerProfileApi: new CustomerProfileApi(apiConfig, domainConfig.baseUrl),
    customerMeterReadingsApi: new CustomerMeterReadingsApi(apiConfig, domainConfig.baseUrl),
    customerBillsApi: new CustomerBillsApi(apiConfig, domainConfig.baseUrl),
    customerMeterApi: new CustomerMeterApi(apiConfig, domainConfig.baseUrl),
    customerTariffSchedulesApi: new CustomerTariffSchedulesApi(apiConfig, domainConfig.baseUrl),
    cookie: {
      readCookieBearerToken: readCookieBearerToken,
      createCookieBearerToken: createCookieBearerToken,
      readCookieRefreshToken: readCookieRefreshToken,
      createCookieRefreshToken: createCookieRefreshToken,
      readCookieAuthType: readCookieAuthType,
      createCookieAuthType: createCookieAuthType,
      readLastSelectedZev: readLastSelectedZev,
      createLastSelectedZev: createLastSelectedZev,
      removeCookies: removeCookies,
    },
    config: domainConfig,
  }
}

import { I18nNamespace } from "../../../../App.i18n"

export const BillingsRecurringI18n: I18nNamespace = {
  name: "billings-recurring",
  de: {
    "list.title": "WIEDERKEHRENDE RECHNUNGEN",
    "list.label.status": "Status",
    "list.label.number-of-zevs": "Anzahl ZEV",
    "list.label.number-of-bills": "Anzahl RECHNUNGEN",
    "list.action.delete.success": "Der Rechnungslauf wurde bereits gelöscht",
    "detail.subtitle": "ÜBERPRÜFEN & FREIGEBEN",
    "detail.invoiceItems": "RECHNUNGSPOSITIONEN",
    "form.field.startDate": "Datum wählen",
    "form.field.endDate": "Enddatum",
    "form.field.billingFrequency": "Nächste Abrechnungsfrequenz",
    "form.field.periodOfYear": "{{part}}. Halbjahr",
    "form.field.selectContract": "Verträge",
    "form.field.allContracts": "Alle Verträge",
    "form.action.create.cta": "Ausgewählte erstellen",
    "details.label.submission.error": "Fehler bei der Übermittlung",
    "details.action.delete.confirm": "Sind Sie sich sicher?",
    "details.action.delete.confirm.cta": "Ausführen",
    "details.action.approve.success": "Der Rechnungslauf war gerade freigegeben",
    "details.action.recalculate.success": "Rechnung wurde erfolgreich neu berechnet",
    "details.action.remove.success": "Rechnung wurde erfolgreich gelöscht",
    "details.action.cancel.success": "Rechnung wurde erfolgreich storniert",
    "details.action.billingSap.success": "Alle Rechnungen wurden erfolgreich an SAP übermittelt",
    "details.action.serviceBillingSap.success": "Rechnung wurde erfolgreich an SAP übermittelt",
    "detail.title": "WIEDERKEHRENDE RECHNUNGEN DETAILS",
    "create.title": "NEUEN ABRECHNUNGSLAUF ERSTELLEN",
    "details.button.shareAll": "Alle freigeben",
    "details.button.submitAllToSAP": "Alle an SAP übermitteln",
    "details.button.submitToSAP": "An SAP übermitteln",
    "details.button.cancel": "Stornieren",
    "details.button.recalculate": "Neu berechnen",
    "details.button.extinguish": "Löschen",
    "details.label.total": "GESAMT",
    "field.label.status": "Status",
    "field.label.billingNumber": "Rechnungsnummer",
    "field.label.period": "Zeitraum",
    "field.label.zev": "Zev",
    "field.label.contract": "Verträg",
    "field.label.totalAmount": "Summe",
    "field.label.billingStatus": "Abrechnungsstatus",
    "field.label.position.name": "Abrechnungsdienstleistung",
    "field.label.position.quantity": "Anzahl",
    "field.label.position.price": "Preis",
    "field.label.position.total": "Summe",
    "field.label.type": "Typ",
    "field.label.orderReferenceNumber": "Auftrags Referenznummer",
    "field.label.invoiceReferenceNumber": "Rechnungs Referenznummer",
    "details.button.retry": "Erneut übermitteln",
    "remove.billing": "Abrechnung entfernen",
  },
  en: {
    "list.title": "RECURRING INVOICES",
    "list.label.status": "Status",
    "list.label.number-of-zevs": "Number of ZEV",
    "list.label.number-of-bills": "Number of BILLS",
    "list.action.delete.success": "The invoice run has already been deleted",
    "detail.subtitle": "REVIEW & RELEASE",
    "detail.invoiceItems": "Rechnungspositionen",
    "form.field.startDate": "Start Date",
    "form.field.endDate": "End Date",
    "form.field.billingFrequency": "Billing Frequency",
    "form.field.firstHalfYear": "1st Half of Year",
    "form.field.secondHalfYear": "2nd Half of Year",
    "form.field.selectContract": "Contracts",
    "form.field.allContracts": "All Contracts",
    "form.action.create.cta": "Create Selected",
    "details.label.submission.error": "Submission error",
    "details.action.delete.confirm": "Are you sure",
    "details.action.delete.confirm.cta": "Confirm",
    "details.action.approve.success": "The invoice run was just released",
    "details.action.recalculate.success": "Invoice was recalculated successfully",
    "details.action.remove.success": "The invoice was successfully deleted",
    "details.action.cancel.success": "Invoice was successfully canceled",
    "details.action.billingSap.success": "All invoices were successfully sent to SAP",
    "details.action.serviceBillingSap.success": "Invoice was successfully sent to SAP",
    "details.button.retry": "Submit again",
    "detail.title": "RECURRING INVOICES DETAILS",
    "create.title": "CREATE A NEW PAYROLL RUN",
    "details.button.shareAll": "Share All",
    "details.button.submitAllToSAP": "Submit All to SAP",
    "details.button.submitToSAP": "Submit to SAP",
    "details.button.cancel": "Cancel",
    "details.button.recalculate": "Recalculate",
    "details.button.extinguish": "Extinguish",
    "details.label.total": "TOTAL",
    "field.label.status": "Status",
    "field.label.billingNumber": "Bill Number",
    "field.label.period": "Period",
    "field.label.zev": "Zev",
    "field.label.contract": "Contract",
    "field.label.totalAmount": "Total Amount",
    "field.label.billingStatus": "Billing Status",
    "field.label.position.name": "Billing Service",
    "field.label.position.quantity": "Quantity",
    "field.label.position.price": "Price",
    "field.label.position.total": "Total",
    "field.label.type": "Type",
    "field.label.orderReferenceNumber": "Order Reference Number",
    "field.label.invoiceReferenceNumber": "Billing Status",
    "remove.billing": "Remove billing",
  },
}

import { Grid, GridProps, styled, Typography } from "@mui/material"

export const ContractFormTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  padding: theme.spacing(2, 0),
  fontSize: theme.typography.pxToRem(24),
}))

export const ContractFormRow = ({ children }: GridProps) => (
  <Grid item xs={12} md={6} p={1} pr={3}>
    {children}
  </Grid>
)

import { useTranslation } from "react-i18next"
import { CircularProgress, Typography } from "@mui/material"
import { useState } from "react"
import { ContractCustomerResponse } from "../../../../data/generated-sources/openapi"
import { SelectPicker } from "../../../../uikit/input/SelectPicker"
import { PrimaryButton } from "../../../../uikit/button"

interface OnboardingWelcomeProps {
  contracts: ContractCustomerResponse[]
  isLoading: boolean
  onNextClick: () => void
  onContractSelect: (contract: ContractCustomerResponse) => void
}

export const OnboardingWelcome = ({ contracts, isLoading, onNextClick, onContractSelect }: OnboardingWelcomeProps) => {
  const { t } = useTranslation("onboarding")
  const [selectedContract, setSelectedContract] = useState<ContractCustomerResponse>()

  const handleContractSelect = (contract: ContractCustomerResponse) => {
    setSelectedContract(contract)
    onContractSelect(contract)
  }

  if (isLoading) return <CircularProgress />
  return (
    <>
      <Typography color={"primary"} fontSize={23} fontWeight={400}>
        {t("startPage.title")}
      </Typography>
      <Typography mb={9.5} fontSize={16} fontWeight={400}>
        {t("startPage.subTitle")}
      </Typography>
      <Typography mb={2} fontSize={23} fontWeight={400}>
        {t("startPage.picker")}
      </Typography>
      <SelectPicker
        type="text"
        name="contractSelector"
        variant="outlined"
        emptyValue={t("startPage.picker")}
        value={selectedContract?.id ?? ""}
        items={contracts.map(({ id, name }) => ({
          label: name,
          value: id,
        }))}
        onChange={({ target }) => {
          const selectedContract = contracts.find((contract) => contract.id === target.value)
          selectedContract && handleContractSelect(selectedContract)
        }}
        customStyle={{ maxWidth: "45%", mb: 10 }}
      />
      <PrimaryButton label={t("startPage.button")} disabled={!selectedContract} onClick={onNextClick} />
    </>
  )
}

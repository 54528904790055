import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { DefaultCell } from "../../../../../../uikit/dataGridTable"
import { getTranslateValue } from "../../../../../Shared.Utils"
import { formatMoneyLabel } from "../../../../../../domain/Domain.Formatters"

export const InitialBillingDetailsTableColumns = () => {
  const initialBillingColumns: Array<GridBaseColDef> = [
    {
      flex: 200,
      field: "name",
      headerName: getTranslateValue("billings-initial:detail.consumptionpoint.list.initial-expenses"),
      renderCell: DefaultCell,
    },
    {
      flex: 100,
      type: "number",
      field: "quantity",
      headerAlign: "left",
      headerName: getTranslateValue("billings-initial:detail.consumptionpoint.list.quality"),
      renderCell: DefaultCell,
    },
    {
      flex: 100,
      field: "price",
      type: "number",
      headerAlign: "left",
      headerName: getTranslateValue("billings-initial:detail.consumptionpoint.list.price"),
      renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={formatMoneyLabel(value)} />,
    },
    {
      flex: 100,
      type: "number",
      headerAlign: "left",
      field: "finalAmountDue",
      headerName: getTranslateValue("billings-initial:detail.consumptionpoint.list.total"),
      renderCell: ({ value, ...rest }) => <DefaultCell {...rest} value={formatMoneyLabel(value)} />,
    },
  ]

  return initialBillingColumns
}

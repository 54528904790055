import { CSSProperties } from "react"

export const DashboardChartArrowLongIcon = (props: { color: string; style?: CSSProperties }) => {
  const { color, style } = props
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={266} height={18} style={style}>
      <g fill="none" fillRule="evenodd">
        <path stroke={color} strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M2 8.994h259" />
        <path
          fill={color}
          d="M259.187 3.118l5.355 5.355a1 1 0 010 1.414l-5.315 5.314a1 1 0 01-1.707-.703l-.04-10.67a1 1 0 011.706-.71z"
        />
      </g>
    </svg>
  )
}

import { DomicileAddress } from "../../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"
import { Stack, Typography, styled } from "@mui/material"

const ParticipantDetailTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(16),
  color: "#1A1A1A",
  lineHeight: "16px",
}))

const ParticipantDetailDomicileAddress = (props: DomicileAddress) => {
  const { t } = useTranslation("customer-participants")
  const { street, houseNumber, co, poBox, postCode, city, land } = props

  return (
    <Stack spacing={2} mt={2}>
      <ParticipantDetailTypography>
        {street} {houseNumber}
      </ParticipantDetailTypography>
      {co && (
        <ParticipantDetailTypography>
          {t("registerForm.co")} {co}
        </ParticipantDetailTypography>
      )}
      {poBox && <ParticipantDetailTypography mt={2}>{poBox}</ParticipantDetailTypography>}
      <ParticipantDetailTypography>
        {postCode} {city}
      </ParticipantDetailTypography>
      <ParticipantDetailTypography>{land}</ParticipantDetailTypography>
    </Stack>
  )
}

export default ParticipantDetailDomicileAddress

import { I18nNamespace } from "../../../../App.i18n"

export const UtilityUnitParticipationsI18n: I18nNamespace = {
  name: "utilityUnitParticipations",
  de: {
    "list.title": "Teilnehmerhistorie",
    "list.currentParticipant": "Aktueller Teilnehmer",
    "list.name": "Name",
    "list.label.participant.fullName": "Teilnehmer",
    "list.label.moveInDate": "Einzugsdatum",
    "list.label.moveOutDate": "Auszugsdatum",
    "list.noParticipation.name": "Leerstand",
    "list.button.reportMoveOut": "Auszug melden",
    "list.button.reportMoveIn": "Einzug melden",
    "list.addNewParticipant": "Teilnehmer erfassen",
    "list.addNewParticipantBtn": "Erfassen",
    "moveIn.form.title": "Einzug melden",
    "moveIn.form.subtitle.participant": "Neuer ZEV-Teilnehmer",
    "moveIn.form.participant": "Teilnehmer auswählen",
    "moveIn.form.moveInDate": "Einzugsdatum",
    "moveIn.form.cta": "Speichern und Endabrechnung anfordern",
    "moveIn.form.participantName": "Teilnehmername",
    "moveOut.saveAndInvoiceBtn": "Speichern und Endabrechnung anfordern",
    "move.form.title": "Auszug melden",
    "move.form.subtitle.currentParticipant": "Aktueller Teilnehmer",
    "move.form.moveOutDate": "Auszugsdatum",
    "move.form.subtitle.newParticipant": "Neuer Teilnehmer",
    "move.form.toggle.participantsKnown": "Teilnehmer bekannt",
    "move.form.toggle.create": "Teilnehmer erfassen",
    "move.form.toggle.select": "Teilnehmer auswählen",
    "move.form.toggle.vacancy": "Leerstand",
    "move.form.existingParticipant": "Teilnehmer auswählen",
    "delete.dialog.body": "Bei Bestätigung wird der Teilnehmer gelöscht. Bestätigen Sie um fortzufahren.",
    "delete.moveOut.dialog.body":
      "Nach der Bestätigung wird das Auszugsdatum des Teilnehmers gelöscht. Bestätigen Sie, um fortzufahren.",
    "delete.alert.success": "Teilnehmer wurde erfolgreich gelöscht",
    "moveInDate.form.tooBig": "Das Einzugsdatum darf nicht früher sein als das Auszugsdatum",
    "moveInDate.form.tooSmall": "Das neue Einzugsdatum darf nicht früher sein als der Beginn des Leerstandes",
    "moveInDate.form.error":
      "Das Einzugsdatum muss zwischen dem Auszugsdatum und dem Auszugsdatum des vorhergehenden Teilnehmers liegen",
    "moveOut.form.moveOutDate": "Auszugsdatum",
    "moveOutDate.form.error":
      "Das Auszugsdatum muss zwischen dem Einzugsdatum und dem Einzugsdatum des nächsten Teilnehmers liegen",
    "initial.hintTitle": "Hinweis",
    "initial.hint": "Hinweis: erfassen Sie den Teilnehmer, der zum ZEV Startdatum die Verbrauchsstelle nutzte.",
    "initial.saveBtn": "Speichern",
    "initial.closeBtn": "Abbrechen",
    "participation.title": "TEILNEHMERHISTORIE",
    "participation.name": "Name",
    "participation.noParticipation": "Leerstand",
    "participation.currentParticipant": "AKTUELLER TEILNEHMER",
    "tableHeader.attendees": "Teilnehmer",
    "tableHeader.moveInDate": "Einzugsdatum",
    "tableHeader.moveOutDate": "Auszugsdatum",
    "tooltip.delete": "Einzug löschen",
    "tooltip.deleteMoveOut": "Auszugsdatum löschen",
    "dialog.editParticipant": "Teilnehmer bearbeiten",
    "dialog.deleteMoveOut": "Auszug löschen",
    "dialog.editMoveIn": "Einzugsdatum anpassen",
    "dialog.editMoveOut": "Auszugsdatum anpassen",
    "dialog.selectMoveIn": "Einzugsdatum auswählen",
    "dialog.selectMoveOut": "Auszugsdatum auswählen",
    "dialog.moveOut.title": "Kennen Sie den neuen Teilnehmer bereits?",
  },
  en: {
    "list.title": "Participation history",
    "list.currentParticipant": "Current participant",
    "list.name": "Name",
    "list.label.participant.fullName": "Name",
    "list.label.moveInDate": "Move in date",
    "list.label.moveOutDate": "Move out date",
    "list.noParticipation.name": "Empty",
    "list.button.reportMoveOut": "Report the move out",
    "list.button.reportMoveIn": "Report the move in",
    "list.tooltip.delete": "Delete indentation",
    "delete.dialog.body": "Upon confirmation, the participant will be deleted. Confirm to continue.",
    "delete.moveOut.dialog.body":
      "After confirmation, the participant's move-out date will be deleted. Confirm to continue.",
    "delete.alert.success": "Teilnehmer wurde erfolgreich gelöscht",
    "move.form.title": "Report the move",
    "move.form.subtitle.currentParticipant": "Current Participant",
    "move.form.moveOutDate": "Move-out date",
    "move.form.subtitle.newParticipant": "New Participant",
    "move.form.toggle.participantsKnown": "Participants known",
    "move.form.toggle.select": "Select participant",
    "move.form.toggle.create": "Create participant",
    "move.form.toggle.vacancy": "Vacancy",
    "move.form.existingParticipant": "Select participants",
    "moveIn.form.title": "Report moving in",
    "moveIn.form.subtitle.participant": "New zev participant",
    "moveIn.form.participant": "Select participants:",
    "moveIn.form.participantName": "participant name",
    "moveOut.saveAndInvoiceBtn": "Save and request final invoice",
    "moveIn.form.moveInDate": "move-in date",
    "moveIn.form.cta": "Save and Request final invoice",
    "moveInDate.form.tooBig": "The move in date cannot occur before the move out date",
    "moveInDate.form.tooSmall": "The new move in date cannot occur before the beginning of the empty item",
    "moveInDate.form.error":
      "The move in date has to occur between the move out date and the move out date of the previous participant",
    "moveOut.form.moveOutDate": "Move out date",
    "moveOutDate.form.error":
      "The move out date has to occur between the move in date and the move in date of the next participant",
    "list.addNewParticipant": "Teilnehmer erfassen",
    "list.addNewParticipantBtn": "Erfassen",
    "initial.hintTitle": "Hinweis",
    "initial.hint": "Hinweis: erfassen Sie den Teilnehmer, der zum ZEV Startdatum die Verbrauchsstelle nutzte.",
    "initial.saveBtn": "Speichern",
    "initial.closeBtn": "Abbrechen",
    "participation.title": "PARTICIPANT HISTORY",
    "participation.name": "Name",
    "participation.noParticipation": "Empty",
    "participation.currentParticipant": "CURRENT PARTICIPANT",
    "tableHeader.attendees": "ATTENDEES",
    "tableHeader.moveInDate": "MOVE-IN DATE",
    "tableHeader.moveOutDate": "MOVE-OUT DATE",
    "tooltip.delete": "Delete indentation",
    "tooltip.deleteMoveOut": "Delete move-out date",
    "dialog.editParticipant": "Edit participant",
    "dialog.deleteMoveOut": "Delete move out date",
    "dialog.editMoveIn": "Edit move-in date",
    "dialog.editMoveOut": "Edit move-out date",
    "dialog.selectMoveIn": "Select move-in date",
    "dialog.selectMoveOut": "Select move-out date",
    "": "Do you already know the new participant?",
  },
}

import { Box } from "@mui/material"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import { SmallPaddedBox } from "../../../../../uikit/box/PaddedBox"
import { INTRA_DAY_METER_CHART_HEIGHT, LineChartView } from "../../../../../uikit/chart/LineChartView"
import { MiddleCircularProgress } from "../../../../../uikit/indicator/ProgressIndicator"
import { getZevDailyChartConfig } from "./chart.config"
import { EmptyChartDisplay } from "../../../../../uikit/chart/EmptyChartView"
import React from "react"
import { ZevDailyPropsInterface } from "../Analise.Model"
import { useTranslation } from "react-i18next"

export const ZevDailyComponent = (props: ZevDailyPropsInterface) => {
  const { t } = useTranslation("analise")
  const { chartData, isLoading } = props

  return (
    <>
      {!!chartData.length && (
        <Box pb={4} pl={2}>
          <Subtitle1>{t("shared:chart.legend.subtitle")}</Subtitle1>
        </Box>
      )}
      <SmallPaddedBox>
        <Box
          sx={{
            width: "100%",
            height: INTRA_DAY_METER_CHART_HEIGHT,
          }}
        >
          {isLoading && <MiddleCircularProgress height={INTRA_DAY_METER_CHART_HEIGHT} />}
          {chartData.length > 0 && (
            <LineChartView dataKey="dateTime" data={chartData} chartConfig={getZevDailyChartConfig(false, t)} />
          )}
          {chartData.length === 0 && <EmptyChartDisplay />}
        </Box>
      </SmallPaddedBox>
    </>
  )
}

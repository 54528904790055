import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"

export const contractMasterDataSchema = yup.object({
  name: yup
    .string()
    .max(255)
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("shared:label.name"),
      }),
    ),
  startDate: yup.date().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("shared:form.startDate"),
    }),
  ),
  productId: yup.string().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("shared:form.product"),
    }),
  ),
})

import { createEpic } from "../../../Shared.Epic"
import { DOMAIN_DEPENDENCIES } from "../../../App.Config"
import {
  ZevPriceCalculatorTariffs,
  EnrichedConsumptionPoint,
  MyZevPriceUpsert,
  MyZevSummary,
} from "../../../../domain/portal/manager/my-zev/MyZev.Model"
import {
  getZevPriceCalculatorEnergyById,
  getZevPriceCalculatorInputById,
  getZevPriceCalculatorTariffsById,
  getZevPriceCalculatorPricesById,
  updateZevPriceCalculatorInputById,
  updateZevPricePackageById,
  getZevPriceCalculatorPricesSuppliedEnergyById,
  getConsumptionPoints,
  createZevPricePackageById,
  getZevPricePackages,
  getMyZevDetails,
} from "../../../../domain/portal/manager/my-zev/MyZev.Repository"
import {
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorInput,
  ZevPriceCalculatorPricesResponse,
} from "../../../../data/generated-sources/openapi"

export enum MyZevElectricityCalculatorActionType {
  MY_ZEV_PRICE_CALCULATOR_ENERGY = "MY_ZEV_PRICE_CALCULATOR_ENERGY",
  MY_ZEV_PRICE_CALCULATOR_INPUT = "MY_ZEV_PRICE_CALCULATOR_INPUT",
  MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID = "MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID",
  MY_ZEV_PRICE_CALCULATOR_PRICES = "MY_ZEV_PRICE_CALCULATOR_PRICES",
  MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE = "MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE",
  MY_ZEV_PRICES_UPDATE = "MY_ZEV_PRICES_UPDATE",
  MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET = "MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET",
  MY_ZEV_CONSUMPTION_POINTS = "MY_ZEV_CONSUMPTION_POINTS",
  MY_ZEV_PRICE_PACKAGE_CREATE = "MY_ZEV_PRICE_PACKAGE_CREATE",
  MY_ZEV_PRICE_PACKAGES = "MY_ZEV_PRICE_PACKAGES",
  MY_CONTRACT = "MY_CONTRACT",
}

export const myZevElectricityCalculatorEpic = [
  createEpic<ZevPriceCalculatorEnergy>(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_ENERGY, (action) =>
    getZevPriceCalculatorEnergyById(action.contractId, action.year, DOMAIN_DEPENDENCIES),
  ),
  createEpic<ZevPriceCalculatorInput | null>(
    MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT,
    (action) => getZevPriceCalculatorInputById(action.contractId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<ZevPriceCalculatorTariffs[]>(
    MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID,
    (action) => getZevPriceCalculatorTariffsById(action.contractId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<ZevPriceCalculatorPricesResponse>(
    MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES,
    (action) => getZevPriceCalculatorPricesById(action.contractId, action.year, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE, (action) =>
    updateZevPriceCalculatorInputById(action.contractId, action.inputPrices, DOMAIN_DEPENDENCIES),
  ),
  createEpic<boolean>(MyZevElectricityCalculatorActionType.MY_ZEV_PRICES_UPDATE, (action) =>
    updateZevPricePackageById(action.contractId, action.pricePackageId, action.pricePackage, DOMAIN_DEPENDENCIES),
  ),
  createEpic<EnrichedConsumptionPoint[]>(MyZevElectricityCalculatorActionType.MY_ZEV_CONSUMPTION_POINTS, (action) =>
    getConsumptionPoints(action.consumptionIds, action.buildingsMap, DOMAIN_DEPENDENCIES),
  ),
  createEpic<ZevPriceCalculatorPricesResponse>(
    MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET,
    (action) =>
      getZevPriceCalculatorPricesSuppliedEnergyById(
        action.contractId,
        action.year,
        action.zevPriceCalculatorEnergy,
        DOMAIN_DEPENDENCIES,
      ),
  ),
  createEpic<boolean>(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGE_CREATE, (action) =>
    createZevPricePackageById(action.contractId, action.pricePackageId, action.nextPricePackage, DOMAIN_DEPENDENCIES),
  ),
  createEpic<MyZevPriceUpsert[]>(MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGES, (action) =>
    getZevPricePackages(action.contractId, DOMAIN_DEPENDENCIES),
  ),
  createEpic<MyZevSummary>(MyZevElectricityCalculatorActionType.MY_CONTRACT, (action) =>
    getMyZevDetails(action.contractId, DOMAIN_DEPENDENCIES),
  ),
]

import { apiHeaders } from "../../../Domain.Calls"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import {
  BillingFrequency,
  ContactData,
  ContractAddressData,
  ContractUpdateMainContactPersonCustomerRequest,
  ContractUpdatePaymentInformationCustomerRequest,
  ContractUpdateStartDateCustomerRequest,
  ContractUpdateVewaBillingSettingsCustomerRequest,
  ContractUpdateZevBillingSettingsCustomerRequest,
  PagedUtilityUnitResponse,
  VewaCostShares,
  ZevPricePackage,
} from "../../../../data/generated-sources/openapi"
import { calculateNextFrequency } from "../../../../services/utils/BillingUtilis"

export const customerGetContractById = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetAreaManagerContracts = async (areaId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAreaApi.customerGetAreaManagerContracts(
    areaId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const customerGetPricePackagesByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetPricePackagesByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContractPricePackageById = async (
  contractId: string,
  pricePackageId: number,
  zevPricePackage: ZevPricePackage,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractPricePackageById(
    contractId,
    pricePackageId,
    zevPricePackage,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerCreatePricePackageByContractId = async (contractId: string, zevPricePackage: ZevPricePackage) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerCreatePricePackageByContractId(
    contractId,
    zevPricePackage,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerDeleteContractPricePackageById = async (contractId: string, pricePackageId: number) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerDeleteContractPricePackageById(
    contractId,
    pricePackageId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetServiceFeatureByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractServiceComponentFeatures(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetBillingSettingsByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetBillingSettingsByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContractStartDateById = async (
  contractId: string,
  payload: ContractUpdateStartDateCustomerRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractStartDateById(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContractVewaBillingSettingsById = async (
  contractId: string,
  payload: ContractUpdateVewaBillingSettingsCustomerRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractVewaBillingSettingsById(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContractZevBillingSettingsById = async (
  contractId: string,
  payload: ContractUpdateZevBillingSettingsCustomerRequest,
  billingFrequency: BillingFrequency,
) => {
  await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractZevBillingSettingsById(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  if (payload.newNextBillingFrequency) {
    return calculateNextFrequency(billingFrequency, payload.newNextBillingFrequency)
  }
  return { currentFrequencyLastActiveDate: "" }
}

export const customerUpdateContractMainContactPersonById = async (
  contractId: string,
  payload: ContractUpdateMainContactPersonCustomerRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractMainContactPersonById(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContractPaymentInformationById = async (
  contractId: string,
  payload: ContractUpdatePaymentInformationCustomerRequest,
) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContractPaymentInformationById(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContactDataByContractId = async (contractId: string, payload: ContactData) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContactDataByContractId(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerUpdateContactAddressByContractId = async (contractId: string, payload: ContractAddressData) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerUpdateContactAddressByContractId(
    contractId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerCompleteContractOnboardingById = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerCompleteContractOnboardingById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetUtilityUnitsByContractId = async (
  contractId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<PagedUtilityUnitResponse> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetUtilityUnitsByContractId(
    contractId,
    page ?? 1,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerGetContactByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContactByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const checkContractLatestCalcAvailability = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetContractLastDataPointById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  return data?.latestCalcAvailability
}

export const customerGetProductHistoryByContractId = (contractId: string) =>
  DOMAIN_DEPENDENCIES.customerContractApi.customerGetProductHistoryContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const customerGetVewaCostSharesByContractId = async (contractId: string) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerContractApi.customerGetVewaConfigByContractId(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const customerPutVewaCostSharesByContractId = async (contractId: string, vewaCostShares: VewaCostShares) => {
  return DOMAIN_DEPENDENCIES.customerContractApi.customerPutVewaCostSharesByContractId(
    contractId,
    vewaCostShares,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

import {
  BillingFrequency,
  ContractUpdateContactAdminRequest,
  ContractState,
  ContractAdminResponse,
} from "../../../../data/generated-sources/openapi"

export interface ContractProduct {
  id: string
  name: string
}

export interface ContractMasterDataModify extends ContractAdminResponse {
  products?: ContractProduct[]
  areaName?: string
}

export const initialContractMasterData: ContractMasterDataModify = {
  id: "",
  name: "",
  startDate: "",
  endDate: "",
  areaId: "",
  productId: "",
  products: [],
  activeState: ContractState.DRAFT,
}

export interface ContractUpdateContactAdminRequestExtended extends ContractUpdateContactAdminRequest {
  useContractName?: boolean
  contractName?: string
}

export const initialContractContact: ContractUpdateContactAdminRequestExtended = {
  contact: {
    telephone: "",
    mobile: "",
    email: "",
  },
  addressData: {
    addressLineOne: "",
    addressLineTwo: "",
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  },
  externalReferenceNumber: "",
  useContractName: false,
  contractName: "",
}

export const ContractBillingFrequencyKeys = Object.keys(BillingFrequency)
export const ContractBillingFrequencyValues = Object.values(BillingFrequency)

import { AbortIcon, BrightnessIcon } from "../Shared.Icon"
import { ButtonProps, IconButton } from "@mui/material"

interface DotIconPropsInterface {
  color: string
  style?: object
}

export const DotIcon = ({ color, style }: DotIconPropsInterface) => (
  <BrightnessIcon
    sx={{
      width: "15px",
      ml: "10px",
      color: color,
      ...style,
    }}
  />
)

export const DialogCloseIcon = ({ onClick, sx, ...rest }: ButtonProps) => (
  <IconButton
    aria-label="close"
    onClick={onClick}
    sx={{
      position: "absolute",
      right: 35,
      top: 28,
      color: "#000",
      ...sx,
    }}
    {...rest}
  >
    <AbortIcon />
  </IconButton>
)

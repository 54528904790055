import { DaysOfWeekCheckboxI } from "../TariffModels"
import { Checkbox, Grid, Typography } from "@mui/material"
import { appThemeGrey250, colorBlack } from "../../../../Shared.Theme"
import React from "react"

const DaysOfWeekCheckbox = ({ day, checked, onChange }: DaysOfWeekCheckboxI) => (
  <Grid item key={day}>
    <Checkbox
      color="primary"
      checked={checked}
      onChange={onChange}
      sx={{
        color: colorBlack,
        "&.Mui-checked": {
          color: colorBlack,
        },
      }}
    />
    <Typography variant="caption" color={appThemeGrey250}>
      {day}
    </Typography>
  </Grid>
)

export default DaysOfWeekCheckbox

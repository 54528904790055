import MasterDataComponent from "./MasterData.Component"
import { getMasterDataConfig } from "./MasterData.Config"
import React from "react"

interface MasterDataChildComponentInterface {
  configName: string
}
const MasterDataChildComponent = (props: MasterDataChildComponentInterface) => {
  const { configName } = props
  const configObject = getMasterDataConfig(configName)
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return <MasterDataComponent config={configObject} />
}

export default MasterDataChildComponent

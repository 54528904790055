import { Box, Grid } from "@mui/material"
import { Subtitle1 } from "../../../../../uikit/typography/Typography"
import DateRangeComponent from "../../../shared/analiyse/components/DateRange.Component"
import { EmptyChartDisplay } from "../../../../../uikit/chart/EmptyChartView"
import React, { useEffect, useState } from "react"
import { AnaliseDateRangeEnum } from "../../../shared/analiyse/Analise.Enum"
import { formatDateRange, getEndDate, getStartDate } from "../../../../../services/utils/AnalyseUtils"
import DateUtils from "../../../../../services/utils/DateUtils"
import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { getMetersAnalytics } from "../../../../../domain/portal/admin/meters/Meters.Repository"
import { MeterReadingsCalcResolution } from "../../../../../data/generated-sources/openapi"
import { getMeterReadingsChartConfig } from "../../../shared/analiyse/charts/chart.config"
import { INTRA_DAY_METER_CHART_HEIGHT } from "../../../../../uikit/chart/LineChartView"
import { MiddleCircularProgress } from "../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { BarChartView } from "../../../../../uikit/chart/BarChartView"
import { ExceptionI, LineChartDataPoint } from "../../../shared/analiyse/Analise.Model"
import { mediumRedColor } from "../../../../Shared.Theme"

interface MeterAnalyticsI {
  meterId: string
}

const MeterAnalytics = (props: MeterAnalyticsI) => {
  const { meterId } = props
  const { t } = useTranslation("meters")
  const [selectedDateTime, setSelectedDateTime] = useState(
    new Date(new Date().setHours(0, 0, 0, 0)).setDate(new Date().getDate() - 1),
  )
  const [selectedDateRange, setSelectedDateRange] = useState(MeterReadingsCalcResolution.MONTH)
  const [timestamp, setTimestamp] = useState(DateUtils.getNowTimestamp())
  const [startDate, setStartDate] = React.useState(DateUtils.getFirstDayOfMonth(timestamp))
  const [endDate, setEndDate] = React.useState(DateUtils.getLastDayOfMonth(timestamp))
  const [error, setError] = useState<boolean>(false)
  const { data, isLoading, remove, refetch } = useQuery(
    ["getMeterAnalise"],
    () => {
      return getMetersAnalytics(
        meterId,
        startDate,
        endDate,
        formatDateRange(selectedDateRange) as MeterReadingsCalcResolution,
      )
    },
    {
      enabled: !!meterId,
      onError: () => {
        setError(true)
      },
    },
  )
  const chartData = data?.data.data

  useEffect(() => {
    if (startDate && endDate && selectedDateRange && timestamp) {
      setStartDate(getStartDate(timestamp, selectedDateRange) as string)
      setEndDate(getEndDate(timestamp, selectedDateRange) as string)
    }
  }, [selectedDateRange, startDate, timestamp, endDate])

  useEffect(() => {
    refetch()
  }, [startDate, endDate])

  useEffect(() => () => remove(), [remove])

  const getDateRange = (range: MeterReadingsCalcResolution | AnaliseDateRangeEnum) => {
    setSelectedDateRange(range as MeterReadingsCalcResolution)
  }

  const getTimeRange = (date: number) => {
    setSelectedDateTime(date)
    setTimestamp(date)
    setStartDate(getStartDate(date, selectedDateRange) as string)
    setEndDate(getEndDate(date, selectedDateRange) as string)
  }

  const getSelectedDateRange = () => {
    const date: unknown = selectedDateRange
    return date as AnaliseDateRangeEnum
  }

  const getChartData = (): LineChartDataPoint[] => {
    if (!chartData) return []
    return chartData
  }

  const getException = (): ExceptionI => {
    const exception: ExceptionI = {
      value: selectedDateRange === MeterReadingsCalcResolution.DAY ? 1 : 96,
      color: mediumRedColor,
      noException: false,
    }
    if (selectedDateRange === MeterReadingsCalcResolution.YEAR) {
      exception.noException = true
    }
    return exception
  }

  return (
    <Grid container={true} item={true} xs={12} justifyContent={"flex-end"} sx={{ p: 1 }}>
      <Grid item xs={12}>
        <ErrorAlert visible={error} message={t("error-codes:OTHER")} />
      </Grid>
      <Grid item xs={12} sm={8}>
        <Box p={1} pb={2}>
          <Subtitle1>{t("label.meterAnalysis")}</Subtitle1>
        </Box>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Box p={1} pb={2}>
          <DateRangeComponent
            selectedDateRange={getSelectedDateRange()}
            handleDateRangeChange={getDateRange}
            selectedDateTime={selectedDateTime}
            handleDateTimeChange={getTimeRange}
            includeDaySelector={true}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <Box
          sx={{
            width: "100%",
            height: 300,
          }}
        >
          {isLoading && <MiddleCircularProgress height={INTRA_DAY_METER_CHART_HEIGHT} />}
          {chartData?.length && data ? (
            <BarChartView
              dataKey="dateTime"
              data={getChartData()}
              meterReadingDateRange={selectedDateRange}
              exception={getException()}
              chartConfig={getMeterReadingsChartConfig(t)}
            />
          ) : (
            <EmptyChartDisplay />
          )}
        </Box>
      </Grid>
    </Grid>
  )
}

export default MeterAnalytics

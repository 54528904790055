import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ORDERED_STRING_COMPARATOR, ORDERED_STRING_LOCALE_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { AreaContractsEnum } from "./AreaContracts.Enum"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"

export const AreaContractColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case AreaContractsEnum.STATUS:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case AreaContractsEnum.NAME:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction)
    case AreaContractsEnum.PRODUCT:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.productId, b.productId, orderBy.direction)
    case AreaContractsEnum.START_DATE:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
    case AreaContractsEnum.END_DATE:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        a.endDate && b.endDate ? ORDERED_STRING_COMPARATOR(a?.endDate, b?.endDate, orderBy.direction) : 1
  }
}

import { combineReducers } from "redux"
import {
  MyZevElectricityCalculatorState,
  myZevElectricityCalculatorReducer,
} from "./my-zev/MyZevElectricityCalculator.Reducer"
import { ProfileDetailReducer, ProfileDetailState } from "./profile/ProfileDetails.Reducer"
import uiSlice, { UiState } from "./store/uiSlice"
import billingsSlice, { BillingsState } from "./store/billingsSlice"
import contractSelectorSlice, { ContractSelectorState } from "./store/contractSelectorSlice"

export interface ManagerPortalState {
  myZevElectricityCalculator: MyZevElectricityCalculatorState
  profileDetails: ProfileDetailState
  ui: UiState
  billings: BillingsState
  contractSelector: ContractSelectorState
}

export const managerPortalReducer = combineReducers<ManagerPortalState>({
  myZevElectricityCalculator: myZevElectricityCalculatorReducer,
  profileDetails: ProfileDetailReducer,
  ui: uiSlice,
  billings: billingsSlice,
  contractSelector: contractSelectorSlice,
})

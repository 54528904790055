import { CircularProgress, Divider, Typography, styled } from "@mui/material"
import { useTranslation } from "react-i18next"

import { ZevBillingParticipations } from "./ZevBillingParticipations"
import { ZevBillingAggregateConsumption } from "./ZevBillingAggregateConsumption"
import { ZevBillingPricePackages } from "./ZevBillingPricePackages"
import { ZevBillingTotalSection } from "./ZevBillingTotalSection"
import { ZevPricePackage } from "../../../../data/generated-sources/openapi"
import { ZevBillingPieChart } from "./ZevBillingPieChart"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { ZevBillingsChartLegend } from "./ZevBillingsChartLegend"
import {
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"

const SectionDivider = styled(Divider)(({ theme }) => ({
  background: "#EFF0EF",
  margin: theme.spacing(6.25, 0),
}))

export const ZevBillingOverview = ({
  billing,
  isLoadingBilling,
  isUpdatingPrices,
  isApprovingBilling,
  onPriceUpdate,
  onClickApprove,
  onGoToUnitClick,
}: {
  billing?:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
  isLoadingBilling: boolean
  isApprovingBilling: boolean
  isUpdatingPrices: boolean
  onClickApprove: () => void
  onGoToUnitClick: (utilityUnitId: string) => void
  onPriceUpdate: (updateData: ZevPricePackage) => void
}) => {
  const { t } = useTranslation("billings-participant")

  return (
    <>
      {isLoadingBilling ? (
        <CircularProgress />
      ) : (
        <>
          {billing && (
            <>
              <Typography fontSize={23} fontWeight={400} mb={3.75}>
                {t("detail.all.consumption-overview")}
              </Typography>
              <ZevBillingsChartLegend {...billing} />
              <ZevBillingPieChart {...billing} isLoading={isLoadingBilling} />
              <SectionDivider />
              <ZevBillingParticipations
                isLoading={isLoadingBilling}
                bills={billing?.bills || []}
                onGoToUnitClick={onGoToUnitClick}
              />
              <SectionDivider />
              <ZevBillingPricePackages
                pricePackages={billing?.pricePackages || []}
                billingId={billing.id}
                onPriceUpdate={onPriceUpdate}
                isUpdatingPrices={isUpdatingPrices}
              />
              <SectionDivider />
              <ZevBillingAggregateConsumption isLoading={isLoadingBilling} billing={billing} />
              <SectionDivider />
              <ZevBillingTotalSection
                billing={billing}
                isLoading={isApprovingBilling}
                onClickApprove={onClickApprove}
              />
            </>
          )}
        </>
      )}
    </>
  )
}

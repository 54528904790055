import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { contactSchema } from "./ContractContactValidation.Schema"

import {
  initialContractContact,
  ContractUpdateContactAdminRequestExtended,
} from "../../../../../domain/portal/admin/contracts/Contracts.Models"
import {
  getContactByContractId,
  getContractManagementById,
  updateContactByContractId,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ContractUpdateContactAdminRequest } from "../../../../../data/generated-sources/openapi"

import ContractContactDataFields from "./ContractContactDataFields"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../Shared.Utils"

export const ContractContactForm = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { id } = params
  const { t } = useTranslation("contracts-management")
  const [contactError, setContactError] = useState<AxiosErrorDataType>()

  const {
    data: contract,
    isLoading: isLoadingContract,
    isFetching: isFetchingContract,
    remove: removeContract,
  } = useQuery(["getContract"], () => getContractManagementById(id), {
    enabled: !!id,
    ...useQueryDefaultOptions,
    onError: setContactError,
  })

  const {
    data: contact,
    isLoading: isLoadingContact,
    isFetching: isFetchingContact,
    remove: contactRemove,
  } = useQuery(["getContact"], () => getContactByContractId(id), {
    enabled: !!id,
    ...useQueryDefaultOptions,
    onError: setContactError,
  })

  useEffect(() => {
    return () => {
      contactRemove()
      removeContract()
    }
  }, [contactRemove, removeContract])

  const { mutate: updateContact, isLoading: isUpdatingContact } = useMutation(
    ["updateContact"],
    (contactData: ContractUpdateContactAdminRequest) => updateContactByContractId(id, contactData),
    {
      onSuccess: () => history.push(`/management-contracts/${id}`),
      onError: setContactError,
    },
  )

  const handleContactUpdate = (contactData: ContractUpdateContactAdminRequestExtended) => {
    delete contactData.useContractName
    delete contactData.contractName
    const payload = contactData
    updateContact(payload)
  }

  if ((isLoadingContact && isFetchingContact) || (isLoadingContract && isFetchingContract)) return <FullScreenLoader />

  return (
    <Formik<ContractUpdateContactAdminRequestExtended>
      initialValues={{ ...initialContractContact, ...contact, contractName: contract?.name }}
      enableReinitialize
      validationSchema={contactSchema}
      onSubmit={handleContactUpdate}
    >
      {(props) => (
        <Form>
          <ErrorAlert
            scrollOnDisplay
            visible={!!contactError}
            message={t(`error-codes:${contactError?.response?.data?.code || contactError?.code || "OTHER"}`)}
          />
          <ContractContactDataFields {...props} />
          <FormUpdateActionsView
            isLoading={isLoadingContact || isUpdatingContact}
            dirty={props.dirty}
            isValid={props.isValid}
            buttonCtaLabel={t("contactForm.saveContact")}
            navigateBack={() => history.goBack()}
          />
        </Form>
      )}
    </Formik>
  )
}

import { StatusIndicator } from "../../../../uikit/indicator/StatusIndicator"

export const TaskListSyncIndicator = ({ syncStatus }: { syncStatus?: boolean }) => {
  switch (syncStatus) {
    case false:
      return <StatusIndicator color={"red"} />
    case true:
      return <StatusIndicator color={"green"} />
    default:
      return <StatusIndicator color={"grey"} />
  }
}

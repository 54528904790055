import { AreaEnum } from "../area/Area.Enum"
import { AreaContractsApiEnum } from "../Components.Enums"
import { AreaContractsEnum } from "../areaContracts/AreaContracts.Enum"
import { AreaContractColumnComparator } from "../areaContracts/AreaContractColumnComparator"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import AreaContractsTableComponent from "../areaContracts/AreaContractsTable.Component"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const AREA_CONTRACTS_LIST_COMPONENT_CONFIG = {
  areaContracts: {
    name: "areaContracts",
    header: {
      title: "contracts:list.title",
      showButton: true,
      showFilter: false,
      redirectUrl: () => `${location.pathname}/contract/create`,
    },
    columnEnums: AreaContractsEnum,
    defaultRowsPerPage: 5,
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: AreaEnum.NAME,
      direction: "desc",
    },
    columnComparator: AreaContractColumnComparator,
    tableHeaders: [
      {
        column: AreaContractsEnum.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
      {
        column: AreaContractsEnum.NAME,
        label: "shared:label.name",
        width: "20%",
        orderable: true,
      },
      {
        column: AreaContractsEnum.PRODUCT,
        label: "contracts:field.label.product",
        width: "30%",
        orderable: true,
      },
      {
        column: AreaContractsEnum.START_DATE,
        label: "area:label.startDate",
        width: "20%",
        orderable: true,
      },
      {
        column: AreaContractsEnum.END_DATE,
        label: "contracts:field.label.end-date",
        width: "20%",
        orderable: true,
      },
    ],
    renderTableRows: (item: ContractAdminResponse) => (
      <AreaContractsTableComponent itemConfig={item} key={getUniqueId()} />
    ),
  },
}

export const AREA_CONTRACTS_API_CONFIG = {
  areaContracts: AreaContractsApiEnum,
}

import { DialogContentText, Paper, CircularProgress, Stack } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import { isEmpty } from "lodash-es"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { BuildingState } from "../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../domain/Domain.Model"
import {
  adminDeactivateBuildingById,
  adminDeleteBuilding,
  adminGetBuildingById,
  adminGetContractForBuilding,
} from "../../../../domain/portal/admin/buildings/Buildings.Repository"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import DateUtils from "../../../../services/utils/DateUtils"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { COMPONENTS_CONFIG } from "../sharedComponentsConfig/Components.Config"
import SharedListComponent from "../sharedListComponet/SharedList.Component"

import { StatusView } from "../../../../uikit/label/StatusView"
import { DeactivateIcon, RemoveIcon } from "../../../../uikit/Shared.Icon"
import { PrimaryEditButton } from "../../../../uikit/button/PrimaryEditButton"
import { ConfirmDialog } from "../../../../uikit/confirmDialog/ConfirmDialog"
import { SingleLineDatePicker } from "../../../../uikit/input/SingleLineDatePicker"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../uikit/Shared.Alert"
import { BuildingDetailsData } from "./fragments/BuildingDetailsData"

export const BuildingDetails = ({ match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { buildingId } = params
  const { t } = useTranslation("buildings")
  const history = useHistory()
  const [deactivateDate, setDeactivateDate] = useState<number>(0)

  const navigateToBuildingList = () => {
    history.push("/buildings")
  }

  const navigateToUpdateBuilding = (buildingId: string) => {
    history.push(`/buildings/${buildingId}/update`)
  }

  const navigateToCreate = (buildingId: string) => {
    history.push(`/buildings/${buildingId}/utility-unit/create`)
  }

  const navigateToMeterCreate = (buildingId: string) => {
    history.push(`/buildings/${buildingId}/meters/create`)
  }

  const {
    data: building,
    error,
    isFetching: isFetchingBuildings,
    isError: isBuildingError,
    refetch: refetchBuilding,
    remove,
  } = useQuery(["getBuilding"], () => adminGetBuildingById(buildingId), {
    enabled: !!buildingId,
  })

  const {
    data: contracts,
    isFetching: isFetchingContracts,
    isError: isContractError,
    error: contractError,
    remove: removeContracts,
  } = useQuery(["getBuildingContracts"], () => adminGetContractForBuilding(buildingId), {
    enabled: !!buildingId,
  })

  const {
    mutate: deleteBuilding,
    isLoading: isDeleting,
    isError: isDeletingError,
    error: deletingError,
  } = useMutation(["deleteBuilding"], () => adminDeleteBuilding(buildingId), {
    onSuccess: navigateToBuildingList,
  })

  const {
    mutate: deactivateBuilding,
    isLoading: isDeactivating,
    isError: isDeacteError,
    error: deacteError,
    isSuccess: isDeactivateSuccess,
  } = useMutation(
    ["deactivateBuilding"],
    () => adminDeactivateBuildingById(buildingId, DateUtils.getDeFormatDate(deactivateDate)),
    {
      onSuccess: () => refetchBuilding(),
    },
  )

  useEffect(() => {
    return () => {
      remove()
      removeContracts()
    }
  }, [remove, removeContracts])

  const hasContractsAssigned = !isEmpty(contracts)
  const isDisabled = hasContractsAssigned || building?.activeState === "INACTIVE"
  const errorMsg = (error || contractError || deletingError || deacteError) as AxiosErrorDataType

  if (isFetchingBuildings || isFetchingContracts) return <CircularProgress />

  return (
    <>
      {building && !isFetchingBuildings && (
        <>
          <ErrorAlert
            scrollOnDisplay
            visible={isBuildingError || isContractError || isDeletingError || isDeacteError}
            message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
          />
          <OptionalSuccessAlert show={isDeactivateSuccess} message={t("details.action.deactivate.success")} />

          <Paper>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={4}>
              <StatusView statusType={StatusType[building.activeState]} />
              {building.activeState !== BuildingState.INACTIVE && (
                <PrimaryEditButton onClick={() => navigateToUpdateBuilding(building.id)} />
              )}
            </Stack>

            <BuildingDetailsData {...building} />

            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"flex-start"}>
              <ConfirmDialog
                actionButtonText={t("details.action.delete")}
                actionButtonStartIcon={<RemoveIcon fontSize="large" />}
                actionButtonLoading={isDeleting}
                actionButtonDisabled={isDisabled}
                onConfirm={deleteBuilding}
                confirmButtonText={t("details.action.delete")}
                dialogTitle={t("details.action.dialogTitle")}
                dialogBodyText={t("details.delete.dialogText")}
                actionButtonStyle={{ mr: 4 }}
              />
              <ConfirmDialog
                actionButtonText={t("details.action.deactivate")}
                actionButtonStartIcon={<DeactivateIcon fontSize="large" />}
                actionButtonLoading={isDeactivating}
                actionButtonDisabled={isDisabled}
                confirmButtonDisabled={!deactivateDate}
                confirmButtonText={t("details.action.deactivate")}
                dialogTitle={t("details.action.dialogTitle")}
                dialogBodyText={t("details.deactivate.dialogText")}
                dialogBody={
                  <>
                    <DialogContentText>{t("details.deactivate.dialogText")}</DialogContentText>
                    <SingleLineDatePicker
                      required
                      name="from"
                      label={t("form.field.billableUntilDate")}
                      value={deactivateDate}
                      onChange={setDeactivateDate}
                      minDate={new Date()}
                    />
                  </>
                }
                onConfirm={deactivateBuilding}
              />
            </Stack>
          </Paper>
          <SharedListComponent configNew={COMPONENTS_CONFIG.buildingContracts} queryParam={building.id} />
          <SharedListComponent
            configNew={COMPONENTS_CONFIG.buildingUtilityUnits}
            onHeaderBtnClick={() => navigateToCreate(building.id)}
            queryParam={building.id}
          />
          <SharedListComponent
            queryParam={building.id}
            configNew={COMPONENTS_CONFIG.buildingMetersPoints}
            onHeaderBtnClick={() => navigateToMeterCreate(building.id)}
          />
        </>
      )}
    </>
  )
}

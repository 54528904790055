import TextField, { StandardTextFieldProps } from "@mui/material/TextField"

export const SingleLineTextField = (props: StandardTextFieldProps) => {
  const { id, helperText, InputProps, sx } = props

  return (
    <TextField
      {...props}
      id={id ? `text-field-${id}` : undefined}
      InputLabelProps={{
        id: id ? `text-field-label-${id}` : undefined,
      }}
      sx={{ width: "100%", fontSize: "14px", fontWeight: "300", ...sx }}
      error={helperText !== undefined}
      helperText={helperText}
      variant="standard"
      InputProps={InputProps}
    />
  )
}

export const FormSingleLineTextField = (props: StandardTextFieldProps) => {
  const { id, helperText } = props

  return (
    <TextField
      {...props}
      id={id ? `text-field-${id}` : undefined}
      InputLabelProps={{
        id: id ? `text-field-label-${id}` : undefined,
        style: { fontSize: 16 },
      }}
      sx={{ width: "100%", fontSize: "14px", fontWeight: "300" }}
      error={helperText !== undefined}
      helperText={helperText}
      variant="standard"
      InputProps={{ style: { fontSize: 16 } }}
    />
  )
}

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
  styled,
} from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"

import { PrimaryOutlinedButton, PrimaryButton } from "../../../../uikit/button"
import { FormatStringAsNumber, formatMoneyLabel } from "../../../../domain/Domain.Formatters"
import { appThemeGrey10, appThemeGrey200 } from "../../../Shared.Theme"
import { AbortIcon } from "../../../../uikit/Shared.Icon"
import {
  ZevAllParticipantBilling,
  ZevIndividualParticipantBilling,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import {
  ProductName,
  ZevAdminAllParticipantBilling,
  ZevAdminIndividualParticipantBilling,
} from "../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { AxiosErrorDataType, isAdminPortal, isManagerPortal } from "../../../Shared.Utils"
import { ConfirmReloadBillingDialog } from "../../../../uikit/confirmDialog/ConfirmReloadBillingDialog"
import { useMutation } from "@tanstack/react-query"
import { customerReloadZevParticipantBillingData } from "../../../../domain/portal/manager/billings/Billings.Repository"
import { adminReloadZevParticipantBillingData } from "../../../../domain/portal/admin/billings/Billings.Repository"
import { useHistory, useParams } from "react-router-dom"
import { AdminPortalRouteParams } from "../../admin/AdminPortal.Routes"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"

const TotalConsumptionStack = styled(Stack)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(14),
  backgroundColor: appThemeGrey10,
  padding: theme.spacing(3, 6),
  borderRadius: theme.spacing(1),
}))

const TotalText = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(13),
  fontWeight: 700,
  color: appThemeGrey200,
  paddingBottom: theme.spacing(1.5),
}))

const TotalAmount = styled(Typography)(({ theme }) => ({
  fontSize: theme.typography.pxToRem(23),
  color: theme.palette.text.primary,
  margin: 0,
  padding: 0,
}))

interface ZevBillingTotalSectionProps {
  isLoading: boolean
  onClickApprove: () => void
  billing:
    | ZevAllParticipantBilling
    | ZevIndividualParticipantBilling
    | ZevAdminAllParticipantBilling
    | ZevAdminIndividualParticipantBilling
}

export const ZevBillingTotalSection = ({ billing, isLoading, onClickApprove }: ZevBillingTotalSectionProps) => {
  const {
    id: billingId,
    totalCosts,
    totalConsumption,
    canCreateBilling,
    isBillingSuspended,
    hasCollection,
    productName,
    billingStatus,
  } = billing
  const { t } = useTranslation("settlements")
  const history = useHistory()
  const { id: contractId, areaId } = useParams<AdminPortalRouteParams>()
  const [showSapDialog, setShowSapDialog] = useState(false)
  const isDisabled = isLoading || isBillingSuspended || !canCreateBilling
  const { pathname } = window.location

  const sendToSAP = isManagerPortal() && productName?.includes(ProductName.ZEV_PREMIUM_PLUS) && hasCollection

  const handleConfirmApprove = () => {
    setShowSapDialog(false)
    onClickApprove()
  }

  const {
    mutate: reloadZevBillingData,
    isLoading: isReloadingZevData,
    error: reloadError,
  } = useMutation(
    ["reloadZevBillingData", billingId],
    () => {
      return isAdminPortal()
        ? adminReloadZevParticipantBillingData(billingId)
        : customerReloadZevParticipantBillingData(billingId)
    },
    {
      onSuccess: () => {
        isAdminPortal() ? history.push(`/management-contracts/${contractId}`) : history.push(`/${areaId}/billings`)
      },
    },
  )

  return (
    <>
      <ErrorAlert
        visible={!!reloadError}
        message={t(
          `error-codes:${
            (reloadError as AxiosErrorDataType)?.response?.data?.code ??
            (reloadError as AxiosErrorDataType)?.code ??
            "OTHER"
          }`,
        )}
      />
      <TotalConsumptionStack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
          <Box mr={10}>
            <TotalText>{t("billings-participant:detail.all.total.total.kwh")}</TotalText>
            <TotalAmount mt={1}>{FormatStringAsNumber(totalConsumption)}</TotalAmount>
          </Box>
          <Box>
            <TotalText>{t("billings-participant:detail.all.total.total")}</TotalText>
            <TotalAmount mt={1}>{formatMoneyLabel(totalCosts)}</TotalAmount>
          </Box>
        </Stack>
        <Stack flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
          {!pathname.includes("individual") && ["IN_PROGRESS", "IN_PROGRESS_REOPENED"].includes(billingStatus) && (
            <ConfirmReloadBillingDialog onConfirmClick={reloadZevBillingData} isLoading={isReloadingZevData} />
          )}
          {sendToSAP ? (
            <PrimaryButton
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={() => setShowSapDialog(true)}
              label={t("billingEdit.confirm.and.send.to.ckw")}
            />
          ) : (
            <PrimaryButton
              disabled={isDisabled}
              isLoading={isLoading}
              onClick={handleConfirmApprove}
              label={t("billingEdit.create.billing")}
            />
          )}
        </Stack>

        <Dialog open={showSapDialog}>
          <IconButton
            style={{ position: "absolute", top: "10px", right: "10px" }}
            onClick={() => setShowSapDialog(false)}
          >
            <AbortIcon />
          </IconButton>
          <DialogTitle style={{ textAlign: "center" }}>{t("billingEdit.confirmation.dialog.title")}</DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" style={{ textAlign: "center" }}>
              {t("billingEdit.confirmation.dialog.body")}
            </DialogContentText>
          </DialogContent>
          <DialogActions
            sx={{
              "&.MuiDialogActions-root": {
                alignItems: "center",
                justifyContent: "center",
              },
            }}
          >
            <PrimaryOutlinedButton
              isLoading={false}
              onClick={() => setShowSapDialog(false)}
              label={t("billingEdit.confirmation.dialog.action.cancel")}
              sx={{ mr: 3.5 }}
            />
            <PrimaryButton
              disabled={false}
              isLoading={false}
              onClick={handleConfirmApprove}
              label={t("billingEdit.confirmation.dialog.action.confirm")}
            />
          </DialogActions>
        </Dialog>
      </TotalConsumptionStack>
    </>
  )
}

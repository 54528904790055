import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { getContractFeesByContractId } from "../../../../../../domain/portal/manager/settings/Settings.Repository"
import { useQueryDefaultOptions } from "../../../../../Shared.Utils"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { DataGridTable } from "../../../../../../uikit/dataGridTable"
import { CostPosition } from "../../../../../../data/generated-sources/openapi"
import { FeesTableConfig } from "./FeesTableConfig"
import { useTranslation } from "react-i18next"
import { Box, Stack, Typography } from "@mui/material"
import { appThemeGrey10 } from "../../../../../Shared.Theme"
import { getUniqueId } from "../../../../../../services/utils/SharedUtils"

const FeesWrapper = ({ contractId }: { contractId: string }) => {
  const { t } = useTranslation()

  const {
    data: feesData,
    isLoading: feesLoading,
    isError: feesIsError,
    remove: removeFees,
    refetch: refetchFees,
  } = useQuery(["getContract", contractId], () => getContractFeesByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
  })

  useEffect(() => {
    refetchFees()
  }, [contractId])

  useEffect(() => {
    return () => removeFees()
  }, [removeFees])

  const renderTotalServiceFee = () => {
    if (feesIsError || feesLoading) return <></>

    return (
      <Box
        sx={{
          width: "100%",
          minHeight: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-around",
          alignItems: "flex-end",
          marginTop: "40px",
          padding: "30px 0",
          backgroundColor: appThemeGrey10,
        }}
      >
        <Typography variant={"subtitle2"} sx={{ mr: 3 }}>
          {t("fees:label.servicePerMonth")}
        </Typography>
        <Typography variant={"h5"} sx={{ mr: 3 }}>
          CHF {feesData?.totalMonthlyFee}
        </Typography>
      </Box>
    )
  }

  return (
    <>
      <Stack pt={5} px={3}>
        <ErrorAlert visible={feesIsError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
        <DataGridTable<CostPosition>
          rows={feesData?.costPositions ?? []}
          columns={FeesTableConfig(t)}
          getRowId={() => getUniqueId()}
          loading={feesLoading}
          hideFooter={true}
          rowHeight={80}
          defaultPageSize={100}
          sx={{
            ".MuiDataGrid-cell": {
              border: "none",
            },
          }}
        />
        {renderTotalServiceFee()}
      </Stack>
    </>
  )
}

export default FeesWrapper

import { I18nNamespace } from "../../../App.i18n"

export const BuildingsI18n: I18nNamespace = {
  name: "buildings",
  de: {
    "list.title": "ANSCHLUSSOBJEKT",
    "list.label.status": "Status",
    "list.label.building-object": "Anschlussobjekt",
    "list.bildings": "Anschlussobjekte",
    "list.label.address": "Adresse",
    "list.label.street": "Strasse",
    "list.label.city": "Ort",
    "field.label.zev": "Zev",
    "list.navigation.update": "AKTUALISIEREN",
    "list.action.delete.success": "Anschlussobjekt wurde erfolgreich gelöscht",
    "details.title": "ANSCHLUSSOBJEKT DETAIL",
    "details.action.delete": "Anschlussobjekt löschen",
    "details.action.dialogTitle": "Sind Sie sich sicher?",
    "details.delete.dialogText": "Wollen sie das Anschlussobjekt inkl. alle dazugehörigen Nutzeinheiten löschen?",
    "details.deactivate.dialogText":
      "Wollen sie das Anschlussobjekt inkl. alle dazugehörigen Nutzeinheiten deaktivieren?",
    "details.action.deactivate": "Anschlussobjekt deaktivieren",
    "details.deactivate.dialog.title": "Anschlussobjekt Deaktivieren",
    "details.action.deactivate.success": "Anschlussobjekt wurde erfolgreich deaktiviert",
    "details.consumption.title": "VERBRAUCHSSTELLE",
    "form.field.billableUntilDate": "Abrechnungsfähig bis",
    "details.consumptionpoint.list.status": "STATUS",
    "details.consumptionpoint.list.name": "VERBRAUCHSSTELLE",
    "details.consumptionpoint.list.type": "TYP",
    "details.consumptionpoint.list.powerMeterType": "ZÄHLERAUSFÜHRUNG",
    "details.consumptionpoint.list.subscriber": "TEILNEHMER",
    "form.subtitle.address": "Adresse",
    "form.field.buildingObject": "Anschlussobjekt",
    "form.field.buildingName": "Name",
    "form.field.addressStreet": "Strasse",
    "form.field.addressHouseNumber": "Nummer",
    "form.field.addressCity": "Ort",
    "form.field.addressPostCode": "PLZ",
    "form.subtitle.zev": "Zev",
    "form.field.zev": "Zev",
    "field.label.area": "Areal",
    "form.alert.success": "Die Änderungen am Anschlussobjekt wurde erfolgreich gespeichert",
    "create.form.action.cta": "Anschlussobjekt speichern",
    "update.form.action.cta": "Anschlussobjekt speichern",
    "update.title": "ANSCHLUSSOBJEKT BEARBEITEN",
    "create.alert.success": "Das Anschlussobjekt wurde erfolgreich angelegt. Gehen Sie zum Anschlussobjekt",
    "type.RESIDENCE": "Wohneinheit",
    "type.BUSINESS": "Gewerbeeinheit",
    "type.GENERAL_SUPPLY": "Allgemeine Versorgung",
    "type.HEATING_PUMP": "Wärmepumpe",
    "label.searchBuildings": "Anschlussobjekte suchen und hinzufügen",
    "label.saveBuildings": "Anschlussobjekte speichern",
    "label.noBuildings": "Keine Anschlussobjekt",
    "breadcrumb.create": "MESSPUNKT ERSTELLEN",
    "breadcrumb.utilityUnit.create": "NUTZEINHEIT ERSTELLEN",
    "breadcrumb.meter.create": "ZÄHLPUNKT ERSTELLEN",
  },
  en: {
    "list.title": "BUILDINGS",
    "list.label.status": "Status",
    "list.label.building-object": "Building object",
    "list.bildings": "Buildings",
    "list.label.address": "Address",
    "list.label.street": "Street",
    "list.label.city": "City",
    "field.label.zev": "Zev",
    "field.label.area": "Area",
    "list.navigation.update": "UPDATE",
    "list.action.delete.success": "Connection object was deleted successfully",
    "details.title": "BUILDING DETAIL",
    "details.action.delete": "Delete building object",
    "details.action.dialogTitle": "Are you sure?",
    "details.action.dialogText": "Do you want to delete the connection object including all associated units?",
    "details.action.deactivate": "Deactivate building object",
    "details.deactivate.dialog.title": "Deactivate Building",
    "details.action.deactivate.success": "Connection object has been deactivated successfully",
    "details.consumption.title": "CONSUMPTION POINT",
    "form.field.billableUntilDate": "Billable until date",
    "details.consumptionpoint.list.status": "STATUS",
    "details.consumptionpoint.list.name": "CONSUMPTION POINT",
    "details.consumptionpoint.list.type": "Type",
    "details.consumptionpoint.list.powerMeterType": "Meter code",
    "details.consumptionpoint.list.subscriber": "Subscriber",
    "form.subtitle.address": "Addresss",
    "form.field.buildingObject": "Building object",
    "form.field.buildingName": "Name",
    "form.field.addressStreet": "Street",
    "form.field.addressHouseNumber": "Number",
    "form.field.addressCity": "City",
    "form.field.addressPostCode": "Post code",
    "form.subtitle.zev": "Zev",
    "form.field.zev": "Zev",
    "form.alert.success": "The changes to the connection object have been saved successfully",
    "create.form.action.cta": "Anschlussobjekt erstellen",
    "update.form.action.cta": "Save building object",
    "update.title": "UPDATE BUILDING",
    "create.alert.success": "The connection object was created successfully. Go to the connection object",
    "type.RESIDENCE": "Residence",
    "type.BUSINESS": "Business",
    "type.GENERAL_SUPPLY": "General supply",
    "type.HEATING_PUMP": "Heating pump",
    "label.searchBuildings": "Search buildings and add",
    "label.saveBuildings": "Save buildings",
    "label.noBuildings": "Save buildings",
    "breadcrumb.create": "CREATE",
    "breadcrumb.utilityUnit.create": "CREATE UTILITY UNIT",
    "breadcrumb.meter.create": "CREATE METER",
  },
}

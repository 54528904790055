import { Cell, Pie, PieChart } from "recharts"
import { COLORS } from "./chart.config"
import { PieChartComponentPropsInterface, PieDataInterface } from "../Analise.Model"
import { LegendIconWrapper } from "../components/Legend"
import { Box } from "@mui/material"
import { formatNumber } from "../../../../../domain/Domain.Formatters"

const PieChartComponent = (props: PieChartComponentPropsInterface) => {
  const { data, total, isLoading, legendIconConfig } = props

  if (isLoading || !Number(total) || !data.length) return null

  return (
    <div className="ckw-pie-chart-wrapper">
      {total ? (
        <span
          style={{
            position: "absolute",
            width: "100px",
            top: "125px",
            left: "50px",
            textAlign: "center",
          }}
        >
          <p style={{ margin: 0, fontWeight: 700 }}>{formatNumber(Number(total))}</p>
          <p style={{ marginBottom: 0, marginTop: 10, fontSize: "16px" }}>kWh</p>
        </span>
      ) : null}

      <Box display="flex">
        <PieChart width={200} height={300}>
          <Pie
            startAngle={-270}
            data={data}
            innerRadius={60}
            outerRadius={100}
            fill="#8884d8"
            paddingAngle={1}
            dataKey="value"
            isAnimationActive={true}
          >
            {data.map((entry: PieDataInterface, index: number) => (
              <Cell key={`cell-${index}`} fill={entry.color ?? COLORS[index]} />
            ))}
          </Pie>
        </PieChart>
        <Box ml={3} display="flex" flexDirection="column" justifyContent="center" alignContent="center">
          {total ? <LegendIconWrapper data={data} config={legendIconConfig} /> : null}
        </Box>
      </Box>
    </div>
  )
}

export default PieChartComponent

import { apiHeaders } from "../../../../Domain.Calls"
import { ZevAdminAllParticipantBilling, ProductName } from "./BillingsParticipant.Model"
import { DOMAIN_DEPENDENCIES } from "../../../../../app/App.Config"
import {
  AllParticipantBillingPricesUpdateAdminRequest,
  AllParticipantsBillingState,
  BillDetails,
} from "../../../../../data/generated-sources/openapi"

export const getProductName = async (contractId: string): Promise<ProductName | undefined> => {
  const { data: contractData } = await DOMAIN_DEPENDENCIES.adminContractApi.adminGetContractById(
    contractId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

  let productName = undefined

  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_COMFORT.toLowerCase())) {
    productName = ProductName.ZEV_COMFORT
    return productName
  }
  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_PREMIUM_PLUS.toLowerCase())) {
    productName = ProductName.ZEV_PREMIUM_PLUS
    return productName
  }

  if (contractData?.product?.name?.toLowerCase()?.includes(ProductName.ZEV_PREMIUM.toLowerCase())) {
    productName = ProductName.ZEV_PREMIUM
    return productName
  }

  return productName
}

export const getAllParticipantBillingDetailsById = async (
  billingId: string,
  contractId: string,
): Promise<ZevAdminAllParticipantBilling> => {
  const { data: billing } =
    await DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.getAdminAllParticipantBillingDetailsById(
      billingId,
      apiHeaders(DOMAIN_DEPENDENCIES),
    )
  const productName = await getProductName(contractId)

  // canCreateBilling: boolean
  // logic from old ZevMapper
  const utilityUnitIds = billing.bills.map((pm: BillDetails) => pm.utilityUnitId)
  const utilityUnitData = billing.bills.map((bill) => bill.utilityUnit)
  let canCreateBilling = true

  if (!billing || !billing?.bills?.length || !utilityUnitIds.length) {
    canCreateBilling = false
  }

  if (
    !utilityUnitIds.some(
      (utilityUnitId: string) => !!utilityUnitData.filter((cp) => cp?.id === utilityUnitId)[0]?.participations?.length,
    )
  ) {
    canCreateBilling = false
  }

  const isBillingSuspended = billing.billingStatus === AllParticipantsBillingState.SUSPENDED

  return { ...billing, canCreateBilling, isBillingSuspended, productName }
}

export const approveAllParticipantBilling = (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.putAdminAllParticipantBillingApproveStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const updateAllParticipantBillingPricesById = async (
  billingId: string,
  allParticipantBillingPricesUpdateCustomerRequest: AllParticipantBillingPricesUpdateAdminRequest,
) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.updateAdminAllParticipantBillingPricesById(
    billingId,
    allParticipantBillingPricesUpdateCustomerRequest,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const suspendZevAllParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.putAdminAllParticipantBillingSuspendStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const unSuspendZevAllParticipantBilling = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.putAdminAllParticipantBillingUnSuspendStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const reopenAllParticipantBilling = async (billingId: string) => {
  return DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.putAdminAllParticipantBillingReopenStateById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const sendSapAllParticipantBillings = async (billingId: string) =>
  DOMAIN_DEPENDENCIES.adminAllParticipantBillingApi.sapSendAdminAllParticipantBillingById(
    billingId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material"
import { AbortIcon } from "../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { updateTaskCommentById } from "../../../../../domain/portal/admin/tasks/Tasks.Repository"
import { useMutation } from "@tanstack/react-query"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { Form, Formik } from "formik"
import { PrimaryButton } from "../../../../../uikit/button"
import { useTranslation } from "react-i18next"
import { commentSchema } from "./CommentValidation.Schema"

interface TaskListNoteUpdateProps {
  id: string
  open: boolean
  comment: string
  closeDialog: () => void
  onUpdateSuccess: () => void
}

export const TaskCommentUpdateDialog = ({
  id,
  comment,
  open,
  closeDialog,
  onUpdateSuccess,
}: TaskListNoteUpdateProps) => {
  const { t } = useTranslation("tasklist")

  const {
    mutate: updateComment,
    isLoading: isUpdating,
    error: updateError,
    isError,
  } = useMutation(["updateComment"], (comment: string) => updateTaskCommentById(id, { comment }), {
    onSuccess: onUpdateSuccess,
  })

  return (
    <Dialog open={open} onClose={closeDialog} fullWidth maxWidth="md" data-testid="TaskCommentUpdateDialog">
      <IconButton
        aria-label="close"
        onClick={closeDialog}
        sx={{
          top: 35,
          right: 35,
          position: "absolute",
        }}
      >
        <AbortIcon />
      </IconButton>
      <ErrorAlert
        visible={isError}
        message={t(`error-codes:${(updateError as AxiosErrorDataType)?.response?.data?.code ?? "OTHER"}`)}
      />
      <Formik<{ comment: string }>
        initialValues={{ comment: comment || "" }}
        enableReinitialize
        onSubmit={(value) => updateComment(value.comment)}
        validationSchema={commentSchema}
        data-testid="TaskCommentUpdateForm"
      >
        {({ values, dirty, isValid, errors, handleSubmit, setFieldValue }) => (
          <Form onSubmit={handleSubmit}>
            <DialogTitle data-testid="TaskCommentUpdateForm-DialogTitle">{t("dialog.title.updateNotes")}</DialogTitle>
            <DialogContent>
              <Box py={4}>
                <TextField
                  multiline
                  autoFocus
                  type="text"
                  minRows={3}
                  maxRows={15}
                  name="comment"
                  variant="outlined"
                  value={values.comment}
                  label={t("shared:label.comment")}
                  onChange={(event) => setFieldValue("comment", event.target.value)}
                  helperText={errors.comment ? errors.comment : undefined}
                  sx={{ width: "100%", lineHeight: 1.6, ".MuiFormHelperText-root": { color: "red" } }}
                  data-testid="TaskCommentUpdateForm-InputField"
                />
              </Box>
            </DialogContent>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={closeDialog}
                disabled={isUpdating}
                data-testid="TaskCommentUpdateForm-CloseBtn"
              >
                {t("shared:form.action.cancel")}
              </Button>
              <PrimaryButton
                type="submit"
                disabled={isUpdating || !dirty || !isValid}
                isLoading={isUpdating}
                label={t("shared:form.action.save")}
                data-testid="TaskCommentUpdateForm-SubmitBtn"
              />
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

import DateUtils from "./DateUtils"
import { MeterReadingsCalcResolution } from "../../data/generated-sources/openapi"
import { TFunction } from "i18next"
import { formatLocale } from "../../uikit/Shared.Formatters"
import { LineChartDataPoint } from "../../app/portal/shared/analiyse/Analise.Model"

export const getStartDate = (date: number, selectedDateRange: MeterReadingsCalcResolution): string | undefined => {
  switch (selectedDateRange) {
    case MeterReadingsCalcResolution.DAY:
      return DateUtils.getFormattedDay(date)
    case MeterReadingsCalcResolution.WEEK:
      return DateUtils.getFirstDayOfWeek(date)
    case MeterReadingsCalcResolution.MONTH:
      return DateUtils.getFirstDayOfMonth(date)
    case MeterReadingsCalcResolution.YEAR:
      return DateUtils.getFirstDayOfYear(date)
  }
}

export const getEndDate = (date: number, selectedDateRange: MeterReadingsCalcResolution): string | undefined => {
  switch (selectedDateRange) {
    case MeterReadingsCalcResolution.DAY:
      return DateUtils.getFormattedDay(date)
    case MeterReadingsCalcResolution.WEEK:
      return DateUtils.getLastDayOfWeek(date)
    case MeterReadingsCalcResolution.MONTH:
      return DateUtils.getLastDayOfMonth(date)
    case MeterReadingsCalcResolution.YEAR:
      return DateUtils.getLastDayOfYear(date)
  }
}

export const formatDateRange = (selectedDateRange: MeterReadingsCalcResolution): string => {
  switch (selectedDateRange) {
    case MeterReadingsCalcResolution.DAY:
      return MeterReadingsCalcResolution.MINUTE as string
    case MeterReadingsCalcResolution.WEEK:
    case MeterReadingsCalcResolution.MONTH:
      return MeterReadingsCalcResolution.DAY as string
    default:
      return MeterReadingsCalcResolution.MONTH as string
  }
}

export const formatXAxisLabel = (meterReadingDateRange: MeterReadingsCalcResolution, t: TFunction) => {
  switch (meterReadingDateRange) {
    case MeterReadingsCalcResolution.DAY:
      return t("axis.date")
    case MeterReadingsCalcResolution.WEEK:
      return t("axis.day")
    case MeterReadingsCalcResolution.MONTH:
      return t("axis.day")
    case MeterReadingsCalcResolution.YEAR:
      return t("axis.month")
  }
}

export const getTickFormatter = (
  date: string,
  meterReadingDateRange: MeterReadingsCalcResolution | undefined,
  dateLocale: Locale,
  isMeterChart = false,
): string => {
  if (!meterReadingDateRange) return formatLocale(new Date(date), "HH:mm", dateLocale)
  switch (meterReadingDateRange) {
    case MeterReadingsCalcResolution.YEAR:
      return formatLocale(new Date(date), "LLL", dateLocale)
    case MeterReadingsCalcResolution.MONTH:
      return `${DateUtils.getDeFormatDate(new Date(date), "DD. MM.")}`
    case MeterReadingsCalcResolution.WEEK:
      return isMeterChart
        ? `${DateUtils.getDeFormatDate(new Date(date), "DD. MM.")}`
        : formatLocale(new Date(date), "EEEEEE", dateLocale)
    default:
      return formatLocale(new Date(date), "HH:mm", dateLocale)
  }
}

export const getYDomainForMeterChart = (
  data: LineChartDataPoint[],
  meterReadingDateRange: MeterReadingsCalcResolution | undefined,
) => {
  const highestValue = Math.max(
    ...data.map((item: LineChartDataPoint) => (item?.consumptionPointCount ? Number(item.consumptionPointCount) : 0)),
  )
  if (meterReadingDateRange && meterReadingDateRange === MeterReadingsCalcResolution.YEAR) {
    return [0, highestValue + 200]
  }
  if (meterReadingDateRange && meterReadingDateRange === MeterReadingsCalcResolution.DAY) {
    return [0, 1]
  }
  return [0, 96]
}

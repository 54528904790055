import { I18nNamespace } from "../../../App.i18n"

export const ManagerUtilityUnitI18n: I18nNamespace = {
  name: "manager-utility-unit",
  de: {
    "table.header.billNumber": "Rg-Nr.",
    "table.header.participant": "Teilnehmer",
    "table.header.sum": "Summe",
    "table.header.status": "Status",
    "label.newParticipantFrom": "Neuer Teilnehmer ab",
    "label.moveOutParticipantFrom": "Teilnehmer Auszug am",
  },
  en: {
    "table.header.billNumber": "Bill No.",
    "table.header.participant": "Participant",
    "table.header.sum": "Sum",
    "table.header.status": "Status",
    "label.newParticipantFrom": "New Particiapnt from",
  },
}

import { useEffect, useState } from "react"
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"

import DateUtils from "../../../../../../services/utils/DateUtils"
import { apiFormattedDateToTimestamp } from "../../../../../../domain/Domain.Formatters"
import { customerUtilityParticipationChangeMoveInDate } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { validateUtilityUnitParticipantMoveIn } from "../../../../admin/utilityUnit/participations/UtilityUnitParticipantMoveInDateForm.Validation"

import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ParticipationDialogPros } from "./UtilityUnitParticipations"
import { PrimaryButton } from "../../../../../../uikit/button"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export const EditParticipantMoveInDate = ({
  showDialog,
  participation,
  onUpdateSuccess,
  onClose,
}: ParticipationDialogPros) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [updateError, setUpdateError] = useState<AxiosErrorDataType>()

  useEffect(() => {
    return () => {
      setUpdateError(undefined)
    }
  }, [setUpdateError])

  const { mutate: changeMoveInDate, isLoading: isMovingOut } = useMutation(
    ["changeMoveInDate"],
    (moveInDate: number) =>
      customerUtilityParticipationChangeMoveInDate(participation?.utilityUnitId || "", participation?.id, moveInDate),
    {
      onSuccess: onUpdateSuccess,
      onError: setUpdateError,
    },
  )
  const onConfirmMoveIn = ({ moveInDate }: { moveInDate: number }) => changeMoveInDate(moveInDate)

  return (
    <Dialog open={showDialog} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("dialog.editMoveIn")}</DialogTitle>
      <ErrorAlert
        scrollOnDisplay
        visible={!!updateError}
        message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
      />
      <Formik<{ moveInDate: number }>
        initialValues={{ moveInDate: apiFormattedDateToTimestamp(participation?.moveInDate) ?? 0 }}
        enableReinitialize
        onSubmit={onConfirmMoveIn}
        validate={(values) =>
          validateUtilityUnitParticipantMoveIn(
            DateUtils.getDeFormatDate(values.moveInDate),
            participation?.moveOutDate || "",
            participation?.prevParticipantMoveOut || "",
            t,
          )
        }
      >
        {({ values, handleBlur, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText fontWeight={700}>{t("dialog.selectMoveIn")}</DialogContentText>
                <SingleLineDatePicker
                  required
                  name="moveInDate"
                  label=""
                  value={values.moveInDate}
                  helperText={errors.moveInDate}
                  minDate={
                    participation?.prevParticipantMoveOut
                      ? DateUtils.addDateDays(participation?.prevParticipantMoveOut, 1)
                      : undefined
                  }
                  maxDate={
                    participation?.moveOutDate ? DateUtils.substructDateDays(participation?.moveOutDate, 1) : undefined
                  }
                  onChange={(value) => setFieldValue("moveInDate", value)}
                  onBlur={handleBlur}
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={onClose} disabled={isMovingOut}>
                  {t("shared:form.action.cancel")}
                </Button>
                <PrimaryButton
                  type="submit"
                  disabled={isMovingOut}
                  isLoading={isMovingOut}
                  label={t("shared:form.action.save")}
                />
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

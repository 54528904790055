import { Box, IconButton, InputAdornment, Typography } from "@mui/material"
import { useState } from "react"
import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { Redirect } from "react-router"

import { SmallPaddedBox, SmallPaddedHorizontalBox } from "../../uikit/box/PaddedBox"
import { PrimaryButton } from "../../uikit/button"
import { TextButton } from "../../uikit/button/TextButton"
import { SingleLineTextField } from "../../uikit/input/SingleLineTextField"
import { ErrorAlert } from "../../uikit/Shared.Alert"
import { validationError } from "../Shared.Validation"

import { AuthLoginValues, AuthType } from "../../domain/auth/Auth.Model"
import { AuthState } from "./AuthLogin.Reducer"
import { validateAuth } from "./AuthLogin.Validation"
import { mapDispatchToProps } from "./AuthLoginAdmin.Connect"
import { AuthManagerContainerView } from "./views/AuthManagerContainerView"
import { PasswordOff, PasswordOn } from "../../uikit/Shared.Icon"

export interface AuthLoginManagerComponentProps extends AuthState, ReturnType<typeof mapDispatchToProps> {}

export const AuthLoginManagerComponent = (props: AuthLoginManagerComponentProps) => {
  const { t } = useTranslation("auth")
  const { viewState, login, navigateToForgottenPassword, previousPathname } = props
  const [showPassword, setShowPassword] = useState(false)

  if (viewState.domainResult) {
    const { lastSelectedArea, areas } = viewState.domainResult
    const zevId = lastSelectedArea ? lastSelectedArea.id : areas[0]?.id
    return <Redirect to={previousPathname ? previousPathname : `/${zevId}/dashboard`} />
  }

  return (
    <AuthManagerContainerView>
      <Typography align="center" variant="h5" gutterBottom id="login-title">
        {t("login.title")}
      </Typography>

      <Formik<AuthLoginValues>
        validateOnBlur
        initialValues={{
          authType: AuthType.MANAGER,
          emailAddress: "",
          password: "",
        }}
        onSubmit={(values) => login(values.emailAddress, values.password, values.authType)}
        validate={(values) => validateAuth(values, t)}
      >
        {({ values, touched, errors, handleChange, handleBlur, handleSubmit, isValid }) => (
          <Form onSubmit={handleSubmit}>
            {viewState.domainError && (
              <>
                {viewState.domainError.code === "OTHER" || viewState.domainError.code === "GENERIC_FATAL_ERROR" ? (
                  <ErrorAlert message={t("login.error.tryAgain")} />
                ) : (
                  <ErrorAlert message={viewState.domainError.message} />
                )}
              </>
            )}
            <SmallPaddedBox>
              <SingleLineTextField
                id="emailAddress"
                autoFocus={true}
                name="emailAddress"
                type="emailAddress"
                label={t("forgotten-password.field.email")}
                helperText={validationError(errors.emailAddress, touched.emailAddress)}
                value={values.emailAddress}
                onChange={handleChange}
                onBlur={handleBlur}
                sx={{
                  ".MuiInput-input": {
                    "&:-internal-autofill-selected": {
                      "-webkit-box-shadow": "0 0 0 30px white inset",
                    },
                  },
                }}
              />
            </SmallPaddedBox>
            <SmallPaddedHorizontalBox pb={4}>
              <SingleLineTextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                label={t("login.field.password")}
                helperText={validationError(errors.password, touched.password)}
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((show) => !show)}
                      >
                        {showPassword ? <PasswordOn /> : <PasswordOff />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                sx={{
                  ".MuiInput-input": {
                    "&:-internal-autofill-selected": {
                      "-webkit-box-shadow": "0 0 0 30px white inset",
                    },
                  },
                }}
              />
            </SmallPaddedHorizontalBox>
            <Box m="auto" display="flex" flexDirection="column" justifyContent="center" alignItems="center">
              <PrimaryButton
                id="login-cta"
                disabled={!isValid || viewState.isLoading}
                isLoading={viewState.isLoading}
                label={t("login.action.cta")}
                type="submit"
              />
              <TextButton
                id="forgotten-password-cta"
                label={t("login.action.forgottenPassword")}
                onClick={navigateToForgottenPassword}
              />
            </Box>
          </Form>
        )}
      </Formik>
    </AuthManagerContainerView>
  )
}

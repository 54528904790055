import { useState, useEffect } from "react"
import { DataGridRedirectRowTable } from "../../../../uikit/dataGridTable"
import { useQuery } from "@tanstack/react-query"
import { getPagedParticipantsByAreaId } from "../../../../domain/portal/manager/participants/Participants.Repository"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { Box, Stack } from "@mui/material"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { SingleLineTextField } from "../../../../uikit/input/SingleLineTextField"
import { PrimaryButton } from "../../../../uikit/button"
import { ParticipantResponse } from "../../../../data/generated-sources/openapi"
import { AxiosErrorDataType, filterArrayBySearchInput } from "../../../Shared.Utils"
import { ParticipantRegister } from "./components/ParticipantRegister"
import { ParticipantsListColumns } from "./ParticipantsListTableColumnsConfig"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"

export const ParticipantsList = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { t } = useTranslation("customer-participants")
  const { areaId } = params
  const [participantsError, setParticipantsError] = useState<AxiosErrorDataType>()
  const [filterQuery, setFilterQuery] = useState<string>("")
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [participantsList, setParticipantsList] = useState<ParticipantResponse[]>([])

  const {
    isLoading,
    isFetching,
    isError,
    data,
    remove: removeParticipants,
    refetch: refetchParticipants,
  } = useQuery(["getAreaParticipants", areaId], () => getPagedParticipantsByAreaId(areaId), {
    enabled: !!areaId,
    onSuccess: (data: ParticipantResponse[]) => setParticipantsList(data),
    onError: setParticipantsError,
  })

  const filterData = (searchInput: string) => {
    if (data) {
      const filtered = filterArrayBySearchInput<ParticipantResponse>(data, searchInput)
      setParticipantsList(filtered)
    }
  }

  useEffect(() => {
    return () => {
      removeParticipants()
      setParticipantsList([])
    }
  }, [removeParticipants])

  useEffect(() => {
    refetchParticipants()
  }, [areaId, refetchParticipants])

  return (
    <Box p={5} pt={6}>
      <ErrorAlert
        visible={isError && !!participantsError}
        message={t(`error-codes:${participantsError?.response?.data?.code || participantsError?.code || "OTHER"}`)}
      />
      <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"} pb={5}>
        <SingleLineTextField
          autoFocus={false}
          id="filter"
          name="filter"
          type="text"
          sx={{ width: "24rem" }}
          label={t("shared:form.search.label")}
          value={filterQuery}
          onChange={(event) => {
            setFilterQuery(event.target.value)
            filterData(event.target.value)
          }}
        />
        <PrimaryButton label={t("table.createParticipantBtn")} onClick={() => setShowCreateModal(true)} />
      </Stack>
      <DataGridRedirectRowTable<ParticipantResponse>
        rows={participantsList}
        columns={ParticipantsListColumns(t)}
        loading={isLoading && isFetching}
        redirectParamName={"id"}
        redirectDescription={t("label.goToParticipant")}
        redirectUrl={location.pathname}
        defaultPageSize={25}
      />
      <ParticipantRegister
        show={showCreateModal}
        onClose={() => setShowCreateModal(false)}
        onCreateSuccess={() => refetchParticipants()}
      />
    </Box>
  )
}

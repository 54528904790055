import i18n, { i18n as Ii18n } from "i18next"
import localeDateDE from "date-fns/locale/de"
import localeDateEN from "date-fns/locale/en-US"
import { initReactI18next } from "react-i18next"

import { SharedI18n } from "./Shared.i18n"
import { AppContainerI18n } from "./appcontainer/AppContainer.i18n"
import { AuthI18n } from "./auth/Auth.i18n"
import { SharedErrorI18n } from "./Shared.Error.i18n"
import { managerPortalI18n } from "./portal/manager/ManagerPortal.i18n"
import { adminPortalI18n } from "./portal/admin/AdminPortal.i18n"

export interface I18nNamespace {
  name: string
  de: Record<string, string>
  en: Record<string, string>
}

const namespaces = [...adminPortalI18n, ...managerPortalI18n, AppContainerI18n, AuthI18n, SharedI18n, SharedErrorI18n]

export const setupI18N = (): Ii18n => {
  i18n.use(initReactI18next).init({
    lng: process.env.APP_LANG,
    resources: {},
    fallbackLng: "de",
    interpolation: {
      escapeValue: false,
    },
  })
  namespaces.forEach((namespace) => {
    i18n.addResourceBundle("de", namespace.name, namespace.de)
    i18n.addResourceBundle("en", namespace.name, namespace.en)
  })
  return i18n
}

export const getDateLocale = (): Locale => {
  switch (process.env.APP_LANG) {
    case "en":
      return localeDateEN
    case "de":
    default:
      return localeDateDE
  }
}

export const translateErrorCode = (key?: string, message?: string) => {
  switch (process.env.APP_LANG) {
    case "en":
      if (!key) return SharedErrorI18n.en.GENERIC_FATAL_ERROR
      if (SharedErrorI18n.en[key]) return SharedErrorI18n.en[key]
      return `${SharedErrorI18n.en.GENERIC_FATAL_ERROR} ${message}`
    case "de":
    default:
      if (!key) return SharedErrorI18n.de.GENERIC_FATAL_ERROR
      if (SharedErrorI18n.de[key]) return SharedErrorI18n.de[key]
      return `${SharedErrorI18n.de.GENERIC_FATAL_ERROR} ${message}`
  }
}

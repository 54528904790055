import { useTranslation } from "react-i18next"
import { useCallback, useEffect, useState } from "react"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { GridRow, GridRowProps } from "@mui/x-data-grid"
import { RouteComponentProps } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { getAdminTasks } from "../../../../domain/portal/admin/tasks/Tasks.Repository"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { Task, TaskStatusEnum } from "../../../../data/generated-sources/openapi"

import { SwitchToggleView } from "../../../../uikit/toggle/SwitchToggleView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { TaskDetail } from "./TaskDetail"
import { TaskListColumns } from "./TaskListColumns"
import { TaskCommentUpdateDialog } from "./fragments/TaskCommentUpdateDialog"
import { DataGridListPage } from "../dataGrid/DataGridListPage"
import { DataGridHeaderWrapper, DataGridQuickFilterSearchInput } from "../dataGrid/fragments/DataGridTableHeader"

export const TaskList = (props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("tasklist")
  const [showCompleted, setShowCompleted] = useState(false)
  const [expandedRowId, setExpandedRowId] = useState<string>("")
  const queryResults = showCompleted ? TaskStatusEnum.DONE : TaskStatusEnum.CREATED

  const initialDialogProps = {
    id: "",
    comment: "",
    open: false,
  }
  const [showCommentDialog, setShowCommentDialog] = useState<{ id: string; comment: string; open: boolean }>(
    initialDialogProps,
  )

  const {
    data: taskList,
    isFetching,
    isError,
    error,
    refetch,
  } = useQuery(["getTasks"], () => getAdminTasks(1, 10000, "desc(createdAt)", queryResults))

  useEffect(() => {
    refetch()
  }, [showCompleted])

  const handleExpandRow = (rowId: string) => {
    const sameRowId = expandedRowId === rowId
    const expandId = sameRowId ? "" : rowId
    setExpandedRowId(expandId)
  }

  const handleNotesClick = (field: string, row: Task) => {
    if (field === "comment") {
      setShowCommentDialog({
        open: true,
        id: row?.id,
        comment: row?.comment ?? "",
      })
    }
  }

  const handleUpdateSuccess = () => {
    refetch()
    setShowCommentDialog(initialDialogProps)
  }

  const HeaderToolbar = useCallback(() => {
    return (
      <>
        <DataGridHeaderWrapper data-testid="TaskLisHeaderWrapper">
          <Typography
            variant="h4"
            fontSize={24}
            fontWeight={300}
            textTransform={"uppercase"}
            data-testid="TaskListHeaderTitle"
          >
            {t("list.title")}
          </Typography>

          <Stack flexDirection="row" alignItems="flex-end">
            <DataGridQuickFilterSearchInput
              data-testid="searchFilterInput"
              InputProps={{ startAdornment: null }}
              placeholder={t("shared:form.filter.label")}
            />
          </Stack>
        </DataGridHeaderWrapper>
        <Box pl={2} pb={4}>
          <SwitchToggleView
            checked={showCompleted}
            data-testid="taskListToggleButton"
            label={t("list.toggle.showcompleted")}
            onChange={(checked) => setShowCompleted(checked)}
          />
        </Box>
      </>
    )
  }, [showCompleted])

  const ExpandableTableRow = useCallback(
    (prop: GridRowProps) => {
      const { rowId, row } = prop
      return (
        <>
          <GridRow {...prop} key={rowId} />
          {rowId === expandedRowId && <TaskDetail {...(row as Task)} onCompleteTask={refetch} {...props} />}
        </>
      )
    },
    [expandedRowId],
  )

  return (
    <Paper data-testid="taskListPage">
      <ErrorAlert
        scrollOnDisplay
        visible={isError}
        message={t(`error-codes:${(error as AxiosErrorDataType)?.code ?? "OTHER"}`)}
      />
      <DataGridListPage<Task>
        title=""
        loading={isFetching}
        data-testid="taskListTable"
        rows={taskList?.elements ?? []}
        pageSizeOptions={[5, 10, 25, 50, 100]}
        columns={TaskListColumns(expandedRowId, handleExpandRow)}
        onCellClick={({ field, row }) => handleNotesClick(field, row)}
        slots={{
          toolbar: HeaderToolbar,
          row: ExpandableTableRow,
        }}
        sx={{ ".MuiDataGrid-row": { cursor: "default" } }}
        disableColumnMenu
      />
      <TaskCommentUpdateDialog
        {...showCommentDialog}
        onUpdateSuccess={handleUpdateSuccess}
        closeDialog={() => setShowCommentDialog(initialDialogProps)}
      />
    </Paper>
  )
}

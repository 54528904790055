import { useHistory } from "react-router-dom"
import { TableCell } from "@mui/material/"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"

import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"

interface ContractsTableInterface {
  itemConfig: ContractAdminResponse
}

const ContractsTableComponent = ({ itemConfig }: ContractsTableInterface) => {
  const history = useHistory()

  return (
    <TableRowClickable<ContractAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={({ id }) => history.push(`/management-contracts/${id}`)}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
      <TableCell align="left">{itemConfig.product?.name ?? "-"}</TableCell>
      <TableCell align="left">{DateUtils.getDeFormatWithDots(itemConfig.startDate)}</TableCell>
      <TableCell align="left">{itemConfig.endDate ? DateUtils.getDeFormatWithDots(itemConfig.endDate) : "-"}</TableCell>
    </TableRowClickable>
  )
}

export default ContractsTableComponent

import { Paper } from "@mui/material"
import { useQuery } from "@tanstack/react-query"
import { getAllContracts } from "../../../../domain/portal/admin/contracts/Contracts.Repository"
import { DataGridListPage } from "../dataGrid/DataGridListPage"
import { contractColumns } from "./ContractCoumns"
import { ContractAdminResponse } from "../../../../data/generated-sources/openapi/api"
import { useHistory } from "react-router-dom"

export const Contracts = () => {
  const { data, isFetching } = useQuery(["getContracts"], () => getAllContracts())
  const history = useHistory()

  const handleRowClick = ({ id }: ContractAdminResponse) => {
    history.push(`/management-contracts/${id}`)
  }

  return (
    <Paper>
      <DataGridListPage<ContractAdminResponse>
        showButton={false}
        loading={isFetching}
        title="contracts:table.title"
        data-testid="contractsListPage"
        rows={data?.elements ?? []}
        columns={contractColumns()}
        onRowClick={({ row }) => handleRowClick(row)}
      />
    </Paper>
  )
}

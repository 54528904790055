import { Dispatch } from "react"
import { AnyAction } from "redux"
import { connect } from "react-redux"

import { AppState } from "../../../App.Reducer"
import { MyZevElectricityCalculatorActionType } from "./MyZevElectricityCalculator.Epic"
import { MyZevElectricityCalculatorComponent } from "./MyZevElectricityCalculator.Component"
import {
  BuildingCustomerResponse,
  ZevPricePackage,
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorInput,
} from "../../../../data/generated-sources/openapi"

export const mapStateToProps = (state: AppState) => {
  return {
    ...state.managerPortal.myZevElectricityCalculator,
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    getZevPriceCalculatorEnergyById: (contractId: string, year: number) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_ENERGY,
        contractId,
        year,
      })
    },

    getMyContractDetails: (contractId: string) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_CONTRACT,
        contractId: contractId,
      })
    },

    getMyZevPrices: (contractId: string) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_PACKAGES,
        contractId: contractId,
      })
    },

    getZevPriceCalculatorInputById: (contractId: string) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT,
        contractId,
      })
    },

    getZevPriceCalculatorTariffsById: (contractId: string) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_TARIFFS_BY_ID,
        contractId,
      })
    },

    getZevPriceCalculatorPricesById: (contractId: string, year: number) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES,
        contractId,
        year,
      })
    },

    updateZevPriceCalculatorInputById: (contractId: string, inputPrices: ZevPriceCalculatorInput) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_INPUT_UPDATE,
        contractId,
        inputPrices,
      })
    },

    updateZevPricePackageById: (contractId: string, pricePackageId: number, pricePackage: ZevPricePackage) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICES_UPDATE,
        contractId,
        pricePackageId,
        pricePackage,
      })
    },

    getZevPriceCalculatorPricesSuppliedEnergyById: (
      contractId: string,
      year: number,
      zevPriceCalculatorEnergy: ZevPriceCalculatorEnergy,
    ) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_PRICE_CALCULATOR_PRICES_SUPPLIED_ENERGY_GET,
        contractId,
        year,
        zevPriceCalculatorEnergy,
      })
    },

    getConsumptionPoints: (consumptionIds: string[], buildingsMap: Map<string, BuildingCustomerResponse>) => {
      dispatch({
        type: MyZevElectricityCalculatorActionType.MY_ZEV_CONSUMPTION_POINTS,
        consumptionIds,
        buildingsMap,
      })
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MyZevElectricityCalculatorComponent)

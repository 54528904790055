import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"

import DateUtils from "../../../../../../services/utils/DateUtils"
import { apiFormattedDateToTimestamp } from "../../../../../../domain/Domain.Formatters"
import { customerUtilityParticipationChangeMoveOutDate } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { validateUtilityUnitParticipantMoveOutDate } from "../../../../admin/utilityUnit/participations/UtilityUnitParticipantMoveOutDateForm.Validation"

import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ParticipationDialogPros } from "./UtilityUnitParticipations"
import { PrimaryButton } from "../../../../../../uikit/button"
import { SingleLineDatePicker } from "../../../../../../uikit/input/SingleLineDatePicker"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export const EditParticipantMoveOutDate = ({
  showDialog,
  participation,
  onUpdateSuccess,
  onClose,
}: ParticipationDialogPros) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [updateError, setUpdateError] = useState<AxiosErrorDataType>()

  useEffect(() => {
    return () => {
      setUpdateError(undefined)
    }
  }, [setUpdateError])

  const { mutate: changeMoveOutDate, isLoading: isMovingOut } = useMutation(
    ["changeMoveOutDate"],
    (moveOutDate: number) =>
      customerUtilityParticipationChangeMoveOutDate(participation?.utilityUnitId, participation?.id, moveOutDate),
    {
      onSuccess: onUpdateSuccess,
      onError: setUpdateError,
    },
  )

  const confirmMoveOut = ({ moveOutDate }: { moveOutDate: number }) => changeMoveOutDate(moveOutDate)

  return (
    <Dialog open={showDialog} onClose={onClose} maxWidth="sm">
      <DialogTitle>{t("dialog.editMoveOut")}</DialogTitle>
      <ErrorAlert
        scrollOnDisplay
        visible={!!updateError}
        message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
      />
      <Formik<{ moveOutDate: number }>
        initialValues={{ moveOutDate: apiFormattedDateToTimestamp(participation?.moveOutDate) ?? 0 }}
        enableReinitialize
        onSubmit={confirmMoveOut}
        validate={(values) =>
          validateUtilityUnitParticipantMoveOutDate(
            DateUtils.getDeFormatDate(values.moveOutDate),
            participation?.moveInDate || "",
            t,
            participation?.nextParticipantMoveIn,
          )
        }
      >
        {({ values, handleBlur, handleSubmit, setFieldValue, errors }) => {
          return (
            <Form onSubmit={handleSubmit}>
              <DialogContent>
                <DialogContentText fontWeight={700}>{t("dialog.selectMoveOut")}</DialogContentText>
                <SingleLineDatePicker
                  required
                  name="moveOutDate"
                  label=""
                  onBlur={handleBlur}
                  value={values.moveOutDate}
                  helperText={errors.moveOutDate}
                  onChange={(value) => setFieldValue("moveOutDate", value)}
                  minDate={participation?.moveInDate ? DateUtils.addDateDays(participation?.moveInDate, 1) : undefined}
                  maxDate={
                    participation?.nextParticipantMoveIn
                      ? DateUtils.substructDateDays(participation?.nextParticipantMoveIn, 1)
                      : undefined
                  }
                />
              </DialogContent>
              <DialogActions>
                <Button variant="outlined" onClick={onClose} disabled={isMovingOut}>
                  {t("shared:form.action.cancel")}
                </Button>
                <PrimaryButton
                  type="submit"
                  disabled={isMovingOut}
                  isLoading={isMovingOut}
                  label={t("shared:form.action.save")}
                />
              </DialogActions>
            </Form>
          )
        }}
      </Formik>
    </Dialog>
  )
}

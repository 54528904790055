import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ConsumptionScope, MeterResponse, UsageType } from "../../../../../../../../data/generated-sources/openapi"
import { SelectPicker } from "../../../../../../../../uikit/input/SelectPicker"
import { VewaGroupMetersProps } from "../../../Medium.Interfaces"
import { labelLayout } from "../../../../../../../../uikit/label/InputLabel"
import { getMetersOptions, selectedMeterItemView } from "../../../MediumUtils"

export const VewaCoolingGroupMeter = ({ setMeters, meters }: VewaGroupMetersProps) => {
  const { t } = useTranslation("medium")

  return (
    <Grid container sx={{ mb: 3 }}>
      <Grid container item>
        <Grid container xs={12} item>
          <Grid xs={12} item>
            <Typography fontSize={16} fontWeight={500} mb={2}>
              {t("label.cooling.meterTotalConsumption")}
            </Typography>
            <Typography fontSize={16} fontWeight={400} mb={2}>
              {t("label.cooling.groupCounterText")}
            </Typography>
          </Grid>
          <Grid xs={5} item>
            {labelLayout(t("label.residentalUnits"))}
            <SelectPicker
              name="medium"
              type="text"
              label={t("label.findAddMeters")}
              value={""}
              items={getMetersOptions(UsageType.RESIDENCE, meters)}
              onChange={(event) => {
                setMeters(
                  meters.map((meter: MeterResponse) => {
                    if (meter.id === event.target.value) {
                      return {
                        ...meter,
                        isLinkedToVewaConfig: true,
                        linkedConsumptionScope: ConsumptionScope.ROOM_COOLING,
                      }
                    }
                    return meter
                  }),
                )
              }}
            />
            {selectedMeterItemView(UsageType.RESIDENCE, ConsumptionScope.ROOM_COOLING, meters, setMeters)}
          </Grid>
          <Grid item xs={1}></Grid>
          <Grid xs={5} item>
            {labelLayout(t("label.commercialUnits"))}
            <SelectPicker
              name="medium"
              type="text"
              label={t("label.findAddMeters")}
              value={""}
              items={getMetersOptions(UsageType.BUSINESS, meters)}
              onChange={(event) => {
                setMeters(
                  meters.map((meter: MeterResponse) => {
                    if (meter.id === event.target.value) {
                      return {
                        ...meter,
                        isLinkedToVewaConfig: true,
                        linkedConsumptionScope: ConsumptionScope.ROOM_COOLING,
                      }
                    }
                    return meter
                  }),
                )
              }}
            />
            {selectedMeterItemView(UsageType.BUSINESS, ConsumptionScope.ROOM_COOLING, meters, setMeters)}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

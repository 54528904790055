import { TableCell, Typography, TypographyProps, styled } from "@mui/material/"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey, getTranslateValue } from "../../../../Shared.Utils"
import { AllParticipantsBilling, AllParticipantsBillingState } from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"
import CurrencyUtils from "../../../../../services/utils/CurrencyUtils"
import { useHistory, useParams } from "react-router-dom"
import { AllParticipantsBillingExtended } from "../../store/billingsSlice"

export const BillNewBadge = styled((props: TypographyProps) => (
  <Typography variant="caption" {...props}>
    {getTranslateValue("shared:label.new")}
  </Typography>
))(({ theme }) => ({
  color: "white",
  fontWeight: "bold",
  backgroundColor: "#f2da6e",
  textTransform: "uppercase",
  borderRadius: theme.spacing(3),
  marginLeft: theme.spacing(1),
  padding: theme.spacing(0.5, 1),
}))

interface ParticipantBillingTableInterface {
  itemConfig: AllParticipantsBillingExtended
  isZevProduct: boolean
}

const ParticipantPeriodicBillingTable = ({ itemConfig, isZevProduct }: ParticipantBillingTableInterface) => {
  const history = useHistory()
  const { id: contractId } = useParams<{ id: string }>()

  const formatDate = (date: string): string => {
    if (!date) return ""

    return DateUtils.getDeFormatDateWithMonthString(date)
  }

  const formatAmount = (): string => {
    if (!itemConfig.totalSum) return ""

    return CurrencyUtils.formatToSwiss(itemConfig.totalSum)
  }

  const isFinalizeState = itemConfig.activeState === AllParticipantsBillingState.DONE ? "finalize" : "edit"

  const navigateToAllParticipantBillingPage = () => {
    const url = `/management-contracts/${contractId}/billings/all/${itemConfig.id}/${isFinalizeState}`
    history.push(url)
  }

  const navigateToVewaBillingDetails = () => {
    if (itemConfig.activeState === AllParticipantsBillingState.IN_PROGRESS) {
      history.push(`/management-contracts/${contractId}/billings/vewa/${itemConfig.id}/validate`)
    }
    if (itemConfig.activeState === AllParticipantsBillingState.DONE) {
      history.push(`/management-contracts/${contractId}/billings/vewa/${itemConfig.id}/bills`)
    }
  }

  return (
    <TableRowClickable<AllParticipantsBilling>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClickable={
        isZevProduct ||
        [AllParticipantsBillingState.IN_PROGRESS, AllParticipantsBillingState.DONE].includes(itemConfig.activeState)
      }
      rowClick={() => {
        if (isZevProduct) {
          return navigateToAllParticipantBillingPage()
        }
        navigateToVewaBillingDetails()
      }}
    >
      <TableCell align="left">
        {formatDate(itemConfig.startDate)} - {formatDate(itemConfig.endDate)}
        {itemConfig?.isNew && <BillNewBadge />}
      </TableCell>
      <TableCell align="left">{formatAmount()}</TableCell>
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
    </TableRowClickable>
  )
}

export default ParticipantPeriodicBillingTable

import { Paper, Typography, Stack, Box, Divider } from "@mui/material"
import { FabButton } from "../../../../../uikit/button"
import { EditIcon } from "../../../../../uikit/Shared.Icon"
import { useTranslation } from "react-i18next"
import { ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import { useHistory, useParams } from "react-router-dom"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { SwitchToggleView } from "../../../../../uikit/toggle/SwitchToggleView"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { setCreateServiceBillsManuallyFlag } from "../../../../../domain/portal/admin/billings/Billings.Repository"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"

export const ContractComment = ({ comment, createServiceBillsManually }: ContractAdminResponse) => {
  const { id: contractId } = useParams<AdminPortalRouteParams>()
  const { t } = useTranslation("shared")
  const history = useHistory()

  const queryClient = useQueryClient()
  const invalidateVewaQuery = () => queryClient.invalidateQueries({ queryKey: ["getContractManagement"] })

  const {
    mutate: setManuallyCreated,
    isLoading: isManuallyCreating,
    isError,
    error,
  } = useMutation(
    ["setManuallyCreated"],
    (isManuallyCreated: boolean) => setCreateServiceBillsManuallyFlag(contractId, isManuallyCreated),
    {
      onSuccess: () => invalidateVewaQuery(),
    },
  )

  const errorMsg = error as AxiosErrorDataType

  const goToEditNotes = () => history.push(`/management-contracts/${contractId}/comment/update`)

  return (
    <Paper sx={{ pt: 5 }} data-testid="ContractCommentSection">
      <Stack flexDirection={"row"} alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" fontWeight={300} textTransform={"uppercase"} data-testid="ContractCommentHeader">
          {t("shared:label.comments")}
        </Typography>
        <FabButton size="medium" icon={<EditIcon />} onClick={goToEditNotes} data-testid="ContractCommentEditButton" />
      </Stack>

      <Divider />

      <Box p={2}>
        <ErrorAlert
          visible={isError}
          message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
        />
        <SwitchToggleView
          label={t("contracts:manuallyCreatedBilling")}
          checked={createServiceBillsManually}
          onChange={(checked) => setManuallyCreated(checked)}
          disabled={isManuallyCreating}
        />
      </Box>

      <Box py={2} pl={1} pr={10}>
        <Typography fontSize={16} fontWeight={400} data-testid="ContractCommentText">
          {comment ?? "-"}
        </Typography>
      </Box>
    </Paper>
  )
}

import { ProductType, BillingType, PowerMeterType } from "../../../../data/generated-sources/openapi"

export const billingTypeKeys = Object.keys(BillingType)
export const powermeterTypeKeys = Object.keys(PowerMeterType)

export interface ProductPriceComponent {
  id: string
  name: string
  billingType: BillingType
  powermeterType?: PowerMeterType
  priceWithoutVat: string
  validFrom: string
  validUntil: string
  externalReferenceNumber: string
}

export interface ProductItem {
  id: string
  name: string
  serviceComponents: {
    id: string
    name: string
    feature: string
  }[]
}

export interface ProductResponseItem {
  id: string
  name: string
  serviceComponents: {
    id: string
    name: string
  }[]
  priceComponents: {
    id: string
    name: string
    billingType: string
    priceWithoutVat: string
    validFrom: string
    externalReferenceNumber: string
    powermeterType: string
  }[]
}

export interface ProductDetail {
  id: string
  name: string
  serviceComponents: {
    id: string
    name: string
    feature: string
  }[]
  priceComponents: ProductPriceComponent[]
  productType?: ProductType
}

export interface ProductUpsert {
  name: string
  serviceComponents: string[]
  productType?: ProductType
}

export interface ProductList {
  productList: ProductItem[]
}

export interface ProductResponseList {
  productList: ProductResponseItem[]
}

export const emptyProductUpsert: ProductUpsert = {
  name: "",
  serviceComponents: [],
}

export interface ProductPriceComponentUpsert {
  name: string
  externalReferenceNumber: string
  billingType: BillingType
  powermeterType?: PowerMeterType
  priceWithoutVat: string
  validFrom: number
  validUntil: number
}

export const draftProductPriceComponentUpsert: ProductPriceComponentUpsert = {
  name: "",
  externalReferenceNumber: "",
  billingType: BillingType.MONTHLY_FEE,
  priceWithoutVat: "",
  validFrom: -1,
  validUntil: -1,
}

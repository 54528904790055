import { useMutation } from "@tanstack/react-query"
import { Grid, Paper, GridProps } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { Form, Formik, FormikHelpers } from "formik"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import { buildingMetersUsageTypeValues, meterMediumTypeValues } from "../Meters.Model"
import { BuildingCreateMeterAdminRequest, Medium, UsageType } from "../../../../../data/generated-sources/openapi"
import { createMeterForBuilding } from "../../../../../domain/portal/admin/meters/Meters.Repository"

import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { ErrorAlert, SuccessAlertLink } from "../../../../../uikit/Shared.Alert"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { buildingMeterCreateSchema } from "./MetersValidation.Schema"
import { useState } from "react"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

const MeterFormRow = ({ children }: GridProps) => (
  <Grid item xs={12} md={6} p={1} pr={3}>
    {children}
  </Grid>
)

interface IBuildingMeter {
  meteringCode: string
  medium: string
  usageType: string
}
const initialBuildingMeter: IBuildingMeter = {
  meteringCode: "",
  medium: "",
  usageType: "",
}

const buildingMeterMediumTypeValues = meterMediumTypeValues.filter((v) => v !== Medium.ELECTRICITY)

const BuildingMeterCreateForm = ({ match: { params }, history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("meters")
  const [createError, setCreateError] = useState<AxiosErrorDataType>()

  const {
    mutate: createBuildingMeter,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    ["createMeterForBuilding"],
    (values: BuildingCreateMeterAdminRequest) => createMeterForBuilding(params.buildingId, values),
    {
      onError: setCreateError,
    },
  )

  const handleOnSubmit = (values: IBuildingMeter, formikHelpers: FormikHelpers<IBuildingMeter>) => {
    const meterData: BuildingCreateMeterAdminRequest = {
      meteringCode: values.meteringCode,
      medium: values.medium as Medium,
      usageType: values.usageType as UsageType,
    }
    setCreateError(undefined)
    createBuildingMeter(meterData, {
      onSuccess: formikHelpers.resetForm,
    })
  }

  return (
    <>
      <SuccessAlertLink
        visible={isSuccess}
        message={t("successMsg.text")}
        onClick={() => history.push(`/buildings/${params.buildingId}`)}
      />
      <ErrorAlert
        message={t(`error-codes:${createError?.response?.data?.code || createError?.code || "OTHER"}`)}
        visible={isError}
      />
      <Formik<IBuildingMeter>
        initialValues={initialBuildingMeter}
        validationSchema={buildingMeterCreateSchema}
        onSubmit={handleOnSubmit}
      >
        {({ values, errors, touched, setFieldValue, handleBlur, handleSubmit, isValid, dirty }) => (
          <Form onSubmit={handleSubmit}>
            <Paper>
              <Grid container rowGap={1}>
                <MeterFormRow>
                  <SingleLineTextField
                    required
                    autoFocus
                    name="meteringCode"
                    type="text"
                    label={t("label.meterIdSpec")}
                    helperText={touched.meteringCode && errors.meteringCode ? errors.meteringCode : undefined}
                    value={values.meteringCode ?? ""}
                    onChange={(event) => setFieldValue("meteringCode", event.target.value)}
                    onBlur={handleBlur}
                  />
                </MeterFormRow>
                <MeterFormRow>
                  <SelectPicker
                    type="text"
                    name="medium"
                    label={t("label.medium")}
                    value={values.medium ?? ""}
                    items={buildingMeterMediumTypeValues.map((medium) => ({
                      label: t(`label.${medium}`),
                      value: medium,
                    }))}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("medium", event.target.value)}
                    helperText={touched.medium && errors.medium ? errors.medium : undefined}
                  />
                </MeterFormRow>
                <MeterFormRow>
                  <SelectPicker
                    type="text"
                    name="usageType"
                    label={t("label.usageType")}
                    value={values.usageType ?? ""}
                    items={buildingMetersUsageTypeValues.map((usageType) => ({
                      label: t(`label.${usageType}`),
                      value: usageType,
                    }))}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("usageType", event.target.value)}
                    helperText={errors.usageType && touched.usageType ? errors.usageType : undefined}
                  />
                </MeterFormRow>
              </Grid>
            </Paper>
            <FormUpdateActionsView
              buttonCtaLabel={t("label.saveChanges")}
              dirty={dirty}
              isLoading={isLoading}
              isValid={isValid && !!params.buildingId}
              navigateBack={() => history.goBack()}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default BuildingMeterCreateForm

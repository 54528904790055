import { getTranslateValue, isActiveStatus } from "../../../../Shared.Utils"
import { PrimaryButton } from "../../../../../uikit/button/PrimaryButton"
import { DeactivateIcon } from "../../../../../uikit/Shared.Icon"
import { deactivateAreaById } from "../../../../../domain/portal/admin/areas/Areas.Repository"
import AreaDeactivateModal from "./AreaDeactivate.modal"
import { ModalComponent } from "../../../../../uikit/modal/Modal"
import React, { useState } from "react"
import { AreaAdminResponse } from "../../../../../data/generated-sources/openapi"

interface AreaDeactivateComponentPropsInterface {
  area: AreaAdminResponse
  refetch: () => void
}
const AreaDeactivateComponent = ({ area, refetch }: AreaDeactivateComponentPropsInterface) => {
  const [isError, setIsError] = useState<boolean>(false)

  return (
    <ModalComponent
      closeButtonText={getTranslateValue("shared:form.action.cancel")}
      buttonText={
        <>
          <PrimaryButton
            disabled={!isActiveStatus(area?.activeState as string)}
            startIcon={<DeactivateIcon fontSize="large" />}
            label={getTranslateValue("area:label.deactivate")}
          />
        </>
      }
      buttonStyle={{ width: "fit-content", marginRight: "15px" }}
      buttonDisabled={!isActiveStatus(area?.activeState as string)}
      ctaButtonText={getTranslateValue("area:label.deactivate")}
      ctaButtonAction={(handleClose) => {
        isActiveStatus(area?.activeState as string) &&
          area?.id &&
          deactivateAreaById(area?.id)
            .then((): void => {
              refetch()
              handleClose && handleClose()
            })
            .catch((): void => {
              setIsError(true)
            })
      }}
      modalStyle={{ backgroundColor: "#fff" }}
      useCloseBtn={false}
      body={<AreaDeactivateModal isError={isError} />}
    />
  )
}

export default AreaDeactivateComponent

import { useTranslation } from "react-i18next"
import { Badge, Button, Stack, Typography } from "@mui/material"
import { useAppSelector } from "../../../hooks"
import { useCallback } from "react"
import { useHistory } from "react-router-dom"
import {
  selectZevAllPeriodicBillings,
  selectZevNotDonePeriodicBillings,
  selectVewaDonePeriodicBillings,
  selectVewaNotDonePeriodicBillings,
} from "../store/billingsSlice"
import {
  AllParticipantsBillingState,
  ProductType,
  VewaAllParticipantsBillingState,
} from "../../../../data/generated-sources/openapi"

import { DataGridRedirectRowTable } from "../../../../uikit/dataGridTable"
import { PeriodicBillingsDoneStatus } from "./PeriodicBillingsDoneStatus"
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import {
  VewaPeriodicBillingItem,
  ZevPeriodicBillingItem,
} from "../../../../domain/portal/manager/billings/Billings.Model"
import { selectSelectedContractData } from "../store/contractSelectorSlice"
import { VewaPeriodicBillingTable } from "./VewaPeriodicBillingTable"
import { periodicBillingsColumns } from "./fragments/PeriodicBillningColumns"

interface PeriodicBillingProps {
  areaId: string
  separateTables?: boolean
  isLoading?: boolean
}

export const PeriodicBillingList = ({ areaId, separateTables, isLoading }: PeriodicBillingProps) => {
  const { t } = useTranslation("settlements")
  const history = useHistory()

  const { contractId, contractProductType } = useAppSelector(selectSelectedContractData)
  const zevAllBills = useAppSelector(selectZevAllPeriodicBillings)
  const zevNotDoneBills = useAppSelector(selectZevNotDonePeriodicBillings)
  const vewaDoneBills = useAppSelector(selectVewaDonePeriodicBillings)
  const vewaNotDoneBills = useAppSelector(selectVewaNotDonePeriodicBillings)

  const billingsWithNewStatus = zevAllBills.filter((bill) => bill.isNew)

  const collectBills = (): ZevPeriodicBillingItem[] | VewaPeriodicBillingItem[] => {
    if (contractProductType === ProductType.ZEV) {
      if (!separateTables) {
        return zevAllBills
      }
      return zevNotDoneBills
    } else {
      if (!separateTables) {
        return [...vewaDoneBills, ...vewaNotDoneBills]
      }
      return vewaNotDoneBills
    }
  }

  const navigateToBillingEdit = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/edit`)
  }

  const navigateToBillingFinalize = (billingId: string) => {
    history.push(`/${areaId}/contracts/${contractId}/billings/all/${billingId}/finalize`)
  }

  const handleVewaBillingNavigation = (billingId: string, activeState: VewaAllParticipantsBillingState) => {
    if (activeState === VewaAllParticipantsBillingState.IN_PROGRESS) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/validate`)
    }
    if (activeState === VewaAllParticipantsBillingState.DONE) {
      history.push(`/${areaId}/contracts/${contractId}/billings/vewa/${billingId}/bills`)
    }
  }

  const handleZevRowClick = ({ id, activeState }: ZevPeriodicBillingItem) => {
    const { DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED } = AllParticipantsBillingState
    if ([DATA_AVAILABLE, IN_PROGRESS, IN_PROGRESS_REOPENED].includes(activeState)) {
      navigateToBillingEdit(id)
    } else {
      navigateToBillingFinalize(id)
    }
  }

  const renderBillingTable = useCallback(() => {
    return (
      <>
        {separateTables && contractProductType === ProductType.VEWA ? (
          <VewaPeriodicBillingTable areaId={areaId} />
        ) : (
          <DataGridRedirectRowTable<ZevPeriodicBillingItem | VewaPeriodicBillingItem>
            rows={collectBills()}
            columns={periodicBillingsColumns()}
            redirectDescription={t("settlements:list-billing-makeBill")}
            hideFooterPagination={!separateTables}
            loading={isLoading}
            getRowClassName={({ row }) => (row?.makeBill ? "" : "RowNotClickable")}
            onRowClick={({ row }) => {
              if (!row?.makeBill) return
              if (contractProductType === ProductType.ZEV) {
                handleZevRowClick(row)
              }
              if (contractProductType === ProductType.VEWA) {
                const { id, activeState } = row
                handleVewaBillingNavigation(id, activeState)
              }
            }}
            defaultPageSize={25}
          />
        )}
      </>
    )
  }, [separateTables, separateTables, isLoading])

  return (
    <Stack p={4} flex={1}>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Typography variant="h5" fontWeight={400} mt={2} mb={5}>
          {t("dashboard:section-overview.most.recent.bills")}
          {!!billingsWithNewStatus?.length && (
            <Badge sx={{ mx: 2 }} badgeContent={billingsWithNewStatus?.length} color="warning" />
          )}
        </Typography>
        {!separateTables && (
          <Button
            variant="text"
            onClick={() => history.push(`/${areaId}/billings`)}
            startIcon={<ArrowForwardIcon fontSize="small" color="inherit" />}
          >
            {t("section-view.all.bills")}
          </Button>
        )}
      </Stack>
      {renderBillingTable()}
      {separateTables && <PeriodicBillingsDoneStatus areaId={areaId} />}
    </Stack>
  )
}

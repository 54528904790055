import { Box, Grid, Paper, Typography } from "@mui/material"
import { styled } from "@mui/material/styles"

import { HasChildren } from "../../../uikit/Shared.Prop"
import { StaticFooterView } from "../../appcontainer/views/footer/StaticFooterView"
import { CustomerStaticHeaderView } from "../../appcontainer/views/header/CustomerStaticHeaderView"

import BackgroundImage1280 from "url:../../../../res/gen/LoginBackground_w1280.jpg"
import BackgroundImage1920 from "url:../../../../res/gen/LoginBackground_w1920.jpg"
import BackgroundImage2560 from "url:../../../../res/gen/LoginBackground_w2560.jpg"
import BackgroundImage300 from "url:../../../../res/gen/LoginBackground_w300.jpg"
import BackgroundImage600 from "url:../../../../res/gen/LoginBackground_w600.jpg"
import BackgroundImage960 from "url:../../../../res/gen/LoginBackground_w960.jpg"

const Background = styled("div")(({ theme }) => ({
  position: "absolute",
  zIndex: -1,
  width: "100%",
  height: "100%",
  backgroundImage: `url(${BackgroundImage300})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
  backgroundPosition: "50%",
  [theme.breakpoints.up("lg")]: { backgroundImage: `url(${BackgroundImage2560})` },
  [theme.breakpoints.down("lg")]: { backgroundImage: `url(${BackgroundImage1920})` },
  [theme.breakpoints.down("md")]: { backgroundImage: `url(${BackgroundImage1280})` },
  [theme.breakpoints.down("sm")]: { backgroundImage: `url(${BackgroundImage960})` },
  [theme.breakpoints.down("xs")]: { backgroundImage: `url(${BackgroundImage600})` },
}))

const CardContentWrappe = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4),
  minWidth: 256,
  [theme.breakpoints.down("md")]: {
    margin: theme.spacing(2),
  },
}))

export const AuthManagerContainerView = (props: HasChildren) => {
  const { children } = props

  const isSafari = /[Aa]+pple/g.test(navigator.vendor)

  return (
    <>
      <Background />
      <CustomerStaticHeaderView />
      <Grid item container justifyContent="center" alignItems="center" sx={{ minHeight: "80vh" }}>
        <Paper elevation={3}>
          {isSafari && (
            <CardContentWrappe style={{ background: "linear-gradient(to bottom,#BCCF02,#6C9C30)" }}>
              <Typography
                align="center"
                variant="body1"
                style={{
                  fontWeight: "bold",
                  color: "#fff",
                }}
              >
                login browser support
              </Typography>
            </CardContentWrappe>
          )}
          <CardContentWrappe>{children}</CardContentWrappe>
        </Paper>
      </Grid>
      <StaticFooterView />
    </>
  )
}

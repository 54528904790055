import { useState } from "react"
import { useTranslation } from "react-i18next"
import { TextField } from "@mui/material"
import NumberFormat from "react-number-format"

import { FormView } from "../../../../../uikit/form/FormView"
import { PriceCalculatorRow } from "../views/PricecalculatorFormRow"
import { ZevPriceCalculatorInput } from "../../../../../data/generated-sources/openapi"

interface CalculatorInvestmentInformationFormProps {
  zevHasVat: boolean
  zevPriceCalculatorInput: ZevPriceCalculatorInput
  dataStatusChanged: (zevPriceCalculatorInput: ZevPriceCalculatorInput) => void
}

export const CalculatorInvestmentInformationForm = (props: CalculatorInvestmentInformationFormProps) => {
  const { zevHasVat, zevPriceCalculatorInput, dataStatusChanged } = props
  const { t } = useTranslation("my-zev")
  const [returnOnInvestmentCostsError, setReturnOnInvestmentCostsError] = useState<boolean>(false)

  return (
    <FormView>
      <PriceCalculatorRow
        label={`${t("section-main.tab.priceCalculator.investmentInformation.investmentCosts")}${
          zevHasVat ? ` ${t("section-main.tab.priceCalculator.vat.excluded")}` : ""
        }`}
        info={t("section-main.tab.priceCalculator.investmentInformation.investmentCosts.info")}
        childField={
          <NumberFormat
            fullWidth
            fixedDecimalScale
            key={"section-main.tab.priceCalculator.investmentInformation.investmentCosts"}
            name={"section-main.tab.priceCalculator.investmentInformation.investmentCosts"}
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            decimalScale={2}
            value={zevPriceCalculatorInput.totalInvestmentAmount}
            suffix="  CHF"
            thousandSeparator="'"
            onValueChange={(totalInvestmentAmount) =>
              dataStatusChanged({
                ...zevPriceCalculatorInput,
                totalInvestmentAmount: totalInvestmentAmount.floatValue ?? 0,
              })
            }
          />
        }
      />
      <PriceCalculatorRow
        label={t("section-main.tab.priceCalculator.investmentInformation.depreciationPeriod")}
        info={t("section-main.tab.priceCalculator.investmentInformation.depreciationPeriod.info")}
        childField={
          <NumberFormat
            fullWidth
            key={"section-main.tab.priceCalculator.investmentInformation.depreciationPeriod"}
            name={"section-main.tab.priceCalculator.investmentInformation.depreciationPeriod"}
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            decimalScale={0}
            fixedDecimalScale
            value={zevPriceCalculatorInput.deprecationPeriodInYears}
            suffix={t("section-main.tab.priceCalculator.textfield.units.years")}
            thousandSeparator="'"
            onValueChange={(deprecationPeriodInYears) =>
              dataStatusChanged({
                ...zevPriceCalculatorInput,
                deprecationPeriodInYears: deprecationPeriodInYears.floatValue ?? 0,
              })
            }
          />
        }
      />
      <PriceCalculatorRow
        label={t("section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts")}
        info={t("section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts.info")}
        childField={
          <NumberFormat
            fullWidth
            key={"section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts"}
            name={"section-main.tab.priceCalculator.investmentInformation.returnOnInvestmentCosts"}
            customInput={TextField}
            variant="standard"
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale
            value={zevPriceCalculatorInput.interestRateOnInvestment}
            suffix=" %"
            thousandSeparator="'"
            error={returnOnInvestmentCostsError}
            helperText={
              returnOnInvestmentCostsError ? t("section-main.tab.priceCalculator.investmentInformation.constraint") : ""
            }
            onValueChange={(returnOnInvestmentCosts) => {
              setReturnOnInvestmentCostsError(returnOnInvestmentCosts.floatValue === 0)
              dataStatusChanged({
                ...zevPriceCalculatorInput,
                interestRateOnInvestment: returnOnInvestmentCosts.floatValue ?? 0,
              })
            }}
          />
        }
      />
    </FormView>
  )
}

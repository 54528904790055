import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"

export const BuildingCreateSchema = yup.object().shape({
  name: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("buildings:form.field.buildingName"),
      }),
    ),
  address: yup.object({
    street: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("buildings:form.field.addressStreet"),
        }),
      ),
    houseNumber: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("buildings:form.field.addressHouseNumber"),
        }),
      ),
    postalCode: yup
      .string()
      .trim()
      .min(4, () =>
        getTranslateValue("shared:validation.length", {
          field: getTranslateValue("buildings:form.field.addressPostCode"),
          length: 4,
        }),
      )
      .max(6, () =>
        getTranslateValue("shared:validation.max.length", {
          field: getTranslateValue("buildings:form.field.addressPostCode"),
          length: 6,
        }),
      ),
    city: yup
      .string()
      .max(255)
      .trim()
      .required(() =>
        getTranslateValue("shared:validation.mandatory", {
          field: getTranslateValue("buildings:form.field.addressCity"),
        }),
      ),
  }),
})

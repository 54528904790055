import { FormikErrors } from "formik"
import { TFunction } from "i18next"
import { validationResult, ValidField } from "../../../../Shared.Validation"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"

export function validateUtilityUnitParticipantMoveIn(
  moveInDate: string,
  moveOutDate: string,
  prevParticipantMoveOut: string,
  t: TFunction,
): FormikErrors<{ moveInDate: number }> {
  return validationResult({
    moveInDate: validateMoveInDate(
      apiFormattedDateToTimestamp(moveInDate),
      moveOutDate ? apiFormattedDateToTimestamp(moveOutDate) : 0,
      prevParticipantMoveOut ? apiFormattedDateToTimestamp(prevParticipantMoveOut) : 0,
      t,
    ),
  })
}

const validateMoveInDate = (moveInDate: number, moveOutDate: number, prevParticipantMoveOut: number, t: TFunction) => {
  if (moveInDate === -1) {
    return t("shared:validation.mandatory", {
      field: t("moveIn.form.moveInDate"),
    })
  } else if (
    (prevParticipantMoveOut && moveInDate <= prevParticipantMoveOut) ||
    (moveOutDate && moveInDate >= moveOutDate)
  ) {
    return t("moveInDate.form.error", {
      field: t("moveIn.form.moveInDate"),
    })
  }
  return ValidField
}

import { I18nNamespace } from "../App.i18n"

export const AppContainerI18n: I18nNamespace = {
  name: "appContainer",
  de: {
    "admin.header.profile": "PROFIL",
    "admin.header.profile.menu.myprofile": "Mein Profil",
    "admin.header.profile.menu.settings": "Einstellungen",
    "admin.header.profile.menu.logout": "Abmelden",
    "admin.navigation.title.product": "PRODUKT",
    "admin.navigation.products": "Produkte",
    "admin.navigation.title.profile": "BENUTZERVERWALTUNG",
    "admin.navigation.profiles": "Benutzer",
    "admin.navigation.title.zevtopology": "ZEV TOPOLOGIE",
    "admin.navigation.zevs": "ZEVs",
    "admin.navigation.buildings": "Anschlussobjekte",
    "admin.navigation.consumptionpoints": "Verbrauchsstellen",
    "admin.navigation.contracts": "Verträge",
    "admin.navigation.title.contracts": "VERTRAG",
    "admin.navigation.title.topology": "TOPOLOGIE",
    "admin.navigation.title.tariff": "TARIF MANAGEMENT",
    "admin.navigation.energySupplier": "Energieversorger",
    "admin.navigation.contractsManagement": "Vertragsverwaltung",
    "admin.navigation.meters": "Zählpunkte",
    "admin.navigation.utilityUnit": "Nutzeinheiten",
    "admin.navigation.area": "Areale",
    "admin.navigation.title.billing": "ABRECHNUNGEN",
    "admin.navigation.billingsDL": "DL-Abrechnungen",
    "admin.navigation.billingsTN": "TN-Abrechnungen",
    "admin.navigation.billingsInitial": "Initial",
    "admin.navigation.billingsRecurring": "Wiederkehrend",
    "admin.navigation.title.tasks": "AUFGABEN",
    "admin.navigation.tasklist": "Aufgabenliste",
    "admin.navigation.participantBillingManagement": "TN-Abrechnungen",
    "customer.navigation.DASHBOARD": "Willkommen",
    "customer.navigation.overview": "Übersicht",
    "customer.navigation.changeArea": "Areal wechseln:",
    "customer.navigation.ANALYSIS": "Analyse",
    "customer.navigation.UTILITY_UNIT": "Nutzeinheit",
    "customer.navigation.UTILITY_UNITS": "Nutzeinheiten",
    "customer.navigation.SETTINGS": "Einstellungen",
    "customer.navigation.BUILDINGS": "Verbrauchsstellen",
    "customer.navigation.BUILDING": "Verbrauchsstelle",
    "customer.navigation.PARTICIPANTS": "Teilnehmer",
    "customer.navigation.BILLING_VEWA": "Rechnung erstellen",
    "customer.navigation.BILLING_VEWA_BILLS": "Rechnung",
    "customer.navigation.BILLINGS": "Abrechnungen",
    "customer.navigation.BILLINGS.FINALIZE": "Abrechnung erstellt",
    "customer.navigation.BILLINGS.FINALIZE.INDIVIDUAL": "Mieterwechselabrechnung erstellen",
    "customer.navigation.BILLINGS.EDIT": "Rechnung erstellen",
    "customer.navigation.BILLINGS.EDIT.INDIVIDUAL": "Mieterwechselabrechnung erstellen",
    "customer.navigation.MY_ZEV": "Mein ZEV",
    "customer.navigation.ONBOARDING": "WILLKOMMEN!",
    "customer.navigation.FAQ": "FAQ",
    "customer.navigation.UNKNOWN": "Dashboard",
    "customer.navigation.billing.manager": "Abrechnungs-Manager",
    "customer.navigation.faq": "FAQ",
    "customer.navigation.contact": "Kontakt",
    "customer.navigation.explanation.videos": "Erklärvideos",
    "footer.home": "Home",
    "footer.faq": "FAQs",
    "footer.contact": "Kontakt",
    "footer.tutorial": "Erklärvideos",
    "footer.impressum": "Impressum",
    "footer.privacy": "Datenschutz",
    "footer.legal": "Rechtliche Hinweise",
    "customer.header.filter": "Suchen",
  },
  en: {
    "admin.header.profile": "PROFILE",
    "admin.header.profile.menu.myprofile": "My Profile",
    "admin.header.profile.menu.settings": "Settings",
    "admin.header.profile.menu.logout": "Logout",
    "admin.navigation.title.product": "PRODUCT",
    "admin.navigation.products": "Products",
    "admin.navigation.title.profile": "PROFILE",
    "admin.navigation.profiles": "Profile",
    "admin.navigation.title.zevtopology": "ZEV TOPOLOGY",
    "admin.navigation.zevs": "ZEVs",
    "admin.navigation.buildings": "Buildings",
    "admin.navigation.consumptionpoints": "Consumption Points",
    "admin.navigation.contracts": "Contracts",
    "admin.navigation.title.contracts": "CONTRACT",
    "admin.navigation.title.topology": "TOPOLOGY",
    "admin.navigation.title.tariff": "TARIFF MANAGEMENT",
    "admin.navigation.energySupplier": "Energy supplier",
    "admin.navigation.contractsManagement": "Contracts management",
    "admin.navigation.meters": "Meters",
    "admin.navigation.utilityUnit": "Utility Unit",
    "admin.navigation.area": "Areas",
    "admin.navigation.title.billing": "DL-BILLING",
    "admin.navigation.billingsInitial": "Initial",
    "admin.navigation.billingsRecurring": "Recurring",
    "admin.navigation.title.tasks": "TASKS",
    "admin.navigation.tasklist": "Task List",
    "admin.navigation.participantBillingManagement": "Participant Billings Management",
    "customer.navigation.overview": "Overview",
    "customer.navigation.changeArea": "Change area:",
    "customer.navigation.DASHBOARD": "Welcome",
    "customer.navigation.ANALYSIS": "Analysis",
    "customer.navigation.UTILITY_UNIT": "Utility Unit",
    "customer.navigation.UTILITY_UNITS": "Utility Units",
    "customer.navigation.SETTINGS": "Settings",
    "customer.navigation.BUILDINGS": "Buildings",
    "customer.navigation.BUILDING": "Building",
    "customer.navigation.PARTICIPANTS": "Participants",
    "customer.navigation.BILLINGS": "Billings",
    "customer.navigation.BILLING_VEWA": "Create a bill",
    "customer.navigation.BILLING_VEWA_BILLS": "Bill",
    "customer.navigation.MY_ZEV": "My ZEV",
    "customer.navigation.ONBOARDING": "WELCOME!",
    "customer.navigation.FAQ": "FAQ",
    "customer.navigation.UNKNOWN": "DASHBOARD",
    "customer.navigation.billing.manager": "Billing-Manager",
    "customer.navigation.faq": "FAQ",
    "customer.navigation.contact": "Contact",
    "customer.navigation.explanation.videos": "Explanation videos",
    "footer.home": "Home",
    "footer.faq": "FAQs",
    "footer.contact": "Contact",
    "footer.tutorial": "Tutorial",
    "footer.impressum": "About",
    "footer.privacy": "Data security",
    "footer.legal": "Legal",
    "customer.header.filter": "Seek",
  },
}

import { TypographyTypeMap, Typography } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"

export const Body1 = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography {...props} variant="body1" fontFamily="Montserrat">
      {children}
    </Typography>
  )
}

export const Body2 = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography {...props} variant="body2" fontFamily="Montserrat">
      {children}
    </Typography>
  )
}

export const Subtitle1 = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography {...props} variant="subtitle1" sx={{ fontWeight: 500, fontSize: "0.9em", fontFamily: "Montserrat" }}>
      {children}
    </Typography>
  )
}

export const Caption = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography {...props} variant="caption" fontFamily="Montserrat">
      {children}
    </Typography>
  )
}

export const Heading4 = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children, id } = props
  return (
    <Typography id={id} {...props} variant="h4" fontFamily="Montserrat">
      {children}
    </Typography>
  )
}

export const Heading5 = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography sx={{ fontWeight: 400 }} {...props} variant="h5" fontFamily="Montserrat">
      {children}
    </Typography>
  )
}

export const DataItemLabel = (props: DefaultComponentProps<TypographyTypeMap>) => {
  const { children } = props
  return (
    <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: "0.9em" }} {...props}>
      {children}
    </Typography>
  )
}

import { ButtonBase, IconButton, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, withRouter, useRouteMatch } from "react-router-dom"
import { AdminPortalRouteParams } from "../../portal/admin/AdminPortal.Routes"
import { AlignBottomBox } from "../../../uikit/box/AlignmentBox"
import { DividerBox } from "../../../uikit/box/DividerBox"
import { TinyPaddedBox } from "../../../uikit/box/PaddedBox"
import { BackIcon } from "../../../uikit/Shared.Icon"

export const AdminAppBackButton = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { history } = props
  const { t } = useTranslation()
  return (
    <ButtonBase
      onClick={() => history.goBack()}
      sx={{
        width: "100%",
      }}
    >
      <TinyPaddedBox
        sx={{
          width: "100%",
        }}
      >
        <AlignBottomBox>
          <BackIcon />
          <DividerBox />
          <Typography variant="body1">{t("shared:form.action.back")}</Typography>
        </AlignBottomBox>
      </TinyPaddedBox>
    </ButtonBase>
  )
})

export const CustomerAppBackButton = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { history } = props
  const matchOnboardingRoute = useRouteMatch("/:areaId/onboarding")

  const iconLayout = () => {
    if (!matchOnboardingRoute)
      return (
        <IconButton
          sx={{ color: "#365F10", alignSelf: "flex-end", paddingBottom: "1rem" }}
          onClick={() => {
            history.goBack()
          }}
        >
          <BackIcon />
        </IconButton>
      )

    return <div style={{ width: "40px" }}></div>
  }

  return <>{iconLayout()}</>
})

import { Grid, Paper } from "@mui/material"
import { useTranslation } from "react-i18next"
import { FormMode } from "../../../../../uikit/form/FormView"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { CommonFormikProps } from "../../../../Shared.Utils"
import { BuildingCreateAdminRequest, BuildingState } from "../../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../../domain/Domain.Model"

interface BuildingCreateUpdateFormProps extends CommonFormikProps<BuildingCreateAdminRequest> {
  mode: FormMode
  activeState?: BuildingState
}

export const BuildingCreateUpdate = ({
  values,
  errors,
  touched,
  mode,
  activeState,
  handleBlur,
  handleChange,
}: BuildingCreateUpdateFormProps) => {
  const { t } = useTranslation("buildings")

  return (
    <Paper>
      <Grid container>
        {activeState && mode === FormMode.UPDATE && (
          <Grid item xs={12} p={2}>
            <StatusView statusType={StatusType[activeState]} />
          </Grid>
        )}
        <Grid item xs={12} md={6} p={2}>
          <SingleLineTextField
            required
            autoFocus
            name="name"
            type="text"
            value={values.name}
            label={t("form.field.buildingName")}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.name ? errors.name : undefined}
          />
        </Grid>
        <Grid item xs={0} md={6}></Grid>
        <Grid item xs={12} md={6} p={2}>
          <SingleLineTextField
            required
            type="text"
            name="address.street"
            value={values.address?.street}
            label={t("form.field.addressStreet")}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.address?.street ? errors.address?.street : undefined}
          />
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <SingleLineTextField
            required
            type="text"
            name="address.houseNumber"
            value={values.address?.houseNumber}
            label={t("form.field.addressHouseNumber")}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.address?.houseNumber ? errors.address?.houseNumber : undefined}
          />
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <SingleLineTextField
            required
            type="text"
            name="address.postalCode"
            inputProps={{ maxLength: 6 }}
            value={values.address?.postalCode}
            label={t("form.field.addressPostCode")}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.address?.postalCode ? errors.address?.postalCode : undefined}
          />
        </Grid>
        <Grid item xs={12} md={6} p={2}>
          <SingleLineTextField
            required
            type="text"
            name="address.city"
            value={values.address?.city}
            label={t("form.field.addressCity")}
            onBlur={handleBlur}
            onChange={handleChange}
            helperText={touched.address?.city ? errors.address?.city : undefined}
          />
        </Grid>
      </Grid>
    </Paper>
  )
}

import { Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useAppSelector } from "../../../hooks"
import { ManagerBlackAlert } from "../../../../uikit/Shared.Alert"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { TutorialStatus } from "../../../../domain/auth/Auth.Model"
import ContractSelector from "../contractSelector/ContractSelector"
import { DashboardMeterReading } from "./DashboardMeterReading"
import { selectTutorialStatus } from "../store/uiSlice"
import { BillingsListTabs } from "../billings/BillingsListTabs"

export const DashboardOverview = ({ match: { params }, history }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { areaId } = params
  const { t } = useTranslation("dashboard")
  const tutorialStatus = useAppSelector(selectTutorialStatus)

  const navigateToOnboarding = () => history.push(`/${areaId}/onboarding`)

  return (
    <>
      <ContractSelector />
      <Box px={2}>
        {tutorialStatus !== TutorialStatus.COMPLETED && (
          <ManagerBlackAlert
            label={t("tutorial-not-completed")}
            buttonText={t("button.tutorial")}
            tooltipText={t("tutorial-not-completed.tooltip")}
            onClick={() => navigateToOnboarding()}
          />
        )}
      </Box>
      <DashboardMeterReading />
      <BillingsListTabs areaId={areaId} separateTables={false} />
    </>
  )
}

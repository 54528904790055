import { CircularProgress, IconButton, Stack } from "@mui/material"
import { useMutation } from "@tanstack/react-query"
import DateUtils from "../../../../../services/utils/DateUtils"
import { useTranslation } from "react-i18next"
import { Formik, Form } from "formik"
import { utilityParticipationChangeMoveOutDate } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

import { AbortIcon, ActivateIcon } from "../../../../../uikit/Shared.Icon"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { validateUtilityUnitParticipantMoveOutDate } from "./UtilityUnitParticipantMoveOutDateForm.Validation"

interface MoveOutDateEditProps {
  participation: UtilityUnitParticipationParticipant
  onEditClose: () => void
  onUpdateSuccess: () => void
  onUpdateError: (error: AxiosErrorDataType) => void
}

export const ParticipantMoveOutDateEdit = ({
  participation,
  onUpdateSuccess,
  onUpdateError,
  onEditClose,
}: MoveOutDateEditProps) => {
  const { moveInDate, moveOutDate, nextParticipantMoveIn } = participation
  const { t } = useTranslation("utilityUnitParticipations")

  const handleUpdateSuccess = () => {
    onUpdateSuccess()
    onEditClose()
  }

  const { mutate: changeMoveOutDate, isLoading: isMovingOut } = useMutation(
    ["changeMoveOutDate"],
    (moveOutDate: number) =>
      utilityParticipationChangeMoveOutDate(participation.utilityUnitId, participation.id, moveOutDate),
    {
      onSuccess: handleUpdateSuccess,
      onError: onUpdateError,
    },
  )

  const confirmMoveOut = ({ moveOutDate }: { moveOutDate: number }) => changeMoveOutDate(moveOutDate)

  return (
    <Formik<{ moveOutDate: number }>
      initialValues={{ moveOutDate: apiFormattedDateToTimestamp(moveOutDate) ?? 0 }}
      enableReinitialize
      onSubmit={confirmMoveOut}
      validate={(values) =>
        validateUtilityUnitParticipantMoveOutDate(
          DateUtils.getDeFormatDate(values.moveOutDate),
          moveInDate ?? "",
          t,
          nextParticipantMoveIn,
        )
      }
    >
      {({ values, handleBlur, handleSubmit, setFieldValue, errors, isValid, dirty }) => {
        return (
          <Form onSubmit={handleSubmit}>
            <Stack>
              <SingleLineDatePicker
                required
                name="moveOutDate"
                label=""
                onBlur={handleBlur}
                value={values.moveOutDate}
                helperText={errors.moveOutDate}
                onChange={(value) => setFieldValue("moveOutDate", value)}
                minDate={moveInDate ? DateUtils.addDateDays(moveInDate, 1) : undefined}
                maxDate={nextParticipantMoveIn ? DateUtils.substructDateDays(nextParticipantMoveIn, 1) : undefined}
              />
              <Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                <IconButton type="submit" color="primary" disabled={!isValid || !dirty} size="small">
                  {isMovingOut ? <CircularProgress color="primary" size={20} /> : <ActivateIcon />}
                </IconButton>
                <IconButton color="primary" onClick={onEditClose} size="small">
                  <AbortIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Form>
        )
      }}
    </Formik>
  )
}

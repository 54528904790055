import React, { useEffect, useState } from "react"
import { Box, Button, Grid, Paper, Stack, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { TariffPageTitle } from "./TariffSuppliers"
import { PrimaryButton, PrimaryOutlinedButton } from "../../../../uikit/button"
import { SaveIcon } from "../../../../uikit/Shared.Icon"
import AddIcon from "@mui/icons-material/Add"
import { appThemeGrey250, appThemeGrey60, newDesignSelectColorDark } from "../../../Shared.Theme"
import { TariffScheduleI } from "./TariffModels"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  adminCreateTariffSchedule,
  adminGetTariffScheduleById,
  adminUpdateTariffSchedule,
} from "../../../../domain/portal/admin/tariffs/Tariffs.Repository"
import { TariffScheduleResponse } from "../../../../data/generated-sources/openapi"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosError } from "axios"
import { defaultPeriod, formatPeriods, mapTariffResponse } from "../../../../services/utils/Tariffs.Utils"
import { FullScreenLoader } from "../../../../uikit/indicator/ProgressIndicator"
import TariffSchedulePeriodRow from "./fragments/TariffSchedulePeriodRow"

export const TariffSupplierAddUpdate = ({ history, match }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("tariff")
  const [periods, setPeriods] = useState<TariffScheduleI[]>([])
  const [energySupplier, setEnergySupplier] = useState<string>("")
  const [errorMsg, setErrorMsg] = useState<string>("")
  const tariffId = match.params.tariffId

  const { mutate: addPeriodMutation } = useMutation(
    ["addPeriod"],
    () =>
      adminCreateTariffSchedule({
        ...formatPeriods(periods),
        name: energySupplier,
      }),
    {
      onSuccess: () => {
        history.push("/tariff")
      },
      onError: (err: AxiosError<Error>) => {
        setErrorMsg(err?.response?.data?.message ?? t("error-codes:GENERIC_FATAL_ERROR"))
      },
    },
  )

  const { mutate: updateTariiffMutation } = useMutation(
    ["updateTariff"],
    () =>
      adminUpdateTariffSchedule(tariffId, {
        ...formatPeriods(periods),
        name: energySupplier,
      }),
    {
      onSuccess: () => {
        history.push("/tariff")
      },
      onError: (err: AxiosError<Error>) => {
        setErrorMsg(err?.response?.data?.message ?? t("error-codes:GENERIC_FATAL_ERROR"))
      },
    },
  )

  const { isFetching, remove } = useQuery(["getTariff", tariffId], () => adminGetTariffScheduleById(tariffId), {
    enabled: !!tariffId,
    onSuccess: (data: TariffScheduleResponse) => {
      setEnergySupplier(data.name)
      setPeriods(mapTariffResponse(data))
    },
    onError: (err: AxiosError<Error>) => {
      setErrorMsg(err?.response?.data?.message ?? t("error-codes:GENERIC_FATAL_ERROR"))
    },
  })

  useEffect(() => () => remove(), [remove])

  const headerRowConfig = [
    {
      label: t("lowTariff"),
      xs: 2,
    },
    {
      label: t("validOnTheseDays"),
      xs: 4,
    },
    {
      label: t("validFrom"),
      xs: 2,
    },
    {
      label: t("validUntil"),
      xs: 2,
    },
  ]

  const addPeriod = () => {
    const newPeriodName = periods.length ? Number(periods[periods.length - 1].name) + 1 : "1"
    const newDefaultPeriod = Object.assign({}, defaultPeriod, { name: String(newPeriodName), days: [] })

    setPeriods([...periods, newDefaultPeriod])
  }

  const updatePeriod = (newPeriod: TariffScheduleI) => {
    setPeriods(periods.map((p) => (p.name === newPeriod.name ? newPeriod : p)))
  }

  const deletePeriod = (periodName: string) => {
    setPeriods(periods.filter((p) => p.name !== periodName))
  }

  const validateForm = () => {
    if (!energySupplier) {
      setErrorMsg(t("errorMessage.pleaseSetEnergySupplier"))
      return false
    }
    return true
  }

  const submitPeriods = () => {
    setErrorMsg("")
    if (!validateForm()) {
      return
    }

    if (tariffId) {
      return updateTariiffMutation()
    }
    addPeriodMutation()
  }

  return (
    <>
      <Paper>
        <TariffPageTitle data-testid="addSupplierTitle">{t("addSupplier")}</TariffPageTitle>
        {isFetching && <FullScreenLoader />}
        <Box my={4}>
          <ErrorAlert visible={!!errorMsg} message={errorMsg} />
          <TextField
            label="Energieversorger"
            variant="standard"
            margin="normal"
            value={energySupplier}
            onChange={(e) => setEnergySupplier(e.target.value)}
          />
          <Box mt={5} p={2} pb={4} borderBottom={"1px solid #DBDBDB"}>
            <Grid container spacing={2} alignItems="center" bgcolor={appThemeGrey60} p={2} borderRadius={2} mb={2}>
              {headerRowConfig.map((config) => (
                <Grid key={config.label} item xs={config.xs}>
                  <Typography variant="body1" fontWeight={"700"} color={newDesignSelectColorDark}>
                    {config.label}
                  </Typography>
                </Grid>
              ))}
            </Grid>
            {periods.length === 0 && (
              <Box mt={3} pl={2}>
                <Typography variant="caption" color={appThemeGrey250}>
                  {t("definePeriod")}
                </Typography>
              </Box>
            )}
            {periods.map((period, index) => (
              <TariffSchedulePeriodRow
                key={index}
                period={period}
                setPeriods={(newPeriod: TariffScheduleI) => updatePeriod(newPeriod)}
                onDelete={() => deletePeriod(period.name)}
              />
            ))}
          </Box>
          <Box mt={2} pl={4}>
            <Typography variant="caption" color={appThemeGrey250}>
              {t("noteMessage")}
            </Typography>
          </Box>
          <Box mt={3}>
            <PrimaryOutlinedButton
              label={t("addPeriod")}
              startIcon={<AddIcon />}
              onClick={addPeriod}
              variant="text"
              color="primary"
            />
          </Box>
        </Box>
      </Paper>
      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"}>
        <Button variant="outlined" onClick={() => history.push("/tariff")} data-testid="canceAddingSupplierBtn">
          {t("shared:form.action.cancel")}
        </Button>
        <PrimaryButton
          isLoading={false}
          startIcon={<SaveIcon />}
          data-testid="addEnergySupplierBtn"
          onClick={submitPeriods}
          label={t("shared:form.action.save")}
        />
      </Stack>
    </>
  )
}

import { Grid, styled, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"

const AddressField = styled(Typography)(({ theme }) => ({
  color: theme.palette.secondary.dark,
  fontSize: theme.typography.pxToRem(12),
}))

const AddressFieldValue = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  fontSize: theme.typography.pxToRem(16),
}))

export const BuildingDetailsData = ({ name, address }: BuildingAdminResponse) => {
  const { t } = useTranslation("buildings")

  return (
    <>
      <Typography variant="h4" mb={4}>
        {name}
      </Typography>

      <Grid container mb={4}>
        <Grid item xs={6}>
          <AddressField>{t("form.field.addressStreet")}</AddressField>
          <AddressFieldValue>{address?.street}</AddressFieldValue>
        </Grid>
        <Grid item xs={6}>
          <AddressField>{t("form.field.addressHouseNumber")}</AddressField>
          <AddressFieldValue>{address?.houseNumber}</AddressFieldValue>
        </Grid>

        <Grid item xs={6}>
          <AddressField>{t("form.field.addressPostCode")}</AddressField>
          <AddressFieldValue>{address?.postalCode}</AddressFieldValue>
        </Grid>
        <Grid item xs={6}>
          <AddressField>{t("form.field.addressCity")}</AddressField>
          <AddressFieldValue>{address?.city}</AddressFieldValue>
        </Grid>
      </Grid>
    </>
  )
}

import { Box, IconButton, Typography, Stack, styled } from "@mui/material"
import { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { ContactIconFilled } from "../../../../uikit/Shared.Icon"
import { determineSelectedNavigation } from "../navigation/CustomerNavigationView"
import { AppHeaderViewProps } from "./AppHeaderView"
import ProfileDetailsConnect from "../../../portal/manager/profile/ProfileDetails.Connect"
import { CustomerAppBackButton } from "../AppBackButton"

const HeaderBox = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  position: "fixed",
  left: 281,
  zIndex: 10,
  backgroundColor: "#FFFFFF",
  borderBottom: "1px solid #DBDFDB",
  height: "100px",
  width: "calc(100vw - 280px)",
}))

const PageTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 300,
  fontSize: theme.typography.pxToRem(36),
  textTransform: "uppercase",
  color: theme.palette.text.primary,
}))

const PageSubTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  fontSize: theme.typography.pxToRem(14),
  color: theme.palette.text.primary,
}))

export interface CustomerHeaderViewProps {
  navigateCustomerRoot: (areaId: string) => void
}

export const CustomerHeaderView = (props: AppHeaderViewProps) => {
  const { t } = useTranslation()
  const location = useLocation()
  const { logout, pageSubTitle } = props

  const [openDrawer, setOpenDrawer] = useState(false)

  const selectedNavigation = useMemo(() => {
    const navigation = determineSelectedNavigation(location.pathname)
    return t(`appContainer:customer.navigation.${navigation}`)
  }, [location, t])

  const currentAreaId = useMemo(() => location.pathname.split("/")[1] ?? "", [location])
  const showBackButton = (location.pathname.match(/\//g)?.length || 0) >= 3

  useEffect(() => {
    if (location) {
      setOpenDrawer(false)
    }
  }, [location])

  return (
    <HeaderBox>
      {showBackButton ? (
        <Stack direction={"row"} pl={0.5}>
          <CustomerAppBackButton />
          <Box>
            <PageSubTitle>{selectedNavigation}</PageSubTitle>
            <PageTitle>{pageSubTitle || selectedNavigation}</PageTitle>
          </Box>
        </Stack>
      ) : (
        <PageTitle pl={5}>{selectedNavigation}</PageTitle>
      )}
      <IconButton
        onClick={() => setOpenDrawer(true)}
        sx={{
          color: "#fff",
          background: "linear-gradient(256.56deg, #9AB20F -22.21%, #4E7818 65.58%)",
          mr: 5,
        }}
      >
        <ContactIconFilled fontSize="medium" color="inherit" />
      </IconButton>
      <ProfileDetailsConnect
        key={openDrawer ? "open" : "close"}
        open={openDrawer}
        areaId={currentAreaId}
        closeDrawer={() => setOpenDrawer(false)}
        logout={logout}
      />
    </HeaderBox>
  )
}

import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"

import { getAreaById } from "../../../../domain/portal/admin/areas/Areas.Repository"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { AreaIcon } from "../../../../uikit/Shared.Icon"
import { getParamsFromUrl } from "../../../Shared.Utils"

export const AreasBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("area")
  const areaUrlParams = getParamsFromUrl("/area/:id")

  const { data: areaData, remove } = useQuery(["getArea"], () => getAreaById(areaUrlParams?.id), {
    enabled: !!areaUrlParams?.id,
  })

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove, areaUrlParams?.id])

  const AreaStartIcon = useCallback(() => {
    const redirectToAreas = () => history.push("/area")
    return (
      <>
        <BreadcrumbsHomeIcon redirectUrl={redirectToAreas} icon={<AreaIcon />} />
        <BreadcrumbsNextIcon />
      </>
    )
  }, [history])

  const AreaBreadcrumbIcon = useCallback(() => {
    return (
      <>
        <AreaStartIcon />
        <BreadcrumbsNavButton label={areaData?.name} onClick={() => history.push(`/area/${areaUrlParams?.id}`)} />
      </>
    )
  }, [history, areaData, areaUrlParams?.id, AreaStartIcon])

  return (
    <Switch>
      <Route path="/area/:id/participants/create" exact>
        <AreaBreadcrumbIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.participantCreate")} />
      </Route>

      <Route path="/area/:id/contract/create" exact>
        <AreaBreadcrumbIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.contract")} />
      </Route>

      <Route path="/area/:id/building/create" exact>
        <AreaBreadcrumbIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.building.create")} />
      </Route>

      <Route path="/area/:id/update" exact>
        <AreaBreadcrumbIcon />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.areaEdit")} />
      </Route>

      <Route path="/area/:id" exact>
        <AreaStartIcon />
        <BreadcrumbsNavButton disabled label={areaData?.name} />
      </Route>

      <Route path="/area/create/new">
        <AreaStartIcon />
        <BreadcrumbsNavButton label={t("breadcrumb.areaCreate")} disabled />
      </Route>

      <Route path="/area" exact>
        <AreaStartIcon />
        <BreadcrumbsNavButton label={t("label.name")} disabled />
      </Route>
    </Switch>
  )
}

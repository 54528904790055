import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { CircularProgress, Box } from "@mui/material"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  customerGetVewaCostSharesByContractId,
  customerPutVewaCostSharesByContractId,
} from "../../../../domain/portal/manager/contracts/Contract.Repository"

import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { OnboardingActionButtons, OnboardingSteperInfo } from "./fragments/OnboardingUI"
import { VewaBillingCostShares } from "../../shared/vewaBillings/VewaBillingCostShares"
import { VewaCostShare, VewaCostShares } from "../../../../data/generated-sources/openapi"
import { AxiosErrorDataType } from "../../../Shared.Utils"

interface OnboardingElectricityPricesInfoProps {
  contractId: string
  onBackClick: () => void
  onNextClick: () => void
}

export const OnboardingVewaCostShares = ({
  contractId,
  onBackClick,
  onNextClick,
}: OnboardingElectricityPricesInfoProps) => {
  const { t } = useTranslation("onboarding")
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: vewaCostShares,
    remove: vewaCostSharesRemove,
    refetch: refetchCostShares,
    isFetching,
  } = useQuery(["getVewaCostShares"], () => customerGetVewaCostSharesByContractId(contractId), {
    enabled: !!contractId,
    onError: setError,
  })

  const { mutate: updateCostShares, isLoading: isUpdating } = useMutation(
    ["updateCostShares"],
    (vewaCostShares: VewaCostShares) => customerPutVewaCostSharesByContractId(contractId, vewaCostShares),
    {
      onError: setError,
      onSuccess: () => refetchCostShares(),
    },
  )

  useEffect(() => {
    return () => {
      vewaCostSharesRemove()
    }
  }, [vewaCostSharesRemove])

  const handleUpdate = (vewaCostShares: VewaCostShare[]) => {
    setError(undefined)
    updateCostShares({ costShares: vewaCostShares })
  }

  return (
    <>
      <OnboardingSteperInfo
        stepNumber={"3"}
        title={t("costShares.title")}
        subTitle={t("costShares.subTitle1")}
        linkText={t("costShares.linkText")}
        linkUrl={t("https://www.ckw.ch/")}
      />
      <Box py={3}>
        <ErrorAlert
          scrollOnDisplay
          visible={!!error}
          message={t(`error-codes:${error?.response?.data?.code || "OTHER"}`)}
        />
        {isFetching ? (
          <CircularProgress />
        ) : (
          <>
            <VewaBillingCostShares costShares={vewaCostShares?.costShares} onUpdateSubmit={handleUpdate} />
            <OnboardingActionButtons
              onNextClick={onNextClick}
              onBackClick={onBackClick}
              nextBtnDisabled={isUpdating}
              isNextBtnLoading={isUpdating}
            />
          </>
        )}
      </Box>
    </>
  )
}

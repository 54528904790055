import { useTranslation } from "react-i18next"
import { useQuery } from "@tanstack/react-query"
import { Box, CircularProgress } from "@mui/material"
import * as yup from "yup"

import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../../uikit/Shared.Alert"
import {
  getBillingSettingsByContractId,
  getContractManagementById,
  getProductsForContract,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { AxiosErrorDataType, CommonFormikProps, getTranslateValue } from "../../../../Shared.Utils"
import { useEffect, useState } from "react"
import DateUtils from "../../../../../services/utils/DateUtils"
import { BillingFrequency, ProductType } from "../../../../../data/generated-sources/openapi"
import { getNextBillingData } from "../../../../../services/utils/BillingUtilis"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"

export interface IReplaceContract {
  contractId: string
  endDate: string
  productId: string
}

interface ReplaceContractProps extends CommonFormikProps<IReplaceContract> {
  showSuccessMessage: boolean
  updateError?: AxiosErrorDataType
}

export const replaceContractSchema = yup.object().shape({
  endDate: yup.date().required(() => getTranslateValue("shared:validation.mandatory")),
  productId: yup.string().required(() =>
    getTranslateValue("shared:validation.mandatory", {
      field: getTranslateValue("shared:form.product"),
    }),
  ),
})

export const ReplaceContract = ({
  values,
  errors,
  touched,
  handleBlur,
  handleChange,
  setFieldValue,
  updateError,
  showSuccessMessage,
}: ReplaceContractProps) => {
  const { t } = useTranslation("shared")
  const [replaceError, setReplaceError] = useState<AxiosErrorDataType>()

  const {
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
    data: billing,
    remove: removeBilling,
  } = useQuery(["getBilling"], () => getBillingSettingsByContractId(values.contractId), {
    enabled: !!values.contractId,
    onError: setReplaceError,
  })

  const {
    data: contract,
    remove: removeContract,
    isLoading: isLoadingContracts,
    isFetching: isFetchingContracts,
  } = useQuery(["getContract"], () => getContractManagementById(values.contractId), {
    enabled: !!values.contractId,
    onError: setReplaceError,
  })

  const {
    data: products,
    remove: removeProducts,
    isLoading: isLoadingProducts,
  } = useQuery(["getAreaProducts"], () => getProductsForContract(), {
    onError: setReplaceError,
  })

  useEffect(() => {
    return () => {
      removeProducts()
      removeContract()
      removeBilling()
      setReplaceError(undefined)
    }
  }, [removeProducts, removeContract, removeBilling])

  const productType = contract?.product?.productType
  const vewaStartDate = billing?.data?.billingPeriod.startDate ?? contract?.startDate

  const { nextBillingAvailableDates, vewaMinDate, vewaMaxDate } = getNextBillingData(
    contract?.product?.productType ?? ProductType.ZEV,
    billing?.data?.billingPeriod.startDate ?? "",
    billing?.data?.billingFrequency ?? BillingFrequency.YEARLY,
    vewaStartDate,
  )

  return isLoadingProducts || (isLoadingBilling && isFetchingBilling) || (isLoadingContracts && isFetchingContracts) ? (
    <CircularProgress color="primary" />
  ) : (
    <>
      <OptionalSuccessAlert show={showSuccessMessage} message={t("contracts-management:contract.update.success")} />
      <ErrorAlert
        visible={!!updateError || !!replaceError}
        message={t(`error-codes:${updateError?.response?.data?.code || replaceError?.response?.data?.code || "OTHER"}`)}
      />
      <Box>
        {productType === ProductType.ZEV ? (
          <SelectPicker
            type="text"
            name="nextBillingDate"
            label={t("form.endDate")}
            value={values.endDate || ""}
            items={nextBillingAvailableDates.map((billingDate) => ({
              label: DateUtils.getDeFormatDateWithMonthString(billingDate),
              value: DateUtils.getDeFormatDate(billingDate),
            }))}
            onBlur={handleBlur}
            onChange={({ target }) => setFieldValue("endDate", target.value)}
            helperText={touched.endDate && errors.endDate ? errors.endDate : undefined}
          />
        ) : (
          <SingleLineDatePicker
            required
            name="endDate"
            label={t("form.endDate")}
            value={apiFormattedDateToTimestamp(values.endDate)}
            onBlur={handleBlur}
            onChange={(value) => setFieldValue("endDate", DateUtils.getDeFormatDate(value))}
            helperText={touched.endDate && errors.endDate ? errors.endDate : undefined}
            {...(vewaMinDate && { minDate: vewaMinDate })}
            {...(vewaMaxDate && { maxDate: vewaMaxDate })}
          />
        )}
      </Box>
      <Box py={6}>
        <SelectPicker
          required
          type="text"
          name="productId"
          label={t("form.product")}
          emptyValue="None"
          value={values.productId}
          items={(products ?? []).map((product) => ({
            label: product.name,
            value: product.id,
          }))}
          onChange={handleChange}
          onBlur={handleBlur}
          helperText={touched.productId && errors.productId ? errors.productId : undefined}
        />
      </Box>
    </>
  )
}

import { useEffect, useMemo, useState } from "react"
import { Redirect, RouteComponentProps } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Chip, Stack, Grid, Typography, Paper } from "@mui/material"

import { ProgressIndicator } from "../../../../uikit/progress/ProgressIndicator"
import { coerce } from "../../../Shared.View"
import { SmallPaddedBox, TinyPaddedBox } from "../../../../uikit/box/PaddedBox"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { firstViewState } from "../../../Shared.Reducer"
import { ErrorAlert, SuccessAlert } from "../../../../uikit/Shared.Alert"
import { PrimaryEditButton } from "../../../../uikit/button/PrimaryEditButton"
import { ProductsDetailState } from "./ProductsDetail.Reducer"
import { FormRowColumn } from "../../../../uikit/form/FormView"
import { AlignEndBox } from "../../../../uikit/box/AlignmentBox"
import { DataItemBox } from "../../../../uikit/box/DataItemBox"
import { DividerBox } from "../../../../uikit/box/DividerBox"
import { PrimaryButton } from "../../../../uikit/button"
import { PrimaryPlusButton } from "../../../../uikit/button/PrimaryPlusButton"
import { RemoveIcon } from "../../../../uikit/Shared.Icon"
import { PriceComponentsTableView } from "./views/PriceComponentsTableView"
import { mapDispatchToProps } from "./ProductsDetail.Connect"
import { ProductUpdatePriceDialogViewComponent } from "./views/ProductUpdatePriceDlgView"
import { ProductCreatePriceDialogViewComponent } from "./views/ProductCreatePriceDlgView"

interface ProductsDetailComponentProps
  extends ProductsDetailState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const ProductsDetailComponent = (props: ProductsDetailComponentProps) => {
  const { t } = useTranslation("products")
  const {
    getProductViewState,
    getProductById,
    navigateToUpdateProduct,
    deletePriceComponent,
    deletePriceComponentViewState,
    prevPriceComponentDeleteId,
    deleteProductViewState,
    deleteProduct,
    match,
    showUpdateAlert,
    showPriceComponentCreateSuccessAlert,
    showPriceComponentUpdateSuccessAlert,
    getProductPriceComponentUpdateById,
    getProductPriceComponentViewState,
    updatePriceComponent,
    updateProductPriceComponentViewState,
    createPriceComponent,
    createProductPriceComponentViewState,
  } = props

  const [showPriceUpdateDialog, setShowPriceUpdateDialog] = useState<string | undefined>(undefined)
  const [showPriceCreateDialog, setShowPriceCreateDialog] = useState<boolean>(false)

  const priceComponentsList = useMemo(
    () => getProductViewState.domainResult?.priceComponents ?? [],
    [getProductViewState],
  )

  useEffect(() => {
    if (firstViewState(getProductViewState)) {
      getProductById(match.params.productId)
    }
  }, [getProductViewState, match.params?.productId, getProductById])

  useEffect(() => {
    if (showPriceUpdateDialog) {
      getProductPriceComponentUpdateById(match.params.productId, showPriceUpdateDialog)
    }
  }, [showPriceUpdateDialog, match.params?.productId, getProductPriceComponentUpdateById])

  if (getProductViewState.isLoading) return <ProgressIndicator />

  return (
    <>
      {deleteProductViewState.domainResult && <Redirect to="/products" />}
      {showUpdateAlert && <SuccessAlert message={t("update.alert.success")} />}
      {showPriceComponentCreateSuccessAlert && <SuccessAlert message={t("create.price.alert.success")} />}
      {showPriceComponentUpdateSuccessAlert && <SuccessAlert message={t("update.price.alert.success")} />}
      {getProductViewState.domainError && <ErrorAlert message={getProductViewState.domainError.message} />}
      {coerce(getProductViewState.domainResult, (productDetail) => (
        <>
          <Paper>
            <FormRowColumn>
              <AlignEndBox>
                <SmallPaddedBox>
                  <PrimaryEditButton onClick={() => navigateToUpdateProduct(productDetail.id)} />
                </SmallPaddedBox>
              </AlignEndBox>
            </FormRowColumn>
            <Grid container>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("field.label.products")} value={productDetail.name} />
              </Grid>
              <Grid item xs={12} md={6}>
                <DataItemBox title={t("form.field.productType")} value={productDetail?.productType} />
              </Grid>
            </Grid>
            <DividerBox />
            <TinyPaddedBox>
              <Typography variant="h5">{t("field.header.base-data")}</Typography>
              <DataItemBox
                title={t("field.label.serviceComponents")}
                value={
                  <Stack direction="row" flexWrap={"wrap"} py={2}>
                    {productDetail.serviceComponents.map((serviceComponent) => (
                      <Chip
                        sx={{ mb: 1, mr: 2 }}
                        label={serviceComponent.name}
                        color="primary"
                        key={serviceComponent.id}
                        variant="outlined"
                      />
                    ))}
                  </Stack>
                }
              />
              {deleteProductViewState.domainError && (
                <>
                  <ErrorAlert message={deleteProductViewState.domainError.message} />
                  <DividerBox />
                </>
              )}
              <PrimaryButton
                startIcon={<RemoveIcon fontSize="large" />}
                label={t("cta.delete-product")}
                isLoading={deleteProductViewState.isLoading}
                disabled={deleteProductViewState.isLoading}
                onClick={() => deleteProduct(productDetail.id)}
              />
              <DividerBox />
            </TinyPaddedBox>
          </Paper>
          <Paper>
            <AlignEndBox>
              <SmallPaddedBox>
                <PrimaryPlusButton onClick={() => setShowPriceCreateDialog(true)} />
              </SmallPaddedBox>
            </AlignEndBox>
            {deletePriceComponentViewState.domainResult.get(prevPriceComponentDeleteId ?? "") && (
              <>
                <SuccessAlert message={t("delete.price.alert.success")} />
                <DividerBox />
              </>
            )}
            <PriceComponentsTableView
              priceComponents={priceComponentsList}
              navigateToUpdatePriceComponent={(priceComponentId) => setShowPriceUpdateDialog(priceComponentId)}
              deletePriceComponent={(priceComponentId) => deletePriceComponent(productDetail.id, priceComponentId)}
              deletePriceComponentViewState={deletePriceComponentViewState}
            />
            {showPriceUpdateDialog && getProductPriceComponentViewState.domainResult && (
              <ProductUpdatePriceDialogViewComponent
                key={showPriceUpdateDialog}
                open={!!showPriceUpdateDialog}
                onClose={() => setShowPriceUpdateDialog(undefined)}
                productId={productDetail.id}
                priceId={showPriceUpdateDialog}
                updatePriceComponent={updatePriceComponent}
                getProductPriceComponentViewState={getProductPriceComponentViewState}
                updateProductPriceComponentViewState={updateProductPriceComponentViewState}
              />
            )}
            {showPriceCreateDialog && (
              <ProductCreatePriceDialogViewComponent
                open={showPriceCreateDialog}
                onClose={() => setShowPriceCreateDialog(false)}
                productId={productDetail.id}
                createPriceComponent={createPriceComponent}
                createProductPriceComponentViewState={createProductPriceComponentViewState}
              />
            )}
          </Paper>
        </>
      ))}
    </>
  )
}

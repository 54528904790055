import { Box, Button, Modal, Typography } from "@mui/material"
import React from "react"
import { Body1 } from "../typography/Typography"
import { PrimaryButton } from "../button/PrimaryButton"
import { CancelButton } from "../button/CancelButton"

const style: { [key: string]: React.CSSProperties } = {
  modal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "600px",
    backgroundColor: "rgba(0, 0, 0, 0.7)",
    border: "2px solid #000",
    boxShadow: "24",
    padding: "20px",
  },
  whiteColor: {
    color: "#fff",
  },
  marginZero: {
    margin: 0,
  },
  buttonStyle: {
    display: "inline-block",
    textTransform: "none",
    margin: 0,
    padding: 0,
  },
  closeButton: {
    backgroundColor: "transparent",
    border: 0,
    color: "#fff",
    cursor: "pointer",
    display: "block",
    fontFamily: "Arial,Baskerville,monospace",
    fontSize: "22px",
    fontStyle: "normal",
    fontWeight: "bold",
    lineHeight: 1,
    margin: "calc(-1em - 15px) -18px -8px auto",
    opacity: "0.65",
    outline: "none",
    padding: "10px 16px",
    position: "relative",
    textDecoration: "none",
    transition: "opacity .2s linear",
    userSelect: "none",
    zIndex: 1,
  },
}

interface ModalComponentInterface {
  buttonText: string | React.ReactNode
  buttonStyle?: object
  buttonDisabled?: boolean
  body: string | React.ReactNode
  title?: string
  modalStyle?: object
  bodyStyle?: object
  titleStyle?: object
  closeButtonText?: string
  ctaButtonText?: string
  ctaButtonAction?: (handleClose: () => void) => void
  useCloseBtn?: boolean
}

export const ModalComponent = (props: ModalComponentInterface) => {
  const {
    buttonText,
    buttonStyle,
    buttonDisabled,
    body,
    title,
    modalStyle,
    bodyStyle,
    titleStyle,
    closeButtonText,
    ctaButtonText,
    ctaButtonAction,
    useCloseBtn = true,
  } = props || ({} as Partial<ModalComponentInterface>)
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => !buttonDisabled && setOpen(true)
  const handleClose = () => setOpen(false)

  const titleLayout = () => {
    return (
      title && (
        <Typography
          id="modal-modal-title"
          style={{ ...style.whiteColor, ...(titleStyle || {}) }}
          variant="h6"
          component="h2"
        >
          {title}
        </Typography>
      )
    )
  }

  const bodyLayout = () => {
    return (
      body && (
        <Body1 id="modal-modal-description" style={{ ...style.whiteColor, ...style.marginZero, ...(bodyStyle || {}) }}>
          {body}
        </Body1>
      )
    )
  }

  const buttonLayout = () => {
    if (typeof buttonText === "string")
      return <Button style={{ ...style.buttonStyle, ...(buttonStyle || {}) }}>{buttonText}</Button>
    return buttonText
  }

  const footerLayout = () => {
    return (
      (closeButtonText || ctaButtonText) && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          {closeButtonText && <CancelButton noIcon={true} onClick={handleClose} />}
          {/*eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
          {/*@ts-ignore*/}
          {ctaButtonText && <PrimaryButton label={ctaButtonText} onClick={() => ctaButtonAction(handleClose)} />}
        </div>
      )
    )
  }
  return (
    <>
      <div onClick={handleOpen}>{buttonLayout()}</div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={{ ...style.modal, ...(modalStyle || {}) }}>
          {useCloseBtn && (
            <button onClick={handleClose} style={style.closeButton}>
              x
            </button>
          )}
          {titleLayout()}
          {bodyLayout()}
          {footerLayout()}
        </Box>
      </Modal>
    </>
  )
}

import { PieDataInterface } from "../Analise.Model"
import { Box, Stack, Typography } from "@mui/material"
import { formatNumber } from "../../../../../domain/Domain.Formatters"

interface PieChartComponentPropsInterface {
  data: PieDataInterface[]
  containerWidth?: string
  boxSize?: string
  justifyStyle?: string
}

const ConsumptionOverviewBox = (props: PieChartComponentPropsInterface) => {
  const { data, containerWidth, boxSize, justifyStyle } = props

  const renderContent = () => {
    const emptyData = [...data].filter((item) => !Number(item?.value))
    const validData = [...data].filter((item) => Number(item?.value))
    const sortedData = [...validData, ...emptyData]

    return sortedData.map((item, index) => {
      if (!Number(item?.value)) return <Box key={index} />
      return (
        <Box
          key={index}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          alignContent="center"
          style={{
            borderRadius: "50%",
            background: `${item?.bgColor || "#EEF1F2"}`,
            width: boxSize ?? "140px",
            height: boxSize ?? "140px",
            marginRight: "24px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            color: `${item.color}`,
          }}
        >
          {item?.icon}
          <Typography data-testid={item?.name} fontSize={"1.5em"} fontWeight={"bold"} mt={1} color={"inherit"}>
            {formatNumber(Number(item?.value))}
          </Typography>
          <Typography component={"span"} fontWeight={700} color={"inherit"}>
            {item?.unit}
          </Typography>
        </Box>
      )
    })
  }

  if (data.length === 0) {
    return (
      <Box
        sx={{
          width: "100%",
        }}
      >
        No results
      </Box>
    )
  }

  return (
    <div style={{ width: containerWidth ?? "30%", display: justifyStyle?.length ? "flex" : "block" }}>
      <Stack
        flex={1}
        flexWrap={"wrap"}
        direction={"row"}
        alignItems={"center"}
        justifyContent={justifyStyle ?? "space-between"}
      >
        {renderContent()}
      </Stack>
    </div>
  )
}

export default ConsumptionOverviewBox

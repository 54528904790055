import { useHistory } from "react-router-dom"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import TableCell from "@mui/material/TableCell"
import { BuildingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { DataItemBoxNoValue } from "../../../../../uikit/box/DataItemBox"

interface AreaBuildingsTableInterface {
  itemConfig: BuildingAdminResponse
}

const AreaBuildingsTableComponent = (props: AreaBuildingsTableInterface) => {
  const history = useHistory()
  const { itemConfig } = props

  const renderStreetView = () =>
    itemConfig.address ? `${itemConfig.address.street} ${itemConfig.address.houseNumber}` : <DataItemBoxNoValue />

  const renderCityView = () =>
    itemConfig.address ? `${itemConfig.address.postalCode} ${itemConfig.address.city}` : <DataItemBoxNoValue />

  return (
    <TableRowClickable<BuildingAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(item: BuildingAdminResponse): void => {
        if (item.id) {
          history.push(`/buildings/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
      <TableCell align="left">{renderStreetView()}</TableCell>
      <TableCell align="left">{renderCityView()}</TableCell>
    </TableRowClickable>
  )
}

export default AreaBuildingsTableComponent

import { DownloadBillInterface } from "./UtilityUnitInterfaces"
import { customerDownloadBillPdf } from "../../../../../../domain/portal/manager/bills/Bills.Repository"
import { FileDownload } from "../../../../../../uikit/Shared.Icon"
import { Box } from "@mui/material"

const DownloadBill = ({ id }: DownloadBillInterface) => {
  return (
    <Box
      sx={{
        cursor: id ? "pointer" : "not-allowed",
        opacity: id ? 1 : 0.5,
      }}
      onClick={(): void => {
        if (!id) return
        customerDownloadBillPdf(id)
      }}
    >
      <FileDownload />
    </Box>
  )
}

export default DownloadBill

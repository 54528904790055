/* eslint-disable indent */
import { useCallback, useState } from "react"
import { GridActionsCellItem, GridActionsColDef } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { CircularProgress, Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory, useParams } from "react-router-dom"
import { isEmpty } from "lodash-es"
import { useQuery } from "@tanstack/react-query"
import { UtilityUnitParticipationParticipant } from "../../../../../../domain/participant/Participant.Model"
import DateUtils from "../../../../../../services/utils/DateUtils"
import { ParticipantMoveIn } from "./ParticipantMoveIn"
import { ParticipantMoveOut } from "./ParticipantMoveOut"
import { DeleteParticipantMoveOut } from "./DeleteParticipantMoveOut"
import { EditParticipantMoveInDate } from "./EditParticipantMoveInDate"
import { EditParticipantMoveOutDate } from "./EditParticipantMoveOutDate"
import { DeleteParticipationParticipant } from "./DeleteParticipationParticipant"
import { DataGridTable, dateSortComparator, DefaultCell } from "../../../../../../uikit/dataGridTable"
import { customerGetUtilityUnitParticipations } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { formatPersonalFullName } from "../../../../../../domain/portal/admin/profiles/Profiles.Formatters"
import { DOMAIN_DEPENDENCIES } from "../../../../../App.Config"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export interface ParticipationDialogPros {
  showDialog: boolean
  participation: UtilityUnitParticipationParticipant
  onClose: () => void
  onUpdateSuccess: () => void
}

export const UtilityUnitParticipations = ({
  utilityUnitId,
  currentParticipantName,
}: {
  utilityUnitId: string
  currentParticipantName: (fullName: string) => void
}) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const history = useHistory()
  const [unitError, setUnitError] = useState<AxiosErrorDataType>()
  const { areaId } = useParams<{ areaId: string }>()
  const [showMoveIn, setShowMoveIn] = useState(false)
  const [showMoveOut, setShowMoveOut] = useState(false)
  const [showEditMoveIn, setShowEditMoveIn] = useState(false)
  const [showEditMoveOut, setShowEditMoveOut] = useState(false)
  const [showDeleteMoveOut, setShowDeleteMoveOut] = useState(false)
  const [showAfterMoveOutStep, setShowAfterMoveOutStep] = useState(false)
  const [showDeleteParticipant, setShowDeleteParticipant] = useState(false)
  const [selectedParticipant, setSelectedParticipant] = useState<UtilityUnitParticipationParticipant>()

  const {
    data: participations,
    isFetching,
    refetch,
  } = useQuery(["getParticipations"], () => customerGetUtilityUnitParticipations(utilityUnitId), {
    enabled: !!utilityUnitId,
    onError: setUnitError,
    onSuccess: (data: UtilityUnitParticipationParticipant[]) => {
      const currentParticipant = data?.find((participant: UtilityUnitParticipationParticipant) => participant.isCurrent)
      setSelectedParticipant(currentParticipant)
      if (currentParticipant && currentParticipant?.participant?.fullName) {
        currentParticipantName(currentParticipant?.participant?.fullName)
      }
    },
  })

  const handleAfterMoveOut = async () => {
    await refetch()
    setShowMoveOut(false)
    setShowAfterMoveOutStep(true)
  }

  const ParticipantName = useCallback(
    (participant) => {
      return (
        <Typography fontWeight={400} fontSize={14}>
          {isEmpty(participant)
            ? t("participation.noParticipation")
            : formatPersonalFullName(participant?.personalData, DOMAIN_DEPENDENCIES)}
        </Typography>
      )
    },
    [t],
  )

  const participantsColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      field: "participant",
      headerName: t("tableHeader.attendees"),
      flex: 300,
      renderCell: ({ value }) => <ParticipantName {...value} />,
    },
    {
      field: "moveInDate",
      headerName: t("tableHeader.moveInDate"),
      flex: 100,
      sortComparator: dateSortComparator,
      valueGetter: (_, row) => (row?.moveInDate ? DateUtils.getDeFormatDateWithMonthString(row?.moveInDate) : "-"),
      renderCell: DefaultCell,
    },
    {
      field: "moveOutDate",
      headerName: t("tableHeader.moveOutDate"),
      flex: 100,
      sortComparator: dateSortComparator,
      valueGetter: (_, row) => (row?.moveOutDate ? DateUtils.getDeFormatDateWithMonthString(row?.moveOutDate) : "-"),
      renderCell: DefaultCell,
    },
    {
      field: "actions",
      type: "actions",
      flex: 50,
      getActions: ({ row }) =>
        row?.participant
          ? [
              <GridActionsCellItem
                divider
                key="edit"
                showInMenu
                label={t("dialog.editParticipant")}
                onClick={() => history.push(`/${areaId}/participants/${row?.participantId}`)}
              />,
              <GridActionsCellItem
                divider
                showInMenu
                key="editMoveIn"
                label={t("dialog.editMoveIn")}
                onClick={() => {
                  setSelectedParticipant(row)
                  setShowEditMoveIn(true)
                }}
              />,
              row?.moveOutDate ? (
                <GridActionsCellItem
                  divider
                  showInMenu
                  key="editMoveOut"
                  label={t("dialog.editMoveOut")}
                  onClick={() => {
                    setSelectedParticipant(row)
                    setShowEditMoveOut(true)
                  }}
                />
              ) : (
                <GridActionsCellItem
                  divider
                  showInMenu
                  key="moveOut"
                  label={t("list.button.reportMoveOut")}
                  onClick={() => {
                    setSelectedParticipant(row)
                    setShowMoveOut(true)
                  }}
                />
              ),
              row?.moveInDate && !row?.moveOutDate ? (
                <GridActionsCellItem
                  divider
                  showInMenu
                  key="deleteParticipantMoveIn"
                  label={t("tooltip.delete")}
                  onClick={() => {
                    setSelectedParticipant(row)
                    setShowDeleteParticipant(true)
                  }}
                />
              ) : (
                <></>
              ),
              row?.moveOutDate && row?.canCancelMoveOut ? (
                <GridActionsCellItem
                  showInMenu
                  key="deleteMoveOut"
                  label={t("dialog.deleteMoveOut")}
                  onClick={() => {
                    setSelectedParticipant(row)
                    setShowDeleteMoveOut(true)
                  }}
                />
              ) : (
                <></>
              ),
            ]
          : !row?.moveOutDate
          ? [
              <GridActionsCellItem
                showInMenu
                key="moveIn"
                label={t("moveIn.form.title")}
                onClick={() => {
                  setSelectedParticipant(row)
                  setShowMoveIn(true)
                }}
              />,
            ]
          : [],
    },
  ]

  if (!participations) return <CircularProgress />

  return (
    <Box p={6} flex={1}>
      <ErrorAlert
        visible={!!unitError}
        message={t(`error-codes:${unitError?.response?.data?.code || unitError?.code || "OTHER"}`)}
      />
      <DataGridTable<UtilityUnitParticipationParticipant>
        rows={participations || []}
        columns={participantsColumns}
        loading={isFetching}
        redirectParamName={"id"}
        redirectUrl={location.pathname}
        hideFooter={!participations?.length}
        defaultPageSize={10}
      />
      {selectedParticipant && (
        <>
          <EditParticipantMoveInDate
            showDialog={showEditMoveIn}
            participation={selectedParticipant}
            onClose={() => setShowEditMoveIn(false)}
            onUpdateSuccess={() => {
              refetch()
              setShowEditMoveIn(false)
            }}
          />
          <EditParticipantMoveOutDate
            showDialog={showEditMoveOut}
            participation={selectedParticipant}
            onClose={() => setShowEditMoveOut(false)}
            onUpdateSuccess={() => {
              refetch()
              setShowEditMoveOut(false)
            }}
          />
          <ParticipantMoveOut
            showDialog={showMoveOut}
            participation={selectedParticipant}
            onClose={() => setShowMoveOut(false)}
            onUpdateSuccess={handleAfterMoveOut}
          />

          <ParticipantMoveIn
            showDialog={showMoveIn || showAfterMoveOutStep}
            afterMoveOutStep={showAfterMoveOutStep}
            participation={selectedParticipant}
            onClose={() => {
              setShowMoveIn(false)
              setShowAfterMoveOutStep(false)
            }}
            onUpdateSuccess={() => {
              refetch()
              setShowMoveIn(false)
              setShowAfterMoveOutStep(false)
            }}
          />
          <DeleteParticipantMoveOut
            showDialog={showDeleteMoveOut}
            participation={selectedParticipant}
            onUpdateSuccess={() => {
              refetch()
              setShowDeleteMoveOut(false)
            }}
            onClose={() => setShowDeleteMoveOut(false)}
          />
          <DeleteParticipationParticipant
            showDialog={showDeleteParticipant}
            participation={selectedParticipant}
            onUpdateSuccess={() => {
              refetch()
              setShowDeleteParticipant(false)
            }}
            onClose={() => setShowDeleteParticipant(false)}
          />
        </>
      )}
    </Box>
  )
}

import { FormRowCell, FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { useTranslation } from "react-i18next"
import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { AreaStateInterface } from "../Area.Interface"
import { Paper } from "@mui/material"

const AreaCreateUpdateForm = (
  props: Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<AreaStateInterface>, "errors" | "values" | "touched"> &
    Pick<FormikHelpers<AreaStateInterface>, "setFieldValue">,
) => {
  const { t } = useTranslation()
  const { handleBlur, values, errors, setFieldValue } = props

  return (
    <Paper>
      <FormView>
        <FormRowColumn>
          <FormRowCell>
            <SingleLineTextField
              required
              autoFocus
              name="name"
              type="text"
              label={t("shared:label.name")}
              helperText={errors.name ? errors.name : undefined}
              value={values.name ?? ""}
              onChange={(event) => setFieldValue("name", event.target.value)}
              onBlur={handleBlur}
            />
          </FormRowCell>
        </FormRowColumn>
      </FormView>
    </Paper>
  )
}

export default AreaCreateUpdateForm

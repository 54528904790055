import { Button, styled, SvgIconProps, ButtonProps, Typography, IconButton } from "@mui/material"
import { BreadcrumbIcon } from "../../../../../uikit/Shared.Icon"
import { newDesignSelectColorDark } from "../../../../Shared.Theme"

interface BreadcrumbsButtonProps extends ButtonProps {
  label: string
}

interface BreadcrumbsHomeIconI {
  redirectUrl: () => void
  icon: JSX.Element
}

const BreadcrumbsButton = styled(Button)(({ theme }) => ({
  fontWeight: 400,
  padding: 0,
  marginLeft: theme.spacing(1),
  textTransform: "uppercase",
  fontSize: theme.typography.pxToRem(11.5),
  color: newDesignSelectColorDark,
  "&.Mui-disabled": {
    color: newDesignSelectColorDark,
    opacity: 1,
  },
  ".MuiTypography-root": {
    fontWeight: 400,
    padding: 0,
    color: newDesignSelectColorDark,
    fontSize: theme.typography.pxToRem(11.5),
  },
}))

export const BreadcrumbsNextIcon = styled((props: SvgIconProps) => <BreadcrumbIcon fontSize="small" {...props} />)(
  ({ theme }) => ({
    color: newDesignSelectColorDark,
    alignSelf: "center",
    marginLeft: theme.spacing(1),
  }),
)

export const BreadcrumbsNavButton = ({ label, disabled, ...rest }: BreadcrumbsButtonProps) => (
  <>
    <BreadcrumbsButton variant={"text"} disabled={disabled} {...rest}>
      <Typography noWrap>{label}</Typography>
    </BreadcrumbsButton>
    {!disabled && <BreadcrumbsNextIcon />}
  </>
)

export const BreadcrumbsHomeIcon = ({ redirectUrl, icon }: BreadcrumbsHomeIconI) => (
  <IconButton sx={{ padding: 0 }} onClick={redirectUrl} color="inherit">
    {icon}
  </IconButton>
)

import { BuildingAdminResponse, ContractAdminResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ContractManagementBuildingColumns, ContractsColumns } from "./Contracts.Enum"

export const contractsColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ContractsColumns.STATUS:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ContractsColumns.CONTRACT_NAME:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction)
    case ContractsColumns.START_DATE:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.startDate, b.startDate, orderBy.direction)
    case ContractsColumns.END_DATE:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a?.endDate ?? "", b?.endDate ?? "", orderBy.direction)
    case ContractsColumns.AREA:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a?.areaName ?? "", b?.areaName ?? "", orderBy.direction)
    case ContractsColumns.PRODUCT:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a?.product?.name ?? "", b?.product?.name ?? "", orderBy.direction)
    default:
      return (a: ContractAdminResponse, b: ContractAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.productId, b.productId, orderBy.direction)
  }
}

export const contractManagementBuildingColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ContractManagementBuildingColumns.STATUS:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ContractManagementBuildingColumns.BUILDING:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.name, b.name, orderBy.direction)
    case ContractManagementBuildingColumns.STREET:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.address?.street, b.address?.street, orderBy.direction)
    case ContractManagementBuildingColumns.CITY:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.address?.postalCode, b.address?.postalCode, orderBy.direction)
    default:
      return (a: BuildingAdminResponse, b: BuildingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.id, b.id, orderBy.direction)
  }
}

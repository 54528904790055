export const FaqTopics = {
  "price-calc": {
    title: "Wie wird der interne Sonnenstrom berechnet?",
    content:
      "Der Strompreis im ZEV setzt sich ausfolgenden Komponenten zusammen: Den Preis für den selbst produzierten ZEV-Sonnenstrom. Für diesen wird empfohlen mit 80% des Hochtarifs für Netzstrom zu rechnen.  Dazu kommt noch der Preis für den Strom aus dem Netz, wobei dieser sich in Hoch- und Niedertariff aufteilt. Eine detaillierte Anleitung zur Anpassung der Strompreise finden Sie hier: ",
    externalUrlText: "Kundeninformation ZEV Strompreise",
    externalUrl:
      "https://www.ckw.ch/_Resources/Persistent/b/8/8/c/b88cd9e9903a702d4b3a5dc1874300c288631b74/Kundeninformation_ZEV_Strompreise_2024.pdf",
  },
  "price-change": {
    title: "Wie oft kann ich, oder muss ich den Sonnenstrompreis ändern?​",
    content:
      "Es gibt keine Regelung wie oft der Sonnenstrompreis angepasst werden kann oder muss. Der Preis kann vor jeder Abrechnung angepasst werden. Es müssen lediglich die gesetzlichen Bestimmungen zur Berechnung des Sonnenstroms eingehalten werden. Eine detaillierte Anleitung zur Anpassung der Strompreise unter Einhaltung der gesetzlichen Bestimmungen finden Sie hier: ",
    externalUrlText: "Kundeninformation ZEV Strompreise",
    externalUrl:
      "https://www.ckw.ch/_Resources/Persistent/b/8/8/c/b88cd9e9903a702d4b3a5dc1874300c288631b74/Kundeninformation_ZEV_Strompreise_2024.pdf",
  },
  "price-rules": {
    title: "Welche gesetzlichen Rahmenbedingungen müssen bei der Berechnung des Sonnenstrompreises eingehalten werden?",
    content:
      "Bei Mietern muss das Mietergesetz (Artikel 6b) eingehalten werden. Dies besagt, dass die Tarifberechnung eine Begrenzung der möglichen Rendite für die Anlage von einem halben Prozent über dem aktuellen Referenzzinssatz hat. Eine Stockwerkeigentümergesellschaft ist frei in der Bestimmung des Strompreises.",
  },
  "price-communication": {
    title: "Muss ich die ZEV-Teilnehmer bei einer Anpassung des Sonnenstrompreises informieren?​",
    content:
      "Es gibt keine gesetzliche Bestimmung, dass die Mieter über eine Sonnenpreisanpassung informiert werden müssen.",
  },
  "price-deadline": {
    title: "Welche Fristen müssen für die Anpassung des Sonnenstrompreises eingehalten werden?",
    content: "Es gibt keine Fristen die von gesetzeswegen eingehalten werden müssen.",
  },
  "bill-deadline": {
    title: "Welche Fristen müssen für die Abrechnung mit den ZEV-Teilnehmer eigehalten werden?",
    content:
      "Es gibt keine gesetzlichen Regelungen wie oft die Abrechnung an die ZEV-Teilnehmer erfolgen soll. Dies liegt im Ermessen der ZEV-Verwaltung.",
  },
  "bill-payed": {
    title: "Was bedeutet das Feld «bezahlt» bei der Rechnungsübersicht?",
    content:
      "Dieses Feld muss manuell gesetzt werden und dient der Kontrolle, ob eine verschickte Rechnung von ZEV-Teilnehmer bereits bezahlt wurde oder noch ausstehend ist.",
  },
  "account-change": {
    title: "Können mehrere Personen mein Account bearbeiten?",
    content:
      "Es besteht die Möglichkeit, für mehrere Personen einen Zugang zum CKW Abrechnungs-Manager zu erhalten. Weitere Zugänge können bei der CKW angefordert werden.",
  },
  "general-prerequisite": {
    title: "Was muss ich machen um die Software verwenden zu können?",
    content:
      "1. Startdatum festlegen, 2. Strompreis definieren, 3. Mieterspiegel erfassen. Sie werden in der Anmeldung dazu aufgefordert-",
  },
  "bill-creation": {
    title: "Was muss ich machen um eine Abrechnung zu erstellen?",
    content:
      "1. Warten bis Messdaten eintreffen, Sie erhalten ein Email von CKW, 2. Rechnungen aufbereiten 3. herunterladen und verteilen",
  },
  "general-password": {
    title: "Ich habe mein Passwort vergessen, wie komme ich zu einem neuen Passwort?",
    content:
      "Auf der Anmeldeseite zum CKW Abrechnungs-Manager kann mit dem Link «Passwort vergessen» ein neues Passwort angefordert werden.",
  },
  "general-transparency": {
    title: "Welche Informationen sind für Mieter sichtbar?",
    content: "Die Mieter haben keinen Zugriff auf den CKW Abrechnungs-Manager.",
  },
  "bill-interval": {
    title: "Wie ist der aktuelle Abrechnungsintervall?",
    content:
      "Aktuell erfolgt die Abrechnung alle 6 Monate, auf den 30.06. und auf den 31.12. Sie können dies jedoch im CKW Abrechnungs-Manager selbst einstellen, ob Sie halbjährliche oder jährliche Abrechnungen wünschen.",
  },
  "bill-differences": {
    title:
      "Die Verbrauchsdaten der Hauptmessung und die Summe der ZEV-Teilnehmer stimmen nicht überein? Wie kann das sein?",
    content:
      "Die Daten werden gemäss den gemessenen Daten aus den Zähler dargestellt. Die Differenz kann durch Zählertoleranzen und durch den Leitungsverlust entstehen.",
  },
  "plug-play-sys": {
    title: "Können auch Plug & Play Solaranlagen von ZEV Teilnehmenden abgerechnet werden?",
    content:
      "Plug & Play Solaranlagen (beispielsweise für den Balkon), welche bei einem ZEV-Teilnehmenden hinter einem Messpunkt installiert sind, werden von der CKW ZEV Abrechnungsdienstleistung nicht unterstützt. Rückspeisungen in das ZEV aus Plug & Play Solaranlagen werden nicht verrechnet. Eine Rückspeisung von Strom aus einer Plug & Play Solaranlagen in das ZEV führt zu Verfälschungen der Messwerte. Zudem erfordert der Verkauf von Solarstrom aus Plug & Play Solaranlagen in einem ZEV eine rechtliche Grundlage. CKW übernimmt keine Verantwortung für die Korrektheit der Messdaten sowie der Abrechnung im Falle von vorhandenen Plug & Play Solaranlagen in einem ZEV.",
  },
  "general-help": {
    title: "Wie kann ich Hilfe bekommen wenn ich nicht weiter weiss?",
    content:
      "Bei Fragen können Sie sich per E-Mail an unseren Support wenden (zev@ckw.ch) oder Sie kontaktieren unsere Telefonzentrale unter 041 249 51 11.",
  },
}

import { CircularProgress, IconButton, Stack, Typography } from "@mui/material"
import { useState } from "react"
import { useMutation } from "@tanstack/react-query"
import { Formik, Form } from "formik"
import { useTranslation } from "react-i18next"
import DateUtils from "../../../../../services/utils/DateUtils"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

import { SingleLineDatePicker } from "../../../../../uikit/input/SingleLineDatePicker"
import { AbortIcon, ActivateIcon, EditIcon } from "../../../../../uikit/Shared.Icon"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"
import { utilityParticipationChangeMoveInDate } from "../../../../../domain/portal/admin/utility-units/UtilityUnits.Repository"
import { validateUtilityUnitParticipantMoveIn } from "./UtilityUnitParticipantMoveInDateForm.Validation"

interface UtilityUnitParticipantMoveInCellProps {
  participation: UtilityUnitParticipationParticipant
  onUpdateSuccess: () => void
  onUpdateError: (error: AxiosErrorDataType) => void
}

export const UtilityUnitParticipantMoveInCell = ({
  participation,
  onUpdateSuccess,
  onUpdateError,
}: UtilityUnitParticipantMoveInCellProps) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [editMode, setEditMode] = useState(false)

  const handleUpdateSuccess = () => {
    setEditMode(false)
    onUpdateSuccess()
  }

  const { mutate: changeMoveInDate, isLoading: isMovingOut } = useMutation(
    ["changeMoveInDate"],
    (moveInDate: number) =>
      utilityParticipationChangeMoveInDate(participation.utilityUnitId, participation.id, moveInDate),
    {
      onSuccess: handleUpdateSuccess,
      onError: onUpdateError,
    },
  )

  const onConfirmMoveIn = ({ moveInDate }: { moveInDate: number }) => changeMoveInDate(moveInDate)

  return (
    <>
      {editMode ? (
        <Formik<{ moveInDate: number }>
          initialValues={{ moveInDate: apiFormattedDateToTimestamp(participation?.moveInDate) ?? 0 }}
          enableReinitialize
          onSubmit={onConfirmMoveIn}
          validate={(values) =>
            validateUtilityUnitParticipantMoveIn(
              DateUtils.getDeFormatDate(values.moveInDate),
              participation?.moveOutDate || "",
              participation?.prevParticipantMoveOut || "",
              t,
            )
          }
        >
          {({ values, handleBlur, handleSubmit, setFieldValue, errors, isValid, dirty }) => {
            return (
              <Form onSubmit={handleSubmit}>
                <Stack>
                  <SingleLineDatePicker
                    required
                    name="moveInDate"
                    label=""
                    value={values.moveInDate}
                    helperText={errors.moveInDate}
                    minDate={
                      participation?.prevParticipantMoveOut
                        ? DateUtils.addDateDays(participation?.prevParticipantMoveOut, 1)
                        : undefined
                    }
                    maxDate={
                      participation?.moveOutDate
                        ? DateUtils.substructDateDays(participation?.moveOutDate, 1)
                        : undefined
                    }
                    onChange={(value) => setFieldValue("moveInDate", value)}
                    onBlur={handleBlur}
                  />
                  <Stack direction={"row"} alignItems={"center"} justifyContent={"space-around"}>
                    <IconButton type="submit" color="primary" disabled={!isValid || !dirty} size="small">
                      {isMovingOut ? <CircularProgress color="primary" size={20} /> : <ActivateIcon />}
                    </IconButton>
                    <IconButton onClick={() => setEditMode(false)} size="small">
                      <AbortIcon />
                    </IconButton>
                  </Stack>
                </Stack>
              </Form>
            )
          }}
        </Formik>
      ) : (
        <Stack flexDirection={"row"} p={2}>
          <Typography fontWeight={400} fontSize={14} pr={0.5}>
            {participation?.moveInDate ? DateUtils.getDeFormatWithDots(participation?.moveInDate) : "-"}
          </Typography>
          {participation?.participant && (
            <EditIcon color="disabled" fontSize="small" onClick={() => setEditMode(true)} cursor="pointer" />
          )}
        </Stack>
      )}
    </>
  )
}

import { Box } from "@mui/material"
import React from "react"

interface TabPanelProps {
  index: unknown
  value: unknown
  children?: React.ReactNode
}

const TabPanel = ({ children, value, index }: TabPanelProps) => {
  if (value !== index) {
    return null
  }
  return (
    <Box role="tabpanel" hidden={value !== index} id={`tabpanel-${index}`} aria-labelledby={`tab-${index}`}>
      {children}
    </Box>
  )
}

export default TabPanel

import { getLegendConfig } from "../analiyse/charts/chart.config"
import { useTranslation } from "react-i18next"
import PieChartComponent from "../analiyse/charts/PieChart.Component"

export interface ZevBillingPieChartProps {
  totalEnergyHighTariff: string
  totalEnergyLowTariff: string
  totalEnergyProduction: string
  isLoading?: boolean
}

export const mapZevChartData = ({
  totalEnergyHighTariff,
  totalEnergyLowTariff,
  totalEnergyProduction,
}: ZevBillingPieChartProps) => {
  return [
    {
      name: "totalProduction",
      value: parseFloat(totalEnergyProduction || ""),
    },
    {
      name: "totalHighTariff",
      value: parseFloat(totalEnergyHighTariff || ""),
    },
    {
      name: "totalLowTariff",
      value: parseFloat(totalEnergyLowTariff || ""),
    },
  ]
}

export const ZevBillingPieChart = ({
  totalEnergyHighTariff,
  totalEnergyLowTariff,
  totalEnergyProduction,
  isLoading,
}: ZevBillingPieChartProps) => {
  const { t } = useTranslation()

  const totalConsumption = [totalEnergyHighTariff, totalEnergyLowTariff, totalEnergyProduction].reduce(
    (sum, value) => sum + Number(value),
    0,
  )

  return (
    <PieChartComponent
      selectedTab={"ELECTRICITY"}
      isLoading={!!isLoading}
      total={String(totalConsumption)}
      legendIconConfig={getLegendConfig(t, "ELECTRICITY")}
      data={mapZevChartData({ totalEnergyHighTariff, totalEnergyLowTariff, totalEnergyProduction })}
    />
  )
}

import {
  BillingScope,
  ConsumptionScope,
  GroupMeter,
  UsageType,
  VewaCostShare,
} from "../../../../data/generated-sources/openapi"
import { GroupMetersProps } from "../../../../app/portal/admin/contractsManagement/medium/Medium.Interfaces"

export type GroupedCostSharesType = Map<BillingScope, Map<ConsumptionScope, VewaCostShare[]>>

export const groupCostShares = (costShares?: VewaCostShare[]): GroupedCostSharesType => {
  const sortOrder = [BillingScope.HEATING_BILL, BillingScope.COOLING_BILL, BillingScope.WATER_BILL]

  const groupedCostShares = new Map<BillingScope, Map<ConsumptionScope, VewaCostShare[]>>()
  // Group the costShares data by billingScope and consumptionScope
  costShares?.forEach((item) => {
    const { billingScope, consumptionScope } = item.type

    const billingMap = groupedCostShares.get(billingScope) || new Map<ConsumptionScope, VewaCostShare[]>()
    const consumptionArray = billingMap.get(consumptionScope) || []

    consumptionArray.push(item)
    consumptionArray?.sort((a, b) => b.type.usageType.localeCompare(a.type.usageType))

    billingMap.set(consumptionScope, consumptionArray)
    groupedCostShares.set(billingScope, billingMap)
  })

  const sortedShares = new Map([...groupedCostShares].sort(([a], [b]) => sortOrder.indexOf(a) - sortOrder.indexOf(b)))
  return sortedShares
}

const getBillingConsumptionScope = (billingScope: BillingScope) => {
  switch (billingScope) {
    case BillingScope.HEATING_BILL:
      return [ConsumptionScope.ROOM_HEATING, ConsumptionScope.WATER_HEATING]
    case BillingScope.COOLING_BILL:
      return [ConsumptionScope.ROOM_COOLING]
    case BillingScope.WATER_BILL:
      return [ConsumptionScope.WATER]
  }
}

export const getInitialCostSharesForBillingScope = (billingScope: BillingScope) => {
  const consumptionScopes = getBillingConsumptionScope(billingScope)

  return consumptionScopes.flatMap((consumptionScope) => {
    return [UsageType.RESIDENCE, UsageType.BUSINESS].flatMap((usageType) => {
      return [
        {
          type: {
            billingScope: BillingScope[billingScope],
            consumptionScope: ConsumptionScope[consumptionScope],
            usageType: UsageType[usageType],
          },
          costStructure: {
            basicCosts: 30,
            consumptionBasedCosts: 70,
          },
        },
      ]
    })
  })
}

export const mapVewaHeatingGroupMeters = (heatMeters: GroupMetersProps[]): GroupMeter[] => {
  const finalPayload: GroupMeter[] = []
  const heatingGroupMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.HEATING_BILL,
      consumptionScope: ConsumptionScope.HEATING,
      usageType: UsageType.UNDEFINED,
    },
  }
  for (const meter of heatMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.HEATING) {
      heatingGroupMeters.meters.push(meter.id as string)
    }
  }
  const roomHeatingResidenceMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.HEATING_BILL,
      consumptionScope: ConsumptionScope.ROOM_HEATING,
      usageType: UsageType.RESIDENCE,
    },
  }
  for (const meter of heatMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.ROOM_HEATING && meter.usageType === UsageType.RESIDENCE) {
      roomHeatingResidenceMeters.meters.push(meter.id as string)
    }
  }
  const roomHeatingBusinessMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.HEATING_BILL,
      consumptionScope: ConsumptionScope.ROOM_HEATING,
      usageType: UsageType.BUSINESS,
    },
  }
  for (const meter of heatMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.ROOM_HEATING && meter.usageType === UsageType.BUSINESS) {
      roomHeatingBusinessMeters.meters.push(meter.id as string)
    }
  }
  const warmHeatingResidenceMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.HEATING_BILL,
      consumptionScope: ConsumptionScope.WATER_HEATING,
      usageType: UsageType.RESIDENCE,
    },
  }
  for (const meter of heatMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.WATER_HEATING && meter.usageType === UsageType.RESIDENCE) {
      warmHeatingResidenceMeters.meters.push(meter.id as string)
    }
  }
  const warmHeatingBusinessMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.HEATING_BILL,
      consumptionScope: ConsumptionScope.WATER_HEATING,
      usageType: UsageType.BUSINESS,
    },
  }
  for (const meter of heatMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.WATER_HEATING && meter.usageType === UsageType.BUSINESS) {
      warmHeatingBusinessMeters.meters.push(meter.id as string)
    }
  }

  finalPayload.push(heatingGroupMeters)
  finalPayload.push(roomHeatingResidenceMeters)
  finalPayload.push(roomHeatingBusinessMeters)
  finalPayload.push(warmHeatingResidenceMeters)
  finalPayload.push(warmHeatingBusinessMeters)

  return finalPayload
}

export const mapVewaCoolingGroupMeters = (coolMeters: GroupMetersProps[]): GroupMeter[] => {
  const finalPayload: GroupMeter[] = []

  const roomCoolingResidenceMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.COOLING_BILL,
      consumptionScope: ConsumptionScope.ROOM_COOLING,
      usageType: UsageType.RESIDENCE,
    },
  }
  for (const meter of coolMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.ROOM_COOLING && meter.usageType === UsageType.RESIDENCE) {
      roomCoolingResidenceMeters.meters.push(meter.id as string)
    }
  }
  const roomCoolingBusinessMeters: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.COOLING_BILL,
      consumptionScope: ConsumptionScope.ROOM_COOLING,
      usageType: UsageType.BUSINESS,
    },
  }
  for (const meter of coolMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.ROOM_COOLING && meter.usageType === UsageType.BUSINESS) {
      roomCoolingBusinessMeters.meters.push(meter.id as string)
    }
  }

  finalPayload.push(roomCoolingResidenceMeters)
  finalPayload.push(roomCoolingBusinessMeters)

  return finalPayload
}

export const mapVewaWaterGroupMeters = (waterMeters: GroupMetersProps[]): GroupMeter[] => {
  const finalPayload: GroupMeter[] = []

  const waterResidenceMetersGroup: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.WATER_BILL,
      consumptionScope: ConsumptionScope.WATER,
      usageType: UsageType.RESIDENCE,
    },
  }
  for (const meter of waterMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.WATER && meter.usageType === UsageType.RESIDENCE) {
      waterResidenceMetersGroup.meters.push(meter.id as string)
    }
  }
  const waterBusinessMetersGroup: GroupMeter = {
    meters: [],
    costShare: {
      billingScope: BillingScope.WATER_BILL,
      consumptionScope: ConsumptionScope.WATER,
      usageType: UsageType.BUSINESS,
    },
  }
  for (const meter of waterMeters) {
    if (meter.linkedConsumptionScope === ConsumptionScope.WATER && meter.usageType === UsageType.BUSINESS) {
      waterBusinessMetersGroup.meters.push(meter.id as string)
    }
  }

  finalPayload.push(waterResidenceMetersGroup)
  finalPayload.push(waterBusinessMetersGroup)

  return finalPayload
}

export const mapCostSharesPayload = (costShares: VewaCostShare[], billingScope: BillingScope) => {
  return costShares.filter((costShare) => costShare.type.billingScope === billingScope)
}

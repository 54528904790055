import { useTranslation } from "react-i18next"
import { ProductUpsert } from "../../../../../domain/portal/admin/products/Products.Model"

import { FormView, FormRowCell, FormRowColumn } from "../../../../../uikit/form/FormView"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import ServiceComponentSelectionConnect from "../../service-components/ServiceComponentSelection.Connect"

import { validationError } from "../../../../Shared.Validation"
import { CommonFormikProps } from "../../../../Shared.Utils"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { ProductType } from "../../../../../data/generated-sources/openapi"

interface ProductsFormProps extends CommonFormikProps<ProductUpsert> {
  createForm?: boolean
}

export const ProductsForm = (props: ProductsFormProps) => {
  const { t } = useTranslation("products")
  const { handleChange, handleBlur, touched, values, errors, setFieldValue, createForm } = props
  return (
    <FormView>
      <FormRowColumn>
        <FormRowCell>
          <SingleLineTextField
            required
            autoFocus
            name="name"
            type="text"
            label={t("form.field.name")}
            helperText={validationError(errors.name, touched.name)}
            value={values.name}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormRowCell>
        <FormRowCell>
          <SelectPicker
            type="text"
            name="productType"
            disabled={!createForm}
            label={t("form.field.productType")}
            value={values.productType ?? ""}
            items={Object.keys(ProductType).map((product) => ({
              label: product,
              value: product,
            }))}
            onBlur={handleBlur}
            onChange={({ target }) => setFieldValue("productType", target.value)}
            helperText={touched.productType && errors.productType ? errors.productType : undefined}
          />
        </FormRowCell>
      </FormRowColumn>
      <FormRowColumn>
        <ServiceComponentSelectionConnect
          selectedItems={values.serviceComponents}
          onSelectionChanged={(serviceComponents: string[]) =>
            setFieldValue(
              "serviceComponents",
              [...serviceComponents].sort((a, b) => ORDERED_STRING_COMPARATOR(a, b, "asc")),
            )
          }
        />
      </FormRowColumn>
    </FormView>
  )
}

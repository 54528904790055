import { I18nNamespace } from "../../../../App.i18n"

export const UtilityUnitI18n: I18nNamespace = {
  name: "utilityUnit",
  de: {
    "label.name": "Nutzeinheiten",
    "utilityType.RESIDENCE": "Wohneinheit",
    "utilityType.BUSINESS": "Gewerbeeinheit",
    "utilityType.GENERAL_SUPPLY": "Allgemeine Versorgung",
    "utilityType.HEATING_PUMP": "Wärmepumpe",
    "label.currentParticipant": "Teilnehmer",
    "label.building": "Anschlussobjekt",
    "label.type": "Typ",
    "status.label": "Status",
    "label.unitSpace": "Nutzfläche in m²",
    "label.saveChanges": "Nutzeinheit speichern",
    "errorMsg.consumption_point_update_too_many_participants":
      "Einer oder mehrere Teilnehmer wurden bereits hinzugefügt.",
    "form.label.name": "Name",
    "unitCreate.successMsg": "Die Nutzeinheit wurde erfolgreich angelegt. Gehen Sie zur Nutzeinheit",
    "unitCreate.errorMsg": "Die Nutzeinheit konnte nicht erstellt werden. Bitte versuchen Sie es später erneut",
    "breadcrumb.unitDetail": "NUTZEINHEITEN DETAIL PAGE",
    "breadcrumb.meters": "MESSTECHNIK",
    "button.delete": "Nutzeinheit löschen",
    "button.deactivate": "Nutzeinheit deaktivieren",
    "datum.deactivate": "Abrechnungsfähig bis",
    "utilityUnit.update.success": "Nutzeinheit erfolgreich aktualisiert",
    "breadcrumb.utilityUnit.update": "NUTZEINHEIT BEARBEITEN",
    "breadcrumb.utilityUnit.create": "NUTZEINHEIT ERSTELLEN",
    "validation.unitType": "Für den ausgewählten Typ muss der Wert für den Nutzfläche Bereich 0 sein",
    "table.label.unit": "Nutzeinheit",
    "table.label.prices": "Preise",
    "managerDetails.information": "Informationen",
    "managerDetails.currentParticipants": "Aktueller Teilnehmer",
    "managerDetails.type": "Typ",
    "managerDetails.unitSpace": "Nutzfläche in m²",
    "managerDetailsHeader.name": "Teilnehmer",
    "managerDetailsHeader.moveInDate": "Einzugsdatum",
    "managerDetailsHeader.moveOutDate": "Auszugsdatum",
    "managerDetailsTab.participantHistory": "Teilnehmerhistorie",
    "managerDetailsTab.settlements": "Abrechnungen",
    "managerDetailsTab.consumptionData": "Zählereinstellungen ",
    "managerDetailsTab.counterSettings": "Verbrauchsdaten",
    "utilityUnits.current": "Aktuelle Nutzeinheiten",
    "utilityUnits.past": "Vergangene Nutzeinheiten",
    "utilityUnit.unitsSpaceMustBeGreaterThanZero":
      "Nutzfläche in m2 muss einen positiven Wert grösser oder gleich 0 haben",
    "utilityUnitDeactivate.confirmQuestion": "Sind Sie sicher, dass Sie diese Nutzeinheit deativiren möchten?",
  },
  en: {
    "label.name": "Utility Unints",
    "utilityType.residence": "Wohneinheit",
    "utilityType.business": "Gewerbeeinheit",
    "utilityType.general_supply": "Allgemeine Versorgung",
    "utilityType.heating_pump": "Wärmepumpe",
    "label.currentParticipant": "Teilnehmer",
    "label.building": "Anschlussobjekt",
    "label.type": "Type",
    "status.label": "Status",
    "label.unitSpace": "Usable area in m²",
    "label.saveChanges": "Save Unit",
    "errorMsg.consumption_point_update_too_many_participants": "One or more participants have already been added.",
    "form.label.name": "Name",
    "unitCreate.successMsg": "The unit was created successfully. Go to the utility unit",
    "unitCreate.errorMsg": "Failed to create new unit, please try later",
    "breadcrumb.unitDetail": "UTILITY UNIT DETAIL PAGE",
    "breadcrumb.meters": "METERS",
    "button.delete": "Delete Unit",
    "button.deactivate": "Deactivate Unit",
    "datum.deactivate": "Billable to",
    "utilityUnit.update.success": "Unit updated successfully",
    "breadcrumb.utilityUnit.update": "UTILITY UNIT UPDATE",
    "breadcrumb.utilityUnit.create": "UTILITY UNIT CREATE",
    "validation.unitType": "For selected Type, value for Usable area must be 0",
    "table.label.unit": "Unit",
    "table.label.prices": "Prices",
    "managerDetails.information": "Information",
    "managerDetails.currentParticipants": "Current participant",
    "managerDetails.type": "Type",
    "managerDetails.unitSpace": "Unit space in m²",
    "managerDetailsHeader.name": "Attendees",
    "managerDetailsHeader.moveInDate": "Move-In Date",
    "managerDetailsHeader.moveOutDate": "Move-Out Date",
    "managerDetailsTab.participantHistory": "Participant History",
    "managerDetailsTab.settlements": "Settlements",
    "managerDetailsTab.consumptionData": "Consumptions",
    "managerDetailsTab.counterSettings": "Counter Settings",
    "utilityUnits.current": "Current units",
    "utilityUnits.past": "Past units",
    "utilityUnit.unitsSpaceMustBeGreaterThanZero":
      "Unit space in m2 must be a positive value greater than or equal to 0",
    "utilityUnitDeactivate.confirmQuestion": "Are you sure want to deactivate this utility unit?",
  },
}

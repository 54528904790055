import { RouteComponentProps } from "react-router-dom"
import { AdminPortalRouteParams } from "../../../portal/admin/AdminPortal.Routes"
import { AuthType } from "../../../../domain/auth/Auth.Model"
import { AdminHeaderView, AdminHeaderViewProps } from "./AdminHeaderView"
import { AuthTypeProps } from "../../AppContainer.Component"
import { CustomerHeaderView, CustomerHeaderViewProps } from "./CustomerHeaderView"
import { mapDispatchToProps } from "../../AppContainer.Connect"
import { AppContainerState } from "../../AppContainer.Reducer"

export interface AppHeaderViewProps
  extends AdminHeaderViewProps,
    CustomerHeaderViewProps,
    AuthTypeProps,
    AppContainerState,
    RouteComponentProps<AdminPortalRouteParams>,
    ReturnType<typeof mapDispatchToProps> {}

export const AppHeaderView = (props: AppHeaderViewProps) => {
  const { authType } = props
  if (authType === AuthType.ADMINISTRATOR) return <AdminHeaderView {...props} />
  return <CustomerHeaderView {...props} />
}

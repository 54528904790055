import { SmallPaddedBox } from "../../../../../uikit/box/PaddedBox"
import { Grid, Stack } from "@mui/material"
import { TogglableButton } from "../../../../../uikit/button/TogglableButton"
import { AnaliseDateRangeEnum } from "../Analise.Enum"
import { appThemePrimaryColor, appThemePrimaryLightColor } from "../../../../Shared.Theme"
import { DateYearSelectorView } from "../../../../../uikit/input/DateYearSelectorView"
import { DateMonthSelectorView } from "../../../../../uikit/input/DateMonthSelectorView"
import { DateWeekSelectorView } from "../../../../../uikit/input/DateWeekSelectorView"
import { useTranslation } from "react-i18next"
import { DateDaySelectorView } from "../../../../../uikit/input/DateDaySelectorView"

interface DateRangeComponentPropsInterface {
  handleDateRangeChange: (value: AnaliseDateRangeEnum) => void
  selectedDateRange: AnaliseDateRangeEnum
  selectedDateTime: number | string
  handleDateTimeChange: (value: number) => void
  includeDaySelector?: boolean
}

const DateRangeComponent = (props: DateRangeComponentPropsInterface) => {
  const { t } = useTranslation("analise")
  const { handleDateRangeChange, selectedDateRange, selectedDateTime, handleDateTimeChange, includeDaySelector } = props

  const renderDaySelector = () => {
    if (includeDaySelector && selectedDateRange === AnaliseDateRangeEnum.DAY) {
      return (
        <DateDaySelectorView
          selectedDateValue={selectedDateTime as number}
          onDateChanged={(newDateTime) => handleDateTimeChange(newDateTime)}
          disabled={false}
        />
      )
    }
  }

  return (
    <>
      <Stack>
        <SmallPaddedBox>
          <Grid container justifyContent="end">
            <Grid item xs={12}>
              <TogglableButton<AnaliseDateRangeEnum>
                height={"30px"}
                activeValue={selectedDateRange}
                value={AnaliseDateRangeEnum.YEAR}
                label={t("shared:picker.year")}
                labelStyle={{ textTransform: "uppercase", fontSize: "13px" }}
                onClick={handleDateRangeChange}
                fWeight={900}
                sColor={appThemePrimaryLightColor}
                eColor={appThemePrimaryColor}
                lColor="#000000"
              />
              <TogglableButton<AnaliseDateRangeEnum>
                height={"30px"}
                activeValue={selectedDateRange}
                value={AnaliseDateRangeEnum.MONTH}
                label={t("shared:picker.month")}
                labelStyle={{ textTransform: "uppercase", fontSize: "13px" }}
                onClick={handleDateRangeChange}
                fWeight={900}
                sColor={appThemePrimaryColor}
                eColor={appThemePrimaryColor}
                lColor="#000000"
              />
              <TogglableButton<AnaliseDateRangeEnum>
                height={"30px"}
                activeValue={selectedDateRange}
                value={AnaliseDateRangeEnum.WEEK}
                label={t("shared:picker.week")}
                labelStyle={{ textTransform: "uppercase", fontSize: "13px" }}
                onClick={handleDateRangeChange}
                fWeight={900}
                sColor={appThemePrimaryColor}
                eColor={appThemePrimaryColor}
                lColor="#000000"
              />
              {includeDaySelector && (
                <TogglableButton<AnaliseDateRangeEnum>
                  height={"30px"}
                  activeValue={selectedDateRange}
                  value={AnaliseDateRangeEnum.DAY}
                  label={t("shared:picker.day")}
                  labelStyle={{ textTransform: "uppercase", fontSize: "13px" }}
                  onClick={handleDateRangeChange}
                  fWeight={900}
                  sColor={appThemePrimaryColor}
                  eColor={appThemePrimaryColor}
                  lColor="#000000"
                />
              )}
            </Grid>
          </Grid>
        </SmallPaddedBox>
        <SmallPaddedBox>
          {selectedDateRange === AnaliseDateRangeEnum.YEAR && (
            <DateYearSelectorView
              selectedDateValue={selectedDateTime}
              onDateChanged={(newDateTime) => handleDateTimeChange(newDateTime)}
              disabled={false}
            />
          )}
          {selectedDateRange === AnaliseDateRangeEnum.MONTH && (
            <DateMonthSelectorView
              selectedDateValue={selectedDateTime}
              onDateChanged={(newDateTime) => handleDateTimeChange(newDateTime)}
              disabled={false}
            />
          )}
          {selectedDateRange === AnaliseDateRangeEnum.WEEK && (
            <DateWeekSelectorView
              selectedDateValue={selectedDateTime}
              onDateChanged={(newDateTime) => handleDateTimeChange(newDateTime)}
              disabled={false}
            />
          )}
          {renderDaySelector()}
        </SmallPaddedBox>
      </Stack>
    </>
  )
}

export default DateRangeComponent

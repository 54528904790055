import { I18nNamespace } from "../../../../App.i18n"

export const BillingsParticipantI18n: I18nNamespace = {
  name: "billings-participant",
  de: {
    "list.all.label.total": "Summe",
    "list.all.label.status": "Status",
    "list.all.button": "Rechnung erstellen",
    "list.individual.label.consumptionPoint": "Verbrauchsstelle",
    "list.individual.label.participant": "Teilnehmer",
    "list.individual.label.status": "Status",
    "list.individual.button": "Rechnung erstellen",
    "detail.all.title": "Abrechnung",
    "detail.all.cta.lock": "Sperren",
    "detail.all.alert.lock.success": "Abrechnung wurde erfolgreich gesperrt",
    "detail.all.cta.unlock": "Entsperren",
    "detail.all.alert.unlock.success": "Abrechnung wurde erfolgreich deaktiviert",
    "detail.all.consumption-overview": "Übersicht Verbrauch aller Nutzeinheiten",
    "detail.all.participants.title": "Sind alle Teilnehmer aktuell?",
    "detail.all.participants.list.label.consumptionPoint": "Verbrauchsstelle",
    "detail.all.participants.list.label.participant": "Teilnehmer",
    "detail.all.participants.list.label.buildingName": "Gebäudename",
    "detail.all.participants.list.label.status": "Status",
    "detail.all.participants.list.label.errorMessage": "Fehlermeldung",
    "detail.all.participants.containsErrorMessages":
      "Es gibt zurzeit Unklarheiten mit den Teilnehmern. Bitte beheben Sie die weiter oben aufgelisteten Fehler",
    "detail.all.prices.title": "Möchten Sie die Preise aktualisieren?",
    "detail.all.consumption.title": "Verbrauch pro Nutzeinheit",
    "detail.all.consumption.list.label.consumptionPoint": "Verbrauchsstelle",
    "detail.all.consumption.list.label.participant": "Teilnehmer",
    "detail.all.consumption.list.label.buildingName": "Anschlussobjekt",
    "detail.all.consumption.list.label.kwh": "kWh",
    "detail.all.consumption.list.label.price": "Preis",
    "detail.all.consumption.list.label.details": "Details",
    "detail.all.consumption.list.label.sapStatus": "SAP Status",
    "detail.all.consumption.list.label.status": "Status",
    "detail.all.consumption.list.label.paid": "bezahlt",
    "detail.all.total.total.kwh": "Summe kWh",
    "detail.all.total.total": "Summe CHF",
    "detail.all.action.cta": "Rechnung erstellen",
    "detail.finalise.title": "Abrechnung",
    "detail.finalise.period": "ZEITRAUM",
    "detail.finalise.invoiceDate": "RECHNUNGSDATUM",
    "detail.finalise.total": "SUMME",
    "detail.finalise.status": "STATUS",
    "detail.finalise.invoiceReady.title": "Rechnungen sind erstellt und bereit für den Versand an Ihre ZEV-Teilnehmer",
    "detail.finalise.invoiceReady.body":
      "Sie haben die Möglichkeit den Status der einzelnen Rechnungen manuell auf bezahlt zu " +
      "setzen. Wenn alle Rechnungen auf bezahlt gesetzt sind, wird der Status der Abrechnung " +
      "automatisch auf «bezahlt» gesetzt.",
    "detail.finalise.downloadAll": "Alle herunterladen",
    "detail.finalise.downloadCsv": "CSV herunterladen",
    "detail.finalise.sendToSap": "Nach SAP senden",
    "state.WAITING_FOR_DATA": "Warte auf Daten",
    "state.DATA_AVAILABLE": "Daten liegen vor",
    "state.SUSPENDED": "Suspendiert",
    "state.IN_PROGRESS": "In Bearbeitung",
    "state.IN_PROGRESS_REOPENED": "In Bearbeitung (neu eröffnet)",
    "state.DONE": "Abgeschlossen",
    "state.PAID": "Bezahlt",
    "dialog.sap.body": "Sie haben die Daten bereits an SAP gesendet. Wollen Sie sie tatsächlich erneut senden?",
    "dialog.sap.cta": "Ja, löschen",
    "sap-billing-id": "SAP-Beleg-Nummer",
    "sap-invoice-id": "Rechnungsstellung-ID",
    "accounting-error": "Account Error",
    "list-billing-ckw-support-informed": "CKW Support wurde informiert",
  },
  en: {
    "list.all.label.total": "Total",
    "list.all.label.status": "Status",
    "list.all.button": "Make a bill",
    "list.individual.label.consumptionPoint": "Consumption point",
    "list.individual.label.participant": "Participant",
    "list.individual.label.status": "Status",
    "list.individual.button": "Make a bill",
    "detail.all.title": "Invoice",
    "detail.all.cta.lock": "Lock",
    "detail.all.alert.lock.success": "Billing was blocked successfully",
    "detail.all.cta.unlock": "Unlock",
    "detail.all.alert.unlock.success": "Billing was successfully deactivated",
    "detail.all.consumption-overview": "Overview consumption of all units",
    "detail.all.participants.title": "Are all participants up to date?",
    "detail.all.participants.list.label.consumptionPoint": "Consumption point",
    "detail.all.participants.list.label.participant": "Participant",
    "detail.all.participants.list.label.buildingName": "Building name",
    "detail.all.participants.list.label.status": "Status",
    "detail.all.participants.list.label.errorMessage": "Error message",
    "detail.all.participants.containsErrorMessages":
      "There are currently ambiguities with the participants. Please correct the errors listed above",
    "detail.all.prices.title": "Would you like to update the prices?",
    "detail.all.consumption.title": "Consumption per unit",
    "detail.all.consumption.list.label.consumptionPoint": "Consumption point",
    "detail.all.consumption.list.label.participant": "Participant",
    "detail.all.consumption.list.label.buildingName": "Building name",
    "detail.all.consumption.list.label.kwh": "kwh",
    "detail.all.consumption.list.label.price": "Price",
    "detail.all.consumption.list.label.details": "Details",
    "detail.all.consumption.list.label.sapStatus": "SAP Status",
    "detail.all.consumption.list.label.status": "Status",
    "detail.all.consumption.list.label.paid": "Paid",
    "detail.all.total.total.kwh": "Total kWh",
    "detail.all.total.total": "Total CHF",
    "detail.all.action.cta": "Make a bill",
    "detail.finalise.title": "Bill",
    "detail.finalise.period": "Period",
    "detail.finalise.invoiceDate": "Invoid date",
    "detail.finalise.total": "Total",
    "detail.finalise.status": "Status",
    "detail.finalise.invoiceReady.title": "Invoices are created and ready to be sent to your ZEV participants",
    "detail.finalise.invoiceReady.body":
      "You have the option of manually setting the status of the individual " +
      "invoices to paid. If all invoices are set to paid, the status of the " +
      "statement is automatically set to 'paid'.",
    "detail.finalise.downloadAll": "Download all",
    "detail.finalise.downloadCsv": "Download CSV",
    "detail.finalise.sendToSap": "Send to SAP",
    "state.WAITING_FOR_DATA": "Waiting for data",
    "state.DATA_AVAILABLE": "Data available",
    "state.SUSPENDED": "Suspended",
    "state.IN_PROGRESS": "In progress",
    "state.IN_PROGRESS_REOPENED": "In progress (reopened)",
    "state.DONE": "Completed",
    "state.PAID": "Paid",
    "dialog.sap.body": "You have already sent the data to SAP. Do you really want to send them again?",
    "dialog.sap.cta": "Yes",
    "sap-billing-id": "SAP Billing ID",
    "sap-invoice-id": "SAP Invoice ID",
    "accounting-error": "Account Error",
    "list-billing-ckw-support-informed": "CKW Support has been informed",
  },
}

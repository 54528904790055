import * as yup from "yup"
import { getTranslateValue } from "../../../../Shared.Utils"
import { utilityUnitTypeValues } from "../../sharedComponentsConfig/utilityUnits/UtilityUnits.Enum"

export const UtilityUnitModifySchema = yup.object({
  name: yup
    .string()
    .trim()
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("utilityUnit:form.label.name"),
      }),
    ),
  usageType: yup
    .mixed()
    .oneOf(utilityUnitTypeValues)
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("utilityUnit:label.type"),
      }),
    ),
  unitSpace: yup.number().optional(),
})

import { RouteComponentProps } from "react-router-dom"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import ContractSelector from "../contractSelector/ContractSelector"
import { BillingsListTabs } from "./BillingsListTabs"

export const BillingsOverview = ({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { areaId } = params
  return (
    <>
      <ContractSelector />
      <BillingsListTabs areaId={areaId} separateTables />
    </>
  )
}

import { Box, IconButton, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import { OpenIcon } from "../../../../../../uikit/Shared.Icon"
import { StatusView } from "../../../../../../uikit/label/StatusView"
import { StatusType } from "../../../../../../domain/Domain.Model"
import DateUtils from "../../../../../../services/utils/DateUtils"
import { InitialBillingAdminResponse } from "../../../../../../domain/portal/admin/billings/initial/BillingsInitial.Model"

const BillingInfoData = ({ title, value }: { title: string; value?: string }) => (
  <Box data-testid="initialBillingHeaderInfoData">
    <Typography variant="caption" color={"primary"}>
      {title}
    </Typography>
    <Typography variant="body2">{value ?? "-"}</Typography>
  </Box>
)

export const InitialBillingInfoHeader = ({
  activeState,
  contractId,
  contractName,
  billingDate,
  billingType,
  orderReferenceNumber,
  invoiceReferenceNumber,
  accountingStatus,
}: InitialBillingAdminResponse) => {
  const { t } = useTranslation("billings-initial")
  const history = useHistory()

  const redirectToContract = () => history.push(`/management-contracts/${contractId}`)

  return (
    <Paper data-testid="initialBillingHeader">
      {activeState && <StatusView statusType={StatusType[activeState]} />}
      <Typography variant="h4" my={4} data-testid="initialBillingHeaderTitle">
        {`${contractName} - ${t("shared:label.initialBill")}`}
      </Typography>

      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} spacing={1}>
        <Box>
          <Typography variant="caption" color={"primary"} noWrap data-testid="initialBillingHeaderContractName">
            {t("detail.label.zev")}
          </Typography>
          <Typography variant="body2">
            {contractName || "-"}
            <IconButton onClick={redirectToContract}>
              <OpenIcon color="primary" fontSize="small" />
            </IconButton>
          </Typography>
        </Box>
        <BillingInfoData
          title={t("detail.label.date")}
          value={DateUtils.getDeFormatDateWithMonthString(billingDate ?? "")}
        />
        <BillingInfoData title={t("detail.label.type")} value={t(`shared:billingType.${billingType}`)} />
        <BillingInfoData title={t("detail.label.order-reference-number")} value={orderReferenceNumber} />
        <BillingInfoData title={t("detail.label.invoice-reference-number")} value={invoiceReferenceNumber} />
        <BillingInfoData
          title={t("detail.label.accounting-status")}
          value={t(`shared:accounting.status.${accountingStatus}`)}
        />
      </Stack>
    </Paper>
  )
}

import { useTranslation } from "react-i18next"
import { StatusType } from "../../domain/Domain.Model"
import { StatusChipView, ChipColor } from "./StatusChipView"

interface StatusViewProps {
  id?: string
  statusType: StatusType
}

export const StatusView = (props: StatusViewProps) => {
  const { t } = useTranslation()
  const { id, statusType } = props
  return <StatusChipView id={id} chipColor={statusChipColor(statusType)} label={t(`shared:status.${statusType}`)} />
}

const statusChipColor = (statusType: StatusType) => {
  switch (statusType) {
    case StatusType.DRAFT:
      return ChipColor.ORANGE

    case StatusType.PAID:
    case StatusType.DONE:
    case StatusType.CREATED:
    case StatusType.ACTIVE:
    case StatusType.APPROVED:
    case StatusType.RELEASED:
    case StatusType.WAITING_FOR_DATA:
      return ChipColor.GREEN

    case StatusType.INACTIVE:
    case StatusType.TERMINATED:
    case StatusType.CANCELLED:
    case StatusType.DELETED:
      return ChipColor.GREY

    case StatusType.MODIFIED:
    case StatusType.DATA_AVAILABLE:
    case StatusType.IN_PROGRESS:
    case StatusType.IN_PROGRESS_REOPENED:
      return ChipColor.TEAL
    default:
      return ChipColor.GREY
  }
}

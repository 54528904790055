import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_BOOLEAN_COMPARATOR, ORDERED_STRING_LOCALE_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ContractParticipantsColumns } from "./ContractParticipants.Enum"

export const participantsColumnComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ContractParticipantsColumns.FIRST_NAME:
      return (a: ParticipantResponse, b: ParticipantResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.personalData?.firstName, b.personalData?.firstName, orderBy.direction)
    case ContractParticipantsColumns.LAST_NAME:
      return (a: ParticipantResponse, b: ParticipantResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.personalData?.lastName, b.personalData?.lastName, orderBy.direction)
    case ContractParticipantsColumns.GP_TYPE:
      return (a: ParticipantResponse, b: ParticipantResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(a.gpType, b.gpType, orderBy.direction)
    case ContractParticipantsColumns.EMAIL:
      return (a: ParticipantResponse, b: ParticipantResponse) =>
        ORDERED_STRING_LOCALE_COMPARATOR(
          a.contactDetails?.email || "",
          b.contactDetails?.email || "",
          orderBy.direction,
        )
    default:
      return (a: ParticipantResponse, b: ParticipantResponse) =>
        ORDERED_BOOLEAN_COMPARATOR(a.syncStatus, b.syncStatus, orderBy.direction)
  }
}

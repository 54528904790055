import { CSSProperties } from "react"

import { HasChildren } from "../../../uikit/Shared.Prop"

const staticPageStyle: CSSProperties = {
  flex: 1,
}

export const StaticPageView = (props: HasChildren) => {
  const { children } = props

  return <div style={staticPageStyle}>{children}</div>
}

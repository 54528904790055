import { useHistory } from "react-router-dom"
import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import TableCell from "@mui/material/TableCell"
import { StatusType } from "../../../../../domain/Domain.Model"
import { AreaAdminResponse } from "../../../../../data/generated-sources/openapi"
import { StatusView } from "../../../../../uikit/label/StatusView"

interface AreaTableInterface {
  itemConfig: AreaAdminResponse
}

const AreaTableComponent = (props: AreaTableInterface) => {
  const history = useHistory()
  const { itemConfig } = props

  return (
    <TableRowClickable<AreaAdminResponse>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={(item) => {
        if (item.id) {
          history.push(`/area/${itemConfig.id}`)
        }
      }}
    >
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState]} />
      </TableCell>
      <TableCell align="left">{itemConfig.name}</TableCell>
    </TableRowClickable>
  )
}

export default AreaTableComponent

import { CSSProperties } from "react"
import { TextField } from "@mui/material"
import NumberFormat from "react-number-format"

interface NumberFormatViewProps {
  tariffValue: number | undefined
  suffix: string
}

const borderStyle: CSSProperties = {
  paddingBottom: 6,
  borderBottom: "2px solid #CCCCCC",
  width: "100%",
  fontFamily: "Montserrat,Arial,sans-serif,serif",
}

export const NumberFormatView = ({ tariffValue, suffix }: NumberFormatViewProps) => {
  return (
    <div style={borderStyle}>
      <NumberFormat
        fullWidth
        displayType="text"
        key={"section-main.tab.priceCalculator.tariff.highTariff"}
        name={"section-main.tab.priceCalculator.tariff.highTariff"}
        customInput={TextField}
        allowNegative={false}
        decimalScale={2}
        fixedDecimalScale
        value={tariffValue}
        suffix={suffix}
        style={{ fontFamily: "Montserrat,Arial,sans-serif,serif" }}
      />
    </div>
  )
}

import { Box, styled } from "@mui/material"

export const GreenCirle = styled(Box)(({ theme }) => ({
  width: "1em",
  height: "1em",
  borderRadius: "50%",
  background: theme.palette.primary.main,
}))

export const RedCirle = styled(GreenCirle)(({ theme }) => ({
  background: theme.palette.error.main,
}))

import { Tab, TabProps, Tabs, TabsTypeMap } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"

export const PrimaryTabContainer = (props: DefaultComponentProps<TabsTypeMap>) => (
  <Tabs
    TabIndicatorProps={{
      style: {
        height: 4,
        marginLeft: "2px",
      },
    }}
    indicatorColor="primary"
    sx={{
      borderBottom: "2px solid #EEEEEE",
      paddingLeft: "32px",
    }}
    selectionFollowsFocus
    {...props}
  />
)

export enum IconPositionEnum {
  TOP = "top",
  BOTTOM = "bottom",
  START = "start",
  END = "end",
}

export const PrimaryTab = (props: TabProps) => (
  <Tab
    color="secondary"
    sx={{
      textTransform: "unset",
      minWidth: 72,
      fontWeight: "bold",
      color: "#c2c1c1",
      fontSize: "0.8rem",
      marginRight: 6,
      paddingLeft: 2,
      paddingRight: 2,
      "&:hover": {
        color: "#333333",
        opacity: 1,
      },
      "&.Mui-selected": {
        color: "#333333",
      },
      "&.Mui-disabled": {
        color: "#00000061",
        cursor: "not-allowed",
        pointerEvents: "visible",
      },
      "& .MuiBadge-root": {
        marginLeft: "1rem",
      },
    }}
    disableRipple
    iconPosition={IconPositionEnum.START}
    {...props}
  />
)

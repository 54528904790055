import { FormikErrors } from "formik"
import { TFunction } from "i18next"
import { validationResult, ValidField } from "../../../../Shared.Validation"
import { apiFormattedDateToTimestamp } from "../../../../../domain/Domain.Formatters"

export function validateUtilityUnitParticipantMoveOutDate(
  moveOutDate: string,
  moveInDate: string,
  t: TFunction,
  nextParticipantMoveIn?: string,
): FormikErrors<{ moveOutDate: number }> {
  return validationResult({
    moveOutDate: validateMoveOutDate(
      apiFormattedDateToTimestamp(moveOutDate),
      apiFormattedDateToTimestamp(moveInDate),
      nextParticipantMoveIn ? apiFormattedDateToTimestamp(nextParticipantMoveIn) : 0,
      t,
    ),
  })
}

const validateMoveOutDate = (moveOutDate: number, moveInDate: number, nextParticipantMoveIn: number, t: TFunction) => {
  if (moveOutDate === -1) {
    return t("shared:validation.mandatory", {
      field: t("moveOut.form.moveOutDate"),
    })
  } else if ((nextParticipantMoveIn && moveOutDate >= nextParticipantMoveIn) || moveInDate >= moveOutDate) {
    return t("moveOutDate.form.error", {
      field: t("moveOut.form.moveOutDate"),
    })
  }
  return ValidField
}

import { ButtonTypeMap } from "@mui/material/Button"
import { useTranslation } from "react-i18next"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { AbortIcon } from "../Shared.Icon"
import { PrimaryOutlinedButton } from "./PrimaryOutlinedButton"

interface CancelButtonProps {
  id?: string
  label?: string
  noIcon?: boolean
}

export const CancelButton = (props: CancelButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { label, startIcon, noIcon, ...rest } = props
  const { t } = useTranslation()

  return (
    <PrimaryOutlinedButton
      label={label || t("shared:button.abort")}
      startIcon={noIcon ? "" : startIcon || <AbortIcon />}
      {...rest}
    />
  )
}

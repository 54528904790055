import { DataGridTable } from "../../../../uikit/dataGridTable/"
import { DataGridTableHeader, IDataGridHeader } from "./fragments/DataGridTableHeader"
import { deDE, enUS } from "@mui/x-data-grid/locales"
import { DOMAIN_DEPENDENCIES } from "../../../App.Config"
import { DataGridTableProps } from "../../../../uikit/dataGridTable/dataGridTable.Model"

interface ISharedListDataGridProps<T> extends IDataGridHeader, DataGridTableProps<T> {}

const localeCode = DOMAIN_DEPENDENCIES.config.locale.code

export const DataGridListPage = <T extends object>({ slots, ...props }: ISharedListDataGridProps<T>) => {
  return (
    <DataGridTable<T>
      admin
      ignoreDiacritics
      defaultPageSize={25}
      disableColumnSelector
      disableColumnMenu={false}
      disableColumnFilter={false}
      disableDensitySelector
      {...props}
      localeText={(localeCode === "de" ? deDE : enUS).components.MuiDataGrid.defaultProps.localeText}
      slots={{
        toolbar: () => <DataGridTableHeader {...props} />,
        ...slots,
      }}
    />
  )
}

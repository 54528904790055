import { I18nNamespace } from "../../../App.i18n"

export const ProfilesI18n: I18nNamespace = {
  name: "profiles",
  de: {
    "list.title": "BENUTZER",
    "list.label.status": "ACCOUNT STATUS",
    "list.label.profileName": "NAME",
    "list.label.userName": "E-MAIL",
    "list.label.type": "Typ",
    "list.label.command": "Befehle",
    "list.tooltip.activate": "aktivieren",
    "list.tooltip.deactivate": "deaktivieren",
    "list.tooltip.createLogin": "Login erstellen",
    "list.alert.delete.success": "Profil wurde erfolgreich gelöscht",
    "manager.title": "ZEV VERWALTER",
    "manager.selection.field.filter": "Verwalter suchen und hinzufügen",
    "manager.selection.message.empty": "Noch keine Verwalter definiert. Bitte fügen Sie einen Verwalter hinzu",
    "details.navigation.update": "PROFIL BEARBEITEN",
    "details.label.contact": "KONTAKT",
    "details.subtitle.contact": "KONTAKT",
    "form.title.user": "Benutzer",
    "form.title.address": "Adresse",
    "form.title.phoneDetails": "Korrespondenz",
    "form.subtitle.telephone": "TELEFON",
    "form.subtitle.mobile": "NATEL",
    "form.subtitle.address": "Adresse",
    "form.field.street": "Strasse",
    "form.field.house-number": "Nr",
    "form.field.postalCode": "PLZ",
    "form.field.city": "Ort",
    "details.label.telephone": "TELEFON",
    "form.field.telephone": "Telefon",
    "form.label.correspondence": "KORRESPONDENZ",
    "form.subtitle.correspondence": "KORRESPONDENZ",
    "form.field.email": "E-Mail",
    "form.field.mobile": "Mobile",
    "form.user.title": "Titel",
    "form.user.salutation": "Anrede",
    "form.delete.profile": "Profil loschen",
    "details.label.account": "Konto",
    "form.label.username": "BENUTZERNAME",
    "form.field.username": "Benutzername",
    "form.label.userType": "TYP",
    "form.field.userType": "Typ",
    "detail.title": "Profil detail",
    "details.button.delete": "Benutzer löschen",
    "details.button.deactivate": "deaktivieren",
    "details.button.reset-password": "Passwort zurücksetzen",
    "details.button.create-zev-for-profile": "ZEV für Profil erstellen",
    "details.button.create-login": "Login erstellen",
    "details.button.activate": "aktivieren",
    "details.alert.success.activate": "Das Profil wurde erfolgreich aktiviert",
    "details.alert.success.deactivate": "Das Profil wurde erfolgreich deaktiviert",
    "details.alert.success.reset-password": "Das Passwort wurde erfolgreich zurückgesetzt",
    "form.subtitle.personal-details": "PERSONALIEN",
    "form.field.first-name": "Vorname",
    "form.field.lastName": "Nachname",
    "form.cta.reset-profile": "Zurücksetzen",
    "form.cta.save-profile": "Profil speichern",
    "form.alert.delete.body": "Wollen Sie das Profil endgültig löschen?",
    "form.alert.delete.cta": "Profil löschen",
    "form.field.salutation-mister": "Herr",
    "form.field.salutation-mrs": "Frau",
    "form.action.create.info.message":
      "Nachdem für das Profil ein Login erstellt wird, erhält die angegebene E-Mail-Adresse eine " +
      "Bestätigungsemail mit welcher das Passwort gesetzt werden kann und das Benutzerkonto aktiviert wird",
    "form.action.create.info.title": "E-Mail Verifikation",
    "form.action.cta": "Profil erstellen",
    "userType.ADMIN": "Administrator",
    "userType.ZEV_MANAGER": "ZEV Manager",
    "status.ACTIVE": "AKTIV",
    "status.BLOCKED": "GESPERRT",
    "status.NOT_CREATED": "NICHT ERSTELLT",
    "status.CREATED": "ERSTELLT",
    "status.DEACTIVATED": "DEAKTIVIERT",
    "update.title": "PROFIL BEARBEITEN",
    "update.breadCrumb.title": "TEILNEHMR BEARBEITEN",
    "create.title": "PROFIL ERSTELLEN",
    "create.alert.success": "Das Profil wurde erfolgreich angelegt. Gehen Sie zum Profil",
    "update.alert.success": "Die Änderungen am Profil wurde erfolgreich gespeichert",
    "form.alert.success": "Die Änderungen am Profil wurden erfolgreich gespeichert",
    "breadCrumbs.details": "PROFIL DETAILSEITE",
    "breadCrumbs.contract.update": "VERTRÄGE BEARBEITEN",
    "breadCrumbs.myProfile": "MEIN PROFIL",
    "breadCrumbs.myProfile.edit": "Mein Profil bearbeiten",
  },
  en: {
    "list.title": "PROFILES",
    "list.label.status": "ACCOUNT STATUS",
    "list.label.profileName": "NAME",
    "list.label.userName": "E-MAIL",
    "list.label.type": "Type",
    "list.label.command": "Command",
    "list.tooltip.activate": "Activate",
    "list.tooltip.deactivate": "Deactivate",
    "list.tooltip.createLogin": "Create login",
    "list.alert.delete.success": "Profile was successfully deleted",
    "manager.title": "ZEV ADMINISTRATOR",
    "manager.selection.field.filter": "Find and add administrators",
    "manager.selection.message.empty": "No administrator defined yet. Please add an administrator",
    "details.navigation.update": "EDIT PROFILE",
    "detail.title": "Profile detail",
    "details.label.contact": "CONTACT",
    "details.subtitle.contact": "CONTACT",
    "form.title.user": "User",
    "form.title.address": "Address",
    "form.title.phoneDetails": "Correspondence",
    "form.subtitle.telephone": "TELEPHONE",
    "form.subtitle.mobile": "MOBILE",
    "form.subtitle.address": "ADDRESS",
    "form.field.street": "STREET",
    "form.field.house-number": "Number",
    "form.field.postalCode": "ZIP",
    "form.field.city": "CITY",
    "details.label.telephone": "TELEFON",
    "form.subtitle.correspondence": "CORRESPONDENCE",
    "form.label.correspondence": "CORRESPONDENCE",
    "form.field.email": "email",
    "form.field.telephone": "PHONE",
    "form.field.mobile": "MOBILE",
    "form.user.title": "TITLE",
    "form.user.salutation": "Salutation",
    "form.delete.profile": "Delete profile",
    "details.label.account": "ACCOUNT",
    "form.label.username": "USER NAME",
    "form.field.username": "User name",
    "form.label.userType": "TYPE",
    "form.field.userType": "Type",
    "details.button.delete": "delete profile",
    "details.button.deactivate": "deactivate",
    "details.button.reset-password": "reset password",
    "details.button.create-zev-for-profile": "Create ZEV for profile",
    "details.button.create-login": "Create login",
    "details.button.activate": "Activate",
    "details.alert.success.activate": "The profile has been activated successfully",
    "details.alert.success.deactivate": "The profile has been deactivated successfully",
    "details.alert.success.reset-password": "The password was successfully reset",
    "form.subtitle.personal-details": "PERSÖNLICHE DATEN",
    "form.field.first-name": "First name",
    "form.field.lastName": "Last name",
    "form.cta.reset-profile": "reset profile",
    "form.cta.save-profile": "save profile",
    "form.alert.delete.body": "Do you want to permanently delete the profile?",
    "form.alert.delete.cta": "Delete profile",
    "form.field.salutation-mister": "Mister",
    "form.field.salutation-mrs": "Mrs",
    "form.action.create.info.title": "E-mail verification",
    "form.action.create.info.message":
      "After a login has been created for the profile, the specified email address receives a confirmation " +
      "email with which the password can be set and the user account is activated",
    "form.action.cta": "Create profile",
    "userType.ADMIN": "Administrator",
    "userType.ZEV_MANAGER": "ZEV Manager",
    "status.ACTIVE": "Active",
    "status.BLOCKED": "Blocked",
    "status.NOT_CREATED": "Not created",
    "status.CREATED": "Created",
    "status.DEACTIVATED": "Deactivated",
    "update.title": "Profile update",
    "create.title": "Profile create",
    "create.alert.success": "The profile was created successfully. Go to profile",
    "update.alert.success": "The changes to the profile have been saved successfully",
    "form.alert.success": "The changes to the profile have been saved successfully",
    "breadCrumbs.details": "PROFILE DETAIL",
    "breadCrumbs.contract.update": "EDIT CONTRACTS",
    "update.breadCrumb.title": "Edit profile",
    "breadCrumbs.myProfile": "MY PROFILE",
    "breadCrumbs.myProfile.edit": "Edit My Profile",
  },
}

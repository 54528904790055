import { Stack, ButtonBase, styled, Typography } from "@mui/material"

interface NavigationButtonCustomerProps {
  icon?: React.ReactNode
  label: string
  selected?: boolean
  onClick?: () => void
}

const NavTitle = styled(Typography)({
  fontWeight: 500,
  fontSize: "16px",
  color: "#000000",
})

const NavButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== "selected",
})<{ selected: boolean }>(({ theme, selected }) => ({
  padding: "14px 0px",
  fontSize: "14px",
  color: theme.palette.text.primary,
  justifyContent: "start",
  ".MuiStack-root": {
    borderBottom: "4px solid transparent",
    ".MuiSvgIcon-root": {
      fill: "#6A716A",
    },
  },

  ":hover": {
    ".MuiStack-root": {
      borderColor: theme.palette.primary.dark,
      ".MuiSvgIcon-root": {
        fill: theme.palette.primary.dark,
      },
    },
  },

  ...(selected && {
    "& .MuiStack-root": {
      borderColor: theme.palette.primary.dark,
      ".MuiSvgIcon-root": {
        fill: theme.palette.primary.dark,
      },
    },
  }),
}))

export const NavigationButtonCustomer = ({ icon, label, selected, onClick }: NavigationButtonCustomerProps) => (
  <NavButton selected={!!selected} onClick={onClick}>
    <Stack direction={"row"} spacing={1} pr={1}>
      {icon}
      <NavTitle>{label}</NavTitle>
    </Stack>
  </NavButton>
)

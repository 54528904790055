import {
  BillingType,
  BillingFrequency,
  UtilityUnitResponse,
  BuildingCustomerResponse,
  ZevPricePackage,
  ZevPriceCalculatorEnergy,
  ZevPriceCalculatorInput,
} from "../../../../data/generated-sources/openapi"

export interface MyZevSummary {
  id: string
  name: string
  streetAddress: string
  postCodeCity: string
  vatNumber: string
  pricePackages?: ZevPricePackage[]
  buildings: string[]
  allConsumptionPointIds: string[]
  buildingsMap: Map<string, BuildingCustomerResponse>
}

export interface MyZevBillingFrequencyUpdate {
  id: string
  currentbillingFrequency: BillingFrequency
  nextBillingFrequency?: BillingFrequency
  updatedDate: string
}

export interface CostPos {
  id: number
  billingType: BillingType
  name: string
  price: string
  units: string
  sum: string
}

export const billingFrequencyKeys = Object.keys(BillingFrequency)

export interface ZevPriceCalculatorTariffs {
  year: number
  tariffs: PriceCalculatorTariff[]
}

export interface PriceCalculatorTariff {
  id: number
  name: string
  isDefaultReferenceTariff: boolean
  basePrice: number
  peakPower: number
  high: number
  low: number
}

export enum DataStatus {
  DISPLAY_VALUE = "DISPLAY_VALUE",
  AVAILABLE = "AVAILABLE",
  MISSING = "MISSING",
  NOT_SELECTED = "NOT_SELECTED",
  NONE = "NONE",
}

export interface EnrichedConsumptionPoint extends UtilityUnitResponse {
  buildingCustomer?: BuildingCustomerResponse
}

export const emptyZevPriceCalculatorInput: ZevPriceCalculatorInput = {
  totalInvestmentAmount: 0,
  interestRateOnInvestment: 3.25,
  deprecationPeriodInYears: 25,
  sellingElectricityPricePerKwh: 0,
  operatingCostsPerKwh: 3,
  purchasingPowerProductId: -1,
  referencePowerProductId: -1,
}

export const initialPriceCalculatorEnergyValues: ZevPriceCalculatorEnergy = {
  boughtHighTariff: "0.00",
  boughtLowTariff: "0.00",
  boughtTotal: "0.00",
  soldTotal: "0.00",
  production: "0.00",
  consumptionOfProductionHighTariff: "0.00",
  consumptionOfProductionLowTariff: "0.00",
  consumptionOfProductionTotal: "0.00",
  consumptionTotal: "0.00",
  averageBoughtMonthlyPeak: "0.00",
}

export interface MyZevPriceUpsert {
  contractId: string
  zevId?: string
  id: number
  name: string
  formTitle?: string
  order: number
  solarPrice: string
  highTariffPrice: string
  lowTariffPrice: string
  additionalServicesPrice: string
  spikePrice?: string
  isFixedRate: boolean
  isNew?: boolean
}

export enum MyZevBillingFrequency {
  BIANNUAL = "BIANNUAL",
  MONTHLY = "MONTHLY",
  QUARTERLY = "QUARTERLY",
  YEARLY = "YEARLY",
}

import { TableRowClickable } from "../../../../../uikit/table/Table.RowView"
import { StatusType } from "../../../../../domain/Domain.Model"
import { StatusView } from "../../../../../uikit/label/StatusView"
import { StatusTypeObjectKey } from "../../../../Shared.Utils"
import {
  IndividualParticipantBilling,
  IndividualParticipantBillingState,
  ParticipantPersonalData,
  UtilityUnitResponse,
} from "../../../../../data/generated-sources/openapi"
import DateUtils from "../../../../../services/utils/DateUtils"
import CurrencyUtils from "../../../../../services/utils/CurrencyUtils"
import { TableCell } from "@mui/material"
import { useHistory, useParams } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { UtilityUnitParticipationParticipant } from "../../../../../domain/participant/Participant.Model"

export interface ExtendedIndividualParticipantBilling extends IndividualParticipantBilling {
  participants: UtilityUnitParticipationParticipant[]
  utilityUnit: UtilityUnitResponse
}
interface ParticipantBillingTableInterface {
  itemConfig: ExtendedIndividualParticipantBilling
}

const ParticipantIndividualBillingTable = ({ itemConfig }: ParticipantBillingTableInterface) => {
  const { t } = useTranslation()
  const history = useHistory()
  const { id: contractId } = useParams<{ id: string }>()
  const finalizeCondition = [IndividualParticipantBillingState.DONE, IndividualParticipantBillingState.PAID]

  const formatDate = (date: string): string => {
    if (!date) return ""

    return DateUtils.getDeFormatDateWithMonthString(date)
  }

  const formatAmount = (): string => {
    if (!itemConfig.finalAmountDue) return ""

    return CurrencyUtils.formatToSwiss(itemConfig.finalAmountDue)
  }

  const formatParticipantName = (participant?: ParticipantPersonalData): string => {
    if (!participant) return "-"

    return `${t("shared:salutation." + participant.salutation)} ${participant.firstName} ${participant.lastName}`
  }

  const renderParticipants = (): React.ReactNode | string => {
    if (!itemConfig?.participant) return `${t("participant:label.noParticipants")}`

    return <p key={itemConfig?.participant?.id}>{formatParticipantName(itemConfig?.participant?.personalData)}</p>
  }

  const isFinalizeState = finalizeCondition.includes(itemConfig.activeState) ? "finalize" : "edit"

  const navigateToIndividualParticipantBillPage = () => {
    const url = `/management-contracts/${contractId}/billings/individual/${itemConfig.id}/${isFinalizeState}`
    history.push(url)
  }

  return (
    <TableRowClickable<ExtendedIndividualParticipantBilling>
      key={itemConfig.id}
      rowData={itemConfig}
      rowClick={({ id }) => {
        if (id) {
          navigateToIndividualParticipantBillPage()
        }
      }}
    >
      <TableCell align="left">
        {formatDate(itemConfig.startDate)} - {formatDate(itemConfig.endDate)}
      </TableCell>
      <TableCell align="left">{itemConfig.utilityUnit?.name ?? "-"}</TableCell>
      <TableCell align="left">{renderParticipants()}</TableCell>
      <TableCell align="left">{formatAmount()}</TableCell>
      <TableCell align="left">
        <StatusView statusType={StatusType[itemConfig.activeState as StatusTypeObjectKey]} />
      </TableCell>
    </TableRowClickable>
  )
}

export default ParticipantIndividualBillingTable

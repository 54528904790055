import { Stack } from "@mui/material"
import { HeaderCard } from "./HeaderCard"

export interface IHeaderCard<T = void> {
  id: string
  title: string
  productChangeDate: string
  descriptions: Array<string>
  icon?: React.ReactElement
  additional?: T
}

interface HeaderCardsWrapperProps<T> {
  cards: Array<IHeaderCard<T>>
  selectedId: string
  onCardSelect: (card: IHeaderCard<T>) => void
}

export function HeaderCardsWrapper<T>(props: Readonly<HeaderCardsWrapperProps<T>>) {
  const { cards, selectedId, onCardSelect } = props

  return (
    <Stack direction={"row"} spacing={2} overflow={"auto"} p={5} sx={{ maxWidth: "100%" }}>
      {cards.map((card) => (
        <HeaderCard<T> {...card} key={card.id} selected={selectedId === card.id} onSelect={() => onCardSelect(card)} />
      ))}
    </Stack>
  )
}

import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useQuery, useMutation } from "@tanstack/react-query"
import { Form, Formik } from "formik"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import { contractMasterDataSchema } from "./ContractMasterDataValidation.Schema"

import { FullScreenLoader } from "../../../../../uikit/indicator/ProgressIndicator"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import ContractMasterDataFields from "./ContractMasterDataFields"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { PlusIcon } from "../../../../../uikit/Shared.Icon"
import {
  createContractForArea,
  getProductsForContract,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { ContractCreateAdminRequest } from "../../../../../data/generated-sources/openapi"
import {
  ContractMasterDataModify,
  initialContractMasterData,
} from "../../../../../domain/portal/admin/contracts/Contracts.Models"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

const ContractMasterDataCreateForm = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("contracts")
  const { areaId } = params
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: products,
    isLoading: isLoadingProducts,
    remove: removeProducts,
  } = useQuery(["getAreaProducts"], () => getProductsForContract(), {
    onError: setError,
  })

  useEffect(() => () => removeProducts(), [removeProducts])

  const { mutate: crateContract, isLoading: isCreatingArea } = useMutation(
    ["crateContract"],
    (formData: ContractCreateAdminRequest) => createContractForArea(areaId, formData),
    {
      onSuccess: () => history.push(`/area/${areaId}`),
      onError: setError,
    },
  )

  const handleSubmit = ({ name, productId, startDate, endDate }: ContractCreateAdminRequest) => {
    crateContract({ name, productId, startDate, endDate })
  }

  if (isLoadingProducts) return <FullScreenLoader />

  return (
    <Formik<ContractMasterDataModify>
      initialValues={{ ...initialContractMasterData, products: products }}
      validationSchema={contractMasterDataSchema}
      onSubmit={handleSubmit}
    >
      {(props) => (
        <Form {...props}>
          <ErrorAlert
            visible={!!error}
            message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
          />
          <ContractMasterDataFields {...props} isEditForm={false} />
          <FormUpdateActionsView
            {...props}
            startIcon={<PlusIcon />}
            isLoading={isCreatingArea}
            navigateBack={() => history.goBack()}
            buttonCtaLabel={t("create.form.action.cta")}
          />
        </Form>
      )}
    </Formik>
  )
}

export default ContractMasterDataCreateForm

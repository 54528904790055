import { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { Dialog, DialogContent, DialogActions, Box, Divider, DialogTitle } from "@mui/material"

import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { ViewState } from "../../../../Shared.Reducer"
import { VALIDATION_DEPS } from "../../../../Shared.Validation"
import { PrimaryButton } from "../../../../../uikit/button"
import { PlusIcon } from "../../../../../uikit/Shared.Icon"
import { SpaceBetweenAlignBottomBox } from "../../../../../uikit/box/AlignmentBox"
import { CancelButton } from "../../../../../uikit/button/CancelButton"

import {
  ProductPriceComponentUpsert,
  draftProductPriceComponentUpsert,
} from "../../../../../domain/portal/admin/products/Products.Model"
import { PriceComponentForm } from "../form/PriceComponentForm"
import { validatePriceComponentForm } from "../form/PriceComponentForm.Validation"

interface ProductCreatePriceDialogViewProps {
  open: boolean
  onClose: () => void
  productId: string
  createPriceComponent: (productId: string, productPriceComponent: ProductPriceComponentUpsert) => void
  createProductPriceComponentViewState: ViewState<string>
}

export const ProductCreatePriceDialogViewComponent = (props: ProductCreatePriceDialogViewProps) => {
  const { t } = useTranslation("products")
  const { open, onClose, productId, createPriceComponent, createProductPriceComponentViewState } = props

  useEffect(() => {
    if (createProductPriceComponentViewState.domainResult) onClose()
  }, [createProductPriceComponentViewState, onClose])

  if (createProductPriceComponentViewState.domainResult) return <Redirect to={`/products/${productId}`} />

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <Formik<ProductPriceComponentUpsert>
        initialValues={draftProductPriceComponentUpsert}
        onSubmit={(values) => createPriceComponent(productId, values)}
        validate={(values) => validatePriceComponentForm(values, VALIDATION_DEPS, t)}
      >
        {({ values, errors, touched, handleChange, handleBlur, handleSubmit, isValid, setFieldValue, dirty }) => (
          <Form onSubmit={handleSubmit}>
            {createProductPriceComponentViewState.domainError && (
              <ErrorAlert message={createProductPriceComponentViewState.domainError.message} />
            )}
            <DialogTitle>{t("price.form.title.createPriceComponent")}</DialogTitle>
            <Divider />
            <DialogContent>
              <PriceComponentForm
                values={values}
                errors={errors}
                setFieldValue={setFieldValue}
                touched={touched}
                handleChange={handleChange}
                handleBlur={handleBlur}
              />
            </DialogContent>
            <DialogActions sx={{ marginBottom: 1, marginTop: 1 }}>
              <SpaceBetweenAlignBottomBox>
                <Box pl={1}>
                  <CancelButton id="form-abort" onClick={onClose} />
                </Box>
                <Box pr={1}>
                  <PrimaryButton
                    disabled={!isValid || !dirty || createProductPriceComponentViewState.isLoading}
                    label={t("create.price.form.action.cta")}
                    type="submit"
                    startIcon={<PlusIcon />}
                    isLoading={createProductPriceComponentViewState.isLoading}
                  />
                </Box>
              </SpaceBetweenAlignBottomBox>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </Dialog>
  )
}

import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { getPropertyFromObject } from "../../../../app/Shared.Utils"
import { apiHeaders } from "../../../Domain.Calls"
import { COMPONENTS_API_CONFIG } from "../../../../app/portal/admin/sharedComponentsConfig/Components.Config"

export const getDataFromApi = (
  en: string,
  page?: number,
  limit?: number,
  orderBy?: string,
  queryParam?: string,
  getParamFromUrl?: () => string,
) => {
  type objectKey = keyof typeof COMPONENTS_API_CONFIG
  const domainDependenciesApiClass = getPropertyFromObject(
    DOMAIN_DEPENDENCIES,
    COMPONENTS_API_CONFIG[en as objectKey].domainDependenceApiClass,
  )
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const apiGetMethod = COMPONENTS_API_CONFIG[en as objectKey].getMethod as const
  let params = [page, limit, orderBy, apiHeaders(DOMAIN_DEPENDENCIES)]
  const urlParam = getParamFromUrl ? getParamFromUrl() : queryParam
  if (urlParam) {
    params = [urlParam, ...params]
  }
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  return domainDependenciesApiClass[apiGetMethod](...params)
}

import { Grid } from "@mui/material"
import { styled } from "@mui/material/styles"
import { useTranslation } from "react-i18next"
import { Link, LinkProps } from "react-router-dom"

export const StaticLink = styled((props: LinkProps) => <Link {...props} />)(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  color: "#979797",
  textDecoration: "none",
  fontSize: "0.875rem",
  fontFamily: "Montserrat",
  fontWeight: 300,
}))

export const StaticHref = styled((props: React.LinkHTMLAttributes<HTMLAnchorElement>) => (
  <a target="_blank" {...props} />
))(({ theme }) => ({
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  color: "#979797",
  textDecoration: "none",
  fontSize: "0.875rem",
  fontFamily: "Montserrat",
  fontWeight: 300,
}))

interface ProfileFooterViewProps {
  areaId: string
}

export const ProfileFooterView = (props: ProfileFooterViewProps) => {
  const { t } = useTranslation("appContainer")
  const { areaId } = props

  return (
    <div style={{ width: "calc(100% - 36px)", paddingBottom: 10, paddingTop: 30 }}>
      <Grid style={{ paddingTop: 40, display: "flex", flexWrap: "wrap" }} container justify-content="center">
        <Grid
          item
          sx={{
            color: "#979797",
            width: "100%",
            display: "flex",
            flexWrap: "wrap",
            fontSize: "0.875rem",
            fontWeight: 300,
          }}
        >
          <StaticLink to={`/${areaId}/faq`}>{t("footer.faq")}</StaticLink>|
          <StaticHref style={{ color: "#979797" }} href="mailto:zev@ckw.ch">
            {t("footer.contact")}
          </StaticHref>
          |
          <StaticHref href="https://www.ckw.ch/abrechnungsmanager" rel="noopener noreferrer">
            {t("footer.tutorial")}
          </StaticHref>
          |
          <StaticHref href="https://www.ckw.ch/footer/impressum.html" rel="noopener noreferrer">
            {t("footer.impressum")}
          </StaticHref>
          |
          <StaticHref href="https://www.ckw.ch/footer/datenschutz.html" rel="noopener noreferrer">
            {t("footer.privacy")}
          </StaticHref>
          |
          <StaticHref href="https://www.ckw.ch/footer/agb.html" rel="noopener noreferrer">
            {t("footer.legal")}
          </StaticHref>
        </Grid>
      </Grid>
    </div>
  )
}

import { ChangeEvent, useEffect, useState } from "react"
import { Grid, SelectChangeEvent } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CommonFormikProps } from "../../../../Shared.Utils"
import {
  ParticipantAdminCreateRequest,
  ParticipantAdminUpdateRequest,
  ParticipantCustomerCreateRequest,
  ParticipantGpType,
  ParticipantPersonalDataSalutationEnum,
} from "../../../../../data/generated-sources/openapi"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { SwitchToggleManagerView } from "../../../../../uikit/toggle/SwitchToggleView"
import { ParticipantAddressCountry } from "../../../../../domain/participant/Participant.Model"

/**
 *
 * shared component between Admin and Manager Portal
 *
 */

export const ParticipantCreateUpdateFormFields = ({
  values,
  errors,
  touched,
  handleBlur,
  setFieldValue,
  adminCreate,
}: CommonFormikProps<
  Partial<ParticipantAdminCreateRequest | ParticipantAdminUpdateRequest | ParticipantCustomerCreateRequest>
> & { adminCreate?: boolean }) => {
  const { t } = useTranslation("customer-participants")
  const [showSecondPerson, setShowSecondPerson] = useState(false)

  const touchedFileds = Object.keys(touched)
  const customError = errors as Partial<
    ParticipantAdminCreateRequest | ParticipantAdminUpdateRequest | ParticipantCustomerCreateRequest
  >

  useEffect(() => {
    if (values?.personalData?.firstNameSecondPerson || values?.personalData?.lastNameSecondPerson) {
      setShowSecondPerson(true)
    }
  }, [values?.personalData])

  return (
    <Grid container rowGap={3} columnSpacing={3}>
      <Grid container item xs={12} columnSpacing={3}>
        {adminCreate && (
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              autoFocus
              name="sapGp"
              type="number"
              value={(values as ParticipantAdminCreateRequest)?.sapGp ?? ""}
              label={t("registerForm.businessPartnerType")}
              onBlur={handleBlur}
              helperText={
                touchedFileds.includes("sapGp") && (customError as Partial<ParticipantAdminCreateRequest>)?.sapGp
                  ? (customError as Partial<ParticipantAdminCreateRequest>)?.sapGp
                  : undefined
              }
              onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue("sapGp", event.target.value)}
            />
          </Grid>
        )}
        <Grid item xs={12} md={6}>
          <SelectPicker
            name="gpType"
            type="text"
            label={t("registerForm.gpType")}
            value={values?.gpType ?? ""}
            helperText={errors.gpType && touched.gpType ? errors.gpType : undefined}
            items={Object.keys(ParticipantGpType).map((type) => ({
              label: t(`registerForm.gptype.${type}`),
              value: type,
            }))}
            onBlur={handleBlur}
            onChange={(event: SelectChangeEvent) => setFieldValue("gpType", event.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SelectPicker
          required
          name="salutation"
          type="text"
          label={t("registerForm.salutation")}
          value={values?.personalData?.salutation ?? ""}
          helperText={
            touchedFileds.includes("salutation") && customError.personalData?.salutation
              ? customError.personalData?.salutation
              : undefined
          }
          items={Object.keys(ParticipantPersonalDataSalutationEnum).map((salut) => ({
            label: t(`shared:salutation.${salut}`),
            value: salut,
          }))}
          onBlur={handleBlur}
          onChange={(event: SelectChangeEvent) => setFieldValue("personalData.salutation", event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          name="title"
          type="text"
          value={values.personalData?.title ?? ""}
          label={t("registerForm.title")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("title") && customError.personalData?.title
              ? customError.personalData?.title
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue("personalData.title", event.target.value)}
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="firstName"
          type="text"
          value={values.personalData?.firstName ?? ""}
          label={t("registerForm.firstName")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("firstName") && customError.personalData?.firstName
              ? customError.personalData?.firstName
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("personalData.firstName", event.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="lastName"
          type="text"
          value={values.personalData?.lastName ?? ""}
          label={t("registerForm.lastName")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("lastName") && customError.personalData?.lastName
              ? customError.personalData?.lastName
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("personalData.lastName", event.target.value)
          }
        />
      </Grid>

      {showSecondPerson && (
        <>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="firstNameSecondPerson"
              type="text"
              value={values.personalData?.firstNameSecondPerson ?? ""}
              label={t("registerForm.firstNameSecondPerson")}
              onBlur={handleBlur}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("personalData.firstNameSecondPerson", event.target.value)
              }
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <SingleLineTextField
              name="lastNameSecondPerson"
              type="text"
              value={values.personalData?.lastNameSecondPerson ?? ""}
              label={t("registerForm.lastNameSecondPerson")}
              onBlur={handleBlur}
              onChange={(event: ChangeEvent<HTMLInputElement>) =>
                setFieldValue("personalData.lastNameSecondPerson", event.target.value)
              }
            />
          </Grid>
        </>
      )}

      <Grid container item xs={12}>
        <Grid item xs={4}>
          <SwitchToggleManagerView
            label={t("registerForm.secondPerson")}
            checked={showSecondPerson}
            onChange={() => setShowSecondPerson((prevState) => !prevState)}
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLineTextField
          name="phone"
          type="text"
          value={values.contactDetails?.phone ?? ""}
          label={t("registerForm.phone")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("phone") && customError.contactDetails?.phone
              ? customError.contactDetails?.phone
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue("contactDetails.phone", event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          name="mobile"
          type="text"
          value={values.contactDetails?.mobile ?? ""}
          label={t("registerForm.mobile")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("mobile") && customError.contactDetails?.mobile
              ? customError.contactDetails?.mobile
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("contactDetails.mobile", event.target.value)
          }
        />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <SingleLineTextField
            name="email"
            type="text"
            value={values.contactDetails?.email ?? ""}
            label={t("registerForm.email")}
            onBlur={handleBlur}
            helperText={
              touchedFileds.includes("email") && customError.contactDetails?.email
                ? customError.contactDetails?.email
                : undefined
            }
            onChange={(event: ChangeEvent<HTMLInputElement>) =>
              setFieldValue("contactDetails.email", event.target.value)
            }
          />
        </Grid>
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="street"
          type="text"
          value={values.domicileAddress?.street ?? ""}
          label={t("registerForm.street")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("street") && customError.domicileAddress?.street
              ? customError.domicileAddress?.street
              : undefined
          }
          onChange={(event) => setFieldValue("domicileAddress.street", event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="houseNumber"
          type="text"
          value={values.domicileAddress?.houseNumber ?? ""}
          label={t("registerForm.houseNumber")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("houseNumber") && customError.domicileAddress?.houseNumber
              ? customError.domicileAddress?.houseNumber
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("domicileAddress.houseNumber", event.target.value)
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLineTextField
          name="co"
          type="text"
          value={values.domicileAddress?.co ?? ""}
          label={t("registerForm.co")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("co") && customError.domicileAddress?.co
              ? customError.domicileAddress?.co
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue("domicileAddress.co", event.target.value)}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          name="poBox"
          type="text"
          value={values.domicileAddress?.poBox ?? ""}
          label={t("registerForm.poBox")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("poBox") && customError.domicileAddress?.poBox
              ? customError.domicileAddress?.poBox
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("domicileAddress.poBox", event.target.value)
          }
        />
      </Grid>

      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="postCode"
          type="text"
          value={values.domicileAddress?.postCode ?? ""}
          label={t("registerForm.postCode")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("postCode") && customError.domicileAddress?.postCode
              ? customError.domicileAddress?.postCode
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) =>
            setFieldValue("domicileAddress.postCode", event.target.value)
          }
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <SingleLineTextField
          required
          name="city"
          type="text"
          value={values.domicileAddress?.city ?? ""}
          label={t("registerForm.city")}
          onBlur={handleBlur}
          helperText={
            touchedFileds.includes("city") && customError.domicileAddress?.city
              ? customError.domicileAddress?.city
              : undefined
          }
          onChange={(event: ChangeEvent<HTMLInputElement>) => setFieldValue("domicileAddress.city", event.target.value)}
        />
      </Grid>

      <Grid container item xs={12}>
        <Grid item xs={12} md={6}>
          <SelectPicker
            required
            name="land"
            type="text"
            value={values.domicileAddress?.land ?? ""}
            label={t("registerForm.land")}
            onBlur={handleBlur}
            items={Object.keys(ParticipantAddressCountry).map((country) => ({
              label: country,
              value: country,
            }))}
            helperText={
              touchedFileds.includes("land") && customError.domicileAddress?.land
                ? customError.domicileAddress?.land
                : undefined
            }
            onChange={(event: SelectChangeEvent) => setFieldValue("domicileAddress.land", event.target.value)}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

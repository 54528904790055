import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { useCallback, useEffect } from "react"

import { AreaIcon, ContractsIcon, MeterIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { adminGetBreadcrumbInfo } from "../../../../domain/portal/admin/breadcrumps/Breadcrumps.Repository"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { MeterAttachmentType } from "../../../../data/generated-sources/openapi"

export const MetersBreadcrumb = ({ history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("meters")
  const { id } = getParamsFromUrl("/meters/:id") || ""

  const { data, remove } = useQuery(["getBreadcrumbInfo"], () => adminGetBreadcrumbInfo({ meterId: id }), {
    enabled: !!id,
  })

  useEffect(() => {
    return () => remove()
  }, [remove, id])

  const MetersListBreadcrumbs = useCallback(
    () => (
      <>
        <BreadcrumbsHomeIcon redirectUrl={() => history.push("/billings/recurring")} icon={<MeterIcon />} />
        <BreadcrumbsNextIcon />
        <BreadcrumbsNavButton label={t("table.title")} disabled />
      </>
    ),
    [history, t],
  )

  const ContractMeterBreadcrumbs = useCallback(
    (props) => (
      <>
        <BreadcrumbsHomeIcon
          redirectUrl={() => history.push("/management-contracts")}
          icon={<ContractsIcon color="primary" />}
        />
        <BreadcrumbsNavButton
          label={data?.meter?.contract?.name || ""}
          onClick={() => history.push(`/management-contracts/${data?.meter?.contract?.id}`)}
        />
        <BreadcrumbsNavButton
          disabled={props?.btnDisabled}
          label={data?.meter?.name || ""}
          onClick={() => history.push(`/meters/${data?.meter?.id}`)}
        />
      </>
    ),
    [data?.meter, history],
  )

  const BuildingMeterBreadcrubs = useCallback(
    (props) => {
      return (
        <>
          <BreadcrumbsHomeIcon redirectUrl={() => history.push("/area")} icon={<AreaIcon />} />
          <BreadcrumbsNextIcon />
          <BreadcrumbsNavButton
            label={props?.meterData?.building?.area?.name || ""}
            onClick={() => history.push(`/area/${props?.meterData?.building?.area?.id}`)}
          />
          <BreadcrumbsNavButton
            label={props?.meterData?.building?.name || ""}
            onClick={() => history.push(`/buildings/${props?.meterData?.building?.id}`)}
          />
          <BreadcrumbsNavButton
            disabled={props?.btnDisabled}
            label={props?.meterData?.name || ""}
            onClick={() => history.push(`/meters/${props?.meterData?.id}`)}
          />
        </>
      )
    },
    [history],
  )

  const UnitMeterBreadcrups = useCallback(
    (props) => {
      return (
        <>
          <BreadcrumbsHomeIcon redirectUrl={() => history.push("/area")} icon={<AreaIcon />} />
          <BreadcrumbsNextIcon />
          <BreadcrumbsNavButton
            label={props?.meterData?.utilityUnit?.building?.area?.name || ""}
            onClick={() => history.push(`/area/${props?.meterData?.utilityUnit?.building?.area?.id || ""}`)}
          />
          <BreadcrumbsNavButton
            label={props?.meterData?.utilityUnit?.building?.name || ""}
            onClick={() => history.push(`/buildings/${props?.meterData?.utilityUnit?.building?.id || ""}`)}
          />
          <BreadcrumbsNavButton
            label={props?.meterData?.utilityUnit?.name || ""}
            onClick={() => history.push(`/utility-units/${props?.meterData?.utilityUnit?.id}`)}
          />
          <BreadcrumbsNavButton
            disabled={props?.btnDisabled}
            label={props?.meterData?.name || ""}
            onClick={() => history.push(`/meters/${props?.meterData?.id}`)}
          />
        </>
      )
    },
    [history],
  )

  const MeterDetailsBreadcrups = useCallback(
    (props) => {
      switch (data?.meter?.meterAttachmentType) {
        case MeterAttachmentType.ZEV_CONFIG:
          return <ContractMeterBreadcrumbs btnDisabled={props?.btnDisabled} />
        case MeterAttachmentType.UTILITY_UNIT:
          return <UnitMeterBreadcrups btnDisabled={props?.btnDisabled} meterData={data?.meter} />
        case MeterAttachmentType.BUILDING:
          return <BuildingMeterBreadcrubs btnDisabled={props?.btnDisabled} meterData={data?.meter} />
        default:
          return <MetersListBreadcrumbs />
      }
    },
    [data?.meter, UnitMeterBreadcrups, MetersListBreadcrumbs, BuildingMeterBreadcrubs, ContractMeterBreadcrumbs],
  )

  return (
    <Switch>
      <Route path="/meters/:id/update" exact>
        <MeterDetailsBreadcrups />
        <BreadcrumbsNavButton disabled label={t("breadcrumb.meters.update")} />
      </Route>

      <Route path="/meters/:id" exact>
        <MeterDetailsBreadcrups btnDisabled />
      </Route>

      <Route path="/meters" exact>
        <MetersListBreadcrumbs />
      </Route>
    </Switch>
  )
}

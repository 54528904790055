import { Form, Formik } from "formik"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { FormUpdateActionsView } from "../../../../uikit/form/FormUpdateActions"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { initialBuildingCreateAdminRequest } from "../../../../domain/portal/admin/buildings/Buildings.Model"

import {
  adminGetBuildingById,
  adminUpdateBuildingById,
} from "../../../../domain/portal/admin/buildings/Buildings.Repository"
import { AxiosErrorDataType } from "../../../Shared.Utils"
import { FormMode } from "../../../../uikit/form/FormView"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { BuildingCreateSchema } from "./fragments/BuildingCreate.Schema"
import { BuildingCreateAdminRequest, BuildingUpdateAdminRequest } from "../../../../data/generated-sources/openapi"
import { BuildingCreateUpdate } from "./fragments/BuildingCreateUpdateForm"
import { useEffect } from "react"

export const BuildingUpdate = ({ match: { params }, history }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { buildingId } = params
  const { t } = useTranslation("buildings")
  const queryClient = useQueryClient()
  const invalidateVewaQuery = () => queryClient.invalidateQueries({ queryKey: ["getBuilding"] })

  const navigateToBuilding = () => history.push(`/buildings/${buildingId}`)

  const {
    data: buildingData,
    isFetching,
    isError: isFetchingError,
    error: errorFetching,
    remove,
  } = useQuery(["getBuilding"], () => adminGetBuildingById(buildingId))

  const {
    mutate: updateBuilding,
    isError: isUpdateError,
    error: updateError,
    isLoading: isUpdating,
  } = useMutation(
    ["updateBuilding"],
    (values: BuildingUpdateAdminRequest) => adminUpdateBuildingById(buildingId, values),
    {
      onSuccess: () => {
        invalidateVewaQuery()
        navigateToBuilding()
      },
    },
  )

  useEffect(() => {
    return () => remove()
  }, [remove])

  const errorMsg = (errorFetching || updateError) as AxiosErrorDataType

  const handleOnSubmit = (values: BuildingUpdateAdminRequest) => {
    const updateData = {
      name: values.name,
      address: values.address,
    }
    updateBuilding(updateData)
  }

  return (
    <Formik<BuildingCreateAdminRequest>
      enableReinitialize
      onSubmit={handleOnSubmit}
      validationSchema={BuildingCreateSchema}
      initialValues={buildingData || initialBuildingCreateAdminRequest}
    >
      {({ isValid, dirty, ...rest }) => (
        <Form>
          <ErrorAlert
            scrollOnDisplay
            visible={isFetchingError || isUpdateError}
            message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
          />
          <BuildingCreateUpdate mode={FormMode.UPDATE} activeState={buildingData?.activeState} {...rest} />

          <FormUpdateActionsView
            dirty={dirty}
            isValid={isValid}
            isLoading={isFetching || isUpdating}
            navigateBack={navigateToBuilding}
            buttonCtaLabel={t("update.form.action.cta")}
          />
        </Form>
      )}
    </Formik>
  )
}

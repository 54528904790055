import { useEffect } from "react"
import { Form, Formik } from "formik"
import { useMutation, useQuery } from "@tanstack/react-query"
import { CircularProgress, Paper, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"

import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"
import {
  getContractManagementById,
  updateContractCommentById,
} from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { AxiosErrorDataType } from "../../../../Shared.Utils"
import { commentSchema } from "../../taskList/fragments/CommentValidation.Schema"

export const ContractCommentUpdate = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { id: contractId } = params
  const { t } = useTranslation("shared")

  const {
    data: contractData,
    error,
    isError,
    isFetching,
    remove,
  } = useQuery(["getContract"], () => getContractManagementById(contractId), {
    enabled: !!contractId,
  })

  const {
    mutate: updateComment,
    error: updateError,
    isLoading: isUpdating,
    isError: isUpdateError,
  } = useMutation(["updateComment"], (comment: string) => updateContractCommentById(contractId, { comment }), {
    onSuccess: () => history.push(`/management-contracts/${contractId}`),
  })

  useEffect(() => {
    return () => remove()
  }, [])

  const handleSubmit = ({ comment }: { comment: string }) => updateComment(comment)

  const backToContractDetails = () => history.push(`/management-contracts/${contractId}`)

  return (
    <Formik<{ comment: string }>
      validateOnBlur
      validateOnChange
      enableReinitialize
      onSubmit={handleSubmit}
      validationSchema={commentSchema}
      initialValues={{ comment: contractData?.comment ?? "" }}
      data-testid="ContractCommentUpdateForm"
    >
      {({ values, isValid, dirty, errors, setFieldValue }) => (
        <Form>
          <Paper>
            <ErrorAlert
              visible={!!isError || !!isUpdateError}
              message={t(`error-codes:${((error || updateError) as AxiosErrorDataType)?.code ?? "OTHER"}`)}
            />
            {isFetching ? (
              <CircularProgress />
            ) : (
              <TextField
                multiline
                autoFocus
                type="text"
                minRows={5}
                maxRows={20}
                variant="outlined"
                name="contractComment"
                label={t("label.comments")}
                value={values.comment}
                onChange={(event) => setFieldValue("comment", event.target.value)}
                helperText={errors.comment ? errors.comment : undefined}
                sx={{ width: "100%", lineHeight: 1.6, ".MuiFormHelperText-root": { color: "red" } }}
                data-testid="ContractCommentUpdateInput"
              />
            )}
          </Paper>
          <FormUpdateActionsView
            dirty={dirty}
            isValid={isValid}
            isLoading={isFetching || isUpdating}
            navigateBack={backToContractDetails}
            buttonCtaLabel={`${t("form.action.save")} ${t("label.comments")}`}
          />
        </Form>
      )}
    </Formik>
  )
}

import { apiHeaders } from "../../../Domain.Calls"
import { participantUpdateMapper } from "./Participants.Mapper"
import { DOMAIN_DEPENDENCIES } from "../../../../app/App.Config"
import { ParticipantResponse, ParticipantCustomerCreateRequest } from "../../../../data/generated-sources/openapi"
import { AxiosPromise } from "axios"

export const getPagedParticipantsByAreaId = async (
  areaId: string,
  page?: number,
  limit?: number,
  orderBy?: string,
): Promise<ParticipantResponse[]> => {
  const { data } = await DOMAIN_DEPENDENCIES.customerAreaApi.customerGetAreaParticipants(
    areaId,
    page,
    limit,
    orderBy,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data.elements
}

export const managerCreateAreaParticipant = async (areaId: string, payload: ParticipantCustomerCreateRequest) => {
  return DOMAIN_DEPENDENCIES.customerAreaApi.customerCreateAreaParticipant(
    areaId,
    payload,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const getOneParticipantById = (participantId: string): AxiosPromise<ParticipantResponse> => {
  return DOMAIN_DEPENDENCIES.customerParticipantsApi.customerGetParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
}

export const customerGetParticipantBulkByIds = async (ids: string[]) => {
  const { data } = await DOMAIN_DEPENDENCIES.customerParticipantsApi.customerGetParticipantBulkByIds(
    { ids },
    apiHeaders(DOMAIN_DEPENDENCIES),
  )
  return data
}

export const updateOneParticipantById = (participantId: string, participant: ParticipantResponse) =>
  DOMAIN_DEPENDENCIES.customerParticipantsApi.customerUpdateParticipantById(
    participantId,
    participantUpdateMapper(participant),
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export const deleteOneParticipantById = (participantId: string) =>
  DOMAIN_DEPENDENCIES.customerParticipantsApi.customerDeleteParticipantById(
    participantId,
    apiHeaders(DOMAIN_DEPENDENCIES),
  )

export enum ContractsColumns {
  STATUS = "STATUS",
  CONTRACT_NAME = "CONTRACT_NAME",
  AREA = "AREA",
  PRODUCT = "PRODUCT",
  START_DATE = "START_DATE",
  END_DATE = "END_DATE",
}

export enum ContractManagementBuildingColumns {
  STATUS = "STATUS",
  BUILDING = "BUILDING",
  STREET = "STREET",
  CITY = "CITY",
  ID = "ID",
}

import { Grid } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { useMutation } from "@tanstack/react-query"
import { ContactData } from "../../../../../data/generated-sources/openapi"
import { customerUpdateContactDataByContractId } from "../../../../../domain/portal/manager/contracts/Contract.Repository"

import { OnboardingContactInfoFormProps } from "./OnboardingContactInfo"
import { LabelTypography, FormEditWrapper, ValueTypography, FormContactHeader } from "../fragments/OnboardingUI"
import { PrimaryButton } from "../../../../../uikit/button"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { onboardMainContactSchema } from "./ContactInfoValidation.Schema"

export const OnboardingContactInfoForm = ({
  contractId,
  contact,
  onSuccessUpdate,
  onErrorUpdate,
}: OnboardingContactInfoFormProps) => {
  const { t } = useTranslation("onboarding")
  const [editContact, setEditContact] = useState(false)

  const { mutate: updateContactContract, isLoading } = useMutation(
    ["updateContactContract"],
    (contactData: ContactData) => customerUpdateContactDataByContractId(contractId, contactData),
    {
      onError: onErrorUpdate,
      onSuccess: () => {
        onSuccessUpdate()
        setEditContact(false)
      },
    },
  )

  return (
    <Formik<ContactData>
      enableReinitialize
      initialValues={contact}
      validationSchema={onboardMainContactSchema}
      onSubmit={(data) => updateContactContract(data)}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => (
        <Form>
          <FormEditWrapper container item rowGap={2} editMode={editContact}>
            <FormContactHeader
              editMode={editContact}
              headerText={t("contactInfo.contactMedia")}
              onClick={() => {
                resetForm()
                setEditContact((prevState) => !prevState)
              }}
            />
            {editContact ? (
              <>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    sx={{ mb: 1.5 }}
                    disabled={!editContact}
                    name="telephone"
                    type="text"
                    onBlur={handleBlur}
                    label={t("contracts-management:contactForm.phone")}
                    value={values?.telephone}
                    onChange={(event) => setFieldValue("telephone", event.target.value)}
                    helperText={touched.telephone && errors.telephone ? errors.telephone : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    sx={{ mb: 1.5 }}
                    disabled={!editContact}
                    name="mobile"
                    type="text"
                    onBlur={handleBlur}
                    label={t("contracts-management:contactForm.mobile")}
                    value={values?.mobile}
                    onChange={(event) => setFieldValue("mobile", event.target.value)}
                    helperText={touched.mobile && errors.mobile ? errors.mobile : undefined}
                  />
                </Grid>
                <Grid container item>
                  <Grid item xs={12} md={6} pr={3}>
                    <SingleLineTextField
                      sx={{ mb: 1.5 }}
                      name="email"
                      type="email"
                      value={values?.email}
                      onBlur={handleBlur}
                      disabled={!editContact}
                      label={t("contracts-management:contactForm.email")}
                      onChange={(event) => setFieldValue("email", event.target.value)}
                      helperText={touched.email && errors.email ? errors.email : undefined}
                    />
                  </Grid>
                </Grid>
                <PrimaryButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={!isValid || !dirty || isLoading}
                  label={t("shared:form.action.save")}
                />
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.phone")}</LabelTypography>
                  <ValueTypography>{values?.telephone || "-"}</ValueTypography>
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.mobile")}</LabelTypography>
                  <ValueTypography>{values?.mobile || "-"}</ValueTypography>
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.email")}</LabelTypography>
                  <ValueTypography>{values?.email || "-"}</ValueTypography>
                </Grid>
              </>
            )}
          </FormEditWrapper>
        </Form>
      )}
    </Formik>
  )
}

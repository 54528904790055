import { Box, ButtonBase, Stack, Typography } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps, Link } from "react-router-dom"
import { NavigationButtonCustomer } from "../../../../uikit/button/NavigationButtonCustomer"
import { SelectPicker } from "../../../../uikit/input/SelectPicker"
import {
  AnalysisIcon,
  BillIcon,
  ConnectedObjectsIcon,
  DashboardIcon,
  ParticipantIcon,
  Settings,
} from "../../../../uikit/Shared.Icon"
import { AppContainerState } from "../../AppContainer.Reducer"
import { AppLogo } from "../AppLogo"
import { AreaCustomerResponse, ContractCustomerResponse } from "../../../../data/generated-sources/openapi"
import { customerGetAreaManagerContracts } from "../../../../domain/portal/manager/contracts/Contract.Repository"
import { useQuery } from "@tanstack/react-query"
import { useAppDispatch } from "../../../hooks"
import {
  initialContractSelectorState,
  setSelectedContractData,
} from "../../../portal/manager/store/contractSelectorSlice"
import { ONBOARDING_REQUIRED_COOKIE_NAME, createCookie } from "../../../Shared.Cookie"
import { setBillingsToInitalState } from "../../../portal/manager/store/billingsSlice"

export interface CustomerNavigationViewProps {
  navigateCustomerRoot: (areaId: string) => void
  navigateCustomerAnalysis: (areaId: string) => void
  navigateCustomerBuildings: (areaId: string) => void
  navigateCustomerParticipants: (areaId: string) => void
  navigateCustomerBillings: (areaId: string) => void
  navigateCustomerUtilityUnits: (areaId: string) => void
  navigateCustomerSettings: (areaId: string) => void
}

export interface CustomerNavigationZevProps {
  areas: AreaCustomerResponse[]
  currentAreaId: string
}

export const CustomerNavigationView = (
  props: CustomerNavigationViewProps & CustomerNavigationZevProps & AppContainerState & RouteComponentProps,
) => {
  const { t } = useTranslation()
  const {
    areas,
    currentAreaId,
    navigateCustomerRoot,
    navigateCustomerAnalysis,
    navigateCustomerParticipants,
    navigateCustomerBillings,
    navigateCustomerUtilityUnits,
    navigateCustomerSettings,
    location,
  } = props
  const selectedNavigation = useMemo(() => determineSelectedNavigation(location.pathname), [location])
  const [selectedAreaId, setSelectedAreaId] = useState(currentAreaId)
  const [onboardingContracts, setOnboardingContracts] = useState<ContractCustomerResponse[]>([])
  const dispatch = useAppDispatch()

  const { remove: removeContract } = useQuery(
    ["getContracts", selectedAreaId],
    () => customerGetAreaManagerContracts(selectedAreaId),
    {
      enabled: !!selectedAreaId,
      onSuccess: (data) => {
        const onboardingContracts = data?.filter((contract) =>
          ["RELEASED", "RELEASED_AND_MODIFIED"].includes(contract.activeState),
        )
        setOnboardingContracts(onboardingContracts)
      },
    },
  )

  useEffect(() => {
    return () => {
      removeContract()
      setOnboardingContracts([])
    }
  }, [removeContract])

  const updateCookie = useCallback(() => {
    createCookie(`${ONBOARDING_REQUIRED_COOKIE_NAME}-${selectedAreaId}`, "true", 1 / 24)
    navigateCustomerSettings(currentAreaId)
  }, [currentAreaId, selectedAreaId, navigateCustomerSettings])

  useEffect(() => {
    if (onboardingContracts?.length) {
      updateCookie()
    }
  }, [updateCookie, onboardingContracts?.length])

  const secondaryLinkStyle = {
    fontSize: "0.875rem",
    fontFamily: "Montserrat",
    textDecoration: "none",
    color: "#8D968D",
    fontWeight: 300,
  }

  const secondaryMenuStyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "30px",
  }

  const secondaryMenuItemSpacerStyle = {
    color: "#8D968D",
    fontFamily: "Montserrat",
    fontWeight: 300,
  }

  return (
    <>
      <Box
        sx={{
          position: "fixed",
          top: 0,
          left: 0,
          backgroundColor: "#FFFFFF",
          borderRight: "1px solid #CCCCCC",
          height: "100%",
          width: 280,
          display: "flex",
          flexDirection: "column",
          zIndex: 100,
          overflow: "auto",
        }}
      >
        <Stack width={280} mb={10}>
          <Box p={4} pb={5}>
            <ButtonBase onClick={() => navigateCustomerRoot(currentAreaId)}>
              <AppLogo />
            </ButtonBase>
            <Typography fontSize={12} fontWeight={600} mt={1.5} color={"#000000"}>
              {t("appContainer:customer.navigation.billing.manager")}
            </Typography>
          </Box>
          <Stack width={240} pl={4}>
            {currentAreaId && (
              <Box>
                <Typography fontSize={12} fontWeight={500} color={"#6A716A"} pb={1}>
                  {t("appContainer:customer.navigation.changeArea")}
                </Typography>
                <SelectPicker
                  type="text"
                  variant="outlined"
                  customStyle={{
                    ":before": { borderBottom: "none" },
                    height: "2.5em",
                    fontSize: "1rem",
                    fontWeight: 500,
                    marginBottom: "1.5em",
                    background: "#FAFAFA",
                  }}
                  value={selectedAreaId}
                  items={areas.map((area: AreaCustomerResponse) => ({
                    value: area.id,
                    label: area.name,
                  }))}
                  onChange={(event) => {
                    dispatch(setBillingsToInitalState())
                    dispatch(setSelectedContractData(initialContractSelectorState))
                    setSelectedAreaId(event.target.value)
                    navigateToSection(event.target.value, props, selectedNavigation)
                  }}
                />
              </Box>
            )}
            <NavigationButtonCustomer
              label={t("appContainer:customer.navigation.overview")}
              icon={<DashboardIcon fontSize="medium" />}
              selected={selectedNavigation === SelectedNavigation.DASHBOARD}
              onClick={() => navigateCustomerRoot(currentAreaId)}
            />
            <NavigationButtonCustomer
              label={t(`appContainer:customer.navigation.${SelectedNavigation.ANALYSIS}`)}
              icon={<AnalysisIcon fontSize="medium" />}
              selected={selectedNavigation === SelectedNavigation.ANALYSIS}
              onClick={() => navigateCustomerAnalysis(currentAreaId)}
            />
            <NavigationButtonCustomer
              label={t(`appContainer:customer.navigation.${SelectedNavigation.UTILITY_UNITS}`)}
              icon={<ConnectedObjectsIcon fontSize="medium" />}
              selected={
                selectedNavigation === SelectedNavigation.UTILITY_UNITS ||
                selectedNavigation === SelectedNavigation.UTILITY_UNIT
              }
              onClick={() => navigateCustomerUtilityUnits(currentAreaId)}
            />
            <NavigationButtonCustomer
              label={t(`appContainer:customer.navigation.${SelectedNavigation.PARTICIPANTS}`)}
              icon={<ParticipantIcon fontSize="medium" />}
              selected={selectedNavigation === SelectedNavigation.PARTICIPANTS}
              onClick={() => navigateCustomerParticipants(currentAreaId)}
            />
            <NavigationButtonCustomer
              label={t("appContainer:customer.navigation.BILLINGS")}
              icon={<BillIcon fontSize="medium" />}
              selected={
                selectedNavigation === SelectedNavigation.BILLINGS_FINALIZE_ALL ||
                selectedNavigation === SelectedNavigation.BILLINGS_EDIT_ALL ||
                selectedNavigation === SelectedNavigation.BILLINGS_FINALIZE ||
                selectedNavigation === SelectedNavigation.BILLINGS_EDIT ||
                selectedNavigation === SelectedNavigation.BILLING_VEWA_BILLS ||
                selectedNavigation === SelectedNavigation.BILLING_VEWA ||
                selectedNavigation === SelectedNavigation.BILLINGS
              }
              onClick={() => navigateCustomerBillings(currentAreaId)}
            />
            <NavigationButtonCustomer
              label={t(`appContainer:customer.navigation.${SelectedNavigation.SETTINGS}`)}
              icon={<Settings fontSize="medium" />}
              selected={selectedNavigation === SelectedNavigation.SETTINGS}
              onClick={() => navigateCustomerSettings(currentAreaId)}
            />
          </Stack>
        </Stack>
        <div style={secondaryMenuStyle}>
          <Link style={secondaryLinkStyle} to={`/${currentAreaId}/faq`}>
            {t("appContainer:customer.navigation.faq")}
          </Link>
          <span style={secondaryMenuItemSpacerStyle}>|</span>
          <a href="mailto:zev@ckw.ch" style={secondaryLinkStyle}>
            {t("appContainer:customer.navigation.contact")}
          </a>
          <span style={secondaryMenuItemSpacerStyle}>|</span>
          <a
            href="https://www.ckw.ch/abrechnungsmanager"
            target="_blank"
            rel="noreferrer noopener"
            style={secondaryLinkStyle}
          >
            {t("appContainer:customer.navigation.explanation.videos")}
          </a>
        </div>
      </Box>
    </>
  )
}

export enum SelectedNavigation {
  DASHBOARD = "DASHBOARD",
  ANALYSIS = "ANALYSIS",
  UTILITY_UNIT = "UTILITY_UNIT",
  UTILITY_UNITS = "UTILITY_UNITS",
  SETTINGS = "SETTINGS",
  BUILDINGS = "BUILDINGS",
  PARTICIPANTS = "PARTICIPANTS",
  BILLINGS = "BILLINGS",
  BILLINGS_FINALIZE = "BILLINGS.FINALIZE.INDIVIDUAL",
  BILLINGS_FINALIZE_ALL = "BILLINGS.FINALIZE",
  BILLINGS_EDIT = "BILLINGS.EDIT.INDIVIDUAL",
  BILLINGS_EDIT_ALL = "BILLINGS.EDIT",
  BILLING_VEWA = "BILLING_VEWA",
  BILLING_VEWA_BILLS = "BILLING_VEWA_BILLS",
  ONBOARDING = "ONBOARDING",
  FAQ = "FAQ",
  UNKNOWN = "UNKNOWN",
}

export const determineSelectedNavigation = (pathname: string): SelectedNavigation => {
  if (pathname.includes("dashboard")) {
    return SelectedNavigation.DASHBOARD
  } else if (pathname.includes("analyses")) {
    return SelectedNavigation.ANALYSIS
  } else if (pathname.includes("buildings")) {
    return SelectedNavigation.BUILDINGS
  } else if (pathname.includes("participants")) {
    return SelectedNavigation.PARTICIPANTS
  } else if (pathname.includes("/billings/vewa/") && pathname.includes("bills")) {
    return SelectedNavigation.BILLING_VEWA_BILLS
  } else if (pathname.includes("/billings/vewa/")) {
    return SelectedNavigation.BILLING_VEWA
  } else if (pathname.includes("billings") && !pathname.includes("finalize") && !pathname.includes("edit")) {
    return SelectedNavigation.BILLINGS
  } else if (pathname.includes("billings") && pathname.includes("finalize") && pathname.includes("all")) {
    return SelectedNavigation.BILLINGS_FINALIZE_ALL
  } else if (pathname.includes("billings") && pathname.includes("finalize")) {
    return SelectedNavigation.BILLINGS_FINALIZE
  } else if (pathname.includes("billings") && pathname.includes("edit") && pathname.includes("all")) {
    return SelectedNavigation.BILLINGS_EDIT_ALL
  } else if (pathname.includes("billings") && pathname.includes("edit")) {
    return SelectedNavigation.BILLINGS_EDIT
  } else if (pathname.includes("utility-units/")) {
    return SelectedNavigation.UTILITY_UNIT
  } else if (pathname.includes("utility-units")) {
    return SelectedNavigation.UTILITY_UNITS
  } else if (pathname.includes("settings")) {
    return SelectedNavigation.SETTINGS
  } else if (pathname.includes("onboarding")) {
    return SelectedNavigation.ONBOARDING
  } else if (pathname.includes("faq")) {
    return SelectedNavigation.FAQ
  } else {
    return SelectedNavigation.UNKNOWN
  }
}

const navigateToSection = (
  areaId: string,
  navigation: CustomerNavigationViewProps,
  selectedNavigation: SelectedNavigation,
) => {
  switch (selectedNavigation) {
    case SelectedNavigation.ANALYSIS:
      return navigation.navigateCustomerAnalysis(areaId)
    case SelectedNavigation.BUILDINGS:
      return navigation.navigateCustomerBuildings(areaId)
    case SelectedNavigation.UTILITY_UNITS:
    case SelectedNavigation.UTILITY_UNIT:
      return navigation.navigateCustomerUtilityUnits(areaId)
    case SelectedNavigation.PARTICIPANTS:
      return navigation.navigateCustomerParticipants(areaId)
    case SelectedNavigation.BILLINGS_FINALIZE_ALL:
    case SelectedNavigation.BILLINGS_EDIT_ALL:
    case SelectedNavigation.BILLINGS_FINALIZE:
    case SelectedNavigation.BILLINGS_EDIT:
    case SelectedNavigation.BILLINGS:
      return navigation.navigateCustomerBillings(areaId)
    case SelectedNavigation.SETTINGS:
      return navigation.navigateCustomerSettings(areaId)
    default:
      return navigation.navigateCustomerRoot(areaId)
  }
}

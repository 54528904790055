export enum ServiceBillingEnum {
  STATUS = "STATUS",
  BILLING_NUMBER = "BILLING_NUMBER",
  AMOUNT = "AMOUNT",
  DATE = "DATE",
}

export enum ServiceBillingTab {
  INITIAL = 0,
  RECURRING = 1,
}

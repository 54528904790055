export enum AnaliseDateRangeEnum {
  WEEK = "WEEK",
  MONTH = "MONTH",
  YEAR = "YEAR",
  DAY = "DAY",
}

export enum ChartTabsEnum {
  HEAT = "HEAT",
  ELECTRICITY = "ELECTRICITY",
  WATER = "WATER",
}

import { connect } from "react-redux"
import { Dispatch } from "react"
import { AnyAction } from "redux"
import { push } from "connected-react-router"
import { AppState } from "../../../App.Reducer"
import { ProfileDetailComponent } from "./ProfileDetails.Component"
import { ProfileActionType } from "./Profile.Epic"
import {
  UpdateAddressUpsert,
  UpdateContactUpsert,
  UpdateEmailUpsert,
  UpdatePersonalUpsert,
} from "../../../../domain/portal/manager/profile/Profile.Model"

export const mapStateToProps = (state: AppState) => {
  return {
    ...state.managerPortal.profileDetails,
  }
}

export const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => {
  return {
    getCustomerProfile: () => {
      dispatch({
        type: ProfileActionType.CUSTOM_PROFILE_GET,
      })
    },
    updateCustomerProfileAddress: (address: UpdateAddressUpsert) => {
      dispatch({
        type: ProfileActionType.CUSTOM_PROFILE_UPDATE_ADDRESS,
        address,
      })
    },
    updateCustomerProfileContact: (contact: UpdateContactUpsert["contact"]) => {
      dispatch({
        type: ProfileActionType.CUSTOM_PROFILE_UPDATE_CONTACT,
        contact,
      })
    },
    updateCustomerProfilePersonal: (personal: UpdatePersonalUpsert) => {
      dispatch({
        type: ProfileActionType.CUSTOM_PROFILE_UPDATE_PERSONAL,
        personal,
      })
    },
    updateCustomerProfileEmail: (email: UpdateEmailUpsert["contact"]) => {
      dispatch({
        type: ProfileActionType.CUSTOM_PROFILE_UPDATE_EMAIL,
        email,
      })
    },
    navigateResetPassword: () => {
      dispatch(push("/change-password"))
    },
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProfileDetailComponent)

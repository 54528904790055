export enum StatusType {
  CREATED = "CREATED",
  DONE = "DONE",
  DRAFT = "DRAFT",
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
  MODIFIED = "MODIFIED",
  APPROVED = "APPROVED",
  TERMINATED = "TERMINATED",
  CANCELLED = "CANCELLED",
  DELETED = "DELETED",
  DATA_AVAILABLE = "DATA_AVAILABLE",
  WAITING_FOR_DATA = "WAITING_FOR_DATA",
  IN_PROGRESS = "IN_PROGRESS",
  IN_PROGRESS_REOPENED = "IN_PROGRESS_REOPENED",
  RELEASED = "RELEASED",
  SUSPENDED = "SUSPENDED",
  PAID = "PAID",
}

export enum PortalContext {
  ADMIN,
  MANAGER,
}

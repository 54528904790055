import { useTranslation } from "react-i18next"
import { OnboardingActionButtons, OnboardingSteperInfo } from "./fragments/OnboardingUI"
import { useQuery } from "@tanstack/react-query"
import { CircularProgress, Grid } from "@mui/material"
import { useEffect, useState } from "react"
import {
  customerGetBillingSettingsByContractId,
  customerGetContractById,
} from "../../../../domain/portal/manager/contracts/Contract.Repository"
import { ProductType } from "../../../../data/generated-sources/openapi"
import { OnboardingPagesProps } from "./Onboarding"

import { ErrorAlert, OptionalSuccessAlert } from "../../../../uikit/Shared.Alert"
import BillingPaymentForm from "../managerSettings/components/billingPreferences/BillingPaymentForm"
import BillingSettingsForm from "../managerSettings/components/billingPreferences/BillingSettingsForm"
import BillingMainContactForm from "../managerSettings/components/billingPreferences/BillingMainContactForm"
import { initialBillingResponse } from "../managerSettings/components/billingPreferences/BillingSettingsTab"
import { AxiosErrorDataType } from "../../../Shared.Utils"

export const OnboardingBillingInfo = ({ contractId, onBackClick, onNextClick }: OnboardingPagesProps) => {
  const { t } = useTranslation("onboarding")
  const [showSuccess, setShowSuccess] = useState(false)
  const [updateError, setUpdateError] = useState<AxiosErrorDataType | null>(null)

  const {
    isLoading,
    isFetching,
    remove: removeContract,
    data: contractData,
  } = useQuery(["getContract"], () => customerGetContractById(contractId), {
    enabled: !!contractId,
    onError: setUpdateError,
  })

  const {
    isLoading: isLoadingBilling,
    isFetching: isFetchingBilling,
    data: billingData,
    refetch: refetchBilling,
    remove: removeBilling,
  } = useQuery(["getBillingSetting"], () => customerGetBillingSettingsByContractId(contractId), {
    enabled: !!contractId,
    onError: setUpdateError,
  })

  const contractProductType = contractData?.product?.productType ?? ProductType.ZEV
  const lastBillingDate = billingData?.billingPeriod.startDate ?? contractData?.startDate

  const billingResponse = { ...initialBillingResponse, ...billingData }

  useEffect(() => {
    return () => {
      removeContract()
      removeBilling()
    }
  }, [removeContract, removeBilling])

  const successHandler = () => {
    refetchBilling()
    setUpdateError(null)
    setShowSuccess(true)
  }

  const errorHandler = (error: AxiosErrorDataType) => {
    setUpdateError(error)
    setShowSuccess(false)
  }

  return (
    <>
      <OnboardingSteperInfo stepNumber={"2"} title={t("billingInfo.title")} />
      <OptionalSuccessAlert show={showSuccess} scrollOnDisplay />
      <ErrorAlert
        visible={!!updateError}
        message={t(`error-codes:${updateError?.response?.data?.code || updateError?.code || "OTHER"}`)}
      />
      {(isLoading && isFetching) || (isLoadingBilling && isFetchingBilling) ? (
        <CircularProgress />
      ) : (
        <Grid container direction={"column"}>
          <BillingSettingsForm
            contractId={contractId}
            billingResponse={billingResponse}
            isZevProductType={contractProductType === ProductType.ZEV}
            vewaStartDate={lastBillingDate}
            onSuccessUpdate={successHandler}
            onErrorUpdate={errorHandler}
          />
          <BillingMainContactForm
            contractId={contractId}
            billingResponse={billingResponse}
            onSuccessUpdate={successHandler}
            onErrorUpdate={errorHandler}
          />
          <BillingPaymentForm
            contractId={contractId}
            billingResponse={billingResponse}
            onSuccessUpdate={successHandler}
            onErrorUpdate={errorHandler}
          />
        </Grid>
      )}
      <OnboardingActionButtons onBackClick={onBackClick} onNextClick={onNextClick} />
    </>
  )
}

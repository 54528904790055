import { forwardRef } from "react"
import { InfoIconOutlined } from "../Shared.Icon"

import { Typography, Tooltip, Fade, styled, TooltipProps, tooltipClasses } from "@mui/material"

const Content = forwardRef<HTMLInputElement>(function Content(props, ref) {
  return (
    <div {...props} ref={ref}>
      <InfoIconOutlined color={"primary"} />
    </div>
  )
})

export const InfoToolTipView = (props: { body: string }) => {
  const { body } = props

  return (
    <HtmlTooltip
      TransitionComponent={Fade}
      TransitionProps={{ timeout: 400 }}
      title={<Typography variant="body1">{body}</Typography>}
    >
      <Content />
    </HtmlTooltip>
  )
}

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} TransitionComponent={Fade} TransitionProps={{ timeout: 600 }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#E6F6FD",
    color: "#36a0f4",
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #36a0f4",
    padding: theme.spacing(2),
  },
}))

import { PersonalDataSalutationEnum } from "../../../../data/generated-sources/openapi"

export interface CustomerProfile {
  personal: UpdatePersonalUpsert
  contact: UpdateContactUpsert
  address: UpdateAddressUpsert
  email: UpdateEmailUpsert
  customerNumber: string
  contractConfigIds: string[]
}

export interface UpdateAddressUpsert {
  address: {
    city: string
    houseNumber: string
    street: string
    postalCode: string
  }
}

export interface UpdateContactUpsert {
  contact: {
    phone: string
    mobile: string
  }
}

export interface UpdateEmailUpsert {
  contact: {
    email: string
  }
}

export interface UpdatePasswordUpsert {
  currentPassword: string
  newPassword: string
}

export interface UpdatePersonalUpsert {
  personal: {
    salutation: PersonalDataSalutationEnum
    firstName: string
    lastName: string
  }
}

export const initialValuesPersonal: UpdatePersonalUpsert = {
  personal: {
    salutation: PersonalDataSalutationEnum.MR,
    firstName: "",
    lastName: "",
  },
}

export const initialValuesAddress: UpdateAddressUpsert = {
  address: {
    street: "",
    houseNumber: "",
    postalCode: "",
    city: "",
  },
}

export const initialValuesContact: UpdateContactUpsert = {
  contact: {
    phone: "",
    mobile: "",
  },
}

export const initialValuesEmail: UpdateEmailUpsert = {
  contact: {
    email: "",
  },
}

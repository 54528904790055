import { useMutation, useQuery } from "@tanstack/react-query"
import { Box } from "@mui/material"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

import { useAppSelector } from "../../../../../hooks"
import { selectSelectedContractData } from "../../../store/contractSelectorSlice"
import { VewaBillingCostShares } from "../../../../shared/vewaBillings/VewaBillingCostShares"
import {
  customerGetVewaCostSharesByContractId,
  customerPutVewaCostSharesByContractId,
} from "../../../../../../domain/portal/manager/contracts/Contract.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { VewaCostShare, VewaCostShares } from "../../../../../../data/generated-sources/openapi"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

export const SettingsVewaCostSharesTab = () => {
  const { contractId } = useAppSelector(selectSelectedContractData)
  const { t } = useTranslation()
  const [error, setError] = useState<AxiosErrorDataType>()

  const {
    data: vewaCostShares,
    remove: removeVewaCostShares,
    refetch: refetchCostShares,
  } = useQuery(["getVewaCostShares"], () => customerGetVewaCostSharesByContractId(contractId), {
    enabled: !!contractId,
    onError: setError,
  })

  const { mutate: updateCostShares, isSuccess } = useMutation(
    ["updateCostShares"],
    (vewaCostShares: VewaCostShares) => customerPutVewaCostSharesByContractId(contractId, vewaCostShares),
    {
      onError: setError,
      onSuccess: () => refetchCostShares(),
    },
  )

  useEffect(() => {
    return () => {
      removeVewaCostShares()
    }
  }, [removeVewaCostShares, contractId])

  const handleUpdate = (vewaCostShares: VewaCostShare[]) => {
    setError(undefined)
    updateCostShares({ costShares: vewaCostShares })
  }

  return (
    <Box p={4}>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || "OTHER"}`)}
      />
      <VewaBillingCostShares
        costShares={vewaCostShares?.costShares}
        onUpdateSubmit={handleUpdate}
        isUpdateSuccess={isSuccess}
      />
    </Box>
  )
}

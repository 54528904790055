import { GridActionsColDef, GridRenderCellParams } from "@mui/x-data-grid"
import { GridBaseColDef } from "@mui/x-data-grid/internals"
import { useTranslation } from "react-i18next"
import { StatusView } from "../../../../uikit/label/StatusView"
import DateUtils from "../../../../services/utils/DateUtils"
import { DefaultCell } from "../../../../uikit/dataGridTable"
import { TaskListSyncIndicator } from "./TaskListSyncIndicator"
import { useHistory } from "react-router-dom"
import { Task, TaskTaskScopeTypeEnum } from "../../../../data/generated-sources/openapi"
import { IconButton, styled, Typography } from "@mui/material"
import { useCallback } from "react"
import { KeyboardArrowDownIcon, KeyboardArrowUpIcon, OpenIcon } from "../../../../uikit/Shared.Icon"
import { appThemeSecondaryColor } from "../../../Shared.Theme"

const CommentTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 500,
  fontSize: theme.typography.pxToRem(14),
  padding: theme.spacing(2, 0),
  cursor: "pointer",
  border: "1px solid transparent",
  minWidth: "100px",
  textAlign: "center",
  ":hover": { fontWeight: "bold", border: `1px solid ${appThemeSecondaryColor}`, borderRadius: "12px" },
}))

const ContractAreaRedirectCell = ({ value }: GridRenderCellParams) => {
  const { taskScope, taskScopeType } = value
  const history = useHistory()

  if ([TaskTaskScopeTypeEnum.AREA, TaskTaskScopeTypeEnum.CONTRACT].includes(taskScopeType)) {
    return (
      <IconButton
        onClick={() => {
          taskScopeType === TaskTaskScopeTypeEnum.AREA
            ? history.push(`/area/${taskScope}`)
            : history.push(`/management-contracts/${taskScope}`)
        }}
      >
        <OpenIcon fontSize="small" />
      </IconButton>
    )
  }
  return <>-</>
}

const CommentCell = ({ value }: GridRenderCellParams) => (
  <CommentTypography noWrap data-testid="contractCommentTypography">
    {value !== "" && value ? value : "-"}
  </CommentTypography>
)

export const TaskListColumns = (expandedRowId: string, handleExpandRow: (rowId: string) => void) => {
  const { t } = useTranslation("tasklist")

  const ArrowCell = useCallback(
    ({ value }: GridRenderCellParams) => {
      const sameRowId = expandedRowId === value
      const expandId = sameRowId ? "" : value
      return (
        <IconButton aria-label="expand row" size="small" onClick={() => handleExpandRow(expandId)}>
          {sameRowId ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
        </IconButton>
      )
    },
    [expandedRowId],
  )

  const taskListColumns: Array<GridBaseColDef | GridActionsColDef> = [
    {
      flex: 50,
      field: "taskId",
      headerName: t("list.label.id"),
      renderCell: DefaultCell,
    },
    {
      flex: 70,
      field: "status",
      sortable: false,
      headerName: t("list.label.status"),
      renderCell: (params: GridRenderCellParams) => <StatusView statusType={params.row?.status} />,
    },
    {
      flex: 80,
      field: "createdAt",
      headerName: t("list.label.date"),
      valueGetter: (_, row) => row,
      valueFormatter: (value: Task) => DateUtils.getDeFormatDateWithMonthString(value?.createdAt),
      renderCell: ({ formattedValue, ...rest }) => <DefaultCell {...rest} value={formattedValue} />,
      sortComparator: (v1, v2) => (v1?.createdAt > v2?.createdAt ? 1 : -1),
    },
    {
      flex: 120,
      field: "type",
      headerName: t("list.label.event"),
      valueGetter: (_, row) => t(`state.${row?.type}`),
      renderCell: DefaultCell,
    },
    {
      flex: 220,
      field: "taskScopeName",
      headerName: t("field.label.contract"),
      renderCell: DefaultCell,
    },
    {
      flex: 10,
      field: "redirectButton",
      headerName: "",
      sortable: false,
      valueGetter: (_, row) => row,
      renderCell: ContractAreaRedirectCell,
    },
    {
      flex: 100,
      field: "comment",
      headerName: t("shared:label.comments"),
      renderCell: CommentCell,
    },
    {
      flex: 50,
      field: "participantSapSyncStatus",
      headerName: t("list.label.sync"),
      renderCell: ({ value }: GridRenderCellParams) => <TaskListSyncIndicator syncStatus={value} />,
    },
    {
      field: "expand",
      flex: 40,
      headerName: "",
      sortable: false,
      valueGetter: (_, row) => row?.id,
      renderCell: ArrowCell,
    },
  ]
  return taskListColumns
}

import { FieldTouched, validate, ValidField } from "../../../../Shared.Validation"

export const validateProfileContactForm = {
  mobile: (value: string, touched: FieldTouched) =>
    validate(() => {
      return ValidField
    }, touched),
  phone: (value: string, touched: FieldTouched) =>
    validate(() => {
      return ValidField
    }, touched),
}

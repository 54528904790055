import { Grid } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { Form, Formik } from "formik"
import { ContractAddressData } from "../../../../../data/generated-sources/openapi"

import { OnboardingContactInfoFormProps } from "./OnboardingContactInfo"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { PrimaryButton } from "../../../../../uikit/button"
import { LabelTypography, FormEditWrapper, ValueTypography, FormContactHeader } from "../fragments/OnboardingUI"
import { useMutation } from "@tanstack/react-query"
import { customerUpdateContactAddressByContractId } from "../../../../../domain/portal/manager/contracts/Contract.Repository"
import { onboardAddressDataSchema } from "./ContactInfoValidation.Schema"

export const OnboardingContactAddressForm = ({
  contractId,
  addressData,
  onSuccessUpdate,
  onErrorUpdate,
}: OnboardingContactInfoFormProps) => {
  const { t } = useTranslation("onboarding")
  const [editAdress, setEditAdress] = useState(false)

  const { mutate: updateAddress, isLoading } = useMutation(
    ["updateAddress"],
    (addresssData: ContractAddressData) => customerUpdateContactAddressByContractId(contractId, addresssData),
    {
      onError: onErrorUpdate,
      onSuccess: () => {
        onSuccessUpdate()
        setEditAdress(false)
      },
    },
  )

  return (
    <Formik<ContractAddressData>
      enableReinitialize
      initialValues={addressData}
      validationSchema={onboardAddressDataSchema}
      onSubmit={(data) => updateAddress(data)}
    >
      {({ values, errors, touched, isValid, dirty, handleBlur, setFieldValue, resetForm }) => (
        <Form>
          <FormEditWrapper container item rowGap={2} editMode={editAdress}>
            <FormContactHeader
              headerText={t("contactInfo.adress")}
              editMode={editAdress}
              onClick={() => {
                resetForm()
                setEditAdress((prevState) => !prevState)
              }}
            />
            {editAdress ? (
              <>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    sx={{ mb: 1.5 }}
                    name="street"
                    type="text"
                    label={t("contracts-management:contactForm.street")}
                    value={values?.street}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("street", event.target.value)}
                    helperText={touched.street && errors.street ? errors.street : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    required
                    sx={{ mb: 1.5 }}
                    name="houseNumber"
                    type="text"
                    label={t("contracts-management:contactForm.houseNumber")}
                    value={values?.houseNumber}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("houseNumber", event.target.value)}
                    helperText={touched.houseNumber && errors.houseNumber ? errors.houseNumber : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    required
                    sx={{ mb: 1.5 }}
                    name="postalCode"
                    type="text"
                    label={t("contracts-management:contactForm.postalCode")}
                    value={values?.postalCode}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("postalCode", event.target.value)}
                    helperText={touched.postalCode && errors.postalCode ? errors.postalCode : undefined}
                  />
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <SingleLineTextField
                    required
                    sx={{ mb: 1.5 }}
                    name="city"
                    type="text"
                    label={t("contracts-management:contactForm.city")}
                    value={values?.city}
                    onBlur={handleBlur}
                    onChange={(event) => setFieldValue("city", event.target.value)}
                    helperText={touched.city && errors.city ? errors.city : undefined}
                  />
                </Grid>
                <PrimaryButton
                  type="submit"
                  isLoading={isLoading}
                  disabled={!isValid || !dirty}
                  label={t("shared:form.action.save")}
                />
              </>
            ) : (
              <>
                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.street")}</LabelTypography>
                  <ValueTypography>{values?.street || "-"}</ValueTypography>
                </Grid>

                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.houseNumber")}</LabelTypography>
                  <ValueTypography>{values?.houseNumber || "-"}</ValueTypography>
                </Grid>
                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.postalCode")}</LabelTypography>
                  <ValueTypography>{values?.postalCode || "-"}</ValueTypography>
                </Grid>

                <Grid item xs={12} md={6} pr={3}>
                  <LabelTypography>{t("contracts-management:contactForm.city")}</LabelTypography>
                  <ValueTypography>{values?.city || "-"}</ValueTypography>
                </Grid>
              </>
            )}
          </FormEditWrapper>
        </Form>
      )}
    </Formik>
  )
}

import { useMutation } from "@tanstack/react-query"
import { Form, Formik, FormikHelpers } from "formik"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router"
import { AdminPortalRouteParams } from "../../AdminPortal.Routes"

import { createBuildingUtilityUnit } from "../../../../../domain/portal/admin/buildings/Buildings.Repository"
import { FormUpdateActionsView } from "../../../../../uikit/form/FormUpdateActions"
import { ErrorAlert, SuccessAlertLink } from "../../../../../uikit/Shared.Alert"
import { UtilityUnitCreateAdminRequest } from "../../../../../data/generated-sources/openapi"
import { UtilityUnitModifyFormFields } from "./UtilityUnitModifyFormFields"
import { UtilityUnitModifySchema } from "./UtilityUnitValidation.Schema"
import { AxiosErrorDataType } from "../../../../Shared.Utils"

const UtilityUnitCreateForm = ({ history, match: { params } }: RouteComponentProps<AdminPortalRouteParams>) => {
  const { buildingId } = params
  const { t } = useTranslation("utilityUnit")
  const [utilityUnitId, setUtilityUnitId] = useState<string | undefined>(undefined)
  const [unitError, setUnitError] = useState<AxiosErrorDataType>()

  const {
    mutate: createUnit,
    isLoading,
    isSuccess,
    isError,
  } = useMutation(
    ["createUtilityUnit"],
    (createData: UtilityUnitCreateAdminRequest) => createBuildingUtilityUnit(buildingId, createData),
    {
      onError: setUnitError,
    },
  )

  const handleOnSubmit = (
    { name, usageType, unitSpace }: Partial<UtilityUnitCreateAdminRequest>,
    formikHelpers: FormikHelpers<Partial<UtilityUnitCreateAdminRequest>>,
  ) => {
    if (!name || !usageType) return
    const unitSpaceNumber = unitSpace ? Math.round(unitSpace * 100) / 100 : undefined
    setUnitError(undefined)
    createUnit(
      { name, usageType, ...{ unitSpace: unitSpaceNumber } },
      {
        onSuccess: ({ data }) => {
          formikHelpers.resetForm()
          const utilityUnitId = data.split("/").at(-1)
          setUtilityUnitId(utilityUnitId)
        },
      },
    )
  }

  const goToUtilityUnit = () => utilityUnitId && history.push(`/utility-units/${utilityUnitId}`)

  return (
    <>
      <ErrorAlert
        visible={isError && !!unitError}
        message={t(`error-codes:${unitError?.response?.data?.code || unitError?.code || "OTHER"}`)}
      />
      <SuccessAlertLink message={t("unitCreate.successMsg")} visible={isSuccess} onClick={goToUtilityUnit} />

      <Formik<Partial<UtilityUnitCreateAdminRequest>>
        initialValues={{
          name: "",
          usageType: undefined,
        }}
        validationSchema={UtilityUnitModifySchema}
        onSubmit={handleOnSubmit}
      >
        {(props) => (
          <Form>
            <UtilityUnitModifyFormFields {...props} />
            <FormUpdateActionsView
              isValid={props.isValid}
              dirty={props.dirty}
              isLoading={isLoading}
              navigateBack={() => history.goBack()}
              buttonCtaLabel={t("label.saveChanges")}
            />
          </Form>
        )}
      </Formik>
    </>
  )
}

export default UtilityUnitCreateForm

import { I18nNamespace } from "../../../App.i18n"

export const PricesI18n: I18nNamespace = {
  name: "prices",
  de: {
    "form.field.title": "Titel",
    "form.field.solarPower": "ZEV Sonnenstrom / kWh",
    "form.field.highTariff": "Netzstrom Hochtarif / kWh",
    "form.field.lowTariff": "Netzstrom Niedertarif / kWh",
    "form.toggle.consumptionDependant": "Verbrauchsabhängig",
    "form.toggle.flatRate": "Pauschal",
    "form.toggle.spike.without": "Ohne Leistungspreis",
    "form.toggle.spike.with": "Mit Leistungspreis",
    "form.field.measurementServicePerHour": "Messung und Dienstleistung / kWh",
    "form.field.measurementServiceMonth": "Messung und Dienstleistung / Monat",
    "form.field.spikePrice": "Leistungspreis / KW / Monat",
    "section-main.tab.prices.without.performance": "Ohne Leistungspreis",
    "section-main.tab.prices.with.performance": "Mit Leistungspreis",
    "section-main.tab.prices.consumption.dependent": "Verbrauchsabhängig",
    "section-main.tab.prices.consumption": "Verbrauch",
    "section-main.tab.prices.dependent": "abhängig",
    "section-main.tab.prices.lump.sum": "Pauschal",
    "section-main.tab.prices.lump": "Klumpen",
    "section-main.tab.prices.sum": "Summe",
    "section-main.tab.prices.without": "Ohne",
    "section-main.tab.prices.with": "Mit",
    "section-main.tab.prices.performance": "leistung",
    "section-main.tab.prices.price": "preis",
  },
  en: {
    "form.field.title": "Title",
    "form.field.solarPower": "ZEV solar power / kWh",
    "form.field.highTariff": "Mains electricity high tariff / kWh",
    "form.field.lowTariff": "Low tariff grid electricity / kWh",
    "form.toggle.consumptionDependant": "Consumption-dependent",
    "form.toggle.flatRate": "Flat-rate",
    "form.toggle.spike.without": "Without spike prices",
    "form.toggle.spike.with": "With spike prices",
    "form.field.measurementServicePerHour": "Measurement and service / kWh",
    "form.field.measurementServiceMonth": "Measurement and service / Monat",
    "form.field.spikePrice": "Performance price / KW / month",
    "section-main.tab.prices.without": "Without",
    "section-main.tab.prices.performance": "performance",
    "section-main.tab.prices.price": "price",
    "section-main.tab.prices.with.performance": "With performance price",
    "section-main.tab.prices.without.performance": "Without performance price",
    "section-main.tab.prices.consumption.dependent": "Consumption",
    "section-main.tab.prices.dependent": "dependent",
    "section-main.tab.prices.lump": "Lump",
    "section-main.tab.prices.sum": "sum",
  },
}

import { ServiceBillingAdminResponse } from "../../../../../data/generated-sources/openapi"
import { ORDERED_NUMBER_COMPARATOR, ORDERED_STRING_COMPARATOR } from "../../../../../domain/Domain.Comparators"
import { TableColumnSortNew } from "../../../../../uikit/table/Table.HeaderView"
import { ServiceBillingEnum } from "./ServiceBilling.Enum"

export const ServiceBillingComparator = (orderBy: TableColumnSortNew) => {
  switch (orderBy.column) {
    case ServiceBillingEnum.STATUS:
      return (a: ServiceBillingAdminResponse, b: ServiceBillingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.activeState, b.activeState, orderBy.direction)
    case ServiceBillingEnum.BILLING_NUMBER:
      return (a: ServiceBillingAdminResponse, b: ServiceBillingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.invoiceReferenceNumber ?? "", b.invoiceReferenceNumber ?? "", orderBy.direction)
    case ServiceBillingEnum.AMOUNT:
      return (a: ServiceBillingAdminResponse, b: ServiceBillingAdminResponse) =>
        ORDERED_NUMBER_COMPARATOR(Number(a.totalAmountDue), Number(b.totalAmountDue), orderBy.direction)
    case ServiceBillingEnum.DATE:
      return (a: ServiceBillingAdminResponse, b: ServiceBillingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.billingDate, b.billingDate, orderBy.direction)
    default:
      return (a: ServiceBillingAdminResponse, b: ServiceBillingAdminResponse) =>
        ORDERED_STRING_COMPARATOR(a.id, b.id, orderBy.direction)
  }
}

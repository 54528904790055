import * as yup from "yup"
import { getTranslateValue } from "../../../../../Shared.Utils"

export const recurringBilingsSchema = yup.object().shape({
  startDate: yup.string().required(),
  endDate: yup.string().required(),
  contractIds: yup
    .array()
    .of(yup.string())
    .min(1, () =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("billings-recurring:field.label.contract"),
      }),
    )
    .required(() =>
      getTranslateValue("shared:validation.mandatory", {
        field: getTranslateValue("billings-recurring:field.label.contract"),
      }),
    ),
})

import { createContext, useEffect, useMemo, useState } from "react"
import { useParams } from "react-router-dom"
import { FullScreenLoader } from "../../../../uikit/indicator/ProgressIndicator"
import { StatusView } from "../../../../uikit/label/StatusView"
import { StatusType } from "../../../../domain/Domain.Model"
import { AxiosErrorDataType, StatusTypeObjectKey } from "../../../Shared.Utils"
import { DataItemBoxNewDesign } from "../../../../uikit/box/DataItemBox"
import { Grid, Paper, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  MasterDataComponentInterface,
  MasterDataContextValueInterface,
  MasterDataRenderingContentInterface,
  RenderingColumnItemInterface,
} from "./MasterData.Interface"
import { ErrorAlert, OptionalSuccessAlert } from "../../../../uikit/Shared.Alert"

export const MasterDataContext = createContext<MasterDataContextValueInterface>({
  error: null,
  componentName: undefined,
  successMessage: undefined,
  setError: () => Function,
  setComponentName: () => Function,
  setSuccessMessage: () => Function,
})

const MasterDataComponent = (props: MasterDataComponentInterface) => {
  const { t } = useTranslation()
  const { id } = useParams<{ id: string }>()
  const { config } = props
  const [response] = config.useQueryHook(id)

  const [error, setError] = useState<AxiosErrorDataType | null>(null)
  const [componentName, setComponentName] = useState<string>("")
  const [successMessage, setSuccessMessage] = useState<string>("")

  const contextValue: MasterDataContextValueInterface = useMemo(
    () => ({
      error,
      componentName,
      successMessage,
      setError,
      setComponentName,
      setSuccessMessage,
    }),
    [componentName, error, successMessage],
  )

  useEffect(() => {
    return () => {
      response?.remove()
    }
  }, [])

  const columnLayout = (item: RenderingColumnItemInterface) => {
    if (item?.getComponentRow) return item.getComponentRow(response)
    if (item?.getComponent) return item.getComponent(response)
    if (!item?.getValue) return null
    if (!item.label) return <StatusView statusType={StatusType[item?.getValue(response) as StatusTypeObjectKey]} />
    else return <DataItemBoxNewDesign title={t(item.label)} value={item?.getValue(response)} />
  }

  const contentLayout = () => {
    return config.renderingContent.map((item: MasterDataRenderingContentInterface, index: number) => {
      return (
        <Grid container key={index} py={2} px={3} alignItems="center" justifyContent="space-between">
          {item.row ? (
            <Grid container item direction="row" xs={12}>
              {columnLayout(item.row)}
            </Grid>
          ) : (
            <>
              <Grid item xs={6}>
                {columnLayout(item.firstColumn)}
              </Grid>
              {item?.secondColumn && (
                <Grid item xs={6}>
                  {columnLayout(item.secondColumn)}
                </Grid>
              )}
            </>
          )}
        </Grid>
      )
    })
  }
  return (
    <>
      {response?.isLoading ? (
        <FullScreenLoader />
      ) : (
        <MasterDataContext.Provider value={contextValue}>
          <Paper sx={{ p: 0, pb: 2 }}>
            {response.isError ? (
              <Grid container>
                <Grid item md={12}>
                  <Typography variant="h5" fontFamily="Montserrat" fontWeight="lighter">
                    {config.name}
                  </Typography>
                </Grid>
                <Grid item md={12} p={2}>
                  <ErrorAlert
                    message={typeof response?.error === "string" ? response?.error : t("error-codes:OTHER")}
                    visible={response.isError}
                  />
                </Grid>
              </Grid>
            ) : (
              <>
                <OptionalSuccessAlert
                  message={contextValue?.successMessage}
                  show={!!contextValue?.successMessage && config.componentName === contextValue.componentName}
                />
                <ErrorAlert
                  visible={
                    !!contextValue?.error?.response?.data?.code && config.componentName === contextValue.componentName
                  }
                  message={t(`error-codes:${contextValue?.error?.response?.data?.code || "OTHER"}`)}
                />
                {contentLayout()}
              </>
            )}
          </Paper>
          {config?.children}
          {config?.childrenWithResponse && config?.childrenWithResponse(response)}
        </MasterDataContext.Provider>
      )}
    </>
  )
}

export default MasterDataComponent

import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { Box, Divider, Stack, Typography } from "@mui/material"
import { IServiceBillings } from "../../../../../../domain/portal/admin/billings/recurring/BillingsRecurring.Model"
import { sapSendAdminServiceBillingById } from "../../../../../../domain/portal/admin/billings/initial/BillingsInitial.Repository"
import { SendIcon } from "../../../../../../uikit/Shared.Icon"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ErrorButtonLoading } from "../../../../../../uikit/button"

const BillingInfoData = ({ title, value }: { title: string; value?: string }) => (
  <Box data-testid="recurringBillingInfoData">
    <Typography fontSize={12} color={"primary"}>
      {title}
    </Typography>
    <Typography fontSize={16}>{value ?? "-"}</Typography>
  </Box>
)
interface RecurringBillingPositionsHeaderProps extends IServiceBillings {
  onInvalidateQuery: () => void
}

export const RecurringBillingPositionsHeader = ({
  id,
  datePeriod,
  billingType,
  orderReferenceNumber,
  invoiceReferenceNumber,
  accountingStatusTranslated,
  accountingErrorMessage,
  onInvalidateQuery,
}: RecurringBillingPositionsHeaderProps) => {
  const { t } = useTranslation("billings-recurring")

  const { mutate: sapSend, isLoading: isSapSending } = useMutation(
    ["sapSend"],
    () => sapSendAdminServiceBillingById(id),
    {
      onSuccess: () => onInvalidateQuery(),
    },
  )

  return (
    <>
      <Divider sx={{ mt: 4, mb: 6 }} />
      {accountingErrorMessage && (
        <ErrorAlert
          scrollOnDisplay
          title={t("details.label.submission.error")}
          message={
            <Stack>
              <Typography mb={2}>{accountingErrorMessage}</Typography>
              <Box>
                <ErrorButtonLoading
                  startIcon={<SendIcon />}
                  isLoading={isSapSending}
                  onClick={() => sapSend()}
                  label={t("details.button.retry")}
                />
              </Box>
            </Stack>
          }
        />
      )}
      <Stack
        direction={"row"}
        alignItems={"center"}
        justifyContent={"space-between"}
        mb={6}
        data-testid="recurringBillingPositionsHeaderWrapper"
      >
        <BillingInfoData title={t("field.label.period")} value={datePeriod} />
        <BillingInfoData title={t("field.label.type")} value={t(`shared:billingType.${billingType}`)} />
        <BillingInfoData title={t("field.label.orderReferenceNumber")} value={orderReferenceNumber} />
        <BillingInfoData title={t("field.label.invoiceReferenceNumber")} value={invoiceReferenceNumber} />
        <BillingInfoData title={t("field.label.billingStatus")} value={accountingStatusTranslated} />
      </Stack>
      <Typography variant="subtitle1" fontWeight={500} my={4}>
        {t("detail.invoiceItems")}
      </Typography>
    </>
  )
}

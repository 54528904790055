import { ParticipantIndividualBillingApiEnum } from "../Components.Enums"
import {
  ParticipantIndividualBillingEnum,
  ParticipantPeriodicBillingEnum,
} from "../participantBilling/ParticipantBilling.Enum"
import ParticipantIndividualBillingTable, {
  ExtendedIndividualParticipantBilling,
} from "../participantBilling/ParticipanIndividualBillingTable.Component"
import { ParticipantIndividualBillingComparator } from "../participations/ParticipantIndividualBilling.Comparator"
import { getPageIndividualParticipantsBillingsByContractId } from "../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { getUniqueId } from "../../../../../services/utils/SharedUtils"

export const PARTICIPANT_INDIVIDUAL_BILLING_LIST_COMPONENT_CONFIG = {
  participantIndividualBilling: {
    name: "participantIndividualBilling",
    columnEnums: ParticipantIndividualBillingEnum,
    header: {
      title: "",
      showButton: false,
      showFilter: false,
    },
    apiDefaultParams: {
      page: 1,
      limit: 100000,
      orderBy: "",
    },
    defaultSorting: {
      column: ParticipantIndividualBillingEnum.STATUS,
      direction: "desc",
    },
    columnComparator: ParticipantIndividualBillingComparator,
    getDataFromRepository: getPageIndividualParticipantsBillingsByContractId,
    tableHeaders: [
      {
        column: ParticipantIndividualBillingEnum.PERIOD,
        label: "shared:label.period",
        width: "25%",
        orderable: true,
      },
      {
        column: ParticipantIndividualBillingEnum.UNIT,
        label: "appContainer:admin.navigation.utilityUnit",
        width: "25%",
        orderable: true,
      },
      {
        column: ParticipantIndividualBillingEnum.PARTICIPANT,
        label: "appContainer:customer.navigation.PARTICIPANTS",
        width: "20%",
        orderable: true,
      },
      {
        column: ParticipantPeriodicBillingEnum.AMOUNT,
        label: "billings-recurring:field.label.totalAmount",
        width: "30%",
        orderable: true,
      },
      {
        column: ParticipantPeriodicBillingEnum.STATUS,
        label: "shared:status.label",
        width: "10%",
        orderable: true,
      },
    ],
    renderTableRows: (item: ExtendedIndividualParticipantBilling) => (
      <ParticipantIndividualBillingTable itemConfig={item} key={getUniqueId()} />
    ),
    defaultRowsPerPage: 5,
  },
}

export const PARTICIPANT_INDIVIDUAL_BILLING_API_CONFIG = {
  participantIndividualBilling: ParticipantIndividualBillingApiEnum,
}

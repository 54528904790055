import { connectRouter, RouterState, routerMiddleware } from "connected-react-router"
import { combineReducers } from "redux"
import { createBrowserHistory } from "history"
import { appContainerReducer, AppContainerState } from "./appcontainer/AppContainer.Reducer"
import { authLoginReducer, AuthState } from "./auth/AuthLogin.Reducer"
import { managerPortalReducer, ManagerPortalState } from "./portal/manager/ManagerPortal.Reducer"
import { adminPortalReducer, AdminPortalState } from "./portal/admin/AdminPortal.Reducer"

export interface AppState {
  router: RouterState
  appContainer: AppContainerState
  authLogin: AuthState
  adminPortal: AdminPortalState
  managerPortal: ManagerPortalState
}

export const history = createBrowserHistory()

history.listen(() => window.scrollTo(0, 0))

export const historyMiddleWare = routerMiddleware(history)

export const createAppReducer = combineReducers<AppState>({
  router: connectRouter(history),
  appContainer: appContainerReducer,
  authLogin: authLoginReducer,
  adminPortal: adminPortalReducer,
  managerPortal: managerPortalReducer,
})

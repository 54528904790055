import localeDateDE from "date-fns/locale/de"
import localeDateEN from "date-fns/locale/en-US"
import { domainDependencies } from "../domain/Domain.Dependencies"
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import CONFIG from "../public/config"

const baseUrl = () => {
  const prodUrl = sessionStorage.getItem("API_BASE_URL")
  const isMockServer = process.env.SERVER_ENV === "mockServer"
  const isDevServer = process.env.SERVER_ENV === "devServer"

  if (prodUrl) return prodUrl
  if (isMockServer) return CONFIG?.API_BASE_URL.mock
  if (isDevServer) return CONFIG?.API_BASE_URL.dev
  return CONFIG?.API_BASE_URL.test
}

const assertNotUndefined = (value: string | undefined, key: string): string => {
  if (!value) throw Error(`env var ${key} not set`)
  return value
}

export const DOMAIN_DEPENDENCIES = domainDependencies({
  baseUrl: baseUrl(),
  appName: assertNotUndefined(process.env.APP_NAME, "APP_NAME"),
  locale: getLocale(assertNotUndefined(process.env.APP_LANG, "APP_LANG")),
})

function getLocale(lang: string) {
  switch (lang) {
    case "en":
      return localeDateEN
    case "de":
      return localeDateDE
    default:
      throw Error("language not found")
  }
}

import { Typography } from "@mui/material"
import Box from "@mui/material/Box"
import TableCell from "@mui/material/TableCell"
import TableHead from "@mui/material/TableHead"
import TableRow from "@mui/material/TableRow"
import TableSortLabel from "@mui/material/TableSortLabel"
import { visuallyHidden } from "@mui/utils"
import { useTranslation } from "react-i18next"
import { SortDirection } from "../../domain/Domain.Comparators"
import { DEFAULT_ROWS_PER_PAGE } from "../Shared.Consts"
import { TableLoadingSkeleton } from "./Table.Loading"

export enum TableAlign {
  left = "left",
  right = "right",
  center = "center",
}

export interface TableHeader<T> {
  column: T
  label: string
  width: number | string
  orderable?: boolean
  align?: TableAlign
}

export interface TableColumnSort<T> {
  column: T
  direction: SortDirection
}

export interface TableColumnSortNew {
  column: string
  direction: SortDirection
}

interface TableHeaderViewProps<T extends string> {
  headers: TableHeader<T>[]
  orderBy: TableColumnSort<T>
  orderByChanged: (sort: TableColumnSort<T>) => void
  isLoading?: boolean
  isUsingTranslate?: boolean
}

interface TableHeaderNoOrderingPropsInterface {
  headers: TableHeader<unknown>[]
  isLoading?: boolean
}

export function TableHeaderView<T extends string>(props: TableHeaderViewProps<T>) {
  const { headers, orderBy, isLoading, isUsingTranslate, orderByChanged } = props
  const { t } = useTranslation()
  const tableHeadTextStyle = {
    fontWeight: "bold",
    fontFamily: "Montserrat",
  }

  const renderTableHeaderCellBox = (label: string) => {
    return (
      <Box sx={{ ...tableHeadTextStyle, textTransform: "uppercase", wordBreak: "break-word" }}>
        {isUsingTranslate ? t(label) : label}
      </Box>
    )
  }

  return (
    <>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell
              key={header.column}
              align={header.align ? header.align : "left"}
              padding="normal"
              sortDirection={orderBy.direction}
              sx={{
                width: header.width,
              }}
            >
              {header.orderable === false ? (
                <Typography id={`table-header-${header.column}`} variant="subtitle2">
                  {renderTableHeaderCellBox(header.label)}
                </Typography>
              ) : (
                <TableSortLabel
                  id={`table-header-${header.column}`}
                  active={orderBy.column === header.column}
                  direction={orderBy.direction}
                  onClick={() => {
                    if (orderBy.column === header.column) {
                      orderByChanged({
                        ...orderBy,
                        direction: orderBy.direction === "asc" ? "desc" : "asc",
                      })
                    } else {
                      orderByChanged({
                        column: header.column,
                        direction: "asc",
                      })
                    }
                  }}
                >
                  {renderTableHeaderCellBox(header.label)}
                  {orderBy.column === header.column ? (
                    <Box component="span" sx={visuallyHidden}>
                      {orderBy.direction === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading && <TableLoadingSkeleton colSpan={headers.length} rowsPerPage={DEFAULT_ROWS_PER_PAGE} />}
    </>
  )
}

export function NonBoldTableHeaderView<T extends string>(props: TableHeaderViewProps<T>) {
  const { headers, orderBy, isLoading, orderByChanged } = props
  const tableHeadTextStyle = {
    fontWeight: "normal",
    fontFamily: "Montserrat",
    fontSize: "1rem",
    marginLeft: "5px",
  }

  return (
    <>
      <TableHead>
        <TableRow>
          {headers.map((header) => (
            <TableCell
              key={header.column}
              align={header.align ? header.align : "left"}
              padding="normal"
              sortDirection={orderBy.direction}
              sx={{
                minWidth: header.width,
              }}
            >
              {header.orderable === false ? (
                <Typography id={`table-header-${header.column}`} variant="subtitle2" sx={tableHeadTextStyle}>
                  <Box sx={{ textTransform: "capitalize" }}>{header.label}</Box>
                </Typography>
              ) : (
                <TableSortLabel
                  id={`table-header-${header.column}`}
                  active={orderBy.column === header.column}
                  direction={orderBy.direction}
                  onClick={() => {
                    if (orderBy.column === header.column) {
                      orderByChanged({
                        ...orderBy,
                        direction: orderBy.direction === "asc" ? "desc" : "asc",
                      })
                    } else {
                      orderByChanged({
                        column: header.column,
                        direction: "asc",
                      })
                    }
                  }}
                  sx={{ ...tableHeadTextStyle, textTransform: "capitalize" }}
                >
                  {header.label}
                  {orderBy.column === header.column ? (
                    <Box component="span" sx={visuallyHidden}>
                      {orderBy.direction === "desc" ? "sorted descending" : "sorted ascending"}
                    </Box>
                  ) : null}
                </TableSortLabel>
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading && <TableLoadingSkeleton colSpan={headers.length} rowsPerPage={DEFAULT_ROWS_PER_PAGE} />}
    </>
  )
}

export function TableHeaderNoOrderingView(props: TableHeaderNoOrderingPropsInterface) {
  const { headers, isLoading } = props
  const { t } = useTranslation()
  const tableHeadTextStyle = {
    fontWeight: "bold",
    fontFamily: "Montserrat",
    color: "#515151",
    fontSize: "0.7rem",
  }

  return (
    <>
      <TableHead>
        <TableRow>
          {headers.map((header, index) => (
            <TableCell
              key={index}
              align={header.align ? header.align : "left"}
              padding="normal"
              sx={{
                width: header.width,
              }}
            >
              <Typography id={`table-header-${header.column}`} variant="subtitle2">
                <Box sx={{ ...tableHeadTextStyle, textTransform: "uppercase", wordBreak: "break-word" }}>
                  {t(header.label)}
                </Box>
              </Typography>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      {isLoading && <TableLoadingSkeleton colSpan={headers.length} rowsPerPage={5} />}
    </>
  )
}

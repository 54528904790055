import { DomainDependencies } from "../../../Domain.Dependencies"
import { from, map, Observable } from "rxjs"
import { apiCall, apiHeaders } from "../../../Domain.Calls"
import { DomainResponse } from "../../../Domain.Response"
import {
  CustomerProfile,
  initialValuesPersonal,
  initialValuesAddress,
  initialValuesContact,
  initialValuesEmail,
  UpdateAddressUpsert,
  UpdateContactUpsert,
  UpdateEmailUpsert,
  UpdatePersonalUpsert,
} from "./Profile.Model"

export const getCustomerProfile = (deps: DomainDependencies): Observable<DomainResponse<CustomerProfile>> => {
  const authHeaders = apiHeaders(deps)
  return apiCall(
    from(deps.customerProfileApi.getCustomerProfile(authHeaders)).pipe(
      map((customerProfileResponse) => {
        return {
          personal: {
            personal: {
              firstName: customerProfileResponse.data.personal.firstName ?? initialValuesPersonal.personal.firstName,
              lastName: customerProfileResponse.data.personal.lastName ?? initialValuesPersonal.personal.lastName,
              salutation: customerProfileResponse.data.personal.salutation ?? initialValuesPersonal.personal.salutation,
            },
          },
          contact: {
            contact: {
              phone: customerProfileResponse.data.contact.telephone ?? initialValuesContact.contact.phone,
              mobile: customerProfileResponse.data.contact.mobile ?? initialValuesContact.contact.mobile,
            },
          },
          address: {
            address: {
              city: customerProfileResponse.data.address.city ?? initialValuesAddress.address.city,
              houseNumber: customerProfileResponse.data.address.houseNumber ?? initialValuesAddress.address.houseNumber,
              street: customerProfileResponse.data.address.street ?? initialValuesAddress.address.street,
              postalCode: customerProfileResponse.data.address.postalCode ?? initialValuesAddress.address.postalCode,
            },
          },
          email: {
            contact: {
              email: customerProfileResponse.data.contact.email ?? initialValuesEmail.contact.email,
            },
          },
          customerNumber: customerProfileResponse.data?.customerNumber ?? "",
          contractConfigIds: [],
        }
      }),
    ),
  )
}

export const updateCustomerProfilePersonal = (
  personal: UpdatePersonalUpsert,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.customerProfileApi.updateCustomerProfilePersonal(personal, apiHeaders(deps))).pipe(map(() => true)),
  )
}

export const updateCustomerProfileAddress = (
  address: UpdateAddressUpsert,
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.customerProfileApi.updateCustomerProfileAddress(address, apiHeaders(deps))).pipe(map(() => true)),
  )
}

export const updateCustomerProfileContact = (
  contact: UpdateContactUpsert["contact"],
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.customerProfileApi.updateCustomerProfileContact(contact, apiHeaders(deps))).pipe(map(() => true)),
  )
}

export const updateCustomerProfileEmail = (
  email: UpdateEmailUpsert["contact"],
  deps: DomainDependencies,
): Observable<DomainResponse<boolean>> => {
  return apiCall(
    from(deps.customerProfileApi.updateCustomerProfileEmail(email, apiHeaders(deps))).pipe(map(() => true)),
  )
}

import { useTranslation } from "react-i18next"
import { Paper, IconButton, Stack, Typography, styled } from "@mui/material"

import { ContractFormTitle } from "../common/CommonFormFragments"
import { PrimaryPlusButton } from "../../../../../uikit/button/PrimaryPlusButton"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { CURRENCY_ADORNMENT } from "../../../../../uikit/input/CurrencyAdornment"
import { SwitchToggleView } from "../../../../../uikit/toggle/SwitchToggleView"
import { CommonFormikProps } from "../../../../Shared.Utils"
import { RemoveIcon } from "../../../../../uikit/Shared.Icon"
import { ZevPricePackage } from "../../../../../data/generated-sources/openapi"
import { useEffect } from "react"

const PricePaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginRight: theme.spacing(3),
  marginBottom: theme.spacing(3),
}))

interface ElectricityPriceProps extends CommonFormikProps<ZevPricePackage[]> {
  addPricePackage: () => void
  removePricePackage: (packageId: number) => void
}

export const ContractElectricityPricesFields = ({
  values,
  errors,
  touched,
  setFieldValue,
  setFieldTouched,
  addPricePackage,
  removePricePackage,
}: ElectricityPriceProps) => {
  const { t } = useTranslation("contracts-management")

  // initial Standard price created on BE does not containt prices object, this is fix for initial setting of price
  useEffect(() => {
    setFieldValue("[0].prices.isFixedRate", values[0]?.prices?.isFixedRate ?? false)
  }, [setFieldValue, values])

  const validatePricesField = (index: number, filed: string) => {
    const errorObject = errors[index]?.prices
    const touchedObject = touched[index]?.prices
    const errorsFileds = errorObject ? Object.keys(errorObject) : []
    const touchedFileds = touchedObject ? Object.keys(touchedObject) : []

    if (touchedFileds.includes(filed) && errorsFileds.includes(filed)) {
      return t("shared:validation.mandatory", {
        field: t(`contracts-management:priceForm.${filed}`),
      })
    }
    return undefined
  }

  const renderElectricityPrices = (value: ZevPricePackage, index: number) => (
    <PricePaper key={value.id}>
      <Stack spacing={3} alignItems={"flex-end"}>
        <IconButton onClick={() => removePricePackage(value.id)} disabled={value.id === 0}>
          <RemoveIcon htmlColor={value.id === 0 ? "transparent" : "#888888"} />
        </IconButton>
        <SingleLineTextField
          id="name"
          name="name"
          label={t("priceForm.name")}
          value={value.name}
          onBlur={() => setFieldTouched(`${index}.name`, true)}
          onChange={({ target }) => setFieldValue(`${index}.name`, target.value)}
          helperText={
            touched[index]?.name && errors[index]?.name
              ? t("shared:validation.mandatory", { field: t("contracts-management:priceForm.name") })
              : undefined
          }
        />
        <SingleLineTextField
          id="solarPrice"
          name="solarPrice"
          label={t("priceForm.solarPrice")}
          value={value.prices?.solarPrice}
          onBlur={() => setFieldTouched(`[${index}].prices.solarPrice`, true)}
          onChange={({ target }) => setFieldValue(`[${index}]prices.solarPrice`, target.value)}
          helperText={validatePricesField(index, "solarPrice")}
          InputProps={CURRENCY_ADORNMENT}
        />
        <SingleLineTextField
          id="highTariffPrice"
          name="highTariffPrice"
          label={t("priceForm.highTariffPrice")}
          value={value.prices?.highTariffPrice}
          onBlur={() => setFieldTouched(`[${index}].prices.highTariffPrice`, true)}
          onChange={({ target }) => setFieldValue(`[${index}]prices.highTariffPrice`, target.value)}
          helperText={validatePricesField(index, "highTariffPrice")}
          InputProps={CURRENCY_ADORNMENT}
        />
        <SingleLineTextField
          id="lowTariffPrice"
          name="lowTariffPrice"
          label={t("priceForm.lowTariffPrice")}
          value={value.prices?.lowTariffPrice}
          onBlur={() => setFieldTouched(`[${index}].prices.lowTariffPrice`, true)}
          onChange={({ target }) => setFieldValue(`[${index}].prices.lowTariffPrice`, target.value)}
          helperText={validatePricesField(index, "lowTariffPrice")}
          InputProps={CURRENCY_ADORNMENT}
        />
        <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
          <Typography>{t("priceForm.consumptionDependant")}</Typography>
          <SwitchToggleView
            label={t("priceForm.flatRate")}
            checked={value.prices?.isFixedRate}
            onChange={(checked) => setFieldValue(`[${index}].prices.isFixedRate`, checked)}
          />
        </Stack>
        <SingleLineTextField
          id="additionalServicesPrice"
          name="additionalServicesPrice"
          label={value.prices?.isFixedRate ? t("priceForm.monthlyMeasurement") : t("priceForm.kwhMeasurement")}
          value={value.prices?.additionalServicesPrice}
          onBlur={() => setFieldTouched(`[${index}].prices.additionalServicesPrice`, true)}
          onChange={({ target }) => setFieldValue(`[${index}].prices.additionalServicesPrice`, target.value)}
          helperText={validatePricesField(index, "additionalServicesPrice")}
          InputProps={CURRENCY_ADORNMENT}
        />
        <Stack direction={"row"} alignItems="center" spacing={2} width="100%">
          <Typography>{t("priceForm.withoutSpike")}</Typography>
          <SwitchToggleView
            label={t("priceForm.withSpike")}
            checked={!!value.prices?.spikePrice}
            onChange={(checked) => setFieldValue(`[${index}].prices.spikePrice`, checked ? "0" : null)}
          />
        </Stack>
        {value.prices?.spikePrice && (
          <SingleLineTextField
            type="text"
            id="spikePrice"
            name="spikePrice"
            label={t("priceForm.spikePrice")}
            value={value.prices?.spikePrice}
            onBlur={() => setFieldTouched(`[${index}].prices.spikePrice`, true)}
            onChange={({ target }) => setFieldValue(`[${index}].prices.spikePrice`, target.value.trim())}
            helperText={validatePricesField(index, "spikePrice")}
            InputProps={CURRENCY_ADORNMENT}
          />
        )}
      </Stack>
    </PricePaper>
  )

  return (
    <Paper>
      <ContractFormTitle>{t("priceForm.title")}</ContractFormTitle>
      <Stack direction="row" alignItems="start" flexWrap="wrap">
        {values?.map(renderElectricityPrices)}
        <PrimaryPlusButton onClick={addPricePackage} />
      </Stack>
    </Paper>
  )
}

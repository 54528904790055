import { useTranslation } from "react-i18next"
import { BillingStatusType } from "../../../../../../domain/portal/admin/billings/participant/BillingsParticipant.Model"
import { ChipColor, StatusChipView } from "../../../../../../uikit/label/StatusChipView"

interface BillingStatusTypeViewProps {
  billingStatusType: BillingStatusType
}

export const BillingStatusTypeView = (props: BillingStatusTypeViewProps) => {
  const { t } = useTranslation()
  const { billingStatusType } = props
  return (
    <StatusChipView
      chipColor={statusChipColor(billingStatusType)}
      label={t(`shared:billing.status.${billingStatusType}`)}
    />
  )
}

const statusChipColor = (statusType: BillingStatusType) => {
  switch (statusType) {
    case BillingStatusType.WAITING_FOR_DATA:
    case BillingStatusType.SUSPENDED:
      return ChipColor.GREY
    case BillingStatusType.DATA_AVAILABLE:
      return ChipColor.YELLOW
    case BillingStatusType.IN_PROGRESS:
    case BillingStatusType.IN_PROGRESS_REOPENED:
      return ChipColor.TEAL
    case BillingStatusType.DONE:
    case BillingStatusType.PAID:
      return ChipColor.GREEN
    default:
      return ChipColor.GREY
  }
}

import { FormikHandlers, FormikHelpers, FormikState } from "formik"
import { useTranslation } from "react-i18next"
import { FormRowCell, FormRowColumn, FormView } from "../../../../../uikit/form/FormView"
import { Box, Typography } from "@mui/material"
import { TinyPaddedBox } from "../../../../../uikit/box/PaddedBox"
import { SelectPicker } from "../../../../../uikit/input/SelectPicker"
import { validationError } from "../../../../Shared.Validation"
import { businessPartnerTypeKeys, salutationKeys } from "../../../../../domain/participant/Participant.Model"
import { SingleLineTextField } from "../../../../../uikit/input/SingleLineTextField"
import { ParticipantResponse } from "../../../../../data/generated-sources/openapi"

export const ParticipantPersonalEditForm = (
  props: Pick<FormikHandlers, "handleChange" | "handleBlur"> &
    Pick<FormikState<ParticipantResponse>, "errors" | "values" | "touched"> &
    Pick<FormikHelpers<ParticipantResponse>, "setFieldValue">,
) => {
  const { t } = useTranslation("customer-participants")
  const { handleChange, handleBlur, touched, values, errors, setFieldValue } = props

  const labelLayout = (label: string) => (
    <Typography variant="body1" textAlign="start" sx={{ fontSize: "12px", mt: 2 }}>
      {label}
    </Typography>
  )

  return (
    <FormView>
      <Box>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("form.field.businessPartnerType"))}
            <SelectPicker
              name="businessPartnerType"
              type="text"
              helperText={validationError(errors.gpType, touched.gpType)}
              value={values.gpType}
              items={businessPartnerTypeKeys.map((businessPartnerType) => ({
                label: t(`registerForm.gptype.${businessPartnerType}`),
                value: businessPartnerType,
              }))}
              onChange={({ target }) => setFieldValue("gpType", target.value)}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}></FormRowCell>
        </FormRowColumn>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("registerForm.salutation"))}
            <SelectPicker
              name="salutation"
              type="text"
              helperText={validationError(errors.personalData?.salutation, touched.personalData?.salutation)}
              value={values.personalData.salutation}
              emptyValue="None"
              items={salutationKeys.map((salutation) => ({
                label: t(`shared:salutation.${salutation}`),
                value: salutation,
              }))}
              onChange={({ target }) => setFieldValue("personalData.salutation", target.value)}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}>
            {labelLayout(t("registerForm.title"))}
            <SingleLineTextField
              sx={{ mt: 2 }}
              name="title"
              type="text"
              helperText={validationError(errors.personalData?.title, touched.personalData?.title)}
              value={values.personalData?.title}
              onChange={({ target }) => setFieldValue("personalData.title", target.value)}
              onBlur={handleBlur}
            />
            {errors.personalData?.title ||
              (touched.personalData?.title && (
                <TinyPaddedBox>
                  <Typography variant="body1" sx={{ color: "red" }}>
                    {errors.personalData?.title}
                  </Typography>
                </TinyPaddedBox>
              ))}
          </FormRowCell>
        </FormRowColumn>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("registerForm.firstName"))}
            <SingleLineTextField
              name="firstName"
              type="text"
              helperText={validationError(errors.personalData?.firstName, touched.personalData?.firstName)}
              value={values.personalData?.firstName}
              onChange={handleChange("personalData.firstName")}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}>
            {labelLayout(t("registerForm.lastName"))}
            <SingleLineTextField
              name="lastName"
              type="text"
              helperText={validationError(errors.personalData?.lastName, touched.personalData?.lastName)}
              value={values.personalData?.lastName}
              onChange={handleChange("personalData.lastName")}
              onBlur={handleBlur}
            />
          </FormRowCell>
        </FormRowColumn>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("registerForm.firstNameSecondPerson"))}
            <SingleLineTextField
              name="firstNameSecondPerson"
              type="text"
              helperText={validationError(
                errors.personalData?.firstNameSecondPerson,
                touched.personalData?.firstNameSecondPerson,
              )}
              value={values.personalData?.firstNameSecondPerson}
              onChange={handleChange("personalData.firstNameSecondPerson")}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}>
            {labelLayout(t("registerForm.lastNameSecondPerson"))}
            <SingleLineTextField
              name="lastNameSecondPerson"
              type="text"
              helperText={validationError(
                errors.personalData?.lastNameSecondPerson,
                touched.personalData?.lastNameSecondPerson,
              )}
              value={values.personalData?.lastNameSecondPerson}
              onChange={handleChange("personalData.lastNameSecondPerson")}
              onBlur={handleBlur}
            />
          </FormRowCell>
        </FormRowColumn>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("registerForm.phone"))}
            <SingleLineTextField
              name="contactTelephone"
              type="text"
              helperText={validationError(errors.contactDetails?.phone, touched.contactDetails?.phone)}
              value={values.contactDetails?.phone}
              onChange={handleChange("contactDetails.phone")}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}>
            {labelLayout(t("registerForm.mobile"))}
            <SingleLineTextField
              name="contactMobile"
              type="text"
              helperText={validationError(errors.contactDetails?.mobile, touched.contactDetails?.mobile)}
              value={values.contactDetails?.mobile}
              onChange={handleChange("contactDetails.mobile")}
              onBlur={handleBlur}
            />
          </FormRowCell>
        </FormRowColumn>
        <FormRowColumn>
          <FormRowCell sx={{ pl: 0, pr: 5 }}>
            {labelLayout(t("registerForm.email"))}
            <SingleLineTextField
              name="contactEmail"
              type="text"
              helperText={validationError(errors.contactDetails?.email, touched.contactDetails?.email)}
              value={values.contactDetails?.email}
              onChange={handleChange("contactDetails.email")}
              onBlur={handleBlur}
            />
          </FormRowCell>
          <FormRowCell sx={{ pl: 5 }}></FormRowCell>
        </FormRowColumn>
      </Box>
    </FormView>
  )
}

import { useEffect, useState } from "react"
import {
  MediumZevAdminUpdateRequest,
  MeterResponse,
  Municipality,
  ZevMeter,
  ZevMeterType,
} from "../../../../../../../data/generated-sources/openapi"
import { useTranslation } from "react-i18next"
import { CircularProgress, Grid, Paper, Typography } from "@mui/material"
import { Form, Formik, FormikProps, FormikValues } from "formik"
import { ErrorAlert } from "../../../../../../../uikit/Shared.Alert"
import { SelectPicker } from "../../../../../../../uikit/input/SelectPicker"
import { useMutation, useQuery } from "@tanstack/react-query"
import {
  getMediumZevByContractId,
  updateMediumZevByContractId,
} from "../../../../../../../domain/portal/admin/mediu/Medium.Repository"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../../../../Shared.Utils"
import MediumZevCardDetailComponent from "./MediumZevCardDetail.Component"
import { FormUpdateActionsView } from "../../../../../../../uikit/form/FormUpdateActions"
import { useHistory } from "react-router-dom"
import { PlusIcon } from "../../../../../../../uikit/Shared.Icon"
import { FabButton } from "../../../../../../../uikit/button/FabButton"
import DateUtils from "../../../../../../../services/utils/DateUtils"
import { getContractManagementById } from "../../../../../../../domain/portal/admin/contracts/Contracts.Repository"
import { mediumZevValidation } from "./MediumZevValidation.Schema"

const mediumObject: ZevMeter = {
  meteringCode: "",
  dateFrom: "",
  dateTo: "",
  meterType: ZevMeterType.OUTGOING,
}

const MediumZevUpdateComponent = ({ contractId }: { contractId: string }) => {
  const [mediumList, setMediumList] = useState<ZevMeter[]>([])
  const history = useHistory()
  const { t } = useTranslation("medium")

  const { data: contractData, remove: removeContract } = useQuery(
    ["getContractForStartDate"],
    () => getContractManagementById(contractId),
    {
      enabled: !!contractId,
      ...useQueryDefaultOptions,
    },
  )

  const {
    data: mediumZevData,
    isError: mediumZevIsError,
    isLoading: mediumZevIsLoading,
    remove: mediumZevRemove,
    error: mediumError,
  } = useQuery(["getMediumZevForUpdateByContractId"], () => getMediumZevByContractId(contractId), {
    enabled: !!contractId,
    ...useQueryDefaultOptions,
    onSuccess: ({ incomingMeters, outgoingMeters }) => {
      setMediumList([
        ...mapToZevMeters(incomingMeters, ZevMeterType.INCOMING),
        ...mapToZevMeters(outgoingMeters, ZevMeterType.OUTGOING),
      ])
    },
  })

  const {
    mutate: updateMediumZevMutate,
    isError: updateMediumZevIsError,
    isLoading: updateMediumZevIsLoading,
    reset: updateMediumZevReset,
    error: updateError,
  } = useMutation(
    ["updateMediumZev"],
    (dto: MediumZevAdminUpdateRequest) => updateMediumZevByContractId(contractId, dto),
    {
      onSuccess: () => history.push(`/management-contracts/${contractId}`),
    },
  )

  useEffect(() => {
    return () => {
      updateMediumZevReset()
      mediumZevRemove()
      removeContract()
    }
  }, [updateMediumZevReset, mediumZevRemove, removeContract])

  const mapToZevMeters = (meters: MeterResponse[] | undefined, meterType: ZevMeterType): ZevMeter[] => {
    if (!meters?.length) {
      return []
    }
    return meters?.map((item: MeterResponse) => {
      return {
        meteringCode: item.meteringCode,
        dateFrom: item.billableFrom ?? "",
        dateTo: item.billableTo ?? "",
        meterType,
      }
    })
  }

  const addMedium = (
    values: FormikValues,
    setFieldValue: (fieldName: string, newValues: MediumZevAdminUpdateRequest) => void,
  ): void => {
    contractData?.startDate && (mediumObject["dateFrom"] = contractData?.startDate)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    setFieldValue("meters", [...values.meters, mediumObject])
  }

  const handleSubmit = (values: MediumZevAdminUpdateRequest) => {
    const updateMediumZevDto: MediumZevAdminUpdateRequest = {
      municipality: values.municipality,
      meters: values.meters.map((item: ZevMeter) => {
        return {
          meteringCode: item.meteringCode,
          meterType: item.meterType,
          dateFrom: item?.dateFrom ? DateUtils.getDeFormatDate(item.dateFrom) : "",
          dateTo: item?.dateTo ? DateUtils.getDeFormatDate(item.dateTo) : undefined,
        }
      }),
    }
    updateMediumZevMutate(updateMediumZevDto)
  }

  const errorMsg = (mediumError || updateError) as AxiosErrorDataType

  if (mediumZevIsLoading) return <CircularProgress />

  return (
    <Formik<MediumZevAdminUpdateRequest>
      initialValues={{
        meters: mediumList,
        municipality: (mediumZevData?.municipality as Municipality) ?? "",
      }}
      validationSchema={mediumZevValidation}
      enableReinitialize
      onSubmit={handleSubmit}
    >
      {(props: FormikProps<MediumZevAdminUpdateRequest>) => {
        const { values, isValid, dirty, handleBlur, setFieldValue } = props
        return (
          <Form>
            <Paper>
              <Typography variant="h4" fontFamily="Montserrat" fontWeight="lighter" mb={3}>
                {t("detail.title")}
              </Typography>
              <ErrorAlert
                scrollOnDisplay
                visible={mediumZevIsError || updateMediumZevIsError}
                message={t(`error-codes:${errorMsg?.response?.data?.code ?? errorMsg?.code ?? "OTHER"}`)}
              />
              <Grid container>
                <Grid item xs={6} mb={3} mt={3}>
                  <SelectPicker
                    type="text"
                    id={"municipality"}
                    name="municipality"
                    label={t("label.municipality")}
                    value={values?.municipality ?? mediumZevData?.municipality}
                    items={Object.values(Municipality)
                      .sort((a, b) => a?.localeCompare(b))
                      .map((item: string) => {
                        return {
                          label: item,
                          value: item,
                        }
                      })}
                    onChange={(e) => {
                      setFieldValue("municipality", e.target.value)
                    }}
                    onBlur={handleBlur}
                  />
                </Grid>
              </Grid>
              <Grid container mt={3}>
                <MediumZevCardDetailComponent {...props} />
                <FabButton
                  size="medium"
                  icon={<PlusIcon fontSize="large" />}
                  onClick={() => addMedium(values, setFieldValue)}
                />
              </Grid>
            </Paper>
            <FormUpdateActionsView
              isValid={isValid}
              dirty={dirty}
              isLoading={updateMediumZevIsLoading}
              navigateBack={() => history.push(`/management-contracts/${contractId}`)}
              buttonCtaLabel={t("medium:label.saveBtn")}
            />
          </Form>
        )
      }}
    </Formik>
  )
}

export default MediumZevUpdateComponent

import React, { useEffect, useState } from "react"
import { Paper, Stack, Typography, Grid, CircularProgress } from "@mui/material"
import { RouteComponentProps } from "react-router"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { useQuery, useMutation } from "@tanstack/react-query"
import { AxiosErrorDataType, useQueryDefaultOptions } from "../../../Shared.Utils"
import { EditIcon, LocationOn, RemoveIcon, TelephoneIconFilled, Sync } from "../../../../uikit/Shared.Icon"

import { FabButton } from "../../../../uikit/button/FabButton"
import { useTranslation } from "react-i18next"
import {
  getParticipantById,
  confirmSapSyncParticipantById,
  deleteParticipant,
} from "../../../../domain/participant/Participant.Repository"
import { FormDataItemBox } from "../../../../uikit/box/DataItemBox"
import { formatEmail, formatPhone } from "../../../../uikit/Shared.Formatters"
import { GreenCirle, RedCirle } from "./fragments/SyncIndicators"
import { PrimaryButton } from "../../../../uikit/button"
import { ConfirmDialog } from "../../../../uikit/confirmDialog/ConfirmDialog"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { ParticipantPersonalDataSalutationEnum } from "../../../../data/generated-sources/openapi"
import { ParticipantUtilityUnitsHistory } from "../../shared/participants/ParticipantUtilityUnitsHistory"

const SectionTitle = ({ icon, title }: { icon: React.ReactChild; title: string }) => (
  <Grid item xs={12}>
    <Stack direction={"row"} alignItems={"center"} justifyContent={"flex-start"} spacing={1} pt={3} pb={1}>
      {icon}
      <Typography variant="h4" fontSize={20} fontWeight={500} textTransform={"capitalize"}>
        {title}
      </Typography>
    </Stack>
  </Grid>
)

export const ParticipantDetails = ({
  history,
  match: { params, url },
}: RouteComponentProps<AdminPortalRouteParams>) => {
  const { participantId } = params
  const { t } = useTranslation("participant")
  const [participantError, setParticipantError] = useState<AxiosErrorDataType>()

  const {
    remove: removeParticipant,
    isLoading,
    isFetching,
    data,
    refetch,
  } = useQuery(["getParticipant"], () => getParticipantById(participantId), {
    enabled: !!participantId,
    ...useQueryDefaultOptions,
    onError: setParticipantError,
  })

  const { mutate: syncParticipant, isLoading: isSyncing } = useMutation(
    ["syncParticipant"],
    () => confirmSapSyncParticipantById(participantId),
    {
      onSuccess: () => refetch(),
      onError: setParticipantError,
    },
  )

  const { mutate: participantDelete, isLoading: isDeleting } = useMutation(
    ["deleteParticipant"],
    () => deleteParticipant(participantId),
    {
      onSuccess: () => history.goBack(),
      onError: setParticipantError,
    },
  )

  useEffect(() => {
    return () => {
      removeParticipant()
    }
  }, [removeParticipant])

  const getSalutation = () =>
    data?.personalData?.salutation === ParticipantPersonalDataSalutationEnum.UNDEFINED
      ? ""
      : t(`shared:salutation.${data?.personalData?.salutation}`)

  const personalName = () =>
    `${data?.personalData?.title ?? ""} ${getSalutation()} ${data?.personalData?.firstName} ${
      data?.personalData?.lastName
    }`

  if (isLoading && isFetching) return <CircularProgress color="primary" size={48} />

  return (
    <>
      <Paper>
        <ErrorAlert
          visible={!!participantError}
          message={t(`error-codes:${participantError?.response?.data?.code || participantError?.code || "OTHER"}`)}
        />
        <Grid container p={2}>
          <Grid item xs={12}>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
              <Stack direction={"row"} alignItems={"center"} spacing={1}>
                {data?.syncStatus ? <GreenCirle /> : <RedCirle />}
                <Typography variant="h6" color={data?.syncStatus ? "green" : "red"}>
                  {t(`detail.label.${data?.syncStatus ? "synced" : "unsynced"}`)}
                </Typography>
              </Stack>
              <FabButton size="small" icon={<EditIcon />} onClick={() => history.push(`${url}/update`)} />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h4" fontSize={34} fontWeight={300} py={2}>
              {personalName()}
            </Typography>
          </Grid>

          <SectionTitle icon={<LocationOn fontSize="small" />} title={t("form.subtitle.personaldata")} />

          <Grid item xs={12} md={6}>
            <FormDataItemBox id="businessPartnerName" title={t("form.field.businessPartnerName")} value={data?.sapGp} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="gpType"
              title={t("form.field.businessPartnerType")}
              value={t(`form.field.gpType.${data?.gpType}`)}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDataItemBox id="salutation" title={t("form.field.salutation")} value={getSalutation()} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox id="title" title={t("form.field.title")} value={data?.personalData?.title} />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDataItemBox id="firstName" title={t("form.field.firstName")} value={data?.personalData?.firstName} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox id="lastName" title={t("form.field.lastName")} value={data?.personalData?.lastName} />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="firstNameSecondPerson"
              title={t("form.field.firstNameSecondPerson")}
              value={data?.personalData?.firstNameSecondPerson}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="lastNameSecondPerson"
              title={t("form.field.lastNameSecondPerson")}
              value={data?.personalData?.lastNameSecondPerson}
            />
          </Grid>

          <SectionTitle icon={<TelephoneIconFilled fontSize="small" />} title={t("form.subtitle.correspondence")} />

          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="contactTelephone"
              title={t("form.field.contactTelephone")}
              value={formatPhone(data?.contactDetails?.phone)}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="contactMobile"
              title={t("form.field.contactMobile")}
              value={formatPhone(data?.contactDetails?.mobile)}
            />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <FormDataItemBox
                id="contactEmail"
                title={t("form.field.contactEmail")}
                value={formatEmail(data?.contactDetails?.email)}
              />
            </Grid>
          </Grid>

          <SectionTitle icon={<LocationOn fontSize="small" />} title={t("form.subtitle.address")} />

          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="addressStreet"
              title={t("form.field.addressStreet")}
              value={data?.domicileAddress.street}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="addressHouseNumber"
              title={t("form.field.addressHouseNumber")}
              value={data?.domicileAddress.houseNumber}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDataItemBox id="addressCO" title={t("form.field.addressCO")} value={data?.domicileAddress?.co} />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="addressPostBox"
              title={t("form.field.addressPostBox")}
              value={data?.domicileAddress?.poBox}
            />
          </Grid>

          <Grid item xs={12} md={6}>
            <FormDataItemBox
              id="addressPostCode"
              title={t("form.field.addressPostCode")}
              value={data?.domicileAddress?.postCode}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <FormDataItemBox id="addressCity" title={t("form.field.addressCity")} value={data?.domicileAddress?.city} />
          </Grid>

          <Grid container item xs={12}>
            <Grid item xs={12} md={6}>
              <FormDataItemBox
                id="addressCountry"
                title={t("form.field.addressCountry")}
                value={data?.domicileAddress?.land}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
      <Paper>
        <Typography fontSize={24} textTransform={"uppercase"}>
          {t("utilityUnit:label.name")}
        </Typography>
        <ParticipantUtilityUnitsHistory
          participant={data}
          isLoading={isFetching}
          goToUnit={(unitId) => history.push(`/utility-units/${unitId}`)}
        />
      </Paper>

      <Stack direction={"row"} justifyContent={"flex-end"} spacing={1}>
        <ConfirmDialog
          actionButtonText={t("detail.action.delete")}
          actionButtonStartIcon={<RemoveIcon />}
          actionButtonLoading={isDeleting}
          onConfirm={() => participantDelete()}
          dialogTitle={t("form.alert.delete.label")}
          confirmButtonText={t("form.alert.delete.cta")}
          actionButtonStyle={{ m: 0 }}
        />

        <PrimaryButton
          startIcon={<Sync />}
          label={t("detail.action.sapSync")}
          isLoading={isSyncing}
          disabled={data?.syncStatus ?? isSyncing}
          onClick={() => syncParticipant()}
        />
      </Stack>
    </>
  )
}

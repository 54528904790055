import { Box, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ErrorAlert } from "../../../../../uikit/Shared.Alert"
import { AreaModalPropsInterface } from "../Area.Interface"

const AreaDeleteModal = ({ isError }: AreaModalPropsInterface) => {
  const { t } = useTranslation()
  return (
    <Box sx={{ pb: 5, mb: 3, color: "#000" }}>
      <ErrorAlert visible={isError} message={t("error-codes:GENERIC_FATAL_ERROR")} />
      <Typography variant="h6">{t("shared:dialog.confirm.title")}</Typography>
      <Typography variant="body2" sx={{ mt: 4 }}>
        {t("area:dialog.bodyText")}
      </Typography>
    </Box>
  )
}

export default AreaDeleteModal

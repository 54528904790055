import { AreaCustomerResponse } from "../../data/generated-sources/openapi"
import { DomainDependencies } from "../Domain.Dependencies"
import { DOMAIN_DEPENDENCIES } from "../../app/App.Config"
import { Observable, of } from "rxjs"
import { createOkResponse, DomainResponse } from "../Domain.Response"

const deps: DomainDependencies = DOMAIN_DEPENDENCIES

export const lastSelectedArea = (areas: AreaCustomerResponse[]): AreaCustomerResponse | undefined => {
  const lastSelectedAreaId = deps.cookie.readLastSelectedZev(deps.config.appName)
  if (lastSelectedAreaId) return areas.find((area) => area.id === lastSelectedAreaId)
  if (areas.length > 0) return areas[0]
  else return undefined
}

export const deleteAdminSession = (deps: DomainDependencies): Observable<DomainResponse<boolean>> => {
  deps.cookie.removeCookies(deps.config.appName)
  return of(createOkResponse(true))
}

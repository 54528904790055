import { Button, CircularProgress, ButtonTypeMap } from "@mui/material"
import { DefaultComponentProps } from "@mui/material/OverridableComponent"
import { PrimaryButtonProps } from "./PrimaryButton"
import { appThemePrimaryLightColor } from "../../app/Shared.Theme"

export const PrimaryOutlinedButton = (props: PrimaryButtonProps & DefaultComponentProps<ButtonTypeMap>) => {
  const { endIcon, isLoading, label, ...rest } = props
  const endIconCustom = isLoading ? (
    <CircularProgress style={{ color: appThemePrimaryLightColor }} size={20} />
  ) : (
    endIcon
  )

  return (
    <Button variant="outlined" endIcon={endIconCustom} disabled={isLoading} {...rest}>
      {label}
    </Button>
  )
}

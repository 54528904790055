import React, { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../hooks"
import { RouteComponentProps, withRouter } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import DateUtils from "../../../../services/utils/DateUtils"
import { ManagerPortalRoutesProps } from "../ManagerPortal.Routes"
import { ContractCustomerResponse, ProductType } from "../../../../data/generated-sources/openapi"
import { customerGetAreaManagerContracts } from "../../../../domain/portal/manager/contracts/Contract.Repository"
import { HeaderCardsWrapper, IHeaderCard } from "../commonComponents/HeaderCardsWrapper/HeaderCardsWrapper"
import { selectSelectedContractData, setSelectedContractData } from "../store/contractSelectorSlice"
import { ErrorAlert } from "../../../../uikit/Shared.Alert"
import { AxiosErrorDataType } from "../../../Shared.Utils"

const ContractSelector = withRouter(({ match: { params } }: RouteComponentProps<ManagerPortalRoutesProps>) => {
  const { areaId } = params
  const { t } = useTranslation("dashboard")
  const dispatch = useAppDispatch()
  const { contractId } = useAppSelector(selectSelectedContractData)

  const [error, setError] = useState<AxiosErrorDataType>()
  const [contractList, setContractList] = useState<IHeaderCard<ProductType>[]>([])

  const handleContractSelect = (selectedContract: IHeaderCard<ProductType>) => {
    if (!selectedContract) return
    const payloadData = {
      contractId: selectedContract.id,
      contractTitle: selectedContract.title,
      contractDesc: selectedContract.descriptions,
      contractProductType: selectedContract.additional || ProductType.ZEV,
    }
    dispatch(setSelectedContractData(payloadData))
  }

  const checkProductChange = (endDate?: string, successorStartDate?: string) => {
    if (successorStartDate) {
      return t("contracts:contractSelector.successorStarDate", {
        date: DateUtils.getDeFormatDate(successorStartDate, "DD. MMMM YYYY"),
      })
    } else if (endDate) {
      return t("contracts:contractSelector.contractEndDate", {
        date: DateUtils.getDeFormatDate(endDate, "DD. MMMM YYYY"),
      })
    } else return ""
  }

  const mapContractCardsData = (contracts: ContractCustomerResponse[]) => {
    const contractsData = contracts?.map((contract) => {
      return {
        id: contract.id,
        title: contract.name,
        descriptions: [contract?.product?.name as string],
        additional: contract?.product?.productType,
        productChangeDate: checkProductChange(contract.endDate, contract.successorContract?.startDate),
      }
    })
    setContractList(contractsData)
    if (!contractId) handleContractSelect(contractsData[0])
  }

  const { refetch: refetchContracts } = useQuery(
    ["getContracts", areaId],
    () => customerGetAreaManagerContracts(areaId),
    {
      enabled: !!areaId && !contractId,
      onError: setError,
      onSuccess: mapContractCardsData,
    },
  )

  useEffect(() => {
    setError(undefined)
    refetchContracts()
  }, [areaId, refetchContracts])

  return (
    <>
      <ErrorAlert
        scrollOnDisplay
        visible={!!error}
        message={t(`error-codes:${error?.response?.data?.code || error?.code || "OTHER"}`)}
      />
      <HeaderCardsWrapper cards={contractList} selectedId={contractId} onCardSelect={handleContractSelect} />
    </>
  )
})

export default React.memo(ContractSelector)

import { Medium, PowerMeterType, UsageType, ZevPricePackage } from "../../../../data/generated-sources/openapi"
import { CommonFormikProps } from "../../../Shared.Utils"
import { GridProps } from "@mui/material"

export const meterMediumTypeValues: Medium[] = Object.values(Medium)
export const powerMeterTypeValues: PowerMeterType[] = Object.values(PowerMeterType)
export const usageTypeValues: UsageType[] = Object.values(UsageType)
export const buildingMetersUsageTypeValues: UsageType[] = usageTypeValues.filter(
  (usage: UsageType) => usage === UsageType.BUSINESS || usage === UsageType.RESIDENCE || usage === UsageType.UNDEFINED,
)
export type MeterMediumObjectKeys = keyof typeof Medium
export type MeterPowerTypeObjectKeys = keyof typeof PowerMeterType

export interface MeterCreateUpdateFormFieldsProps<T> extends CommonFormikProps<T> {
  pricePackagesOptions: ZevPricePackage[] | []
  isEditMeter: boolean
  contractStartDate?: string
}

export interface MeterFormRowInterface extends GridProps {
  md?: number
  xs?: number
  p?: number
  pr?: number
}

import { VewaTotalConsumption } from "../../../../../domain/portal/manager/dashboard/Dashboard.Model"
import { ColdWaterIcon, FireIcon, SnowFlakeIcon, WarmWaterIcon } from "../../../../../uikit/Shared.Icon"
import {
  mediumBlueLightColor,
  mediumGrayColor,
  mediumPinkColor,
  mediumPurpleColor,
  mediumPurpleLightColor,
  mediumPurpleMidColor,
  mediumRedColor,
} from "../../../../Shared.Theme"
import { PieDataInterface } from "../Analise.Model"

export const vewaTotalConsumptionDataMapper = ({
  totalColdConsumption,
  totalHeatConsumption,
  totalWarmWaterConsumption,
  totalColdWaterConsumption,
}: VewaTotalConsumption): PieDataInterface[] => {
  const mapData = (consumptionType: string): PieDataInterface => {
    switch (consumptionType) {
      case "totalHeatConsumption":
        return {
          name: consumptionType,
          value: totalHeatConsumption?.toFixed(2),
          color: mediumRedColor,
          bgColor: mediumPinkColor,
          unit: "kWh",
          icon: <FireIcon />,
        }
      case "totalColdConsumption":
        return {
          name: consumptionType,
          value: totalColdConsumption?.toFixed(2),
          color: mediumBlueLightColor,
          bgColor: mediumGrayColor,
          icon: <SnowFlakeIcon />,
          unit: "kWh",
        }
      case "totalWarmWaterConsumption":
        return {
          name: consumptionType,
          value: totalWarmWaterConsumption?.toFixed(2),
          color: mediumPurpleColor,
          bgColor: mediumPurpleLightColor,
          icon: <WarmWaterIcon />,
          unit: "m³",
        }
      default:
        return {
          name: consumptionType,
          value: totalColdWaterConsumption?.toFixed(2),
          color: mediumPurpleMidColor,
          bgColor: mediumPurpleLightColor,
          icon: <ColdWaterIcon />,
          unit: "m³",
        }
    }
  }

  const consumptionPieData: PieDataInterface[] = [
    "totalHeatConsumption",
    "totalColdConsumption",
    "totalWarmWaterConsumption",
    "totalColdWaterConsumption",
  ].map((consumption) => mapData(consumption))

  return consumptionPieData
}

import { useEffect, useState } from "react"
import { Dialog, DialogTitle } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"

import { UtilityUnitMoveInFormData } from "../../../../../../domain/participant/Participant.Model"
import { customerMoveInUtilityUnitParticipantExisting } from "../../../../../../domain/portal/manager/utilityUnit/UtilityUnit.Repository"
import { ErrorAlert } from "../../../../../../uikit/Shared.Alert"
import { ParticipationDialogPros } from "./UtilityUnitParticipations"
import { DialogCloseIcon } from "../../../../../../uikit/icons/Icons"
import DateUtils from "../../../../../../services/utils/DateUtils"
import { MoveInForm } from "./MoveInForm"
import { MoveinParticipationExistingRequest } from "../../../../../../data/generated-sources/openapi"
import { AxiosErrorDataType } from "../../../../../Shared.Utils"

interface ParticipantMoveInPros extends ParticipationDialogPros {
  minMoveInDate?: number
  afterMoveOutStep?: boolean
}

export const ParticipantMoveIn = ({
  showDialog,
  participation,
  minMoveInDate,
  afterMoveOutStep,
  onUpdateSuccess,
  onClose,
}: ParticipantMoveInPros) => {
  const { t } = useTranslation("utilityUnitParticipations")
  const [moveInError, setMoveInError] = useState<AxiosErrorDataType>()

  let moveInMinDate = minMoveInDate ?? DateUtils.getTimeStamp(participation?.moveInDate ?? "") ?? undefined

  if (afterMoveOutStep) {
    moveInMinDate =
      participation?.isCurrent && participation?.moveOutDate
        ? DateUtils.getTimeStamp(DateUtils.addDays(participation?.moveOutDate || "", 1))
        : DateUtils.getTimeStamp(participation?.moveInDate ?? "")
  }

  useEffect(() => {
    return () => {
      setMoveInError(undefined)
    }
  }, [setMoveInError])

  const { mutate: moveIn, isLoading: isMovingIn } = useMutation(
    ["moveIn"],
    (moveInData: MoveinParticipationExistingRequest) =>
      customerMoveInUtilityUnitParticipantExisting(participation?.utilityUnitId, participation?.id, moveInData),
    {
      onSuccess: onUpdateSuccess,
      onError: setMoveInError,
    },
  )

  const handleMoveInSubmit = (moveInData: UtilityUnitMoveInFormData) => {
    setMoveInError(undefined)
    if (!moveInData.participantId) return

    const moveInPayload = {
      participantId: moveInData.participantId,
      moveinDate: DateUtils.getDeFormatDate(moveInData.moveInDate),
    }
    moveIn(moveInPayload)
  }

  return (
    <Dialog open={showDialog} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>{t("moveIn.form.title")}</DialogTitle>
      <DialogCloseIcon onClick={onClose} />
      <ErrorAlert
        scrollOnDisplay
        visible={!!moveInError}
        message={t(`error-codes:${moveInError?.response?.data?.code ?? moveInError?.code ?? "OTHER"}`)}
      />

      <MoveInForm
        isLoading={isMovingIn}
        utilityUnitId={participation?.utilityUnitId}
        minMoveInDate={moveInMinDate}
        afterMoveOutStep={afterMoveOutStep}
        participationId={participation?.id}
        onClose={onClose}
        onMoveInSubmit={handleMoveInSubmit}
        setMoveInError={setMoveInError}
        onUpdateSuccess={onUpdateSuccess}
      />
    </Dialog>
  )
}

import { useTranslation } from "react-i18next"
import { Route, RouteComponentProps, Switch, withRouter } from "react-router-dom"
import { useCallback, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { AdminPortalRouteParams } from "../AdminPortal.Routes"
import { getParamsFromUrl } from "../../../Shared.Utils"
import { getOnlyProductById } from "../../../../domain/portal/admin/products/Products.Repository"
import { BreadcrumbsNextIcon, BreadcrumbsNavButton, BreadcrumbsHomeIcon } from "./fragments/BreadcrumbsFragments"
import { ProductIconNew } from "../../../../uikit/Shared.Icon"

export const ProductsBreadcrumb = withRouter((props: RouteComponentProps<AdminPortalRouteParams>) => {
  const { history } = props
  const { t } = useTranslation("products")
  const productUrlParams = getParamsFromUrl("/products/:productId") || ""

  const { data, remove } = useQuery(["getProduct"], () => getOnlyProductById(productUrlParams?.productId), {
    enabled: !!productUrlParams?.productId,
  })

  useEffect(() => {
    return () => {
      remove()
    }
  }, [remove, productUrlParams?.productId])

  const ProductsStartIcon = useCallback(() => {
    const redirectToProducts = () => history.push("/products")
    return (
      <>
        <BreadcrumbsHomeIcon redirectUrl={redirectToProducts} icon={<ProductIconNew />} />
        <BreadcrumbsNextIcon />
      </>
    )
  }, [history])

  return (
    <Switch>
      <Route path="/products/create" exact>
        <ProductsStartIcon />
        <BreadcrumbsNavButton disabled label={t("create.title")} />
      </Route>

      <Route path="/products/:productId/update">
        <ProductsStartIcon />
        <BreadcrumbsNavButton
          label={data?.data?.name || t("detail.title")}
          onClick={() => history.push(`/products/${productUrlParams?.productId}`)}
        />
        <BreadcrumbsNavButton disabled label={t("update.title")} />
      </Route>

      <Route path="/products/:productId">
        <ProductsStartIcon />
        <BreadcrumbsNavButton disabled label={data?.data?.name || t("list.title")} />
      </Route>

      <Route path="/products">
        <ProductsStartIcon />
        <BreadcrumbsNavButton disabled label={t("list.title")} />
      </Route>
    </Switch>
  )
})

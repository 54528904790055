import { ButtonBase, Menu, MenuItem, Box, IconButton, Stack } from "@mui/material"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { RouteComponentProps } from "react-router-dom"
import { ProfileIcon } from "../../../../uikit/Shared.Icon"
import { AdminPortalRouteParams } from "../../../portal/admin/AdminPortal.Routes"
import { AppBreadcrumb } from "../../../App.Breadcrumb"
import { AppLogo } from "../AppLogo"
import { COOKIE_ITEMS } from "../../../config/outsorceConstants"
import { whiteColor } from "../../../Shared.Theme"

export interface AdminHeaderViewProps {
  navigateAdminRoot: () => void
  navigateAdminContracts: () => void
  navigateAdminMyProfile: () => void
  navigateAdminSettings: () => void
  logout: (previousPathname?: string) => void
}

export const AdminHeaderView = (props: AdminHeaderViewProps & RouteComponentProps<AdminPortalRouteParams>) => {
  const { t } = useTranslation("appContainer")
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const { navigateAdminContracts, navigateAdminMyProfile, navigateAdminSettings, logout } = props

  return (
    <Stack
      py={1}
      px={3}
      flexDirection={"row"}
      alignItems={"center"}
      justifyContent={"flex-start"}
      sx={{
        backgroundColor: whiteColor,
        boxShadow: "0px 4px 6px -2px #0000000D, 0px 10px 15px -3px #0000001A",
        position: "fixed",
        zIndex: 5,
        width: "calc(100% - 16px)",
        height: 40,
      }}
    >
      <Box width={248}>
        <ButtonBase onClick={() => navigateAdminContracts()}>
          <AppLogo />
        </ButtonBase>
      </Box>
      <AppBreadcrumb {...props} />
      <IconButton onClick={handleClick}>
        <ProfileIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        sx={{
          ".MuiPaper-root": {
            padding: "10px",
            boxShadow:
              "0px 5px 5px -3px rgba(0,0,0,0.2),0px 8px 10px 1px rgba(0,0,0,0.14),0px 3px 14px 2px rgba(0,0,0,0.12)",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            handleClose()
            navigateAdminMyProfile()
          }}
        >
          {t("admin.header.profile.menu.myprofile")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleClose()
            navigateAdminSettings()
          }}
        >
          {t("admin.header.profile.menu.settings")}
        </MenuItem>
        <MenuItem
          onClick={() => {
            localStorage.setItem(COOKIE_ITEMS.userCkwIsAdmin, JSON.stringify(true))
            handleClose()
            logout()
          }}
        >
          {t("admin.header.profile.menu.logout")}
        </MenuItem>
      </Menu>
    </Stack>
  )
}
